import { SearchOutlined } from '@ant-design/icons';
import {
  faCopy,
  faFileExport,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Container,
  Spinner,
  UncontrolledTooltip
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import { useQuery } from "@tanstack/react-query";
import { Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import ModalCopyDash from "./ModalCopyDash";
import ModalDeleteDash from "./ModalDeleteDash";
import ModalEditDash from "./ModalEditDash";
import ModalExportDash from "./ModalExportDash";
import ModalNewDash from "./ModalNewDash";

/*** BOTÕES DE AÇÃO ***/
const ActionButtonsDash = ({ dash, tableReload }) => {
  const { t } = useTranslation();

  const [openExportDashboard, setExportDashboard] = useState(false);
  const [openCopyDash, setOpenCopyDash] = useState(false);
  const [openEditDash, setOpenEditDash] = useState(false);
  const [openDeleteDash, setOpenDeleteDash] = useState(false);

  // ativa/desativa modais
  function toggleExportDashboard() {
    setExportDashboard(!openExportDashboard);
  }
  function toggleCopyDash() {
    setOpenCopyDash(!openCopyDash);
  }
  function toggleEditDash() {
    setOpenEditDash(!openEditDash);
  }
  function toggleDeleteDash() {
    setOpenDeleteDash(!openDeleteDash);
  }

  return (
    <div className="d-flex justify-content-left">
      {/* ================== Copy =================== */}
      <div
        id={"exportDash" + dash.dashId}
        className="align-middle mr-1 cursor-pointer"
      >
        <FontAwesomeIcon // botão de editar
          icon={faFileExport}
          className="align-middle cursor-pointer"
          fixedWidth
          onClick={toggleExportDashboard}
        />
        {/* ** MODAL DE EDITAR DASHBOARD  ** */}
        {openExportDashboard ? (
          <ModalExportDash
            open={openExportDashboard}
            toggle={toggleExportDashboard}
            dash={dash}
            tableReload={tableReload}
          />
        ) : null}
        <UncontrolledTooltip
          placement="top"
          target={"exportDash" + dash.dashId}
        >
          {t("dashboards.exportDashboardTooltip")}
        </UncontrolledTooltip>
      </div>
      <div
        id={"copyDash" + dash.dashId}
        className="align-middle mr-1 cursor-pointer"
      >
        <FontAwesomeIcon // botão de editar
          icon={faCopy}
          className="align-middle cursor-pointer"
          fixedWidth
          onClick={toggleCopyDash}
        />
        {/*** MODAL DE EDITAR DASHBOARD  ***/}
        {openCopyDash ? (
          <ModalCopyDash
            open={openCopyDash}
            toggle={toggleCopyDash}
            dash={dash}
            tableReload={tableReload}
          />
        ) : null}
        <UncontrolledTooltip placement="top" target={"copyDash" + dash.dashId}>
          {t("dashboards.copyDashobardTooltip")}
        </UncontrolledTooltip>
      </div>
      {/* ================== Edit =================== */}
      <div
        id={"editDash" + dash.dashId}
        className="align-middle mr-1 cursor-pointer"
      >
        <FontAwesomeIcon // botão de editar
          icon={faPen}
          className="align-middle cursor-pointer"
          fixedWidth
          onClick={toggleEditDash}
        />
        {openEditDash ? (
          <ModalEditDash
            open={openEditDash}
            toggle={toggleEditDash}
            dash={dash}
            tableReload={tableReload}
          />
        ) : null}
        <UncontrolledTooltip placement="top" target={"editDash" + dash.dashId}>
          {t("dashboards.editDashobardTooltip")}
        </UncontrolledTooltip>
      </div>
      {/* ================== Delete =================== */}
      <div
        id={"deleteDash" + dash.dashId}
        className="align-middle mr-1 cursor-pointer"
      >
        <FontAwesomeIcon // botão de remover
          icon={faTrash}
          className="align-middle cursor-pointer"
          fixedWidth
          onClick={toggleDeleteDash}
        />
        {/* ** MODAL DE REMOVER DASHBOARD  ** */}
        {openDeleteDash ? (
          <ModalDeleteDash
            open={openDeleteDash}
            toggle={toggleDeleteDash}
            dash={dash}
            tableReload={tableReload}
          />
        ) : null}
        <UncontrolledTooltip
          placement="top"
          target={"deleteDash" + dash.dashId}
        >
          {t("dashboards.deleteDashobardTooltip")}
        </UncontrolledTooltip>
      </div>
    </div>
  );
};

const DashboardsTable = () => {
  const { t } = useTranslation();

  const [openModals, setOpenModals] = useState();
  const userType = localStorage.getItem("userType");
  const {data: dashs, isLoading, refetch} = useQuery({
    queryKey: 'dashboardSearch',
    queryFn: async () => {
      try {
        
        const response = await axios.get('/dashboardSearch')
        return response.data
      } catch (error) {
        console.log(error)
        toastr.warning('Erro!', "Erro ao buscar dashboards.");
        throw error
      }
    },
  });


  function toggle() {
    // Toggle selected element
    setOpenModals(!openModals);
  };
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex, isLink = false) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            color="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="sm"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="sm"
            style={{
              width: 90,
            }}
            color="warning"
            outline
          >
            Limpar
          </Button>

          <Button
            color="link"
            size="sm"
            onClick={() => {
              close();
            }}
          >
            Fechar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text,row) =>
      searchedColumn === dataIndex ? isLink ? (
        <Link to={`/dashboardView`} onClick={() => localStorage.setItem('dashId', row.dashId)}>
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        </Link>
      ): (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) :
      isLink ? (
        <Link to={`/dashboardView`} onClick={() => localStorage.setItem('dashId', row.dashId)}>
          {text}
        </Link>
      ) :
      (
        text
      ),
  });
  const tableColumns = [
    {
      dataIndex: "name",
      title: t('dashboards.name'),
      sorter: (a, b) => a.name.localeCompare(b.name),
       width: "50%" ,
       ...getColumnSearchProps('name', true),

    },
    {
      dataIndex: "description",
      title:  t('dashboards.description'),
      width: "100%" ,
      sorter: (a, b) => a.description.localeCompare(b.description),
      ...getColumnSearchProps('description'),
    },
    {
      dataIndex: "acoes",
      title:  t('dashboards.actions'),
      // sort: true,
      width: "20%" ,
      render: (_, row) => {
        return (
          <ActionButtonsDash
            className="text-center"
            dash={row}
            tableReload={() => refetch()}
          />
        );
      },
    },
  ];




  return (
    <Container fluid>
      <Header>
        <HeaderTitle> {t("dashboards.myDashboards")}</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{t("dashboards.homePage")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("dashboards.myDashboards")}</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      {userType === "3" ? null : (
        <Card className="h-100%" style={{ marginTop: "-26px" }}>
          <CardBody className="text-center">
            <p>{t("dashboards.moreDash")}</p>

          <Button
            color="primary"
            onClick={toggle}
            className="mr-1"
          >
            {t('dashboards.newDash')}
          </Button>
          <ModalNewDash
            open={openModals}
            toggle={toggle}
            tableReload={() => refetch()}
          />
        </CardBody>
      </Card>)}

      <Card>
      <CardBody className="p-0">
          {(isLoading && !dashs)
            ? (!dashs)
              ? <div className="text-center"><Spinner color="primary" /></div>
              : <div className="text-center">
                <p className="mt-4">{t('dashboards.anyDashboards')} </p>
              </div>
            : <div className="m-2">
              <Table
                columns={tableColumns}
                dataSource={dashs}
                size="small"
              />
            </div>
          }
        </CardBody>
      </Card>
    </Container>
  );
};

export default DashboardsTable;
