import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  listMeters,
  readParamsPlant,
  readPlant,
  readDeviceStatus,
  getGeneralConsumptionPlantData,
} from "./PlantService";
import { getStartEndDays, getStartEndMonth } from "../../../../../src/utils/functions";
import moment from "moment";

export const PlantContext = createContext();

export const PlantProvider = ({ children }) => {
  
  const urlParams = useParams();

  const [objectEnergy, setObjectEnergy] = useState({
    ucs: [],
    totalConsumption: 0,
    totalPeakConsumption: 0,
    totalOffPeakConsumption: 0,
  });
  const [filterMeters, setFilterMeters] = useState([]);

  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
  );
  const [params, setParams] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [plant, setPlant] = useState(null);
  const [deviceStatus, setDeviceStatus] = useState([]);
  const [chart, setChart] = useState(null);
  const [loadingChart, setLoadingChart] = useState(false);
  const [searchModel, setSearchModel] = useState("days");

  const handleChangeSearchModel = (model) => setSearchModel(model);

  const handlePrevDate = () => {
    setSelectedDate((prevToday) => {
      let newDate = moment(prevToday).subtract(
        searchModel === "week" ? 7 : 1,
        searchModel !== "month" ? "day" : "month"
      );

      if (newDate.isAfter(prevToday)) {
        newDate.subtract(1, "year");
      }

      return newDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    });
  };

  const handleNextDate = () => {
    setSelectedDate((prevSelectedDate) => {
      let newDate = moment(prevSelectedDate).add(
        searchModel === "week" ? 7 : 1,
        searchModel !== "month" ? "day" : "month"
      );

      if (newDate.isBefore(prevSelectedDate)) {
        newDate.add(1, "year");
      }

      return newDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    });
  };

  async function getMonthSelected() {
    setLoadingChart(true);
    const { start, end } = searchModel !== "days"
        ? getStartEndDays(selectedDate, searchModel)
        : getStartEndMonth(selectedDate);
    const chart = await getGeneralConsumptionPlantData({
      projId: urlParams.id,
      start,
      end,
      searchModel,
    });
    setChart(chart);
    setLoadingChart(false);
  }

  const getPlant = async (id) => {
    const plant = await readPlant(id);
    setPlant(plant);
  };

  const getParams = async (id) => {
    const params = await readParamsPlant(id);
    setParams(params);
  };

  const getGeneralValues = async (id) => {
    const data = await listMeters({
      projectId: id,
      monthRef: selectedDate,
    });

    if (data) {
      setObjectEnergy(data);
      setFilterMeters(data.meters);
    }
    setLoadingData(false);
  };

  const getDeviceStatus = async (projId) => {
    const response = await readDeviceStatus(projId);
    if (response?.status === 200) {
      setDeviceStatus(response.data);
    }
  };


  function reload() {
    getParams(urlParams.id);
    getPlant(urlParams.id);
    getGeneralValues(urlParams.id);
    getDeviceStatus(urlParams.id);
    getMonthSelected();
  }

  useEffect(() => {
    getParams(urlParams.id);
    getPlant(urlParams.id);
    getGeneralValues(urlParams.id);
    getDeviceStatus(urlParams.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams, selectedDate]);

  useEffect(() => {
    getMonthSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchModel, selectedDate]);

  return (
    <PlantContext.Provider
      value={{
        projectId: urlParams.id,
        objectEnergy,
        setObjectEnergy,
        filterMeters,
        setFilterMeters,
        selectedDate,
        setSelectedDate,
        plant,
        params,
        loadingData,
        setLoadingData,
        getParams,
        getPlant,
        reload,
        deviceStatus,
        chart,
        getMonthSelected,
        loadingChart,
        searchModel,
        handleChangeSearchModel,
        handlePrevDate,
        handleNextDate,
      }}
    >
      {children}
    </PlantContext.Provider>
  );
};

export function usePlantContext() {
  const context = useContext(PlantContext);
  if (context === undefined) {
    throw new Error("usePlantContext must be used within a PlantProvider");
  }
  return context;
}
