const ptBrLayoutTranslations = {
  sidebar: {
    logout: "Sair",
    data: "Dados",
    tools: "Recursos",
    adminOnly: "Ferramentas de Administrador",
    home: "Página Inicial",
    dashboards: "Dashboards",
    dataExplorer: "Data Explorer",
    projects: "Minhas Plantas",
    alarms: "Alarmes",
    registers: "Configurações",
    security: "Segurança",
    management: "Gestão",
    alarmView: "Histórico",
    alarmConfig: "Parâmetros",
    alarmsData: "Gestão de Parâmetros",
    usersData: "Empresas e Usuários",
    projectsData: "Plantas e Dispositivos",
    logsData: "Registros de Ações",
    myDriversData: "Criação de Drivers",
    gateways: "Gestão de Gateways",
    devices: "Dispositivos",
    solarView: "Solar",
    energyView: "Energia",
    iot: "IoT",
    specialist: "Especialistas",
    solarModuleOn: "Solar",
    powerModuleOn: "Energia",
    waterModuleOn: "Água",
    gasModuleOn: "Gás",
    climateModuleOn: "Climatização",
    nobreakModuleOn: "NoBreak",
    changeTheme: "Alterar Tema",
    notifications: "Notificações",
    changeLanguage: "Alterar Idioma",
    profile: "Perfil",
    soundNotifications: "Notificações Sonoras",
    noc: "Network Operations Center",
    nocCenter: "Network Operations Center",
    nocAs: "Autorizações de Serviços",
    nocAsCommercial: "Gerenciamento Comercial"
  },
  footer: {
    support: "Suporte",
    privacy: "Privacidade",
    terms: "Termos de Serviço",
    contact: "Contato",
  },
  navbar: {
    search: "Buscar",
    alarms: "Alarmes",
    activeAlarms: "alarmes ativos",
    activeAlarm: "alarme ativo",
    noAlarms: "Não há alarmes ativos",
    settings: "Preferências",
    logout: "Sair",
  },
  theme: {
    modern: "Moderno",
    classic: "Clássico",
    dark: "Escuro",
    light: "Claro",
  },
};

export default ptBrLayoutTranslations;
