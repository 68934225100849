import { useQuery } from "@tanstack/react-query";
import { ConfigProvider, Table } from "antd";
import axios from "axios";
import moment from "moment";
import { useMemo, useRef, useState } from "react";
import { Nav } from "react-bootstrap";
import { Modal, ModalBody } from "reactstrap";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";

export const ModalInfoSolar = ({ showModal, handleModal, companyId }) => {
  const { t } = useTranslation();
  const {
    status,
    data: reactQuery,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["companyStatusSolarDevicesModal", companyId],
    queryFn: async () => {
      const res = await axios.get(
        `v2/company/listStatusSolarDevices?companyId=${companyId}&listSolarDevices=true`
      );
      return res.data;
    },
  });

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [activeTab, setActiveTab] = useState("All");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`${t("navbar.search")} ${dataIndex}`}
          value={selectedKeys[0] || ""}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t("navbar.search")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {t("navbar.clear")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            {t("navbar.filter")}
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            {t("projTable.close")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = useMemo(
    () => [
      {
        title: t("noc.table.deviceDescription"),
        dataIndex: "description", // Alterado para refletir o novo campo
        key: "description",
        width: 150,
        sorter: (a, b) => a.description.localeCompare(b.description),
        ...getColumnSearchProps("description"),
      },
      {
        title: t("noc.table.lastComm"),
        dataIndex: "lastComm",
        key: "lastComm",
        width: 120,
        sorter: (a, b) => (a.lastComm || 0) - (b.lastComm || 0),
        render: (text, record) =>
          record.lastComm ? moment.unix(record.lastComm).format("lll") : "N/A",
      },
      {
        title: t("noc.table.commInterval"),
        dataIndex: "commInterval",
        key: "commInterval",
        width: 150,
        sorter: (a, b) => a.commInterval - b.commInterval,
      },
      {
        title: t("noc.table.projectName"),
        dataIndex: "projectName", // Novo campo para planta
        key: "projectName",
        width: 200,
        sorter: (a, b) => a.projectName.localeCompare(b.projectName),
        ...getColumnSearchProps("projectName"),
      },
      {
        title: t("noc.table.company"),
        dataIndex: "companyName", // Novo campo para empresa
        key: "companyName",
        width: 200,
        sorter: (a, b) => a.companyName.localeCompare(b.companyName),
        ...getColumnSearchProps("companyName"),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 100,
        filters: [
          { text: "Online", value: 0 },
          { text: "Offline", value: 1 },
          { text: "Nunca Comunicou", value: 2 },
        ],
        onFilter: (value, record) => record.status === value, // Filtro para a coluna de status
        render: (_, record) => {
          const statusText =
            record.status === 0
              ? "Online"
              : record.status === 1
              ? "Offline"
              : "Nunca Comunicou";
          const color =
            record.status === 0
              ? "green"
              : record.status === 1
              ? "red"
              : "gray";

          return (
            <span style={{ color, fontWeight: "bold" }}>{statusText}</span>
          );
        },
      },
    ],
    [searchText, searchedColumn]
  );

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const filteredDevices = useMemo(() => {
    return activeTab === "All"
      ? reactQuery || []
      : reactQuery?.filter((device) => device.model === activeTab) || [];
  }, [reactQuery, activeTab]);

  return (
    <Modal
      isOpen={showModal}
      toggle={() => {
        handleModal();
      }}
      size="xl"
    >
      {status === "loading" || isFetching ? (
        <p>Loading...</p>
      ) : (
        <>
          <Nav variant="tabs" activeKey={activeTab} onSelect={toggle}>
            <Nav.Item>
              <Nav.Link eventKey="All">Todos Dispositivos</Nav.Link>
            </Nav.Item>
          </Nav>
          <ModalBody>
            <div
              className="w-100"
              sm="12"
              style={{ height: "100%", overflow: "hidden" }}
            >
              <ConfigProvider>
                <Table
                  columns={columns.map((col) => ({
                    ...col,
                    onHeaderCell: (column) => ({
                      width: column.width,
                    }),
                  }))}
                  scroll={{ y: 47 * 10 }}
                  dataSource={filteredDevices}
                  size="small"
                  pagination={{
                    pageSizeOptions: [10, 25, 50],
                    defaultPageSize: 25,
                  }}
                />
              </ConfigProvider>
            </div>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};
