const ptBrProjectTranslations = {
    home: {
        welcome: "Bem vindo",
        connectivity: "Conectividade",
        online: "Ativos",
        offline: "Inativos",
        noComm: "Nunca comunicaram",
        onlineTitle: "Dispositivos Ativos",
        offlineTitle: "Dispositivos Inativos",
        noCommTitle: "Dispositivos que nunca comunicaram",
        description: "Referência",
        project: "Projeto",
        lastComm: "Última comunicação",
        search: "Busca",
        map: "Mapa",
        latestOccurrences: "Últimas Ocorrências",
        typesOfAlarms: "Tipos de Alarmes",
        high: "Alta",
        average: "Média",
        low: "Baixa",
        notOccurrences: "Não há ocorrências de alarmes.",
        powerModuleOn: "Módulo de Energia",
        solarModuleOn: "Módulo Solar",
        waterModuleOn: "Módulo de Água",
        gasModuleOn: "Módulo de Gás",
    },
}

export default ptBrProjectTranslations;