import React from "react";
import { Form } from "react-bootstrap";

export const FormInput = ({
  id,
  label,
  name,
  placeholder,
  type = "text",
  isInvalid = false,
  feedbackText,
  text,
  register,
  validation,
  options = [],
  setValue,
  getValues,
  inputBlur = null,
  ...props
}) => {
  const handleInputChange = (event) => {
    if (name === "zipCode") {
      const input = event.target.value.replace(/\D/g, "");
      const formattedInput = input
        .slice(0, 8)
        .replace(/(\d{5})(\d{3})/, "$1-$2");

      setValue(name, formattedInput);
    }
    if (name === "monthValue") {
      const input = event.target.value.replace(/\D/g, "");

      let valorCentavos = parseInt(input);

      if (!isNaN(valorCentavos)) {
        let valorReais = valorCentavos / 100;

        const formattedValue = valorReais.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });

        const valorMonetario = formattedValue;

        setValue(name, valorMonetario);
      } else {
        setValue(name, "");
      }
    }
    if (name === "contractDuration") {
      const input = event.target.value.replace(/\D/g, "");
      const months = parseInt(input);
      const formattedInput = months ? `Meses: ${months}` : "Meses: 0";
      setValue(name, formattedInput);
    }
  };

  const handleInputBlur = (event) => {
    if (inputBlur !== null) {
      const inputValue = event.target.value;
      inputBlur(inputValue);
    }
  };

  return (
    <Form.Group className="mb-1">
      <Form.Label htmlFor={id}>{label}</Form.Label>
      {type === "select" ? (
        //NOTE: Select sem controle de required
        //TODO: CORREÇÃO FUTURA
        // <Select
        //   id={id}
        //   name={name}
        //   options={
        //     options.length > 0
        //       ? options.map((option) => ({
        //           value: option.id,
        //           label: option.name,
        //         }))
        //       : []
        //   }
        //   placeholder={placeholder}
        //   isInvalid={isInvalid}
        //   defaultInputValue={
        //     options.length > 0 && getValues && getValues(name)
        //       ? options.find((option) => option.id === getValues(name))?.name ||
        //         ""
        //       : ""
        //   }
        //   onChange={(selectedOption) => setValue(name, selectedOption.value)}
        //   {...props}
        // />
        <Form.Control
          {...register(name, validation)}
          id={id}
          name={name}
          placeholder={placeholder}
          as={type}
          isInvalid={isInvalid}
          // onChange={handleInputChange}
          disabled={props.disabled}
          {...props}
        >
          <option value="" selected>
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </Form.Control>
      ) : type === "textarea" ? (
        <Form.Control
          {...register(name, validation)}
          id={id}
          name={name}
          placeholder={placeholder}
          as={type}
          isInvalid={isInvalid}
          onChange={handleInputChange}
          {...props}
        />
      ) : type === "date" || type === "number" ? (
        <Form.Control
          {...register(name, validation)}
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
          isInvalid={isInvalid}
          onChange={handleInputChange}
          {...props}
        />
      ) : (
        <Form.Control
          {...register(name, validation)}
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
          isInvalid={isInvalid}
          onChange={handleInputChange}
          onBlur={inputBlur ? handleInputBlur : null}
          {...props}
        />
      )}
      {isInvalid && feedbackText && (
        <Form.Control.Feedback type="invalid">
          {feedbackText}
        </Form.Control.Feedback>
      )}
      {text && <Form.Text>{text}</Form.Text>}
    </Form.Group>
  );
};
