import axios from "axios";
import { toastr } from "react-redux-toastr";

export const listDevices = async (projId) => {
  try {
    const response = await axios.get(
      `/v2/energy/listDevices?projId=${projId}&type=energy`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const listDistributors = async () => {
  try {
    const response = await axios.get(`/v2/energy/listDistributors`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const readPlant = async (projId) => {
  try {
    const response = await axios.get(`/v2/energy/readPlant?projId=${projId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const readParamsRefMeter = async (projId, selectDate) => {
  try {
    const response = await axios.get("/v2/energy/readParamsRefMeter", {
      params: {
        projId,
        monthRef: !selectDate ? new Date() : selectDate,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.status;
  }
};

export const readDistributor = async (distributorId) => {
  try {
    const response = await axios.get(
      `/v2/energy/readDistributor?distributorId=${distributorId}`
    );
    return response.data;
  } catch (error) {
    return error.response.status;
  }
};

export const readProjEnergyData = async (
  projId,
  selectDate,
  setLoadingData
) => {
  try {
    setLoadingData(true);
    const response = await axios.get(`/v2/energy/readRefMeter`, {
      params: {
        projId,
        monthRef: !selectDate ? new Date() : selectDate,
      },
    });

    setLoadingData(false);
    return response.data;
  } catch (error) {
    console.error(error);
  } finally {
    setLoadingData(false);
  }
};

export const readBarChartConsumption = async (dvcId, start, end, range) => {
  try {
    const response = await axios.get(
      `/v2/energy/readBarChartConsumptionRefMeter?dvcId=${dvcId}&start=${start}&end=${end}&range=${range}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const readBarChartReactiveConsumed = async (dvcId, start, end, range) => {
  try {
    const response = await axios.get(
      `/v2/energy/readBarChartReactiveConsumedRefMeter?dvcId=${dvcId}&start=${start}&end=${end}&range=${range}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const readBarChartDemand = async (dvcId, start, end, range) => {
  try {
    const response = await axios.get(
      `/v2/energy/readBarChartDemandRefMeter?dvcId=${dvcId}&start=${start}&end=${end}&range=${range}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const readPowerFactorChart = async (dvcId, start, end, inductiveName, capacitiveName, range) => {
  try {
    const response = await axios.get(
      `/v2/energy/readChartPowerFactorRefMeter`,
      {
        params: {
          dvcId,
          start,
          end,
          inductiveName,
          capacitiveName,
          range,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const readReportBill = async (projId, selectDate) => {
  try {
    const response = await axios.get("/v2/energy/readReportBill", {
      params: {
        projId,
        monthRef: !selectDate ? new Date() : selectDate,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const readReportBillBarChart = async (projId, selectDate) => {
  try {
    const response = await axios.get("/v2/energy/readReportBill", {
      params: {
        projId,
        monthRef: !selectDate ? new Date() : selectDate,
        screenshot: "true",
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

//TODO: Converter captura de gráfico para front-end
// export const readReportBillScreenshotDemand = async (projId, selectDate) => {
//   try {
//     const response = await axios.get(
//       "/v2/energy/readReportBillScreenshotDemand",
//       {
//         params: {
//           projId,
//           monthRef: !selectDate ? new Date() : selectDate,
//         },
//       }
//     );

//     return response.data.reportBillScreenshotDemand;
//   } catch (error) {
//     console.error(error);
//   }
// };

//TODO: Converter captura de gráfico para front-end
// export const readReportBillScreenshotConsumption = async (
//   projId,
//   selectDate
// ) => {
//   try {
//     const response = await axios.get(
//       "/v2/energy/readReportBillScreenshotConsumption",
//       {
//         params: {
//           projId,
//           monthRef: !selectDate ? new Date() : selectDate,
//         },
//       }
//     );

//     return response.data.reportBillScreenshotConsumption;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const sendReportBill = async (formData, toggle) => {
  try {
    const email = await axios.post("/v2/energy/sendReportBill", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    toggle();
    toastr.success(email.data.message);
    return email.data;
  } catch (error) {
    console.error(error);
  }
};

export const readDeviceInfo = async (deviceId) => {
  try {
    const { data, status } = await axios.get(`/v2/energy/readDeviceInfo`, {
      params: { deviceId },
    });
    return { data, status };
  } catch (error) {
    console.error(error);
  }
};
