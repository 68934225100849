import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import iGoalLogo from "../../../../../../../assets/img/igoal-logo.png";
import { fetchReadPreviousMonthWaterData } from "../../GasService";
import moment from "moment";

const formattedDate = (date) => {
  const newDate = new Date(date * 1000);
  const processedAtDate =
    newDate.getDate().toString().padStart(2, "0") +
    "/" +
    (newDate.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    newDate.getFullYear() +
    " " +
    newDate.getHours().toString().padStart(2, "0") +
    ":" +
    newDate.getMinutes().toString().padStart(2, "0");
  return processedAtDate;
};

const formatMonthRef = (monthRef) => {
  const [month, year] = monthRef.split("-");
  const lang = localStorage.getItem("defaultLanguage");
  return moment(`${year}-${month}-01`).locale(lang).format("MMM/YY");
}

const ReportPdf = ({ waterData }) => {
  const { t } = useTranslation();
  Font.register({ family: "Roboto", src: "https://fonts.googleapis.com" });
  const logo = iGoalLogo;
  const [dataDashboard, setDataDashboard] = useState([]);

  const searchDashboardData = async () => {
    const { data, status } = await fetchReadPreviousMonthWaterData({
      params: { projectId: waterData.projId, monthRef: waterData.monthRef },
    });
    if (status === 200) setDataDashboard(data);
  };

  const styles = StyleSheet.create({
    page: {
      // display: 'flex',
      // flexDirection: 'column',
      paddingTop: 22,
      paddingHorizontal: 48,
      backgroundColor: "#eef9f9",
    },
    title: {
      fontFamily: "Roboto",
    },
    address: {
      fontSize: 12,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "#3E4676",
      lineHeight: 1.5,
    },
  });

  const styleText = {
    display: "flex",
    fontSize: "10px",
    fontWeight: "light",
    letterSpacing: "0.5",
    marginTop: "4px",
  };

  let maxConsumption = 0;

  dataDashboard?.forEach((item, index) => {
    const diferenca =
      item.consumoMedicao > item.consumoConcessionaria
        ? item.consumoMedicao
        : item.consumoConcessionaria;
    if (diferenca > maxConsumption) {
      maxConsumption = diferenca;
    }
  });

  useEffect(() => {
    searchDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterData]);

  // RENDERIZAÇÃO DO COMPONENTE
  return (
    <Document>
      {/*render a single page*/}
      <Page style={styles.page} size="A4">
        {/* === CABEÇALHO === */}
        <View style={{}}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View>
              <Text style={styleText}>
                {t("solar.processed")}: {formattedDate(waterData?.processedAt)}
              </Text>
              <Text style={styleText}>
                {t("water.period")}:{" "}
                {`${
                  formattedDate(waterData?.timestampInit) ??
                  formattedDate(new Date() / 1000)
                } - ${
                  formattedDate(waterData?.timestampEnd) ??
                  formattedDate(new Date() / 1000)
                }`}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  height: 33,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={logo}
              />
            </View>
          </View>
          <Text
            style={{
              borderBottom: "0.5px solid grey",
              padding: "4px",
              marginBottom: "8px",
            }}
          ></Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View>
              <Text style={styleText}>
                {t("water.companyRead")}:{" "}
                {waterData?.dealerConsumption
                  ? waterData?.dealerConsumption + " m³"
                  : t("none")}
              </Text>
              <Text style={styleText}>
                {t("water.comumArea")}:{" "}
                {waterData?.commonAreaConsumption
                  ? waterData?.commonAreaConsumption + " m³"
                  : t("none")}
              </Text>
              <Text style={styleText}>
                {t("water.meterRead")}:{" "}
                {waterData?.generalConsumption
                  ? waterData?.generalConsumption + " m³"
                  : t("none")}
              </Text>
            </View>
            <View>
              <Text style={styleText}>
                {t("water.companyBudget")}:{" "}
                {waterData?.utilityBill
                  ? "R$ " + waterData?.utilityBill
                  : t("none")}
              </Text>
              <Text style={styleText}>
                {t("water.comumAreaCost")}:{" "}
                {waterData?.countMetering
                  ? "R$ " + waterData?.countMetering
                  : t("none")}
              </Text>
              <Text style={styleText}>
                {t("water.measurementCost")}:{" "}
                {waterData?.countConsumption
                  ? "R$ " + waterData?.countConsumption.toFixed(2)
                  : t("none")}
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={{
            borderBottom: "0.5px solid grey",
            padding: "4px",
            marginBottom: "8px",
          }}
        />

        <Text style={{ ...styleText, fontSize: "10px" }}>
          {t("water.pdf.graphicAnnualConsumption")}
        </Text>

        <View
          style={{
            height: "100px",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            padding: "8px 0px",
          }}
        >
          <View
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Text style={{ ...styleText, fontSize: "8px", textAlign: "right" }}>
              {maxConsumption}
            </Text>
            <Text style={{ ...styleText, fontSize: "8px", textAlign: "right" }}>
              0
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              borderBottom: "0.5px solid grey",
              justifyContent: "center",
              borderLeft: "0.5px solid grey",
              padding: "4px",
            }}
          >
            {/*TODO: Continuar*/}
            {dataDashboard?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        gap: "2px",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          height: `${
                            (item?.consumoMedicao / maxConsumption) * 100
                          }%`,
                          width: "5px",
                          backgroundColor: "lightblue",
                        }}
                      ></View>

                      <View
                        style={{
                          height: `${
                            (item?.consumoConcessionaria / maxConsumption) * 100
                          }%`,
                          width: "5px",
                          backgroundColor: "#3b7ddd",
                        }}
                      ></View>

                      <View
                        style={{
                          display: "flex",
                          height: `${
                            (item?.consumoAreaComum / maxConsumption) * 100
                          }%`,
                          width: "5px",
                          backgroundColor: "green",
                        }}
                      ></View>
                    </View>
                    <Text style={{ ...styleText, fontSize: "8px" }}>
                      {item?.monthRef ? formatMonthRef(item?.monthRef) : ""}
                    </Text>
                  </View>
                </React.Fragment>
              );
            })}
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            padding: "8px 0px",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <View
              style={{
                width: "15px",
                height: "12px",
                backgroundColor: "lightblue",
              }}
            ></View>
            <Text style={{ ...styleText, fontSize: "8px" }}>
              {t("water.readConsumption")}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <View
              style={{
                width: "15px",
                height: "12px",
                backgroundColor: "#3b7ddd",
              }}
            ></View>
            <Text style={{ ...styleText, fontSize: "8px" }}>
              {t("water.companyConsumption")}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <View
              style={{
                width: "15px",
                height: "12px",
                backgroundColor: "green",
              }}
            ></View>
            <Text style={{ ...styleText, fontSize: "8px" }}>
              {t("water.comumArea")}
            </Text>
          </View>
        </View>
        <Text
          style={{
            borderBottom: "0.5px solid grey",
            padding: "4px",
            marginBottom: "8px",
          }}
        />

        <Text style={{ ...styleText, fontSize: "10px" }}>
          {t("water.pdf.currentMonthConsumptionTable")}
        </Text>

        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              borderBottom: "0.5px solid grey",
              padding: "4px",
            }}
          >
            <Text style={{ ...styleText, width: "5%", fontSize: "8px" }}>
              #
            </Text>
            <Text style={{ ...styleText, width: "30%", fontSize: "8px" }}>
              {t("water.consumptionUnit")}
            </Text>
            <Text style={{ ...styleText, width: "25%", fontSize: "8px" }}>
              {t("water.meter")}
            </Text>
            <Text
              style={{
                ...styleText,
                width: "25%",
                fontSize: "8px",
                textAlign: "right",
              }}
            >
              {t("water.previousValue")}
            </Text>
            <Text
              style={{
                ...styleText,
                width: "25%",
                fontSize: "8px",
                textAlign: "right",
              }}
            >
              {t("water.currentValue")}
            </Text>
            <Text
              style={{
                ...styleText,
                width: "25%",
                fontSize: "8px",
                textAlign: "right",
              }}
            >
              {t("water.price")} (R$)
            </Text>
          </View>
          {waterData?.registers?.map((obj, index) => {
            return (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  borderBottom: "0.5px solid grey",
                  padding: "4px",
                }}
              >
                <Text style={{ ...styleText, width: "5%", fontSize: "8px" }}>
                  {index + 1}
                </Text>
                <Text style={{ ...styleText, width: "30%", fontSize: "8px" }}>
                  {obj?.waterucs_description}
                </Text>
                <Text style={{ ...styleText, width: "25%", fontSize: "8px" }}>
                  {obj?.registerNo}
                </Text>
                <Text
                  style={{
                    ...styleText,
                    width: "25%",
                    fontSize: "8px",
                    textAlign: "right",
                  }}
                >
                  {((obj?.leituraAnterior[0]?.consumption * (obj?.gain || 1)) +
                    obj?.leituraInicial).toFixed(2)}
                </Text>
                <Text
                  style={{
                    ...styleText,
                    width: "25%",
                    fontSize: "8px",
                    textAlign: "right",
                  }}
                >
                  {(((obj?.leituraAtual?.consumption || obj?.leituraAnterior[0]?.consumption) * (obj?.gain || 1)) + obj?.leituraInicial).toFixed(2)}
                </Text>
                <Text
                  style={{
                    ...styleText,
                    width: "25%",
                    fontSize: "8px",
                    textAlign: "right",
                  }}
                >
                  {(waterData?.pricem3 * obj.totalConsumption).toFixed(2)}
                </Text>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

const ModalGasReport = ({ open, toggle, data }) => {
  const { t } = useTranslation(); 
  return (
    <Modal isOpen={open} toggle={toggle} size="lg">
      <Form>
        <ModalHeader toggle={toggle}>{t("gasModule.plant.ModalGasReport")}</ModalHeader>

        <ModalBody className="overflow-auto">
          <PDFViewer
            style={{
              width: "100%",
              height: Math.floor(window.innerHeight * 0.67),
            }}
          >
            <ReportPdf waterData={data} />
          </PDFViewer>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            className="mr-1 mb-1"
            onClick={toggle}
            outline
          >
            Cancelar
          </Button>

          <Button type="submit" color="primary" className="mr-1 mb-1">
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ModalGasReport;
