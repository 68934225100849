export const LineText = ({ open, children }) => {
  return (
    <div
      style={{
        overflow: open ? "visible" : "hidden",
        color: "rgb(32, 33, 36)",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        transition: "all 0.3s",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyItems: "space-between",
      }}
    >
      {children}
    </div>
  );
};
