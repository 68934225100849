import {
  faBolt,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { 
  Badge,
  Card, 
  Col, 
  CardHeader, 
  CardTitle, 
  CardBody, 
  CardFooter,
  CardImg, 
  Row 
} from "reactstrap";
import { useConsumerUnitContext } from "./ConsumerUnitProvider";
import { usePlantContext } from "../PlantProvider";
import { BarChartConsumption } from "./components/BarChartConsumption";
import { BarChartDemand } from "./components/BarChartDemand";
import { DonutChartConsumption } from "./components/DonutChartConsumption";
import { PowerFactorChart } from "./components/PowerFactorChart";
import StatusCard from "./components/StatusCard";
import nullImage from "../../../../../assets/devices/noDevice.png";

export const ConsumerUnit = () => {
  const { t } = useTranslation();
  const [windowObservable, setWindowObservable] = useState();

  const { consumerUnit, consumerUnitData, deviceInfo } = useConsumerUnitContext();
  const { params } = usePlantContext();

  useEffect(() => {
      function handleResize() {
          setWindowObservable(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);
      handleResize();

      return () => {
          window.removeEventListener("resize", handleResize);
      };
  }, [setWindowObservable]);

  return (
      <>
          {/* //NOTE: Caso não houver dados de consumo */}
          {(consumerUnitData?.totalPeakConsumption === 0 || consumerUnitData?.totalPeakConsumption === null) &&
          (consumerUnitData?.totalOffPeakConsumption === 0 || consumerUnitData?.totalOffPeakConsumption === null) ? (
              <Card>
                  <CardBody style={{ marginTop: "54px", height: "94px", textAlign: "center" }}>
                      {t('energyModule.noDataMonth')}
                  </CardBody>
              </Card>
          ) : (
              <>
                  {/* //NOTE: Caso houver dados de consumo */}
                  <header>
                      <Row>
                          <Col lg={7}>
                              <Row>
                                  <Col xs="12" md="6" lg="4">
                                      <StatusCard
                                          title={t("cards.monthlyConsumption")}
                                          icon={faBolt}
                                          keyValue2={t("cards.offPeak")}
                                          value2={`${Math.floor(consumerUnitData?.totalOffPeakConsumption ?? 0).toLocaleString()} kWh`}
                                          keyValue={t("cards.peak")}
                                          value={`${Math.floor(consumerUnitData?.totalPeakConsumption ?? 0).toLocaleString()} kWh`}
                                      />
                                  </Col>
                                  <Col xs="12" md="6" lg="4">
                                      <StatusCard
                                          title={t("cards.maxDemand")}
                                          icon={faBolt}
                                          keyValue2={t("cards.offPeak")}
                                          value2={`${(consumerUnitData?.maxOffPeakDemand ?? 0).toLocaleString("pt-br")} kW`}
                                          keyValue={t("cards.peak")}
                                          value={`${(consumerUnitData?.maxPeakDemand ?? 0).toLocaleString("pt-br")} kW`}
                                      />
                                  </Col>
                                  <Col xs="12" md="6" lg="4">
                                      <StatusCard
                                          title={t("cards.powerFactor")}
                                          icon={faBolt}
                                          keyValue={t("cards.avgInductive")}
                                          value={`${(consumerUnitData?.avgPowerFactorInductive ?? 0).toFixed(2).toLocaleString("pt-BR")}`}
                                          keyValue2={t("cards.avgCapacitive")}
                                          value2={`${(consumerUnitData?.avgPowerFactorCapacitive ?? 0).toFixed(2).toLocaleString("pt-BR")}`}
                                      />
                                  </Col>
                              </Row>
                              <Row>
                                <Col lg={12} className="my-2">
                                    <StatusCard
                                        title={t("cards.contract")}
                                        icon={faBuilding}
                                        keyValue={t("cards.distributor")}
                                        value={`${consumerUnitData?.distributor ? consumerUnitData?.distributor : ""}`}
                                        keyValue2={t("cards.flag")}
                                        value2={`${consumerUnitData?.flag ? consumerUnitData?.flag : ""}`}
                                        keyValue3={t("cards.peakContractedDemand")}
                                        value3={`${params?.peakContractedDemand?.toLocaleString("pt-br", {
                                        currency: "BRL",
                                        })} kW`}
                                        keyValue4={t("cards.offPeakContractedDemand")}
                                        value4={`${params?.offPeakContractedDemand?.toLocaleString(
                                        "pt-br",
                                        {
                                            currency: "BRL",
                                        }
                                        )} kW`}
                                    />
                                </Col>
                              </Row>
                          </Col>
                          <Col lg={3}>
                            <Card className="m-0">
                            <CardHeader className="pb-0">
                                <CardTitle tag="h5" className="mb-2 overflow-hidden">
                                {deviceInfo?.description || t("homePlant.none")}
                                </CardTitle>
                                <div className="card-subtitle mb-2 text-muted d-flex overflow-hidden">
                                {deviceInfo?.model || t("homePlant.none")}
                                </div>
                            </CardHeader>
                            <CardBody className="p-0">
                                <CardImg
                                className="p-0"
                                src={deviceInfo?.image ? deviceInfo?.image : nullImage}
                                style={{
                                    width: "150px",
                                    marginLeft: "50%",
                                    transform: "translateX(-50%)",
                                }}
                                />
                            </CardBody>
                            <CardFooter className="pt-0">
                                <div>
                                <Badge
                                    className="badge-pill mr-1 mb-1"
                                    style={{
                                    background:
                                        deviceInfo?.lastComm >=
                                        Math.floor(Date.now() / 1000) - deviceInfo?.commInterval
                                        ? "green"
                                        : "red",
                                    }}
                                >
                                    {deviceInfo?.lastComm >=
                                    Math.floor(Date.now() / 1000) - deviceInfo?.commInterval
                                    ? "Online"
                                    : "Offline"}
                                </Badge>
                                </div>
                                <div style={{ fontSize: "12px" }}>
                                    {t("public.lastComm")}: <br />
                                <span
                                className="display-5 mt-2 mb-4 text-center"
                                style={{ fontSize: "18px", color: "#203A45" }}
                                >
                                {new Date(deviceInfo?.lastComm * 1000).toLocaleString() ||
                                    t("homePlant.none")}
                                </span>
                                </div>
                            </CardFooter>
                            </Card>
                          </Col>
                          <Col lg={2}>
                            <Card>
                                <DonutChartConsumption windowObservable={windowObservable} />
                            </Card>
                          </Col>
                      </Row>
                  </header>
                  <Row>
                      <Col sm="12" lg="5" xl="5.5">
                          <Card>
                              <BarChartConsumption 
                              windowObservable={windowObservable} 
                              dvcId={consumerUnitData?.refMeter} 
                              viewChart={consumerUnit?.viewChart.barChartConsumption}
                              />
                          </Card>
                      </Col>
                      <Col sm="12" lg="7" xl="6.5">
                          <Card>
                              <BarChartDemand 
                              windowObservable={windowObservable} 
                              dvcId={consumerUnitData?.refMeter}
                              viewChart={consumerUnit?.viewChart.barChartDemand} 
                              />
                          </Card>
                      </Col>
                  </Row>
                  <Row>
                      <Col sm="12" md="6">
                          <Card>
                              <PowerFactorChart 
                              windowObservable={windowObservable} 
                              dvcId={consumerUnitData?.refMeter}
                              viewChart={consumerUnit?.viewChart.powerFactor} 
                              />
                          </Card>
                      </Col>
                  </Row>
              </>
          )}
      </>
  );
};
