import React from "react";
import {
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter, 
  Row, Col,
} from "reactstrap";

// /*** PACOTE DE NOTIFICAÇÕES ***/
import { toastr } from "react-redux-toastr";
import axios from "axios";
/*** PACOTE DE TRADUÇÕES ***/
import { useTranslation } from 'react-i18next';

/****** MODAL DE REMOVER DASHBOARD ******/
const ModalDeleteCard = ({open, toggle, cardId, tableReload}) => {
  const { t } = useTranslation ()

  return (
    <Modal 
      isOpen={open}
      toggle={toggle}
    >
      <Form
        onSubmit={e => {
          e.preventDefault(); // evitar erro ao enviar dados com Enter
          /* Verificação do captcha */
          
          if (document.getElementById('captcha').value === "DELETE") {
          //   deleteDash(user.id); // envia solicitação para API
            // axios.post('deleteCard/', JSON.stringify({
            //   id: cardId
            // }))
            axios.delete('deleteCard/', {data: {id: cardId}})
            .then(response => {
              // console.log(tableReload)
              
              tableReload(true); // atualizar visualização da tabela
              toggle(); // fecha o modal
            })
            .catch(err => {
              console.log(err);
              toastr.error('Erro!',"Erro ao deletar Card."); // t() da problema com o useEffect
            });
          } else {
            /* Notificação de captcha incorreto */
            toastr.warning(`${t('dataTable.error')}!`, t('dataTable.captcha'));
            document.getElementById('captcha').value = ''; // Limpa campo de entrada
          }
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('dashboards.deleteCard')}
        </ModalHeader>

        <ModalBody className="text-center m-3">
          <div className="m-sm-4">
            <Row>
              <Col md={1}>
              </Col>
              <Col md={10}>
                {/* <h6>{t('dataTable.att')}!</h6> */}
                <p className="mb-0">
                {t('dashboards.deleteMessageCard')}
                </p>
                {/*** CAMPO DE CAPTCHA ***/}
                <Input 
                  type="text" 
                  name="captcha"
                  id="captcha"
                />
              </Col>
              <Col md={1}>
              </Col>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button 
            color='primary'
            onClick={toggle}
            outline
          >
            {t('dataTable.cancel')}
          </Button>
          {/*** BOTÃO DE DELETAR USUÁRO ***/}
          <Button
            color="primary"
            type="submit"
            
          >
            {t('dashboards.delete')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
};

export default ModalDeleteCard;