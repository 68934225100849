const enUsGatewayTranslations = {
    reports: {
        month1: "January",
        month2: "February",
        month3: "March",
        month4: "April",
        month5: "May",
        month6: "June",
        month7: "July",
        month8: "August",
        month9: "September",
        month10: "October",
        month11: "November",
        month12: "December",
        monthJan: "JAN/",
        monthFev: "FEB/",
        monthMar: "MAR/",
        monthAbr: "APR/",
        monthMai: "MAY/",
        monthJun: "JUN/",
        monthJul: "JUL/",
        monthAgo: "AUG/",
        monthSet: "SEP/",
        monthOut: "OCT/",
        monthNov: "NOV/",
        monthDez: "DEC/",
        deleteEmail: "Delete E-mail",
    },
    page404: {
        notFound: "Page not found",
        removed: "The page you are looking for might have been removed",
        return: "Home Page",
    },
    searchBar: {
        logout: "Sair",
        home: "Home",
        dashboards: "Dashboards",
        dataExplorer: "Data Explorer",
        allCards: "All Cards",
        newCard: "New Card",
        MeusCards: "My Cards",
        projects: "My Plants",
        alarms: "Alarms",
        registers: "Settings",
        settings: "Preferences",
        devices: "Devices",
        alarmView: "Alarm History",
        alarmConfig: "Alarm Parameters",
        usersData: "Companies and Users Registers",
        projectsData: "Plants and Devices Registers",
        alarmsData: "Alarm Parameters Registers",
    },
    gateways: {
        title: "Gateway Overview",
        clickText: "Click on a gateway name to see details about its status",
        expandText1: "Click on",
        expandText2: "to see the peripherical devices linked to each gateway",
        description: "Reference",
        model: "Model",
        serialNumber: "Serial Number",
        projectName: "Project",
        companyName: "Company",
        lastComm: "Last Comm.",
        commInterval: "Comm. Interval",
        actions: "Actions",
        modalTitle: "Status of",
        upTime: "Time since initialization",
        connectedDvcs: "# of devices linked",
        usedStorage: "Total storage memory used",
        usedMem: "Current memory use",
        connMode: "Connection mode",
        MACAddress: "MAC Address",
        gwIP: "Network IP",
        dhcp: "DHCP connection",
        gateway: "Gateway IP",
        subnetMask: "Subnet mask",
        DNS: "DNS IP",
        rssi: "Signal intensity",
        gwVersion: "Firmware version",
        gwHardware: "Hardware version",
        linkSpeed: "Link speed",
        ago: "ago",
        unknown: "Unknown",
        yes: "Yes",
        no: "No",
        noGateway: "No gateway found",
        noPeripherical: "There is no device linked to this gateway",
        noStatus: "The gateway did not publish anything about its status",
        refresh: "Refresh",
        close: "Close",
      },
}

export default enUsGatewayTranslations;