import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import axios from "axios";
import { Search, X } from "react-feather";
// import onSignOutClick from '../onClicks/onSignOutClick';

export const ListPlants = ({ open, setModal }) => {
  const { t } = useTranslation();
  const [projs, setProjs] = useState([]);
  const [filteredProjs, setFilteredProjs] = useState([]);
  const [searchbar, setSearchbar] = useState("");

  useEffect(() => {
    const fetchReadPlants = async () => {
      axios
        .get("/projectUserSearch")
        .then((res) => {
          setProjs(res.data);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);

          if (err.response) {
          } else {
          }
        });
    };

    Promise.all([fetchReadPlants()]);
  }, []);

  useEffect(() => {
    setFilteredProjs(
      projs.filter((item) => {
        return item.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            searchbar
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      })
    );
  }, [searchbar, projs]);

  return (
    <Modal
      isOpen={open}
      toggle={() => setModal(false)}
      className="m-0 radius-0 text-body"
      style={{ width: "250px", height: "100vh" }}
    >
      <ModalBody className="m-0 radius-0" style={{ height: "100vh" }}>
        <Row className="d-flex align-items-center justify-content-between pr-2">
          <ModalHeader tag={"h5"} style={{ textAlign: "center" }}>
            {t("searchBar.projects")}
          </ModalHeader>
          <X
            className="cursor-pointer"
            size={16}
            onClick={() => {
              setModal(false);
            }}
          />
        </Row>
        <div
          className="d-flex align-items-center justify-content-start"
          style={{
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "2px 4px",
            height: "32px",
          }}
        >
          <Search width={16} height={16} />
          <Input
            type="text"
            placeholder={t("projects.searchProjects")}
            aria-label="Search"
            className="form-control-lite text-body"
            value={searchbar}
            onChange={(e) => setSearchbar(e.target.value)}
          />
        </div>
        <div
          style={{
            height: "calc(100vh - 120px)",
            overflow: "auto",
            padding: "16px 0",
          }}
        >
          {filteredProjs?.map((item, i) => {
            return (
              <Link
                to={`/project-${item.id}-default`}
                style={{ textDecoration: "none", color: "inherit" }}
                key={i}
                onClick={(e) => setModal(false)}
              >
                <div
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                    padding: "2px 4px",
                    marginTop: "4px",
                  }}
                >
                  <p className="m-0">{item.name}</p>
                  <p
                    className="text-black-50"
                    style={{
                      maxWidth: "180px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.description}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
      </ModalBody>
    </Modal>
  );
};
