import { PRESET, useWindowDimensions } from "../../../igDashboards/view/hooks/useWindowDimensions";

const MIN_HEIGHT = 120;

const useChartHeight = () => {
  const{height, preset} =  useWindowDimensions();
  
  if(preset === PRESET.MOBILE){
    return 250
  }
  const TOP = 280;
  const CARD_HEADER = 90;
  const MARGIN = height <= 900 ? 100 : 180;
  const heightWithoutTopBar = (height - TOP - CARD_HEADER - MARGIN) / 2;

  



  return heightWithoutTopBar < MIN_HEIGHT ? MIN_HEIGHT : heightWithoutTopBar;
};

export default useChartHeight;