import { faPen, faPlus, faSort, faSortDown, faSortUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import moment from 'moment';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { sendReportBill } from './../../../../RefMeterService';


const ModalPdfBillEmail = ({
  open,
  toggle,
  pdfBlob,
  projectName
}) => {
  const [emailList, setEmailList] = useState([])
  const [inputMail, setInputMail] = useState("")
  const [inputEditMail, setInputEditMail] = useState("")
  const [showEditEmailInput, setShowEditEmailInput] = useState("none")
  const [idMail, setIdMail] = useState(0);
  const [idEditMail, setIdEditMail] = useState(null);
  const [disableSendButton, setDisableSendButton] = useState(false)
  const { t } = useTranslation();

  function handleAddNewEmail(email) {
    let re = /\S+@\S+\.\S+/;
    let validateMail = re.test(email)

    let objMail = {
      id: idMail,
      email: email,
    };

    if (validateMail === false) {
      toastr.warning(t("reportsEnergy.errors.invalidEmail.title"), t("reportsEnergy.errors.invalidEmail.msg"))
    } else if (emailList.some(emailIsEqual => emailIsEqual.email === inputMail)) {
      toastr.warning(t("reportsEnergy.errors.emailAdded.title"), t("reportsEnergy.errors.emailAdded.msg"))
    } else {
      setEmailList([...emailList, objMail])
      setIdMail(idMail + 1);
      setInputMail("")
    }
  }

  function handleEditEmail(row) {
    setShowEditEmailInput("unset");
    const findIndex = emailList.findIndex(item =>
      item.id === row.id
    )
    setIdEditMail(findIndex)
    setInputEditMail(emailList[findIndex].email);
  }

  function handleSubmitEditEmail(email) {
    let re = /\S+@\S+\.\S+/;
    let validateMail = re.test(email);

    if (validateMail === false) {
      toastr.warning(t("reportsEnergy.errors.invalidEmail.title"), t("reportsEnergy.errors.invalidEmail.msg"));
    } else if (emailList.some(emailIsEqual => emailIsEqual.email === inputEditMail)) {
      toastr.warning(t("reportsEnergy.errors.emailAdded.title"), t("reportsEnergy.errors.emailAdded.msg"));
    } else {
      const newUpdatedEmailList = emailList.map(item => {
        if (item.id === idEditMail) {
          return { ...item, email: email }
        } else {
          return item;
        }
      })
      setEmailList(newUpdatedEmailList);
      setShowEditEmailInput("none");
    }
  }

  function handleDeleteMail(row) {

    const findIndex = emailList.find(item =>
      item.id === row.id
    )
    setIdEditMail(findIndex);
    setEmailList(emailList.filter(item => item.id !== row.id))
  }

  function handleSendMail() {
    if (emailList.length > 0) {
      sendPdfBill();
    } else {
      toastr.warning(t("reportsEnergy.errors.emptyEmail.title"), t("reportsEnergy.errors.emptyEmail.msg"));
    }
  }

  function sendPdfBill() {
    const formData = new FormData();
    formData.append('pdfDocument', new Blob([pdfBlob], { type: 'application/pdf' }));
    formData.append('pdfName', projectName + ' - ' + moment().format('MM-YYYY'));
    formData.append('emailList', JSON.stringify(emailList));

    setDisableSendButton(true);
    sendReportBill(formData, toggle);
    setDisableSendButton(false);
  }
  
  const emailCols = [
    {
      dataField: "id",
      text: t('projTable.id'),
      sort: true,
      hidden: true,
      isKey: true
    },
    {
      dataField: "email",
      text: "E-Mail",
      sort: true
    },
    {
      dataField: "actions",
      text: t('reportsEnergy.actions'),
      editable: false,
      headerStyle: { width: "11%" },
      formatter: (_, row) => (
        <div className="d-flex justify-content-left">
          <div // editar
            id={"editGain" + row.id}
            className="align-middle cursor-pointer"
            onClick={() => handleEditEmail(row)}
          >

            <FontAwesomeIcon icon={faPen} fixedWidth />
            {/* Tooltip */}
            <UncontrolledTooltip
              placement="top"
              target={"editGain" + row.id}
            >
              {t('reportsEnergy.edit')}
            </UncontrolledTooltip>
          </div>

          <div
            id={"removeGain" + row.id}
            className="align-middle cursor-pointer"
            onClick={() => handleDeleteMail(row)}
          >
            <FontAwesomeIcon icon={faTrash} fixedWidth />

            <UncontrolledTooltip
              placement="top"
              target={"removeGain" + row.id}
            >
              {t('reportsEnergy.delete')}
            </UncontrolledTooltip>
          </div>
        </div>
      )
    }
  ];

  const sortIcon = {
    sortCaret: (order) => (!order)
      ? <FontAwesomeIcon
        className={'fa-xs ml-1'}
        icon={faSort}
      />
      : (order === 'asc')
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faSortUp}
        />
        : <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faSortDown}
        />
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      scrollable
    >
      <ModalHeader>
        {t("reportsEnergy.sendEmail")}
      </ModalHeader>
      <ModalBody
        style={{
          display: "flex",
          flexDirection: "column",
          paddingInline: "1rem",
          marginInline: 16
        }}>
        <Row>
          <Col className="d-flex align-items-center justify-content-center mb-2">
            <Label>{t("reportsEnergy.sendEmailText")}</Label>
          </Col>
        </Row>
        <Row style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 8,
        }}>
          <Input
            style={{ width: "12rem", marginRight: "1rem" }}
            placeholder={t("reportsEnergy.inputPlaceholderEmail")}
            value={inputMail}
            onChange={e => setInputMail(e.target.value)}
            type="email"
          />
          <Button color="primary" onClick={() => handleAddNewEmail(inputMail)}>
            <FontAwesomeIcon fontSize={2} fixedWidth icon={faPlus} />
          </Button>
        </Row>
        <div style={{
          display: showEditEmailInput
        }}>
          <Row>
            <Col className="d-flex align-items-center justify-content-center mb-2">
              <Label>{t('reportsEnergy.sendEmailEditText')}</Label>
            </Col>
          </Row>
          <Row style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingInline: 12,
          }}>
            <Input
              style={{ width: "12rem", marginRight: "1rem" }}
              placeholder={t("reportsEnergy.inputPlaceholderEmail")}
              value={inputEditMail}
              onChange={e => setInputEditMail(e.target.value)}
              type="email"
            />
            <Button color="primary" onClick={() => handleSubmitEditEmail(inputEditMail)}>
              {/* <FontAwesomeIcon fontSize={2} fixedWidth icon={faPlus} /> */}Confirmar
            </Button>
          </Row>
        </div>

        <hr style={{ color: "#000", height: 16 }} />

        <BootstrapTable
          bootstrap4
          keyField="id"
          data={emailList}
          columns={emailCols}
          condensed
          bordered={false}
          striped={true}
          sort={sortIcon}
        />

      </ModalBody>
      <ModalFooter>

        <Button
          color='primary'
          className="mb-1"
          onClick={toggle}
          outline

        >
          {t("reportsEnergy.closeButton")}
        </Button>
        <Button
          color='primary'
          className="mb-1"
          onClick={handleSendMail}
          disabled={disableSendButton}
          style={{ minWidth: 70 }}
        >
          {(disableSendButton === true) ? <Spinner size={"sm"} /> : t("reportsEnergy.sendButton")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default ModalPdfBillEmail;