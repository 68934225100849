import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import nobreakDiagram from "../../../assets/devices/nobreakDiagram.png";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useWindowDimensions } from "../../igDashboards/view/hooks/useWindowDimensions";
import GenericStateCard from "./GenericStateCard";
import ActivePower from "./solarModule/ModalDevice/components/ActivePower";
import CurrentCards from "./solarModule/ModalDevice/components/CurrentCards";
import CurrentFaseA from "./solarModule/ModalDevice/components/CurrentFaseA";
import CurrentFaseB from "./solarModule/ModalDevice/components/CurrentFaseB";
import CurrentFaseC from "./solarModule/ModalDevice/components/CurrentFaseC";
import EnergyDaily from "./solarModule/ModalDevice/components/EnergyDaily";
import Frequency from "./solarModule/ModalDevice/components/Frequency";
import LastComm from "./solarModule/ModalDevice/components/LastComm";
import LifetimeEnergy from "./solarModule/ModalDevice/components/LifetimeEnergy";
import PowerFactory from "./solarModule/ModalDevice/components/PowerFactory";
import PVCards from "./solarModule/ModalDevice/components/PVCards";
import Temperature from "./solarModule/ModalDevice/components/Temperature";
import VoltageFaseA from "./solarModule/ModalDevice/components/VoltageFaseA";
import VoltageFaseB from "./solarModule/ModalDevice/components/VoltageFaseB";
import VoltageFaseC from "./solarModule/ModalDevice/components/VoltageFaseC";

/**
 * @typedef {object} Device
 * @property {string} projectName - The name of the project the device belongs to.
 * @property {number} id - The unique identifier of the device.
 * @property {string} model - The model of the device (e.g., "IG9K").
 * @property {string} description - A description of the device.
 * @property {number} lastComm - Timestamp of the last communication with the device (likely Unix epoch time).
 * @property {number} commInterval - Communication interval in seconds.
 * @property {(string|null)} image - A URL or path to an image representing the device, or null if no image is available.
 */

/**
 * Modal component to display and potentially manage device variables.
 *
 * @param {object} props - Component properties.
 * @param {boolean} props.open - Flag indicating whether the modal is open.
 * @param {Device} props.device - The device object.
 * @param {function} props.toggle - Function to toggle the modal's visibility.
 * @param {array} props.deviceVars - Array of device variables.  Specific structure not defined.
 * @param {array} props.deviceNobreakVars - Array of device no-break variables.  Specific structure not defined. Defaults to an empty array.
 */
const ModalDeviceVar = ({
  open,
  device,
  toggle,
  deviceVars,
  deviceNobreakVars = [],
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  // Cria o CancelToken ao abrir o modal
  useEffect(() => {
    if (open) {
      const tokenSource = axios.CancelToken.source();
      setCancelTokenSource(tokenSource);

      return () => {
        tokenSource.cancel("Modal fechado, requisição cancelada.");
      };
    }
  }, [open]);

  const {
    data: dataGet,
    error,
    status,
  } = useQuery({
    queryKey: ["readDataLast", device.id],
    queryFn: async () => {
      try {
        const response = await axios.get("/readDataLast", {
          params: {
            idDevice: device.id,
          },
          cancelToken: cancelTokenSource.token,
        });
        return response.data;
      } catch (error) {
        if (error.response) {
          throw error.response.data;
        } else {
          throw error.message;
        }
      }
    },
    refetchInterval: 1000 * 60, // 1 minuto
    enabled: !!cancelTokenSource,
  });

  const loading = status === "loading";

  if (!dataGet && loading) {
    return (
      <Modal
        isOpen={open}
        toggle={toggle}
        className={`modal-dialog`}
        size="xl"
        style={{ marginTop: "10px", marginBottom: "-4px" }}
      >
        <ModalBody style={{ paddingTop: "0px" }}>
          <CardBody
            style={{ marginTop: "54px", height: "94px", textAlign: "center" }}
          >
            <Spinner color="primary" />
          </CardBody>
        </ModalBody>
      </Modal>
    );
  }

  if (status === "error" && !dataGet) {
    return (
      <Modal
        isOpen={open}
        toggle={toggle}
        className={`modal-dialog`}
        size="xl"
        style={{ marginTop: "10px", marginBottom: "-4px" }}
      >
        <ModalHeader
          toggle={toggle}
          style={{
            paddingLeft: "32px",
            borderBottom: "none",
            paddingBottom: "6px",
            paddingTop: "10px",
          }}
        >
          {device}
        </ModalHeader>
        <ModalBody style={{ paddingTop: "0px" }}>
          <CardBody
            style={{ marginTop: "54px", height: "94px", textAlign: "center" }}
          >
            <b>{error.message ?? t("solar.noDataPlants")}</b>
          </CardBody>
        </ModalBody>
      </Modal>
    );
  }

  const cardStyleTop = {
    position: "absolute",
    top: 5,
    height: "140px",
    width: "240px",
    marginBottom: "10px",
    background: "#dde0e5",
    opacity: "0.8",
    textAlign: "center",
    overflowY: "scroll",
    left: {
      position: "absolute",
      top: 20,
      left: 20,
      height: "140px",
      width: "300px",
      marginBottom: "10px",
      background: "#dde0e5",
      opacity: "0.8",
      textAlign: "center",
      overflowY: "scroll",
    },
    right: {
      position: "absolute",
      top: 20,
      right: 20,
      height: "140px",
      width: "300px",
      marginBottom: "10px",
      background: "#dde0e5",
      opacity: "0.8",
      textAlign: "center",
      overflowY: "scroll",
    },
  };

  const cardStyleRight = {
    position: "absolute",
    right: 20,
    height: "140px",
    width: "240px",
    marginBottom: "10px",
    background: "#dde0e5",
    opacity: "0.8",
    textAlign: "center",
    overflowY: "scroll",
  };

  const cardStyleBottom = {
    position: "absolute",
    bottom: 0,
    height: "140px",
    width: "300px",
    marginBottom: "10px",
    background: "#dde0e5",
    opacity: "0.8",
    textAlign: "center",
    overflowY: "scroll",
    right: {
      position: "absolute",
      bottom: 0,
      right: 20,
      height: "140px",
      width: "240px",
      marginBottom: "10px",
      background: "#dde0e5",
      opacity: "0.8",
      textAlign: "center",
      overflowY: "scroll",
    },
  };

  const cardStyleLeft = {
    position: "absolute",
    left: 20,
    height: "140px",
    width: "240px",
    marginBottom: "10px",
    background: "#dde0e5",
    opacity: "0.8",
    textAlign: "center",
    overflowY: "scroll",
  };

  const modalStyle = {
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "32rem",
  };

  const mobileModalStyle = {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    background: "#dde0e5",
    justifyContent: "space-between",
    textAlign: "center",
    opacity: "0.8",
    height: "10rem",
    width: "60%",
    overflowY: "scroll",
    inverter: {
      display: "flex",
      // flexDirection: "column",
      flexWrap: "wrap",
      // background: "#dde0e5",
      gap: "1rem",
      // textAlign: "center",
      opacity: "0.8",
      // height: "10rem",
      // width: "60%",
      overflowY: "scroll",
    },
  };

  const colStyle = {
    display: "flex",
    width: 250,
    height: 140,
    justifyContent: "space-between",
    fontWeight: "500",
    labelStyle: {
      marginBottom: 0,
    },
  };

  const cardTitleStyle = {
    fontSize: 13,
    fontStyle: "normal",
    marginTop: 8,
  };

  console.log('DEVICE: ', device);

  return dataGet && deviceVars.length > 0 ? (
    device.model === "100KTL-H1" ||
    device.model === "185KTL-H0" ||
    device.model === "215KTL-H0" ? (
      <Modal
        isOpen={open}
        toggle={toggle}
        className={`modal-dialog`}
        size="xl"
        style={{ marginTop: "10px", marginBottom: "-4px" }}
      >
        <ModalHeader
          toggle={toggle}
          style={{
            paddingLeft: "32px",
            borderBottom: "none",
            paddingBottom: "6px",
            paddingTop: "10px",
          }}
        >
          {device.description}
        </ModalHeader>
        <ModalBody style={{ paddingTop: "0px" }}>
          <div
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              paddingTop: "0px",
              marginBottom: "0px",
            }}
          >
            <Row sm="12" lg="12" style={{ paddingRight: "20px" }}>
              <Col>
                {deviceVars.map((card) =>
                  card.S === "S" ? (
                    <LastComm
                      key={card.tag}
                      state={card.tagType === "S" ? dataGet.color : null}
                    />
                  ) : null
                )}
              </Col>
              <Col>
                <GenericStateCard
                  width={"100%"}
                  cardTitle={t("devices.modalLastComm")}
                  ProcessedAt={dataGet.lastComm}
                  icon={faDatabase}
                />
              </Col>
              <Col>
                {deviceVars.map((card) =>
                  card.varType === "P" ? (
                    <ActivePower
                      key={card.tag}
                      allData={dataGet}
                      ActivePower={dataGet.activePower}
                    />
                  ) : null
                )}
              </Col>
              <Col>
                {deviceVars.map((card) =>
                  card.varType === "P" ? (
                    <EnergyDaily
                      key={card.tag}
                      allData={dataGet}
                      EnergyDaily={dataGet.dailyEnergy}
                    />
                  ) : null
                )}
              </Col>
              <Col>
                {deviceVars.map((card) =>
                  card.varType === "E" ? (
                    <LifetimeEnergy
                      key={card.tag}
                      allData={dataGet}
                      LifetimeEnergy={dataGet.totalEnergy}
                    />
                  ) : null
                )}
              </Col>
              <Col>
                {deviceVars.map((card) =>
                  card.varType === "E" ? (
                    <Frequency
                      key={card.tag}
                      allData={dataGet}
                      data={dataGet}
                    />
                  ) : null
                )}
              </Col>
              <Col>
                {deviceVars.map((card) =>
                  card.tagType === "F" ? (
                    <Temperature
                      key={card.tag}
                      allData={dataGet}
                      data={dataGet}
                    />
                  ) : null
                )}
              </Col>
              <Col>
                {deviceVars.map((card) =>
                  card.tagType === "T" ? (
                    <PowerFactory
                      key={card.tag}
                      allData={dataGet}
                      data={dataGet}
                    />
                  ) : null
                )}
              </Col>
            </Row>
            <Row
              style={{
                paddingLeft: "10px",
                justifyContent: "space-between",
                paddingRight: "30px",
                paddingTop: "15px",
              }}
            >
              <div>
                <CardHeader
                  style={{
                    paddingLeft: "1px",
                    paddingTop: "0px",
                    paddingBottom: "2px",
                  }}
                >
                  <h5>{t("solar.gridVoltage")}</h5>
                </CardHeader>
                <Row>
                  <Col>
                    <VoltageFaseA
                      allData={dataGet}
                      VoltageFaseA={dataGet.A_V}
                    />
                  </Col>
                  <Col>
                    <VoltageFaseB
                      allData={dataGet}
                      VoltageFaseB={dataGet.B_V}
                    />
                  </Col>
                  <Col>
                    <VoltageFaseC
                      allData={dataGet}
                      VoltageFaseC={dataGet.C_V}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <CardHeader
                  style={{
                    paddingLeft: "0px",
                    paddingTop: "0px",
                    paddingBottom: "2px",
                  }}
                >
                  <h5>{t("solar.gridCurrent")}</h5>
                </CardHeader>
                <Row>
                  <Col>
                    <CurrentFaseA
                      allData={dataGet}
                      CurrentFaseA={dataGet.A_Curr}
                    />
                  </Col>
                  <Col>
                    <CurrentFaseB
                      allData={dataGet}
                      CurrentFaseB={dataGet.B_Curr}
                    />
                  </Col>
                  <Col>
                    <CurrentFaseC
                      allData={dataGet}
                      CurrentFaseC={dataGet.C_Curr}
                    />
                  </Col>
                </Row>
              </div>
            </Row>
            {dataGet ? (
              <div>
                <CardHeader
                  className="headerdeviceModal"
                  style={{
                    paddingTop: "0px",
                    paddingLeft: "27px",
                    top: "8px",
                    width: "271px",
                    position: "relative",
                    marginLeft: "-120px",
                  }}
                >
                  <h6>{t("solar.inputVoltage")}</h6>
                </CardHeader>
                <Row
                  id="larguraDiv"
                  className="ROW NO MODAL PV styleScroll"
                  style={{
                    overflowX: "scroll",
                    marginRight: "5px",
                    marginLeft: "28px",
                    marginTop: "-25px",
                  }}
                >
                  <PVCards data={dataGet} />
                </Row>
                <CardHeader
                  className="headerdeviceModal"
                  style={{
                    paddingTop: "0px",
                    paddingLeft: "10px",
                    height: "37px",
                    top: "14px",
                    width: "271px",
                    position: "relative",
                    marginLeft: "-120px",
                  }}
                >
                  <h6>{t("solar.inputCurrent")}</h6>
                </CardHeader>
                <Row
                  className="ROW NO MODAL PC"
                  style={{
                    overflowX: "scroll",
                    marginRight: "5px",
                    marginLeft: "28px",
                    marginTop: "-10px",
                    paddingBottom: "10px",
                  }}
                >
                  <CurrentCards data={dataGet} />
                </Row>
              </div>
            ) : (
              <CardBody className="text-center"> {t("solar.noData")} </CardBody>
            )}
          </div>
        </ModalBody>
      </Modal>
    ) : deviceNobreakVars?.modelType === "NB" ? (
      <Modal isOpen={open} toggle={toggle} scrollable size={"lg"}>
        <ModalHeader>
          {t("devices.variablesOf")} {device.description}
        </ModalHeader>
        <ModalBody
          style={modalStyle}
          className="d-flex overflow-scroll mb-0 pb-0"
        >
          <img
            style={
              width > 995
                ? {
                    position: "absolute",
                    width: "30rem",
                  }
                : {
                    display: "none",
                    position: "fixed",
                    width: "30rem",
                  }
            }
            src={nobreakDiagram}
            alt="Foto do diagrama"
          />
          <>
            {/* CARD TOPO ESQUERDA */}
            <Card style={width > 995 ? cardStyleTop.left : mobileModalStyle}>
              {/* <CardTitle tag="h5"
                  style={cardTitleStyle}>
                  {device.model}
                </CardTitle> */}
              <CardBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {deviceVars.map((dvcVar) => (
                      <div key={dvcVar.tag}>
                        {dvcVar.tagType === "I" &&
                        dataGet[dvcVar.tag] !== undefined ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Label style={colStyle.labelStyle} tag="h5">
                              {dvcVar.tagType === "I" ? dvcVar.varName : null}
                            </Label>
                            <Label>
                              {dvcVar.tagType === "I"
                                ? dataGet[dvcVar.tag]
                                : null}{" "}
                              {dvcVar.tagType === "I" ? dvcVar.unit : null}
                            </Label>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {/* CARD CENTRO TOPO */}
            <Card style={width > 995 ? cardStyleTop : mobileModalStyle}>
              <CardTitle tag="h5" style={cardTitleStyle}>
                {device.model}
              </CardTitle>
              <CardBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {deviceVars.map((dvcVar) => (
                      <div key={dvcVar.tag}>
                        {dvcVar.tagType === "OLoad" &&
                        dataGet[dvcVar.tag] !== undefined ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Label style={colStyle.labelStyle} tag="h5">
                              {dvcVar.tagType === "OLoad"
                                ? dvcVar.varName
                                : null}
                            </Label>
                            <Label>
                              {dvcVar.tagType === "OLoad"
                                ? dataGet[dvcVar.tag]
                                : null}{" "}
                              {dvcVar.tagType === "OLoad" ? dvcVar.unit : null}
                            </Label>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {/* CARD TOPO DIREITA */}
            <Card style={width > 995 ? cardStyleTop.right : mobileModalStyle}>
              {/* <CardTitle tag="h5"
                  style={cardTitleStyle}>
                  {device.model}
                </CardTitle> */}
              <CardBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {deviceVars.map((dvcVar) => (
                      <div key={dvcVar.tag}>
                        {dvcVar.tagType === "O" &&
                        dataGet[dvcVar.tag] !== undefined ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Label style={colStyle.labelStyle} tag="h5">
                              {dvcVar.tagType === "O" ? dvcVar.varName : null}
                            </Label>
                            <Label>
                              {dvcVar.tagType === "O"
                                ? dataGet[dvcVar.tag]
                                : null}{" "}
                              {dvcVar.tagType === "O" ? dvcVar.unit : null}
                            </Label>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {/* CARD DIREITA */}
            <Card style={width > 995 ? cardStyleRight : mobileModalStyle}>
              <CardTitle tag="h5" style={cardTitleStyle}>
                {t("devices.outputVoltage")}
              </CardTitle>
              <CardBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {deviceVars.map((dvcVar) => (
                      <div key={dvcVar.tag}>
                        {dvcVar.tagType === "OV" &&
                        dataGet[dvcVar.tag] !== undefined ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Label style={colStyle.labelStyle} tag="h5">
                              {dvcVar.tagType === "OV" ? dvcVar.varName : null}
                            </Label>
                            <Label>
                              {dvcVar.tagType === "OV"
                                ? dataGet[dvcVar.tag]
                                : null}{" "}
                              {dvcVar.tagType === "OV" ? dvcVar.unit : null}
                            </Label>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {/* CARD CENTRO EMBAIXO */}
            <Card style={width > 995 ? cardStyleBottom : mobileModalStyle}>
              <CardTitle tag="h5" style={cardTitleStyle}>
                {t("devices.batery")}
              </CardTitle>
              <CardBody>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {deviceVars.map((dvcVar) => (
                      <div key={dvcVar.tag}>
                        {dvcVar.tagType === "B" &&
                        dataGet[dvcVar.tag] !== undefined ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Label style={colStyle.labelStyle} tag="h5">
                              {dvcVar.tagType === "B" ? dvcVar.varName : null}
                            </Label>
                            <Label>
                              {dvcVar.tagType === "B"
                                ? dataGet[dvcVar.tag]
                                : null}{" "}
                              {dvcVar.tagType === "B" ? dvcVar.unit : null}
                            </Label>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {/* CARD EMBAIXO DIREITA */}
            {deviceVars.map((dvcVar) =>
              dvcVar.tagType === undefined ? (
                <Card
                  key={dvcVar.tag}
                  style={width > 995 ? cardStyleBottom.right : mobileModalStyle}
                >
                  <CardTitle tag="h5" style={cardTitleStyle}>
                    {t("devices.others")}
                  </CardTitle>
                  <CardBody>
                    <Row>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          {dvcVar.tagType === undefined ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Label style={colStyle.labelStyle} tag="h5">
                                {dvcVar.tagType === undefined
                                  ? dvcVar.varName
                                  : null}
                              </Label>
                              <Label>
                                {dvcVar.tagType === undefined
                                  ? dataGet[dvcVar.tag]
                                  : null}{" "}
                                {dvcVar.tagType === undefined
                                  ? dvcVar.unit
                                  : null}
                              </Label>
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ) : null
            )}
            {/* CARD DA ESQUERDA */}
            <Card style={width > 995 ? cardStyleLeft : mobileModalStyle}>
              <CardTitle tag="h5" style={cardTitleStyle}>
                {t("devices.inputVoltage")}
              </CardTitle>
              <CardBody>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {deviceVars.map((dvcVar) => (
                      <div key={dvcVar.tag}>
                        {dvcVar.tagType === "IV" &&
                        dataGet[dvcVar.tag] !== undefined ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Label style={colStyle.labelStyle} tag="h5">
                              {dvcVar.tagType === "IV" ? dvcVar.varName : null}
                            </Label>
                            <Label>
                              {dvcVar.tagType === "IV"
                                ? dataGet[dvcVar.tag]
                                : null}{" "}
                              {dvcVar.tagType === "IV" ? dvcVar.unit : null}
                            </Label>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        </ModalBody>

        <ModalFooter>
          {/* <Button
              color="primary"
              outline
            // disabled={loading}
            // onClick={() => setLoading(true)}
            >
              {t('gateways.refresh')}
            </Button> */}

          <Button color="primary" onClick={toggle}>
            {t("gateways.close")}
          </Button>
        </ModalFooter>
      </Modal>
    ) : (
      // SE O MODAL NÃO TIVER NENHUM DIAGRAMA MOSTRA AS VARIÁVEIS
      <Modal isOpen={open} toggle={toggle} scrollable size={"lg"}>
        <ModalHeader>{t("devices.variablesOf")} {device.description}</ModalHeader>

        <ModalBody
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1rem",
          }}
          className="d-flex  mb-0 pb-0"
        >
          {deviceVars.map((dvcVar) => (
            <Card
              key={dvcVar.tag}
              style={{
                minHeight: "100px",
                minWidth: "200px",
                maxHeight: "100px",
                marginBottom: "10px",
                background: "#dde0e5",
              }}
            >
              <CardBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <CardTitle tag="h5" style={cardTitleStyle}>
                  {dvcVar.varName}
                </CardTitle>
                <Label
                  tag="h1"
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "1.2",
                  }}
                >
                  {dataGet[dvcVar.tag] ||
                  dataGet[dvcVar.tag] === 0 ||
                  dataGet[dvcVar.tag] === false ? (
                    dataGet[dvcVar.tag].toString()
                  ) : (
                    <p>Não há dados</p>
                  )}
                  {dataGet[dvcVar.tag] ? dvcVar.unit : null}
                </Label>
                <Row>
                  <Col style={colStyle}>
                    <Label></Label>
                    <Label></Label>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))}
        </ModalBody>

        <ModalFooter>
          {/* <Button
          color="primary"
          outline
          // disabled={loading}
          // onClick={() => setLoading(true)}
        >
          {t('gateways.refresh')}
        </Button> */}

          <Button color="primary" onClick={toggle}>
            {t("gateways.close")}
          </Button>
        </ModalFooter>
      </Modal>
    )
  ) : null;
};

export default ModalDeviceVar;
