export const LineIcon = ({
  width = "20px",
  height = "20px",
  marginRight = "18px",
  children,
}) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        marginRight: marginRight,
        display: "flex",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};
