import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import '../../igCards';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card, CardBody, CardHeader, CardTitle,
  Form, FormGroup, Input, Label,
  Row, Col, Spinner
} from "reactstrap";
import axios from "axios";
import { toastr } from "react-redux-toastr";
// import Statistics from "../cards/StatisticsPreview";
// import StatisticsState from "../cards/StatisticsState";
// import StatisticsPreview from "../cards/StatisticsPreview";
import FormulaMath from "../cards/FormulaMath";
import ModalAddFormula from "./ModalAddFormula";
import FormulaMathPreview from "../cards/FormulaMathPreview";

const NewFormulaCard = ({ cardType, cardProps }) => {
  const { t } = useTranslation();
  let stringMath = require("string-math");

  let parsedFormula = (cardProps?.id) ? JSON.parse(cardProps?.formula) : "";
  
  const [isLoading, setLoading] = useState(true); // carregando projetos
  const [isEditLoading, setEditLoading] = useState(false); // salvando edição
  const [projList, setProjList] = useState([]); // lista de projetos
  const [dvcList, setDvcList] = useState([]); // lista de devices do projeto selecionado
  const [varList, setVarList] = useState([]); // lista de variaveis do device selecionado
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const [name, setName] = useState(cardProps?.id ? cardProps?.name : ""); // campo de nome
  const [description, setDescription] = useState(cardProps?.id ? cardProps?.description : "") // campo de descricao
  const [deviceId, setDeviceId] = useState(""); // device selecionado
  const [variable, setVariable] = useState(""); // variavel selecionada
  const [reloadTime, setReloadTime] = useState(cardProps?.id ? cardProps.reloadTime : ""); // periodo selecionado para reload
  const [decimalNumber, setDecimalNumber] = useState(cardProps?.id ? cardProps?.decimalNumber : "");
  const [labelUnit, setLabelUnit] = useState(cardProps?.id ? parsedFormula.unit : "")
  const [mathFormulaInput, setMathFormulaInput] = useState("");
  const [formulaInputToApi, setFormulaInputToApi] = useState("");
  
  const [resultToPreview, setResultToPreview] = useState("");
  // const [varValueSelected, setVarValueSelected] = useState("")
  const [variableListIfExists, setVariableListIfExists] = useState([]); // variavel selecionada
  const [deviceData, setdeviceData] = useState([]);
  const [dataLoading, setdataLoading] = useState(true);
  const [cardDataToEdit, setCardDataToEdit] = useState();
  
  const varTag = (variable) ? JSON.parse(variable).tag : "";

  let history = useHistory();

  function toggleModalAddFormula() {
    setIsModalOpen(!isModalOpen);
  }

  function onProjectChange(projId) {
    setDvcList([]); // limpa lista de dispositivos
    setVarList([]); // limpa lista de variaveis
    setDeviceId(""); // limpa dispositivo selecionado
    setVariable(""); // limpa variavel selecionada

    setLoading(true);
    axios.get('/deviceSearch', {
      params: {
        projId: projId
      }
    })
      .then(response => {

        setDvcList(response.data) // atualiza lista de dispositivos
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar dispositivos.");  // t() da problema com o useEffect
        setLoading(false);
      })
  };

  function onDeviceChange(dvcId) {
    setVarList([]); // limpa lista de variaveis
    setVariable(""); // limpa variavel selecionada

    setDeviceId(dvcId); // atualiza dispositivo selecionado
    setLoading(true);
    axios.get('/readVariables', {
      params: {
        id: dvcId
      }
    })
      .then(response => {
        // console.log('RESPONSIVE', response);
        setVarList(response.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar variáveis.");  // t() da problema com o useEffect
        setLoading(false);
      });
  };

  function handAddNewVariable(selectedVariable) {

    let parsedVariable = JSON.parse(selectedVariable);
    let dvcId = deviceId.split(".")[0]
    let deviceName = deviceId.split(".")[1]

    parsedVariable = { ...parsedVariable, dvcId: dvcId, deviceName: deviceName };

    setVariableListIfExists([...variableListIfExists, parsedVariable]);
  }

  function handleDeleteVariable(itemTag) {
    setVariableListIfExists(variableListIfExists.filter(deleted => deleted.tag !== itemTag));
  }
  
  async function handleAddFormula() {

    let selectedVariables = mathFormulaInput
    
    variableListIfExists.forEach(variable => {
      let patternToReplace = `${variable.deviceName}.${variable.varName}`;
    
      selectedVariables.search(patternToReplace);
      selectedVariables = selectedVariables.replace(patternToReplace, `${variable.dvcId}.${variable.varName}`);
    
    });

    setFormulaInputToApi(selectedVariables)
    setIsModalOpen(false);
    
  }

  async function onSubmitForm() {
    setEditLoading(true);
    setTimeout(() => {
      setEditLoading(false)
    }, 6000)
    if (name.length >= 50) {
      return toastr.warning("Cuidado!", "Título muito extenso.")
    }

    if (!deviceId || !variable) {
      toastr.warning('Erro!', 'Não foi selecionada variável.');
    }

    else {
      const whArr = [3, 6];

      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        dashId: localStorage.getItem('dashId'),
        // idCard: (cardProps?.id) ? cardId : null,
        cardType: cardType,
        name: name,
        description: description,
        devices: deviceId.split(".")[0],
        variables: (variableListIfExists.length) ? JSON.stringify(variableListIfExists) : variable,
        // variables: variable,
        period: null,
        reloadTime: reloadTime,
        w: whArr[0],
        h: whArr[1],
        decimalNumber: (decimalNumber) ? decimalNumber : null,
        formula: {
          unit: labelUnit,
          formula: formulaInputToApi,
          // result: resultToPreview,
        }
      };
      // return console.log(jsonData);
      /*** Envio de dados ***/
      axios.post('newFormulaCard/', JSON.stringify(jsonData))
        .then(result => {
          toastr.success(result.data.messageHead, result.data.messageBody);
          history.push("../dashboardView")
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
          if (err.response.data) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  };
  
  // FUNÇÃO PARA SALVAR DADOS EDITADOS DO CARD
  async function handleUpdateCard(cardId) {

    setEditLoading(true);
    setTimeout(() => {
      setEditLoading(false)
    }, 6000)

    if (name.length >= 50) {
      return toastr.warning("Cuidado!", "Título muito extenso.")
    }

    else {
      const whArr = [3, 6];
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        dashId: localStorage.getItem('dashId'),
        idCard: cardId,
        // cardType: cardType,
        name: name,
        description: description,
        devices: deviceId.split(".")[0],
        variables: (variableListIfExists.length) ? JSON.stringify(variableListIfExists) : variable,
        // variables: editVariable,
        period: null,
        reloadTime: reloadTime,
        w: whArr[0],
        h: whArr[1],
        rangeStructure: null,
        decimalNumber: (decimalNumber) ? decimalNumber : null,
        formula: {
          unit: labelUnit,
          formula: formulaInputToApi,
          // result: resultToPreview,
        },
        minValueProp: null,
        maxValueProp: null
      };
      
      /*** Envio de dados ***/
      await axios.put('editCardV2/', JSON.stringify(jsonData))
        .then(result => {
          toastr.success(result.data.messageHead, result.data.messageBody);
          setEditLoading(false)
          history.push("../../dashboardView")
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
          setEditLoading(false)
          // console.log("catch", isEditLoading);
          if (err.response.data) {

            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody,
            );
            // console.log("if", isEditLoading);
          }
        });
    }
  };

  //CHAMADAS PARA TRAZER CAMPOS PREENCHIDOS AO EDITAR
  useEffect(() => {
    if (cardProps?.id) {
      axios.get('/readDataOnEditCard', {
        params: {
          dvcId: cardProps.devices
        }
      })
        .then(res => {
          // console.log(res);
          setCardDataToEdit(res.data)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar projetos.");  // t() da problema com o useEffect
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios.get('/projectUserSearch')
      .then(response => {
        setProjList(response.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar projetos.");  // t() da problema com o useEffect
      });
  }, []);

  useEffect(() => {
    if (cardProps?.id && cardDataToEdit) {

      onProjectChange(cardDataToEdit.projId);
      onDeviceChange(cardProps?.devices);

      let editCard = JSON.parse(cardProps.variables);

      editCard.forEach(variable => {

        setVariableListIfExists(prevData => [...prevData, variable]);
        setDeviceId(`${variable.dvcId}.${variable.deviceName}`);
        
        let deviceToMatch = variable.dvcId
        let pattern = new RegExp(deviceToMatch, "g");

        let matches = parsedFormula.formula.match(pattern);

        if (matches) {
          let replacedFormula = parsedFormula.formula.replace(pattern, variable.deviceName);

          setMathFormulaInput(replacedFormula);
          setFormulaInputToApi(replacedFormula);
          // console.log(mathFormulaInput);
          // console.log(formulaInputToApi);
        }
      })
    }
    // eslint-disable-next-line
  }, [cardProps?.id && cardDataToEdit])

  useEffect(() => {

    if (deviceId) {
      axios.get('/readDataLast', {
        params: {
          idDevice: deviceId.split(".")[0]
        }
      })
        .then(response => {
          // console.log(response);
          setdeviceData(response.data)
          // setLastComm(response.data.lastComm);
          // setCommInterval(response.data.commInterval);
          setdataLoading(false)
        })
        .catch(err => {
          console.log(err);
          // toastr.warning('Erro!', "Erro ao buscar dados.");
        })
    }

    if (reloadTime) {
      const intervalSearch = setInterval(() => {
        if (deviceId) {
          axios.get('/readDataLast', {
            params: {
              idDevice: deviceId.split(".")[0]
            }
          })
            .then(response => {
              setdeviceData(response.data)
              // setLastComm(response.data.lastComm);
              // setCommInterval(response.data.commInterval);
            })
            .catch(err => {
              console.log(err);
              // toastr.warning('Erro!', "Erro ao buscar dados.");
            });
          if (history.location.pathname !== "/dashboardView") {
            clearInterval(intervalSearch);
          };
        }
      }, reloadTime);
    }
  }, [deviceId.split(".")[0], dataLoading, history, reloadTime])

  return <Row xs={1} xl={2}>
    <Col>
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{t('dashboards.cardOptions')}</CardTitle>
        </CardHeader>

        <CardBody>
          <Form
            onSubmit={e => {
              e.preventDefault();
              onSubmitForm();
            }}
          >
            {(cardProps?.id) ?
              <>
                {/* Edit Nome */}
                <FormGroup>
                  <Label>
                    {t('dashboards.name')}
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t('dashboards.name')}
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </FormGroup>

                {/* Edit Descrição */}
                <FormGroup>
                  <Label>
                    {t('dashboards.description')}
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder={t('dashboards.description')}
                    rows="3"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                </FormGroup>

                {/* Editar Seleção de projeto */}
                <FormGroup className="flex" row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.projects')}
                  </Label>
                  <Col lg={12} xl={9}>
                    {(cardDataToEdit) ?
                      <Input
                        type="select"
                        id="project"
                        required
                        defaultValue={(cardDataToEdit?.projId) ? cardDataToEdit.projId : ""}
                        onChange={e => onProjectChange(e.target.value)}
                        disabled={isLoading}
                      >
                        <option hidden value={(cardDataToEdit?.projId) ? cardDataToEdit.projId : ""}>{(cardDataToEdit) ? cardDataToEdit?.name : ""}</option>
                        {(projList.length)
                          ? projList.map(item => (
                            <option value={item.id} key={item.id}>{item.name}</option>
                          ))
                          : <option disabled>{t('dashboards.noProject')}</option>
                        }
                      </Input>
                      : null}
                  </Col>
                </FormGroup>

                {/* Edit Seleção de Fórmula */}
                <FormGroup row className="mb-3 align-items-center">
                  <Label lg={12} xl={3}>
                    {t("Fórmula")}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Button color="primary" onClick={() => setIsModalOpen(!isModalOpen)}>Adicionar</Button>
                  </Col>
                </FormGroup>

                <ModalAddFormula
                  open={isModalOpen}
                  toggle={toggleModalAddFormula}
                  cardDataToEdit={cardDataToEdit}
                  variable={variable}
                  setVariable={setVariable}
                  varList={varList}
                  isLoading={isLoading}
                  deviceId={deviceId}
                  onDeviceChange={onDeviceChange}
                  dvcList={dvcList}
                  handAddNewVariable={handAddNewVariable}
                  labelUnit={labelUnit}
                  setLabelUnit={setLabelUnit}
                  variableListIfExists={variableListIfExists}
                  setVariableListIfExists={setVariableListIfExists}
                  handleDeleteVariable={handleDeleteVariable}
                  mathFormulaInput={mathFormulaInput}
                  setMathFormulaInput={setMathFormulaInput}
                  handleAddFormula={handleAddFormula}
                />

                {/* Edit Seleção de casas decimais */}
                <FormGroup row className="mb-3 align-items-center">
                  <Label lg={12} xl={3}>
                    {t("dashboards.decimalNumber")}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      value={decimalNumber}
                      onChange={e => setDecimalNumber(e.target.value)}
                    // disabled={(typeof (disableDecimalNumber) !== "number" ? true : false)}
                    >
                      <option value="" disabled>Selecione</option>
                      <option value="7">Todas</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Input>
                  </Col>
                </FormGroup>

                {/* Edit Seleção tempo Reload */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.refreshTime')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="reloadTimeSelect"
                      required
                      value={reloadTime}
                      onChange={e => setReloadTime(e.target.value)}
                    >
                      <option hidden value="">{t('dashboards.pleaseRefresh')}</option>
                      <option value="30000">30 segundos</option>
                      <option value="60000">1 minuto</option>
                      <option value="120000">2 minutos</option>
                      <option value="180000">3 minutos</option>
                      <option value="240000">4 minutos</option>
                      <option value="300000">5 minutos</option>
                      <option value="900000">15 minutos</option>
                      <option value="1800000">30 minutos</option>

                    </Input>
                  </Col>
                </FormGroup>
              </>
              :
              <>
                {/* Nome */}
                <FormGroup>
                  <Label>
                    {t('dashboards.name')}
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t('dashboards.name')}
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </FormGroup>

                {/* Descrição */}
                <FormGroup>
                  <Label>
                    {t('dashboards.description')}
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder={t('dashboards.description')}
                    rows="3"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                </FormGroup>

                {/* Seleção de projeto */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.projects')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="project"
                      required
                      onChange={e => onProjectChange(e.target.value)}
                      disabled={isLoading}
                    >
                      <option hidden value="">{t('dashboards.pleaseProject')}</option>
                      {(projList.length)
                        ? projList.map(item => (
                          <option value={item.id} key={item.id}>{item.name}</option>
                        ))
                        : <option disabled>{t('dashboards.noProject')}</option>
                      }
                    </Input>
                  </Col>
                </FormGroup>

                {/* Seleção de Fórmula */}
                <FormGroup row className="mb-3 align-items-center">
                  <Label lg={12} xl={3}>
                    {t("Fórmula")}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Button color="primary" onClick={() => setIsModalOpen(!isModalOpen)}>Adicionar</Button>
                  </Col>
                </FormGroup>

                <ModalAddFormula
                  open={isModalOpen}
                  toggle={toggleModalAddFormula}
                  variable={variable}
                  setVariable={setVariable}
                  varList={varList}
                  isLoading={isLoading}
                  deviceId={deviceId}
                  onDeviceChange={onDeviceChange}
                  dvcList={dvcList}
                  handAddNewVariable={handAddNewVariable}
                  labelUnit={labelUnit}
                  setLabelUnit={setLabelUnit}
                  variableListIfExists={variableListIfExists}
                  setVariableListIfExists={setVariableListIfExists}
                  handleDeleteVariable={handleDeleteVariable}
                  mathFormulaInput={mathFormulaInput}
                  setMathFormulaInput={setMathFormulaInput}
                  handleAddFormula={handleAddFormula}
                  parsedFormula={parsedFormula}
                />

                {/* Seleção de casas decimais */}
                <FormGroup row className="mb-3 align-items-center">
                  <Label lg={12} xl={3}>
                    {t("dashboards.decimalNumber")}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      value={decimalNumber}
                      onChange={e => setDecimalNumber(e.target.value)}
                    // disabled={(typeof (disableDecimalNumber) !== "number" ? true : false)}
                    >
                      <option value="" disabled>Selecione</option>
                      <option value="7">Todas</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Input>
                  </Col>
                </FormGroup>

                {/* Seleção tempo Reload */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.refreshTime')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="reloadTimeSelect"
                      required
                      value={reloadTime}
                      onChange={e => setReloadTime(e.target.value)}
                    >
                      <option hidden value="">{t('dashboards.pleaseRefresh')}</option>
                      <option value="30000">30 segundos</option>
                      <option value="60000">1 minuto</option>
                      <option value="120000">2 minutos</option>
                      <option value="180000">3 minutos</option>
                      <option value="240000">4 minutos</option>
                      <option value="300000">5 minutos</option>
                      <option value="900000">15 minutos</option>
                      <option value="1800000">30 minutos</option>

                    </Input>
                  </Col>
                </FormGroup>
              </>
            }

            {/* Botão de salvar */}
            <div className="text-right">
              <Link to="/dashboardView">
                <Button
                  name="btnCancel"
                  color='primary'
                  className="mr-2"
                  outline
                >
                  {t('dashboards.cancel')}
                </Button>
              </Link>
              {(cardProps?.id) ?
                <Button
                  color="primary"
                  type="button"
                  disabled={isEditLoading}
                  style={{ minWidth: 80 }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddFormula();
                    // setInterval(() => {
                    handleUpdateCard(cardProps.id);
                    // }, 2000)
                  }}
                >
                  {(isEditLoading === true) ? <Spinner size={"sm"} /> : t("Atualizar")}
                </Button>
                : <Button
                  disabled={isEditLoading}
                  style={{ minWidth: 80 }}
                  color="primary"
                  type="submit"
                  onClick={handleAddFormula}
                >
                  {(isEditLoading === true) ? <Spinner size={"sm"} /> : t('dashboards.save')}
                </Button>
              }
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>

    <Col>
      {/* <Card>
        {(cardType === "formulaCard" ||
          cardProps?.type === "formulaCard")
          ? <FormulaMathPreview
            deviceData={deviceData}
            resultToPreview={resultToPreview}
            editName={editName}
            editDescription={editDescription}
            editDeviceId={editDeviceId}
            editVariable={editVariable}
            editReloadTime={editReloadTime}
            editDecimalNumber={editDecimalNumber}
            retrieveDataFromPreviewCard={retrieveDataFromPreviewCard}
            cardDataToEdit={cardDataToEdit}
            decimalNumber={decimalNumber}
            cardType={cardType}
            cardProps={cardProps}
            name={name}
            description={description}
            variables={variable}
            deviceId={deviceId}
            preview={true}
            labelUnit={labelUnit}
          />
          : null
        }
      </Card> */}
    </Col>
  </Row>
}

export default NewFormulaCard;