import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  Input,
  ListGroup,
  ListGroupItem,
  Spinner,
} from "reactstrap";
import { Search } from "react-feather";
import { useTranslation } from "react-i18next";
import {PRESET, useWindowDimensions} from "../../../../../igDashboards/view/hooks/useWindowDimensions";
const UCList = ({ UCs, selectedUC, setSelectedUC, loading }) => {
  const { t } = useTranslation();
  const [searchBar, setSearchBar] = useState("");
  const {preset, height} = useWindowDimensions();

  return (
    <Card
      style={{ minHeight: "340px", maxHeight: preset === PRESET.MOBILE ? "348px" : height - 373 }}
      className="overflow-scroll"
    >
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {t("water.consumptionUnits")}
        </CardTitle>
      </CardHeader>

      <div className="d-flex align-items-center justify-content-start mr-3 mb-3">
        <Search width={36} height={16} className="text-muted" />
        <Input
          type="text"
          placeholder="Buscar"
          aria-label="Search"
          // className="form-control-lite"
          value={searchBar}
          onChange={(e) => setSearchBar(e.target.value.toLowerCase())}
          style={{
            height: "24px",
            padding: "0 0.7rem",
            fontSize: "0.8375rem",
          }}
        />
      </div>

      <ListGroup flush style={{ overflowY: "scroll" }}>
        <ListGroupItem
          key={0}
          className={"cursor-pointer" + (!selectedUC ? " bg-light" : "")}
          onClick={(e) => {
            e.preventDefault();
            setSelectedUC("general");
          }}
        >
          {t("water.general")}
        </ListGroupItem>

        {loading ? (
          <div className="text-center mt-2">
            <Spinner color="primary" />
          </div>
        ) : (
          UCs.filter((element) =>
            element.description.toLowerCase().includes(searchBar)
          ).map((element) => (
            <ListGroupItem
              key={element.id}
              className={
                "cursor-pointer" + (selectedUC === element ? " bg-light" : "")
              }
              onClick={(e) => {
                e.preventDefault();
                setSelectedUC(element);
              }}
            >
              {element.description}
            </ListGroupItem>
          ))
        )}
      </ListGroup>
    </Card>
  );
};

export default UCList;
