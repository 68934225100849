import { Carousel } from "antd";
import React, { useRef } from "react";
import ConsumChart from "../carouselComponents/ConsumChart";

export default function CarouselSimple({ components = [] }) {
  const ref = useRef(null);

  const handleNext = () => {
    ref.current.next();
  };

  const handlePrev = () => {
    ref.current.prev();
  };

  return (
    <div className="carousel-general-water">
      <button
        onClick={handlePrev}
        className="button-carousel button-carousel-left"
      >
        <span aria-hidden="true" class="carousel-control-prev-icon"></span>
      </button>
      <button
        onClick={handleNext}
        className="button-carousel button-carousel-right"
      >
        <span aria-hidden="true" class="carousel-control-next-icon"></span>
      </button>
      <Carousel ref={ref} dots={true} infinite={true} draggable={false}>
        {components
          .filter((item) => item !== null)
          .map((component, index) => (
            <div key={index}>
              <div className="info-container">{component}</div>
            </div>
          ))}
      </Carousel>
    </div>
  );
}
