import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { 
  Card,
  CardBody, 
  CardTitle, 
  Row, 
  Col, 
  CardHeader, 
  CardFooter,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown, 
  CardSubtitle
} from "reactstrap";
import { useHistory } from "react-router-dom";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';
import { faTemperatureHigh } from '@fortawesome/free-solid-svg-icons';
import { faFan, faSun, faTint } from '@fortawesome/free-solid-svg-icons';
import { Droplet } from "react-feather";
import { faAutoprefixer } from "@fortawesome/free-brands-svg-icons";
import { Spinner } from "reactstrap";

/*** PACOTE DE COMUNICAÇÃO COM API ***/
import axios from 'axios';

/*** PACOTE DE NOTIFICAÇÕES ***/
import { toastr } from "react-redux-toastr";

/*** COMPONENTE DO CARD ***/
const DevicesView = ({device, projectId, activeTab}) => {

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [textLabel, setTextLabel] = useState();
  const [targetTemperature, setTargetTemperature] = useState();
  const [currentTemperature, setCurrentTemperature] = useState();
  const [currentHumidity, setCurrentHumidity] = useState();
  const [mode, setMode] = useState("");
  const [on, setOn] = useState();

  let history = useHistory();

  // UseEffect para buscar os dados dos estados dos devices
  useEffect(() => {

    axios.get('/readACState?deviceId=' + device.id)
    .then(response => {
      if (response.data) {
        setCurrentTemperature((response.data.currentTemperature) ? response.data.currentTemperature.toFixed(1) : null);
        setTargetTemperature(response.data.targetTemperature);
        setCurrentHumidity((response.data.currentHumidity) ? response.data.currentHumidity.toFixed(1) : null);
        setMode(response.data.mode);
        setOn(response.data.on);
        setIsLoading(false)
        setTextLabel((response.data.on === false) ? true : false)
      }
    })
    .catch(err => {
      console.log(err);
      setIsLoading(false)
   })
   .finally(()=>setIsLoading(false));

   let intervalDeviceSearch = setInterval(()=> {

    axios.get('/readACState?deviceId=' + device.id)
    .then(response => {
      setCurrentTemperature((response.data.currentTemperature) ? response.data.currentTemperature.toFixed(1) : null);
      setTargetTemperature(response.data.targetTemperature);
      setCurrentHumidity((response.data.currentHumidity) ? response.data.currentHumidity.toFixed(1) : null);
      setMode(response.data.mode);
      setOn((response.data.on))
      setIsLoading(false)
      setTextLabel((response.data.on === false) ? true : false)
    })
    .catch(err => {
      console.log(err);
      setIsLoading(false)
    });
  
    if (history.location.pathname !== `/project-${projectId}-default` || activeTab !== "climate") {
      clearInterval(intervalDeviceSearch);
    };
  }, 90000);
  
  return function clear () {
    clearInterval(intervalDeviceSearch);
  }

  }, [device, projectId, activeTab, history.location.pathname]);

    // Seta o box shadow de acordo com o estado on/off
    let colorBoxShadow = (textLabel === false) ? "rgb(5 203 75 / 79%) 0px 3px 10px, rgb(0 0 0 / 23%) 0px 3px 10px" : "rgb(241 10 10 / 72%) 0px 3px 10px, rgb(0 0 0 / 23%) 0px 3px 10px"

    // função que altera a label do botão on/off
    function troca() {
    
      if (textLabel === true) {
        setTextLabel(false);
      }
      else {
        setTextLabel(true);
      }
    }
  
    // variável que compara a label com o estado da tradução
    var labelMode = (mode === "0") 
      ? t('climate.cooling')
      : (mode === "1")
        ? t('climate.heating')
        : (mode === "4")
          ? t('climate.drying')
          : (mode === "3")
            ? t('climate.fanOnly')
            : t('climate.autoMode')
  
    // Variável para a label fan
    var temperatureLabel = (mode === "3") ? null : targetTemperature + " ºC"
  
    // Variável para os ícones
    var iconMode = (mode === "0")
      ? faSnowflake
      : (mode === "1")
        ? faSun
        : (mode === "4")
          ? faTint
          : (mode === "3")
            ? faFan
            : faAutoprefixer

  return(
    <div style={{boxShadow: colorBoxShadow, maxHeight:"240px", minWidth: "312px", minHeight:"240px"}}>
      {(isLoading) 
        ? <div className="text-center pt-7" style={{backgroundColor:"#fff", height: "240px"}}> <Spinner color="primary"/></div>
        : (on === true || on === false)
          ?
          <Card style={{height: "240px"}}>
          <CardHeader style={{paddingBottom:"0px"}}>    
            <CardTitle className="text-left my-1 h3" style={{fontSize:"20px"}}>
              {device.deviceName}
            </CardTitle>
            <CardSubtitle className="text-muted text-left" style={{fontSize:"16px"}}>
              {device.model}
            </CardSubtitle>
            <CardSubtitle className="text-muted text-left" style={{fontSize:"16px"}}>
              {labelMode} {temperatureLabel}
            </CardSubtitle>
          </CardHeader>

          <CardBody style={{paddingTop:"0px", marginTop:"0px", paddingBottom:"3px"}}>
            <div >
              <h1 className="text-muted text-center" style={{fontSize:"65px", marginBottom:"0px", marginTop: "-7px"}}>
                {currentTemperature} ºC
              </h1>  
            </div>
            <div>
              <h1 className="text-muted text-left" style={{fontSize:"20px"}}>
              <Droplet 
                className="feather align-middle"
                style={{marginTop:"-5px", marginRight: "3px", width:"17px", height:"17px"}}
              />
                {currentHumidity} %
              </h1>
            </div>

          </CardBody>
          <CardFooter className="text-left" style={{paddingTop:"0px"}}> 
            <Row>
              <Col style={{paddingRight: "0px"}}>
                <Button 
                  id="btn" 
                  color= "primary"
                  onClick={()=>{

                    troca();
                    const jsonData = JSON.stringify({ 
                      deviceId: device.id,
                      newValue: textLabel,
                      
                    });
                    axios.post('changeOnState/', jsonData)
                    .then(response => {
                      toastr.success(t('climate.success'), t('climate.successSending')); 
                    
                    })
                    .catch(err => {
                      console.log(err);
                     toastr.error(t('climate.error'), t('climate.errorSending'));
                   });
                  }}
                >
                {t('climate.'+ textLabel)}
                </Button>
              </Col>

              <Col style={{paddingLeft: "0px", paddingRight:"0px"}}>
                <Row style={{marginTop:"7px"}}>
                  <Col style={{paddingRight:"0px"}}>
                    <UncontrolledDropdown style={{marginTop:"0px", width:"24px"}}>
                      <DropdownToggle  tag="a">
                        <FontAwesomeIcon icon={iconMode} fixedWidth className="align-right" style={{width: "18px", height: "18px"}}/>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-direita">
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACMode/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "0"
                            }))
                            .then(response => {
                              setMode("0")
                              toastr.success(t('climate.success'), t('climate.successSending')); 
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          <FontAwesomeIcon 
                            icon={faSnowflake} 
                            id="btn"
                            fixedWidth 
                            className="align-left" 
                            style={{width: "18px", height: "18px", marginRight: "10px"}}
                          >  
                          </FontAwesomeIcon>
                          {t('climate.cool')}
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACMode/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "1"
                            }))
                            .then(response => {
                              setMode("1")
                              toastr.success(t('climate.success'), t('climate.successSending')); 
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          <FontAwesomeIcon 
                            icon={faSun} 
                            fixedWidth 
                            className="align-left" 
                            style={{width: "18px", height: "18px", marginRight: "10px"}}  
                          />
                          {t('climate.heat')}
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACMode/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "3"
                            }))
                            .then(response => {
                              setMode("3")
                              toastr.success(t('climate.success'), t('climate.successSending')); 
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                        <FontAwesomeIcon 
                          icon={faFan} 
                            fixedWidth 
                            className="align-left" 
                            style={{width: "18px", height: "18px", marginRight: "10px"}}
                        />
                          {t('climate.fan')}
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACMode/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "4"
                            }))
                            .then(response => {
                              setMode("4")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                        <FontAwesomeIcon 
                          icon={faTint } 
                            fixedWidth 
                            className="align-left" 
                            style={{width: "18px", height: "18px", marginRight: "10px"}}
                        />
                          {t('climate.dry')}
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACMode/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "2"
                            }))
                            .then(response => {
                              setMode("2")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          <FontAwesomeIcon 
                            icon={faAutoprefixer} 
                            fixedWidth 
                            className="align-left" 
                            style={{width: "18px", height: "18px", marginRight: "10px"}}
                          />
                          {t('climate.auto')}
                        </DropdownItem>
                      </DropdownMenu>  
                    </UncontrolledDropdown>
                  </Col>
                  <Col>
                    <UncontrolledDropdown>
                      <DropdownToggle  tag="a">
                        <FontAwesomeIcon 
                          icon={faTemperatureHigh} 
                          fixedWidth 
                          className="align-right" style={{width: "18px", height: "18px"}}/>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-cima">
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACTemperature/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "18"
                            }))
                            .then(response => {
                              setTargetTemperature("18")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          18
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACTemperature/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "19"
                            }))
                            .then(response => {
                              setTargetTemperature("19")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}                       
                        >
                          19
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACTemperature/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "20"
                            }))
                            .then(response => {
                              setTargetTemperature("20")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}                        
                        >
                          20
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACTemperature/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "21"
                            }))
                            .then(response => {
                              setTargetTemperature("21")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          21
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACTemperature/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "22"
                            }))
                            .then(response => {
                              setTargetTemperature("22")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}                       
                        >
                          22
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACTemperature/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "23"
                            }))
                            .then(response => {
                              setTargetTemperature("23")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          23
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACTemperature/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "24"
                            }))
                            .then(response => {
                              setTargetTemperature("24")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          24
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACTemperature/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "25"
                            }))
                            .then(response => {
                              setTargetTemperature("25")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          25
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACTemperature/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "26"
                            }))
                            .then(response => {
                              setTargetTemperature("26")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          26
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACTemperature/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "27"
                            }))
                            .then(response => {
                              setTargetTemperature("27")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          27
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACTemperature/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "28"
                            }))
                            .then(response => {
                              setTargetTemperature("28")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          28
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACTemperature/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "29"
                            }))
                            .then(response => {
                              setTargetTemperature("29")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          29
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACTemperature/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "30"
                            }))
                            .then(response => {
                              setTargetTemperature("30")
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          30
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                  <Col style={{paddingLeft:"0px", paddingRight:"0px"}}>
                    
                    <UncontrolledDropdown>
                      <DropdownToggle  tag="a">
                        <FontAwesomeIcon 
                          icon={faFan} 
                          fixedWidth 
                          className="align-right" 
                          style={{width: "18px", height: "18px"}}/>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-direita">
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACFanState/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "1"
                            }))
                            .then(response => {
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          {t('climate.low')}
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACFanState/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "2"
                            }))
                            .then(response => {
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          {t('climate.medium')}
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACFanState/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "3"
                            }))
                            .then(response => {
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          {t('climate.high')}
                        </DropdownItem>
                        <DropdownItem
                          onClick={()=>{
                            axios.post('setACFanState/', JSON.stringify({
                              deviceId: device.id,
                              newValue: "0"
                            }))
                            .then(response => {
                              toastr.success(t('climate.success'), t('climate.successSending'));
                            })
                            .catch(err => {
                              console.log(err);
                             toastr.error(t('climate.error'), t('climate.errorSending'));
                           });
                          }}
                        >
                          {t('climate.auto')}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>    
                  </Col>
                </Row>  
              </Col>
            </Row>   
          </CardFooter>
          </Card>
          : <Card style={{height: "240px"}}>
              <CardHeader style={{paddingBottom:"0px"}}>    
                <CardTitle className="text-left my-1 h3" style={{fontSize:"20px"}}>
                  {device.deviceName}
                </CardTitle>
                <CardSubtitle className="text-muted text-left" style={{fontSize:"16px"}}>
                  {device.model}
                </CardSubtitle>
                <CardSubtitle className="text-muted text-left" style={{fontSize:"16px"}}>
                  
                </CardSubtitle>
              </CardHeader>
              <CardBody>
                <div className="text-muted text-center" style={{fontSize:"18px", marginTop: "57px"}}>
                  {t('climate.deviceNotRegistered')}
                </div>
              </CardBody>
              <CardFooter>
                <div>
                </div>
              </CardFooter>
            </Card>
      }
    </div>   
  );  
}

/*** PÁGINA DO MÓDULO CLIMA ***/
const ClimateModule = ({ devicesSearch, projectId, activeTab }) => {

  const [devices, setDevices] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);

  // Effect que busca os dispositivos do módulo
  useEffect(() => {
    axios.get('/readClimateDevices', {params: {
      projId: projectId
    }})
    .then(response => {
      setDevices(response.data)
    })
    .catch(err => {
      console.log(err);
    });
  }, [projectId]);

  useEffect(() => {
    let filteredClimateDevices = devices.filter(device => devicesSearch.find(element => element.id === device.id));
    setFilteredDevices(filteredClimateDevices)
      
  }, [devicesSearch, devices]);

  return (!filteredDevices.length)
    ? <div className="text-center pt-7" style={{backgroundColor:"#f4f7fc", height: "237px"}}> <Spinner color="primary"/></div>
    : // Renderiza os dispositivos
      <Row
        sm= "1" md="2" lg="3" xl="4"
        className="d-flex flex-row"
      >
        {filteredDevices.map((item, i) => {
          return (
            <Col key={i} style={{paddingBottom:"10px", marginTop: "10px", minWidth: "322px"}}>
              <DevicesView device={item} projectId={projectId} activeTab={activeTab}/>
            </Col> 
          )
        })}       
      </Row>
}

export default ClimateModule;