import { useState } from "react";
import { Modal, Nav } from "react-bootstrap";
import { TabContent, TabPane } from "reactstrap";
import { Overview } from "./Contents/Overview";
import { PowerModule } from "./Contents/PowerModule/index";
import { SolarModule } from "./Contents/SolarModule";
import { WaterAndGasModule } from "./Contents/WaterAndGasModule";
import { useTranslation } from "react-i18next";

export const ModalProjects = ({ item, open, onClose }) => {
  const {t} = useTranslation();
  const [activeTab, setActiveTab] = useState("overview");

  const handleToggle = (value) => {
    setActiveTab(value);
  };

  const renderTabPane = (tabId, content) => (
    <TabPane tabId={tabId}>{content}</TabPane>
  );

  return (
    <Modal show={open} onHide={onClose} size={"xl"}>
      <Modal.Header closeButton>
        <Modal.Title>{item.projectName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Nav variant="tabs" activeKey={activeTab} onSelect={handleToggle}>
          <Nav.Item>
            <Nav.Link eventKey="overview">{t("noc.labels.overview")}</Nav.Link>
          </Nav.Item>
          {Boolean(+item.solarModuleOn) && (
            <Nav.Item>
              <Nav.Link eventKey="solarModuleOn">{t("noc.labels.solar")}</Nav.Link>
            </Nav.Item>
          )}
          {Boolean(+item.powerModuleOn) && (
            <Nav.Item>
              <Nav.Link eventKey="powerModuleOn">{t("noc.labels.power")}</Nav.Link>
            </Nav.Item>
          )}
          {/* TODO: VERIFICICAR MAIS INFORMAÇÕES SOBRE CLIMATE */}
          {/* {Boolean(+item.climateModuleOn) && (
            <Nav.Item>
              <Nav.Link eventKey="climateModuleOn">Climate</Nav.Link>
            </Nav.Item>
          )} */}
          {Boolean(+item.gasModuleOn || +item.waterModuleOn) && (
            <Nav.Item>
              <Nav.Link eventKey="waterModuleOn">{`${t("noc.labels.water")}/${t("noc.labels.gas")}`}</Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          {renderTabPane("overview", <Overview item={item} />)}
          {Boolean(+item.solarModuleOn) &&
            activeTab === "solarModuleOn" &&
            renderTabPane("solarModuleOn", <SolarModule item={item} />)}
          {Boolean(+item.powerModuleOn) &&
            activeTab === "powerModuleOn" &&
            renderTabPane("powerModuleOn", <PowerModule item={item} />)}
          {/* TODO: VERIFICICAR MAIS INFORMAÇÕES SOBRE CLIMATE */}
          {/* {Boolean(+item.climateModuleOn) &&
            activeTab === "climateModuleOn" &&
            renderTabPane("climateModuleOn", <ClimateModule item={item} />)} */}
          {(Boolean(+item.waterModuleOn) || Boolean(+item.gasModuleOn)) &&
            activeTab === "waterModuleOn" &&
            renderTabPane("waterModuleOn", <WaterAndGasModule item={item} />)}
        </TabContent>
      </Modal.Body>
    </Modal>
  );
};
