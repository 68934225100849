const ptBrSolarTranslations = {
  solar: {
    prPvSystPercent: "PR Efetivo (%)",
    prIrradiationPercent: "PR Irradiação (%)",
    plants: "Plantas",
    params: "Parâmetros",
    details: "Detalhes",
    energy: "Energia",
    daily: "Diária",
    daily2: "Energia",
    current: "Potência",
    lifetime: "Acumulada",
    power: "Atual",
    income: "Economia",
    month: "Mensal",
    month2: "Mês",
    cultivated: "Árvores",
    treesCultivated: "Cultivadas",
    deviceList: "Lista de Dispositivos",
    inputVoltage: "Tensão de Entrada (V)",
    inputCurrent: "Corrente de Entrada (A)",
    gridVoltage: "Tensão de Rede (V)",
    gridCurrent: "Corrente de Fase (C)",
    deviceStatus: "Status do Dispositivo",
    activePower: "Potência Ativa",
    currentDayPower: "Potência do dia Atual",
    currentDayEnergy: "Energia do dia Atual",
    generationXirradiationDay: "Geração x Irradiação do dia Atual",
    settings: "Configurações",
    configPrice: "Insira o valor por kWh",
    price: "Valor/kWh (R$)",
    insertDevices: "Selecione um ou mais dispositivos",
    devices: "Dispositivos",
    refConsuption: "Referência de Consumo",
    save: "Salvar",
    selectMonth: "Selecione um mês",
    tree: "Árvore",
    trees: "Árvores",
    monthlyComparison: "Comparação Mensal de Potência",
    monthlyComparison2: "Comparação Mensal de Energia",
    currentDay: "Dia Atual",
    currentMonth: "Mês Atual",
    lastMonth: "Mês Passado",
    noData: "Não há dados",
    noDataMonth: "Não há dados para o mês selecionado.",
    noSolarDevices: "Não há dispositivos do tipo Solar",
    energyTooltip: "Energia",
    powerTooltip: "Potência",
    energyLabel: "Energia Gerada",
    consumedLabel: "Energia Consumida",
    powerLabel: "Potência",
    hour: "Hora",
    date: "Data",
    total: "Total",
    income2: "Economia",
    pv: "Sistema",
    system: "Fotovoltáico",
    online: "online",
    incomeSolar: "Economia Total",
    inverterQuantity: "Quantidade de Inversores",
    inverter: "Inversor",
    inverters: "Inversores",
    status: "Status",
    pvPlant: "Planta Fotovoltáica",
    solar: "Solar",
    solarVision: "Visão Geral do Módulo Solar",
    tooltipOff: "Falha de comunicação em todos os dispositivos",
    tooltipOn: "Todos os dispositivos estão comunicando",
    tooltipWarning: "Falha de comunicação em um ou mais dispositivos",
    noDataPlants: "Não há plantas do tipo Solar",
    generate: "Gerada",
    consumed: "Consumida",
    powerGraphic: "Potência",
    powerCard: "Potência",
    capacity: "Instalada",
    avoided: "Emissões",
    emissions: "Evitadas",
    monthly: "Energia",
    weeklyEnergyLabel: "Energia Semanal",
    monthlyEnergy: "Mensal",
    weeklyEnergy: "Semanal",
    inverterStatus: "Status do Inversor",
    processed: "Processado",
    dailyEnergy: "Energia Diária",
    lifetimeEnergy: "Energia Acumulada",
    frequency: "Frequência",
    temperature: "Temperatura",
    powerFactory: "Fator de Potência",
    lastCommunication: "Última comunicação",
    area: "Fator de Multiplicação: ",
    rendimento: "Rendimento do Módulo",
    cliente: "Cliente",
    localizacao: "Localização",
    conjuntoProduto: "Tipo de Produto",
    posicionamento: "Posicionamento",
    picoEnergia: "Pico de Energia",
    contratoInicio: "Início do Contrato",
    contratoFim: "Fim do Contrato",
    imagemPlanta: "Imagem da Planta",
    expectedEfficiency: "Parâmetros de Inversores",
    humidity: "Umidade/Pluviômetro",
    velocity: "Velocidade",
    irradiation: "Irradiação",
    irradiance: "Irradiância",
    moduleTemperature: "Temperatura do Módulo",
    ambientTemperature: "Temperatura Ambiente",
    windDirection: "Direção do Vento",
    solarimetric: "Estação Solarimétrica",
    generation: "Geração",
    generationXirradiation: "Geração x Irradiância",
    indicators: "Indicadores de Performance",
    disponibility: "Disponibilidade Geral da Planta",
    capacityFactor: "Fator de Capacidade",
    solarCapacity: "Capacidade",
    productivity: "Produtividade",
    plant: "Planta",
    expectedGeneration: "Geração Prevista",
    pr: "Taxa de Desempenho",
    availability: "Disponibilidade",
    plantAvailability: "Disponibilidade Geral da Planta",
    day: "Dia",
    noParams: "Parâmetros não cadastrados",
    config: "Configurar",

    // NOTE:MODAL PVSYST
    modalPvSystHeader: "Configurar PVSyst",
    pvSystJan: "Janeiro",
    pvSystFev: "Fevereiro",
    pvSystMar: "Marco",
    pvSystAbr: "Abril",
    pvSystMai: "Maio",
    pvSystJun: "Junho",
    pvSystJul: "Julho",
    pvSystAgo: "Agosto",
    pvSystSet: "Setembro",
    pvSystOut: "Outubro",
    pvSystNov: "Novembro",
    pvSystDez: "Dezembro",

    north: "N",
    northeast: "NE",
    east: "E",
    southeast: "SE",
    south: "S",
    southwest: "SO",
    west: "O",
    northwest: "NO",

    // NOTE:REPORTS PDF
    reactPdfModalTitle: "Relatório Módulo Solar",
    reactPdfReport: "Relatório",
    reactPdfDailyEnergy: "Energia Diária Gerada",
    reactPdfMonthlyEnergy: "Energia Mensal Gerada",
    reactPdfLifetimeEnergy: "Energia Acumulada",
    reactPdfCurrentPower: "Potência Atual",
    reactPdfCapacity: "Potência Instalada",
    reactPdfIncomeDisp: "Economia Mensal",
    reactPdfAvoidedDisp: "Emissões Evitadas",
    errors: {
      monthRef: {
        title: "Mês de Referência",
        msg: "Deve ser uma data válida",
      },
      contractEnd: {
        title: "Fim do Contrato",
        msg: "A data deve ser maior que a data de ínicio do contrato",
      },
      efficiency: {
        title: "Rendimento do Módulo",
        msg: "Deve estar entre 0 e 100",
      },
      invalidEmail: {
        title: "Atenção",
        msg: "Formato de e-mail inválido",
      },
      emailAdded: {
        title: "Atenção",
        msg: "E-mail já adicionado",
      },
      emptyEmail: {
        title: "Atenção",
        msg: "Não há e-mails para ser enviado",
      },
      sendReportManager: {
        title: "Relatório Gerencial",
        msg: "Erro ao enviar",
      },
    },
    success: {
      sendReportManager: {
        title: "Relatório Gerencial",
        msg: "Enviado com sucesso",
      },
    },
    billingReport: {
      title: "Relatório financeiro",
      prevReport: "Relatórios anteriores",
      generateReport: "Gerar relatório",
      generate: "Gerar",
      errorPDF: "Por favor selecione um arquivo PDF",
      readingProgress: "Lendo os dados",
      generatingReport: "Gerenado relatório",
      selectMonthRef: "Selecione o mês de referência",
      selectFile: "Selecionar arquivo",
      changeFile: "Alterar arquivo",
      onlyPdfFile: "Somente arquivos de PDF",
      previousRead: "Leitura anterior",
      currentRead: "Leitura atual",
      generationBalancekWh: "Saldo de geração kWh",
      kwhBalanceToExpire: "Saldo kWh a expirar",
      value: "Valor",
      TUSDConsumption: "Consumo TUSD",
      TEConsumption: "Consumo TE",
      consumption: "Consumo",
      TUSDInjectedEnergy: "Energia injetada",
      TEInjectedEnergy: "Energia injetada",
      publicLightingAgreement: "Convênio iluminação publica municipal",
      injectedActiveEnergy: "Energia ativa injetada",
      totalToPay: "Total a pagar",
      noPdfFileSelected: "Arquivo PDF não selecionado",
      confirmTheReadData: "Confirme os dados lidos",
      continue: "Continue",
    },
    compare: {
      week: "Semana",
      month: "Mês",
      day: "Dia",
    },
    prDelta: "Delta PR",
    generatedVsPvsyst: "Gerado / pvSyst (%)",
    prProjected: "PR Projetado (%)",
  },
  solarReport: {
    // NOTE:MODAL REACT-PDF/RENDERER
    reportTitle: "Planta",
    youSaved: "Você economizou",
    performanceRatio: "Taxa de Desempenho",
    generatedEnergy: "Energia Gerada",
    projectedEnergy: "Energia Projetada",
    avoidedDisp: "Emissões Evitadas",
    cultivatedTrees: "Equivalente ao total de árvores cultivadas",
    previousMonths: "Meses Anteriores",
    solutionMade: "Solução feita por",
    // NOTE:MODAL DE ENVIAR POR E-MAIL
    sendEmail: "Enviar por e-mail",
    bodyText: "Digite o e-mail desejado e clique no botão +",
    inputPlaceholder: "exemplo@email.com",
    editText: "Edite o e-mail e clique no botão Confirmar",
    closeButton: "Fechar",
    sendButton: "Enviar",
    // NOTE:TOOLTIPS
    generateMonthlyReport: "Gerar Relatório Mensal",
    reportPDF: "Relatório PDF",
    reportXLS: "Relatório XLS",
    // NOTE:BOOTSTRAP TABLE
    // NOTE: columnEmail: "",
    startDate: "Data de Início",
    endDate: "Data de Fim",
    selectDates: "Selecione as datas",
    error: "Houve um erro, tente novamente mais tarde.",
    solarManagementReport: "Relatório de Gestão Solar",
  },
  reportManager: {
    title: "Relatório Gerencial",
    modalTitle: "Relatório Gerencial - Módulo Solar",
    month: "mês",
    customer: "Cliente",
    location: "Localização",
    productCluster: "Produto",
    positioning: "Posicionamento",
    peakPower: "Pico de Energia",
    contractStart: "Início de contrato",
    contractEnd: "Fim de contrato",
    detailsTitle: "Detalhes",
    statusTitle: "Status Atual",
    statusInverter: "Todos os inversores",
    statusSystem: "Monitoramento de sistema",
    kpisTitle: "KPI's (Valores médios por mês)",
    eventTitle:
      "Atividades de manutenção, falhas relevantes e outros eventos perceptíveis",
    grossMargin: "Economia",
    mwhProduced: "Mwh produzido",
    co2Saved: "Emissões evitadas",
    avgPr: "Desempenho",
    labelEvent:
      "Deseja adicionar registro de eventos no relatório? Digite abaixo e clique no botão +",
    eventDescription: "Descrição do evento",
    eventAction: "Ação",
    showPDF: "Visualizar PDF",
    notEmptyEvent: "Preencha a descrição e ação do evento",
  },
  availability: {
    deleteSuccess: "Registro deletado com sucesso!",
    deleteError: "Erro ao deletar registro.",
    saveSuccess: "Configuração salva com sucesso!",
    saveConflict: "Houve um conflito",
    saveError: "Ocorreu um erro ao tentar salvar a configuração",
    configTitle: "Configuração de Disponibilidade",
    backButton: "Voltar",
    addButton: "Adicionar",
    deleteConfirm: "Tem certeza que deseja deletar este registro?",
    deleteYes: "Sim",
    deleteNo: "Não",
    cancelButton: "Cancelar",
    saveButton: "Salvar",
    deviceName: "Dispositivo",
    model: "Modelo",
    startOffline: "Desligado em",
    endOffline: "Religado em",
    motivation: "Motivação",
    actions: "Ações",
    addFiles: "Adicionar Arquivos",
  },
};

export default ptBrSolarTranslations;
