import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*** PACOTE DE NOTIFICAÇÕES ***/
import { toastr } from "react-redux-toastr";

/*** PACOTE DE COMUNICAÇÃO COM API ***/
import axios from 'axios';
import { faCaretDown, faCaretUp, faSort } from "@fortawesome/free-solid-svg-icons";
// import { result } from "lodash";

/****** MODAL DE EDIÇÃO DE DASHBOARD ******/
const ModalCopyDash = ({ open, toggle, dash, tableReload }) => {
  const { t } = useTranslation();

  const [loadUsersComp, setLoadUsersComp] = useState(true);

  const [users, setUsers] = useState([])
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);

  useEffect(() => {
    // Busca usuários da empresa (e empresas-filhas)
    if (loadUsersComp) {

      axios.get('/getCopyDashboard')
        .then(res => {

          setUsers(res.data.userDetails);
          setLoadUsersComp(false);
          // setLoadUsersDashboard(true);
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar usuários da empresa."); // t() da problema com o useEffect
        });
    }

  }, [loadUsersComp]);



  function onSubmitForm() {
    /* Salvar dados do formulário no formato JSON */

    // console.log(dash);
    const jsonData = {
      usersId: selectedUsersIds,
      dashId: dash.dashId,
      dashName: dash.name,
      dashDescription: dash.description,
    };

    // console.log(jsonData);


    /*** Verificação antes de copiar dados ***/
    axios.post('verifyCopyDashboard/', JSON.stringify(jsonData))
      .then(result1 => {
        // console.log(result1, 'verifyCopyDashboard');

        if (result1.data.length) {

          if (result1.data.some(item => item.allowed)) {
            toastr.confirm(
              t('dashboards.dashboardConfirmToastr'), {
              onOk: () => {
                let userProjLinks = []

                result1.data.forEach(item => {
                  if (item.allowed) {
                    let linkToAdd = {
                      userId: item.userId,
                      projId: item.cardProjId
                    }
                    if (!userProjLinks.includes(linkToAdd)) {
                      userProjLinks.push(linkToAdd)
                    }
                  }
                })
                // console.log("addUserToDevice", JSON.stringify(userProjLinks));

                // Vincular usuários aos projetos, depois copiar cards
                axios.post('addUserToDevice/', JSON.stringify({
                  userProjLinks
                }))
                  .then((result2) => {
                    // toastr.success(result.data.messageHead, result.data.messageBody);
                    // console.log(result2, "addUserToDevice");


                    /*** Copia dashboard ***/
                    axios.post('postCopyDashboard/', jsonData)
                      .then(result3 => {
                        // console.log(result3, "postCopyDashboard");
                        toastr.success(result3.data.messageHead, result3.data.messageBody);
                        toggle();
                      })
                      .catch(err => {
                        console.log(err);
                        console.log(err.response);

                        if (err.response.data) {
                          /* Notificação de erro de envio */
                          toastr.warning(
                            err.response.data.messageHead, err.response.data.messageBody
                          );
                        }
                      });

                  })
                  .catch(err => {
                    console.log(err);
                    console.log(err.response);

                    if (err.response.data) {
                      /* Notificação de erro de envio */
                      toastr.warning(
                        err.response.data.messageHead, err.response.data.messageBody
                      );
                    }
                  });

              },
              // Apenas Copiar cards
              onCancel: () => {
                /*** Envio de dados copiados ***/
                axios.post('postCopyDashboard/', jsonData)
                  .then(result3 => {
                    // console.log(result3, "postCopyDashboard");
                    toastr.success(result3.data.messageHead, result3.data.messageBody);
                    toggle();
                  })
                  .catch(err => {
                    console.log(err);
                    console.log(err.response);

                    if (err.response.data) {
                      /* Notificação de erro de envio */
                      toastr.warning(
                        err.response.data.messageHead, err.response.data.messageBody
                      );
                    }
                  });
              }
            }
            )
          }

          else { // copiar cards, e avisar que alguns usuários não podem acessar alguns cards
            /*** Envio de dados copiados ***/
            axios.post('postCopyDashboard/', jsonData)
              .then(result3 => {
                toastr.success(result3.data.messageHead, result3.data.messageBody);
                // console.log(result3, "postCopyDashboard");
                toggle();
              })
              .catch(err => {
                console.log(err);
                console.log(err.response);

                if (err.response.data) {
                  /* Notificação de erro de envio */
                  toastr.warning(
                    err.response.data.messageHead, err.response.data.messageBody
                  );
                }
              });

          }
        }

        else {
          axios.post('postCopyDashboard/', jsonData)
            .then(result3 => {
              toastr.success(result3.data.messageHead, result3.data.messageBody);
              console.log(result3, "RESULT DO POST");
              toggle();
            })
            .catch(err => {
              console.log(err);
              console.log(err.response);

              if (err.response.data) {
                /* Notificação de erro de envio */
                toastr.warning(
                  err.response.data.messageHead, err.response.data.messageBody
                );
              }
            });
        }

      })
      .catch(err => {
        console.log(err);
        // console.log(err.response);

        if (err.response.data) {
          /* Notificação de erro de envio */
          toastr.warning(
            "err.response.data.messageHead", err.response.data.messageBody
          );
        }
      });



  };

  const tableColumns = [
    { // ID (oculto)
      dataField: "userId",
      text: `${t('projTable.id')}`,
      sort: true,
      hidden: true,
    },
    {
      dataField: "userName",
      text: t('dashboards.name'),
      sort: true,
      headerStyle: { width: "50%" },
    },
    {
      dataField: "companyName",
      text: t('dashboards.companyName'),

      headerStyle: { width: "100%" },
      sort: true
    },
  ];
  const selectRow = {
    mode: "checkbox",
    bgColor: "#f8f9fa",
    selected: selectedUsersIds,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedUsersIds([...selectedUsersIds, row.userId]);
      } else {
        setSelectedUsersIds(selectedUsersIds.filter(x => x !== row.userId));
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        let ids = [];
        rows.map(row => ids.push(row.userId));
        setSelectedUsersIds(ids);
      } else {
        setSelectedUsersIds([]);
      }
    }
  }
  const sortIcon = {
    sortCaret: (order) => (!order)
      ? <FontAwesomeIcon
        className={'fa-xs ml-1'}
        icon={faSort}
      />
      : (order === 'asc')
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faCaretUp}
        />
        : <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faCaretDown}
        />
  }
  const defaultSorted = [{
    dataField: 'userName', // if dataField is not match to any column you defined, it will be ignored.
    order: 'asc' // desc or asc
  }];

  return (

    <Modal

      isOpen={open}
      toggle={toggle}
    >

      <Form onSubmit={e => {
        e.preventDefault();
        onSubmitForm();
      }}>


        <ModalHeader toggle={toggle}>
          {t('dashboards.copyDashboard')} {dash.name}
        </ModalHeader>
        <Row>
          <ModalBody
            style={{
              maxHeight: "70vh",
              overflowY: "auto"
            }}
            className="mx-6"
          >

            {/*** CAMPO DE NOME ***/}
            <p className="mb-4">{t('dashboards.copyDashboardModal')} {dash.name}</p>
            <BootstrapTable
              bootstrap4
              keyField="userId"
              data={users}
              columns={tableColumns}
              condensed
              bordered={false}
              striped={true}
              selectRow={selectRow}
              sort={sortIcon}
              defaultSorted={defaultSorted}

            />
          </ModalBody>
        </Row>
        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button
            color='primary'
            className="mr-1 mb-1"
            onClick={toggle}
            outline
          >
            {t('dataTable.cancel')}
          </Button>
          {/*** BOTÃO DE ENVIAR DADOS ***/}
          <Button
            type='submit'
            color='primary'
            className="mr-1 mb-1"
          >
            {t('dashboards.save')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>

  );
};

export default ModalCopyDash;