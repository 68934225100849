import { useQueries } from "@tanstack/react-query";
import axios from "axios";
import setHours from "date-fns/setHours";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toastr } from "react-redux-toastr";
import {
  requestChart,
  requestDataExplorerInCsv,
  requestTable,
} from "./DataExplorerService";
import { useTranslation } from "react-i18next";

export const DataExplorerContext = createContext();

export const DataExplorerProvider = ({ children }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("dashboard");

  const [fetchLoading, setFetchLoading] = useState([]);

  const [endDate, setEndDate] = useState(new Date()); // data e hora atual
  const [startDate, setStartDate] = useState(
    setHours(endDate, endDate.getHours() - 1)
  ); // 1h antes da hora atual
  const [aggregation, setAggregation] = useState(""); // periodo selecionado para Agregação
  const [resolution, setResolution] = useState(""); // periodo selecionado para Resolução

  const [typeVariable, setTypeVariable] = useState(null); // tipo de variável selecionado para valores

  const [projects, setProjects] = useState([]);

  const [errorInitDatepicker, setInitDatepicker] = useState(false); // invalid1
  const [errorEndDatepicker, setEndDatepicker] = useState(false); // invalid2
  const [errorTreeSelect, setErrorTreeSelect] = useState(null); // invalid3 (tree select)

  const [errorResolution, setErrorResolution] = useState(false); // invalid5

  const [projectSelects, setProjectSelects] = useState([]);
  const [devicesSelecteds, setDevicesSelecteds] = useState([]);
  const [showModalSelectVariables, setShowModalSelectVariables] =
    useState(false);
  const [variablesSelect, setVariablesSelect] = useState([]);

  const [dataExplorerInfo, setDataExplorerInfo] = useState(null);
  const [dataTable, setDataTable] = useState(null);

  const openModalSelectVariables = () => {
    setShowModalSelectVariables(true);
  };
  const closeModalSelectVariables = () => {
    setShowModalSelectVariables(false);
  };

  const devicesQueries = useQueries({
    queries: projectSelects.map((project) => {
      return {
        queryKey: ["devices", project],
        queryFn: () => getDevicesProjects(project),
        staleTime: Infinity,
      };
    }),
  });
  const getDevicesProjects = useCallback(async (project) => {
    const response = await axios.get("/deviceSearch", {
      params: {
        projId: project,
      },
    });
    return response.data;
  }, []);

  const lisDevices = devicesQueries
    .filter((item) => item.isSuccess)
    .map((item) => item.data)
    .flat();
  const listDevicesLoading = devicesQueries.some((item) => item.isLoading);

  const variablesQueries = useQueries({
    queries: devicesSelecteds.map((device) => {
      return {
        queryKey: ["variables", device.info.id],
        queryFn: () => getVariablesDevices(device.info.id),
        staleTime: Infinity,
      };
    }),
  });

  const variablesLoading = variablesQueries.some((item) => item.isLoading);

  const getVariablesDevices = useCallback(
    async (deviceId) => {
      const response = await axios.get("/readVariables", {
        params: {
          id: deviceId,
        },
      });
      const device = lisDevices.find((dev) => dev.id === deviceId);
      return response.data.map((item) => {
        return {
          ...item,
          device,
        };
      });
    },
    [lisDevices]
  );
  const listVariables = variablesQueries
    .filter((item) => item.isSuccess)
    .map((item) => item.data)
    .flat();

  var today = new Date();
  useEffect(() => {
    if (aggregation === "Nenhum") {
      setResolution("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregation]);

  const handleChange = (nextTargetKeys) => {
    setVariablesSelect(nextTargetKeys);
  };

  const searchDataValues = async (tab, changePage) => {
    // se o startDate é maior ou igual ao endDate
    if (Date.parse(startDate) >= Date.parse(endDate)) {
      setInitDatepicker(true);
      setEndDatepicker(true);
      setErrorTreeSelect(null);
      setErrorResolution(false);

      toastr.warning(
        `${t("dataExplorer.attention")}!`,
        `${t("dataExplorer.warningStartEndDate")}!`
      );
      return;
    }

    // se não foi selecionado o device
    if (!variablesSelect.length) {
      setInitDatepicker(false);
      setEndDatepicker(false);
      setErrorTreeSelect("error");
      setErrorResolution(false);

      toastr.warning(
        `${t("dataExplorer.attention")}!`,
        `${t("dataExplorer.selectVariables")}!`
      );
      return;
    }

    // se não foi selecionada a resolução, tendo selecionado o tipo de agregação
    if (
      !(aggregation === "" || aggregation === "Nenhum") &&
      resolution === ""
    ) {
      setInitDatepicker(false);
      setEndDatepicker(false);
      setErrorTreeSelect(null);
      setErrorResolution(true);

      toastr.warning(
        `${t("dataExplorer.attention")}!`,
        `${t("dataExplorer.selectAggregation")}!`
      );
      return;
    }

    // se a resolução for maior que o intervalo de tempo selecionado
    if (Date.parse(endDate) - Date.parse(startDate) < resolution) {
      setInitDatepicker(false);
      setEndDatepicker(false);
      setErrorTreeSelect(null);
      setErrorResolution(true);

      toastr.warning(
        `${t("dataExplorer.attention")}!`,
        `${t("dataExplorer.warningResolutionDate")}!`
      );
      return;
    }

    setInitDatepicker(false);
    setEndDatepicker(false);
    setErrorTreeSelect(null);
    setErrorResolution(false);

    // interpreta nenhuma agregação selecionada como a opção "Nenhum"
    if (aggregation === "") {
      setAggregation("Nenhum");
      setResolution("");
    }

    const now = new Date();
    const timezoneOffsetMinutes = now.getTimezoneOffset();

    const groupedVariables = variablesSelect.reduce((acc, variable) => {
      const parsed = JSON.parse(variable);
      const device = parsed.device;
      const key = device.id;
      if (!acc[key]) {
        acc[key] = {
          deviceId: device.id,
          description: device.description,
          variables: [],
          title: [],
        };
      }
      acc[key].variables.push(parsed.tag);
      acc[key].title.push(parsed.varName);
      return acc;
    }, {});

    const params = {
      startDate: startDate ? Math.floor(startDate.getTime() / 1000) : "",
      endDate: endDate ? Math.floor(endDate.getTime() / 1000) : "",
      devicesAndVariables: Object.values(groupedVariables),
      aggregation: aggregation,
      resolution: resolution,
      typeVariable: typeVariable,
      timezoneOffsetMinutes,
    };

    if (tab !== "export") {
      setFetchLoading([...fetchLoading, "dashboard"]);
      try {
        const [request, table] = await Promise.all([
          requestChart(params),
          requestTable(params),
        ]);
        setDataTable(table);
        setDataExplorerInfo({
          ...request,
          sendedParams: params,
        });
        setFetchLoading((prev) =>
          prev.filter((loading) => loading !== "dashboard")
        );
        if (request.maxSizeInBytes) {
          toastr.warning(
            `${t("dataExplorer.attention")}!`,
            `${t("dataExplorer.maxSizeInBytes")}!`
          );
        }
      } catch (error) {
        console.error("Error fetching chart:", error);
        toastr.error(
          `${t("dataExplorer.error")}!`,
          `${t("dataExplorer.errorFetchingData")}!`
        );
        setFetchLoading((prev) =>
          prev.filter((loading) => loading !== "dashboard")
        );
        setDataTable(null);
        setDataExplorerInfo(null);
      }
    }

    if (tab === "export") {
      setFetchLoading([...fetchLoading, "export"]);
      const request = await requestDataExplorerInCsv(params);

      if (request.error) {
        toastr.error(`${t("dataExplorer.error")}!`, request.message);
      } else {
        toastr.success(`${t("dataExplorer.success")}!`, request.message);
      }

      setFetchLoading((prev) => prev.filter((loading) => loading !== "export"));
    }
  };

  useEffect(() => {
    //NOTE: Carrega a lista de projetos
    axios
      .get("/projectUserSearch")
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  }, []);

  return (
    <DataExplorerContext.Provider
      value={{
        activeTab,
        setActiveTab,
        searchDataValues,
        errorInitDatepicker,
        errorEndDatepicker,
        errorTreeSelect,
        errorResolution,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        aggregation,
        setAggregation,
        resolution,
        setResolution,
        typeVariable,
        setTypeVariable,
        fetchLoading,
        setFetchLoading,
        projects,
        projectSelects,
        setProjectSelects,
        devicesSelecteds,
        setDevicesSelecteds,
        lisDevices,
        variablesSelect,
        setVariablesSelect,
        openModalSelectVariables,
        showModalSelectVariables,
        closeModalSelectVariables,
        listVariables,
        handleChange,
        today,
        dataExplorerInfo,
        dataTable,
        listDevicesLoading,
        variablesLoading,
      }}
    >
      {children}
    </DataExplorerContext.Provider>
  );
};

export function useDataExplorer() {
  const context = useContext(DataExplorerContext);
  if (context === undefined) {
    throw new Error("Context must be used within a DataExplorerProvider");
  }
  return context;
}
