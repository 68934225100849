import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";

export const SidebarHeader = ({ open, title }) => {
  const { t } = useTranslation();

  return (
    <Row
      style={{
        width: open ? "240px" : "72px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "rgb(32, 33, 36)",
        transition: "width 0.1s ease-in-out",
      }}
      className="sidebar-header"
    >
      {t(`${title}`)}
    </Row>
  );
};
