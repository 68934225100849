import { useSelector } from "react-redux";

export const IconModule = ({ IconComponent, moduleValue, ...props }) => {
    const currentTheme = useSelector((store) => store.theme.currentTheme);
  
    const colorByValue = (value) => {
      return value > 0 ? currentTheme.primary : currentTheme.disabled;
    };
  
    const pointerByValue = (value) => {
      return value > 0 ? "pointer" : "not-allowed";
    };
  
    return (
      <IconComponent
        cursor={pointerByValue(moduleValue)}
        size="12px"
        color={moduleValue > 0 ? currentTheme.info : currentTheme.disabled}
        style={{
          filter: `drop-shadow(0 0 3px ${colorByValue(moduleValue)})`,
        }}
        {...props}
      />
    );
  };
  