// import React, { useState } from "react";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
    Row, Col,
    Spinner,
    // UncontrolledTooltip
} from "reactstrap";
// import {
//     faPen,
//     faTrash,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';

// import ModalDeleteDriver from "./ModalDeleteDriver"
// import ModalEditDriver from "./ModalEditDriver";


const ExpandedRowData = ({ varList }) => {

    const { t } = useTranslation();

    /*** busca usuários da API ***/
    // const [isLoading, setLoading] = useState(true);
    /* array de usuários selecionados */

    // const ActionButtonsComp = ({ comp, tableReload }) => {
    //     const { t } = useTranslation();

    //     const [openEditVar, setOpenEditVar] = useState(false);
    //     const [openDeleteVar, setOpenDeleteVar] = useState(false);

    //     // ativa/desativa modais
    //     function toggleEditComp() {
    //         setOpenEditVar(!openEditVar);
    //     }
    //     function toggleDeleteComp() {
    //         setOpenDeleteVar(!openDeleteVar);
    //     }

    //     return (
    //         <div className="d-flex justify-content-left">
    //             <div // botão de editar
    //                 id={"editComp" + comp.id}
    //                 className="align-middle mr-1 cursor-pointer"
    //                 onClick={() => setOpenEditVar(true)}
    //             >
    //                 <FontAwesomeIcon icon={faPen} fixedWidth />
    //                 {/*** MODAL DE EDITAR EMPRESA  ***/}
    //                 {(openEditVar)
    //                     ? <ModalEditDriver
    //                         open={openEditVar}
    //                         toggle={toggleEditComp}
    //                         comp={comp}
    //                         tableReload={tableReload}
    //                     />
    //                     : null
    //                 }
    //                 {/* Tooltip */}
    //                 <UncontrolledTooltip
    //                     placement="top"
    //                     target={"editComp" + comp.id}
    //                 >
    //                     {t('dataTable.editComp')}
    //                 </UncontrolledTooltip>
    //             </div>

    //             <div // botão de remover
    //                 id={"deleteComp" + comp.id}
    //                 className="align-middle cursor-pointer"
    //                 onClick={() => {
    //                     (comp.tag.toString() === localStorage.getItem('tag'))
    //                         ? toastr.warning(t('dataTable.att'), t('dataTable.deleteNotComp'))
    //                         : setOpenDeleteVar(true)
    //                 }}
    //             >
    //                 <FontAwesomeIcon icon={faTrash} fixedWidth />
    //                 {/*** MODAL DE REMOVER EMPRESA  ***/}
    //                 {(openDeleteVar)
    //                     ? <ModalDeleteDriver
    //                         open={openDeleteVar}
    //                         toggle={toggleDeleteComp}
    //                         comp={comp}
    //                         tableReload={tableReload}
    //                     />
    //                     : null
    //                 }
    //                 {/* Tooltip */}
    //                 <UncontrolledTooltip
    //                     placement="top"
    //                     target={"deleteComp" + comp.id}
    //                 >
    //                     {t('dataTable.deleteComp')}
    //                 </UncontrolledTooltip>
    //             </div>
    //         </div>
    //     )
    // };

    const colsUsr = [
        {
            dataField: "id",
            text: "varId",
            sort: true,
            hidden: true
        },
        { // ID (oculto)
            dataField: "compayId",
            text: "Empresa",
            sort: true,
            hidden: true
        },
        { // nome do driver
            dataField: "varName",
            text: `${t('addNewDriver.driverNameExpandedRow')}`,
            sort: true,
        },
        { // descrição da variável
            dataField: "varDescription",
            text: `${t('addNewDriver.driverDescriptionExpandedRow')}`,
            sort: true,
            headerStyle: { width: "25%" }
        },
        {
            dataField: "tag",
            text: `${t('addNewDriver.driverTagExpandedRow')}`,
            sort: true,
        },
        {
            dataField: "unit",
            text: `${t('addNewDriver.driverUnitExpandedRow')}`,
            sort: true,
        },
        {
            dataField: "varType",
            text: `${t('addNewDriver.driverTypeExpandedRow')}`,
            sort: true,

        },
        {
            dataField: "measureType",
            text: `${t('addNewDriver.driverMeasureExpandedRow')}`,
            sort: true,
        },
    ];



    /* exibe enquanto promise do axios não resolve */
    // if (isLoading && !varList) {
    if (!varList) {
        return <div className="text-center"><Spinner color="primary" /></div>;
    }

    /*** RENDERIZA SUB-TABELA ***/
    return (varList.length)
        ? (
            <div key={varList.tag} className='my-2'>
                {console.log(varList)}
                <div>
                    <Row className="mb-2 align-items-center">
                        <Col md="4" xs="4" className="text-left">
                        </Col>
                        <Col md="8" xs="8" className="text-right">

                        </Col>
                    </Row>
                </div>
                <BootstrapTable
                    bootstrap4
                    keyField="tag"
                    data={varList}
                    columns={colsUsr}
                    condensed
                    bordered={false}
                    striped={true}
                // sort={sortIcon}
                // selectRow={selectRow}
                />
            </div>
        )
        : <p className="text-center">
            {t('addNewDriver.noVariablesAdded')}.
        </p>
};

export default ExpandedRowData;