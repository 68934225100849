import enGB from "date-fns/locale/en-GB";
import ptBR from "date-fns/locale/pt-BR";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { toastr } from "react-redux-toastr";
import { MultiSelect } from "../../../../../../components/MultiSelect";
import { useConsumptionSharingContext } from "../ConsumptionSharingProvider";
import { listUcs, listPlants } from "../ConsumptionSharingService";

const FormParams = () => {
  const { id, refId } = useParams();
  const { i18n, t } = useTranslation();

  const { handleSubmit } = useConsumptionSharingContext();

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [plantId, setPlantId] = useState(null);
  const [ucs, setUcs] = useState([]);
  const [bill, setBill] = useState(null);

  const [selectUcs, setSelectUcs] = useState([]);

  const [plants, setPlants] = useState([]);

  const handleSelectCompany = () => {
    // eslint-disable-next-line eqeqeq
    setUcs([]);
    setSelectUcs([]);
    getPlants(refId, id);
  };

  const handleSelectPlant = (plantId) => {
    setPlantId(plantId);
    getUcs(plantId);
  };

  const getPlants = async (refId, id) => {
    const plants = await listPlants(refId);
    const filteredPlants = plants.filter(plant => plant.id === parseInt(id));
    setPlants(filteredPlants);
  };

  const getUcs = async (projId) => {
    const data = await listUcs(projId);

    if (!data?.ucs.length) {
      toastr.info(
        t("formParams.noUcs"),
      );
      return setUcs([]);
    };
    setUcs(data?.ucs);
  };

  useEffect(() => {
    handleSelectCompany(refId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refId]);

  return (
    <Form style={{ width: "100%" }}>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0 b-0">
          {t("formParams.params")}
        </CardTitle>
      </CardHeader>

      <CardBody style={{ padding: "0.10rem 1rem" }}>
        <Row className="alarm-search">
          <Col>
            {/* Período Inicial*/}
            <FormGroup className="mb-0 d-flex flex-column">
              <Label>{t("formParams.periodInit")}</Label>
              <DatePicker
                placeholderText="Seleciona uma data"
                selected={start}
                maxDate={end}
                onChange={(date) => {
                  setStart(date);
                }}
                locale={i18n.language === "br" ? ptBR : enGB}
                dateFormat={
                  i18n.language === "en" ? "MMMM d, yyyy" : "dd/MM/yyyy"
                }
                className="form-control width-75"
              />
            </FormGroup>
            {/* Planta */}
            <FormGroup className="mb-0 d-flex flex-column">
              <Label>{t("formParams.plant")}</Label>
              <Input
                type="select"
                name="plant"
                value={plantId?.id}
                onChange={({ target }) => {
                  const { value } = target;
                  handleSelectPlant(value);
                }}
                disabled={!plants?.length}
              >
                <option hidden>Selecione</option>

                {plants?.map((plant) => (
                  <option key={plant.id} value={plant.id}>
                    {plant.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col>
            {/* Período Final */}
            <FormGroup className="mb-0 d-flex flex-column">
              <Label>{t("formParams.periodEnd")}</Label>
              <DatePicker
                placeholderText="Seleciona uma data"
                selected={end}
                minDate={start}
                onChange={(date) => {
                  setEnd(date);
                }}
                locale={i18n.language === "br" ? ptBR : enGB}
                dateFormat={
                  i18n.language === "en" ? "MMMM d, yyyy" : "dd/MM/yyyy"
                }
                className="form-control width-75"
              />
            </FormGroup>
            {/* Unidades de Consumo */}
            <FormGroup className="mb-0">
              <Label>{t("formParams.unitConsumption")}</Label>
            </FormGroup>
            <MultiSelect
              disabled={!ucs.length}
              options={ucs.map((e) => ({ ...e, id: e.ucId }))}
              setValue={setSelectUcs}
              values={selectUcs}
            />
          </Col>
          <Col>
            {/* Valor da fatura */}
            <FormGroup className="mb-0 d-flex flex-column">
              <Label>{t("formParams.invoiceValue")}</Label>
              <Input
                type="number"
                name="bill"
                value={bill}
                onChange={({ target }) => {
                  const { value } = target;
                  setBill(value);
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <CardFooter
              style={{ marginTop: "0.6rem" }}
              className="d-flex justify-content-center"
            >
              <Button
                style={{ "&:hover": { backgroundColor: "green" } }}
                color="primary"
                onClick={() => {
                  const params = {
                    start: new Date(start).toISOString(),
                    end: new Date(end).toISOString(),
                    ucs: selectUcs,
                    bill,
                  };

                  if (!selectUcs.length) return toastr.warning(t("formParams.noUcs"));
                  if (!bill) return toastr.warning(t("formParams.billWarning"));
                  handleSubmit(plantId, params);
                }}
              >
                {t("formParams.view")}
              </Button>
            </CardFooter>
          </Col>
        </Row>
      </CardBody>
    </Form>
  );
};

export default FormParams;
