import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form, FormGroup, Input, Label,
  Modal, ModalHeader, ModalBody, ModalFooter, 
  Col,
} from "reactstrap";

/*** PACOTE DE NOTIFICAÇÕES ***/
import { toastr } from "react-redux-toastr";

/*** PACOTE DE COMUNICAÇÃO COM API ***/
import axios from 'axios';

/****** MODAL DE EDIÇÃO DE DASHBOARD ******/
const ModalEditDash = ({open, toggle, dash, tableReload}) => {
  const { t } = useTranslation();

  const [name, setName] = useState(dash.name);
  const [description, setDescription] = useState(dash.description);

  function onSubmitForm() {
    /* Salvar dados do formulário no formato JSON */
    const jsonData = JSON.stringify({
      dashId: dash.dashId,
      name: name,
      description: description,
    });

    /*** Envio de dados ***/
    axios.put('editDashboard/', jsonData)
    .then(result => {
      toastr.success(result.data.messageHead, result.data.messageBody);
      toggle();
      tableReload();
    })
    .catch(err => {
      console.log(err);
      console.log(err.response);

      if (err.response.data) {
        /* Notificação de erro de envio */
        toastr.warning(
          err.response.data.messageHead, err.response.data.messageBody
        );
      }
    });
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <Form onSubmit={e => {
        e.preventDefault();
        onSubmitForm();
      }}>
        <ModalHeader toggle={toggle}>
          {t('dashboards.editDashboards')}
        </ModalHeader>

        <ModalBody className="m-3">
            {/*** CAMPO DE NOME ***/}
            <FormGroup row>
              <Label sm={2} className="text-sm-right">
                {t('dashboards.name')}
              </Label>
              <Col sm={10}>
                <Input 
                  type="text" 
                  name="name" 
                  id="name"
                  placeholder={t('dashboards.name')} 
                  required
                  value={name}
                  onChange={e=>setName(e.target.value)}
                />
              </Col>
            </FormGroup>

            {/*** CAMPO DE DESCRIÇÃO ***/}
            <FormGroup row>
            <Label sm={2} className="text-sm-right">
            {t('dashboards.description')}
            </Label>
            <Col sm={10}>
              <Input
                type="textarea"
                description="description"
                id="description"
                placeholder={t('dashboards.description')}
                rows="3"
                required
                value={description}
                onChange={e=>setDescription(e.target.value)}
              />
            </Col>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button 
            color='primary' 
            className="mr-1 mb-1" 
            onClick={toggle}
            outline
          >
            {t('dataTable.cancel')}
          </Button>
          {/*** BOTÃO DE ENVIAR DADOS ***/}
          <Button 
            type='submit'
            color='primary'
            className="mr-1 mb-1"
          >
            {t('dashboards.save')}
          </Button>
        </ModalFooter>
      </Form> 
    </Modal>
  );
};
  
export default ModalEditDash;