import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Check, Trash2 } from "react-feather";
import { useSelector } from "react-redux";
import { useServiceAuthContext } from "../../../ServiceAuthorizationsProvider";
import { useTranslation } from "react-i18next";
import { deleteFile } from "../../../ServiceAuthorizationsService";

export const FileInput = () => {
  const currentTheme = useSelector((store) => store.theme.currentTheme);
  const { t } = useTranslation();
  const { selectedFiles, setSelectedFiles, filesUploaded, modalEdit, setFilesUploaded } =
    useServiceAuthContext();
  const [data, setData] = useState([]);

  useEffect(() => {
    const combinedFiles = [
      ...selectedFiles.map((file) => ({ name: file.name })),
      ...filesUploaded.map((file) => ({ name: file.name, uploaded: true, key: file.key })),
    ];
    setData(combinedFiles);
  }, [selectedFiles, filesUploaded]);

  const handleChangeFile = (event) => {
    const fileList = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...fileList]);
  };

  const handleRemoveFile = (fileName) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== fileName);
    setSelectedFiles(updatedFiles);
  };

  const handleDeleteUploadedFile = async (row) => {
    if (window.confirm(`${t("dataTable.deleteHead")} ${row.name}?`)) {
      const response = await deleteFile(row.key, modalEdit.id);
      if (response) {
        setFilesUploaded((prev) => prev.filter((file) => file.name !== row.name));
      }
    }
  };

  const tableColumns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cell) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "200px",
          }}
          title={cell}
        >
          {cell}
        </div>
      ),
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (cell, row) => (
        <div>
          {row.uploaded && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-top`}>{t("file.uploaded")}</Tooltip>
              }
            >
              <Check size={16} />
            </OverlayTrigger>
          )}
          <Trash2
            size={16}
            onClick={
              row.uploaded
                ? () => handleDeleteUploadedFile(row)
                : () => handleRemoveFile(row.name)
            }
            cursor="pointer"
          />
        </div>
      ),
    },
  ];

  return (
    <Col xs={12} className="p-0 mt-2">
      <Form.Group
        className="d-flex justify-content-center align-items-center"
        style={{ border: `1px dashed ${currentTheme.primary}` }}
      >
        <label htmlFor="files" className="w-100 text-center">
          {t("noc.form.addFiles")}
        </label>
        <input
          className="d-none"
          type="file"
          id="files"
          multiple
          onChange={handleChangeFile}
        />
      </Form.Group>
      {data.length > 0 && (
        <div style={{ maxHeight: "20vh", overflowY: "auto" }}>
          <BootstrapTable
            bootstrap4
            keyField="name"
            data={data}
            columns={tableColumns}
            bordered={false}
            striped
          />
        </div>
      )}
    </Col>
  );
};
