import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardTitle,
  Input,
  Spinner,
} from "reactstrap";

import axios from "axios";
import { RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import noDevice from "../../../../../../assets/devices/noDevice.png";
import ModalDeviceVar from "../../../ModalDeviceVar";

export const DeviceView = ({ device, devices }) => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  const [loadingVars, setLoadingVars] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [deviceVars, setDeviceVars] = useState([]);
  const [deviceNobreakVars, setDeviceNobreakVars] = useState([]);
  const [dataGet, setDataGet] = useState();
  const [currentVar, setCurrentVar] = useState("");

  const lastCommDate = new Date(device.lastComm * 1000);
  const timestampNow = Math.floor(Date.now() / 1000);

  useEffect(() => {
    let isUnmount = false;
    if (loadingVars) {
      // busca variaveis a serem lidas
      if (!isUnmount) {
        axios
          .get("/readNobreakVariables", {
            params: {
              id: device.id,
            },
          })
          .then((res) => {
            setDeviceNobreakVars(res.data);
            setLoadingVars(false);
          })
          .catch((err) => {
            setLoadingVars(false);
          });

        if (deviceNobreakVars) {
          // busca dados

          axios
            .get("/readDataLast", {
              params: {
                idDevice: device.id,
              },
            })

            .then((res) => {
              setDataGet(res.data);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => setLoadingData(false));
        }
      }
    }
    return () => {
      isUnmount = true;
    };
  }, [device.id, loadingVars, loadingData]);

  useEffect(() => {
    let isUnmount = false;
    if (loadingVars) {
      // busca variaveis a serem lidas
      if (!isUnmount) {
        axios
          .get("/readVariables", {
            params: {
              id: device.id,
            },
          })
          .then((res) => {
            setDeviceVars(res.data);
            setLoadingVars(false);
          })
          .catch((err) => {
            console.log(err);
            setLoadingVars(false);
          });

        if (loadingData) {
          // busca dados

          axios
            .get("/readDataLast", {
              params: {
                idDevice: device.id,
              },
            })

            .then((res) => {
              setDataGet(res.data);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => setLoadingData(false));
        }
      }
    }
    return () => {
      isUnmount = true;
    };
  }, [device.id, loadingVars, loadingData]);

  return (
    <Card style={{ height: "90%" }}>
      <CardHeader>
        <div className="card-actions float-right">
          <span className="cursor-pointer mr-1">
            <RefreshCw onClick={() => setLoadingData(true)} />
          </span>{" "}
        </div>
        {/* <Link to={`/variables-${device.id}`}> */}

        <CardTitle
          style={{ whiteSpace: "nowrap" }}
          tag="h5"
          className="mb-2 overflow-hidden"
        >
          {device.description}
        </CardTitle>
        {/* </Link> */}
        <p
          style={{ whiteSpace: "nowrap" }}
          className="card-subtitle mb-2 text-muted d-flex overflow-hidden"
        >
          {device.model}
        </p>
      </CardHeader>
      <CardBody
        style={{ alignItems: "center", gap: ".5rem" }}
        className="d-flex flex-column text-center"
      >
        <ModalDeviceVar
          open={modal}
          toggle={openModal}
          device={device}
          devices={devices}
          deviceNobreakVars={deviceNobreakVars}
          deviceVars={deviceVars}
          dataGet={dataGet}
        />
        <CardImg
          role={!dataGet ? "" : "button"}
          onClick={openModal}
          style={{ width: "5rem" }}
          src={device.image === null ? noDevice : device.image}
        />
        {loadingVars || loadingData ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : deviceVars && dataGet ? (
          device.lastComm >= timestampNow - device.commInterval * 2 ? (
            <div>
              <Input
                type="select"
                id="dataSelect"
                name="dataSelect"
                className="mb-3"
                defaultValue={""}
                onChange={(e) => setCurrentVar(e.currentTarget.value)}
              >
                <option key={0} value={""} disabled>
                  {t("devices.selectData")}
                </option>
                {deviceVars.map((v, i) => (
                  <option key={i} value={v.tag}>
                    {v.varName}
                  </option>
                ))}
              </Input>
              {currentVar ? (
                dataGet[currentVar] ||
                dataGet[currentVar] === false ||
                dataGet[currentVar] === 0 ? (
                  <h1 className="display-5 mt-1 mb-3 text-center">
                    {dataGet[currentVar] + " "}
                    {deviceVars.find((v) => v.tag === currentVar).unit}
                  </h1>
                ) : (
                  <p className="pt-3 mb-3 text-center">
                    {t("devices.noVarData")}
                  </p>
                )
              ) : null}
            </div>
          ) : (
            t("devices.offline")
          )
        ) : (
          t("devices.noData")
        )}
      </CardBody>

      <CardFooter>
        {device.lastComm
          ? t("devices.lastComm") +
            ": " +
            lastCommDate.getDate().toString().padStart(2, "0") +
            "/" +
            (lastCommDate.getMonth() + 1).toString().padStart(2, "0") +
            "/" +
            lastCommDate.getFullYear().toString().padStart(2, "0") +
            " " +
            lastCommDate.getHours().toString().padStart(2, "0") +
            ":" +
            lastCommDate.getMinutes().toString().padStart(2, "0") +
            ":" +
            lastCommDate.getSeconds().toString().padStart(2, "0")
          : t("devices.noComm")}
      </CardFooter>
    </Card>
  );
};
