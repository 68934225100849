// NOTE: Alarmes
import ptBrAlarmsTranslations from './Alarms/pt-br';
import enUsAlarmsTranslations from './Alarms/en-us';

// NOTE: Módulo Climate
import ptBrClimateTranslations from './ClimateModule/pt-br';
import enUsClimateTranslations from './ClimateModule/en-us';

// NOTE: Dashboard
import ptBrDashboardTranslations from './Dashboard/pt-br';
import enUsDashboardTranslations from './Dashboard/en-us';

// NOTE: Data Explorer
import ptBrDataExplorerTranslations from './DataExplorer/pt-br';
import enUsDataExplorerTranslations from './DataExplorer/en-us';

// NOTE: Drivers
import ptBrDriversTranslations from './Drivers/pt-br';
import enUsDriversTranslations from './Drivers/en-us';

// NOTE: Módulo de Energia
import ptBrEnergyHomeTranslations from './EnergyModule/Home/pt-br';
import ptBrEnergyPlantTranslations from './EnergyModule/Plant/pt-br';
import ptBrEnergySharingTranslations from './EnergyModule/Sharing/pt-br';
import ptBrEnergyAlarmTranslations from './EnergyModule/Alarm/pt-br';

import enUsEnergyHomeTranslations from './EnergyModule/Home/en-us';
import enUsEnergyPlantTranslations from './EnergyModule/Plant/en-us';
import enUsEnergySharingTranslations from './EnergyModule/Sharing/en-us';
import enUsEnergyAlarmTranslations from './EnergyModule/Alarm/en-us';

// NOTE: Gateway
import ptBrGatewayTranslations from './Gateway/pt-br';
import enUsGatewayTranslations from './Gateway/en-us';

// NOTE: Global
import ptBrGlobalTranslations from './Global/pt-br';
import enUsGlobalTranslations from './Global/en-us';

// NOTE: Home
import ptBrHomeTranslations from './Home/pt-br';
import enUsHomeTranslations from './Home/en-us';

// NOTE: Layouts
import ptBrLayoutsTranslations from './Layouts/pt-br';
import enUsLayoutsTranslations from './Layouts/en-us';

// NOTE: Login
import ptBrLoginTranslations from './Login/pt-br';
import enUsLoginTranslations from './Login/en-us';

// NOTE: Módulo NoBreak
import ptBrNoBreakModuleTranslations from './NoBreakModule/pt-br';
import enUsNoBreakModuleTranslations from './NoBreakModule/en-us';

// NOTE: Projetos
import ptBrProjectsTranslations from './Projects/pt-br';
import enUsProjectsTranslations from './Projects/en-us';

// NOTE: Módulo Solar
import ptBrSolarTranslations from './SolarModule/pt-br';
import enUsSolarTranslations from './SolarModule/en-us';

// NOTE: User
import ptBrUserTranslations from './User/pt-br';
import enUsUserTranslations from './User/en-us';

// NOTE: Módulo Água
import ptBrWaterTranslations from './WaterModule/pt-br';
import enUsWaterTranslations from './WaterModule/en-us';
import ptBrWaterHomeTranslations from './WaterModule/Home/pt-br';
import enUsWaterHomeTranslations from './WaterModule/Home/en-us';

import ptBrNetworkOperationsCenter from './NetworkOperationsCenter/pt-br';
import enUsNetworkOperationsCenter from './NetworkOperationsCenter/en-us';

// NOTE: Módulo de Gas
import ptBrGasTranslations from './GasModule/pt-br';
import enUsGasTranslations from './GasModule/en-us';

const ptBrTranslations = {
  translations: {
    ...ptBrAlarmsTranslations,
    ...ptBrClimateTranslations,
    ...ptBrDashboardTranslations,
    ...ptBrDataExplorerTranslations,
    ...ptBrDriversTranslations,

    ...ptBrEnergyAlarmTranslations,
    ...ptBrEnergyHomeTranslations,
    ...ptBrEnergyPlantTranslations,
    ...ptBrEnergySharingTranslations,

    ...ptBrGatewayTranslations,
    ...ptBrGlobalTranslations,
    ...ptBrHomeTranslations,
    ...ptBrLayoutsTranslations,
    ...ptBrLoginTranslations,
    ...ptBrNoBreakModuleTranslations,
    ...ptBrProjectsTranslations,
    ...ptBrSolarTranslations,
    ...ptBrUserTranslations,
    
    ...ptBrWaterTranslations,
    ...ptBrWaterHomeTranslations,

    ...ptBrNetworkOperationsCenter,
    ...ptBrGasTranslations


  }
}

const enUsTranslations = {
  translations: {
    ...enUsAlarmsTranslations,
    ...enUsClimateTranslations,
    ...enUsDashboardTranslations,
    ...enUsDataExplorerTranslations,
    ...enUsDriversTranslations,

    ...enUsEnergyHomeTranslations,
    ...enUsEnergyPlantTranslations,
    ...enUsEnergySharingTranslations,
    ...enUsEnergyAlarmTranslations,

    ...enUsGatewayTranslations,
    ...enUsGlobalTranslations,
    ...enUsHomeTranslations,
    ...enUsLayoutsTranslations,
    ...enUsLoginTranslations,
    ...enUsNoBreakModuleTranslations,

    ...enUsProjectsTranslations,
    ...enUsSolarTranslations,
    ...enUsUserTranslations,

    ...enUsWaterTranslations,
    ...enUsWaterHomeTranslations,
    
    ...enUsNetworkOperationsCenter,
    ...enUsGasTranslations
  }
}

const translations = {
  'br': ptBrTranslations,
  'en': enUsTranslations
};

export default translations