const ptBrNoBreakModuleTranslations = {
    noBreak:{
        deviceList:"Lista de dispositivos",
        inputs: "Entrada",
        outputs: "Saída",
        frequency: "Frequencia",
        tension: "Tensão",
        capacity: "Capacidade em uso",
        current: "Corrente",
        battery: "Bateria",
        timeOfAutonomy: "Tempo de autonomia",
        mode: "Modo de operação",
        temperature: "Temperatura",
        chargeLeft: "Carga restante",
        normalMode: "Normal",
        failMode: "Em falha",
        totalDevices: "Total de dispositivos",
        normalOperation:"Operação normal",
        alarms: "Alarmes",
        deviceInfo:"Informações sobre dispositivo",
        inputGraph:"Dados de entrada",
        batteryGraph: "Dados de bateria",
        outputGraph: "Dados de saida",
        noComm: "Não comunicou",
        batteryOperation: "Modo bateria",
        byPassOperation: "Modo by pass"
    },
}

export default ptBrNoBreakModuleTranslations;
