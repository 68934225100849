import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import '../../igCards';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card, CardBody, CardHeader, CardTitle,
  Form, FormGroup, Input, Label,
  Row, Col, Spinner
} from "reactstrap";
import axios from "axios";
import { toastr } from "react-redux-toastr";

import BarAmChartsPreview from "../cards/BarChartPreview";
import LineChartPreview from "../cards/LineChartPreview";

const NewChartCard = ({ cardType, cardProps }) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true); // carregando projetos
  const [isEditLoading, setEditLoading] = useState(false); // salvando edição
  const [projList, setProjList] = useState([]); // lista de projetos
  const [dvcList, setDvcList] = useState([]); // lista de devices do projeto selecionado
  const [varList, setVarList] = useState([]); // lista de variaveis do device selecionado

  const [name, setName] = useState((cardProps?.id) ? cardProps?.name : ""); // campo de nome
  const [description, setDescription] = useState((cardProps?.id) ? cardProps?.description : "") // campo de descricao
  const [deviceId, setDeviceId] = useState((cardProps?.id) ? cardProps?.devices : ""); // device selecionado
  const [variable, setVariable] = useState((cardProps?.id) ? cardProps?.variables : ""); // variavel selecionada
  const [reloadTime, setReloadTime] = useState((cardProps?.id) ? cardProps?.reloadTime : ""); // periodo selecionado para reload
  const [variableListIfExists, setVariableListIfExists] = useState([]); // variavel selecionada
  const [typeVariable, setTypeVariable] = useState((cardProps?.id) ? cardProps?.typeVariable : null); // tipo selecionado (apenas gráficos)
  const [period, setPeriod] = useState((cardProps?.id) ? cardProps?.period : ""); // periodo selecionado (apenas gráficos)
  const [cardDataToEdit, setCardDataToEdit] = useState();

  let history = useHistory();

  /**ADICIONA VARIÁVEL PARA PARA O ARRAY */
  function handAddNewVariable(selectedVariable) {
    let parsedVariable = JSON.parse(selectedVariable);
    let dvcId = deviceId.split(".")[0]
    let deviceName = deviceId.split(".")[1]

    parsedVariable = { ...parsedVariable, dvcId: dvcId, deviceName: deviceName };

    setVariableListIfExists([...variableListIfExists, parsedVariable]);
    setVariable("")
    setVariable("")
  }

  function handleDeleteVariable(index) {
    setVariableListIfExists(variableListIfExists.filter((item, i) => cardProps?.id ?(--index) : index !== i))
  }

  // chamada para trazer o card preenchido durante a edição do card
  useEffect(() => {
    if (cardProps?.id) {
      axios.get('/readDataOnEditCard', {
        params: {
          dvcId: cardProps.devices
        }
      })
        .then(res => {
          // console.log(res);
          setCardDataToEdit(res.data)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar projetos.");  // t() da problema com o useEffect
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios.get('/projectUserSearch')
      .then(response => {
        setProjList(response.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar projetos.");  // t() da problema com o useEffect
      });
  }, []);

  function onProjectChange(projId) {
    setDvcList([]); // limpa lista de dispositivos
    setVarList([]); // limpa lista de variaveis
    setDeviceId(""); // limpa dispositivo selecionado
    setVariable(""); // limpa variavel selecionada

    setLoading(true);
    axios.get('/deviceSearch', {
      params: {
        projId: projId
      }
    })
      .then(response => {
        setDvcList(response.data) // atualiza lista de dispositivos
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar dispositivos.");  // t() da problema com o useEffect
        setLoading(false);
      })
  };

  function onDeviceChange(dvcId) {

    setVarList([]); // limpa lista de variaveis
    setVariable(""); // limpa variavel selecionada

    setDeviceId(dvcId); // atualiza dispositivo selecionado
    setLoading(true);
    axios.get('/readVariables', {
      params: {
        id: dvcId
      }
    })
      .then(response => {
        setVarList(response.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar variáveis.");  // t() da problema com o useEffect
        setLoading(false);
      });
  };

  function onSubmitForm() {
    setEditLoading(true)
    setTimeout(() => {
      setEditLoading(false)
    }, 6000)
    if (name.length >= 50) {
      return toastr.warning("Cuidado!", "Título muito extenso.")
    }
    
    else if (!variableListIfExists.length) {
      return toastr.warning("Atenção", "Nenhuma variável selecionada")
    }

    else {
      const whArr = [6, 9];
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        dashId: localStorage.getItem('dashId'),
        // idCard: cardId,
        cardType: cardType,
        name: name,
        description: description,
        devices: deviceId.split(".")[0],
        variables: (variableListIfExists.length) ? JSON.stringify(variableListIfExists) : variable,
        // variables: variable,
        period: period,
        typeVariable: typeVariable,
        reloadTime: reloadTime,
        w: whArr[0],
        h: whArr[1],
        // variableListIfExists: variableListIfExists,
        rangeStructure: null,
        formula: null,
        decimalNumber: null,
        minValueProp: null,
        maxValueProp: null
      };
      // return console.log(jsonData);
      /*** Envio de dados ***/
      axios.post('/v2/dashboard/addCard', JSON.stringify(jsonData))
        .then(result => {
          toastr.success(result.data.messageHead, result.data.messageBody);
          history.push("../dashboardView")
        })
        .catch(err => {
          // console.log(err);
          console.log(err.response);
          if (err.response.data) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  };

  // submit do card editado
  async function handleUpdateCard(cardId) {
    setEditLoading(true)
    setTimeout(() => {
      setEditLoading(false)
    }, 3000)

    if (name.length >= 50) {
      return toastr.warning("Cuidado!", "Título muito extenso.")
    } else if (!variableListIfExists) {
      return toastr.warning("Atenção", "Nenhuma variável selecionada")
    } else if (cardProps?.id) {

      const whArr = [6, 9];
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        dashId: localStorage.getItem('dashId'),
        idCard: cardId,
        cardType: cardProps.type,
        name: name,
        description: description,
        devices: deviceId.split(".")[0],
        variables: (variableListIfExists.length) ? JSON.stringify(variableListIfExists) : variable,
        // variables: editVariable,
        period: period,
        typeVariable: typeVariable,
        reloadTime: reloadTime,
        decimalNumber: (cardProps.decimalNumber) ? cardProps.decmalNumber : null,
        w: whArr[0],
        h: whArr[1],
        minValueProp: null,
        maxValueProp: 0
      };

      /*** Envio de dados ***/
      await axios.put('/v2/dashboard/updateCard', JSON.stringify(jsonData))
        .then(result => {
          toastr.success(result.data.messageHead, result.data.messageBody);
          history.push("../../dashboardView")
        })
        .catch(err => {
          // console.log(err);
          console.log(err.response);
          if (err.response.data) {
            /* Notificação de erro de envio */
            toastr.warning("Falha", "Ocorreu um erro ao atualizar.");
          }
        });
    }
  }

  // função pra trazer os campos preenchidos ao editar o card
  useEffect(() => {
    if (cardProps?.id && cardDataToEdit) {
      onProjectChange(cardDataToEdit.projId);
      onDeviceChange(cardProps?.devices)

      let editCard = JSON.parse(cardProps.variables)
      if (editCard.length > 0) {
        editCard?.forEach(variable => {
          setDeviceId(`${variable.dvcId}.${variable.deviceName}`)
          setVariableListIfExists(prevData => [...prevData, variable])
        });
      }
      /** O ELSE SERVE PARA CARDS ANTIGOS TAMBÉM PODEREM SER EDITADOSV */
      else {
        setDeviceId(`${deviceId}.${cardDataToEdit.description}`)
        setVariableListIfExists(prevData => [...prevData, editCard])

      }
    }
    // eslint-disable-next-line
  }, [cardProps?.id, cardDataToEdit]);

  return <Row xs={1} lg={2}>
    <Col>
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{t('dashboards.cardOptions')}</CardTitle>
        </CardHeader>

        <CardBody>
          <Form
            onSubmit={e => {
              e.preventDefault();
              onSubmitForm();
            }}
          >
            {(cardProps?.id && cardDataToEdit) ?
              <>
                {/* Editar Nome */}
                <FormGroup>
                  <Label>
                    {t('dashboards.name')}
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t('dashboards.name')}
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </FormGroup>

                {/* Editar Descrição */}
                <FormGroup>
                  <Label>
                    {t('dashboards.description')}
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder={t('dashboards.description')}
                    rows="3"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                </FormGroup>

                {/* Editar  Seleção de projeto */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.projects')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="project"
                      required
                      value={(cardDataToEdit?.projId) ? cardDataToEdit.projId : ""}
                      onChange={e => onProjectChange(e.target.value)}
                      disabled={isLoading}
                    >
                      {/* <option hidden value="">{t('dashboards.pleaseProject')}</option> */}
                      <option hidden value={(cardDataToEdit?.projId) ? cardDataToEdit.projId : ""}>
                        {(cardDataToEdit?.name) ? cardDataToEdit?.name : ""}
                      </option>

                      {(projList.length)
                        ? projList.map(item => (
                          <option value={item.id} key={item.id}>{item.name}</option>
                        ))
                        : <option disabled>{t('dashboards.noProject')}</option>
                      }
                    </Input>
                  </Col>
                </FormGroup>

                {/* Editar Seleção de dispositivo */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.devices')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="device"
                      required
                      value={deviceId}
                      onChange={e => onDeviceChange(e.target.value)}
                      disabled={!dvcList.length || isLoading}
                    >
                      {/* <option hidden value="">{t('dashboards.pleaseDevice')}</option> */}
                      <option hidden defaultValue={
                        (cardDataToEdit?.description) ?
                          cardDataToEdit.description
                          : ""}
                      >

                        {(cardDataToEdit.description) ? cardDataToEdit.description
                          : t('dashboards.pleaseDevice')}
                      </option>
                      {dvcList.map(item => (
                        <option value={`${item.id}.${item.description}`} key={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>

                {/* Editar Seleção de variável */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.variables')}
                  </Label>
                  <Col lg={12} xl={6}>
                    <Input
                      type="select"
                      id="variablesSelect"
                      required
                      value={variable}
                      onChange={e => setVariable(e.target.value)}
                      disabled={!varList.length || isLoading}
                    >
                      <option hidden value="">
                        {t('dashboards.pleaseVariable')}
                      </option>
                      {varList.map(item => (
                        <option value={JSON.stringify(item)} key={item.tag}>
                          {item.varName}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Button onClick={() => handAddNewVariable(variable)} color="primary" type="button">Adicionar</Button>
                  </Col>
                </FormGroup>

                {variableListIfExists.map((item, index) => (
                  <FormGroup key={index} row>
                    <Label lg={12} xl={3}>
                      {t('dashboards.variables')} {++index} 
                    </Label>
                    <Col lg={12} xl={6}>
                      <Input
                        type="select"
                        id="variablesSelect"
                        required
                        value={variable}
                        onChange={e => setVariable(e.target.value)}
                        disabled
                      >
                        <option value={JSON.stringify(item)} key={item.tag}>
                          {item.varName}
                        </option>
                      </Input>
                    </Col>
                    <Col>
                      <Button onClick={() => handleDeleteVariable(index)} style={{ width: 83 }} color="danger" type="button">Remover</Button>
                    </Col>
                  </FormGroup>
                ))}

                {/* Editar Seleção de tipo (apenas para gráficos) */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.typeVariable')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="typeVariableSelect"
                      required
                      value={typeVariable}
                      onChange={e => setTypeVariable(e.target.value)}
                    >
                      <option hidden value="">{t('dashboards.pleaseTypeVariable')}</option>
                      <option value="integral">{t('dashboards.integral')}</option>
                      <option value="differential">{t('dashboards.differential')}</option>

                    </Input>
                  </Col>
                </FormGroup>

                {/* Editar Seleção de período (apenas para gráficos) */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.period')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="periodSelect"
                      required
                      value={period}
                      onChange={e => setPeriod(e.target.value)}
                    >
                      <option hidden value="">{t('dashboards.pleasePeriod')}</option>
                      <option value="3600">Última hora</option>
                      <option value="86400">Últimas 24 horas</option>
                      <option value="604800">Últimos 7 dias</option>
                      <option value="1296000">Últimos 15 dias</option>
                      <option value="2592000">Últimos 30 dias</option>

                    </Input>
                  </Col>
                </FormGroup>

                {/* Editar Seleção tempo Reload */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.refreshTime')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="reloadTimeSelect"
                      required
                      value={reloadTime}
                      onChange={e => setReloadTime(e.target.value)}
                    >
                      <option hidden value="">{t('dashboards.pleaseRefresh')}</option>
                      <option value="30000">30 segundos</option>
                      <option value="60000">1 minuto</option>
                      <option value="120000">2 minutos</option>
                      <option value="180000">3 minutos</option>
                      <option value="240000">4 minutos</option>
                      <option value="300000">5 minutos</option>
                      <option value="900000">15 minutos</option>
                      <option value="1800000">30 minutos</option>

                    </Input>
                  </Col>
                </FormGroup>
              </>
              : <>
                {/* Nome */}
                <FormGroup>
                  <Label>
                    {t('dashboards.name')}
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t('dashboards.name')}
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </FormGroup>

                {/* Descrição */}
                <FormGroup>
                  <Label>
                    {t('dashboards.description')}
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder={t('dashboards.description')}
                    rows="3"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                </FormGroup>

                {/* Seleção de projeto */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.projects')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="project"
                      required
                      onChange={e => onProjectChange(e.target.value)}
                      disabled={isLoading}
                    >
                      <option hidden value="">{t('dashboards.pleaseProject')}</option>
                      {(projList.length)
                        ? projList.map(item => (
                          <option value={item.id} key={item.id}>{item.name}</option>
                        ))
                        : <option disabled>{t('dashboards.noProject')}</option>
                      }
                    </Input>
                  </Col>
                </FormGroup>

                {/* Seleção de dispositivo */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.devices')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="device"
                      required
                      value={deviceId}
                      onChange={e => onDeviceChange(e.target.value)}
                      disabled={!dvcList.length || isLoading}
                    >
                      <option hidden value="">{t('dashboards.pleaseDevice')}</option>
                      {dvcList.map(item => (
                        <option value={`${item.id}.${item.description}`} key={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>

                {/* Seleção de variável */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.variables')}
                  </Label>
                  <Col lg={12} xl={6}>
                    <Input
                      type="select"
                      id="variablesSelect"
                      required={(variableListIfExists.length > 0) ? false : true}
                      value={variable}
                      onChange={e => setVariable(e.target.value)}
                      disabled={!varList.length || isLoading}
                    >
                      <option hidden value="">{t('dashboards.pleaseVariable')}</option>
                      {varList.map((item, i) => (
                        <option value={JSON.stringify(item)} key={i}>
                          {item.varName}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Button onClick={() => handAddNewVariable(variable)} color="primary" type="button">Adicionar</Button>
                  </Col>
                </FormGroup>

                {variableListIfExists?.map((item, index) => (
                  <FormGroup key={index} row>
                    <Label lg={12} xl={3}>
                      {t('dashboards.variables')} {++index}
                    </Label>
                    <Col lg={12} xl={6}>
                      <Input
                        type="select"
                        // id="variablesSelect"
                        // required={(variableListIfExists.length > 0) ? true : false}
                        value={item}
                        // onChange={e => setVariable(e.target.value)}
                        disabled
                      >

                        <option key={index} value={item.varName}>
                          {item.varName}
                        </option>
                      </Input>
                    </Col>
                    <Col>
                      <Button onClick={() => handleDeleteVariable(--index)} style={{ width: 83 }} color="danger" type="button">Remover</Button>
                    </Col>
                  </FormGroup>
                ))}

                {/* Seleção de tipo de variável */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.typeVariable')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="typeVariableSelect"
                      required
                      value={typeVariable}
                      onChange={e => setTypeVariable(e.target.value)}
                    >
                      <option hidden value="">{t('dashboards.pleaseTypeVariable')}</option>
                      <option value="integral">{t('dashboards.integral')}</option>
                      <option value="differential">{t('dashboards.differential')}</option>
                    </Input>
                  </Col>
                </FormGroup>

                {/* Seleção de período (apenas para gráficos) */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.period')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="periodSelect"
                      required
                      value={period}
                      onChange={e => setPeriod(e.target.value)}
                    >
                      <option hidden value="">{t('dashboards.pleasePeriod')}</option>
                      <option value="3600">Última hora</option>
                      <option value="86400">Últimas 24 horas</option>
                      <option value="604800">Últimos 7 dias</option>
                      <option value="1296000">Últimos 15 dias</option>
                      <option value="2592000">Últimos 30 dias</option>

                    </Input>
                  </Col>
                </FormGroup>

                {/* Seleção tempo Reload */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.refreshTime')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="reloadTimeSelect"
                      required
                      value={reloadTime}
                      onChange={e => setReloadTime(e.target.value)}
                    >
                      <option hidden value="">{t('dashboards.pleaseRefresh')}</option>
                      <option value="30000">30 segundos</option>
                      <option value="60000">1 minuto</option>
                      <option value="120000">2 minutos</option>
                      <option value="180000">3 minutos</option>
                      <option value="240000">4 minutos</option>
                      <option value="300000">5 minutos</option>
                      <option value="900000">15 minutos</option>
                      <option value="1800000">30 minutos</option>
                    </Input>
                  </Col>
                </FormGroup>
              </>
            }
            
            {/* Botão de salvar */}
            <div className="text-right">
              <Link to="/dashboardView">
                <Button
                  name="btnCancel"
                  color='primary'
                  className="mr-2"
                  outline
                >
                  {t('dashboards.cancel')}
                </Button>
              </Link>
              {(cardProps?.id) ?
                <Button
                  color="primary"
                  type="button"
                  disabled={isEditLoading}
                  style={{ minWidth: 80 }}
                  onClick={() => handleUpdateCard(cardProps.id)}
                >
                  {(isEditLoading === true) ? <Spinner size={"sm"} /> : t("Atualizar")}
                </Button>
                : <Button
                  disabled={isEditLoading}
                  style={{ minWidth: 80 }}
                  color="primary"
                  type="submit"
                >
                  {(isEditLoading === true) ? <Spinner size={"sm"} /> : t('dashboards.save')}
                </Button>
              }
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>

    <Col>
      <Card>
        {(cardType === "lineChart" || cardProps?.type === "lineChart")
          ? <LineChartPreview
            projList={projList}
            variableListIfExists={variableListIfExists}
            cardDataToEdit={cardDataToEdit}
            cardType={cardType}
            cardProps={cardProps}
            name={name}
            description={description}
            variables={variable}
            deviceId={deviceId}
            preview={true}
            period={period}
          />
          : (cardType === "barChart" || cardProps?.type === "barChart")
            ? <BarAmChartsPreview
              projList={projList}
              variableListIfExists={variableListIfExists}
              cardDataToEdit={cardDataToEdit}
              cardType={cardType}
              cardProps={cardProps}
              name={name}
              description={description}
              variables={variable}
              deviceId={deviceId}
              preview={true}
              period={period}
            />
            : null
        }
      </Card>
    </Col>
  </Row>
}

export default NewChartCard