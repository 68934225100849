/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { useRefMeterContext } from "../../../RefMeterProvider";
import { readReportBill } from "../../../RefMeterService";
import ModalPdfBillEmail from "./components/ModalPdfBillEmail";
import PdfBillBlob from "./components/PdfBillBlob";
import PdfBillView from "./components/PdfBillView";
import { usePlantContext } from "../../../../PlantProvider";

const ModalPdfBill = ({ open, toggle }) => {
  const { t } = useTranslation();
  const [isModalEmailOpen, setIsModalEmailOpen] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [billData, setBillData] = useState(null);

  const {
    plant,
    barChartDemandScreenshoot,
    barChartConsumptionScreenshoot,
  } = useRefMeterContext();
  const { selectedDate } = usePlantContext();

  useEffect(() => {
    getBillData();
  }, []);

  const getBillData = async () => {
    const billData = await readReportBill(plant.id, selectedDate);
    setBillData(billData);
  };

  function toggleModalSendMail() {
    setIsModalEmailOpen(!isModalEmailOpen);
  }

  return !billData ||
    barChartDemandScreenshoot === null ||
    barChartConsumptionScreenshoot === null ? (
    <div className="text-center p-3">
      <Spinner color="primary" />
    </div>
  ) : (
    <Modal isOpen={open} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{t("reportBill.title")}</ModalHeader>

      <ModalBody className="overflow-auto">
        <PdfBillView
          data={billData}
          barChartDemandScreenshot={barChartDemandScreenshoot}
          barChartConsumptionScreenshot={barChartConsumptionScreenshoot}
        />
        <PdfBillBlob
          data={billData}
          barChartDemandScreenshot={barChartDemandScreenshoot}
          barChartConsumptionScreenshot={barChartConsumptionScreenshoot}
          setPdfBlob={setPdfBlob}
        />
        {isModalEmailOpen && (
          <ModalPdfBillEmail
            open={isModalEmailOpen}
            toggle={toggleModalSendMail}
            pdfBlob={pdfBlob}
            projectName={plant.name}
          />
        )}
      </ModalBody>

      <ModalFooter>
        <Button color="primary" className="mb-1" onClick={toggleModalSendMail}>
          {t("reportsEnergy.sendEmail")}
        </Button>
        <Button color="primary" className="mb-1" onClick={toggle} outline>
          {t("reportsEnergy.closeButton")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalPdfBill;
