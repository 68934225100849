import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <h1 className="display-1 font-weight-bold">404</h1>
      <p className="h1">{t('page404.notFound')}.</p>
      <p className="h2 font-weight-normal mt-3 mb-4">
        {t('page404.removed')}.
      </p>
      <Link to="/">
        <Button color="primary" size="lg">
          {t('page404.return')}
        </Button>
      </Link>
    </div>
  )
};

export default Page404;
