import { faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useDispatch } from "react-redux";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  enableClassicTheme,
  enableDarkTheme,
  enableLightTheme,
  enableModernTheme,
} from "../../../redux/actions/themeActions";
import { useTranslation } from "react-i18next";

export const ThemesDropdown = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function updateTheme(theme) {
    axios.put(
      "updateTheme/",
      JSON.stringify({
        theme: theme,
      })
    );
  }

  return (
    <DropdownMenu
      style={{
        minWidth: "7rem",
        position: "fixed",
        zIndex: "2000",
      }}
    >
      <DropdownItem
        onClick={() => {
          dispatch(enableModernTheme());
          updateTheme("modern");
          localStorage.setItem("defaultTheme", "modern");
        }}
      >
        <FontAwesomeIcon
          icon={faSun}
          fixedWidth
          className="mr-2 align-middle"
        />
        {t("theme.modern")}
      </DropdownItem>

      <DropdownItem
        onClick={() => {
          dispatch(enableClassicTheme());
          updateTheme("classic");
          localStorage.setItem("defaultTheme", "classic");
        }}
      >
        <FontAwesomeIcon
          icon={faSun}
          fixedWidth
          className="mr-2 align-middle"
        />
        {t("theme.classic")}
      </DropdownItem>

      <DropdownItem
        onClick={() => {
          dispatch(enableDarkTheme());
          updateTheme("dark");
          localStorage.setItem("defaultTheme", "dark");
        }}
      >
        <FontAwesomeIcon
          icon={faSun}
          fixedWidth
          className="mr-2 align-middle"
        />
        {t("theme.dark")}
      </DropdownItem>

      <DropdownItem
        onClick={() => {
          dispatch(enableLightTheme());
          updateTheme("light");
          localStorage.setItem("defaultTheme", "light");
        }}
      >
        <FontAwesomeIcon
          icon={faSun}
          fixedWidth
          className="mr-2 align-middle"
        />
        {t("theme.light")}
      </DropdownItem>
    </DropdownMenu>
  );
};
