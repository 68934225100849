const ptBrGatewayTranslations = {
    reports: {
        month1: "Janeiro",
        month2: "Fevereiro",
        month3: "Março",
        month4: "Abril",
        month5: "Maio",
        month6: "Junho",
        month7: "Julho",
        month8: "Agosto",
        month9: "Setembro",
        month10: "Outubro",
        month11: "Novembro",
        month12: "Dezembro",
        monthJan: "JAN/",
        monthFev: "FEV/",
        monthMar: "MAR/",
        monthAbr: "ABR/",
        monthMai: "MAI/",
        monthJun: "JUN/",
        monthJul: "JUL/",
        monthAgo: "AGO/",
        monthSet: "SET/",
        monthOut: "OUT/",
        monthNov: "NOV/",
        monthDez: "DEZ/",
        deleteEmail: "Deletar E-mail"
    },
    page404: {
        notFound: "Página não encontrada",
        removed: "A página que você procura pode ter sido removida",
        return: "Página Inicial"
    },
    searchBar: {
        logout: "Sair",
        home: "Página Inicial",
        dashboards: "Dashboards",
        dataExplorer: "Data Explorer",
        allCards: "Todos Cards",
        newCard: "Novo Card",
        MeusCards: "Meus Cards",
        projects: "Minhas Plantas",
        alarms: "Alarmes",
        registers: "Configurações",
        settings: "Preferências",
        devices: "Dispositivos",
        alarmView: "Histórico de Alarmes",
        alarmConfig: "Parâmetros de Alarmes",
        usersData: "Registros de Empresas e Usuários",
        projectsData: "Registros de Plantas e Dispositivos",
        alarmsData: "Registros de Parâmetros de Alarmes",
    },
    gateways: {
        title: "Visão Geral de Gateways",
        clickText: "Clique sobre o nome de um gateway para ver detalhes acerca do seu status",
        expandText1: "Clique em",
        expandText2: "para visualizar os dispositivos periféricos vinculados a cada gateway",
        description: "Referência",
        model: "Modelo",
        serialNumber: "Número de Série",
        projectName: "Projeto",
        companyName: "Empresa",
        lastComm: "Última Com.",
        commInterval: "Int. de Publicação",
        actions: "Ações",
        modalTitle: "Status de",
        upTime: "Tempo desde inicialização",
        connectedDvcs: "Nº de dispositivos vinculados",
        usedStorage: "Memória utilizada",
        usedMem: "Uso de memória atual",
        connMode: "Modo de conexão",
        MACAddress: "MAC Address",
        gwIP: "IP de rede",
        dhcp: "Conexão DHCP",
        gateway: "IP do Gateway",
        subnetMask: "Máscara",
        DNS: "IP do DNS",
        rssi: "Intensidade do sinal",
        gwVersion: "Versão de firmware",
        gwHardware: "Versão de hardware",
        linkSpeed: "Velocidade da conexão",
        ago: "atrás",
        unknown: "Desconhecido",
        yes: 'Sim',
        no: 'Não',
        noGateway: "Nenhum Gateway encontrado",
        noPeripherical: "Nenhum dispositivo vinculado ao Gateway",
        noStatus: "Não há dados sobre o status do Gateway",
        refresh: "Atualizar",
        close: "Fechar"
    },
}

export default ptBrGatewayTranslations;