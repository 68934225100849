import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardHeader,
    CardImg,
    CardTitle,
    Col,
    Row,
    Spinner
} from "reactstrap";

import nullProject from "../../../../assets/img/project_null";

export function ProjectsCards({ projs, searchbar, isLoading }) {
  const { t } = useTranslation();

  const [filteredProjs, setFilteredProjs] = useState([]);

  useEffect(() => {
    setFilteredProjs(
      projs.filter((item) => {
        return item.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(searchbar);
      })
    );
  }, [searchbar, projs]);

  /* Exibe spinner enquanto carrega projetos */
  if (isLoading) {
    return (
      <div className="text-center" style={{ marginTop: "300px" }}>
        <Spinner color="primary" />
      </div>
    );
  }

  /* Exibe cards dos projetos */
  return projs.length ? (
    <div>
      <Row
        xl="5"
        lg="5"
        md="3"
        sm="2"
        xs="1"
        className="d-flex flex-row"
        style={{ marginTop: "-30px" }}
      >
        {filteredProjs.map((item, i) => (
          <Col key={i} className="md-1">
            <Link
              to={`/project-${item.id}-default`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card style={{ height: "90%" }} className="cursor-pointer">
                <CardImg
                  top
                  width="100%"
                  src={item.image ? item.image : nullProject}
                  alt="Project image"
                />

                <CardHeader>
                  <CardTitle tag="h5" className="mb-0">
                    {item.name}
                  </CardTitle>
                </CardHeader>

                <CardBody>{item.description}</CardBody>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  ) : (
    // card explicando que nao foram encontrados projetos
    <Card>
      <CardBody className="text-center m-3">
        {t("projects.noProjects")}.
      </CardBody>
    </Card>
  );
}
