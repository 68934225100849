import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useWaterModuleAPI } from "../../WaterProvider";

function getDaysInMonth(month, year) {
  return moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
}

function identificarFormatoData(data) {
  const formatos = {
    "DD/MM/YYYY": /^\d{2}\/\d{2}\/\d{4}$/,
    "D/M/YYYY": /^\d{1,2}\/\d{1,2}\/\d{4}$/,
    "DD/MM": /^\d{2}\/\d{2}$/,
    "D/M": /^\d{1,2}\/\d{1,2}$/,
    dddd: /^[a-zA-Z]+$/,
    ddd: /^[a-zA-Z]{3}$/,
    "ddd, DD/MM": /^[a-zA-Z]{3}, \d{2}\/\d{2}$/,
  };

  for (let formato in formatos) {
    if (formatos[formato].test(data)) {
      return formato;
    }
  }

  return "Formato desconhecido";
}

// Função para gerar o array completo com os dias do mês
function fillMissingDays(data, month, year) {
  const daysInMonth = getDaysInMonth(month, year);
  const filledData = [];

  if (data.length === 0) {
    for (let day = 1; day <= daysInMonth; day++) {
      const format = "DD/MM/YYYY";
      const newTimestamp = moment(
        `${year}-${month}-${day}`,
        "YYYY-MM-DD"
      ).format(format);
      filledData.push({
        timestamp: newTimestamp,
        consumption: 0,
      });
    }
    return filledData;
  }

  // Preencher os dias do mês
  for (let day = 1; day <= daysInMonth; day++) {
    const format = identificarFormatoData(data[0].timestamp);
    // Formatar o dia e mês para "DD/MM"
    const dayStr = moment(`${year}-${month}-${day}`, "YYYY-MM-DD").format(
      format
    );

    // Verificar se já existe um consumo para esse dia
    const existingData = data.find((d) => d.timestamp === dayStr);

    if (existingData) {
      filledData.push(existingData);
    } else {
      // Gerar uma data sem dia da semana e com consumo 0
      const newTimestamp = moment(
        `${year}-${month}-${day}`,
        "YYYY-MM-DD"
      ).format("ddd, DD/MM");
      filledData.push({
        timestamp: newTimestamp,
        consumption: 0,
      });
    }
  }

  return filledData;
}

const ConsumChart = ({ registers = [], dashName }) => {
  const { t } = useTranslation();
  const { selectedMonthRef } = useWaterModuleAPI();
  const date = selectedMonthRef ?? new Date();

  const ArrayRegister = fillMissingDays(
    registers,
    new Date(date).getMonth() + 1,
    new Date(date).getFullYear()
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={ArrayRegister}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="timestamp" />
        <YAxis
          dataKey="consumption"
          label={{
            value: `${t("gasModule.plant.consumption")} (m³)"`,
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip
          cursor={{ fill: "transparent" }}
          formatter={(value) => [
            `${value.toLocaleString()} m³`,
            `${t("gasModule.plant.consumption")}`,
          ]}
        />
        <Legend formatter={() => `${t("gasModule.plant.consumption")}`} />
        <Bar dataKey="consumption" fill="#3178c6">
          {ArrayRegister.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.consumption < 0 ? "#e53016" : "#3178c6"}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ConsumChart;
