import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaSun,
  FaCloudSun,
  FaCloudSunRain,
  FaCloud,
  FaSnowflake,
  FaCloudShowersHeavy,
  FaBolt,
  FaCloudRain,
} from "react-icons/fa";

const weatherCodeIcons = {
  0: <FaSun aria-label="Céu limpo" />,
  1: <FaCloudSun aria-label="Poucas nuvens" />,
  2: <FaCloudSunRain aria-label="Nuvens dispersas" />,
  3: <FaCloud aria-label="Nuvens" />,
  48: <FaSnowflake aria-label="Geada" />,
  51: <FaCloudRain aria-label="Chuva leve" />,
  53: <FaCloudShowersHeavy aria-label="Chuva moderada" />,
  55: <FaCloudShowersHeavy aria-label="Chuva forte" />,
  56: <FaSnowflake aria-label="Chuva leve congelante" />,
  57: <FaSnowflake aria-label="Chuva forte congelante" />,
  61: <FaCloudRain aria-label="Chuva leve" />,
  63: <FaCloudShowersHeavy aria-label="Chuva moderada" />,
  65: <FaCloudShowersHeavy aria-label="Chuva forte" />,
  66: <FaSnowflake aria-label="Chuva leve congelante" />,
  67: <FaSnowflake aria-label="Chuva forte congelante" />,
  71: <FaSnowflake aria-label="Neve leve" />,
  73: <FaSnowflake aria-label="Neve moderada" />,
  75: <FaSnowflake aria-label="Neve forte" />,
  77: <FaSnowflake aria-label="Neve granulada" />,
  80: <FaCloudShowersHeavy aria-label="Chuva forte e intermitente" />,
  81: <FaCloudShowersHeavy aria-label="Chuva moderada e intermitente" />,
  82: <FaCloudShowersHeavy aria-label="Chuva muito forte e intermitente" />,
  85: <FaSnowflake aria-label="Neve intermitente" />,
  86: <FaSnowflake aria-label="Neve forte e intermitente" />,
  95: <FaBolt aria-label="Trovoada com chuva" />,
  96: <FaBolt aria-label="Trovoada com granizo" />,
  99: <FaBolt aria-label="Trovoada com granizo forte" />,
};

export const WeatherContainer = ({ weatherData }) => {
  const { temperature, windSpeed, weatherDescription, unit, weatherCode } =
    weatherData;

  const widthWindow = window.innerWidth;
  return (
    <div
      style={{
        position: "absolute",
        color: "white",
        zIndex: 2,
        top: "-100px",
        right: "12px",
        padding: "5px",
        background: "rgba(0, 0, 0, 0.7)",
        borderRadius: "8px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
        display: "flex",
        gap: "8px",
        fontSize: "16px",
        paddingTop: widthWindow < 600 ? "15px" : "5px",
      }}
    >
      {widthWindow > 600 && (
        <div
          className="weather-icon"
          style={{
            fontSize: widthWindow < 600 ? "1rem" : "3rem",
            marginBottom: "8px",
          }}
        >
          {weatherCodeIcons[weatherCode]}
        </div>
      )}
      <div className="weather-container">
        <div
          className="weather-description color-info"
          style={{ fontWeight: "semi-bold" }}
        >
          {weatherDescription}
        </div>
        <div className="d-flex align-items-center">
          {widthWindow < 600 && (
            <div
              className="weather-icon"
              style={{
                fontSize: widthWindow < 600 ? "1rem" : "3rem",
                marginRight: "10px",
              }}
            >
              {weatherCodeIcons[weatherCode]}
            </div>
          )}
          <div
            className="weather-temperature"
            style={{
              fontSize: widthWindow > 600 ? "1.5rem" : "0.875rem",
              marginBottom: "5px",
            }}
          >
            {temperature} {unit}
          </div>
        </div>
        {widthWindow > 600 && (
          <div
            className="weather-wind"
            style={{ display: "flex", alignItems: "center" }}
          >
            <FontAwesomeIcon icon="wind" style={{ marginRight: "5px" }} />
            {windSpeed} m/s
          </div>
        )}
      </div>
    </div>
  );
};
