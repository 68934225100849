import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import '../../igCards';
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { toastr } from "react-redux-toastr";

// import Statistics from "../cards/StatisticsPreview";
import StatisticsState from "../cards/StatisticsState";
import StatisticsPreview from "../cards/StatisticsPreview";

const NewStatsCard = ({ cardType, cardProps }) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true); // carregando projetos
  const [isEditLoading, setEditLoading] = useState(false); // salvando edição
  const [projList, setProjList] = useState([]); // lista de projetos
  const [dvcList, setDvcList] = useState([]); // lista de devices do projeto selecionado
  const [varList, setVarList] = useState([]); // lista de variaveis do device selecionado
  const [name, setName] = useState(cardProps?.name ? cardProps?.name : ""); // campo de nome
  const [description, setDescription] = useState(
    cardProps?.description ? cardProps?.description : ""
  ); // campo de descricao
  const [deviceId, setDeviceId] = useState(
    cardProps?.devices ? cardProps?.devices : ""
  ); // device selecionado
  const [variable, setVariable] = useState(
    cardProps?.variables ? cardProps?.variables : ""
  ); // variavel selecionada
  // const [variableListIfExists, setVariableListIfExists] = useState([]); // variavel selecionada
  const [reloadTime, setReloadTime] = useState(
    cardProps?.reloadTime ? cardProps?.reloadTime : ""
  ); // periodo selecionado para reload
  const [decimalNumber, setDecimalNumber] = useState(
    cardProps?.decimalNumber ? cardProps?.decimalNumber : ""
  );
  const [disableDecimalNumber, setDisableDecimalNumber] = useState();
  const [cardDataToEdit, setCardDataToEdit] = useState();
  const [formula, setFormula] = useState(
    cardProps?.valueFormula ? cardProps?.valueFormula : ""
  );
  const [unit, setUnit] = useState(cardProps?.newUnit ? cardProps?.newUnit : "");

  let varName = cardProps?.variables
    ? JSON.parse(cardProps?.variables).varName
    : "";

  let history = useHistory();

  //CHAMADAS PARA TRAZER CAMPOS PREENCHIDOS AO EDITAR
  useEffect(() => {
    if (cardProps?.id) {
      axios
        .get("/readDataOnEditCard", {
          params: {
            dvcId: cardProps.devices,
          },
        })
        .then((res) => {
          // console.log(res);
          setCardDataToEdit(res.data);
        })
        .catch((err) => {
          console.log(err);
          toastr.error("Erro!", "Erro ao buscar projetos."); // t() da problema com o useEffect
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios
      .get("/projectUserSearch")
      .then((response) => {
        setProjList(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toastr.error("Erro!", "Erro ao buscar projetos."); // t() da problema com o useEffect
      });
  }, []);

  function retrieveDataFromPreviewCard(data) {
    setDisableDecimalNumber(Number(data));
    console.log("data", disableDecimalNumber);
  }

  function onProjectChange(projId) {
    setDvcList([]); // limpa lista de dispositivos
    setVarList([]); // limpa lista de variaveis
    setDeviceId(""); // limpa dispositivo selecionado
    setVariable(""); // limpa variavel selecionada

    setLoading(true);
    axios
      .get("/deviceSearch", {
        params: {
          projId: projId,
        },
      })
      .then((response) => {
        setDvcList(response.data); // atualiza lista de dispositivos
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toastr.error("Erro!", "Erro ao buscar dispositivos."); // t() da problema com o useEffect
        setLoading(false);
      });
  }
  function onDeviceChange(dvcId) {
    setVarList([]); // limpa lista de variaveis
    setVariable(""); // limpa variavel selecionada

    setDeviceId(dvcId); // atualiza dispositivo selecionado
    setLoading(true);
    axios
      .get("/readVariables", {
        params: {
          id: dvcId,
        },
      })
      .then((response) => {
        // console.log(response);
        setVarList(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toastr.error("Erro!", "Erro ao buscar variáveis."); // t() da problema com o useEffect
        setLoading(false);
      });
  }

  function onSubmitForm() {
    setEditLoading(true);

    setTimeout(() => {
      setEditLoading(false);
    }, 3000);
    if (name.length >= 50) {
      return toastr.warning("Cuidado!", "Título muito extenso.");
    }

    if (!deviceId || !variable) {
      toastr.warning("Erro!", "Não foi selecionada variável.");
    } else {
      const whArr = [3, 6];
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        dashId: localStorage.getItem("dashId"),
        // idCard: cardId,
        cardType: cardType,
        name: name,
        description: description,
        devices: deviceId,
        // variables: (variableListIfExists.length) ? JSON.stringify(variableListIfExists) : variable,
        variables: variable,
        period: null,
        reloadTime: reloadTime,
        w: whArr[0],
        h: whArr[1],
        rangeStructure: null,
        decimalNumber: decimalNumber ? decimalNumber : null,
        formula: null,
        minValueProp: null,
        maxValueProp: null,
        valueFormula: formula,
        newUnit: unit,
      };

      /*** Envio de dados ***/
      axios
        .post("newCard/", JSON.stringify(jsonData))
        .then((result) => {
          toastr.success(result.data.messageHead, result.data.messageBody);
          history.push("../dashboardView");
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          if (err.response.data) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  }

  // FUNÇÃO PARA SALVAR DADOS EDITADOS DO CARD
  async function handleUpdateCard(cardId) {
    setEditLoading(true);

    setTimeout(() => {
      setEditLoading(false);
    }, 3000);

    if (name.length >= 50) {
      return toastr.warning("Cuidado!", "Título muito extenso.");
    }

    if (!deviceId || !variable) {
      toastr.warning("Erro!", "Não foi selecionada variável.");
    } else {
      const whArr = [3, 6];
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        dashId: localStorage.getItem("dashId"),
        idCard: cardId,
        // cardType: cardType,
        name: name,
        description: description,
        devices: deviceId,
        variables: variable,
        period: null,
        reloadTime: reloadTime,
        w: whArr[0],
        h: whArr[1],
        decimalNumber: decimalNumber ? decimalNumber : null,
        formula: null,
        minValueProp: null,
        maxValueProp: 0,
        valueFormula: formula,
        newUnit: unit,
      };
      // return console.log("jsonData", jsonData);
      /*** Envio de dados ***/
      await axios
        .put("editCardV2/", JSON.stringify(jsonData))
        .then((result) => {
          toastr.success(result.data.messageHead, result.data.messageBody);
          setEditLoading(false);
          history.push("../../dashboardView");
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          setEditLoading(false);
          // console.log("catch", isEditLoading);
          if (err.response.data) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
            // console.log("if", isEditLoading);
          }
        });
    }
  }

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    let result = minutes;
    if (millis === 30000) {
      return `${seconds} segundos`;
    } else {
      return `${result} minutos`;
    }
  }

  useEffect(() => {
    if (cardProps?.id && cardDataToEdit) {
      onProjectChange(cardDataToEdit.projId);
      onDeviceChange(cardProps?.devices);
      setVariable(cardProps?.variables);
    }
    // eslint-disable-next-line
  }, [cardDataToEdit]);

  return (
    <Row xs={1} xl={2}>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">{t("dashboards.cardOptions")}</CardTitle>
          </CardHeader>

          <CardBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm();
              }}
            >
              {cardProps?.id ? (
                <>
                  {/* Editar Nome */}
                  <FormGroup>
                    <Label>{t("dashboards.name")}</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder={t("dashboards.name")}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormGroup>

                  {/* Editar Descrição */}
                  <FormGroup>
                    <Label>{t("dashboards.description")}</Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder={t("dashboards.description")}
                      rows="3"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FormGroup>

                  {/* Editar Seleção de projeto */}
                  <FormGroup className="flex" row>
                    <Label lg={12} xl={3}>
                      {t("dashboards.projects")}
                    </Label>
                    <Col lg={12} xl={9}>
                      {cardDataToEdit ? (
                        <Input
                          type="select"
                          id="project"
                          required
                          defaultValue={
                            cardDataToEdit?.projId ? cardDataToEdit.projId : ""
                          }
                          onChange={(e) => onProjectChange(e.target.value)}
                          disabled={isLoading}
                        >
                          <option
                            hidden
                            value={
                              cardDataToEdit?.projId
                                ? cardDataToEdit.projId
                                : ""
                            }
                          >
                            {cardDataToEdit ? cardDataToEdit?.name : ""}
                          </option>
                          {projList.length ? (
                            projList.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            ))
                          ) : (
                            <option disabled>
                              {t("dashboards.noProject")}
                            </option>
                          )}
                        </Input>
                      ) : null}
                    </Col>
                  </FormGroup>

                  {/* Editar Seleção de dispositivo */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t("dashboards.devices")}
                    </Label>
                    <Col lg={12} xl={9}>
                      {cardDataToEdit ? (
                        <Input
                          type="select"
                          id="device"
                          required
                          defaultValue={
                            cardDataToEdit?.description
                              ? cardDataToEdit.description
                              : ""
                          }
                          onChange={(e) => onDeviceChange(e.target.value)}
                          disabled={isLoading}
                        >
                          <option
                            hidden
                            defaultValue={
                              cardDataToEdit?.description
                                ? cardDataToEdit.description
                                : ""
                            }
                          >
                            {/* VALOR QUE APARECE NO INPUT */}
                            {cardProps.id
                              ? cardDataToEdit.description
                              : t("dashboards.pleaseDevice")}
                          </option>
                          {dvcList.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.description}
                            </option>
                          ))}
                        </Input>
                      ) : null}
                    </Col>
                  </FormGroup>

                  {/* Editar Seleção de variável */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t("dashboards.variables")}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="select"
                        id="variablesSelect"
                        required
                        defaultValue={variable}
                        onChange={(e) => setVariable(e.target.value)}
                        disabled={isLoading}
                      >
                        {/* <option hidden value="">
                        {t('dashboards.pleaseVariable')}
                      </option> */}
                        <option hidden defaultChecked default={variable}>
                          {varName}
                        </option>
                        {varList.map((item) => (
                          <option value={JSON.stringify(item)} key={item.tag}>
                            {item.varName}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t("dashboards.formula")}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="text"
                        value={formula}
                        onChange={(e) => setFormula(e.target.value)}
                        placeholder="Ex: x * 1000" // Exemplo de placeholder
                      />
                    </Col>
                  </FormGroup>

                  {/* Unidade de medida após processamento */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t("dashboards.unit")}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="text"
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                        placeholder="Ex: mW" // Exemplo de placeholder
                      />
                    </Col>
                  </FormGroup>

                  {cardProps.id && cardProps.type === "statistics" ? (
                    <FormGroup row className="mb-3 align-items-center">
                      <Label lg={12} xl={3}>
                        {t("dashboards.decimalNumber")}
                      </Label>
                      <Col lg={12} xl={9}>
                        <Input
                          type="select"
                          defaultValue={decimalNumber}
                          onChange={(e) => setDecimalNumber(e.target.value)}
                        >
                          {/* {console.log(decimalNumber)} */}
                          <option
                            hidden
                            value={
                              cardProps.decimalNumber
                                ? cardProps.decimalNumber
                                : ""
                            }
                          >
                            {cardProps.decimalNumber
                              ? cardProps.decimalNumber
                              : t("Selecione (opcional)")}
                          </option>
                          <option value="7">Todas</option>
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </Input>
                      </Col>
                    </FormGroup>
                  ) : null}

                  {/* Editar Seleção tempo Reload */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t("dashboards.refreshTime")}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="select"
                        id="reloadTimeSelect"
                        required
                        defaultValue={reloadTime}
                        onChange={(e) => setReloadTime(e.target.value)}
                      >
                        <option hidden value={reloadTime ? reloadTime : ""}>
                          {millisToMinutesAndSeconds(cardProps.reloadTime)}
                        </option>
                        <option value="30000">30 segundos</option>
                        <option value="60000">1 minuto</option>
                        <option value="120000">2 minutos</option>
                        <option value="180000">3 minutos</option>
                        <option value="240000">4 minutos</option>
                        <option value="300000">5 minutos</option>
                        <option value="900000">15 minutos</option>
                        <option value="1800000">30 minutos</option>
                      </Input>
                    </Col>
                  </FormGroup>
                </>
              ) : (
                <>
                  {/* Nome */}
                  <FormGroup>
                    <Label>{t("dashboards.name")}</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder={t("dashboards.name")}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormGroup>

                  {/* Descrição */}
                  <FormGroup>
                    <Label>{t("dashboards.description")}</Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder={t("dashboards.description")}
                      rows="3"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FormGroup>

                  {/* Seleção de projeto */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t("dashboards.projects")}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="select"
                        id="project"
                        required
                        onChange={(e) => onProjectChange(e.target.value)}
                        disabled={isLoading}
                      >
                        <option hidden value="">
                          {t("dashboards.pleaseProject")}
                        </option>
                        {projList.length ? (
                          projList.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))
                        ) : (
                          <option disabled>{t("dashboards.noProject")}</option>
                        )}
                      </Input>
                    </Col>
                  </FormGroup>

                  {/* Seleção de dispositivo */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t("dashboards.devices")}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="select"
                        id="device"
                        required
                        value={deviceId}
                        onChange={(e) => onDeviceChange(e.target.value)}
                        disabled={!dvcList.length || isLoading}
                      >
                        <option hidden value="">
                          {t("dashboards.pleaseDevice")}
                        </option>
                        {dvcList.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.description}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>

                  {/* Seleção de variável */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t("dashboards.variables")}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="select"
                        id="variablesSelect"
                        required
                        value={variable}
                        onChange={(e) => setVariable(e.target.value)}
                        disabled={!varList?.length || isLoading}
                      >
                        <option hidden value="">
                          {t("dashboards.pleaseVariable")}
                        </option>
                        {varList?.map((item) => (
                          <option value={JSON.stringify(item)} key={item.tag}>
                            {item.varName}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    {/* <Col>
                    <Button onClick={() => handAddNewVariable(variable)} color="primary" type="button">Adicionar</Button>
                  </Col> */}
                  </FormGroup>
                  {/* {variableListIfExists?.map((item, index) => (
                  <FormGroup key={index} row>
                    {console.log('Esse EH O ITEM', item)}
                    <Label lg={12} xl={3}>
                      {t('dashboards.variables')} {++index}
                    </Label>
                    <Col lg={12} xl={6}>
                      <Input
                        type="select"
                        // id="variablesSelect"
                        // required={(variableListIfExists.length > 0) ? true : false}
                        value={item}
                        // onChange={e => setVariable(e.target.value)}
                        disabled
                      >
                        <option hidden value="">{t('dashboards.pleaseVariable')}</option>
                        <option key={index} value={item.varName}>
                          {item.varName}
                        </option>
                      </Input>
                    </Col>
                    <Col>
                      <Button onClick={() => handleDeleteVariable(index)} style={{ width: 83 }} color="danger" type="button">Remover</Button>
                    </Col>
                  </FormGroup>
                ))} */}
                  {/* Seleção de casas decimais */}
                  {cardType === "statistics" ? (
                    <>
                      <FormGroup row>
                        <Label lg={12} xl={3}>
                          {t("dashboards.formula")}
                        </Label>
                        <Col lg={12} xl={9}>
                          <Input
                            type="text"
                            value={formula}
                            onChange={(e) => setFormula(e.target.value)}
                            placeholder="Ex: x * 1000" // Exemplo de placeholder
                          />
                        </Col>
                      </FormGroup>

                      {/* Unidade de medida após processamento */}
                      <FormGroup row>
                        <Label lg={12} xl={3}>
                          {t("dashboards.unit")}
                        </Label>
                        <Col lg={12} xl={9}>
                          <Input
                            type="text"
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                            placeholder="Ex: mW" // Exemplo de placeholder
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mb-3 align-items-center">
                        <Label lg={12} xl={3}>
                          {t("dashboards.decimalNumber")}
                        </Label>
                        <Col lg={12} xl={9}>
                          <Input
                            type="select"
                            value={decimalNumber}
                            onChange={(e) => setDecimalNumber(e.target.value)}
                            disabled={
                              typeof disableDecimalNumber !== "number"
                                ? true
                                : false
                            }
                          >
                            {/* {console.log(decimalNumber)} */}
                            <option value="" disabled>
                              Selecione
                            </option>
                            <option value="7">Todas</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </Input>
                        </Col>
                      </FormGroup>
                    </>
                  ) : null}

                  {/* Seleção tempo Reload */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t("dashboards.refreshTime")}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="select"
                        id="reloadTimeSelect"
                        required
                        value={reloadTime}
                        onChange={(e) => setReloadTime(e.target.value)}
                      >
                        <option hidden value="">
                          {t("dashboards.pleaseRefresh")}
                        </option>
                        <option value="30000">30 segundos</option>
                        <option value="60000">1 minuto</option>
                        <option value="120000">2 minutos</option>
                        <option value="180000">3 minutos</option>
                        <option value="240000">4 minutos</option>
                        <option value="300000">5 minutos</option>
                        <option value="900000">15 minutos</option>
                        <option value="1800000">30 minutos</option>
                      </Input>
                    </Col>
                  </FormGroup>
                </>
              )}

              {/* Botão de salvar */}
              <div className="text-right">
                <Link to="/dashboardView">
                  <Button
                    name="btnCancel"
                    color="primary"
                    className="mr-2"
                    outline
                  >
                    {t("dashboards.cancel")}
                  </Button>
                </Link>
                {cardProps?.id ? (
                  <Button
                    color="primary"
                    type="button"
                    disabled={isEditLoading}
                    style={{ minWidth: 80 }}
                    onClick={() => handleUpdateCard(cardProps.id)}
                  >
                    {isEditLoading === true ? (
                      <Spinner size={"sm"} />
                    ) : (
                      t("Atualizar")
                    )}
                  </Button>
                ) : (
                  <Button
                    disabled={isEditLoading}
                    style={{ minWidth: 80 }}
                    color="primary"
                    type="submit"
                  >
                    {isEditLoading === true ? (
                      <Spinner size={"sm"} />
                    ) : (
                      t("dashboards.save")
                    )}
                  </Button>
                )}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>

      <Col>
        <Card>
          {cardType === "statistics" || cardProps?.type === "statistics" ? (
            <StatisticsPreview
              retrieveDataFromPreviewCard={retrieveDataFromPreviewCard}
              cardDataToEdit={cardDataToEdit}
              decimalNumber={decimalNumber}
              cardType={cardType}
              cardProps={cardProps}
              name={name}
              description={description}
              variables={variable}
              deviceId={deviceId}
              formula={formula}
              defaultVarUnit={unit}
              preview={true}
            />
          ) : cardType === "statisticsState" ||
            cardProps?.type === "statisticsState" ? (
            <StatisticsState
              retrieveDataFromPreviewCard={retrieveDataFromPreviewCard}
              cardDataToEdit={cardDataToEdit}
              decimalNumber={decimalNumber}
              cardType={cardType}
              cardProps={cardProps}
              name={name}
              description={description}
              variables={variable}
              deviceId={deviceId}
              preview={true}
            />
          ) : null}
        </Card>
      </Col>
    </Row>
  );
};

export default NewStatsCard;
