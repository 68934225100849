import React from "react";
import { Area, AreaChart } from "recharts";

export default function MonthlyPowerData({ data }) {
  const { plantMonthlyPowerData, plantPower } = data;

  const chartData = plantMonthlyPowerData?.days.map((day, index) => {
    return {
      name: day,
      value: plantMonthlyPowerData.power[index],
    };
  });
  return (
    <div className="d-flex align-items-end justify-content-between">
      <AreaChart data={chartData} width={100} height={30}>
        <Area
          type="monotone"
          dataKey="value"
          stroke="#8884d8"
          fill="#8884d8"
          isAnimationActive={false}
        />
      </AreaChart>
      <span>{plantPower.toLocaleString()}</span>
    </div>
  );
}
