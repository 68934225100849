import React, { createContext, useContext, useState } from "react";
import { getConsumptionSharing } from "./ConsumptionSharingService";
import { useTranslation } from "react-i18next";

export const ConsumptionSharingContext = createContext();

export const ConsumptionSharingProvider = ({ children }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [chart, setChart] = useState(null);
  const [dataTableColumns, setDataTableColumns] = useState([{ none: "none" }]);
  const [dataTableRows, setDataTableRows] = useState([]);

  const getDataTableValues = (values) => {
    if (values?.consumptionSharingUcs?.length) {
      const consumptionSharingUcs = values.consumptionSharingUcs || [];
      const firstElement = consumptionSharingUcs[0] || { none: "none" };

      const keyValues = Object.keys(firstElement);
      const columns = keyValues?.map((key) => ({
        field: key,
        headerName: t(`sharingTable.${key}`),
      }));

      const rows = consumptionSharingUcs.map((e, index) => {
        return Object.assign(
          {},
          ...keyValues?.map((key) => ({ [key]: e[key] }))
        );
      });

      setDataTableColumns(columns);
      setDataTableRows(rows);
    }
  };

  const handleSubmit = async (projId, params) => {
    setIsLoading(true);
    const response = await getConsumptionSharing(projId, params);
    setIsLoading(false);

    if (!response.consumptionSharingUcs.length) return;
    setChart(response?.consumptionSharingUcs || []);
    getDataTableValues(response);
  };

  return (
    <ConsumptionSharingContext.Provider
      value={{
        isLoading,
        chart,
        handleSubmit,
        dataTableColumns,
        dataTableRows,
      }}
    >
      {children}
    </ConsumptionSharingContext.Provider>
  );
};

export function useConsumptionSharingContext() {
  const context = useContext(ConsumptionSharingContext);
  if (context === undefined) {
    throw new Error(
      "useConsumptionSharingContext must be used within a ConsumptionSharingProvider"
    );
  }
  return context;
}
