import {
  faArrowAltCircleRight,
  faBell,
  faBellSlash,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import {
  Button,
  Col,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

import ModalOffAlarm from "../../../pages/igAlarms/alarmView/ModalOffAlarm";

import NotificationSound from "../../../assets/sounds/notification-sound.mp3";

const MyCustomComponent = ({ data }) => {
  const [openOffAlarm, setOpenOffAlarm] = useState(false);
  function toggleOffAlarm() {
    setOpenOffAlarm(!openOffAlarm);
  }
  let alarm = data[0];
  function handleClick() {
    setOpenOffAlarm(true);
  }
  return (
    <>
      <Button color="primary">
        {" "}
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href="/alarmView"
        >
          <FontAwesomeIcon
            icon={faArrowAltCircleRight}
            fixedWidth
            className=" align-middle"
          />{" "}
        </a>
      </Button>
      <Button className="ml-1" onClick={handleClick}>
        <FontAwesomeIcon
          icon={faBellSlash}
          fixedWidth
          className="align-middle"
        />
        <ModalOffAlarm
          open={openOffAlarm}
          toggle={toggleOffAlarm}
          alarm={alarm}
        />
      </Button>
    </>
  );
};
export const NotificationsDropdown = () => {
  const { t } = useTranslation();

  const [alarmCount, setAlarmCount] = useState(0);
  const [soundOn, setSoundOn] = useState(
    Number(localStorage.getItem("soundOn") === "1")
  );

  let history = useHistory();
  let { url } = useRouteMatch();

  const audioPlayer = useRef(null);

  function searchAlarms() {
    axios
      .get("/alarmOnSearch")
      .then((res) => {
        setAlarmCount(res.data.length);

        const newAlarms = res.data.filter(
          (item) => item.dateOccurred >= Date.now() / 1000 - searchInterval
        );

        function playAudio() {
          audioPlayer.current.play();
        }

        if (newAlarms.length) {
          const toastrOpts = {
            onShowComplete: () =>
              localStorage.getItem("soundOn") === "1" ? playAudio() : null,
            showCloseButton: true,
            closeOnToastrClick: false,
            timeOut: 0,
            tapToDismiss: false,
            removeOnHover: false,
            component: <MyCustomComponent data={newAlarms} />,
          };
          newAlarms.forEach((alarm) => {
            const date = new Date(alarm.dateOccurred * 1000);
            let dateFormat =
              ("0" + date.getDate()).slice(-2) +
              "/" +
              ("0" + (date.getMonth() + 1)).slice(-2) +
              "/" +
              date.getFullYear() +
              " " +
              ("0" + date.getHours()).slice(-2) +
              ":" +
              ("0" + date.getMinutes()).slice(-2) +
              ":" +
              ("0" + date.getSeconds()).slice(-2);

            if (alarm.priority === 1) {
              // alta
              toastr.error(
                alarm.description,
                ` valor: ${alarm.value}
              data: ${dateFormat},
               ${alarm.messageOn}
               , nome: ${alarm.deviceName}`,
                toastrOpts
              );
            } else if (alarm.priority === 2) {
              // media
              toastr.warning(
                alarm.description,
                ` valor: ${alarm.value}
              data: ${dateFormat},
               ${alarm.messageOn}
               , nome: ${alarm.deviceName}`,
                toastrOpts
              );
            } else {
              // baixa
              toastr.info(
                alarm.description,
                ` valor: ${alarm.value}
               data: ${dateFormat},
                ${alarm.messageOn}
                , nome: ${alarm.deviceName}`,
                toastrOpts
              );
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  }

  function switchSound() {
    const newState = Number(!soundOn);
    axios
      .put(
        "enableSound",
        JSON.stringify({
          soundOn: newState,
        })
      )
      .then((response) => {
        setSoundOn(newState);
        localStorage.setItem("soundOn", newState);
        toastr.success("Sucesso", response.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  }

  const searchInterval = 10; // intervalo de tempo de busca de alarmes

  useEffect(() => {
    searchAlarms();

    const intervalSearch = setInterval(() => {
      searchAlarms();

      if (history.location.pathname !== url) {
        clearInterval(intervalSearch);
      }
    }, searchInterval * 1000);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DropdownMenu className="dropdown-menu-lg">
        <Link to="/alarmView">
          <DropdownItem>
            <Row className="align-items-center">
              <Col xs={2}>
                <FontAwesomeIcon
                  icon={faBell}
                  className={alarmCount ? "text-danger" : "text-success"}
                />
              </Col>

              <Col xs={10}>
                <div className="text-dark">{t("navbar.alarms")}</div>

                <div className="text-muted small mt-1">
                  {alarmCount
                    ? `${alarmCount} ${
                        alarmCount === 1
                          ? t("navbar.activeAlarm")
                          : t("navbar.activeAlarms")
                      }`
                    : t("navbar.noAlarms")}
                </div>
              </Col>
            </Row>
          </DropdownItem>
        </Link>

        <DropdownItem divider />

        <DropdownItem
          onClick={(e) => {
            e.preventDefault();
            switchSound();
          }}
        >
          <Form className="mx-3">
            <FormGroup className="d-flex align-items-center justify-content-start">
              <CustomInput
                bsSize="sm"
                type="switch"
                id={"soundEnable"}
                defaultChecked={localStorage.getItem("soundOn") === "1"}
              />
              <Label className="flex-grow-1">{t('sidebar.soundNotifications')}</Label>
              <FontAwesomeIcon icon={soundOn ? faVolumeUp : faVolumeMute} />
            </FormGroup>
          </Form>
        </DropdownItem>
      </DropdownMenu>
      <audio ref={audioPlayer} src={NotificationSound} />
    </>
  );
};
