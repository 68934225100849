import React from "react";

export default function Productivity({ plant }) {
  const { plantProductivity } = plant;
  return (
    <div className="progress relative">
      <div
        class="progress-bar bg-success"
        role="progressbar"
        style={{
          width: `${plantProductivity}%`,
        }}
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
      <span
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          color: "black",
          fontWeight: "bold",
        }}
      >
        {plantProductivity.toFixed(2)}%
      </span>
    </div>
  );
}
