import React from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Header from "../../../../../components/Header";
import HeaderTitle from "../../../../../components/HeaderTitle";
import GeneratedEnergy from "./components/Chart/GeneratedEnergy";
import PVSystem from "./components/PVSystem";
import PlantsTableSort from "./components/Table/index";
import TotalAvoidedEmissions from "./components/TotalAvoidedEmissions";
import TotalDailyEnergy from "./components/TotalDailyEnergy";
import TotalMonthlyEnergy from "./components/TotalMonthlyEnergy";
import TotalWeeklyEnergy from "./components/TotalWeeklyEnergy";
import Productivity from "./components/Chart/Productivity";
import { useWindowDimensions } from "../../../../igDashboards/view/hooks/useWindowDimensions";

/*** Página ***/
const Projects = () => {
  const { t } = useTranslation();
  const { status, data } = useQuery({
    queryKey: ["readUserSolarData"],
    queryFn: async () => {
      const res = await axios.get("/readUserSolarData", {
        params: {
          monthRef: new Date(),
        },
      });
      return res.data;
    },
    refetchInterval: 1000 * 30 * 15, // 15 minutos
  });

  const {preset} = useWindowDimensions();

  const loading = status === "loading";

  if (status === "error" && !data) {
    return (
      <Container fluid>
        <Header>
          <HeaderTitle>{t("solar.solarVision")}</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">{t("sidebar.home")}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{t("solar.solar")}</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <Card>
          <CardBody
            style={{ marginTop: "54px", height: "94px", textAlign: "center" }}
          >
            {t("solar.noDataPlants")}
          </CardBody>
        </Card>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t("solar.solarVision")}</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{t("sidebar.home")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("solar.solar")}</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <div>
        {data ? (
          <div>
            <Row xs={12} style={{ marginTop: "-26px", paddingRight: "12px" }}>
              <Col xs={12} md="auto" style={{
                display: 'flex',
                flex: 1
              }} >
                <TotalDailyEnergy
                  dailyEnergy={data.general.dailyEnergy}
                  loading={loading}
                />
              </Col>
              <Col xs={12} md="auto" style={{
                display: 'flex',
                flex: 1
              }} >
                <TotalWeeklyEnergy
                  weeklyEnergy={data.general.totalWeeklyEnergy}
                  loading={loading}
                />
              </Col>
              <Col xs={12} md="auto" style={{
                display: 'flex',
                flex: 1
              }} >
                <TotalMonthlyEnergy
                  monthlyEnergy={data.general.totalMonthlyEnergy}
                  loading={loading}
                />
              </Col>
              <Col xs={12} md="auto" style={{
                display: 'flex',
                flex: 1
              }} >
                <PVSystem
                  devicesOnline={data.general.devicesOnline}
                  devicesTotal={data.general.devicesTotal}
                  loading={loading}
                />
              </Col>
              <Col xs={12} md="auto" style={{
                display: 'flex',
                flex: 1
              }} >
                <TotalAvoidedEmissions
                  AvoidedEmissions={data.general.avoidedCO2}
                  loading={loading}
                />
              </Col>
            </Row>
            <Row xs={12} style={{
              marginBottom: preset === 'desktop-hd' ? "-10px" : 'unset',
              marginTop:preset === 'desktop-hd' ? "-10px" : 'unset',
            }}>
              <Col>
                <PlantsTableSort plantList={data.plantList} loading={loading} />
              </Col>
            </Row>
            <Row xs={12}>
              <Col xs={12} lg={6} style={{
                marginRight:preset === 'desktop-hd' ? "-5px" : 'unset',
              }}>
                <Productivity />
              </Col>
              <Col xs={12} lg={6} style={{
                marginLeft:preset === 'desktop-hd' ? "-5px" : 'unset',
              }}>
                <GeneratedEnergy />
              </Col>
            </Row>
          </div>
        ) : loading ? (
          <div className="text-center mb-4" style={{ marginTop: "300px" }}>
            <Spinner color="primary" />
          </div>
        ) : (
          <Card>
            <CardBody
              style={{ marginTop: "54px", height: "94px", textAlign: "center" }}
            >
              {t("solar.noDataPlants")}
            </CardBody>
          </Card>
        )}
      </div>
    </Container>
  );
};

export default Projects;
