import { Card, CardBody, CardFooter, CardHeader, Row } from "react-bootstrap";
import { Col } from "reactstrap";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { BsSnow } from "react-icons/bs";
import { GoZap } from "react-icons/go";
import { MdOutlineGasMeter, MdOutlineWaterDrop } from "react-icons/md";
import { PiPencil, PiSolarPanelLight } from "react-icons/pi";

import { IconModule } from "../../../../../components/IconModule";
import { TextWithTooltip } from "../../../../../components/TextWithTooltip";
import { useServiceAuthContext } from "../ServiceAuthorizationsProvider";

export const CardItem = ({ item, index }) => {
  let history = useHistory();
  const { handleUpdateCard } = useServiceAuthContext();
  const { t } = useTranslation();

  const handleNavigate = (value, module, projectId) => {
    if (value) history.push(`/project-${projectId}-${module}`);
  };

  const handleCardClick = (e) => {
    if (!e.target.closest(".no-card-click")) {
      //TODO: Alterar para rota de futura tela

      history.push(`/project-${item.projectId}-default`);
    }
  };

  return (
    <Card
      key={index + item.projectId}
      style={{
        width: "260px",
        marginBottom: "4px",
        borderRadius: "5px",
        border: "1px solid #ddd",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        transition: "background-color 0.3s ease-in-out",
        padding: "10px",
      }}
      onClick={handleCardClick}
    >
      <CardHeader className="p-0">
        <Card.Title>{item.projectName}</Card.Title>
      </CardHeader>
      <CardBody className="p-0">
        <Row className="m-0 mb-2" style={{ gap: "0.375rem" }}>
        <TextWithTooltip
            id="companyName"
            text={item.companyName}
            tooltip={t("noc.form.company")}
            className={`badge-pill ${
              "badge-success"
            } m-0`}
          />
          <TextWithTooltip
            id="typeContract"
            text={item.typeContract}
            tooltip={t("noc.form.typeContract")}
            className={`badge-pill ${
              item.typeContract === "Demo" ? "badge-danger" : "badge-light"
            } m-0`}
          />
        </Row>
        <Row className="m-0 mb-2 mt-3 align-items-center">
          <Col className="d-flex m-0 p-0" style={{ gap: "0.375rem" }}>
            <IconModule
              IconComponent={PiSolarPanelLight}
              moduleValue={item.solarModule}
              onClick={() =>
                handleNavigate(item.solarModule, "solar", item.projectId)
              }
              className="no-card-click"
            />
            <IconModule
              IconComponent={GoZap}
              moduleValue={item.powerModule}
              onClick={() =>
                handleNavigate(item.powerModule, "energy", item.projectId)
              }
              className="no-card-click"
            />
            <IconModule
              IconComponent={BsSnow}
              moduleValue={item.climateModule}
              onClick={() =>
                handleNavigate(item.climateModule, "climate", item.projectId)
              }
              className="no-card-click"
            />
            <IconModule
              IconComponent={MdOutlineWaterDrop}
              moduleValue={item.waterModule}
              onClick={() =>
                handleNavigate(item.waterModule, "water", item.projectId)
              }
              className="no-card-click"
            />
            <IconModule
              IconComponent={MdOutlineGasMeter}
              moduleValue={item.gasModule}
              onClick={() =>
                handleNavigate(item.gasModule, "gas", item.projectId)
              }
              className="no-card-click"
            />
          </Col>
          <Col className="m-0 p-0">
            <TextWithTooltip
              id="editCard"
              text={
                <PiPencil
                  size="12px"
                  cursor={"pointer"}
                  onClick={() => handleUpdateCard(item)}
                />
              }
              tooltip={t("noc.form.editCard")}
              className="m-0 text-right no-card-click"
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="p-0 m-0">
        <Row className="justify-content-between m-0">
          <Col className="p-0 m-0">
            <TextWithTooltip
              id="deadlineForExecution"
              title={t("noc.form.deadlineForExecution")}
              text={moment(item.deadlineForExecution.split('T')[0]).format("DD/MM/YYYY")}
              tooltip={t("noc.form.deadlineForExecution")}
              className="m-0"
            />
          </Col>
          <Col className="p-0 m-0">
            <TextWithTooltip
              id="contractDuration"
              title={t("noc.form.contractDuration")}
              text={item.contractDuration + " " + t("noc.card.month")}
              tooltip={t("noc.form.totalDuration")}
              className="m-0 text-right"
            />
          </Col>
        </Row>
        <Row className="justify-content-between m-0 mt-1">
          <Col className="p-0 m-0">
            <TextWithTooltip
              id={"as"}
              title={item.statusName === "as" ? `${t("noc.card.createdBy")}:` : `${t("noc.card.movedBy")}:`}
              text={item.userName}
              className="m-0"
            />
          </Col>
          <Col className="p-0 m-0">
            <TextWithTooltip
              id={"commercialResponsibleId"}
              title={t("noc.form.commercialResponsible")}
              text={item.commercialResponsibleName}
              className="m-0 text-right"
            />
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};
