import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';

export const ReportBillBarChartConsumption = ({ data, windowObservable }) => {
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  useEffect(() => {

    const barChartConsumption = data.reportBill.barChartConsumption;

    const chartOptions = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: barChartConsumption.categories,
        labels: {
          style: {
            fontSize: windowObservable >= 1580 ? '24px': '14px'
          },
        },
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      legend: {
        fontSize: windowObservable >= 1580 ? '28px': '16px'
      },
      colors: ['#808080', '#CCCCCC'],
      dataLabels: {
        enabled: true,
        position: 'top',
        style: {
          colors: ['black', 'black'],
          fontSize: windowObservable >= 1580 ? '24px': '14px'
        }
      },
      stroke: {
        show: false,
        width: 0,
      }
    };

    const chartSeries = [
      {
        name: barChartConsumption.monthsPeakConsumption.name,
        data: Object.values(barChartConsumption.monthsPeakConsumption.data)
    },
    {
        name: barChartConsumption.monthsOffPeakConsumption.name,
        data: Object.values(barChartConsumption.monthsOffPeakConsumption.data)
    }
    ];

    setOptions(chartOptions);
    setSeries(chartSeries);

    setTimeout(() => {
      const dataLabels = document.querySelectorAll('.apexcharts-data-labels');
      dataLabels.forEach((label) => {
        const randomValue = -5 - Math.random() * 20;
        label.style.transform = `translateY(${randomValue}%)`;
      });
    }, 1000);

  }, [data, windowObservable]);

  return (
    <ApexCharts 
      options={options} 
      series={series} 
      type="bar" 
    />
  );
};