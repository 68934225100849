import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { fetchGetWitchParams } from "../../../../../../../../NocService";

export const BarChartDemandReactive = ({ dvcId }) => {
  const { t } = useTranslation();
  const url = `/v2/energy/readBarChartReactiveConsumedRefMeter`;
  const params = {
    dvcId: dvcId,
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().endOf("month").format("YYYY-MM-DD"),
  };

  const [data, setData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [xDomain, setXDomain] = useState([0, 1]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    fetchGetWitchParams(source, setData, setError, setLoading, url, params);

    return () => {
      source.cancel();
    };
  }, []);

  // Função para formatar os dados recebidos
  const formatData = (rawData) => {
    return rawData.totalKwDemandReTimestamp.map((timestamp, index) => ({
      timestamp: timestamp * 1000,
      [t("noc.modal.power.totalOffPeakDemandReactive")]:
        rawData.totalOffPeakDemandReactive[index],
      [t("noc.modal.power.totalPeakDemandReactive")]:
        rawData.totalPeakDemandReactive[index],
    }));
  };

  useEffect(() => {
    if (data.length !== 0) {
      const formattedData = formatData(data);

      if (formattedData.length > 0) {
        setFormattedData(formattedData);
        setXDomain([
          formattedData[0].timestamp,
          formattedData[formattedData.length - 1].timestamp,
        ]);
      }
    }
  }, [data]);

  // Função para formatar o timestamp para data legível
  const formatDate = (timestamp) => {
    const date = new Date(timestamp); // Já em milissegundos
    return `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };

  if (loading) {
    return <Skeleton style={{ width: "100%", height: "100px" }} />;
  }

  const handleReload = () => {
    setLoading(true);
    setError(null);
    const source = axios.CancelToken.source();
    fetchGetWitchParams(source, setData, setError, setLoading, url, params);
  };

  if (error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{t("noc.error.errorOccurred")}</CardTitle>
        </CardHeader>
        <CardBody>
          <Button onClick={handleReload}> {t("noc.error.tryAgain")}</Button>
        </CardBody>
      </Card>
    );
  }

  if (formattedData.length === 0) {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{t("noc.modal.graphic.noData")}</CardTitle>
        </CardHeader>
        <CardBody>
          <Button onClick={handleReload}> {t("noc.error.tryAgain")}</Button>
        </CardBody>
      </Card>
    );
  }

  const handleBrushChange = (e) => {
    if (e && e.startIndex !== undefined && e.endIndex !== undefined) {
      const startTimestamp = formattedData[e.startIndex]?.timestamp;
      const endTimestamp = formattedData[e.endIndex]?.timestamp;
      if (startTimestamp !== undefined && endTimestamp !== undefined) {
        setXDomain([startTimestamp, endTimestamp]);
      }
    }
  };

  if (data.length === 0) {
    return <div>{t("noc.modal.graphic.noData")}</div>;
  }

  return (
    <ResponsiveContainer width="100%" height={450}>
      <LineChart
        data={formattedData}
        margin={{ top: 10, right: 30, left: 0, bottom: 70 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="timestamp"
          domain={xDomain}
          type="number"
          scale="time"
          tickFormatter={formatDate}
          angle={-45}
          textAnchor="end"
          height={80}
        />
        <YAxis />
        <Tooltip labelFormatter={(label) => formatDate(label)} />
        <Legend verticalAlign="top" height={36} />
        <Line
          type="monotone"
          dataKey={t("noc.modal.power.totalOffPeakDemandReactive")}
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey={t("noc.modal.power.totalPeakDemandReactive")}
          stroke="#82ca9d"
        />
        <Brush
          dataKey="timestamp"
          height={30}
          stroke="#8884d8"
          onChange={handleBrushChange}
          tickFormatter={formatDate}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
