import React, { useEffect, useState } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";

import {
  CardBody,
  CardTitle,
  Row,
  Col,
  CardHeader,
  CardFooter,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardSubtitle,
  UncontrolledTooltip,
} from "reactstrap";
import * as Icon from "react-feather";
import { MoreVertical } from "react-feather";
import { Link, useHistory } from "react-router-dom";

import ModalDeleteCard from "../ModalDeleteCard";
import Thermometer from "./components/Thermometer";
import { useQuery } from "@tanstack/react-query";

function CardDropdown(props) {
  const { t } = useTranslation();

  const [openDeleteCard, setOpenDeleteCard] = useState(false);
  const [editCard, setEditCard] = useState(false);

  function toggleDeleteCard() {
    setOpenDeleteCard(!openDeleteCard);
  }

  return (
    <UncontrolledDropdown className="d-inline-block" disabled={props.preview}>
      <DropdownToggle tag="a">
        <MoreVertical />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          onClick={() => {
            toggleDeleteCard();
          }}
        >
          {t("dashboards.delete")}
        </DropdownItem>
        {openDeleteCard ? (
          <ModalDeleteCard
            open={openDeleteCard}
            toggle={toggleDeleteCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
          />
        ) : null}
        <Link
          onClick={() => setEditCard(props.cardId)}
          to={{
            pathname: `/newCard/thermometerCard/${props.cardId}`,
            state: {
              cardProps: props.card,
              editCard: editCard,
            },
          }}
        >
          <DropdownItem>{t("dashboards.edit")}</DropdownItem>
        </Link>

        <DropdownItem
          onClick={() => {
            props.setDataLoading(true);
          }}
        >
          {t("dashboards.refresh")}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

const ThermometerChart = ({
  cardId,
  card,
  name,
  description,
  decimalNumber,
  deviceId,
  variables,
  tableReload,
  preview,
  reloadTime,
  setMinValue,
  maxTemperature,
  dimensions,
  retrieveDataFromPreviewCard,
}) => {
  const { t } = useTranslation();

  const [deviceData, setdeviceData] = useState([]);
  const [dataLoading, setdataLoading] = useState(true);
  const [device, setDevice] = useState("");
  const [cardHeight, setCardHeight] = useState();
  const [varUnit, setVarUnit] = useState("");
  const [lastComm, setLastComm] = useState();
  const [commInterval, setCommInterval] = useState();

  const varName = variables ? JSON.parse(variables)?.varName : "";
  const varTag = variables ? JSON.parse(variables).tag : "";

  let history = useHistory();

  // Enviao id do device e retorna os valores
  useEffect(() => {
    if (dimensions) {
      if (dimensions?.id === card?.id) {
        let var1 =
          dimensions.size.height < 240 && card.id === dimensions.id
            ? 67
            : dimensions.size.height > 241 &&
              card.id === dimensions.id &&
              dimensions.size.height < 320
            ? 110
            : dimensions.size.height > 321 &&
              card.id === dimensions.id &&
              dimensions.size.height < 370
            ? 200
            : dimensions.size.height > 371 &&
              card.id === dimensions.id &&
              dimensions.size.height < 420
            ? 250
            : dimensions.size.height > 421 &&
              card.id === dimensions.id &&
              dimensions.size.height < 500
            ? 290
            : dimensions.size.height > 501 && card.id === dimensions.id
            ? 350
            : 68;
        setCardHeight(var1);
      }
    }
  }, [dimensions, card?.id]);

  //Envia o id do device e retorna o último dado
  useQuery({
    queryKey: ["readDataLast", { id: deviceId }],
    queryFn: () => axios.get("/readDataLast", { params: { idDevice: deviceId } }),
    onSuccess: (response) => {
      setdeviceData(response.data);
      setLastComm(response.data.lastComm);
      setCommInterval(response.data.commInterval);
      setdataLoading(false);
    },
    onError: (error) => {
      console.log(error);
      toastr.warning("Erro!", "Erro ao buscar dados.");
    },
    refetchInterval: reloadTime,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  })

  //Envia o id do device e retorna o modelo
  useQuery({
    queryKey: ["readDeviceInfo", { id: deviceId }],
    queryFn: () => axios.get("/readDeviceInfo", { params: { idDevice: deviceId } }),
    onSuccess: (response) => {
      setDevice(response.data);
      setLastComm(response.data.lastComm);
      setCommInterval(response.data.commInterval);
    },
    onError: (error) => {
      console.log(error);
      toastr.error("Erro!", "Erro ao buscar Modelo.");
    },
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })



  //Envia o id do device e retorna a unidade da variável
  const { isLoading } = useQuery({
    queryKey: ["readVariables/unit", { id: deviceId }, varTag],
    queryFn: () => axios.get("/readVariables", { params: { id: deviceId } }),
    onSuccess: (response) => {
      console.log("varTag", varTag);
      console.log("response", response.data);
      setVarUnit(response.data.find((item) => item.tag === varTag).unit);
    },
    onError: (error) => {
      console.log(error);
      toastr.error("Erro!", "Erro ao buscar Unidade.");
    },
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    enabled: !!deviceId,
  });

  const lastCommDate = new Date(lastComm * 1000);

  return (
    <div id="parent">
      <CardHeader>
        <div className="card-actions float-right" style={{ marginTop: "2px" }}>
          <Row className="card-actions float-right">
            <Col className="pl-0" style={{ paddingRight: "3px" }}>
              <div className="box no-cursor cursor-pointer mp-0 otavio">
                <Icon.Move style={{ width: "15px" }} />
              </div>
            </Col>
            <CardDropdown
              card={card}
              cardId={cardId}
              tableReload={tableReload}
              name={name}
              description={description}
              preview={preview}
              setDataLoading={setdataLoading}
            />
          </Row>
        </div>
        <div id={"name" + cardId}>
          {name ? (
            <UncontrolledTooltip
              style={{
                backgroundColor: "#202020",
                opacity: 1,
                fontSize: ".8rem",
                color: "#F1F1F1",
                border: "1px solid #000",
                padding: "1rem",
              }}
              placement="top"
              target={"name" + cardId}
            >
              {name}
            </UncontrolledTooltip>
          ) : null}
          <CardTitle
            style={{ whiteSpace: "nowrap" }}
            className="mr-5 overflow-hidden text-left my-1 h5"
          >
            {name}
          </CardTitle>
          <CardSubtitle
            style={{ whiteSpace: "nowrap" }}
            className="mr-5 overflow-hidden text-muted text-left"
          >
            {description}
          </CardSubtitle>
          {description ? (
            <UncontrolledTooltip
              style={{
                backgroundColor: "#202020",
                opacity: 1,
                fontSize: ".8rem",
                color: "#F1F1F1",
                border: "1px solid #000",
                padding: "1rem",
              }}
              placement="bottom"
              target={"name" + cardId}
            >
              {description}
            </UncontrolledTooltip>
          ) : null}
        </div>
      </CardHeader>
      <CardBody
        id={"desc" + cardId}
        className="text-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 20,
          paddingTop: "0px",
        }}
      >
        {deviceData[varTag] || deviceData[varTag] === 0 ? (
          //verifica se tem devices e o conteúdo e se o conteúdo é igual a zero ele printa a variável e a unidade selecionada no novo card, se não não há dados.
          lastComm >= Math.floor(Date.now() / 1000) - commInterval * 2 ? (
            <div>
              {cardHeight && !isLoading ? (
                <Thermometer
                  cardId={cardId}
                  cardHeight={cardHeight}
                  varUnit={varUnit}
                  minValue={card.minValueProp}
                  maxValue={card.maxValueProp}
                  deviceData={deviceData[varTag]}
                />
              ) : null}
            </div>
          ) : (
            <p className="mt-1">{t("dashboards.offlineStats")}</p>
          )
        ) : (
          <p className="mt-1">{t("dashboards.noDataStats")}</p>
        )}
      </CardBody>
      {cardHeight > 67 ? (
        <CardFooter
          style={{
            whiteSpace: "nowrap",
            backgroundColor: "transparent",
            bottom: "0",
            position: `${!preview ? "absolute" : null}`,
            padding: "5px 1.25rem",
          }}
          className="mr-5 overflow-hidden text-left my-1 h5 text-left mt-1"
        >
          <h6 className="text-left">{device.description}</h6>
          <h6 className="card-subtitle text-muted">{varName}</h6>
          <h6 className="card-subtitle text-muted mt-1">
            {lastComm
              ? lastCommDate.getDate().toString().padStart(2, "0") +
                "/" +
                (lastCommDate.getMonth() + 1).toString().padStart(2, "0") +
                "/" +
                lastCommDate.getFullYear().toString().padStart(2, "0") +
                " " +
                lastCommDate.getHours().toString().padStart(2, "0") +
                ":" +
                lastCommDate.getMinutes().toString().padStart(2, "0") +
                ":" +
                lastCommDate.getSeconds().toString().padStart(2, "0")
              : t("dashboards.noComm")}
          </h6>
        </CardFooter>
      ) : null}
    </div>
  );
};

export default ThermometerChart;
