import React, { useEffect } from "react";
import { CardHeader, CardBody, CardTitle } from "reactstrap";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';

let chartHeight = window.innerWidth > 700 ? "335px" : "277px";

const TrackersChart = ({ data }) => {
  return (
    <>
      <LineChart width={800} height={400} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip />
        <Legend />
        {Array.from(new Set(data.map((d) => d.name))).map((name) => (
          <Line
            key={name}
            type="monotone"
            dataKey="value"
            stroke={Math.floor(Math.random() * 16777215).toString(16)} // Cores aleatórias para cada linha
            name={name}
          />
        ))}
      </LineChart>
    </>
  );
};

const Trackers = ({ data }) => {
  console.log(data);

  return data ? (
    <>
      <CardHeader>
        <CardTitle tag="h5">Trackers</CardTitle>
      </CardHeader>

      <CardBody>
        <TrackersChart data={data} />
      </CardBody>
    </>
  ) : null;
};

export default Trackers;
