import { useState } from "react";
import { Table } from "reactstrap";

//NOTE: Abaixo é um exemplo de como deve ser a variavel columns
// const exampleColumns = [
//   { field: "id", headerName: "#", width: 130 },
//   { field: "description", headerName: "Descrição", width: 250 },
//   {
//     field: "totalPeakConsumption",
//     headerName: "Total Ponta",
//     width: 130,
//   },
//   ...
// ];
//NOTE: Abaixo é um exemplo de como deve ser a variavel rows
// const exampleRows = [
//   {
//     id: 1,
//     description: "123",
//     totalPeakConsumption: 0,
//     ...
//   },
// ];

export const DataTable = ({ columns, rows }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowClick = (id) => {
    const index = selectedRows.indexOf(id);
    if (index === -1) {
      setSelectedRows([...selectedRows, id]);
    } else {
      const newSelectedRows = [...selectedRows];
      newSelectedRows.splice(index, 1);
      setSelectedRows(newSelectedRows);
    }
  };

  return (
    <Table hover responsive size="">
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.field} style={{ width: column.width }}>
              {column.headerName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows?.map((row) => (
          <tr
            key={row.id}
            className={selectedRows.includes(row.id) ? "selected-row" : ""}
            onClick={() => handleRowClick(row.id)}
          >
            {columns.map((column) => (
              <td key={column.field}>
                {row[column.field] || "-"}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
