const ptBrHomeTranslations = {
    homePage: {
        title: "Página Inicial",
        waterVision: "Visão Geral do Módulo Água",
        gasVision: "Visão Geral do Módulo Gás", 
        water: "Água",
        gas: "Gás",
        pvWater: "Planta de Água",
        pvGas: "Planta de Gás",
        totalConsumption: "Consumo Total",
        peak: "Consumo Ponta",
        offPeak: "Consumo Fora Ponta",
        status: "Status",
        tooltipOff: "Falha de comunicação em todos os dispositivos",
        tooltipOn: "Todos os dispositivos estão comunicando",
        tooltipWarning: "Falha de comunicação em um ou mais dispositivos",
        noWaterDataPlants: "Não há plantas do tipo Água",
        noGasDataPlants: "Não há plantas do tipo Gás",
        consumption: "Consumo",
    },
    menuCard: {
        myAccount: "Minha conta",
        sharing: "Rateio de Consumo"
    },
    public: {
        expand: "Expandir",
        lastComm: "Última comunicação",
        deviceModel: "Modelo",
        deviceStatus: "Status",
    },
}

export default ptBrHomeTranslations;