const enUsLayoutTranslations = {
  sidebar: {
    logout: "Logout",
    data: "Data",
    tools: "Tools",
    adminOnly: "Admin Tools",
    home: "Home Page",
    dashboards: "Dashboards",
    dataExplorer: "Data Explorer",
    projects: "My Plants",
    alarms: "Alarms",
    registers: "Settings",
    security: "Security",
    management: "Management",
    alarmView: "History",
    alarmConfig: "Parameters",
    alarmsData: "Parameter Settings",
    usersData: "Companies and Users",
    projectsData: "Plants and Devices",
    logsData: "User Action Logs",
    myDriversData: "Driver Creator",
    gateways: "Gateway Management",
    devices: "Devices",
    solarView: "Solar",
    energyView: "Energy",
    iot: "IoT",
    specialist: "Specialist",
    solarModuleOn: "Solar",
    powerModuleOn: "Power",
    waterModuleOn: "Water",
    gasModuleOn: "Gas",
    climateModuleOn: "Climate",
    nobreakModuleOn: "Ups",
    changeTheme: "Change Theme",
    notifications: "Notifications",
    changeLanguage: "Change Language",
    profile: "Profile",
    soundNotifications: "Sound Notifications",
    noc: "Network Operations Center",
    nocCenter: "Network Operations Center",
    nocAs: "Service Authorizations",
    nocAsCommercial: "Commercial Management",
  },
  footer: {
    support: "Support",
    privacy: "Privacy",
    terms: "Terms of Service",
    contact: "Contact",
  },
  navbar: {
    search: "Search",
    alarms: "Alarms",
    activeAlarms: "active alarms",
    activeAlarm: "active alarm",
    noAlarms: "no active alarms",
    settings: "Preferences",
    logout: "Log out",
  },
  theme: {
    modern: "Modern",
    classic: "Classic",
    dark: "Dark",
    light: "Light",
  },
};

export default enUsLayoutTranslations;
