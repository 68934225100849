import React from "react";
import { connect } from "react-redux";

import Content from "../components/Content";
import Footer from "../components/Footer";
import Main from "../components/Main";
import { SidebarV2 } from "../components/SidebarV2";
import Wrapper from "../components/Wrapper";

const Dashboard = ({ sidebar, children }) => (
  <React.Fragment>
    <Wrapper>
      <SidebarV2 />
      <Main>
        <Content>{children} </Content>
        <Footer />
      </Main>
    </Wrapper>
  </React.Fragment>
);

export default connect((store) => ({
  sidebar: store.sidebar,
}))(Dashboard);
