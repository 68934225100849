const enUsDataExplorerTranslations = {
  dataExplorer: {
    noData: "There is no data.",
    noProject: "There's no projects registered.",
    graphics: "Graphics",
    dataTable: "Table",
    parameters: "Parameters",
    start: "Start",
    end: "End",
    projectsDevices: "Projects/Devices",
    projects: "Projects",	
    project: "Project",
    devices: "Devices",
    device: "Device",
    toView: "To View",
    selectVariables: "Select the Variables",
    variablesSelected: "Selected Variables",
    select: "Select",
    remove: "Remove",
    selected: "Selected",
    available: "Available",
    variable: "Variable",
    variables: "Variables",
    search: "Search",
    customizeColors: "Customize Colors",
    attention: "Attention",
    error: "Error",
    errorFetchingData: "Error fetching data.",
    success: "Success",
    warningStartEndDate: "The start date must be before the end date.",
    warningResolutionDate: "The resolution cannot be greater than the selected data time.",
    aggregation: "Aggregation",
    resolution: "Resolution",
    selectAggregation: "Please, select an aggregation",
    selectResolution: "Please, select a resolution",
    typeVariable: "Variable Type",
    selectTypeVariable: "Please, select a variable type value",
    integral: "Integral",
    differential: "Differential",
    first: "First",
    last: "Last",
    maximum: "Maximum",
    minimum: "Minimum",
    average: "Average",
    sum: "Sum",
    none: "None",
    minutes: "Minutes",
    hours: "Hours",
    timestamp: "Date & Time",
    maxSizeInBytes: "Too much data for the dashboard",
    loading: "Loading...",
    export: "Export",
    nextPage: "Next",
    previousPage: "Previous",
  },
};


export default enUsDataExplorerTranslations;