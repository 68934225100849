import axios from "axios";
import React, { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { toastr } from "react-redux-toastr";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

/****** MODAL DE REGISTRO DE USUÁRIO ******/
const ModalNewUser = ({ open, toggle, companyId, tableReload }) => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loginUser, setLoginUser] = useState("");
  const [userType, setUserType] = useState("2");
  const [linked, setLinked] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  //                                  email  username
  const [valid, setValid] = useState([false, false]);

  const [defaultPassword, setDefaultPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <Form
        onSubmit={(e) => {
          e.preventDefault(); // evitar erro ao enviar dados com Enter
          setSaveLoading(true);
          const btn = e.nativeEvent.submitter.name;

          /* Salvar dados do formulário no formato JSON */
          const jsonData = JSON.stringify({
            companyId: companyId,
            name: name,
            email: email,
            phone: phone,
            loginUser: loginUser,
            userType: userType,
            isLinked: linked,
            module: "default",
            defaultPassword: defaultPassword,
            password: password,
          });

          /*** Envio de dados ***/
          axios
            .post("newUser/", jsonData)
            .then((res) => {
              toastr.success(res.data.messageHead, res.data.messageBody);

              /* se dados foram enviados pelo botão "salvar dados" */
              if (btn === "btnClose") {
                toggle(); // fechar o modal
              } else {
                // caso contrário
                /* limpar campos do formulario */
                setName("");
                setEmail("");
                setLoginUser("");
                setUserType("2");
                setValid([false, false]);
              }
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response);

              if (err.response) {
                /* Notificação de erro de envio */
                toastr.warning(
                  err.response.data.messageHead,
                  err.response.data.messageBody
                );
                setValid([
                  Boolean(err.response.data.emailExists),
                  Boolean(err.response.data.loginUserExists),
                ]);
              }
            })
            // atualizar visualização da tabela
            .finally(() => {
              tableReload();
              setSaveLoading(false);
            });
        }}
      >
        <ModalHeader toggle={toggle}>{t("dataTable.newUser")}</ModalHeader>

        <ModalBody className="text-center m-3">
          <div className="m-sm-4">
            {/*** Empresa e Nome ***/}
            <Row form>
              {/*** Empresa ***/}
              <Col md={6}>
                <FormGroup>
                  <Label>{t("dataTable.company")}</Label>
                  <Input
                    type="select"
                    name="companyId"
                    id="companyId"
                    defaultValue={companyId}
                    disabled // usuário deve estar vinculado à empresa selecionada
                  >
                    {JSON.parse(localStorage.getItem("companyList")).map(
                      (item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.name}
                          </option>
                        );
                      }
                    )}
                  </Input>
                </FormGroup>
              </Col>

              {/*** Nome ***/}
              <Col md={6}>
                <FormGroup>
                  <Label>{t("dataTable.fullName")}</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t("dataTable.fullName")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* E-mail e telefone */}
            <Row form>
              {/*** E-mail ***/}
              <Col md={6}>
                <FormGroup>
                  <Label>E-mail</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="user@mail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    invalid={valid[0]}
                  />
                </FormGroup>
              </Col>

              {/*** Telefone ***/}
              <Col md={6}>
                <FormGroup>
                  <Label>Telefone</Label>
                  <InputMask
                    mask="(99) 99999-9999"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  >
                    {(inputProps) => (
                      <Input
                        {...inputProps}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="(xx) xxxxx-xxxx"
                        // required
                      />
                    )}
                  </InputMask>
   
                </FormGroup>
              </Col>
            </Row>

            {/* Username e tipo de usuário */}
            <Row form>
              {/*** Nome de usuário (username) ***/}
              <Col md={6}>
                <FormGroup>
                  <Label>{t("dataTable.username")}</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="username"
                    value={loginUser}
                    onChange={(e) => setLoginUser(e.target.value)}
                    required
                    invalid={valid[1]}
                  />
                </FormGroup>
              </Col>

              {/*** Tipo de usuário (admin/default/endUser) ***/}
              <Col md={6}>
                <FormGroup>
                  <Label>{t("dataTable.userType")}</Label>
                  <Input
                    type="select"
                    value={userType}
                    onChange={(e) => {
                      let userType = e.target.value;
                      setUserType(userType);
                      userType === "3" ? setLinked(false) : setLinked(true);
                    }}
                  >
                    <option value="2">{t("dataTable.default")}</option>
                    <option value="1">{t("dataTable.admin")}</option>
                    <option value="3">{t("dataTable.endUser")}</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <FormGroup className="m-0">
                  <Input
                    type="checkbox"
                    checked={defaultPassword}
                    onChange={(e) => setDefaultPassword(e.target.checked)}
                  />
                  <Label>{t("dataTable.checkDefaultPassword")}</Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>{t("dataTable.password")}</Label>
                  <Row st>
                    <Col md={10}>
                      <Input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder={t("dataTable.password")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required={!defaultPassword}
                        disabled={defaultPassword}
                      />
                    </Col>
                    <Button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      color="link"
                      className="p-0"
                      disabled={defaultPassword}
                    >
                      {!showPassword ? <EyeOff /> : <Eye />}
                    </Button>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
           * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button
            name="btnCancel"
            color="primary"
            className="mr-1 mb-1"
            onClick={toggle}
            outline
          >
            {t("dataTable.cancel")}
          </Button>
          {/*** BOTÃO DE ENVIAR DADOS PARA A API SEM FECHAR O MODAL ***/}
          <Button
            name="btnOpen"
            type="submit"
            color="primary"
            disabled={saveLoading}
            style={{ width: "140px" }}
            // onClick={() => setSaveLoading(true)}
          >
            {saveLoading ? (
              <Spinner size={"sm"} />
            ) : (
              t("dataTable.saveContinue")
            )}
          </Button>
          {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
          <Button
            name="btnClose"
            type="submit"
            color="primary"
            disabled={saveLoading}
            style={{ width: "120px" }}
            // onClick={() => setSaveLoading(true)}
          >
            {saveLoading ? <Spinner size={"sm"} /> : t("dataTable.saveUsr")}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

/****** MODAL DE REGISTRO DE EMPRESA ******/
const ModalNewCompany = ({ open, toggle, companyList, tableReload }) => {
  const { t } = useTranslation();

  let ids = [];
  companyList.map((item) =>
    ids.push({
      id: item.id,
      name: item.name,
    })
  );

  const [companyName, setCompanyName] = useState("");
  const [parentId, setParentId] = useState(localStorage.getItem("companyId"));
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [loginUser, setLoginUser] = useState("");
  const [compListId, setCompListId] = useState(ids);
  const [saveLoading, setSaveLoading] = useState(false);

  const [userType, setUserType] = useState("2");
  const [linked, setLinked] = useState(true);

  const [defaultPassword, setDefaultPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  //                                  comp   email  username
  const [valid, setValid] = useState([false, false, false]);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <Form
        onSubmit={(e) => {
          // para enviar dados com Enter
          e.preventDefault(); // evita atualizar página
          setSaveLoading(true);
          const btn = e.nativeEvent.submitter.name;

          /* Salvar dados do formulário no formato JSON */
          const jsonData = JSON.stringify({
            companyName: companyName,
            parentId: parentId,
            contactName: contactName,
            contactEmail: contactEmail,
            loginUser: loginUser,
            userType: userType,
            isLinked: linked,
            module: "default",
            defaultPassword: defaultPassword,
            password: password,
          });

          /*** Envio de dados ***/
          axios
            .post("newCompany/", jsonData)
            .then((result) => {
              toastr.success(result.data.messageHead, result.data.messageBody);

              /* se dados foram enviados pelo botão "salvar dados" */
              if (btn === "btnClose") {
                toggle(); // fechar o modal
                tableReload();
              } else {
                // caso contrário
                /* atualiza lista de empresas */
                setCompListId([
                  ...compListId,
                  {
                    id: result.data.newCompanyId,
                    name: companyName,
                  },
                ]);

                /* limpar campos do formulario */
                setCompanyName("");
                setContactName("");
                setContactEmail("");
                setLoginUser("");
                setValid([false, false, false]);
              }
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response);

              if (err.response) {
                /* Notificação de erro de envio */
                toastr.warning(
                  err.response.data.messageHead,
                  err.response.data.messageBody
                );
                /* Realce de campo inválido */
                err.response.data.emailExists ||
                err.response.data.loginUserExists
                  ? setValid([
                      false,
                      Boolean(err.response.data.emailExists),
                      Boolean(err.response.data.loginUserExists),
                    ])
                  : setValid([true, false, false]);
              }
            })
            .finally(() => setSaveLoading(false));
        }}
      >
        <ModalHeader toggle={toggle}>{t("dataTable.newCompany")}</ModalHeader>

        <ModalBody className="text-center m-3">
          <h6 className="text-muted">{t("dataTable.insertCompany")}</h6>
          {/*** DADOS DA EMPRESA ***/}
          <Row form>
            {/*** CAMPO DE NOME DA EMPRESA ***/}
            <Col md={6}>
              <FormGroup>
                <Label>{t("dataTable.name")}</Label>
                <Input
                  type="text"
                  name="companyName"
                  id="companyName"
                  placeholder={t("dataTable.name")}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                  invalid={valid[0]}
                />
              </FormGroup>
            </Col>
            {/*** CAMPO DE VINCULAR EMPRESA ***/}
            <Col md={6}>
              <FormGroup>
                <Label>{t("dataTable.linkCompany")}</Label>
                {compListId ? (
                  <Input
                    type="select"
                    name="linkCompanyId"
                    id="linkCompanyId"
                    value={parentId}
                    onChange={(e) => setParentId(e.target.value)}
                  >
                    {compListId.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                ) : (
                  <div>
                    <Spinner color="primary" />
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <hr />

          {/* <h6 className="text-muted">{t('dataTable.insertUser')}</h6> */}
          {/*** DADOS DO PRIMEIRO USUÁRIO *
           * (empresa não pode existir sem usuários) ***/}
          <Row form>
            {/*** CAMPO DE NOME DO USUÁRIO ***/}
            <Col md={6}>
              <FormGroup>
                <Label>{t("dataTable.fullName")}</Label>
                <Input
                  type="text"
                  name="contactName"
                  id="contactName"
                  placeholder={t("dataTable.fullName")}
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            {/*** CAMPO DE EMAIL DO USUÁRIO ***/}
            <Col md={6}>
              <FormGroup>
                <Label>E-mail</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-mail"
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                  required
                  invalid={valid[1]}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            {/*** Nome de usuário (username) ***/}
            <Col md={6}>
              <FormGroup>
                <Label>{t("dataTable.username")}</Label>
                <Input
                  type="text"
                  name="username"
                  id="username"
                  placeholder="username"
                  value={loginUser}
                  onChange={(e) => setLoginUser(e.target.value)}
                  required
                  invalid={valid[1]}
                />
              </FormGroup>
            </Col>

            {/*** Tipo de usuário (admin/default/endUser) ***/}
            <Col md={6}>
              <FormGroup>
                <Label>{t("dataTable.userType")}</Label>
                <Input
                  type="select"
                  value={userType}
                  onChange={(e) => {
                    let userType = e.target.value;
                    setUserType(userType);
                    userType === "3" ? setLinked(false) : setLinked(true);
                  }}
                >
                  <option value="2">{t("dataTable.default")}</option>
                  <option value="1">{t("dataTable.admin")}</option>
                  <option value="3">{t("dataTable.endUser")}</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <FormGroup className="m-0">
                <Input
                  type="checkbox"
                  checked={defaultPassword}
                  onChange={(e) => setDefaultPassword(e.target.checked)}
                />
                <Label>{t("dataTable.checkDefaultPassword")}</Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>{t("dataTable.password")}</Label>
                <Row st>
                  <Col md={10}>
                    <Input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder={t("dataTable.password")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required={!defaultPassword}
                      disabled={defaultPassword}
                    />
                  </Col>
                  <Button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    color="link"
                    className="p-0"
                    disabled={defaultPassword}
                  >
                    {!showPassword ? <EyeOff /> : <Eye />}
                  </Button>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR ***/}
          <Button
            name="btnCancel"
            type="button"
            color="primary"
            className="mr-1 mb-1"
            onClick={() => {
              toggle();
              tableReload(); // atualizar visualização da tabela
            }}
            outline
          >
            {t("dataTable.cancel")}
          </Button>

          {/*** BOTÃO DE ENVIAR DADOS PARA A API SEM FECHAR O MODAL ***/}
          <Button
            name="btnOpen"
            type="submit"
            color="primary"
            disabled={saveLoading}
            style={{ width: "140px" }}
            // onClick={() => setSaveLoading(true)}
          >
            {saveLoading ? (
              <Spinner size={"sm"} />
            ) : (
              t("dataTable.saveContinue")
            )}
          </Button>

          {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
          <Button
            name="btnClose"
            type="submit"
            color="primary"
            disabled={saveLoading}
            style={{ width: "120px" }}
            // onClick={() => setSaveLoading(true)}
          >
            {saveLoading ? <Spinner size={"sm"} /> : t("dataTable.saveCompany")}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export { ModalNewCompany, ModalNewUser };
