import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmog } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';

const TotalAvoidedEmissions = ({ AvoidedEmissions, loading }) => {
  const { t } = useTranslation();

  // condição para exibir a grandeza
  if (AvoidedEmissions) {
    var avoidedAndUnit = (AvoidedEmissions <= 999) 
      ? (AvoidedEmissions.toFixed(1)).replace(".", ",") + " kg" 
      : (AvoidedEmissions/1000).toFixed(2) + " t";
  }

  return (
    <div className="w-100">
      <Row >
        <Col sm="12" style={{paddingRight:"0px"}}>
          <Card style={{ minWidth: "177px", maxHeight:"113px", height: "113px"}}>
            <CardBody style={{padding: "15px"}}>
              <Row style={{height:"49px"}}>
                <Col className="mt-0">
                  <CardTitle tag="h5">{t('solar.avoided')}<br></br>{t('solar.emissions')}</CardTitle>
                </Col>
                <Col className="col-auto" style={{paddingLeft:"0px"}}>
                  <div style={{width:"26px",height:"26px"}}>
                    <div className="avatar-title rounded-circle bg-primary-dark">
                      <FontAwesomeIcon icon={faSmog} fixedWidth className="align-middle" />
                    </div>
                  </div>
                </Col>
              </Row>
              {(loading)
                ? <div className="text-center mb-4"><Spinner /></div>
                : (loading)
                  ? <div className="text-center mb-4"> <Spinner color="primary"/></div>
                  : (AvoidedEmissions)
                    ? <h1 className="display-5 text-center" style={{fontSize:"26px", marginBottom: "0px"}}>{avoidedAndUnit} CO₂</h1>
                    : <p className="text-center">Não há dados</p>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
  </div>
  );
}   

export default TotalAvoidedEmissions;