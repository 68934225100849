import React, { useState } from "react";
import {
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Spinner
} from "reactstrap";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';

function onDeleteProject(projId, setLoadProjects, toggle, setCaptchaInput) {
  /*** Envio de solicitação para a API ***/
  // axios.post('deleteProject/', JSON.stringify({
  //   projId: projId
  // }))
  axios.delete(`v2/project/clearProjectData?projId=${projId}`)
    .then(res => {
      /* Busca dispositivos do projeto */
      // axios.post('deviceSearch/', JSON.stringify({
      //   projId: projId
      // }))
      axios.get('/deviceSearch', {
        params: {
          projId: projId
        }
      })
        .then(res1 => {
          // Remove dispositivos encontrados
          if (res1.data.length) {
            res1.data.forEach(item => {
              // axios.post('deleteDevice/', JSON.stringify({
              //   deviceId: item.id
              // }))
              axios.delete('deleteDevice/', { data: { deviceId: item.id } })
                .then(res2 => {
                  console.log(res2.data);
                })
                .catch(err => {
                  console.log(err);
                  console.log(err.response);
                })
            })
          }
          toastr.success(res.data.messageHead, res.data.messageBody);
          setLoadProjects(true) // atualiza tabela
          toggle(); // fecha o modal
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
        })
    })
    .catch(err => {
      console.log(err);
      console.log(err.response);

      if (err.response) {
        toastr.warning( // Notificação de erro de envio
          err.response.data.messageHead, err.response.data.messageBody
        );
      }
      setCaptchaInput('')
    });
};

/****** MODAL DE REMOVER PROJETO ******/
const ModalDeleteProject = ({ open, toggle, proj, setLoadProjects }) => {
  const { t } = useTranslation();

  const [captchaInput, setCaptchaInput] = useState('');

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <Form
        onSubmit={e => {
          e.preventDefault(); // evita atualização da página
          /* Verificação do captcha */
          if (captchaInput === 'DELETE') {
            // excecuta função de remover projeto
            onDeleteProject(proj.id, setLoadProjects, toggle, setCaptchaInput)
          } else {
            /* Notificação de captcha incorreto */
            toastr.warning(
              `${t('projTable.error')}!`,
              t('projTable.captcha')
            );
            // Limpa campo de entrada
            setCaptchaInput('');
          }
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('projTable.deleteProject')} {proj.name}
        </ModalHeader>
        <ModalBody className="text-center mx-6">
          <div className="m-sm-4">
            <h6>{t('projTable.att')}!</h6>
            <p className="mb-0">
              {t('projTable.captchaLabel')}:
            </p>
            {/*** CAMPO DE CAPTCHA ***/}
            <Input
              type="text"
              name="captcha"
              id="captcha"
              value={captchaInput}
              onChange={e => setCaptchaInput(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button
            color='primary'
            type="button"
            onClick={toggle}
            outline
          >
            {t('projTable.cancel')}
          </Button>
          {/*** BOTÃO DE DELETAR USUÁRO ***/}
          <Button
            color="primary"
            type="submit"
          >
            {t('projTable.saveDelete')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
};

/****** MODAL DE REMOVER DISPOSITIVO ******/
const ModalDeleteDevice = ({ open, toggle, dvc, setLoadDevices }) => {
  const { t } = useTranslation();

  const [captchaInput, setCaptchaInput] = useState('');
  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api

  function onSubmit() {
    /* Verificação do captcha */
    if (captchaInput === 'DELETE') {
      setLoading(true);
      /*** Envio de solicitação para a API ***/
      // axios.post('deleteDevice/', JSON.stringify({
      //   deviceId: dvc.id
      // }))
      axios.delete('deleteDevice/', { data: { deviceId: dvc.id } })
        .then(res => {
          setLoading(false);
          toastr.success(res.data.messageHead, res.data.messageBody);

          setLoadDevices(true) // atualiza tabela
          toggle(); // fecha o modal
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
          console.log(err.response);

          if (err.response) {
            toastr.warning( // Notificação de erro de envio
              err.response.data.messageHead, err.response.data.messageBody
            );
          }
          setCaptchaInput('')
        });
    } else {
      /* Notificação de captcha incorreto */
      toastr.warning(
        `${t('projTable.error')}!`,
        t('projTable.captcha')
      );
      // Limpa campo de entrada
      setCaptchaInput('')
    }
  }

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <Form
        onSubmit={e => {
          e.preventDefault(); // evita atualização da página
          onSubmit();
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('projTable.deleteDevice')} {dvc.description}
        </ModalHeader>

        <ModalBody className="text-center mx-6">
          <div className="m-sm-4">
            <h6>{t('projTable.att')}!</h6>
            <p className="mb-0">
              {t('projTable.captchaLabel')}
            </p>
            {/*** CAMPO DE CAPTCHA ***/}
            <Input
              type="text"
              name="captcha"
              id="captcha"
              value={captchaInput}
              onChange={e => setCaptchaInput(e.target.value)}
              disabled={loading}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          {(loading)
            ? <Spinner color="primary" className="mr-3" />
            : <>
              <Button
                color='primary'
                onClick={toggle}
                outline
              >
                {t('projTable.cancel')}
              </Button>
              <Button
                color="primary"
                type="submit"
              >
                {t('projTable.deleteDevice')}
              </Button>
            </>
          }
        </ModalFooter>
      </Form>
    </Modal>
  );
};

/****** MODAL DE REMOVER DISPOSITIVO ******/
const ModalDeleteGateway = ({ open, toggle, dvc, setLoadDevices, deviceList }) => {
  const { t } = useTranslation();

  const [newGatewayId, setNewGatewayId] = useState('');
  const [captchaInput, setCaptchaInput] = useState('');

  const [invalidGW, setInvalidGW] = useState(false);
  const [invalidCaptcha, setInvalidCaptcha] = useState(false);

  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api

  const ppList = deviceList.filter(item => item.gatewayId === dvc.id);
  const gwList = deviceList.filter(item => item.category === 'gw' && item.id !== dvc.id);

  function onSubmit() {
    // if (!newGatewayId && ppList.length) {
    //   toastr.warning(`${t('projTable.att')}!`, `${t('projTable.newGateway')}.`);
    //   setInvalidGW(true);
    //   setCaptchaInput('');
    // }

    if (captchaInput !== 'DELETE') {
      /* Notificação de captcha incorreto */
      toastr.warning(
        `${t('projTable.error')}!`,
        t('projTable.captcha')
      );
      // Limpa campo de entrada
      setCaptchaInput('');
      setInvalidCaptcha(true);
    }

    else {
      const jsonData = {
        deviceId: dvc.id,
        newGatewayId: (newGatewayId) ? newGatewayId : null
      }

      setLoading(true);
      /*** Envio de solicitação para a API ***/
      axios.delete('deleteGateway/', { data: jsonData })
        .then(res => {
          setLoading(false);
          toastr.success(res.data.messageHead, res.data.messageBody);

          setLoadDevices(true) // atualiza tabela
          toggle(); // fecha o modal
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
          console.log(err.response);

          if (err.response) {
            toastr.warning( // Notificação de erro de envio
              err.response.data.messageHead, err.response.data.messageBody
            );
          }
          setCaptchaInput('')
        });
    }
  }
  console.log('Gateways', gwList);
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <Form
        onSubmit={e => {
          e.preventDefault(); // evita atualização da página
          onSubmit();
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('projTable.deleteDevice')} {dvc.description}
        </ModalHeader>

        <ModalBody className="text-center mx-6">

          <div className="m-sm-4">
            <h6>{t('projTable.att')}!</h6>

            {(ppList.length)
              ? <>
                <p className="mb-0">
                  {`${t('projTable.newGatewayText')} ${dvc.description}:`}
                </p>
                {/*** CAMPO DE VINCULAR PERIFÉRICOS ***/}
                <Input
                  type="select"
                  name="newGatewayId"
                  id="newGatewayId"
                  value={newGatewayId}
                  onChange={e => {
                    setNewGatewayId(e.target.value);
                    setInvalidGW(false);
                  }}
                  invalid={invalidGW}
                  // disabled={!gwList.length}
                  disabled={!gwList.length || loading}
                >
                  <option value="" disabled>
                    {t('projTable.select')}
                  </option>
                  {gwList.map((item, i) => (
                    <option value={item.id} key={i}>
                      {item.description}
                    </option>
                  ))}
                </Input>


                <hr className="my-4" />
              </>
              : null
            }
            {(newGatewayId)
              ? <>
                <p className="mb-0">
                  {t('projTable.captchaLabel')}
                </p>
                {/*** CAMPO DE CAPTCHA ***/}
                <Input
                  type="text"
                  name="captcha"
                  id="captcha"
                  value={captchaInput}
                  onChange={e => {
                    setCaptchaInput(e.target.value);
                    setInvalidCaptcha(false);
                  }}
                  invalid={invalidCaptcha}
                  disabled={loading}
                />
              </>
              : (!ppList.length)
                ?
                <>
                  <p className="mb-0">
                    {t('projTable.captchaLabel')}
                  </p>
                  {/*** CAMPO DE CAPTCHA ***/}
                  <Input
                    type="text"
                    name="captcha"
                    id="captcha"
                    value={captchaInput}
                    onChange={e => {
                      setCaptchaInput(e.target.value);
                      setInvalidCaptcha(false);
                    }}
                    invalid={invalidCaptcha}
                    disabled={loading}
                  />
                </>
                : "Selecione o gateway"}

          </div>
        </ModalBody>

        <ModalFooter>
          {(loading)
            ? <Spinner color="primary" className="mr-3" />
            : <>
              <Button
                color='primary'
                onClick={toggle}
                outline
              >
                {t('projTable.cancel')}
              </Button>
              <Button
                color="primary"
                type="submit"
              >
                {t('projTable.deleteDevice')}
              </Button>
            </>
          }
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export { ModalDeleteProject, ModalDeleteDevice, ModalDeleteGateway };