import React, { useState } from "react";
import {
  Col,
  Button,
  Modal, ModalBody, ModalFooter, ModalHeader,
  Form, FormGroup, Input, Label,
} from "reactstrap";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';

const ModalNewDash = ({ open, toggle, tableReload }) => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [disableOnSubmit, setDisableOnSubmit] = useState(false);

  function onSubmitForm() {
    setDisableOnSubmit(true)
    /* Salvar dados do formulário no formato JSON */
    const jsonData = JSON.stringify({
      name: name,
      description: description,
    });
    /*** Envio de dados ***/
    axios.post('newDashboard/', jsonData)
      .then(result => {
        setName("");
        setDescription("");

        toastr.success(result.data.messageHead, result.data.messageBody);

        tableReload();
        toggle();
        setDisableOnSubmit(false)
      })
      .catch(err => {
        console.log(err);
        console.log(err.response);

        if (err.response.data) {
          /* Notificação de erro de envio */
          toastr.warning(
            err.response.data.messageHead, err.response.data.messageBody
          );
        }
      });

  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        {t('dashboards.newDash')}
      </ModalHeader>

      <Form
        onSubmit={e => {
          e.preventDefault();
          onSubmitForm();
        }}
      >
        <ModalBody className="text-center m-3">
          {/*** CAMPO DE NOME ***/}
          <FormGroup row>
            <Label sm={2} className="text-sm-right">
              {t('dashboards.name')}
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder={t('dashboards.name')}
                required
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Col>
          </FormGroup>

          {/*** CAMPO DE DESCRIÇÃO ***/}
          <FormGroup row>
            <Label sm={2} className="text-sm-right">
              {t('dashboards.description')}
            </Label>
            <Col sm={10}>
              <Input
                type="textarea"
                description="description"
                id="description"
                placeholder={t('dashboards.description')}
                rows="3"
                required
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Col>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button
            name="btnCancel"
            color='primary'
            className="mr-1 mb-1"
            onClick={toggle}
            outline
            type="button"
          >
            {t('dataTable.cancel')}
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={disableOnSubmit}
          >
            {t('dashboards.save')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
};

export default ModalNewDash;