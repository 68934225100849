import React from "react";
import { 
  CardBody, CardHeader, CardTitle, 
  Row, Col
} from "reactstrap";
import AmbientTemperature from './components/AmbientTemperature';
import Humidity from './components/Humidity';
import Velocity from './components/Velocity';
import WindDirection from "./components/WindDirection";
import Irradiation from "./components/Irradiation";
import ModuleTemperature from "./components/ModuleTemperature"
import { useTranslation } from 'react-i18next';

const SolarimetricaRealTime = ({ solarimeter }) => {

  const { t } = useTranslation();

  let cardHeight = (window.innerWidth > 700 ) ? "369px" : "277px";

  // var over = (window.innerWidth < 700) ? "scroll" : null

  return (
    <>
      {(solarimeter)
        ? <>
            <CardHeader>
              <CardTitle tag="h5">
                {t('solar.solarimetric')}
              </CardTitle>
            </CardHeader>

            <CardBody className="ml-2 pr-5" style={{ 
              width: "100%", 
              height: cardHeight 
            }}>
              <Row>
                <Col>
                  <AmbientTemperature airTemperature={solarimeter.airTemperature}/>
                </Col>
                <Col>
                  <Humidity humidity={solarimeter.humidity}/>
                </Col>
                <Col>
                  <Velocity windVelocity={solarimeter.windVelocity}/>
                </Col>
              </Row>
              <Row> 
                <Col>
                  <WindDirection windAngle={solarimeter.windDirection}/>
                </Col>
                <Col>
                  <Irradiation sunIrradiation={solarimeter.sunIrradiation}/>
                </Col>
                <Col>
                  <ModuleTemperature moduleTemperature={solarimeter.moduleTemperature}/>
                </Col>
              </Row>
            </CardBody>
          </>
        : <CardBody className="text-center py-6">
            {t('solar.noSolarDevices')}.
          </CardBody>
      }
    </>
  );
};

export default SolarimetricaRealTime;