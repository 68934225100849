import React, { useEffect, useState } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';
import { 
  CardHeader, CardBody, CardFooter,
  CardTitle, CardSubtitle,
  DropdownToggle, DropdownMenu, DropdownItem,
  Row, Col,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip
} from "reactstrap";
import * as Icon from "react-feather";
import { MoreVertical } from "react-feather";
import { Link, useHistory } from "react-router-dom";

import ModalDeleteCard from "../ModalDeleteCard";

const CardDropdown = (props, { cardId, name, description, tableReload, preview, setDataLoading}) => {
  const { t } = useTranslation();
  
  const [openDeleteCard, setOpenDeleteCard] = useState(false);
  const [editCard, setEditCard] = useState(false)

  function toggleDeleteCard() {
    setOpenDeleteCard(!openDeleteCard);
  };
  
  return (
    <UncontrolledDropdown className="d-inline-block" disabled={preview}>
      <DropdownToggle tag="a">
        <MoreVertical />
      </DropdownToggle>

      <DropdownMenu right>
        <DropdownItem onClick={() => toggleDeleteCard()}>
          {t('dashboards.delete')}
        </DropdownItem>
        {(openDeleteCard)
          ? <ModalDeleteCard
            open={openDeleteCard}
            toggle={toggleDeleteCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
          />
          : null
        }
        <Link
          onClick={() => setEditCard(props.cardId)}
          to={{
            pathname: `/newCard/customCard/${props.cardId}`,
            state: {
              cardProps: props.card,
              editCard: editCard,
            }
          }}>
          <DropdownItem>
            {t('dashboards.edit')}
          </DropdownItem>
        </Link>
        <DropdownItem onClick={() => setDataLoading(true)}>
          {t('dashboards.refresh')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
};

const CustomState = ({
  cardId,
  card,
  name,
  description,
  deviceId,
  variables,
  reloadTime,
  rangeStructure,
  tableReload,
  decimalNumber,
  preview,
  dimensions
}) => {
  const { t } = useTranslation();

  const [dataLoading, setDataLoading] = useState(!preview);
  const [varValue, setVarValue] = useState();
  const [device, setDevice] = useState({});
  const [lastComm, setLastComm] = useState();
  const [commInterval, setCommInterval] = useState();
  const [cardHeight, setCardHeight] = useState();
  const [state, setState] = useState();
  
  const lastCommDate = new Date(lastComm * 1000);

  let history = useHistory();
  
  useEffect(() => {
    if (dimensions) {
      if (dimensions?.id === card?.id) {

        let var1 =
          ((dimensions.size.height < 240 && dimensions.size.width < 240) && (card.id === dimensions.id)) ? 240
            : ((dimensions.size.height > 241 && dimensions.size.width > 310) && (card.id === dimensions.id) && (dimensions.size.height <= 310)) ? 310
              : ((dimensions.size.height > 311 && dimensions.size.width > 370) && (card.id === dimensions.id) && (dimensions.size.height < 370)) ? 370
                : ((dimensions.size.height > 371 && dimensions.size.width > 420) && (card.id === dimensions.id) && (dimensions.size.height < 420)) ? 420
                  : ((dimensions.size.height > 421 && dimensions.size.width > 500) && (card.id === dimensions.id) && (dimensions.size.height < 500)) ? 500
                    : ((dimensions.size.height > 501 && dimensions.size.width > 501) && (card.id === dimensions.id)) ? 501
                      : 240
        
        setCardHeight(var1)
      }
    }
    // eslint-disable-next-line
  }, [dimensions])

  /** Envia o id do device e retorna seu nome, lastComm e commInterval  */
  useEffect(() => {  
    if (deviceId) {
      axios.get('/readDeviceInfo', {params: {
        idDevice: deviceId
      }})
      .then(response => {
        // console.log('useEffect readDeviceInfo', response.data);
        setDevice(response.data);
        setLastComm(response.data.lastComm);
        setCommInterval(response.data.commInterval);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!',"Erro ao buscar Modelo.");
      });
    }      
  }, [deviceId]);

  let varName = (variables) ? JSON.parse(variables)?.varName : "";
  let varTag = (variables) ? JSON.parse(variables).tag : "";
  let unit = (variables) ? JSON.parse(variables).unit : "";

  function readValue() {
    axios.get('/readDataLast', {params: {
      idDevice: deviceId
    }})
    .then(response => {
      setVarValue(response.data[varTag]);
      setLastComm(response.data.lastComm);
      if (response.data.lastComm) {
        setLastComm(response.data.lastComm);
      }

      let stateAux = rangeStructure.states[0];
      rangeStructure.thresholds.forEach((thr, i) => {
        if (thr.includes) {
          if (response.data[varTag] >= thr.value) {
            // console.log(response.data[varTag], rangeStructure.states[i].message);
            // setState(rangeStructure.states[i]);
            stateAux = rangeStructure.states[i+1];
          }
  
          else {
            // console.log(response.data[varTag], rangeStructure.states[i+1].message);
            // setState(rangeStructure.states[i+1]);
            return
          }
        }

        else {
          if (response.data[varTag] > thr.value) {
            // console.log(response.data[varTag], rangeStructure.states[i].message);
            // setState(rangeStructure.states[i]);
            stateAux = rangeStructure.states[i+1];
          }

          else {
            // console.log(response.data[varTag], rangeStructure.states[i+1].message);
            // setState(rangeStructure.states[i+1]);
            return
          }
        }
      });
      setState(stateAux);

      setDataLoading(false);
    })
    .catch(err => {
      console.log(err);
      // toastr.warning('Erro!',"Erro ao buscar dados.");
      setDataLoading(false);
    });
  }

  /** Envia o id do device e retorna os valores */
  useEffect(() => {
    if (dataLoading || varTag) {
      readValue();
    };

    if (history.location.pathname === "/dashboardView" && !dataLoading) {
      const intervalSearch = setInterval(()=> {
        readValue();

        if (history.location.pathname !== "/dashboardView") {
          clearInterval(intervalSearch);
        };
      }, reloadTime);
    };
    // eslint-disable-next-line
  }, [dataLoading, deviceId, history, reloadTime, varTag]);

  return (
      <div id="parent" style={{height: "-webkit-fill-available"}}>
        <CardHeader className="mb-0 pb-0">
          <div className="card-actions float-right" style={{paddingRight: "3px"}}>
            <Row className="card-actions float-right">
              <Col className="pl-0" style={{paddingRight: "3px"}}>
                <div className="box no-cursor cursor-pointer mp-0 otavio">
                  <Icon.Move style={{width:"15px"}}/>
                </div>
              </Col>

              <CardDropdown
                card={card}
                cardId={cardId}
                name={name}
                description={description}
                tableReload={tableReload} 
                preview={(preview)}
                setDataLoading={setDataLoading}
              />
            </Row>
          </div>

          <div id={"name" + cardId} >
            <CardTitle style={{ whiteSpace: "nowrap" }} tag="h5" className="mr-3 overflow-hidden text-left my-1 h5">{name}</CardTitle>
            {(name) ?
              <UncontrolledTooltip
                placement="top"
                target={"name" + cardId}
              >
                {name}
              </UncontrolledTooltip>
              : null}
            <CardSubtitle style={{ whiteSpace: "nowrap" }} className="overflow-hidden text-muted text-left">
              {description}
            </CardSubtitle>
            {(description) ?
              <UncontrolledTooltip
                placement="bottom"
                target={"name" + cardId}
              >
                {description}
              </UncontrolledTooltip>
              : null}
          </div>
        </CardHeader>

        <CardBody className="d-flex align-items-center justify-content-center" style={{ padding: 0, margin: 0, height: "70px"}}>
          {(dataLoading)
            ? <div className="text-center">
                <Spinner color="primary" />
              </div>
            : ((varValue || varValue === 0 || varValue === false) && state)
            ? (lastComm >= Math.floor(Date.now() / 1000) - (commInterval * 2))
                ? <div
                style={{ marginTop: (cardHeight < 300) ? cardHeight / 4 : (cardHeight >= 300) ? cardHeight / 2 : "0", whiteSpace: "nowrap", height: "100%", width: "100%" }}
                  className="overflow-hidden d-flex align-items-center justify-content-center mp-0"
                >
                    {(state.image)
                      ? <img 
                          alt={name}
                          src={state.image}
                          width="25%" // height="100%"
                        />
                      : null
                    }
                    <h2 className="display-3 w-responsive text-center ml-1" 
                      style={{
                        // fontSize: fontSize*0.8,
                        fontSize:`${cardHeight / 8}px`,
                        transform: "translate (0, -50%)",
                        color: (state.color) ? state.color : "inherit"
                      }}
                    > 
                    {(state.message) ? state.message : (typeof(varValue)) === "number" ? `${varValue?.toFixed(Number(decimalNumber))} ${unit}`
                      : (state.message) ? state.message : (varValue === false) ? `Desligado`
                        : varValue === true ? "Ligado"
                          : null
                    }
                    </h2>
                  </div>
                : <p className="mt-1">{t('dashboards.offlineStats')}</p>
              : <p className="mt-1">{t('dashboards.noDataStats')}</p>
          }
        </CardBody>
        {(cardHeight > 300) ?
          <CardFooter className="text-left mt-0 pt-0 pb-1" style={{
            bottom: "0",
            position: `${(!preview) ? "absolute" : null}`,
            background: "transparent"
          }}>
            <h6 className="text-left">{device.description}</h6>
            <h6 className="card-subtitle text-muted">{varName}</h6>
            <h6 className="card-subtitle text-muted mt-1">
              {(lastComm)
                ? lastCommDate.getDate().toString().padStart(2, '0') + '/' +
                (lastCommDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
                lastCommDate.getFullYear().toString().padStart(2, '0') + ' ' +
                lastCommDate.getHours().toString().padStart(2, '0') + ':' +
                lastCommDate.getMinutes().toString().padStart(2, '0') + ':' +
                lastCommDate.getSeconds().toString().padStart(2, '0')
                : t('dashboards.noComm')
              }
            </h6>
          </CardFooter>
          : null}
      </div>
  );
};

export default CustomState;
