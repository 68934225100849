import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Maximize2 } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  Button,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatTimestamp } from "../../../../../../../src/utils/functions.js";
import {
  DateRangeSelector,
  ENUM_ENTRY_RANGE,
} from "../../../../../../components/DateRangeSelector.js";
import { COLOR_CHARTS } from "../../../../../../utils/Constants.js";
import { usePlantContext } from "../../PlantProvider.js";
import { useRefMeterContext } from "../RefMeterProvider";
import { readBarChartConsumption } from "../RefMeterService";
const moment = require("moment");

export const BarChartConsumption = ({
  windowObervable,
  isModal = false,
  viewChart,
}) => {
  const { t } = useTranslation();

  const { dvcId } = useRefMeterContext();
  const { selectedDate } = usePlantContext();

  const [loadingChart, setLoadingChart] = useState(false);
  const [chart, setChart] = useState(null);
  const [start, setStart] = useState(
    moment(selectedDate).startOf("month").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState(
    moment(selectedDate).endOf("month").format("YYYY-MM-DD")
  );
  const [range, setRange] = useState("fullMonth");
  const [toggleModal, setToggleModal] = useState(false);
  const isFirstRender = useRef(true);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  useEffect(() => {
    if (dvcId) getChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dvcId, start, end]);

  useEffect(() => {
    if (selectedDate) {
      setStart(moment(selectedDate).startOf("month").format("YYYY-MM-DD"));
      setEnd(moment(selectedDate).endOf("month").format("YYYY-MM-DD"));
      setRange("fullMonth");
    }
  }, [selectedDate]);

  const getChartData = useCallback(async () => {
    setLoadingChart(true);
    const chart = await readBarChartConsumption(
      dvcId,
      isFirstRender.current
        ? moment(start).startOf("day").toISOString()
        : moment(start).toISOString(),
      isFirstRender.current
        ? moment(end).endOf("day").toISOString()
        : moment(end).toISOString(),
      ENUM_ENTRY_RANGE[range]
    );
    setChart(chart);
    setLoadingChart(false);
  }, [dvcId, start, end, range]);

  const dates = useMemo(
    () =>
      chart?.totalKwConsumptionTimestamp
        ? chart.totalKwConsumptionTimestamp
        : [],
    [chart]
  );
  const peakValues = useMemo(
    () => (chart?.totalPeakConsumption ? chart.totalPeakConsumption : []),
    [chart]
  );
  const offPeakValues = useMemo(
    () => (chart?.totalOffPeakConsumption ? chart.totalOffPeakConsumption : []),
    [chart]
  );

  const data = useMemo(() => {
    if (!dates || !offPeakValues || !peakValues) return []; // Protege contra dados indefinidos

    return dates.map((date, index) => ({
      date: formatTimestamp(date, ENUM_ENTRY_RANGE[range]),
      offPeak: offPeakValues[index] || 0, // Adiciona valor padrão se indefinido
      peak: peakValues[index] || 0, // Adiciona valor padrão se indefinido
    }));
  }, [dates, offPeakValues, peakValues, range]);

  const handleBarClick = (data) => {
    const clickedData = data; // Captura os dados da barra clicada

    if (ENUM_ENTRY_RANGE[range] === "days") {
      const clickedTimestamp = clickedData.date;
      const date = clickedTimestamp.split(" - ")[0];
      const [day, month] = date.split("/");
      const startDay = moment(`${day}/${month}`, "DD/MM")
        .startOf("day")
        .toDate();
      const endDay = moment(`${day}/${month}`, "DD/MM").endOf("day").toDate();

      setRange("24Hours");
      setStart(startDay);
      setEnd(endDay);
    }
    if (ENUM_ENTRY_RANGE[range] === "months") {
      const startMonth = moment(clickedData.date, "MM/YYYY")
        .startOf("month")
        .toDate();
      const endMonth = moment(clickedData.date, "MM/YYYY")
        .endOf("month")
        .toDate();

      setRange("fullMonth");
      setStart(startMonth);
      setEnd(endMonth);
    }
  };

  const renderCustomBar = (props) => {
    const { fill, x, y, width, height, value } = props;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={fill}
          onClick={() => handleBarClick(props, value)}
          style={
            ENUM_ENTRY_RANGE[range] !== "minutes"
              ? { cursor: "pointer" }
              : { cursor: "default" }
          }
        />
      </g>
    );
  };

  return (
    //NOTE: Só exibe se existir a estrutura de energia do driver ou estrutura de energia personalizada
    viewChart || chart?.viewChart ? (
      <>
        <CardHeader className="d-flex align-content-center justify-content-between">
          <CardTitle tag="h5" style={{ marginBottom: "0px" }}>
            {t("charts.chartConsumption")}
          </CardTitle>
          {!isModal && (
            <Button
              className="bg-transparent border-0"
              id={"button-maximize-dashboard"}
              onClick={handleToggleModal}
            >
              <Maximize2 size={16} color="grey" />
              <UncontrolledTooltip target={"button-maximize-dashboard"}>
                {t("public.expand")}
              </UncontrolledTooltip>
            </Button>
          )}
        </CardHeader>
        <DateRangeSelector
          loading={loadingChart}
          initDate={start}
          finishDate={end}
          setEntrieStartDate={setStart}
          setEntrieEndDate={setEnd}
          entrieRange={range}
          setEntrieRange={setRange}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              flex: 1,
              height: "100%",
            }}
          >
            <ResponsiveContainer width="100%" height={278}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis tickFormatter={(value) => value.toLocaleString()} />
                <Tooltip
                  formatter={(value) => value.toLocaleString()}
                  labelFormatter={(label) => `Data: ${label}`}
                />
                <Legend />
                <Bar
                  dataKey="offPeak"
                  fill={COLOR_CHARTS.offPeak}
                  name={t("charts.offPeak")}
                  stackId="a"
                  shape={renderCustomBar}
                />
                <Bar
                  dataKey="peak"
                  fill={COLOR_CHARTS.peak}
                  name={t("charts.peak")}
                  stackId="a"
                  shape={renderCustomBar}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </DateRangeSelector>
        {toggleModal && (
          <Modal
            isOpen={toggleModal}
            toggle={handleToggleModal}
            size={windowObervable < 1400 ? "lg" : "xl"}
          >
            <ModalBody>
              <BarChartConsumption isModal={true} />
            </ModalBody>
          </Modal>
        )}
      </>
    ) : null
  );
};
