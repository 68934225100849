import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Search } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faSort
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

/****** MODAL DE ADICIONAR E REMOVER USUÁRIOS A PROJETO ******/
const DoughnutModal = ({open, toggle, index, devices}) => {
  const { t } = useTranslation();

  const [searchField, setSearchField] = useState("");
  const [filteredDevices, setFilteredDevices] = useState(devices);

  useEffect(() => {
    if (devices) {
      setFilteredDevices(devices.filter(dvc => {
        return (
          dvc.description.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .toLowerCase().includes(searchField)
          || dvc.projName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .toLowerCase().includes(searchField)
        )
      }));
    }    
  }, [searchField, devices]);

  /*** configurações das colunas da tabela ***/
  const cols = [
    { // ID (oculto)
      dataField: "id",
      text: "ID",
      sort: true,
      hidden: true,
    },
    { // nome de referência
      dataField: "description",
      text: t('home.description'),
      sort: true,
    },
    { // projeto
      dataField: "projName",
      text: t('home.project'),
      sort: true,
    },
    { // última publicação
      dataField: "lastComm",
      text: t('home.lastComm'),
      sort: true,
      formatter: cell => {
        if (cell) {
          const cellDate = new Date(cell*1000);

          return (
            cellDate.getDate().toString().padStart(2, '0') + '/' +
            (cellDate.getMonth()+1).toString().padStart(2, '0') + '/' +
            cellDate.getFullYear().toString().padStart(2, '0') + ' ' +
            cellDate.getHours().toString().padStart(2, '0') + ':' +
            cellDate.getMinutes().toString().padStart(2, '0') + ':' +
            cellDate.getSeconds().toString().padStart(2, '0')
          )
        } else return '-'
      }
    }
  ];

  /* Ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) => (!order) 
      ? <FontAwesomeIcon
        className={'fa-xs ml-1'}
        icon={faSort}
      />
      : (order === 'asc')
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faCaretUp}
        />
        : <FontAwesomeIcon
            className={'fa-xs ml-1'}
            icon={faCaretDown}
          />
  }

  return (
    <Modal 
      isOpen={open}
      toggle={toggle}
    >      
      <ModalHeader toggle={toggle}>
        {(index === 0)
          ? t('home.onlineTitle')
          : (index === 1)
            ? t('home.offlineTitle')
            : t('home.noCommTitle')
        }
      </ModalHeader>

      <ModalBody className="mx-4 px-0" style={{overflowY: "scroll", maxHeight: "400px", overflow: "auto"}}>
        <div className="d-flex align-items-center justify-content-start mb-1">
          <Search width={16} height={16} />
          <Input
            type="text"
            className="ml-1"
            placeholder={t('home.search')}
            aria-label="Search"
            // className="form-control-lite"
            value={searchField}
            onChange={e=>setSearchField(e.target.value
              .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
            )}
          />
        </div>

        <BootstrapTable
          bootstrap4
          keyField="id"
          data={filteredDevices}
          columns={cols}
          condensed
          bordered={false}
          striped={true}
          sort={sortIcon}
        />
      </ModalBody>

      <ModalFooter>
        {/*** BOTÃO DE FECHAR O MODAL ***/}
        <Button color="primary" onClick={toggle}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DoughnutModal;