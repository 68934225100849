import axios from "axios";
import { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  CardBody,
  Spinner,
  useAccordionButton,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CardProject } from "./components/CardProjects";
import { CustomToggle } from "./components/CustomToggle";
import { CardHeader, CardTitle } from "reactstrap";
import { useQuery } from "@tanstack/react-query";

export const Projects = ({ companyId = localStorage.getItem("companyId") }) => {
  const refetchInterval = 1000 * 60 * 15;
  const currentTheme = useSelector((store) => store.theme.currentTheme);
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState("0");

  const {
    status,
    data: reactQuery,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["listProjectsInfo"],
    queryFn: async () => {
      const res = await axios.get(
        `v2/company/listProjectsInfo?companyId=${companyId}`
      );
      return res.data;
    },
    refetchInterval: refetchInterval,
  });

  useEffect(() => {
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[companyId])

  if (status === "error") {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{t("noc.error.errorOccurred")}</CardTitle>
        </CardHeader>
        <CardBody>
          <Button onClick={refetch}> {t("noc.error.tryAgain")}</Button>
        </CardBody>
      </Card>
    );
  }

  return (
    <Accordion
      activeKey={activeKey}
      onSelect={(key) => setActiveKey(key)}
      style={{
        width: "100%",
        maxHeight: "calc(100vh - 400px)",
        overflow: "hidden",
      }}
    >
      <Card>
        <CustomToggle eventKey="0" useAccordionButton={useAccordionButton}>
          {t("noc.main.accordion.healthyPlants")} -{" "}
          {status === "loading" || isFetching ? (
            <Spinner style={{
              background: "#fff",
              width: "12px",
              height: "12px",
              padding: "10px",
              borderRadius: "50%",
              opacity: ".3",
            }} />
          ) : (
            reactQuery.onlines.length
          )}
        </CustomToggle>
        <Accordion.Collapse eventKey="0">
          <CardBody
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              maxHeight: "calc(100vh - 600px)",
              minHeight: "350px",
              overflow: "auto",
              gap: "4px",
            }}
          >
            {reactQuery &&
              reactQuery.onlines.map((project, index) => (
                <CardProject
                  key={index}
                  item={project}
                  companyId={companyId}
                  accordion={0}
                  color={currentTheme.success}
                />
              ))}
          </CardBody>
        </Accordion.Collapse>
      </Card>
      <Card>
        <CustomToggle eventKey="1" useAccordionButton={useAccordionButton}>
          {t("noc.main.accordion.unhealthyPlants")} -{" "}
          {status === "loading" || isFetching ? (
            <Spinner style={{
              background: "#fff",
              width: "12px",
              height: "12px",
              padding: "10px",
              borderRadius: "50%",
              opacity: ".3",
            }} />
          ) : (
            reactQuery.offlines.length
          )}
        </CustomToggle>
        <Accordion.Collapse eventKey="1">
          <CardBody
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              maxHeight: "calc(100vh - 600px)",
              minHeight: "350px",
              overflow: "auto",
              gap: "4px",
            }}
          >
            {reactQuery &&
              reactQuery.offlines.map((project, index) => (
                <CardProject
                  key={index}
                  item={project}
                  companyId={companyId}
                  accordion={1}
                  color={currentTheme.warning}
                />
              ))}
          </CardBody>
        </Accordion.Collapse>
      </Card>
      <Card>
        <CustomToggle eventKey="2" useAccordionButton={useAccordionButton}>
          {t("noc.main.accordion.neverCommunicated")} -{" "}
          {status === "loading" || isFetching ? (
            <Spinner style={{
              background: "#fff",
              width: "12px",
              height: "12px",
              padding: "10px",
              borderRadius: "50%",
              opacity: ".3",
            }} />
          ) : (
            reactQuery.neverComm.length
          )}
        </CustomToggle>
        <Accordion.Collapse eventKey="2">
          <CardBody
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              maxHeight: "calc(100vh - 600px)",
              minHeight: "350px",
              overflow: "auto",
              gap: "4px",
            }}
          >
            {reactQuery &&
              reactQuery.neverComm.map((project, index) => (
                <CardProject
                  key={index}
                  item={project}
                  accordion={2}
                  color={currentTheme.disabled}
                />
              ))}
          </CardBody>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
