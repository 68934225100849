import React, { useState, useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';


const Chart = ({ data, setLoading,loading}) => {
    
    useEffect(() => { 
      setLoading(true);
      let root = am5.Root.new("chartdivbattery");
  
      root.setThemes([
        am5themes_Animated.new(root)
      ]);
  
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          wheelY: "zoomX",
          layout: root.verticalLayout,
          maxTooltipDistance: 0
        })
      );
  
  
      // Create Y-axis
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {})
        })
      );
      let yAxis2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true
          })
        })
      );
  
      // Create X-Axis
      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          // renderer: am5xy.AxisRendererX.new(root, {}),
          // categoryField: "timestamp"
  
  
          maxDeviation: 0.5,
          groupData: false,
          baseInterval: {
            timeUnit: "minute",
            count: 1
          },
  
          renderer: am5xy.AxisRendererX.new(root, {}),
          tooltip: am5.Tooltip.new(root, {}),
          // visible: (index === 0)? true : false
        })
      );
      xAxis.data.setAll(data);
  
      // Create series
      let series1 = chart.series.push(
        am5xy.LineSeries.new(root, {
          connect: false,
          name: "Corrente",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "BatteryCurrent",
          valueXField: "millitimestamp",
          // categoryXField: "timestamp",
          tooltip: am5.Tooltip.new(root, {
            labelText: "[bold]{name}: {BatteryCurrent}"
          })
        })
      );
      series1.data.setAll(data);
  
      let series2 = chart.series.push(
        am5xy.LineSeries.new(root, {
          connect: false,
          name: "Status",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "BatteryStatus",
          valueXField: "millitimestamp",
          // categoryXField: "timestamp",
          tooltip: am5.Tooltip.new(root, {
            labelText: "[bold]{name}: {BatteryStatus}"
          })
        })
      );
  
      series2.data.setAll(data);

      let series4 = chart.series.push(
        am5xy.LineSeries.new(root, {
          connect: false,
          name: "Temperature",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "BatteryTemperature",
          valueXField: "timestamp",
          tooltip: am5.Tooltip.new(root, {
            labelText: "[bold]{name}: {BatteryTemperature}"
          })
        })
      );
  
      series4.data.setAll(data);
  
      let series5 = chart.series.push(
        am5xy.LineSeries.new(root, {
          connect: false,
          name: "Tensão",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "BatteryVoltage",
          valueXField: "timestamp",
          tooltip: am5.Tooltip.new(root, {
            labelText: "[bold]{name}: {BatteryVoltage}"
          })
        })
      );
  
      series5.data.setAll(data);
  
      let series6 = chart.series.push(
        am5xy.LineSeries.new(root, {
          connect: false,
          name: "Carga restante",
          xAxis: xAxis,
          yAxis: yAxis2,
          valueYField: "EstimatedChargeRemaining",
          valueXField: "timestamp",
          tooltip: am5.Tooltip.new(root, {
            labelText: "[bold]{name}: {EstimatedChargeRemaining}"
          })
        })
      );
  
      series6.data.setAll(data);
  
      let series7 = chart.series.push(
        am5xy.LineSeries.new(root, {
          connect: false,
          name: "Tempo restante",
          xAxis: xAxis,
          yAxis: yAxis2,
          valueYField: "EstimatedMinutesRemaining",
          valueXField: "timestamp",
          tooltip: am5.Tooltip.new(root, {
            labelText: "[bold]{name}: {EstimatedMinutesRemaining}"
          })
        })
      );
  
      series7.data.setAll(data);

      let series8 = chart.series.push(
        am5xy.LineSeries.new(root, {
          connect: false,
          name: "Tempo em bateria",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "SecondsOnBattery",
          valueXField: "timestamp",
          tooltip: am5.Tooltip.new(root, {
            labelText: "[bold]{name}: {SecondsOnBattery}"
          })
        })
      );
  
      series8.data.setAll(data);
  
      // Add legend
      let legend = chart.children.push(am5.Legend.new(root, {}));
      legend.data.setAll(chart.series.values);
  
      // Add cursor
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "zoomX"
      }));
      cursor.lineY.set("visible", false);
      chart.appear();
  
      setLoading(false);
  
      root._logo.dispose();
  
  
  
  
      // eslint-disable-next-line
    }, [loading])
  
  
    return <div id="chartdivbattery" style={{ width: "95%", height: "44vh", padding: "1vw" }}></div>
  }

  const BatteryChart = ({ graphData, selectedDevice }) => {
    // eslint-disable-next-line 
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();
    // Define data
    let data = graphData.find(item => item[0].deviceId === selectedDevice)[0].batteryStatus;
  
    return (data.length) ?
      (loading) ?
        <div className="text-center">
          <Spinner color="primary" />
        </div>
        : <Chart data={data} setLoading={setLoading} loading={loading}/>
      : <p style={{ fontSize: "2em", textAlign: "center",marginTop:"2em" }}>{t('dashboards.noDataStats')}</p>
  
  }

  export default BatteryChart
  