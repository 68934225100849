import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table
} from "reactstrap";
import { BarChartDemandReactive } from "./components/BarChartDemandReactive";
import { fetchGetWitchParams } from "../../../../../../../NocService";

export const PowerModule = ({ item }) => {
  const { t } = useTranslation();
  const url = `v2/energy/readRefMeter`;
  const params = {
    projId: item.projectId,
    monthRef: new Date(),
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();

    fetchGetWitchParams(source, setData, setError, setLoading, url, params);

    return () => {
      source.cancel();
    };
  }, []);

  if (loading) {
    return <Skeleton style={{ width: "100%", height: "100px" }} />;
  }

  const handleReload = () => {
    setLoading(true);
    setError(null);
    const source = axios.CancelToken.source();
    fetchGetWitchParams(source, setData, setError, setLoading, url, params);
  };

  if (error || data.length === 0) {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{t("noc.error.errorOccurred")}</CardTitle>
        </CardHeader>
        <CardBody>
          <Button onClick={handleReload}> {t("noc.error.tryAgain")}</Button>
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <Table striped responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>{t("cards.monthlyConsumption")}</th>
            <th>{t("cards.monthlyConsumptionRe")}</th>
            <th>{t("cards.maxDemand")}</th>
            <th>{t("cards.powerFactor")}</th>
            <th>{t("cards.chargeFactor")}</th>
            <th>{t("cards.monthlyBill")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              {t("cards.peak")}/{t("cards.avgInductive")}
            </th>
            <td>{`${Math.floor(
              data?.totalPeakConsumption ?? 0
            ).toLocaleString()} kWh`}</td>
            <td>{`${Math.floor(
              data?.totalPeakConsumptionRe ?? 0
            ).toLocaleString()} kVAr`}</td>
            <td>{`${data?.maxPeakDemand?.toLocaleString("pt-br", {
              currency: "BRL",
            })} kW`}</td>
            <td>{`${data?.avgPowerFactorInductive
              ?.toFixed(2)
              ?.toLocaleString("pt-BR", { currency: "BRL" })}`}</td>
            <td>{`${
              data ? (data?.chargeFactorPeak * 100)?.toFixed(2) : ""
            } %`}</td>
            <td>{`R$ ${data?.invoice?.toLocaleString("pt-BR", {
              currency: "BRL",
            })}`}</td>
          </tr>
          <tr>
            <th>
              {t("cards.offPeak")}/{t("cards.avgCapacitive")}
            </th>
            <td>{`${Math.floor(
              data?.totalOffPeakConsumption ?? 0
            ).toLocaleString()} kWh`}</td>
            <td>{`${Math.floor(
              data?.totalOffPeakConsumptionRe ?? 0
            ).toLocaleString()} kVAr`}</td>
            <td>{`${data?.maxOffPeakDemand?.toLocaleString("pt-br", {
              currency: "BRL",
            })} kW`}</td>
            <td>{`${data?.avgPowerFactorCapacitive
              ?.toFixed(2)
              ?.toLocaleString("pt-BR", { currency: "BRL" })}`}</td>
            <td>{`${
              data ? (data?.chargeFactorOffPeak * 100)?.toFixed(2) : ""
            } %`}</td>
          </tr>
        </tbody>
      </Table>

      <Card>
        <BarChartDemandReactive dvcId={data?.refMeter} />
      </Card>
    </>
  );
};
