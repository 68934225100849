import { faSort, faSortDown, faSortUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";

import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
  FormGroup,
} from "reactstrap";

const ModalAddFormula =
  ({
    open,
    toggle,
    variable,
    setVariable,
    varList,
    isLoading,
    handAddNewVariable,
    labelUnit,
    setLabelUnit,
    variableListIfExists,
    setVariableListIfExists,
    mathFormulaInput,
    setMathFormulaInput,
    handleAddFormula,
    deviceId,
    onDeviceChange,
    dvcList,
  }) => {
    const { t } = useTranslation();

    function handleDeleteVariable(row) {
      setVariableListIfExists(variableListIfExists.filter(item => item.tag !== row.tag))
    }

    const variableCols = [
      { // ID (oculto)
        dataField: "tag",
        text: t('projTable.id'),
        sort: true,
        hidden: true,
        isKey: true
      },
      { // nome do dispositivo
        dataField: "deviceName",
        text: "Dispositivo",
        sort: true,
        style: { width: 180 }
      },
      { // nome da variável
        dataField: "varName",
        text: "Variável",
        sort: true
      },
      { // botões de ação
        dataField: "actions",
        text: t('projTable.actions'),
        editable: false,
        headerStyle: { width: "11%" },
        formatter: (_, row) => (
          <div className="d-flex justify-content-left">
            <div // remover 
              id={"removeGain" + row.id}
              className="align-middle cursor-pointer"
              onClick={() => handleDeleteVariable(row)}
            >
              <FontAwesomeIcon icon={faTrash} fixedWidth />
              {/* Tooltip */}
              <UncontrolledTooltip
                placement="top"
                target={"removeGain" + row.id}
              >
                {t('projTable.delete')}
              </UncontrolledTooltip>
            </div>
          </div>
        )
      }
    ];

    const sortIcon = {
      sortCaret: (order) => (!order)
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faSort}
        />
        : (order === 'asc')
          ? <FontAwesomeIcon
            className={'fa-xs ml-1'}
            icon={faSortUp}
          />
          : <FontAwesomeIcon
            className={'fa-xs ml-1'}
            icon={faSortDown}
          />
    };

    return (
      <Modal
        isOpen={open}
        toggle={toggle}
        scrollable
      >
        <ModalHeader>
          {t("Adicionar Fórmula")}
        </ModalHeader>
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            paddingInline: "1rem",
            marginInline: 16
          }}>

          {/* Seleção de dispositivo */}
          <FormGroup row>
            <Label lg={9} xl={3}>
              {t('dashboards.devices')}
            </Label>
            <Col lg={9} xl={6}>
              <Input
                type="select"
                id="device"
                required
                value={deviceId}
                onChange={e => onDeviceChange(e.target.value)}
                disabled={!dvcList.length || isLoading}
              >
                <option hidden value="">{t('dashboards.pleaseDevice')}</option>
                {dvcList.map(item => (
                  <option value={item.id + '.' + item.description} key={item.id}>
                    {item.description}
                  </option>
                ))}
              </Input>
            </Col>
          </FormGroup>

          {/* Seleção de variável */}
          <FormGroup row>
            <Label lg={12} xl={3}>
              {t('dashboards.variables')}
            </Label>
            <Col sm={9} lg={12} xl={6}>
              <Input
                type="select"
                id="variablesSelect"
                required
                value={variable}
                onChange={e => setVariable(e.target.value)}
              // disabled={!varList.length || isLoading}
              >
                <option hidden value="">{t('dashboards.pleaseVariable')}</option>
                {varList?.map(item => (
                  <option value={JSON.stringify(item)} key={item.tag}>
                    {item.varName}
                  </option>
                ))}
              </Input>
            </Col>
            <Col>
              <Button onClick={() => handAddNewVariable(variable)} color="primary" type="button">Adicionar</Button>
            </Col>
          </FormGroup>

          <hr style={{ color: "#000" }} />

          <BootstrapTable
            bootstrap4
            keyField="tag"
            data={variableListIfExists}
            columns={variableCols}
            condensed
            bordered={false}
            striped={true}
            sort={sortIcon}
          // cellEdit={cellEdit}
          />
          
          <Row className="d-flex justify-content-center mb-2">
            {/* <Row style={{ posistion: "abolute", top: -10 }}> */}
            <Label style={{ maxWidth: 420, textAlign: "justify" }}>
              Para utilizar a fórmula, digite o nome do dispositivo separado
              por um ponto do nome da variável e a operação dejesada entre
              chaves. {"{{Dispositivo.Tensão}} / {{Dispositivo.Resistência}}"}.
              Os operadores matemáticos são representados por +, -, *, / e
              podem ser utilizados desta maneira: {"{{(Dispositivo.Tensão + 2.7)}} * {{Dispositivo.Resistência}}"}
            </Label>
          </Row>

          {/* Input da fórmula */}
          <FormGroup row className="align-items-center">
            <Label sm={4} lg={4} xl={3}>
              Fórmula
              {/* <FontAwesomeIcon icon="fa-solid fa-square-question" /> */}
            </Label>
            <Col xm={12} sm={4} lg={4} xl={7}>
              <Input
                type="textarea"
                value={mathFormulaInput}
                onChange={(e) => setMathFormulaInput(e.target.value)}
              />
            </Col>
            {/* <Col xm={12} sm={4} lg={4} xl={2}>
              <div id="howTo">
                <UncontrolledTooltip placement="auto" style={{ padding: "1rem", minInlineSize: 300 }} target={"howTo"}>
                  <Label style={{  }}>
                    Para utilizar a fórmula, digite o nome do dispositivo separado
                    por um ponto do nome da variável e a operação dejesada entre
                    chaves. {"{{Dispositivo.Tensão}} / {{Dispositivo.Resistência}}"}.
                    Os operadores matemáticos são representados por +, -, *, / e
                    podem ser utilizados desta maneira: {"{{(Dispositivo.Tensão + 2.7)}} * {{Dispositivo.Resistência}}"}
                  </Label>
                </UncontrolledTooltip>
                <Label>
                  <FontAwesomeIcon icon={faQuestion} />
                </Label>
              </div>
            </Col> */}
            {/* <Col xm={3} sm={3} lg={6} xl={3}>
              <Button style={{ width: 83 }} color="primary" onClick={handleAddFormula}>Calcular</Button>
            </Col> */}
          </FormGroup>

          {/* Seleção da Unidade */}
          <FormGroup>
            <FormGroup row>
              <Label lg={12} xl={3}>
                Unidade
              </Label>
              <Col lg={12} xl={7}>
                <Input
                  type="text"
                  id="variablesSelect"
                  value={labelUnit}
                  onChange={e => setLabelUnit(e.target.value)}
                />
              </Col>
            </FormGroup>
          </FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            className="mb-1"
            onClick={toggle}
            outline
          >
            {t("solarReport.closeButton")}
          </Button>
          <Button
            color='primary'
            className="mb-1"
            style={{ minWidth: 70 }}
            onClick={handleAddFormula}
          >
            {t("Confirmar")}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

export default ModalAddFormula;