import { Card, Container, Row, Spinner } from "reactstrap";
import { Breadcrumb, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { ChartAlarmDevices } from "./components/ChartAlarmDevices";
import { ChartCommDevices } from "./components/ChartCommDevices";
import { ChartProjectModules } from "./components/ChartProjectModules";
import { InfoEnergy } from "./components/InfoEnergy";
import { InfoSolar } from "./components/InfoSolar";
import { InfoWater } from "./components/InfoWater";
import { Projects } from "./components/Projects";
import { readCompanies } from "./ServiceAuthorizations/ServiceAuthorizationsService";
import { NewWindow } from "./newWindow";
import { ChevronLeft, ChevronRight } from "react-feather";

export const Noc = () => {
  const { t } = useTranslation();

  const companyListString = localStorage.getItem("companyList");
  let companyList = companyListString ? JSON.parse(companyListString) : [];
  const companyId = localStorage.getItem("companyId");

  const [selectedCompany, setSelectedCompany] = useState(companyId || "");
  const [listCompanies, setListCompanies] = useState(companyList);
  const [loading, setLoading] = useState(false);
  const [windowSelected, setWindowSelected] = useState("newWindow");

  const searchCompanies = async () => {
    setLoading(true);
    const response = await readCompanies();
    setLoading(false);
    setListCompanies(response.data);
    localStorage.setItem("companyList", JSON.stringify(response.data));
  };

  useEffect(() => {
    if (!companyList.length) {
      searchCompanies();
    }
  }, []);

  if (loading) {
    <div>
      <Spinner />
    </div>;
  }

  const availableWindowOptions = {
    projects: <Projects companyId={selectedCompany} />,
    newWindow: <NewWindow companyId={selectedCompany} />
  };

  const keysAvailableWindowOptions = Object.keys(availableWindowOptions);
  
  const handleNext = () => {
    setWindowSelected((prevStatus) => {
      const currentIndex = keysAvailableWindowOptions.indexOf(prevStatus);
      return keysAvailableWindowOptions[(currentIndex + 1) % keysAvailableWindowOptions.length];
    });
  };
  
  const handlePrevious = () => {
    setWindowSelected((prevStatus) => {
      const currentIndex = keysAvailableWindowOptions.indexOf(prevStatus);
      return keysAvailableWindowOptions[
        currentIndex === 0 ? keysAvailableWindowOptions.length - 1 : currentIndex - 1
      ];
    });
  };

  return (
    <Container style={{height: "100vh"}}>
      <Header>
        <HeaderTitle>{t("noc.header.title")}</HeaderTitle>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">{t("sidebar.home")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t("noc.header.title")}</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Row className="m-0 mb-1">
        <Card className="m-0 d-flex w-100 p-1">
          <Form.Group className="d-flex flex-row align-items-center">
            <Form.Label htmlFor={"companyInput"} className="d-flex">
              {t("noc.main.pageView")}
            </Form.Label>
            <Form.Control
              className="d-flex"
              style={{ padding: 0, width: "120px", border: "none" }}
              id={"companyInput"}
              name={"company"}
              as={"select"}
              value={selectedCompany}
              onChange={({ target }) => setSelectedCompany(target.value)}
            >
              <option value="" selected disabled>
                {t("noc.form.selectCompany")}
              </option>
              {listCompanies.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Card>
      </Row>
      <Row
        className="m-0"
        style={{
          gap: "8px",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
        }}
      >
        <ChartCommDevices companyId={selectedCompany} />
        <ChartProjectModules companyId={selectedCompany} />
        <ChartAlarmDevices companyId={selectedCompany} />
        <InfoSolar companyId={selectedCompany} />
        <InfoEnergy companyId={selectedCompany} />
        <InfoWater companyId={selectedCompany} />
      </Row>
      <Row className="m-0 flex-row noc-window-main-content" style={{height: "calc(100vh - 410px)"}}>
        <ChevronLeft className="noc-prev-button" onClick={handlePrevious} />
          {availableWindowOptions[windowSelected]}
        <ChevronRight className="noc-next-button" onClick={handleNext} />
      </Row>
    </Container>
  );
};
