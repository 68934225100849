import React, { useEffect, useState } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';
import {
  CardBody,
  CardTitle,
  Row,
  Col,
  CardHeader,
  CardFooter,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardSubtitle,
  UncontrolledTooltip,
} from "reactstrap";
import * as Icon from "react-feather";
import { MoreVertical } from "react-feather";
import { Link, useHistory } from "react-router-dom";

import ModalDeleteCard from "../ModalDeleteCard";

function CardDropdown(props) {
  const { t } = useTranslation();
  
  const [openDeleteCard, setOpenDeleteCard] = useState(false);
  const [editCard, setEditCard] = useState(false)

  function toggleDeleteCard() {
    setOpenDeleteCard(!openDeleteCard);
  }
  
  return (
    <UncontrolledDropdown className="d-inline-block" disabled={(props.preview)}>
      <DropdownToggle tag="a">
        <MoreVertical />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          onClick={() => {
            toggleDeleteCard();
          }}
        >
          {t('dashboards.delete')}
        </DropdownItem>
        {(openDeleteCard)
          ? <ModalDeleteCard
            open={openDeleteCard}
            toggle={toggleDeleteCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
          />
          : null
        }
        <Link
          onClick={() => setEditCard(props.cardId)}
          to={{
            pathname: `/newCard/formulaCard/${props.cardId}`,
            state: {
              cardProps: props.card,
              editCard: editCard,
            }
          }}>
          <DropdownItem>
            {t('dashboards.edit')}
          </DropdownItem>
        </Link>

        <DropdownItem
          onClick={() => {
            props.setDataLoading(true);

          }}
        >
          {t('dashboards.refresh')}
        </DropdownItem>

      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const FormulaMath = ({
  cardId,
  card,
  name,
  description,
  decimalNumber,
  deviceId,
  variables,
  tableReload,
  preview,
  reloadTime,
  labelUnit,
  resultToPreview,
  dimensions
}) => {
  // console.log(card);
  const { t } = useTranslation();
  const [formulaData, setFormulaData] = useState();
  const [deviceData, setdeviceData] = useState([]);
  const [dataLoading, setdataLoading] = useState(true);
  const [device, setDevice] = useState("");
  const varName = (variables) ? JSON.parse(variables)?.varName : "";
  const [lastComm, setLastComm] = useState();
  const [commInterval, setCommInterval] = useState();
  const [cardHeight, setCardHeight] = useState();

  let history = useHistory();

  useEffect(() => {
    if (dimensions) {
      if (dimensions?.id === card?.id) {
        let var1 =
          ((dimensions.size.height < 200 && dimensions.size.width < 240) && (card.id === dimensions.id)) ? 1.9
            : ((dimensions.size.height > 241 && dimensions.size.width > 310) && (card.id === dimensions.id) && (dimensions.size.height <= 310)) ? 4
              : ((dimensions.size.height > 311 && dimensions.size.width > 370) && (card.id === dimensions.id) && (dimensions.size.height < 370)) ? 6
                : ((dimensions.size.height > 371 && dimensions.size.width > 420) && (card.id === dimensions.id) && (dimensions.size.height < 420)) ? 8
                  : ((dimensions.size.height > 421 && dimensions.size.width > 500) && (card.id === dimensions.id) && (dimensions.size.height < 500)) ? 10
                    : ((dimensions.size.height > 501 && dimensions.size.width > 501) && (card.id === dimensions.id)) ? 12
                      : 2.1

        setCardHeight(var1)
      }
    }
  }, [dimensions, card?.id]);
  
  useEffect(() => {
    if (deviceId || dataLoading) {
      axios.get('/readDataLast', {
        params: {
          idDevice: deviceId
        }
      })
        .then(response => {
          // console.log(response);
          setdeviceData(response.data)
          setLastComm(response.data.lastComm);
          setCommInterval(response.data.commInterval);
          setdataLoading(false)
        })
        .catch(err => {
          console.log(err);
          toastr.warning('Erro!', "Erro ao buscar dados.");
        })
        .finally(setdataLoading(false))
    }

    if (reloadTime) {
      const intervalSearch = setInterval(() => {
        if (deviceId) {
          axios.get('/readDataLast', {
            params: {
              idDevice: deviceId
            }
          })
            .then(response => {
              setdeviceData(response.data)
              setLastComm(response.data.lastComm);
              setCommInterval(response.data.commInterval);
            })
            .catch(err => {
              console.log(err);
              toastr.warning('Erro!', "Erro ao buscar dados.");
            });

          if (history.location.pathname !== "/dashboardView") {
            clearInterval(intervalSearch);
          };
        }
      }, reloadTime);
    }
  }, [deviceId, dataLoading, history, reloadTime])

  // //Envia o id do device e retorna o modelo 
  useEffect(() => {

    if (deviceId) {
      axios.get('/readDeviceInfo', {
        params: {
          idDevice: deviceId
        }
      })
        .then(response => {
          setDevice(response.data);
          setLastComm(response.data.lastComm);
          setCommInterval(response.data.commInterval);
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar Modelo.");
        })
    }

  }, [deviceId, deviceData])

  useEffect(() => {
    if (deviceId) {
      axios.get('/readDataToFormulaCard', {
        params: {
          idCard: card.id,
          dashId: card.dashId
        }
      })
        .then(response => {
          setFormulaData(response.data.resultAndUnit)
        })
        .catch(err => {
          console.error(err);
        })
    }

    if (reloadTime) {
      const intervalSearch = setInterval(() => {
        if (deviceId) {
          axios.get('/readDataToFormulaCard', {
            params: {
              idCard: card.id,
              dashId: card.dashId
            }
          })
            .then(response => {
              // console.log("TESTE", response.data.resultAndUnit);

              setFormulaData(response.data.resultAndUnit)
            })
            .catch(err => {
              console.error(err);
            })
        } if (history.location.pathname !== "/dashboardView") {
          clearInterval(intervalSearch);
        };
      }, reloadTime);
    }

  }, [deviceId, dataLoading, card, history.location.pathname, reloadTime])

  const lastCommDate = new Date(lastComm * 1000);
  
  return <div id="parent">
    <CardHeader>
      <div className="card-actions float-right" style={{ marginTop: "2px" }}>
        <Row className="card-actions float-right">
          <Col className="pl-0" style={{ paddingRight: "3px" }}>
            <div className="box no-cursor cursor-pointer mp-0 otavio">
              <Icon.Move style={{ width: "15px" }} />
            </div>
          </Col>
          <CardDropdown
            card={card}
            cardId={cardId}
            tableReload={tableReload}
            name={name}
            description={description}
            preview={(preview)}
            setDataLoading={setdataLoading}
          />
        </Row>
      </div>
      <div id={"name" + cardId} >
        {(name) ?
          <UncontrolledTooltip
            style={{ backgroundColor: "#202020", opacity: 1, fontSize: ".8rem", color: "#F1F1F1", border: "1px solid #000", padding: "1rem" }}
            placement="top"
            target={"name" + cardId}
          >
            {name}
          </UncontrolledTooltip>
          : null}
        <CardTitle style={{ whiteSpace: "nowrap" }} className="mr-5 overflow-hidden text-left my-1 h5">{name}</CardTitle>
        <CardSubtitle style={{ whiteSpace: "nowrap" }} className="mr-5 overflow-hidden text-muted text-left">
          {description}
        </CardSubtitle>
        {(description) ?
          <UncontrolledTooltip
            style={{ backgroundColor: "#202020", opacity: 1, fontSize: ".8rem", color: "#F1F1F1", border: "1px solid #000", padding: "1rem" }}
            placement="bottom"
            target={"name" + cardId}
          >
            {description}
          </UncontrolledTooltip>
          : null}
      </div>
    </CardHeader>
    <CardBody id={"desc" + cardId} className="text-center" style={{ paddingTop: "0px" }}>
      {(formulaData)
        //verifica se tem devices e o conteúdo e se o conteúdo é igual a zero ele printa a variável e a unidade selecionada no novo card, se não não há dados.
        ? (lastComm >= Math.floor(Date.now() / 1000) - (commInterval * 2))
          ? <div
            style={{ whiteSpace: "nowrap" }}
            className="overflow-hidden text-muted text-left"
          >
            <h2 className="display-3 w-responsive text-center" style={{
              fontSize: `${cardHeight}rem`,
              top: "35%",
              transform: "translate (0, -50%)",
              marginTop: "-6px"
            }}>
              {(formulaData.length) ? formulaData[0].calculatedResult.toFixed(card.decimalNumber) : null} {" "}
              {(formulaData.length) ? formulaData[0].unit.toString() : null}
            </h2>
          </div>
          : <p className="mt-1">{t('dashboards.offlineStats')}</p>
        : <p className="mt-1">{t('dashboards.noDataStats')}</p>
      }

    </CardBody>
    {
      (cardHeight > 4) ?
        <CardFooter className="text-left mt-1" style={{ bottom: "0", position: `${(!preview) ? "absolute" : null}`, padding: "5px 1.25rem" }}>
          <h6 className="text-left">{device.description}</h6>
          <h6 className="card-subtitle text-muted">{varName}</h6>
          <h6 className="card-subtitle text-muted mt-1">
            {(lastComm)
              ? lastCommDate.getDate().toString().padStart(2, '0') + '/' +
              (lastCommDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
              lastCommDate.getFullYear().toString().padStart(2, '0') + ' ' +
              lastCommDate.getHours().toString().padStart(2, '0') + ':' +
              lastCommDate.getMinutes().toString().padStart(2, '0') + ':' +
              lastCommDate.getSeconds().toString().padStart(2, '0')
              : t('dashboards.noComm')
            }
          </h6>
        </CardFooter>
        : null
    }
  </div >
}

export default FormulaMath;

