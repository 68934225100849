import enGB from "date-fns/locale/en-GB";
import ptBR from "date-fns/locale/pt-BR";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { usePlantContext } from "../../../PlantProvider";

const SelectMonth = () => {
  const { i18n, t } = useTranslation();

  const { setSelectedDate } = usePlantContext();

  return (
    <DatePicker
      className="inputs form-control cursor-pointer"
      onChange={(date) => {
        setSelectedDate(date);
      }}
      dateFormat="MM/yyyy"
      showMonthYearPicker
      showFullMonthYearPicker
      placeholderText={t("subMenu.selectMonth")}
      locale={i18n.language === "br" ? ptBR : enGB}
      maxDate={new Date()}
    />
  );
};

export default SelectMonth;
