// import { message } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from "@fortawesome/free-solid-svg-icons";

// import { handleAddNewVar } from './ModalEditDriver'

function ModalAddNewDriver({ args, tableReload }) {
  const { t } = useTranslation();
  // Campos do formulário
  const [modal, setModal] = useState(false);
  const [models, setModels] = useState("");
  const [modelType, setModelType] = useState("Potência");
  const [varName, setVarName] = useState("");
  const [varDescription, setVarDescription] = useState("");
  const [tag, setTag] = useState("");
  const [unit, setUnit] = useState("");
  const [varType, setVarType] = useState("Number");
  const [varOptionalType, setVarOptionalType] = useState("");
  const [measureType, setMeasureType] = useState("Integral");
  const [newVar, setNewVar] = useState([]);
  // const [addNewVar, setAddNewVar] = useState([])
  const toggle = () => setModal(!modal);

  function handleAddNewVar() {
    try {
      setVarType("Number");
      setMeasureType("Integral");
      let newVarData = {
        varName,
        varDescription,
        tag,
        unit,
        varType,
        measureType,
        varOptionalType
      };
      // toastr.success(t('addNewDriver.variableSuccessfulyAdded'))
      setVarName("");
      setVarDescription("");
      setTag("");
      setUnit("");
      setVarOptionalType("");
      setNewVar(prevData => [...prevData, newVarData]);

    } catch (err) {
      console.error(err);
    }

  }

  function handleDeleteVariable(index) {
    setNewVar(newVar.filter((item, i) => (i !== index)));
  }

  function handleSaveAddedVars(e) {
    e.preventDefault();

    if (!models || !modelType) {
      toastr.warning(t('addNewDriver.toastSaveVar'))
    } else if (newVar.length === 0) {
      toastr.warning("Cuidado, você não adicionou nenhum variável")
    } else {
      e.preventDefault();
      axios.post('/addCustomDriverOnSubmit', JSON.stringify({
        models,
        modelType,
        newVar,
      }))
        .then(res => {
          toastr.success(res.data.messageHead, res.data.messageBody);
          console.log(res);
          toggle();
        })
        .catch(err => console.log(err))
        .finally(() => {
          tableReload()
        })
    }
  }

  return (
    <div>
      <Button color="primary" onClick={toggle}>
        {t("addNewDriver.btnAdd")}
      </Button>
      <Modal scrollable={true} isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader fixed="top" toggle={toggle}>{t("addNewDriver.modalTitle")}</ModalHeader>
        <ModalBody>
          {/* FORMULARIO PARA ADICIONAR DRIVER PERSONALIZADO */}
          <Form name="form" onSubmit={handleSaveAddedVars}>
            <Row scrollable={true}>
              <Col md={6} >
                <FormGroup>
                  <Label for="models">
                    {t("addNewDriver.driverName")}
                  </Label>
                  <Input
                    id="models"
                    name="models"
                    type="text"
                    value={models}
                    onChange={(e) => setModels(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6} >
                <FormGroup>
                  <Label for="modelType">
                    {t('addNewDriver.modelType')}
                  </Label>
                  <Input
                    id="modelType"
                    name="modelType"
                    placeholder="select"
                    type="select"
                    value={modelType}
                    onChange={(e) => setModelType(e.target.value)}
                  >
                    <option value="P">{t("addNewDriver.driverTypeP")}</option>
                    <option value="S">{t("addNewDriver.driverTypeS")}</option>
                    <option value="M">{t("addNewDriver.driverTypeM")}</option>
                    <option value="C">{t("addNewDriver.driverTypeC")}</option>
                    <option value="T">{t("addNewDriver.driverTypeT")}</option>
                    <option value="U">{t("addNewDriver.driverTypeU")}</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <hr />
            {(newVar.map((driverDetails, index) => (
              <div key={index}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="varName">
                        {t('addNewDriver.varName')}
                      </Label>
                      <Input
                        required
                        id="varName"
                        name="varName"
                        type="text"
                        value={driverDetails.varName}
                        onChange={(e) =>
                          setNewVar(newVar.map((driverDetails, i) => {

                            return (i === index)
                              ? { ...driverDetails, varName: e.target.value }
                              : driverDetails;
                          }))
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="varDescription">
                        {t('addNewDriver.varDescription')}
                      </Label>
                      <Input
                        required
                        id="varDescription"
                        name="varDescription"
                        type="text"
                        value={driverDetails.varDescription}
                        onChange={(e) => setNewVar(newVar.map((driverDetails, i) => {

                          return (i === index)
                            ? { ...driverDetails, varDescription: e.target.value }
                            : driverDetails;
                        }))}
                      />
                    </FormGroup>
                  </Col>

                </Row>

                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="tag">
                        {t('addNewDriver.varTag')}
                      </Label>
                      <Input
                        required
                        id="tag"
                        name="tag"
                        type="text"
                        value={driverDetails.tag}
                        onChange={(e) => setNewVar(newVar.map((driverDetails, i) => {

                          return (i === index)
                            ? { ...driverDetails, tag: e.target.value }
                            : driverDetails;
                        }))}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="unit">
                        {t("addNewDriver.varUnit")}
                      </Label>
                      <Input
                        required
                        id="unit"
                        name="unit"
                        type="text"
                        value={driverDetails.unit}
                        onChange={(e) => setNewVar(newVar.map((driverDetails, i) => {

                          return (i === index)
                            ? { ...driverDetails, unit: e.target.value }
                            : driverDetails;
                        }))}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="varType">
                        {t("addNewDriver.type")}
                      </Label>
                      <Input
                        id="varType"
                        name="varType"
                        type="select"
                        value={driverDetails.varType}
                        onChange={(e) => setNewVar(newVar.map((driverDetails, i) => {

                          return (i === index)
                            ? { ...driverDetails, varType: e.target.value }
                            : driverDetails;
                        }))}
                      >
                        {/* <option>{t("addNewDriver.chooseOneOption")}</option> */}
                        <option value="Number">{t("addNewDriver.varTypeNumber")}</option>
                        <option value="Boolean">{t("addNewDriver.varTypeBool")}</option>
                        <option value="Text">{t("addNewDriver.varTypeText")}</option>
                      </Input>

                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="measureType">
                        {t("addNewDriver.measureType")}
                      </Label>
                      <Input
                        id="measureType"
                        name="measureType"
                        type="select"
                        value={driverDetails.measureType}
                        onChange={(e) => setNewVar(newVar.map((driverDetails, i) => {

                          return (i === index)
                            ? { ...driverDetails, measureType: e.target.value }
                            : driverDetails;
                        }))}
                      >
                        {/* <option>{t("addNewDriver.chooseOneOption")}</option> */}
                        <option>{t("addNewDriver.varMeasureTypeI")}</option>
                        <option>{t("addNewDriver.varMeasureTypeD")}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="measureType">
                        {t("addNewDriver.varOptionalType")}
                      </Label>
                      <Input
                        id="measureType"
                        name="measureType"
                        type="select"
                        value={driverDetails.varOptionalType}
                        onChange={(e) => setNewVar(newVar.map((driverDetails, i) => {

                          return (i === index)
                            ? { ...driverDetails, varOptionalType: e.target.value }
                            : driverDetails;
                        }))}
                      >
                        <option>{t("addNewDriver.varOptionalTypeSelect")}</option>
                        <option value="D">{t("addNewDriver.varOptionalTypeD")}</option>
                        <option value="E">{t("addNewDriver.varOptionalTypeE")}</option>
                        <option value="M">{t("addNewDriver.varOptionalTypeM")}</option>
                        <option value="P">{t("addNewDriver.varOptionalTypeP")}</option>
                        <option value="S">{t("addNewDriver.varOptionalTypeS")}</option>
                      </Input>
                    </FormGroup>
                  </Col>

                </Row>
                <Row className="mb-3" style={{ display: "flex", justifyContent: "center" }}>
                  <div id={"deleteVariable" + index}>

                    <FontAwesomeIcon id={"deleteVariable" + index} onClick={() => handleDeleteVariable(index)} icon={faTrash} fixedWidth />
                    <UncontrolledTooltip
                      placement="top"
                      target={"deleteVariable" + index}
                    >
                      {t('addNewDriver.deleteVariable')}
                    </UncontrolledTooltip>
                  </div>
                </Row>
                <hr className="mb-4" />
              </div>
            )))}
            <Row className="ant-row-end mr-3 mb-3">
              <Button
                color="primary"
                onClick={handleAddNewVar}
              >
                {t("addNewDriver.addVarBtn")}
              </Button>
            </Row>
            <ModalFooter>
              <Button color="primary" onClick={toggle} outline>
                {t("addNewDriver.cancelVarBtn")}
              </Button>
              <Button
                type="submit"
                color="primary"
              >
                {t("addNewDriver.saveNewDriverBtn")}
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </div >
  );
}

export default ModalAddNewDriver;