import React from "react";
import { Page, Document, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Importação de logo, icones e imagens para PDF
import imagePlant from '../../../img/image-plant.png';
import iconPlant from '../../../img/icon-plant.png';
import inverterIcon from '../../../img/icon-inverter.png';
import systemIcon from '../../../img/icon-system.png';
import grossMarginIcon from '../../../img/icon-gross-margin.png';
import mwhProducedIcon from '../../../img/icon-mwh-produced.png';
import co2SavedIcon from '../../../img/icon-co2-saved.png';
import avgPrIcon from '../../../img/icon-avg-pr.png';
import greenCircleIcon from '../../../img/icon-circle-green.png';
import redCircleIcon from '../../../img/icon-circle-red.png';

const ReportManagerDocument = ({
  data,
  chartScreenshot, 
  projectName
}) => {

  const { t } = useTranslation();

  function getMonth() {  

      let months = [
        t('reports.month1'),
        t('reports.month2'),
        t('reports.month3'),
        t('reports.month4'),
        t('reports.month5'),
        t('reports.month6'),
        t('reports.month7'),
        t('reports.month8'),
        t('reports.month9'),
        t('reports.month10'),
        t('reports.month11'),
        t('reports.month12')
        ];

        return data?.monthRef ? months[data.monthRef.split('-')[0] - 1] : null;
  }
    
  const details = {
    customer: data.details.customer ? data.details.customer : "",
    location: data.details.location ? data.details.location : "" ,
    productCluster: data.details.productCluster ? data.details.productCluster : "" ,
    positioning: data.details.positioning ? data.details.positioning : "",
    peakPower: data.details.peakPower ? data.details.peakPower : "",
    contractStart: data.details.contractStart ? moment(data.details.contractStart).format('DD/MM/YYYY') : "" ,
    contractEnd: data.details.contractEnd ? moment(data.details.contractEnd).format('DD/MM/YYYY') : "",
    imagePlant: data.details.imagePlant ? data.details.imagePlant : imagePlant
  };

  const chart = {
    month: getMonth(),
    year: data?.monthRef ? data.monthRef.split('-')[1] : null,
  };

  const status = {
    invertersWorking: data.currentStatus.invertersWorking ? 
      greenCircleIcon : redCircleIcon,
    systemWorking: data.currentStatus.systemWorking ?
    greenCircleIcon : redCircleIcon,
  };

  const kpis = {
    grossMargin: data.kpis.grossMargin ? `${data.kpis.grossMargin} / ${t('reportManager.month')}` : t('none'),
    mwhProduced: data.kpis.mwhProduced ? `${data.kpis.mwhProduced} / ${t('reportManager.month')}` : t('none'),
    co2Saved: data.kpis.co2Saved? `${data.kpis.co2Saved} t / ${t('reportManager.month')}` : t('none'),
    avgPr: data.kpis.avgPr? `${data.kpis.avgPr} %` : t('none'),
  };

  const events = data.events;

  const styles = StyleSheet.create({

    // Layout
    page: {
      paddingTop: 20,
      paddingHorizontal: 10,
      backgroundColor: "white",
    },
    
    row: {
      flexDirection: 'row',
      marginBottom: 10,
    },
    
    details: {
      flex: 3,
      margin: '0 10px',
    },

    chart: {
      flex: 4,
    },

    status: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      padding: 5,
      margin: '0',
    },

    kpis: {
      flex: 3,
      borderRadius: '10px',
      padding: 5,
      margin: '0 10px',
      backgroundColor: "#f2f2f2",
    },

    event: {
      flex: 7,
      padding: 5,
      margin: '0',
      borderRadius: '10px',
      backgroundColor: "#f2f2f2",
    },

    // Elementos Gerais

    text: {
      fontSize: '12px',
    },

    detachText: {
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#320352',
      alignSelf: 'center'
    },

    title: {
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#320352',
    },

    icon: {
      width: '30px',
      height: '30px'
    },

    logo: {
      // width: '180px',
      fontSize: '16px',
      color: '#320352',
    },

    // Detalhes

    detailsCard: {
      borderRadius: '10px',
      padding: 5,
      backgroundColor: "#f2f2f2",
    },

    detailsTitle: {
      color: "black",
      margin: '-5px -5px 10px -5px',
      backgroundColor: "#c8caf0",
      padding: 10,
      fontWeight: 'bold'
    },
    detailsData: {
      padding: '5px',
      lineHeight: 1.7
    },

    detailsImage: {
      display: 'flex', 
      flexDirection: 'row', 
      justifyContent: 'space-around', 
      alignItems: 'center',
      margin: '5px 0',
      padding: '5px',
      border: '5px solid #c7ccea',
      borderRadius: '10px',
    },

    detailsImagePlant: {
      width: "120px",
      borderRadius: '10px',
    },

    detailsImageIcon: {
      width: "50px"
    },
    
    // Gráfico
    titleChart: {
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      fontSize: '12px',
      margin: '5px 0'
    },
    
    barChart: {
      display: 'flex', 
      alignItems: 'center',
    },

    imageChart: {
      width: '100%',
      height: '200px'
    },

    //Status Atual 

    statusTitle: {
      margin: '-5px -5px 10px -5px',
      backgroundColor: "#c8caf0",
      padding: 10,
      color: 'black',
      fontWeight: 'bold'
    },

    statusText: {
      fontSize: '12px',
      top: 8
    },

    statusCircle: {
      width: '20px',
      height: '20px',
      top: '5px'
    },

    statusInverter: {
      display: 'flex',
      flexDirection: 'row',
      padding: '5px 20px',
      justifyContent: 'space-around',
      gap: '10px'
    },

    statusSystem: {
      display: 'flex',
      flexDirection: 'row',
      padding: '5px 20px',
      justifyContent: 'space-around',
      gap: '10px'
    },

    statusPerfomance: {
      display: 'flex',
      flexDirection: 'row',
      margin: '20px',
      justifyContent: 'center',
    },

    //KPI's 

    kpisTitle: {
      margin: '-5px -5px 10px -5px',
      backgroundColor: "#c8caf0",
      padding: 10,
      color: 'black',
      fontWeight: 'bold'
    },

    kpisGrossMargin: {
      display: 'flex',
      flexDirection: 'row',
      padding: '5px',
      justifyContent: 'space-between'
    },

    kpisMwhProduced: {
      display: 'flex',
      padding: '5px',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },

    kpisCo2Saved: {
      display: 'flex',
      flexDirection: 'row',
      padding: '5px',
      justifyContent: 'space-between'
    },

    kpisAvgPr: {
      display: 'flex',
      flexDirection: 'row',
      padding: '5px',
      justifyContent: 'space-between'
    },

    kpisText: {
      fontSize: '12px',
      alignSelf: 'center'
    },

    // Eventos

    eventTitle: {
      margin: '-5px -5px 10px -5px',
      backgroundColor: "#c8caf0",
      padding: 10,
      color: 'black',
      fontWeight: 'bold'
    },

    headerTable: {
      backgroundColor: "#a5a5a5",
      fontSize: '14px',
      padding: 5,
      textAlign: 'center',
      color: 'white'
    },

    tr: {
      flexDirection: 'row'
    },

    td: {
      flex: 1,
      padding: 2,
    },

    cell: {
      backgroundColor: "white",
      padding: 5,
      fontSize: '10px'
    },


  });
    return (
      <Document>
        <Page style={styles.page} size="A4" orientation="landscape">
          <View style={styles.row}>

          {/* Detalhes */}
            <View style={styles.details}>
              <View style={styles.detailsCard}>
                <View style={styles.detailsTitle}>
                  <Text style={styles.title}>{t('reportManager.detailsTitle')}</Text>
                </View>
                <View style={styles.detailsData}>
                  <Text style={styles.text}>
                    <Text style={styles.detachText}>{t('reportManager.customer')}: </Text>
                    <Text>{details.customer}</Text>
                  </Text>
                  <Text style={styles.text}>
                    <Text style={styles.detachText}>{t('reportManager.location')}: </Text>
                    <Text>{details.location}</Text>
                  </Text>
                  <Text style={styles.text}>
                    <Text style={styles.detachText}>{t('reportManager.productCluster')}: </Text>
                    <Text>{details.productCluster}</Text>
                  </Text>
                  <Text style={styles.text}>
                    <Text style={styles.detachText}>{t('reportManager.positioning')}: </Text>
                    <Text>{details.positioning}</Text>
                  </Text>
                  <Text style={styles.text}>
                    <Text style={styles.detachText}>{t('reportManager.peakPower')}: </Text>
                    <Text>{details.peakPower}</Text>
                  </Text>
                  <Text style={styles.text}>
                    <Text style={styles.detachText}>{t('reportManager.contractStart')}: </Text>
                    <Text>{details.contractStart}</Text>
                  </Text>
                  <Text style={styles.text}>
                    <Text style={styles.detachText}>{t('reportManager.contractEnd')}: </Text>
                    <Text>{details.contractEnd}</Text>
                  </Text>
                </View>
              </View>

              <View style={styles.detailsImage}>
                <Image style={styles.detailsImageIcon} src={iconPlant} />
                <Image style={styles.detailsImagePlant} src={details.imagePlant} />
              </View>
            </View>

            <View>

              {/* Gráfico */}
              <View style={styles.chart}>
                  <View style={styles.titleChart}>
                      <Text style={styles.logo}>{projectName} | </Text>
                      <Text style={styles.title}> {chart.month} {chart.year}</Text>
                  </View>
                  <View style={styles.barChart}>                      
                    <Image style={styles.imageChart} src={chartScreenshot}/>
                  </View>
              </View>

              {/* Status Atual */}
              <View style={styles.statusTitle}>
                <Text style={styles.title}>{t('reportManager.statusTitle')}</Text>
              </View>

              <View style={styles.status}>

                <View style={styles.statusInverter}>
                  <Image style={styles.icon} src={inverterIcon} />
                  <Text style={styles.statusText}>{t('reportManager.statusInverter')}</Text>
                  <Image style={styles.statusCircle} src={status.invertersWorking} />
                </View>

                <View style={styles.statusSystem}>
                  <Image style={styles.icon} src={systemIcon} />
                  <Text style={styles.statusText}>{t('reportManager.statusSystem')}</Text>
                  <Image style={styles.statusCircle} src={status.systemWorking} />
                </View>

              </View>

            </View>
            
          </View>

          <View style={styles.row}>

            {/* KPI's */}
            <View style={styles.kpis}>
              <View style={styles.kpisTitle}>
                <Text style={styles.title}>{t('reportManager.kpisTitle')}</Text>
              </View>
              <View style={styles.kpisGrossMargin}>
                <Image style={styles.icon} src={grossMarginIcon} />
                <Text style={styles.detachText}>{t('reportManager.grossMargin')}</Text>
                <Text style={styles.kpisText}>{kpis.grossMargin}</Text>
              </View>
              <View style={styles.kpisMwhProduced}>
                <Image style={styles.icon} src={mwhProducedIcon} />
                <Text style={styles.detachText}>{t('reportManager.mwhProduced')}</Text>
                <Text style={styles.kpisText}>{kpis.mwhProduced}</Text>
              </View>
              <View style={styles.kpisCo2Saved}>
                <Image style={styles.icon} src={co2SavedIcon} />
                <Text style={styles.detachText}>{t('reportManager.co2Saved')}</Text>
                <Text style={styles.kpisText}>{kpis.co2Saved}</Text>
              </View>
              <View style={styles.kpisAvgPr}>
                <Image style={styles.icon} src={avgPrIcon} />
                <Text style={styles.detachText}>{t('reportManager.avgPr')}</Text>
                <Text style={styles.kpisText}>{kpis.avgPr}</Text>
              </View>
            </View>

            {/* Eventos */}
            <View style={styles.event}>

              <View style={styles.eventTitle}>
                <Text style={styles.title}>{t('reportManager.eventTitle')}</Text>
              </View>

              <View style={styles.tr}>
                <View style={styles.td}>
                  <View style={styles.headerTable}>
                    <Text>{t('reportManager.eventDescription')}</Text>
                  </View>
                </View>
                <View style={styles.td}>
                   <View style={styles.headerTable}>
                    <Text>{t('reportManager.eventAction')}</Text>
                  </View>
                </View>
              </View>

              {events.map((event) => (
                <View style={styles.tr}>
                    <View style={styles.td}>
                      <View style={styles.cell}>
                          <Text>{event.description}</Text>
                      </View>
                    </View>
                    <View style={styles.td}>
                      <View style={styles.cell}>
                        <Text>{event.action}</Text>
                      </View>
                    </View>
                </View>
              ))}

            </View>
          </View>
        </Page>
      </Document> 
    )

}

export default ReportManagerDocument;
