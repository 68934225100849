import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner
} from "reactstrap";
import { useConsumerUnitContext } from "../ConsumerUnitProvider";

const StatusCard = ({
  title,
  value,
  value2,
  value3,
  value4,
  icon,
  keyValue,
  keyValue2,
  keyValue3,
  keyValue4
}) => {
  const { loadingData } = useConsumerUnitContext();

  return (
    <Card className="m-0" style={{minHeight: "165px"}}>
      <CardHeader>
        <Row>
          <Col className="mt-0">
            <CardTitle tag="h5" style={{ marginBottom: "0px" }}>
              {title}
            </CardTitle>
          </Col>
          <Col className="col-auto">
            <div style={{ width: "33px", height: "33px" }}>
              <div className="avatar-title rounded-circle bg-primary-dark">
                <FontAwesomeIcon icon={icon} />
              </div>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="py-0">
        <Row className="d-flex justify-content-center align-items-center mt-0">
          {loadingData ? (
            <Spinner />
          ) : (
            <>
              {value ? (
                <Col className="p-0 d-flex justify-content-center align-items-center flex-column text-center">
                  <div style={{ fontSize: "12px" }}>
                    {keyValue}
                  </div>
                  <div
                    className="display-5 mt-2 mb-4 text-center"
                    style={{ fontSize: "20px", color: "#203A45" }}
                  >
                    {value.includes("undefined") ? "" : value}
                  </div>
                </Col>
              ) : null}
              {value2 ? (
                <Col className="p-0 d-flex justify-content-center align-items-center flex-column text-center">
                  <div style={{ fontSize: "12px" }}>
                    {keyValue2}
                  </div>
                  <div
                    className="display-5 mt-2 mb-4 text-center"
                    style={{ fontSize: "20px", color: "#203A45" }}
                  >
                    {value2.includes("undefined") ? "" : value2}
                  </div>
                </Col>
              ) : null}
              {value3 ? (
                <Col className="p-0 d-flex justify-content-center align-items-center flex-column text-center">
                  <div style={{ fontSize: "12px" }}>
                    {keyValue3}
                  </div>
                  <div
                    className="display-5 mt-2 mb-4 text-center"
                    style={{ fontSize: "20px", color: "#203A45" }}
                  >
                    {value3.includes("undefined") ? "" : value3}
                  </div>
                </Col>
              ) : null}
              {value4 ? (
                <Col className="p-0 d-flex justify-content-center align-items-center flex-column text-center">
                  <div style={{ fontSize: "12px", color: "#203A45" }}>
                    {keyValue4}
                  </div>
                  <div
                    className="display-5 mt-2 mb-4 text-center"
                    style={{ fontSize: "20px" }}
                  >
                    {value4.includes("undefined") ? "" : value4}
                  </div>
                </Col>
              ) : null}
            </>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default StatusCard;
