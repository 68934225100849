import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  DropdownItem,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import { usePlantContext } from "../../PlantProvider";
import { updatePreferences } from "../../PlantService";

const Preferences = ({ projId, open, toggle }) => {
  const { t } = useTranslation();

  const options = [
    "consumption",
    "carbonEmission",
    "demand",
    "powerFactor",
    "devicesInfo",
    "listMeters",
  ];

  const { params, reload } = usePlantContext();
  const [preferences, setPreferences] = useState([]);

  useEffect(() => {
    if (params !== 404) {
      setPreferences(
        params?.preferences || [
          "consumption",
          "demand",
          "powerFactor",
          "listMeters",
        ]
      );
    }
  }, [params]);

  function handlePreferences(preference) {
    const updatedPreferences = preferences?.includes(preference)
      ? preferences?.filter((item) => item !== preference)
      : [...preferences, preference];

    setPreferences(updatedPreferences);
  }

  async function savePreferences() {
    await updatePreferences(projId, preferences);
    reload();
    toggle();
  }

  return (
    <>
      {preferences &&
        options.map((option) => (
          <DropdownItem key={option} toggle={false}>
            <FormGroup check onClick={(e) => e.stopPropagation()}>
              <Label check>
                <Input
                  type="checkbox"
                  value={option}
                  checked={preferences?.includes(option)}
                  onChange={() => handlePreferences(option)}
                  onClick={(e) => e.stopPropagation()} // Adicione isso
                  disabled={option === "consumption" || option === "listMeters"}
                />
                {t(`preferences.${option}`)}
              </Label>
            </FormGroup>
          </DropdownItem>
        ))}
      <Button color="secondary" onClick={savePreferences} block>
        {t("preferences.save")}
      </Button>
    </>
  );
};

export default Preferences;
