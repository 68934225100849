import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { connect } from "react-redux";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Card, CardBody, CardHeader, CardTitle, Table,  Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';

const DoughnutChartAlarm = ({ theme }) => {

  const { t } = useTranslation();
  const [deviceUserAlarms, setDeviceUserAlarms] = useState([]);
  const [loadTypesAlarms, setLoadTypesAlarms] = useState(true);

  let history = useHistory();

  useEffect(() => {

    /* Roda a primeira chamada ao abrir a página, antes do setInterval */
    // axios.post('deviceUserAlarms/', JSON.stringify({
    //   userId: localStorage.getItem('userId')
    // }))
    // axios.get('/deviceUserAlarms', {params: {
    //   userId: localStorage.getItem('userId')
    // }})
    axios.get('/deviceUserAlarms')
    .then(response => {
      setDeviceUserAlarms(response.data);
    })
    .catch(err => {
      console.log(err);
      console.log(err.response);
      toastr.warning('Erro!',"Erro ao buscar alarmes.");
    })
    .finally(() => {
      setLoadTypesAlarms(false);
    });

    /* Roda chamadas a cada 10 segundos e atualiza o gráfico */
    const intervalDonutAlarmSearch = setInterval(()=> {
      // axios.post('deviceUserAlarms/', JSON.stringify({
      //   userId: localStorage.getItem('userId')
      // }))
      // axios.get('/deviceUserAlarms', {params: {
      //   userId: localStorage.getItem('userId')
      // }})
      axios.get('/deviceUserAlarms')
      .then(response => {
        setDeviceUserAlarms(response.data);
      })
      .catch(err => {
        console.log(err);
        console.log(err.response);
        toastr.warning('Erro!',"Erro ao buscar alarmes.");
      });
      /* Se não estiver na página home para o reload do gráfico a cada 10 segundos */
      if (history.location.pathname !== "/home") {
        clearInterval(intervalDonutAlarmSearch);
      };
    }, 30000);
  }, [history]);

  var alarmPriority = [0, 0, 0]; //posições a serem populadas com o time recebido da api
  
  /* iteração no estado setado pelo response da chamada, no item priority, somando +1 e populando a variável */
  if(deviceUserAlarms.length) {
    deviceUserAlarms.forEach(item=>{

        if (item.priority === 1) {
          alarmPriority[0] = alarmPriority[0] + 1 // alta prioridade
        }
        else if (item.priority === 2) {
          alarmPriority[1] = alarmPriority[1] + 1 // média prioridade
        }
        else  {
          alarmPriority[2] = alarmPriority[2] + 1 // baixa prioridade
        }
    })
  }

  const data = {
    /* label do gráfico */
    labels: [
      "Alta", 
      "Média", 
      "Baixa", 
      "Você não Possui Alarmes"
    ],
    datasets: [
      /* data popula com as infos e background a sua cor referente */
      {
        data: alarmPriority,
        backgroundColor: [
          "#dc3545",
          "#fd7e14",
          "#FFFF00",
        ],
        borderColor: "transparent"
      }
    ]
  };

   /* função de soma para popular a label do doughnutlabel */
  var getTotal = function() {
    var sum = data.datasets[0].data.reduce((a, b) => a + b, 0);
    return `${sum}`;
  }

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 65,

    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: getTotal,
            font: {
              size: '25'
            },
            color: 'black'
          },
          {
            text: 'Total',
            font: {
              size: '20'
            }
          },
        ]
      }
    },

    legend: {
      position: "bottom", //posição da legenda
      display: false, // desativa a legenda nativa do componente
    }
  };

  return (
    <Card style={{minWidth: "247px",paddingRight: "0px"}}>
      <CardHeader>
        <CardTitle tag="h5">{t('home.typesOfAlarms')}</CardTitle>
        <h6 className="card-subtitle text-muted">
        </h6>
      </CardHeader>
      <CardBody style={{height: "267px"}}>
        <div className="chart chart-xs" style={{height: "200px", marginBottom:"0px"}}>
          {(!deviceUserAlarms.length)
            ? (loadTypesAlarms)
              ? <div className="text-center">
                  <Spinner color="primary" />
                </div>
              : <div style={{height: "130px", marginBottom:"15px"}}>
                <Doughnut data={data} options={options} />
                <Table style={{padding:"6"}} >
                  <tbody >
                    <tr className="p-0" style={{padding:"6px"}}>
                      <td style={{padding:"6px"}}>
                        <FontAwesomeIcon icon={faCircle} className="text-danger" />{" "}
                        {t('home.high')}
                      </td>
                      <td className="text-right" style={{padding:"6px"}}>
                      {alarmPriority[0]}
                      </td>
                    </tr>
                    <tr className="p-0" style={{padding:"6px"}}>
                      <td style={{padding:"6px"}}>
                        <FontAwesomeIcon icon={faCircle} className="text-warning" />{" "}
                        {t('home.average')}
                      </td>
                      <td className="text-right" style={{padding:"6px"}}>
                      {alarmPriority[1]}
                        </td>
                    </tr>
                    <tr className="p-0" style={{padding:"6px"}}>
                      <td style={{padding:"6px"}}>
                        <FontAwesomeIcon icon={faCircle} style={{color:"#FFFF00"}}/> {t('home.low')}
                      </td>
                      <td className="text-right" style={{padding:"6px"}}>{alarmPriority[2]}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            : <div style={{height: "130px", marginBottom:"0px"}}>
                <Doughnut data={data} options={options} />
                <Table style={{padding:"6", marginTop:"30px" }} >
                  <tbody style={{fontSize:"11px"}}>
                    <tr className="p-0" style={{padding:"6px"}}>
                      <td style={{padding:"6px"}}>
                        <FontAwesomeIcon icon={faCircle} className="text-danger" />{" "}
                        {t('home.high')}
                      </td>
                      <td className="text-right" style={{padding:"6px"}}>
                      {alarmPriority[0]}
                      </td>
                    </tr>
                    <tr className="p-0" style={{padding:"6px"}}>
                      <td style={{padding:"6px"}}>
                        <FontAwesomeIcon icon={faCircle} className="text-warning" />{" "}
                        {t('home.average')}
                      </td>
                      <td className="text-right" style={{padding:"6px"}}>
                      {alarmPriority[1]}
                        </td>
                    </tr>
                    <tr className="p-0" style={{padding:"6px"}}>
                      <td style={{padding:"6px"}}>
                        <FontAwesomeIcon icon={faCircle} style={{color:"#FFFF00"}}/> {t('home.low')}
                      </td>
                      <td className="text-right" style={{padding:"6px"}}>{alarmPriority[2]}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
          }
        </div>
      </CardBody>
    </Card>
  );
};

export default connect(store => ({
  theme: store.theme.currentTheme
}))(DoughnutChartAlarm);
