import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Table, UncontrolledTooltip, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

export const UnitConsumers = ({ devices, metersId, setUcs, ucs, refMeter }) => {
  const { t } = useTranslation();

  const structure = [
    { id: 1, description: t("params.peakOffPeak"), variable: 'peakOffPeak' },
    { id: 2, description: t("params.kWhActiveConsumed"), variable: 'kWhActiveConsumed' },
    { id: 3, description: t("params.kWhActiveGenerate"), variable: 'kWhActiveGenerate' },
    { id: 4, description: t("params.demandAt"), variable: 'demandAt' },
    { id: 5, description: t("params.demandRe"), variable: 'demandRe' },
    { id: 6, description: t("params.kWhReativeConsumedExtended"), variable: 'kWhReativeConsumedExtended' },
    { id: 7, description: t("params.postoReat"), variable: 'postoReat' },
    { id: 8, description: t("params.kWhReativeConsumed"), variable: 'kWhReativeConsumed' },
    { id: 9, description: t("params.powerFactor"), variable: 'powerFactor' },
    { id: 10, description: t("params.pAtC"), variable: 'pAtC' },
    { id: 11, description: t("params.fpCap"), variable: 'fpCap' },
    { id: 12, description: t("params.fpInd"), variable: 'fpInd' }
  ];

  const [selectedUnit, setSelectedUnit] = useState(null);
  const [form, setForm] = useState({
    dvcId: null,
    description: null,
    energyStructure: structure.reduce((acc, { variable }) => {
          acc[variable] = null;
          return acc;
        }, {}),
    varlist: []
  });

  function viewUnit (unit) {
    setSelectedUnit(unit);
  };

  function addUnit() {
    setUcs([...ucs, { ...form }]);
    clearForm();
  }

  function editUnit() {
    setUcs(ucs.map(unit => (unit.dvcId === form.dvcId ? form : unit)));
    clearForm();
  }

  function deleteUnit(unitId) {
    setUcs(ucs.filter(unit => unit.dvcId !== unitId));
    clearForm();
  }

  function handleSelectedDevice(e) {
    const selectedDeviceId = parseInt(e.target.value);
    const selectedDevice = devices.find(device => device.dvcId === selectedDeviceId);
    setForm({
      dvcId: selectedDevice.dvcId,
      model: selectedDevice.model,
      description: selectedDevice.description,
      energyStructure: selectedDevice?.energyStructure || structure.reduce((acc, { variable }) => {
          acc[variable] = null;
          return acc;
        }, {}),
      varlist: selectedDevice?.varlist || []
    });
    setSelectedUnit(null);
  };

  function handleSelectedVariable(index, value) {
    const updatedStructure = form.energyStructure;
    updatedStructure[index] = value;
    
    setForm(prevState => ({
      ...prevState,
      energyStructure: updatedStructure
    }));  
  };

  function clearForm() {
    setSelectedUnit(null);
    setForm({
      dvcId: null,
      model: null,
      description: null,
      energyStructure: structure.reduce((acc, { variable }) => {
          acc[variable] = null;
          return acc;
        }, {}),
      varlist: []
    });
  };

  const columns = [
    { dataField: 'dvcId', text: 'ID', hidden: true },
    { dataField: 'description', text: t("params.devices") },
    {
      dataField: 'actions',
      text: t("params.actions"),
      formatter: (cell, row) => (
        <div className="d-flex justify-content-left">
          <div
            id={"edit" + row.dvcId}
            className="align-middle cursor-pointer mr-1"
            onClick={() => viewUnit(row)}
          >
            <FontAwesomeIcon icon={faPen} fixedWidth />
          </div>
          <UncontrolledTooltip placement="top" target={"edit" + row.dvcId}>
            {t("params.edit")}
          </UncontrolledTooltip>
          <div
            id={"delete" + row.dvcId}
            className="align-middle cursor-pointer mr-1"
            onClick={() => deleteUnit(row.dvcId)}
          >
            <FontAwesomeIcon icon={faTrash} fixedWidth />
          </div>
          <UncontrolledTooltip placement="top" target={"delete" + row.dvcId}>
            {t("params.delete")}
          </UncontrolledTooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const ucs = devices.filter(device => metersId.includes(device.dvcId));
    setUcs(ucs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, metersId]);

  useEffect(() => {
    if (selectedUnit) {
      setForm({
        dvcId: selectedUnit.dvcId,
        model: selectedUnit.model,
        description: selectedUnit.description,
        energyStructure: selectedUnit?.energyStructure || structure.reduce((acc, { variable }) => {
          acc[variable] = null;
          return acc;
        }, {}),
        varlist: selectedUnit?.varlist || []
      });
    } else {
      setForm({
        id: null,
        model: null,
        description: null,
        energyStructure: structure.reduce((acc, { variable }) => {
          acc[variable] = null;
          return acc;
        }, {}),
        varlist: []
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit]);

  return (
    <Row>
      <Col md={4}>
        <BootstrapTable keyField="dvcId" data={ucs} columns={columns} />
      </Col>
      <Col md={8}>
        <Form onSubmit={(e) => { e.preventDefault(); selectedUnit ? editUnit(): addUnit(); }}>
          <FormGroup>
            <Label for="device">{t("params.selectUc")}</Label>
            <Input
              type="select"
              description="device"
              id="device"
              value={selectedUnit ? selectedUnit.id : ''}
              onChange={handleSelectedDevice}
            >
              <option hidden>
                {form.description || t("params.selectDevice")}
              </option>  
              {devices.filter(device => 
                !ucs.some(unit => unit.dvcId === device.dvcId || refMeter.dvcId === device.dvcId)
              ).map(device => (
                <option key={device.dvcId} value={device.dvcId}>
                  {device.description}
                </option>
              ))}
            </Input>
          </FormGroup>
          {form.description &&
          <Table>
            <thead>
              <tr>
                <th>{t("params.model")}</th>
                <th>{t("params.structure")}</th>
                <th>{t("params.variables")}</th>
              </tr>
            </thead>
            <tbody>
              {structure.map((item) => (
                <tr key={item.id}>
                  <td>{form.model}</td>
                  <td>
                    {item.description} 
                    {["Ponta e Fora ponta", "Energia Ativa Consumida"].includes(item.description) && 
                    <span style={{ color: 'red' }}> é requerido </span>}
                  </td>
                  <td>
                    <Input
                      type="select"
                      value={form.energyStructure[item.variable] || ''}
                      onChange={(e) => handleSelectedVariable(item.variable, e.target.value)}
                      disabled={form.model === "IG9K" || form.model === "IG9K - Estendido"}
                    >
                      <option value="">{form.energyStructure[item.variable] || t("params.selectVariable")}</option>
                      {form.varlist.map(variable => (
                        <option key={variable.tag} value={variable.tag}>{variable.varName}</option>
                      ))}
                    </Input>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          }
          <Button color="primary" type="submit">{selectedUnit ? t("params.save") : t("params.add")}</Button>
        </Form>
      </Col>
    </Row>
  );
};
