import { BlobProvider } from '@react-pdf/renderer';
import PdfBillDocument from "./PdfBillDocument";

const PdfBillBlob = ({
  data,
  barChartDemandScreenshot, 
  barChartConsumptionScreenshot, 
  setPdfBlob
}) => (
  <BlobProvider document={<PdfBillDocument 
    data={data}
    barChartDemandScreenshot={barChartDemandScreenshot}
    barChartConsumptionScreenshot={barChartConsumptionScreenshot}
    setPdfBlob={setPdfBlob}
  />}>
    {({blob}) => {
      if (blob) {
        setPdfBlob(blob);
      }
      return null;
    }}
  </BlobProvider>
);

export default PdfBillBlob;
