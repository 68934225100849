import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CardBody, Card, CardHeader, Button } from "reactstrap";
import { PlantConfig } from "./../SubMenu/PlantConfig";
import moment from "moment";
import SelectMonth from "./../SubMenu/components/SelectMonth";

export const NoParamsPlant = ({
  projId,
  projName,
  monthRef,
  params,
  plantData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  async function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <>
      <div
        className="d-flex"
        style={{ position: "absolute", marginTop: "-75px", right: 0 }}
      >
        <div className="px-2 flex-column inputs form-control">
          REF {"  "}
          {monthRef
            ? moment(monthRef).format("MM/YYYY")
            : moment().format("MM/YYYY")}
        </div>
        <div className="px-2">
          <SelectMonth />
        </div>
      </div>

      {(params === 404 || plantData === 0) && (
        <>
          <Card className="text-center p-6 mt-4">
            <CardHeader>{t("noParamsPlant.msg")}</CardHeader>
            <CardBody>
              {localStorage.getItem("userType") !== "3" && (
                <Button
                  color="primary"
                  id={"addProject"}
                  className="cursor-pointer"
                  onClick={() => toggleModal()}
                >
                  {t("noParamsPlant.register")}
                </Button>
              )}
            </CardBody>
          </Card>

          {isModalOpen && (
            <PlantConfig
              open={isModalOpen}
              toggle={toggleModal}
              projName={projName}
              projId={projId}
            />
          )}
        </>
      )}
    </>
  );
};
