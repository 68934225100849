import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Maximize2 } from "react-feather";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Button,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DateRangeSelector } from "../../../../../components/DateRangeSelector";
import { COLOR_CHARTS } from "../../../../../utils/Constants";
import { formatTimestamp } from "../../../../../utils/functions";
import { usePlantContext } from "../PlantProvider";
import { getGeneralConsumptionPlantData } from "../PlantService";

const ENUM_ENTRY_RANGE = {
  today: "minutes",
  last24Hours: "minutes",
  last7Days: "days",
  last30Days: "days",
  last12Months: "months",
  "24Hours": "minutes",
  "7D": "days",
  fullMonth: "days",
  fullYear: "months",
};

export const BarChartConsumptionGeneralPlant = ({
  windowObervable,
  projectId,
  isModal = false,
}) => {
  const { t } = useTranslation();
  const urlParams = useParams();
  const { selectedDate } = usePlantContext();

  const [loadingChart, setLoadingChart] = useState(false);
  const [chart, setChart] = useState(null);
  const [start, setStart] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState(moment().endOf("month").format("YYYY-MM-DD"));
  const [range, setRange] = useState("fullMonth");
  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (selectedDate) {
      setStart(moment(selectedDate).startOf("month").format("YYYY-MM-DD"));
      setEnd(moment(selectedDate).endOf("month").format("YYYY-MM-DD"));
      // setRange("fullMonth");
    }
  }, [selectedDate]);

  const getChartData = useCallback(async (id) => {
    setLoadingChart(true);
    const chart = await getGeneralConsumptionPlantData({
      projId: id,
      start: isFirstRender.current ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss") : start,
      end: isFirstRender.current ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss") : end,
      searchModel: ENUM_ENTRY_RANGE[range],
    });
    setChart(chart);
    setLoadingChart(false);
    isFirstRender.current = false;
  }, [start, end, range]);


  const dates = useMemo(
    () =>
      chart?.arrayConsumption
        ? chart.arrayConsumption.map((data) => data.date)
        : [],
    [chart]
  );
  const peakValues = useMemo(
    () =>
      chart?.arrayConsumption
        ? chart.arrayConsumption.map((data) => data.peak)
        : [],
    [chart]
  );
  const offPeakValues = useMemo(
    () =>
      chart?.arrayConsumption
        ? chart.arrayConsumption.map((data) => data.offPeak)
        : [],
    [chart]
  );

  const data = useMemo(() => {
    if (!dates || !offPeakValues || !peakValues) return []; // Protege contra dados indefinidos

    return dates.map((date, index) => ({
      date: formatTimestamp(date, ENUM_ENTRY_RANGE[range]),
      offPeak: offPeakValues[index] || 0, // Adiciona valor padrão se indefinido
      peak: peakValues[index] || 0, // Adiciona valor padrão se indefinido
    }));
  }, [dates, offPeakValues, peakValues, range]);

  useEffect(() => {
    if (urlParams.id) getChartData(urlParams.id);
  }, [urlParams.id, getChartData]);

 

  // Função para lidar com o clique nas barras do gráfico
  const handleBarClick = (data) => {
    const clickedData = data; // Captura os dados da barra clicada

    if (ENUM_ENTRY_RANGE[range] === "days") {
      const clickedTimestamp = clickedData.date;
      const date = clickedTimestamp.split(" - ")[0];
      const [day, month] = date.split("/");
      const startDay = moment(`${day}/${month}`, "DD/MM")
        .startOf("day")
        .toDate();
      const endDay = moment(`${day}/${month}`, "DD/MM").endOf("day").toDate();

      setRange("24Hours");
      setStart(startDay);
      setEnd(endDay);
    }
    if (ENUM_ENTRY_RANGE[range] === "months") {
      const startMonth = moment(clickedData.date, "MM/YYYY")
        .startOf("month")
        .toDate();
      const endMonth = moment(clickedData.date, "MM/YYYY")
        .endOf("month")
        .toDate();

      setRange("fullMonth");
      setStart(startMonth);
      setEnd(endMonth);
    }
  };

  const renderCustomBar = (props) => {
    const { fill, x, y, width, height, value } = props;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={fill}
          onClick={() => handleBarClick(props, value)}
          style={
            ENUM_ENTRY_RANGE[range] !== "minutes"
              ? { cursor: "pointer" }
              : { cursor: "default" }
          }
        />
      </g>
    );
  };

  return (
    <>
      <CardHeader className="d-flex align-content-center justify-content-between">
        <CardTitle tag={"h5"}>{t("charts.chartConsumption")} </CardTitle>
        {!isModal && (
          <Button
            className="bg-transparent border-0"
            id={"button-maximize-dashboard"}
            onClick={handleToggleModal}
          >
            <Maximize2 size={16} color="grey" />
            <UncontrolledTooltip target={"button-maximize-dashboard"}>
              {t("public.expand")}
            </UncontrolledTooltip>
          </Button>
        )}
      </CardHeader>
      <DateRangeSelector
        loading={loadingChart}
        initDate={start}
        finishDate={end}
        setEntrieStartDate={setStart}
        setEntrieEndDate={setEnd}
        entrieRange={range}
        setEntrieRange={setRange}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            flex: 1,
            height: "100%",
          }}
        >
          <ResponsiveContainer width="100%" height={330}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis tickFormatter={(value) => value.toLocaleString()} />
              <Tooltip
                formatter={(value) => value.toLocaleString()}
                labelFormatter={(label) => `Data: ${label}`}
              />
              <Legend />
              <Bar
                dataKey="offPeak"
                fill={COLOR_CHARTS.offPeak}
                name={t("charts.offPeak")}
                stackId="a"
                shape={renderCustomBar}
              />
              <Bar
                dataKey="peak"
                fill={COLOR_CHARTS.peak}
                name={t("charts.peak")}
                stackId="a"
                shape={renderCustomBar}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </DateRangeSelector>

      {toggleModal && (
        <Modal
          isOpen={toggleModal}
          toggle={handleToggleModal}
          size={windowObervable < 1400 ? "lg" : "xl"}
          style={{
            display: "flex",
          }}
        >
          <ModalBody
            style={{
              maxHeight: windowObervable < 1400 ? "60vh" : "80vh",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <BarChartConsumptionGeneralPlant
              isModal={true}
              projectId={projectId}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
