import axios from 'axios';
import { baseURL } from './baseURL';
import moment from 'moment';

function axiosDefaults() {

  // endereço da API
  axios.defaults.baseURL = baseURL; // ip do desenvolvedor

  /** configurações padrão do axios */
  axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
  axios.defaults.headers['Access-Control-Allow-Origin'] = '*';

  /** idioma de exibição */
  axios.defaults.headers['x-api-lang'] = localStorage.getItem('defaultLanguage');

  /** token e refresh token */
  axios.defaults.headers['x-api-token'] = localStorage.getItem('token');
  axios.defaults.headers['x-api-refreshtoken'] = localStorage.getItem('refreshToken');

  axios.defaults.headers['x-client-timeoffset'] = moment().utcOffset();

  /** Renovação do token */
  axios.defaults.transformResponse = (data, headers) => {
    let dataParse;
    try {
      dataParse = JSON.parse(data);
    } catch (e) {
        // console.log(e);
        dataParse = data;
    }

    // verifica se houve renovação de token
    if (headers['x-api-newtoken']) {
      // armazena novo token
      axios.defaults.headers['x-api-token'] = headers['x-api-newtoken'];
      localStorage.setItem('token', headers['x-api-newtoken']);

      return dataParse;
    }

    // verifica se houve desautenticação (token expirado)
    if (dataParse.expiredToken === true) {
      // usuario sera redirecionado para pagina de login
      localStorage.clear();
      // window.location.reload();

      return dataParse;
    }

    return dataParse;
  };

  // /** Notificação de erro padrão */
  // axios.interceptors.response.use(response => {
  //   console.log(response);
  //   return response;
  // }, err => {
  //   // Any status codes that falls outside the range of 2xx cause this function to trigger
  //   // Do something with response error

  //   if (err.response) {
  //     if (err.response.data.messageHead && err.response.data.messageBody) {
  //       toastr.warning(err.response.data.messageHead, err.response.data.messageBody)
  //     } else if (err.response.status && err.response.statusText) {
  //       toastr.error('Error '+err.response.status, err.response.statusText)
  //     } else {
  //       toastr.error('Error', 'Network error')
  //     }
  //   } else {
  //     toastr.error('Error', 'Network error')
  //   }

  //   return Promise.reject(err);
  // });
};

export default axiosDefaults;