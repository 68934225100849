const enUsGlobalTranslations = {
    settings: {
        title: "User Preferences",
  
        // NOTE: sidebar
        settings: "Preferences",
        account: "Account",
        password: "Password",
  
        // NOTE: profile
        profile: "Update account data",
        avatarText:
          "For best results, use an image at least 128px by 128px in .jpg format",
        username: "Username",
        phone: "Phone number",
  
        // NOTE: password
        changePassword: "Change password",
        currPassword: "Current password",
        newPassword: "New password",
        confirmPassword: "Confirm new password",
  
        // NOTE: notifications
        success: "Success",
        att: "Attention",
        err: "Error",
  
        update: "The information have been updated",
        exists: "Information already exist",
  
        minCharacters: "Passwords must be at least 8 characters long",
        notEqual: "The passwords do not match",
  
        submit: "Save changes",
    },
    userLogs: {
        login: "Login",
  
        // NOTE: Col logs
        id: "User id",
        username: "User name",
        date: "Date of occurred",
        display: "Action",
  
        // NOTE:Table legend
        showing: "Show",
        of: "of",
        results: "Logs",
  
        // NOTE:Search bar
        searchTitle: "Search Logs",
        searchHolder: "Type your search",
  
        // NOTE: Checkboxes
        checktitle: "Log type filter",
  
        // NOTE: Submit button
        submit: "Search",
  
        // NOTE: Priority Badges
        exclude: "delete",
        add: "add",
        edit: "Edition",
  
        // NOTE: Details
        historic: "Not Found",
        cardtitle: "Log tables",
        subtitle1: "Historic",
        subtitle2: "Logs",
        treePlaceholder: "Select the company",
      },
}

export default enUsGlobalTranslations;