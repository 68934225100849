import React, { useEffect, useState } from "react";
import {
  Button,
  Card, CardHeader, CardBody, CardTitle,
  Modal, ModalHeader, ModalBody, ModalFooter, 
  Row, Col,
  Spinner,
} from "reactstrap";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import 'moment/locale/pt-br';

const StatusShow = ({status, item, loading}) => {
  const { i18n, t } = useTranslation();

  let itemTitle = t('gateways.'+item);
  if (itemTitle.startsWith('gateways.')) {
    itemTitle = item;
  }


  let value;
  switch (item) {
    case ('upTime'):
      moment.locale((i18n.language==="br") ? 'br' : 'en');
      value = moment.duration(status[item]*1000)
      .humanize() + ' ' + t('gateways.ago');

      break;

    case ('usedStorage'):      
    case ('usedMem'):
      value = status[item].toFixed(2) + ' %' 

      break;

    case ('connMode'):
      value = (status[item] === 0)
        ? 'Wi-Fi'
        : (status[item] === 1)
          ? 'ETH'
          : (status[item] === 2)
            ? 'CEL'
            : t('gateways.unknown');

      break;

    case ('dhcp'):
      value = (status[item] === 1)
        ? t('gateways.yes')
        : t('gateways.no')

      break;

    case ('rssi'):
      if (status[item] === 'unknown') {
        value = '-'
      }

      else {
        value = status[item] + ' dB'
      }

      break;

    case ('linkSpeed'):
      value = status[item] + ' Mbps'

      break;
    
    default: value = status[item];
  }

  // let titleFontSize = (itemTitle < 12)
  //   ? "13"
  //   : (itemTitle < 15)
  //     ? "13"
  //     : "13";
  let titleFontSize = '13'

  // let valueFontSize = (value.toString().length < 12)
  //   ? "18"
  //   : "13";
  let valueFontSize = '18'

  return (
    <Col>
      <Card style={{minWidth: "135px", maxHeight:"100px", height: "100px", marginBottom: "10px", background:"#dde0e5"}}>
        <CardHeader className="mt-0 mb-0 pb-0">
          <CardTitle tag="h5" 
            style={{
              fontSize: titleFontSize + "px",
              fontStyle: (itemTitle === item) ? 'italic': 'normal'
            }}
          >
            {itemTitle}
          </CardTitle>
        </CardHeader>
        <CardBody className='my-0 pt-0 pb-1 d-flex align-items-end justify-content-center'>
          {(!loading)
            ? <h1 className="display-5 text-center" style={{fontSize: valueFontSize + "px", fontWeight:"500"}}>
                {value}
              </h1>
            : <div className="text-center mb-2">
                <Spinner color="primary" />
              </div>
          }
        </CardBody>  
      </Card>
    </Col>
  );
};

/** Modal de Status de Gateway */
const ModalGatewayStatus = ({open, toggle, gateway}) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState(gateway.status);
  const [loading, setLoading] = useState(false);

  // console.log(gateway);

  useEffect(()=>{
    if (loading) {
      axios.get('/gwStatusSearch', {params: {
        deviceId: gateway.id
      }})
      .then(res => {
        setStatus(JSON.parse(res.data[0].status));
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        console.log(err.response);

        toastr.error("Erro!", "Erro ao atualizar status do Gateway");
        setLoading(false);
      })
    }
  }, [loading, gateway]);

  return (
    <Modal 
      isOpen={open} 
      toggle={toggle} 
      size={'lg'}
    >
      <ModalHeader toggle={toggle}>
        {t('gateways.modalTitle')} {gateway.description}
      </ModalHeader>

      <ModalBody className="overflow-scroll mb-0 pb-0">
        {(status)
          ? <Row md='4'>
              {Object.keys(status).map((item, i) => (
                <StatusShow key={i} 
                  status={status} 
                  item={item} 
                  loading={loading}
                />
              ))}
            </Row>
          : (loading)
            ? <Spinner color="primary" />
            : <>
                {t('gateways.noStatus')}
              </>
        }
      </ModalBody>

      <ModalFooter>
        <Button 
          color="primary" 
          outline 
          disabled={loading}
          onClick={()=>setLoading(true)}
        >
          {t('gateways.refresh')}
        </Button>

        <Button 
          color="primary"
          onClick={()=>toggle()}
        >
          {t('gateways.close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalGatewayStatus;