import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSolarPanel } from '@fortawesome/free-solid-svg-icons';

const PVSystem = ({ devicesOnline, devicesTotal, loading }) => {
  const { t } = useTranslation();

  return (
    <div className="w-100">
      <Row>
        <Col sm="12" style={{paddingRight:"0px"}}>
          <Card style={{marginBottom: "24px", minWidth:"177px", maxHeight:"113px"}}>
            <CardBody>
              <Row>
                <Col className="mt-0">
                  <CardTitle tag="h5" style={{marginBottom: "0px"}}>{t('solar.inverters')}<br />{t('solar.online')}</CardTitle>
                </Col>
                <Col className="col-auto">
                  <div style={{width:"33px",height:"33px"}}>
                    <div className="avatar-title rounded-circle bg-primary-dark">
                      <FontAwesomeIcon icon={faSolarPanel} fixedWidth className="align-middle" />
                    </div>
                  </div>
                </Col>
              </Row>
              {
                (devicesOnline)
                  ? <Row>
                      <Col style={{padding:"0px", display:"flex", justifyContent:"flex-end"}}>
                        <h1 className="display-5 mt-2 mb-4 text-center" style={{fontSize:"26px"}}>{devicesOnline}/{devicesTotal}</h1>
                      </Col>
                      <Col style={{padding:"0px", display:"flex", marginLeft:"8px", marginTop:"10px"}}>
                        <h6 className="display-5 mt-2 mb-4 text-center text-success " style={{fontSize:"16px"}}>{t('solar.online')}</h6>
                      </Col>
                    </Row>
                  : (loading)
                    ? <div className="text-center mb-4"> <Spinner color="primary"/></div>
                      : <p className="mt-3 text-center">{t('devices.noData')}</p>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}   

export default PVSystem;