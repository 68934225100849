import axios from "axios";
import jsPDF from "jspdf";
import moment from "moment";
import { useEffect, useState } from "react";
import { Check } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { SelectMonth } from "../../../../../../components/SelectMonth";
import { formatAvoidedCO2 } from "../../../../../../utils/functions";
import background from "./backgroundPDF.png";
import { PdfViewer } from "./components/PdfViewr";

import { getCompanyLogo } from "../../../../../../utils/domain";

import * as pdfjsLib from "pdfjs-dist/webpack";

export const ModalBillingReport = ({ open, toggle }) => {
  const { t } = useTranslation();
  const projId = window.location.pathname.split("-")[1];

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [prevReports, setPrevReports] = useState([]);
  const [valuesReadFromPDF, setValuesReadFromPDF] = useState({
    leituraAtual: "",
    leituraAnterior: "",
    creditoAcumulado: "",
    creditoExpirar: "",
    consumoCompTUSD: "",
    valorConsumoCompTUSD: "",
    consumoCompTE: "",
    valorConsumoCompTE: "",
    consumo: "",
    geracaoKwh: "",
    valorConsumo: "",
    energiaInjetadaTUSD: "",
    valorEnergiaInjetadaTUSD: "",
    energiaInjetadaTE: "",
    valorEnergiaInjetadaTE: "",
    convenioIluminacaoPublica: "",
    valorConvenioIluminacaoPublica: "",
    totalPagar: "",
  });
  const [viewPdf, setViewPdf] = useState(false);
  const [loading, setLoading] = useState(null);
  const [images, setImages] = useState(null);

  const handleChangeFile = (event) => {
    handleFileChange(event);
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      console.error(t("solar.billingReport.errorPDF"));
    }
    event.target.value = "";
  };

  const handleSelectViewBillingReport = (id) => {
    const data = prevReports.find((r) => r.id === id);
    if (data) viewBillingReport(data);
  };
  const dataURLtoBlob = (dataURL) => {
    const [header, data] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const binary = atob(data);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mime });
  };

  const onSubmit = async () => {
    if(!images) return alert("Selecione um arquivo PDF");
    setLoading(t("solar.billingReport.readingProgress"));
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("imagePDF", dataURLtoBlob(images), "imagePDF.png");

    const { data, status } = await axios
      .post("/v2/solar/readPdfBillingReport", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .finally(() => {
        setLoading(null);
      });

    if (status === 200) {
      setValuesReadFromPDF(data);
      setViewPdf(true);
    }
  };

  const generateBillingReport = async () => {
    setLoading(`${t("solar.billingReport.generatingReport")}...`);
    setViewPdf(false);
    try {
      const formData = new FormData();
      formData.append("pdfFile", selectedFile);
      formData.append("projId", projId);
      formData.append("monthRef", selectedDate);
     

      Object.keys(valuesReadFromPDF).forEach((key) => {
        formData.append(key, valuesReadFromPDF[key]);
      });

      const { data, status } = await axios
        .post("/v2/solar/createBillingReport", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .finally(() => {
          setLoading(null);
        });

      if (status === 200) {
        viewBillingReport(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const viewBillingReport = (data) => {
    const logo = getCompanyLogo();

    const doc = new jsPDF();

    const img = new Image();
    img.src = background;
    img.onload = () => {
      doc.addImage(img, "PNG", 0, 0, 210, 297);

      if (logo) {
        doc.addImage(logo, "PNG", 130, 4, 80, 35);
      }

      const sizeByTotalValue =
        data.pago > 1000 ? 26 : data.pago > 10000 ? 20 : 32;

      doc.setFontSize(18);
      doc.setTextColor(29, 46, 69);
      doc.setFont("Helvetica", "Bold");
      doc.text(`${localStorage.getItem("name").toUpperCase()}`, 5, 19);

      doc.setFont("Helvetica", "Bold");
      doc.setTextColor(29, 46, 69);
      doc.setFontSize(18);
      doc.text(
        `Demonstração ${moment(data.monthRef, "M-YYYY").format("MMMM")}`,
        5,
        26
      );

      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor(29, 46, 69);
      doc.text(
        `Período de leitura ${data.previousReading} a ${data.currentReading}`,
        5,
        32
      );

      doc.setFontSize(sizeByTotalValue);
      doc.setTextColor(255, 255, 255);
      doc.text(String(data.totalValueWithoutSolarEnergy), 50, 83, {
        align: "center",
      });

      doc.setFontSize(sizeByTotalValue);
      doc.setTextColor(255, 255, 255);
      doc.text(String(data.paid), 120, 83, { align: "center" });

      doc.setFontSize(sizeByTotalValue);
      doc.setTextColor(255, 255, 255);
      doc.text(String(data.savings), 175, 83, { align: "center" });

      doc.setFontSize(20);
      doc.setTextColor(255, 255, 255);
      doc.text(String(data.totalConsumption), 28, 127, { align: "center" });

      doc.setFontSize(20);
      doc.setTextColor(255, 255, 255);
      doc.text(String(data.generationExpectation || "0"), 79, 127, {
        align: "center",
      });

      doc.setFontSize(20);
      doc.setTextColor(255, 255, 255);
      doc.text(String(data.generationkWh), 131, 127, { align: "center" });

      doc.setFontSize(20);
      doc.setTextColor(255, 255, 255);
      doc.text(String(data.performance || "0"), 183, 127, {
        align: "center",
      });

      doc.setFontSize(20);
      doc.setTextColor(29, 46, 69);
      doc.text(String(+data.injected * -1), 88, 178, { align: "right" });

      doc.setFontSize(20);
      doc.setTextColor(29, 46, 69);
      doc.text(String(data.consumedFromUtility), 88, 197, { align: "right" });

      doc.setFontSize(20);
      doc.setTextColor(29, 46, 69);
      doc.text(String(data.accumulatedCredit), 88, 216, { align: "right" });

      doc.setFontSize(20);
      doc.setTextColor(29, 46, 69);
      doc.text(String(data.creditExpiration), 88, 234, { align: "right" });

      doc.setFontSize(32);
      doc.setTextColor(29, 46, 69);
      doc.text(String(parseInt(data.cultivatedTrees)), 136, 189, {
        align: "left",
      });

      doc.setFontSize(32);
      doc.setTextColor(29, 46, 69);
      doc.text(`${formatAvoidedCO2(data.avoidedCO2)}`, 136, 225, {
        align: "left",
      });

      setLoading(null);

      var string = doc.output("datauristring");
      setSelectedFile(string);
      setViewPdf(true);
    };
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      const fileReader = new FileReader();
      fileReader.onload = function () {
        const typedArray = new Uint8Array(this.result);
        pdfjsLib.getDocument(typedArray).promise.then(function (pdf) {
          pdf.getPage(1).then(function (page) { // Sempre obter a primeira página
            const viewport = page.getViewport({ scale: 6 });
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = viewport.width;
            canvas.height = viewport.height;
  
            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
  
            page.render(renderContext).promise.then(function () {
              const dataURL = canvas.toDataURL("image/png");
              setImages(dataURL); // Armazena a primeira imagem gerada
            });
          });
        });
      };
      fileReader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
    const getPrevBillingReports = async () => {
      axios
        .get(`/v2/solar/readBillingReport?projId=${projId}`)
        .then((response) => {
          setPrevReports(response.data);
        });
    };
    getPrevBillingReports();
  }, [projId]);

  return (
    <Modal isOpen={open} toggle={toggle} scrollable size="md">
      <ModalHeader toggle={toggle}>
        {t("solar.billingReport.title")}
      </ModalHeader>
      <ModalBody style={{ minHeight: "250px" }}>
        <Row className="m-0 h-100">
          <Col sx={12} md={6}>
            <h5
              className="m-0"
              style={{ maxHeight: "200px", overflow: "auto" }}
            >
              {t("solar.billingReport.prevReport")}
            </h5>
            <ListGroup>
              {prevReports?.map((el, index) => (
                <ListGroupItem
                  key={index}
                  style={{ padding: "2px 0 2px 2px", border: " none" }}
                  className="cursor-pointer"
                  onClick={() => handleSelectViewBillingReport(el.id)}
                >
                  {el.monthRef}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
          <Col
            sx={12}
            md={6}
            style={{ display: "grid", gridTemplateRows: "20px, 1fr" }}
          >
            <h5 className="m-0">{t("solar.billingReport.generateReport")}</h5>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label className="mt-2">
                {t("solar.billingReport.selectMonthRef")}:
              </label>
              <SelectMonth
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
              />

              <div className="mt-3">
                {selectedFile && (
                  <div
                    className="mt-2"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Check color="green" size={12} />
                    <p className="p-0 m-0">{selectedFile?.name}</p>
                  </div>
                )}
                <label htmlFor="files" className="w-100 cursor-pointer">
                  {selectedFile
                    ? t("solar.billingReport.changeFile")
                    : t("solar.billingReport.selectFile")}
                </label>
                <small style={{ color: "GrayText", display: "block" }}>
                  {t("solar.billingReport.onlyPdfFile")}
                </small>
                <input
                  className="d-none"
                  type="file"
                  id="files"
                  accept="application/pdf"
                  onChange={handleChangeFile}
                />
              </div>
            </div>
            <Button
              className="mt-4 w-100"
              style={{ height: "32px" }}
              disabled={(!selectedFile && !images) || loading}
              onClick={onSubmit}
            >
              {loading ?? t("solar.billingReport.generate")}
            </Button>
          </Col>
        </Row>
        {viewPdf && (
          <PdfViewer
            file={selectedFile}
            open={viewPdf}
            toggle={() => {
              setSelectedFile(null);
              setViewPdf(false);
            }}
            changeFile={() => setSelectedFile(null)}
            data={valuesReadFromPDF}
            setData={setValuesReadFromPDF}
            generateBillingReport={generateBillingReport}
          />
        )}
      </ModalBody>
    </Modal>
  );
};
