import React from "react";
import classNames from "classnames";

const defaultProps = {
  tag: "h1"
};

var FontTitle = (window.innerWidth < 400) ? "20px" : "1.575rem";

const HeaderTitle = ({ children, className, tag: Tag, ...rest }) => (
  <Tag className={classNames("header-title", className)} {...rest} style={{fontSize: FontTitle}}>
    {children}
  </Tag>
);

HeaderTitle.defaultProps = defaultProps;

export default HeaderTitle;
