import axios from "axios";

export const updateModulesProject = async ({ projId, userId, modules }) => {
  const request = await axios.put("v2/project/updateModules", {
    projId,
    userId,
    modules
  });

  return request
};
