import React from "react";
import { Card, CardHeader, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThermometerEmpty } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const AmbientTemperature = ({ airTemperature }) => {
  const { t } = useTranslation();
  
  return (
    <div className="w-100">
      <Row >
        <Col sm="12" style={{paddingRight:"0px"}}>
          <Card style={{minWidth: "200px", height: "140px", backgroundColor: "#dde0e5", marginBottom: "18px"}}>
            <CardHeader style={{padding: "25px 25px 21px 25px"}}>
              <Row>
                <Col className="mt-0">
                  <CardTitle tag="h5">{t('solar.ambientTemperature')}</CardTitle>
                </Col>
                <Col className="col-auto" style={{paddingLeft:"0px"}}>
                  <div>
                    <FontAwesomeIcon 
                        icon={faThermometerEmpty} 
                        style={{fontSize:"22px"}}
                        />
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody style={{paddingTop: "0px"}}>
              {(airTemperature || airTemperature === 0)
                ? <h2 className="text-center"> {airTemperature.toFixed(1).replace(".", ",")} °C</h2>
                : <div className="text-center mb-4"><Spinner /></div>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}   

export default AmbientTemperature;