import axios from "axios";
import { toastr } from "react-redux-toastr";

export const listCompanies = async () => {
  try {
    const response = await axios.get("/v2/energy/company/listCompanies", {
      params: {
        powerModuleOn: 1,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const listPlants = async (companyId) => {
  try {
    const response = await axios.get("/v2/project/listProjects", {
      params: {
        companyId,
        powerModuleOn: 1,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const listUcs = async (projId) => {
  try {
    const response = await axios.get(`/v2/energy/listUcs?projId=${projId}&status=ativo`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getConsumptionSharing = async (projId, params) => {
  try {
    const response = await axios.post(
      `/v2/energy/readConsumptionSharing`,
      {
        projId,
        start: params.start,
        end: params.end,
        ucs: params.ucs,
        bill: params.bill,
      }
    );
    if (!response.data?.consumptionSharingUcs?.length) {
      toastr.info(
        "Nenhum dado encontrado",
        "Não há dados para o período selecionado."
      );
    }
    return response.data;
  } catch (error) {
    toastr.error("Erro", "Erro ao buscar dados");
    console.error(error);
  }
};

export const listDevices = async (projId) => {
  try {
    const response = await axios.get(`/v2/energy/listDevices?projId=${projId}&type=energy`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
