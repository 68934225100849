/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { usePlantContext } from "../PlantProvider";
import {
  listDevices,
  readDeviceInfo,
  readParamsRefMeter,
  readPlant,
  readProjEnergyData,
  readReportBillBarChart
  //TODO: Converter captura de gráfico para front-end
  // readReportBillScreenshotConsumption,
  // readReportBillScreenshotDemand,
} from "./RefMeterService";

export const RefMeterContext = createContext();

export const RefMeterProvider = ({ children, projId }) => {
  const { selectedDate } = usePlantContext();
  const { refId } = useParams();

  const [plant, setPlant] = useState(null);
  const [plantData, setPlantData] = useState(null);
  const [dvcId, setDvcId] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [params, setParams] = useState(null);
  const [devices, setDevices] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingPlant, setLoadingPlant] = useState(false);

  //NOTE: Converte captura de gráfico para front-end
  const [barChartDemandScreenshoot, setBarChartDemandScreenshoot] = useState(null);
  const [barChartConsumptionScreenshoot, setBarChartConsumptionScreenshoot] = useState(null);
  const [reportBillBarChart, setReportBillBarChart] = useState(null);

  useEffect(() => {
    if (projId) {
      getPlant(projId);
      getDevices(projId);
      getReportBillBarChart(projId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projId]);

  useEffect(() => {
    if (selectedDate) {
      getParams(projId);
      getEnergyData(projId);
      //TODO: Converter captura de gráfico para front-end
      // getBarChartDemand(projId);
      // getBarChartConsumption(projId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const getEnergyData = async (projId) => {
    const projEnergyData = await readProjEnergyData(
      projId,
      selectedDate,
      setLoadingData
    );
    if (projEnergyData) {
      setPlantData(projEnergyData);
    }
  };

  const getPlant = async (projId) => {
    setLoadingPlant(true);
    const plant = await readPlant(projId);
    setLoadingPlant(false);
    setPlant(plant);
  };

  const getParams = async (projId) => {
    const params = await readParamsRefMeter(projId, selectedDate);
    setParams(params);
    setDvcId(params.refMeter);
  };

  const getDevices = async (projId) => {
    const devices = await listDevices(projId);
    setDevices(devices);
  };

  //TODO: Converter captura de gráfico para front-end
  // const getBarChartDemand = async (projId) => {
  //   setBarChartDemandScreenshoot(null)
  //   const data = await readReportBillScreenshotDemand(
  //     projId,
  //     selectedDate
  //   );
  //   setBarChartDemandScreenshoot(data);
  // };

  //TODO: Converter captura de gráfico para front-end
  // const getBarChartConsumption = async (projId) => {
  //   const barChartConsumption = await readReportBillScreenshotConsumption(
  //     projId,
  //     selectedDate
  //   );
  //   setBarChartConsumptionScreenshoot(barChartConsumption);
  // };

  
  const getReportBillBarChart = async (projId) => {
    const reportBillBarChart = await readReportBillBarChart(
      projId,
      selectedDate
    );
    setReportBillBarChart(reportBillBarChart);
  };

  const getDeviceInfo = async () => {
    if(!refId) return;
    const response = await readDeviceInfo(refId);
    if (response?.status === 200) {
      setDeviceInfo(response.data);
    }
  };

  useEffect(() => {
    if (refId) {
      getDeviceInfo();
    }
  }, [refId]);

  return (
    <RefMeterContext.Provider
      value={{
        plant,
        plantData,
        setPlantData,
        params,
        setParams,
        dvcId,
        loadingData,
        setLoadingData,
        devices,
        loadingPlant,
        //TODO: Converte captura de gráfico para front-end
        barChartDemandScreenshoot,
        barChartConsumptionScreenshoot,
        setBarChartDemandScreenshoot,
        setBarChartConsumptionScreenshoot,
        reportBillBarChart,
        getParams,
        deviceInfo
      }}
    >
      {children}
    </RefMeterContext.Provider>
  );
};

export function useRefMeterContext() {
  const context = useContext(RefMeterContext);
  if (context === undefined) {
    throw new Error(
      "useRefMeterContext must be used within a RefMeterProvider"
    );
  }
  return context;
}
