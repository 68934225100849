import { ConfigProvider, Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Resizable } from "react-resizable";
import { Link } from "react-router-dom";
import {
  Button,
  CardText,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";

import { useQuery } from "@tanstack/react-query";
import enUS from "antd/es/locale/en_US";
import ptBR from "antd/es/locale/pt_BR";
import axios from "axios";
import moment from "moment";
import { Cpu } from "react-feather";

export const OfflinePlantsTable = ({ companyId }) => {
  const {
    status,
    data: reactQuery,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["listRecentlyOfflineProject", { companyId }],
    queryFn: async () => {
      const res = await axios.get(
        `v2/company/listRecentlyOfflineProject?companyId=${companyId}`
      );
      return res.data;
    },
    refetchInterval: 1000 * 60 * 5,
  });

  const plantList = reactQuery || [];

  const { t, i18n } = useTranslation();
  const [columns, setColumns] = useState([
    {
      title: `Planta`,
      dataIndex: "projectName",
      key: "projectName",
      width: window.innerWidth < 768 ? 100 : 150,
      fixed: "left",
      sorter: (a, b) => a.projectName.localeCompare(b.projectName),
      render: (_, record) => <Link>{record.projectName}</Link>,
    },
    {
      title: `Ultima Comunicação`,
      dataIndex: "total_devices",
      key: "total_devices",
      align: "center",
      width: 120,
      sorter: (a, b) => a.last_offline_comm - b.last_offline_comm,
      render: (_, record) =>
        `${moment.unix(record.last_offline_comm).format("lll")}`,
    },
    {
      title: `Devices`,
      dataIndex: "total_devices",
      key: "total_devices",
      align: "center",
      width: 70,
      render: (_, plant) => <Status plant={plant} />,
    },
    {
      title: `Ações`,
      dataIndex: "viewDevices",
      key: "viewDevices",
      align: "center",
      width: 70,
      render: (_, plant) => <Actions plant={plant} />,
    },
  ]);

  const handleResize =
    (index) =>
    (e, { size }) => {
      setColumns((prevColumns) => {
        const nextColumns = [...prevColumns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  if (isFetching) {
    return (
      <div
        className="w-100 d-flex justify-content-center align-items-center"
        sm="12"
        style={{ height: "100%", overflow: "hidden" }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  if (!isFetching && plantList.length === 0) {
    return (
      <div
        className="w-100 d-flex justify-content-center align-items-center flex-column"
        sm="12"
        style={{ height: "100%", overflow: "hidden" }}
      >
        <CardText>{t("noc.error.errorOccurred")}</CardText>
        <Button onClick={refetch}>{t("noc.error.tryAgain")}</Button>
      </div>
    );
  }

  return (
    <div
      className="w-100"
      sm="12"
      style={{ height: "100%", overflow: "hidden" }}
    >
      <ConfigProvider locale={i18n.language === "en" ? enUS : ptBR}>
        <Table
          columns={columns.map((col, index) => ({
            ...col,
            onHeaderCell: (column) => ({
              width: column.width,
              onResize: handleResize(index),
            }),
          }))}
          scroll={{ y: 224 }}
          dataSource={plantList}
          size="small"
          pagination={false}
          components={{
            header: {
              cell: ResizableTitle,
            },
          }}
        />
      </ConfigProvider>
    </div>
  );
};

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const Status = ({ plant }) => {
  const { device_offline, total_devices } = plant;
  return (
    <div className="progress relative">
      <div
        class="progress-bar bg-warning"
        role="progressbar"
        style={{
          width: `${(device_offline / total_devices) * 100}%`,
        }}
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
      <span
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          color: "black",
          fontWeight: "bold",
        }}
      >
        {`${device_offline}/${total_devices}`}
      </span>
    </div>
  );
};

const Actions = ({ plant }) => {
  const { t } = useTranslation();
  const { projectId } = plant;
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => setToggle(!toggle);

  return (
    <>
      <Cpu
        id={"addDevice" + projectId}
        width={16}
        height={16}
        onClick={handleToggle}
      />

      <UncontrolledTooltip placement="top" target={"addDevice" + projectId}>
        Visualizar Dispositivo
      </UncontrolledTooltip>
      {toggle && (
        <ModalListDevices
          isOpen={toggle}
          handleClose={handleToggle}
          projectId={projectId}
        />
      )}
    </>
  );
};

const ModalListDevices = ({ projectId, isOpen, handleClose }) => {
  const {
    status,
    data: reactQuery,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["listRecentlyOfflineProject", { projectId }],
    queryFn: async () => {
      const res = await axios.get(
        `v2/project/listRecentlyOfflineDevice?projId=${projectId}`
      );
      return res.data;
    },
  });
  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <ModalBody className="p-0">
        <DeviceTableSort deviceList={reactQuery} loading={isFetching} />
      </ModalBody>
    </Modal>
  );
};

const DeviceTableSort = ({ deviceList, loading }) => {
  const { t, i18n } = useTranslation();
  const [columns, setColumns] = useState([
    {
      title: `Device`,
      dataIndex: "projectName",
      key: "projectName",
      width: window.innerWidth < 768 ? 100 : 150,
      fixed: "left",
      sorter: (a, b) => a.description.localeCompare(b.description),
      render: (_, record) => <Link>{record.description}</Link>,
    },
    {
      title: `Ultima Comunicação`,
      dataIndex: "lastComm",
      key: "lastComm",
      align: "center",
      width: 120,
      render: (_, record) => `${moment.unix(record.lastComm).format("lll")}`,
    },
    {
      title: `Modelo`,
      dataIndex: "model",
      key: "model",
      align: "center",
      width: 120,
      render: (_, record) => `${record.model}`,
    },
    {
      title: `Fabricante`,
      dataIndex: "manufacturer",
      key: "manufacturer",
      align: "center",
      width: 120,
      render: (_, record) => `${record.manufacturer}`,
    },
    // {
    //   title: `Devices`,
    //   dataIndex: "total_devices",
    //   key: "total_devices",
    //   align: "center",
    //   width: 120,
    //   render: (_, plant) => <Status plant={plant} />,
    // },
    // {
    //   title: `Ações`,
    //   dataIndex: "viewDevices",
    //   key: "viewDevices",
    //   align: "center",
    //   width: 70,
    //   render: (_, plant) => <Actions plant={plant} />,
    // },
  ]);

  const handleResize =
    (index) =>
    (e, { size }) => {
      setColumns((prevColumns) => {
        const nextColumns = [...prevColumns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  return (
    <div className="w-100" sm="12">
      {loading ? (
        <div className="text-center mb-4">
          <Spinner color="primary" />
        </div>
      ) : (
        <ConfigProvider locale={i18n.language === "en" ? enUS : ptBR}>
          <Table
            columns={columns.map((col, index) => ({
              ...col,
              onHeaderCell: (column) => ({
                width: column.width,
                onResize: handleResize(index),
              }),
            }))}
            scroll={{ y: "80vh" }}
            dataSource={deviceList}
            size="small"
            pagination={false}
            components={{
              header: {
                cell: ResizableTitle,
              },
            }}
          />
        </ConfigProvider>
      )}
      {!loading && deviceList.length === 0 && (
        <p className="mt-3 text-center">{t("devices.noData")}</p>
      )}
    </div>
  );
};
