import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import {
  Button,
  CardHeader,
  CardTitle,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { useConsumerUnitContext } from "../ConsumerUnitProvider";
import { useState } from "react";
import { Maximize2 } from "react-feather";
import { COLOR_CHARTS } from "../../../../../../utils/Constants";

export const DonutChartConsumption = ({ windowObervable, isModal = false }) => {
  const { t } = useTranslation();

  const { consumerUnitData, loadingData } = useConsumerUnitContext();
  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  const chart = {
    options: {
      chart: {
        type: "donut",
      },
      colors: [COLOR_CHARTS.peak, COLOR_CHARTS.offPeak],
      legend: {
        position: "bottom",
      },
      total: {
        show: true,
        label: "Total",
        color: "black",
        formatter: function (w) {
          return (
            consumerUnitData?.totalPeakConsumption + consumerUnitData?.totalOffPeakConsumption
          );
        },
      },
      labels: [t("charts.peak"), t("charts.offPeak")],
      tooltip: {
        y: {
          formatter: function (val, opts) {
            return val.toLocaleString();
          },
        },
      },
    },
    series: [
      consumerUnitData?.totalPeakConsumption,
      consumerUnitData?.totalOffPeakConsumption,
    ],
  };

  return (
    <div
      className=" my-2"
      style={{
        width: "100%",
        minHeight: "145px",
      }}
    >
      <CardHeader className="d-flex align-content-center justify-content-between">
        <CardTitle tag="h5" style={{ marginBottom: "0px" }}>
          {t("charts.chartMonthlyConsumption")}
        </CardTitle>
        {(!isModal && consumerUnitData?.totalOffPeakConsumption > 0) && (
          <Button
            className="bg-transparent border-0"
            id={"button-maximize-dashboard"}
            onClick={handleToggleModal}
          >
            <Maximize2 size={16} color="grey" />
            <UncontrolledTooltip target={"button-maximize-dashboard"}>
              {t("public.expand")}
            </UncontrolledTooltip>
          </Button>
        )}
      </CardHeader>

      {loadingData ? (
        <div
          className="d-flex justify-content-center align-items-center h-100 flex-column"
          style={{
            height: windowObervable < 1400 ? "16rem" : "20rem",
            padding: 32,
          }}
        >
          <Spinner />
        </div>
      ) : consumerUnitData?.totalPeakConsumption > 0 ||
        consumerUnitData?.totalOffPeakConsumption > 0 ? (
        <div
          className="d-flex justify-content-center"
          style={{ width: "100%", height: "100%" }}
        >
          <ReactApexChart
            options={chart.options}
            series={chart.series}
            type="donut"
            height="100%"
            width="100%"
          />
          {toggleModal && (
            <Modal isOpen={toggleModal} toggle={handleToggleModal} size={windowObervable < 1400 ? "lg" : "lg"}>
              <ModalBody>
                <DonutChartConsumption isModal={true} />
              </ModalBody>
            </Modal>
          )}
        </div>
      ) : (
        <Row className="d-flex h-100 w-100 justify-content-center align-items-center">
          <Label> {t("charts.noData")}</Label>
        </Row>
      )}
    </div>
  );
};
