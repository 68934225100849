import React from "react";

import { ConfigProvider, Table } from "antd";
import enUS from "antd/es/locale/en_US";
import ptBR from "antd/es/locale/pt_BR";

import { useTranslation } from "react-i18next";
import { useDataExplorer } from "./../DataExplorerProvider";

const DadosTableShow = () => {
  const { i18n } = useTranslation();
  const { dataTable } = useDataExplorer();

  const data = !dataTable ? [] : dataTable.data.rowData;
  const columns = !data.length
    ? []
    : dataTable.data.columns.map((col, index) => ({
        title: col.text.trim(),
        dataIndex: col.dataField,
        key: col.dataField,
        sorter: col.sort
          ? (a, b) => a[col.dataField].localeCompare(b[col.dataField])
          : null,
        render: (text) => (text !== undefined && text !== null ? text : "-"),
        fixed: index === 0 ? "left" : null,
        width: index === 0 ? 200 : 150,
      }));


  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 400px)",
        overflowY: "auto",
        position: "relative",
      }}
    >
      <ConfigProvider locale={i18n.language === 'en' ? enUS : ptBR}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: ["5", "10", "20", "50", "100"],
            hideOnSinglePage: true,
          }}
          rowKey="time"
          scroll={{ x: 100 }}
        />
      </ConfigProvider>
    </div>
  );
};

export default DadosTableShow;
