import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { toastr } from "react-redux-toastr";
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

/****** MODAL DE EDIÇÃO DE DASHBOARD ******/
const ModalExportDash = ({ open, toggle, dash, tableReload }) => {

  const { t } = useTranslation();

  const [urlDomain, setUrlDomain] = useState("");
  const [loadUrl, setLoadUrl] = useState(true);
  const [userDashUrl, setUserDashUrl] = useState();

  const [onEditUrl, setOnEditUrl] = useState(false);
  const [editUrlDomain, setEditUrlDomain] = useState("");

  // console.log(dash);

  useEffect(() => {
    if (loadUrl) {
      axios.get('/getDashboard', {
        params: {
          dashId: dash.dashId
        }
      })
        .then(res => {
          // console.log(res.data[0]);
          if (res.data.length) {
            setUserDashUrl(res.data[0].publicUrl);
          }
          setLoadUrl(false)
        })
        .catch(err => {
          setLoadUrl(false)
          console.log(err);
        })
    }

  }, [loadUrl, dash.dashId]);

  function handleDeleteUrl() {
    toastr.confirm("ATENÇÃO! Esta ação não pode ser desfeita. Tem certeza que deseja continuar?", {
      onOk: () => {
        axios.delete('/deleteExportedUrlDash', {
          params: {
            dashId: dash.dashId
          }
        })
          .then(res => {
            // console.log(res);
            toastr.success(t('dashboards.successDeleteToastTitle'), t('dashboards.successDeleteToastMessage'));
            toggle();
          })
          .catch(err => {
            toastr.warning(t('dashboards.warningToastTitle'), t('dashboards.warningDeleteToastMessage'));
            console.log(err);
          })
      },
      onCancel: () => {
        // console.log("Cancelled");
      }
    })
  }

  function onSubmitEdit(url) {

    const newUrl = `${url}.igoaltecnologia.com`;
    const regex = /\W|_/
    const found = editUrlDomain.match(regex)
    if (found) {
      // console.log(found);
      toastr.warning(t('dashboards.symbolsWarningToastTitle'), t('dashboards.symbolsWarningToastMessage'));
      return
    } else if (editUrlDomain.length < 4) {
      toastr.warning(t('dashboards.symbolsWarningToastTitle'), t('dashboards.smallWarningToastMessage'));
      return
    } else {
      const jsonData = {
        dashId: dash.dashId,
        publicUrl: newUrl
      }
      // console.log('onSubmitEdit', jsonData);
      axios.put('/editExportedUrlDash', JSON.stringify(jsonData))
        .then(res => {
          // console.log(res);
          toastr.success(t('dashboards.successToastTitle'), t('dashboards.successToastMessage'));
          toggle();
        })
        .catch(err => {
          console.log(err);
          toastr.warning(t('dashboards.warningToastTitle'), t('dashboards.warningToastMessage'));
        })
    }
  }

  function onSubmitForm() {
    const url = `${urlDomain}.igoaltecnologia.com`
    const regex = /\W|_/
    const found = urlDomain.match(regex)
    if (found) {
      // console.log(found);
      toastr.warning(t('dashboards.symbolsWarningToastTitle'), t('dashboards.symbolsWarningToastMessage'));
      return
    } else if (urlDomain.length < 4) {
      toastr.warning(t('dashboards.symbolsWarningToastTitle'), t('dashboards.smallWarningToastMessage'));
      return
    } else {
      const jsonData = {
        dashId: dash.dashId,
        publicUrl: url
      };

      /* Envio do dashboard para tabela dashboards_exports */
      axios.post('exportUrlDash/', JSON.stringify(jsonData))
        .then(result => {
          toastr.success(result.data.messageHead, result.data.messageBody);
          // console.log(url);
          window.open(url, '_blank')
          toggle();
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);

          if (err.response.data) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead, err.response.data.messageBody
            );
          }
        })
    }

  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        {t('dashboards.modalHeaderUrl')} {dash.name}
      </ModalHeader>

      {(loadUrl) ?
        <ModalBody
          style={{
            maxHeight: "70vh",
            overflowY: "auto"
          }}
          className="mx-5 "
        >
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        </ModalBody> :

        (userDashUrl)
          ? (!onEditUrl)
            ? <div className="d-flex justify-content-center p-3">
              <div className="align-middle mr-3 cursor-pointer">
                <a className="" href={userDashUrl} target="_black">
                  {userDashUrl}
                </a>
              </div>

              <div
                id={"editUrl" + dash.dashId}
                className="align-middle mr-1 cursor-pointer"
              >
                <FontAwesomeIcon // botão de remover
                  icon={faPen}
                  className="align-middle cursor-pointer"
                  fixedWidth
                  // onClick={onClickEditUrlDomain}
                  onClick={() => {
                    setEditUrlDomain(userDashUrl.slice(0, -20));
                    setOnEditUrl(true);
                  }}
                />
                <UncontrolledTooltip
                  placement="right"
                  target={"editUrl" + dash.dashId}
                >
                  {t('dashboards.editUrl')}
                </UncontrolledTooltip>
              </div>

              <div
                id={"deleteUrl" + dash.dashId}
                className="align-middle mr-1 cursor-pointer"
              >
                <FontAwesomeIcon // botão de remover
                  icon={faTrash}
                  className="align-middle cursor-pointer"
                  fixedWidth
                  onClick={handleDeleteUrl}
                />
                <UncontrolledTooltip
                  placement="right"
                  target={"deleteUrl" + dash.dashId}
                >
                  {t('dashboards.deleteUrl')}
                </UncontrolledTooltip>
              </div>
            </div>
            :
            <Form onSubmit={e => { // editar URL existente
              e.preventDefault();
              onSubmitEdit(editUrlDomain);
            }}>
              <ModalBody
                style={{
                  maxHeight: "70vh",
                  overflowY: "auto"
                }}
                className="mx-5 "
              >
                <Row className="text-center">
                  <Col xs={3} sm={3} md={3}>
                    <Label>
                      {t('dashboards.urlName')}
                    </Label>
                  </Col>
                  <Col className="p-0" xs={1} sm={1} md={1}>
                    <Label>www.</Label>
                  </Col>
                  <Col className="" xs={4} sm={4} md={4}>
                    <Input
                      name="url"
                      type="text"
                      value={editUrlDomain}
                      onChange={e => setEditUrlDomain(e.target.value)}
                    />
                  </Col>
                  <Col xs={3} sm={3} md={3}>
                    <Label>
                      .igoaltecnologia.com
                    </Label>
                  </Col>
                </Row>
                <Row >
                  <Col className="text-center" xs={12} sm={12} md={12}>
                    <Label style={{ color: 'red', marginTop: '1rem' }}>
                      {t('dashboards.alert')}
                    </Label>
                    <Label>
                    </Label>
                  </Col>
                </Row>
                <Row className="text-center">
                  <Col>
                    <Label>
                      {t('dashboards.explanation')}
                    </Label>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                {/*** BOTÃO DE ENVIAR DADOS ***/}
                <Button
                  type='submit'
                  color='primary'
                  className="mr-1 mb-1"
                >
                  {t('dashboards.save')}
                </Button>
              </ModalFooter>
            </Form>
          : <Form onSubmit={e => { // criar URL
            e.preventDefault();
            onSubmitForm();
          }}>
            <ModalBody
              style={{
                maxHeight: "70vh",
                overflowY: "auto"
              }}
              className="mx-5 "
            >
              <Row className="text-center">
                <Col xs={3} sm={3} md={3}>
                  <Label>
                    {t('dashboards.urlName')}
                  </Label>
                </Col>
                <Col className="p-0" xs={1} sm={1} md={1}>
                  <Label>www.</Label>
                </Col>
                <Col xs={4} sm={4} md={4}>
                  <Input
                    name="url"
                    type="text"
                    value={urlDomain}
                    onChange={e => setUrlDomain(e.target.value)}
                  />
                </Col>
                <Col xs={3} sm={3} md={3}>
                  <Label>
                    .igoaltecnologia.com
                  </Label>
                </Col>
              </Row>
              <Row >
                <Col className="text-center" xs={12} sm={12} md={12}>
                  <Label style={{ color: 'red', marginTop: '1rem' }}>
                    {t('dashboards.alert')}
                  </Label>
                  <Label>
                  </Label>
                </Col>
              </Row>
              <Row className="text-center">
                <Col>
                  <Label>
                    {t('dashboards.explanation')}
                  </Label>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {/*** BOTÃO DE CANCELAR *
              * (apenas fecha o modal sem enviar nada para a API) ***/}
              <Button
                type="button"
                color='primary'
                className="mr-1 mb-1"
                onClick={toggle}
                outline
              >
                {t('dataTable.cancel')}
              </Button>
              {/*** BOTÃO DE ENVIAR DADOS ***/}
              <Button
                type='submit'
                color='primary'
                className="mr-1 mb-1"
              >
                {t('dashboards.save')}
              </Button>
            </ModalFooter>
          </Form>
      }
    </Modal >
  );
};

export default ModalExportDash;