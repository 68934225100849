import { useState, useEffect, useLayoutEffect } from "react";

export const useContainerDimensions = myRef => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  // holds the timer for setTimeout and clearInterval
  let movement_timer = null;
  // the number of ms the window size must stay the same size before the
  // dimension state variable is reset
  const RESET_TIMEOUT = 100;
  
  const test_dimensions = () => {
    // For some reason targetRef.current.getBoundingClientRect was not available
    // I found this worked for me, but unfortunately I can't find the
    // documentation to explain this experience
    if (myRef.current) {
      setDimensions({
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeigth
      });
    }
  };
  
  useLayoutEffect(() => {
    test_dimensions();
  }, []);
  
  // every time the window is resized, the timer is cleared and set again
  // the net effect is the component will only reset after the window size
  // is at rest for the duration set in RESET_TIMEOUT.  This prevents rapid
  // redrawing of the component for more complex components such as charts
  window.addEventListener("resize", () => {
    clearInterval(movement_timer);
    movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
  });
  
  // useEffect(() => {
  //   const getDimensions = () => ({
  //     width: myRef.current.offsetWidth,
  //     height: myRef.current.offsetHeight
  //   })

  //   const handleResize = () => {
  //     setDimensions(getDimensions())
  //   }

  //   if (myRef.current) {
  //     setDimensions(getDimensions())
  //   }

  //   window.addEventListener("resize", handleResize)

  //   return () => {
  //     window.removeEventListener("resize", handleResize)
  //   }
  // }, [myRef])

  return dimensions;
};