const enUsNoBreakModuleTranslations = {
    noBreak: {
        deviceList: "Device List",
        inputs: "Input",
        outputs: "Output",
        frequency: "Frequency",
        tension: "Tension",
        capacity: "Use capacity",
        current: "Current",
        battery: "Battery",
        timeOfAutonomy: "Time of autonomy",
        mode: "Operation mode",
        temperature: "Temperature",
        chargeLeft: "Charge left",
        normalMode: "Normal",
        failMode: "Fail",
        totalDevices: "Devices total",
        normalOperation: "Normal operation",
        alarms: "Alarms",
        deviceInfo: "Device information",
        inputGraph: "Input graphic",
        batteryGraph: "Battery graphic",
        outputGraph: "Output graphic",
        noComm: "No communication",
        batteryOperation: "Battery mode",
        byPassOperation: "By pass mode",
    },
}

export default enUsNoBreakModuleTranslations;