import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Zap } from "react-feather";
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';

const TotalWeeklyEnergy = ({ weeklyEnergy, loading }) => {
  const { t } = useTranslation();

  var weeklyEnergy2 = Math.trunc(weeklyEnergy)
  var weeklyEnergy2String = weeklyEnergy2.toString()
  
  var weeklyEnergyEnergyAndUnit = (weeklyEnergy2String.length <= 3)
    ? (weeklyEnergy.toFixed(2)).replace(".", ",") + " kWh"
      : (weeklyEnergy2String.length <= 6)
        ? (parseFloat((weeklyEnergy/1000).toFixed(2))).toString().replace(".", ",") + " MWh"
          : (weeklyEnergy2String.length <= 9)
            ? (parseFloat((weeklyEnergy/1000000).toFixed(2))).toString().replace(".", ",") + " GWh"
              : (weeklyEnergy2String.length <= 12)
                ? (parseFloat((weeklyEnergy/1000000000).toFixed(2))).toString().replace(".", ",") + " TWh"
                : ""
  const labels = t('solar.weeklyEnergyLabel').split(" ")
  return (
    <div className="w-100">
      <Row>
        <Col sm="12" style={{paddingRight:"0px"}}>
          <Card style={{marginBottom: "24px", minWidth:"177px", maxHeight:"113px"}}>
            <CardBody>
              <Row>
                <Col className="mt-0">
                  <CardTitle tag="h5" style={{marginBottom: "0px"}}>{labels[0]}<br></br>{labels[1]}</CardTitle>
                </Col>
                <Col className="col-auto">
                  <div style={{width:"33px",height:"33px"}}>
                    <div className="avatar-title rounded-circle bg-primary-dark">
                      <Zap className="feather align-middle" />
                    </div>
                  </div>
                </Col>
              </Row>
              {
                (weeklyEnergy)
                    ? <h1 className="display-5 mt-2 mb-4 text-center" style={{fontSize:"26px"}}> {weeklyEnergyEnergyAndUnit}</h1>
                      : (loading)
                        ? <div className="text-center mb-4"> <Spinner color="primary"/></div>
                          : <p className="mt-3 text-center">{t('devices.noData')}</p>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}   

export default TotalWeeklyEnergy;