import ModalPDF from "./ModalPdf";
import ModalReportManagerEvent from "./ModalReportManager";
import ModalXls from "./ModalXls";

import { useAPI } from "../SolarProvider";
import { ModalBillingReport } from "./ModalBillingReport";

export const ModalSelectReport = ({
  jsonData,
  getDataToSend,
  projectName,
  monthlyDataUsage,
  opacity,
  monthlyUsageScreenshot,
  pdfScreenshot,
  data,
  projectId,
}) => {
  const { handleToggleModal, toggleModal } = useAPI();

  const handleCloseModal = () => handleToggleModal(false);

  return (
    <>
      {toggleModal === "modalPDF" ? (
        <ModalPDF
          jsonData={jsonData}
          getDataToSend={getDataToSend}
          projectName={projectName}
          monthlyDataUsage={monthlyDataUsage}
          opacity={opacity}
          monthlyUsageScreenshot={monthlyUsageScreenshot}
          pdfScreenshot={pdfScreenshot}
          open={toggleModal === "modalPDF"}
          toggle={handleCloseModal}
          data={data}
          projectId={projectId}
        />
      ) : null}

      {toggleModal === "modalXLS" && projectId && projectName ? (
        <ModalXls
          open={toggleModal === "modalXLS"}
          toggle={handleCloseModal}
          data={data}
          projectName={projectName}
          projectId={projectId}
        />
      ) : null}
      {toggleModal === "modalReportManager" ? (
        <ModalReportManagerEvent
          open={toggleModal === "modalReportManager"}
          toggle={handleCloseModal}
        />
      ) : null}

      {toggleModal === "modalBillingReport" ? (
        <ModalBillingReport
          open={toggleModal === "modalBillingReport"}
          toggle={handleCloseModal}
        />
      ) : null}
    </>
  );
};

export default ModalSelectReport;
