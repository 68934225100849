import axios from "axios";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle
} from "reactstrap";
import { ProjectDetails } from "./components/ProjectDetails";
import { fetchGetWitchParams } from "../../../../../../../NocService";
import { useTranslation } from "react-i18next";

export const WaterAndGasModule = ({ item }) => {
  const {t} = useTranslation();
  const url = `v2/water/readGeneralData`;
  const params = {
    projectId: item.projectId,
    selectedMonthRef: new Date(),
    searchReport: true,
  };

  const [projectsData, setProjectsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();

    fetchGetWitchParams(source, setProjectsData, setError, setLoading, url, params);

    return () => {
      source.cancel();
    };
  }, []);

  if (loading) {
    return <Skeleton style={{ width: "100%", height: "100px" }} />;
  }

  const handleReload = () => {
    setLoading(true);
    setError(null);
    const source = axios.CancelToken.source();
    fetchGetWitchParams(source, setProjectsData, setError, setLoading, url, params);
  };

  if (error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{t("noc.error.errorOccurred")}</CardTitle>
        </CardHeader>
        <CardBody>
          <Button onClick={handleReload}> {t("noc.error.tryAgain")}</Button>
        </CardBody>
      </Card>
    );
  }

  return <ProjectDetails project={projectsData} />;
};



