import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CardBody, CardHeader, CardTitle, Spinner } from "reactstrap";

import { useQuery } from "@tanstack/react-query";
import { Modal, DatePicker } from "antd";
import axios from "axios";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { queryClient } from "../../../../../App";
import { DateRangeSelector } from "../../../../../components/DateRangeSelector";
import { COLOR_SOLAR } from "../../../../../utils/Constants";
import { formatEnergy } from "../../../../../utils/functions";
import useChartHeight from "../hook";

const { RangePicker, MonthPicker, WeekPicker } = DatePicker;
const ENUM_ENTRY_RANGE = {
  today: "minutes",
  last24Hours: "minutes",
  last7Days: "days",
  last30Days: "days",
  last12Months: "months",
  "24Hours": "minutes",
  "7D": "days",
  fullMonth: "days",
  fullYear: "months",
};

const ModalCompare = ({ isOpen, handleClose, handleSearch }) => {
  const { t } = useTranslation();
  const [selectOption, setSelectOption] = useState("month");
  const [firstDate, setFirstDate] = useState({
    energy: [],
  });
  const [secondDate, setSecondDate] = useState({
    energy: [],
  });
  const options = ["day", "week", "month"];

  const handleOk = () => {
    handleSearch({ firstDate, secondDate, selectOption }); // Chame a função handleSearch com as datas selecionadas
    handleClose(); // Feche o modal após a busca
  };

  const renderDatePickers = () => {
    const PickerComponent =
      selectOption === "month"
        ? MonthPicker
        : selectOption === "week"
        ? WeekPicker
        : DatePicker;

    return (
      <>
        <PickerComponent
          onChange={setFirstDate}
          getPopupContainer={(trigger) => trigger.parentNode}
          format={
            selectOption === "month"
              ? "MM/YYYY"
              : selectOption === "day"
              ? "DD/MM/YYYY"
              : undefined
          }
        />
        <PickerComponent
          onChange={setSecondDate}
          getPopupContainer={(trigger) => trigger.parentNode}
          format={
            selectOption === "month"
              ? "MM/YYYY"
              : selectOption === "day"
              ? "DD/MM/YYYY"
              : undefined
          }
        />
      </>
    );
  };

  return (
    <Modal open={isOpen} onCancel={handleClose} footer={null}>
      <div className="d-flex align-items-center justify-content-center">
        {options.map((option) => (
          <Button
            key={option}
            color="primary"
            onClick={() => setSelectOption(option)}
            style={{
              marginRight: "8px",
              filter: selectOption === option ? "brightness(0.8)" : "none",
            }}
          >
            {t(`solar.compare.${option}`)}
          </Button>
        ))}
      </div>

      <div
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: "16px", gap: "8px" }}
      >
        {renderDatePickers()}
      </div>

      <div style={{ marginTop: "16px", textAlign: "right" }}>
        <Button
          onClick={handleClose}
          color="secondary"
          style={{ marginRight: "8px" }}
        >
          Cancelar
        </Button>
        <Button type="primary" color="primary" onClick={handleOk}>
          Buscar
        </Button>
      </div>
    </Modal>
  );
};

const EnergyChart = ({
  hours = [],
  firstEnergy = [],
  firstIrradiation = [],
  secondEnergy = [],
  secondIrradiation = [],
  compare = false,
  firstDataLegend = "",
  secondDataLegend = "",
}) => {
  const { t } = useTranslation();

  const data = hours.map((hour, index) => ({
    time: hour.toString().padStart(2, "0"),
    energy: firstEnergy[index] || 0,
    irradiation: firstIrradiation[index] || 0,
    ...(compare && {
      energy2: secondEnergy[index] || 0,
      irradiation2: secondIrradiation[index] || 0,
    }),
  }));

  const minEnergy = 0;
  const maxEnergy = Math.max(...firstEnergy, ...(compare ? secondEnergy : []));
  const minIrradiation = 0;
  const maxIrradiation = Math.max(...firstIrradiation, ...(compare ? secondIrradiation : []));

  const { dailyEnergyGenerated, irradiationWm2, energyAccumulated, energyForecasted } = COLOR_SOLAR;
  const secondaryEnergyColor = "#42A5F5";
  const secondaryIrradiationColor = "#42A5F5";
  const height = useChartHeight();

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart data={data}>
        <defs>
          <linearGradient id="colorEnergy" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={dailyEnergyGenerated} stopOpacity={0.6} />
            <stop offset="95%" stopColor={dailyEnergyGenerated} stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorIrradiation" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={irradiationWm2} stopOpacity={0.6} />
            <stop offset="95%" stopColor={irradiationWm2} stopOpacity={0} />
          </linearGradient>
          {compare && (
            <>
              <linearGradient id="colorEnergy2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={secondaryEnergyColor} stopOpacity={0.6} />
                <stop offset="95%" stopColor={secondaryEnergyColor} stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorIrradiation2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={secondaryIrradiationColor} stopOpacity={0.6} />
                <stop offset="95%" stopColor={secondaryIrradiationColor} stopOpacity={0} />
              </linearGradient>
            </>
          )}
        </defs>

        <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
        <XAxis dataKey="time" />

        <YAxis
          yAxisId="left"
          orientation="left"
          domain={[minEnergy, maxEnergy]}
          tickFormatter={formatEnergy}
          width={50}
          axisLine={false}
          tickLine={false}
        />

        <YAxis
          yAxisId="right"
          orientation="right"
          domain={[minIrradiation, maxIrradiation]}
          tickFormatter={(value) => `${value} W/m²`}
          width={50}
          axisLine={false}
          tickLine={false}
        />

        <Tooltip
          formatter={(value, name) => [
            name.includes(t("solar.energyLabel")) ? formatEnergy(value) : value,
            name.includes(t("solar.energyLabel"))
              ? `${t("solar.energyLabel")}`
              : `${t("solar.irradiation")} (W/m²)`,
          ]}
          labelFormatter={(label) => `${label}`}
          cursor={{ fill: "#E0E0E0", opacity: 0.1 }}
        />

        <Legend verticalAlign="bottom" height={36} />

        <Bar
          yAxisId="left"
          type="monotone"
          dataKey="energy"
          name={`${firstDataLegend} ${t("solar.energyLabel")}`}
          // stroke={energyAccumulated}
          fill={energyAccumulated}
          fillOpacity={0.6}
          // strokeWidth={2}
          dot={false}
        />

        {/* <Line
          yAxisId="right"
          type="monotone"
          dataKey="irradiation"
          name={`${firstDataLegend} ${t("solar.irradiation")}`}
          stroke={irradiationWm2}
          strokeWidth={2}
          dot={false}
        /> */}

        {compare && (
          <>
            <Area
              yAxisId="left"
              type="monotone"
              dataKey="energy2"
              name={`${secondDataLegend} ${t("solar.energyLabel")} - Comparação`}
              stroke={secondaryEnergyColor}
              fill="url(#colorEnergy2)"
              fillOpacity={0.3}
              strokeWidth={2}
              dot={false}
            />

            <Line
              yAxisId="right"
              type="monotone"
              dataKey="irradiation2"
              name={`${secondDataLegend} ${t("solar.irradiation")} - Comparação`}
              stroke={secondaryIrradiationColor}
              strokeOpacity={0.6}
              strokeWidth={2}
              dot={false}
            />
          </>
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};



const CurrentDayPower = ({ projectId, monthRef }) => {
  const { t } = useTranslation();
  const [start, setStart] = useState(
    moment(new Date()).startOf("day").toDate()
  );
  const [end, setEnd] = useState(moment(new Date()).endOf("day").toDate());
  const [range, setRange] = useState("24Hours");

  const [timesData, setTimesData] = useState(["00:00"]);
  const [firstData, setFirstData] = useState(null);
  const [secondData, setSecondData] = useState(null);
  
  const [firstDataLegend, setFirstDataLegend] = useState("");
  const [secondDataLegend, setSecondDataLegend] = useState("");

  const [compare, setCompare] = useState(false);
  const [handleModal, setHandleModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToogleModal = () => setHandleModal(!handleModal);

  const { data, isFetching } = useQuery({
    queryKey: [
      "solarReadDataChartByTimestamp",
      { projId: projectId, start, end, range },
    ],
    queryFn: () =>
      axios
        .get("v2/solar/readDataChartByTimestamp", {
          params: {
            projId: projectId,
            start,
            end,
            range: ENUM_ENTRY_RANGE[range],
          },
        })
        .then((res) => res.data),
    staleTime: 1000 * 60 * 60, // 1 hour
    refetchInterval: 1000 * 60 * 60, // 1 hour
  });

  const handleCompare = async ({ firstDate, secondDate, selectOption }) => {
    setLoading(true);
    const initUnix = moment(firstDate).unix();
    const finalUnix = moment(secondDate).unix();

    try {
      const response = await queryClient.ensureQueryData({
        queryKey: [
          "v2/solar/compareData",
          { projId: projectId, initialDate: firstDate, finalDate: secondDate },
        ],
        queryFn: () =>
          axios
            .get("v2/solar/compareData", {
              params: {
                projId: projectId,
                initialDate: initUnix,
                finalDate: finalUnix,
                type: selectOption,
              },
            })
            .then((res) => res.data),
      });

      if (selectOption === "month") {
        let firstLegend = moment(firstDate).format("M-YYYY");
        let secondLegend = moment(secondDate).format("M-YYYY");
      } else if (selectOption === "week") {
        let firstLegend = moment.unix(initUnix).week();
        let secondLegend = moment.unix(finalUnix).week();
      } else {
        let firstLegend = moment(firstDate).format("DD/MM/YYYY");
        let secondLegend = moment(secondDate).format("DD/MM/YYYY");

        setFirstDataLegend(firstLegend);
        setSecondDataLegend(secondLegend);

        setTimesData(
          response[firstLegend].times.length >
            response[secondLegend].times.length
            ? response[firstLegend].times
            : response[secondLegend].times
        );
        setFirstData(response[firstLegend]);
        setSecondData(response[secondLegend]);
      }

      setCompare(true);
    } catch (error) {
      console.log(error);
      toastr.warning("Erro!", "Erro ao buscar dados do mês anterior.");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (monthRef) {
  //     setStart(moment(monthRef).startOf("month").toDate());
  //     setEnd(moment(monthRef).endOf("day").toDate());
  //   }
  // }, [monthRef]);

  return (
    <>
      <CardHeader className="d-flex align-items-center justify-content-between mr-4" style={{height: "fit-content", paddingBottom: 0}}>
        <CardTitle tag="h5">{`${t("solar.energyLabel")}`}</CardTitle>
        {/* <Button
          color="primary"
          onClick={handleToogleModal}
          disabled={loading}
          size="small"
        >
          {loading ? <Spinner size="sm" /> : t("Comparar")}
        </Button> */}
      </CardHeader>

      <CardBody className="p-0">
        {isFetching ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <DateRangeSelector
            loading={isFetching}
            initDate={start}
            finishDate={end}
            setEntrieStartDate={setStart}
            setEntrieEndDate={setEnd}
            entrieRange={range}
            setEntrieRange={setRange}
          >
            <EnergyChart
              hours={!compare ? data.times : timesData}
              firstEnergy={!compare ? data.energy : firstData?.energy || []}
              firstIrradiation={
                !compare ? data.irradiation : firstData?.irradiation || []
              }
              secondEnergy={!compare ? [] : secondData?.energy || []}
              secondIrradiation={!compare ? [] : secondData?.irradiation || []}
              firstDataLegend={firstDataLegend}
              secondDataLegend={secondDataLegend}
              compare={compare}
            />
          </DateRangeSelector>
        )}
        {handleModal && (
          <ModalCompare
            isOpen={handleModal}
            handleClose={handleToogleModal}
            handleSearch={handleCompare}
          />
        )}
      </CardBody>
    </>
  );
};

export default CurrentDayPower;
