import React, { createContext, useContext, useEffect, useState } from "react";
import { usePlantContext } from "../PlantProvider";
import {
  readDeviceInfo,
  readUc,
  readUcData
} from "./ConsumerUnitService";

export const ConsumerUnitContext = createContext();

export const ConsumerUnitProvider = ({ children, deviceId }) => {
  const { selectedDate } = usePlantContext();

  const [consumerUnitData, setConsumerUnitData] = useState(null);
  const [consumerUnit, setConsumerUnit] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingUc, setLoadingUc] = useState(false);

  const getUc = async () => {
    setLoadingUc(true);
    const data = await readUc(
      deviceId
    );
    setConsumerUnit(data);
    setLoadingUc(false);
  };

  const getUcData = async () => {
    setLoadingUc(true);
    const data = await readUcData(
      deviceId,
      selectedDate
    );
    setConsumerUnitData(data);
    setLoadingUc(false);
  };

  const getDeviceInfo = async () => {
    if(!deviceId) return;
    const response = await readDeviceInfo(deviceId);
    if (response?.status === 200) {
      setDeviceInfo(response.data);
    }
  };

  useEffect(() => {
    if(deviceId) {
      getUcData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, selectedDate]);

  useEffect(() => {
    if(deviceId) {
      getUc();
      getDeviceInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  return (
    <ConsumerUnitContext.Provider
      value={{
        consumerUnitData,
        consumerUnit,
        getUc,
        loadingData,
        setLoadingData,
        loadingUc,
        deviceId,
        deviceInfo
      }}
    >
      {children}
    </ConsumerUnitContext.Provider>
  );
};

export function useConsumerUnitContext() {
  const context = useContext(ConsumerUnitContext);
  if (context === undefined) {
    throw new Error("useConsumerUnitContext must be used within a ConsumerUnitProvider");
  }
  return context;
}
