import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import '../../igCards';
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { toastr } from "react-redux-toastr";

import MqttControlPreview from "../cards/MqttControlPreview";

const NewMqttControl = ({ cardType, cardProps }) => {
  const { t } = useTranslation();

  const [isEditLoading, setEditLoading] = useState(false); // salvando edição
  const [name, setName] = useState(cardProps?.name ? cardProps?.name : ""); // campo de nome

  const [jsonString, setJsonString] = useState(
    cardProps?.configMqtt
      ? JSON.stringify(JSON.parse(cardProps.configMqtt).json, null, 2)
      : `{
    "name": " ",
    "payload": {
      "hash": " ",
      "ModbusDvc": " ",
      "ModbusAddr": " ",
      "ModbusData": " ",
      "ModbusFunc": " "
    }
  }`
  );

  const [topicHash, setTopicHash] = useState(
    cardProps?.configMqtt
      ? JSON.stringify(JSON.parse(cardProps.configMqtt).topic, null, 2).replaceAll('"', "")
      : ""
  );

  const [description, setDescription] = useState(
    cardProps?.description ? cardProps?.description : ""
  ); // campo de descricao

  const [disableDecimalNumber, setDisableDecimalNumber] = useState();

  let history = useHistory();

  function retrieveDataFromPreviewCard(data) {
    setDisableDecimalNumber(Number(data));
    console.log("data", disableDecimalNumber);
  }

  function clearJson(json) {
    let jsonObject = JSON.parse(json);

    for (let key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (typeof jsonObject[key] === "string") {
          console.log("jsonObject[key]", jsonObject[key]);
          jsonObject[key] = jsonObject[key].trim();
        }
        if (typeof jsonObject[key] === "object") {
          console.log("jsonObject[key]", jsonObject[key]);
          clearJson(JSON.stringify(jsonObject[key]));
        }
      }
    }

    return jsonObject;
  }

  function onSubmitForm() {
    setEditLoading(true);

    if (name.length >= 50) {
      return toastr.warning("Cuidado!", "Título muito extenso.");
    }

    const whArr = [3, 6];

    const formattedJson = clearJson(jsonString);

    const jsonData = {
      userId: localStorage.getItem("userId"),
      dashId: localStorage.getItem("dashId"),
      cardType: cardType,
      name: name,
      description: description,
      devices: null,
      variables: null,
      period: null,
      reloadTime: null,
      w: whArr[0],
      h: whArr[1],
      rangeStructure: null,
      decimalNumber: null,
      formula: null,
      minValueProp: null,
      maxValueProp: null,
      configMqtt: {
        topic: topicHash,
        json: formattedJson,
      },
    };

    /*** Envio de dados ***/
    axios
      .post("/v2/dashboard/addCard", jsonData)
      .then((result) => {
        toastr.success(result.data.messageHead, result.data.messageBody);
        history.push("../dashboardView");
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        if (err.response.data) {
          /* Notificação de erro de envio */
          toastr.warning(
            err.response.data.messageHead,
            err.response.data.messageBody
          );
        }
      })
      .finally(() => {
        setEditLoading(false);
      });
  }

  async function handleUpdateCard(cardId) {
    setEditLoading(true);

    setTimeout(() => {
      setEditLoading(false);
    }, 3000);

    if (name.length >= 50) {
      return toastr.warning("Cuidado!", "Título muito extenso.");
    }

    const whArr = [3, 6];

    const formattedJson = clearJson(jsonString);
    console.log("formattedJson", formattedJson)

    const jsonData = {
      userId: localStorage.getItem("userId"),
      idCard: cardId,
      dashId: localStorage.getItem("dashId"),
      cardType: cardType,
      name: name,
      description: description,
      devices: null,
      variables: null,
      period: null,
      reloadTime: null,
      w: whArr[0],
      h: whArr[1],
      rangeStructure: null,
      decimalNumber: null,
      formula: null,
      minValueProp: null,
      maxValueProp: null,
      configMqtt: {
        topic: topicHash,
        json: formattedJson,
      },
    };
    // return console.log("jsonData", jsonData);
    /*** Envio de dados ***/
    await axios
      .put("/v2/dashboard/updateCard", jsonData)
      .then((result) => {
        toastr.success(result.data.messageHead, result.data.messageBody);
        setEditLoading(false);
        history.push("../../dashboardView");
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        setEditLoading(false);
        // console.log("catch", isEditLoading);
        if (err.response.data) {
          /* Notificação de erro de envio */
          toastr.warning(
            err.response.data.messageHead,
            err.response.data.messageBody
          );
          // console.log("if", isEditLoading);
        }
      });
  }

  if (localStorage.getItem("userType") !== "1")
    history.push("../../dashboardView");

  return (
    <Row xs={1} xl={2}>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">{t("dashboards.cardOptions")}</CardTitle>
          </CardHeader>

          <CardBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitForm();
              }}
            >
              <>
                {/* Nome */}
                <FormGroup>
                  <Label>{t("dashboards.name")}</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t("dashboards.name")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>

                {/* Descrição */}
                <FormGroup>
                  <Label>{t("dashboards.description")}</Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder={t("dashboards.description")}
                    rows="3"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormGroup>

                {/* Hash */}
                <FormGroup>
                  <Label>{t("dashboards.topic")}</Label>
                  <Input
                    type="text"
                    name="topic"
                    id="topic"
                    placeholder={t("dashboards.topic")}
                    value={topicHash}
                    onChange={(e) => setTopicHash(e.target.value)}
                  />
                </FormGroup>

                {/* JSON */}
                <FormGroup>
                  <Label>Json</Label>
                  <Input
                    type="textarea"
                    name="json"
                    id="json"
                    placeholder={"Json"}
                    rows="8"
                    value={jsonString}
                    onChange={(e) => setJsonString(e.target.value)}
                  />
                </FormGroup>
              </>

              {/* Botão de salvar */}
              <div className="text-right">
                <Link to="/dashboardView">
                  <Button
                    name="btnCancel"
                    color="primary"
                    className="mr-2"
                    outline
                  >
                    {t("dashboards.cancel")}
                  </Button>
                </Link>
                {cardProps?.id ? (
                  <Button
                    color="primary"
                    type="button"
                    disabled={isEditLoading}
                    style={{ minWidth: 80 }}
                    onClick={() => handleUpdateCard(cardProps.id)}
                  >
                    {isEditLoading === true ? (
                      <Spinner size={"sm"} />
                    ) : (
                      t("Atualizar")
                    )}
                  </Button>
                ) : (
                  <Button
                    disabled={isEditLoading}
                    style={{ minWidth: 80 }}
                    color="primary"
                    type="submit"
                  >
                    {isEditLoading === true ? (
                      <Spinner size={"sm"} />
                    ) : (
                      t("dashboards.save")
                    )}
                  </Button>
                )}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>

      <Col>
        <Card>
          <MqttControlPreview
            retrieveDataFromPreviewCard={retrieveDataFromPreviewCard}
            cardType={cardType}
            cardProps={cardProps}
            name={name}
            description={description}
            preview={true}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default NewMqttControl;
