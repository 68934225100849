import classnames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { DataTable } from "../../../../../components/DataTable";
import {
  ConsumptionSharingProvider,
  useConsumptionSharingContext,
} from "./ConsumptionSharingProvider";
//TODO: Adicionar gráfico para rateio de consumo
// import { BarChartConsumption } from "./components/BarChartConsumption";
import FormParams from "./components/FormParams";

export const ConsumptionSharingWithProvider = () => {
  return (
    <ConsumptionSharingProvider>
      <ConsumptionSharing />
    </ConsumptionSharingProvider>
  );
};

export const ConsumptionSharing = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(2);
  const { dataTableColumns, dataTableRows } = useConsumptionSharingContext();

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <>
      <Card className="mt-4">
        <FormParams />
        <Nav
          tabs
          className="card-header-tabs"
          style={{ marginLeft: "0.5rem", position: "sticky" }}
        >
          {/* <NavItem style={{ zIndex: "1000" }}>
            <NavLink
              className={classnames({ active: activeTab === 1 })}
              onClick={() => {
                toggle(1);
              }}
              href="#"
            >
              {t("home.graphics")}
            </NavLink>
          </NavItem> */}

          <NavItem style={{ zIndex: "1000" }}>
            <NavLink
              className={classnames({ active: activeTab === 2 })}
              onClick={() => {
                toggle(2);
              }}
              href="#"
            >
              {t("homeSharing.dataTable")}
            </NavLink>
          </NavItem>
        </Nav>
        {/*
        //TODO: Adicionar gráfico para rateio de consumo
        <TabContent activeTab={activeTab} className="p-4">
          <TabPane tabId={1}>
            <Row>
              <Col xs="12" className="text-center">
                  <BarChartConsumption /> 
              </Col>
            </Row>
          </TabPane>
        </TabContent>
        */}
        <TabContent activeTab={activeTab} className="p-4">
          <TabPane tabId={2}>
            <Row>
              <Col xs="12" className="text-center">
                {dataTableColumns.length || dataTableRows.length ? (
                  <DataTable
                    columns={dataTableColumns}
                    rows={dataTableRows || []}
                  />
                ) : (
                  <Card>{t("charts.noData")}</Card>
                )}
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Card>
    </>
  );
};
