import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ptBR from 'date-fns/locale/pt-BR';
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import {
    Badge, 
    Breadcrumb, BreadcrumbItem,
    Button, ButtonGroup, ButtonToolbar,
    Card, CardBody, CardHeader, CardTitle,
    Container,
    Form, FormGroup, Input, Label,
    Row, 
    Col,
    Spinner,
    //UncontrolledTooltip
    
  } from "reactstrap";
  import {ChevronRight} from "react-feather";
  import Header from "../../../components/Header";
  import HeaderTitle from "../../../components/HeaderTitle";
  import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { 
    //faBell,
    //faBellSlash,
    faCaretDown,
    faCaretUp,
    //faSearch,
    faSort,
  } from "@fortawesome/free-solid-svg-icons";
import { TreeSelect } from "antd";

  const PriorityBadge = ({ priority }) => {
    const { t } = useTranslation();
    return (priority === 1)
      ? <Badge color="info">{t('userLogs.exclude')}</Badge>
      : (priority === 2) 
        ? <Badge color="primary">{t('userLogs.edit')}</Badge>
        :(priority === 3)
        ? <Badge color="dark">{t('userLogs.add')}</Badge>
        :<Badge color="success">{t('userLogs.login')}</Badge>
  };
// Buscando logs da API
const LogDataTable = () =>{

  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const { SHOW_PARENT } = TreeSelect;
  const [treeValue, setTreeValue] = useState([]);
  const [loadLogs, setLoadLogs]= useState(true);
  const [logs, setLogs] = useState([]);
  const [isMore, setMore] = useState(false);
  //const [active, setActive] = useState(true);
  //const [inactive, setInactive] = useState(true);
  const [searchField, setSearchField] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [exclude , setExclude] = useState(true);
  const [add , setAdd] = useState(true);
  const [edit, setEdit] = useState(true);
  const [login, setLogin] = useState(true);
  const sizePerPage = 12;
  const { i18n, t } = useTranslation();

  const [treeData, setTreeData] = useState([]);

    useEffect(()=>{
        axios.get('getLogData/')
        .then(res=>{
        setLogs(res.data);
        setLoadLogs(false);
        })
        .catch(err => {
        console.log(err);
        toastr.error('Erro!',"Erro ao buscar Logs."); //Quando ocorre erro no log
        });   
    },[]);

    useEffect(() => { // busca por empresa e usuario
      axios.get('/companySearch')
      .then(res => {
        let optAux = [];
        if (res.data.length > 0) {
          res.data.forEach(comp => {
            const str = 'comp' + comp.id;
            optAux.push({
              title: comp.name,
              value: str,
              key: str,
              id: str,
              isLeaf: false,
              checkable: true,
              children: []
            });
          });
        }
        setTreeData(optAux);
      })
      .catch(err => {
        console.log(err);
        console.log(err.response);
      });
    }, []);

    function onLoadData(treeNode) {
      return new Promise(resolve => {
        const index = treeData.findIndex(item => {
          return item.key === treeNode.key
        });
        setTimeout(() => {
          if (treeNode.children.length) {
            resolve();
            return;
          } else {
            axios.get('/userSearchRestricted', {params: {
              companyId: treeNode.key.split('comp')[1]
              // projId: treeNode.id
            }})
            .then(res => {
              let optAux = treeData;
              if (res.data.length > 0) {
                res.data.forEach(usr => {
                  const str = 'usr' + usr.id;
                  optAux[index].children.push({
                    title: usr.name,
                    value: str,
                    key: str,
                    id: str,
                    isLeaf: true,
                    checkable: true
                  });
                });
              }
              setTreeData()
              setTreeData(optAux);
            })
            .catch(err => {
              console.log(err);
              console.log(err.response);
            })
            .finally(()=>{
              resolve();
              return
            });
          }
        }, 300);
      });
    };

    function onChange(checkedKeys) {
      setTreeValue(checkedKeys);
      if (checkedKeys.length > 0) {
        let compAux = [];
        let usrAux = [];
        checkedKeys.forEach(key => {
          if (key.includes('comp')) {
            compAux.push(key.split('comp')[1]);
          } else if (key.includes('usr')) {
            usrAux.push(key.split('usr')[1]);
          }
        })
        setCompanies(compAux);
        setUsers(usrAux);
      } else {
        setCompanies([]);
        setUsers([]);
      }
    };
    //***** Load de logs ao iniciar
    if(loadLogs && !logs.length){
        return <div className="text-center"><Spinner color="primary" /></div>;
    }
    //***** Filtro avançado de busca
    function onFilterSearch() { 
    const JSONDATA = {
      exclude: exclude,
      add: add,
      edit: edit,
      login: login,
      startDate: startDate,
      endDate: endDate,
      searchField:searchField.toLowerCase(),
      offset: 0,
      users: users,
      companies: companies
    }
    setCurrPage(1)
    setLoadLogs(true);
    axios.get('/filteredLogData', {params: JSONDATA})
    .then(response => {
      setLogs(response.data);
     
    })
    .catch(err => {
      console.log(err);
      console.log(err.response);
      toastr.warning('Logs de usuário', 'Logs nao encontrados');
    })
    .finally(() => setLoadLogs(false));
    }
      
    const sortIcon = {
      sortCaret: (order) => (!order) 
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faSort}
        />
        : (order === 'asc')
          ? <FontAwesomeIcon
            className={'fa-xs ml-1'}
            icon={faCaretDown}
          />
          : <FontAwesomeIcon
              className={'fa-xs ml-1'}
              icon={faCaretUp}
            />
    }
    //****** Colunas de pesquisa
    const colsLogs =[ 
      {// ID de usuário
         dataField: "userId",
         text: t('userLogs.id'),
         sort: true,
         hidden: true
     },
     {// User name
         dataField: "userName",
         text: t('userLogs.username'),
         sort: true,
         headerStyle: {width: "25%"}
     },    
     { // data de finalização
         dataField: "timestamp",
         text: t('userLogs.date'),
         sort: true,
         formatter: cell => {
           if (cell) {
             var date = new Date(cell*1000);
   
             return ("0"+date.getDate()).slice(-2)
               + '/' + ("0"+(date.getMonth()+1)).slice(-2)
               + '/' + date.getFullYear()
               + ' ' + ("0"+date.getHours()).slice(-2)
               + ':' + ("0"+date.getMinutes()).slice(-2)
               + ':' + ("0"+date.getSeconds()).slice(-2);
           } else return '-'
         },
         headerStyle: {width: "25%"}
     },
     {// Filtro de Payload
         dataField: "display",
         text: t("userLogs.display"),
         sort: true
     }
 ]
 const TableLegend = ({from, to, size}) => {
  

  return (
    <span 
      className="react-bootstrap-table-pagination-total" 
      style={{
        display: (window.innerWidth < 700) ? "none" : "block",
        marginBottom: (window.innerWidth < 700) ? "15px" : "0"
      }}
    >
      {t('userLogs.showing')} { from }-{ to } ({t('userLogs.of')}){ size } ({t('userLogs.results')})
    </span>
  );
};
    //**** Paginaçao customizada 
 const CustomPagination = ({pages, onPageChange}) => {
  let activePage = pages.find(item => item.active).page;

  return <Col 
    className={"d-flex justify-content-" + ((window.innerWidth < 700) ? "start" : "end")} 
    style={{
      position: (window.innerWidth < 700) ? "absolute" : "relative"
    }}
  >
    <ButtonToolbar className="mr-2">
      <ButtonGroup>
        {pages.map((p, i) => (p.page === ">" || p.page === ">>")
          ? <Button key={i}
              color="primary" 
              outline={!p.active}
              disabled={!isMore && p.disabled}
              onClick={() => {
                // verifica se está na última página
                if (activePage === pages[pages.length-3].page) {
                  setLoadLogs(true);

                  axios.get('/getLogData', {params: {
                
                    startDate: startDate,
                    endDate: endDate,
                    searchField: searchField.toLowerCase(),
                    offset: logs.length,
                   
                  }})
                  .then(res => {
                    setCurrPage((logs.length % sizePerPage === 0)
                      ? activePage + 1
                      : activePage
                    )
                    let temp = logs;
                    res.data.logs.map(item => temp.push(item));
                    setLogs(temp);
                    setMore(res.data.isMore);

                  })
                  .catch(err => {
                    console.log(err);
                    console.log(err.response);
                  })
                  .finally(()=>setLoadLogs(false));
                }

                else {
                  onPageChange(p.page);
                }
              }}
            >
              {p.page}
            </Button>

          : <Button key={i}
              color="primary" 
              outline={!p.active} 
              disabled={p.disabled}
              onClick={() => onPageChange(p.page)}
            >
              {p.page}
            </Button>
        )}
      </ButtonGroup>
    </ButtonToolbar>
  </Col>
};

 const paginationOptions = {
  page: currPage,
  sizePerPage: sizePerPage,
  pageStartIndex: 1,
  paginationSize: 3,
  // withFirstAndLast: true, // Hide the going to First and Last page button
  alwaysShowAllBtns: true, // Always show next and previous button
  firstPageText: '<<',
  prePageText: '<',
  nextPageText: '>',
  lastPageText: '>>',
  // firstPageTitle: 'Next page',
  // prePageTitle: 'Pre page',
  // nextPageTitle: 'First page',
  // lastPageTitle: 'Last page',
  hideSizePerPage: true, // Hide the sizePerPage dropdown always    
  disablePageTitle: true,
  hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
  showTotal: true,
  pageListRenderer: ({pages, onPageChange}) => <CustomPagination pages={pages} onPageChange={onPageChange}/>,
  paginationTotalRenderer: (from, to, size) => <TableLegend from={from} to={to} size={size} />
};
 
  return(
  
   <div className="alarm-search">
    <div >
      {/*Busca avançada */}
    <Form onSubmit={e =>{
      e.preventDefault()
      onFilterSearch()
      
    }}>
    <Row className="mb-2">
          {/* Coluna de prioridade */}
      <Col md={5}>
          {/* Busca por termo */}
          <FormGroup className="mb-0">
              <Label for="search" className="ml-1">
                {t('userLogs.searchTitle')}
              </Label>
              <Input
                type="text"
                name="search"
                id="search"
                placeholder={t('userLogs.searchHolder')}
                value={searchField}
                onChange={e=>{
                  setSearchField(e.target.value)
                }}
              />
          </FormGroup>
          
          <div className="d-flex justify-content-between mt-2 mb-2">
            <TreeSelect
              id="logs"
              treeDataSimpleMode
              maxTagCount="responsive"
              treeData={treeData}
              value={treeValue}
              onChange={onChange}
              loadData={onLoadData}
              treeCheckable={true}
              showCheckedStrategy={SHOW_PARENT}
              placeholder={t('userLogs.treePlaceholder')}
              size="small"
              style={{width: '100%'}}
              className="cursor-pointer"
            />
          
          </div>
                {/** Checkbox for filtered logs */}
          <div className="d-flex justify-content-between mt-2 ">
              <Label className="ml-1" style={{ }}>
                {t('userLogs.checktitle')}
              </Label>
              <FormGroup check inline className="mb-0 ml-2">
                <Label check className="cursor-pointer">
                  <Input
                    type="checkbox"
                    name="exclusao"
                    className="cursor-pointer"
                    checked={exclude}
                    onChange={()=>setExclude(!exclude)}
                    style={{verticalAlign: "middle"}}
                  />
                  <PriorityBadge priority={1} />
                </Label>
              </FormGroup>

              <FormGroup check inline className="mb-0 ml-auto">
                <Label check className="cursor-pointer">
                  <Input
                    type="checkbox"
                    name="add"
                    className="cursor-pointer"
                    checked={add}
                    onChange={()=>setAdd(!add)}
                    style={{verticalAlign: "middle"}}
                  />
                  <PriorityBadge priority={2} />
                </Label>
              </FormGroup>

              <FormGroup check inline className="mb-0 ml-auto">
                <Label check className="cursor-pointer">
                  <Input
                    type="checkbox"
                    name="edit"
                    className="cursor-pointer"
                    checked={edit}
                    onChange={()=>setEdit(!edit)}
                    style={{verticalAlign: "middle"}}
                  />
                  <PriorityBadge priority={3} />
                </Label>
              </FormGroup>
              <FormGroup check inline className="mb-0 ml-auto">
                <Label check className="cursor-pointer">
                  <Input
                    type="checkbox"
                    name="login"
                    className="cursor-pointer"
                    checked={login}
                    onChange={()=>{setLogin(!login)}}
                    style={{verticalAlign: "middle"}}
                  />
                  <PriorityBadge priority={4}/>
                </Label>
              </FormGroup>
          </div>
      
            <Button type="submit">{t('userLogs.submit')}</Button> 
            
      </Col> 
      {/* Coluna de datas */}   
      <Col md={5}>
      <FormGroup className="mb-0">
                <Label for="start" className="ml-1">
                  {t('alarms.searchStart')}:
                </Label>
                <DatePicker
                  id="start"
                  className="form-control"
                  showTimeSelect
                  isClearable
                  placeholderText={t('alarms.datepickerPlaceholder')}
                  selected={startDate}
                  maxDate={(endDate) ? endDate : new Date()}
                  onChange={date => setStartDate(date)}
                  locale={(i18n.language==="br") ? ptBR : enGB}
                  dateFormat={(i18n.language ==="en")
                    ? "MMMM d, yyyy, hh:mm aa"
                    : "dd/MM/yyyy, HH:mm"
                  }
                />
              </FormGroup>
              
              {/* Fim */}
              <FormGroup className="mb-0 mt-2">
                <Label for="end" className="ml-1">
                  {t('alarms.searchEnd')}:
                </Label>
                <DatePicker
                id="end"
                className="form-control"
                showTimeSelect
                isClearable
                placeholderText={t('alarms.datepickerPlaceholder')}
                selected={endDate}
                minDate={startDate}
                maxDate={new Date()}
                onChange={date => setEndDate(date)}
                dateFormat={(i18n.language ==="en")
                ? "MMMM d, yyyy, hh:mm aa"
                : "dd/MM/yyyy, HH:mm"
                 }
              />
            </FormGroup>
      </Col>     
    </Row>
     
    </Form>
    </div>
     {(loadLogs) // se está carregando...
     // retorna spinner
     ? <div className="text-center">
         <Spinner color="primary" />
       </div>
     // se não está mais carregando, verifica se há logs
     : (!logs.length)
       // se não há logs e não está mais carregando, é porque não tem logs mesmo
       ? <p className="text-center mt-5">
           {t('userLogs.historic')}.
         </p>
       // se não está mais carregando e há logs, mostra a tabela
       : <div>
           <BootstrapTable // Tabela
             bootstrap4
             keyField="id"
             data={ logs }
             columns={colsLogs}
             condensed
             bordered={false}
             striped={true}
             sort={sortIcon}
             pagination={paginationFactory(paginationOptions)}

           />
         </div>
    }
  </div>
  )
}

const LogData = () =>{
  const {t}= useTranslation();
  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          {'Logs de usuário'}
        </HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{'Home'}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/userRegister">{'Registros'}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {'Logs'}
          </BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card style={{marginTop: "-26px"}}>
        <CardHeader>
          <CardTitle tag="h5">
            {t('userLogs.cardtitle')}
          </CardTitle>
          <h6 className="card-subtitle text-muted mt-1">
            {t('userLogs.subtitle1')} 
            <ChevronRight width={16} height={16} /> 
            {t('userLogs.subtitle2')}.
          </h6>
        </CardHeader>

        <CardBody>

          <LogDataTable/>


        </CardBody>
      </Card>
    </Container>
  );
}
 
export default LogData