import { Button, Form, Input, Modal } from "antd";
import { format } from "date-fns";
import React, { memo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CardBody } from "reactstrap";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ptBR from "date-fns/locale/pt-BR";
import enUS from "date-fns/locale/en-US";

const formatTime = (time, locale) => {
  const date = new Date(time);

  return format(date, "Pp", { locale: locale === 'br' ? ptBR : enUS });
};
const colorsPlaced = [
  "#8884d8",
  "#82ca9d",
  "#ffc658",
  "#ff7300",
  "#ff0000",
  "#00c49f",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
];

const DataExplorerChart = memo(({ dataExplorerInfo, t, language }) => {
  const dataChart = dataExplorerInfo?.dataChart;
  const sendedParams = dataExplorerInfo?.sendedParams;

  const series = dataChart
    ?.filter((element) => element.length > 0)
    .map((element) => ({
      name:
        element[0]?.deviceName +
        "---" +
        element[0]?.title +
        (element[0]?.unit ? ` (${element[0]?.unit})` : ""),
      unit: element[0]?.unit,
      data: element.map((item) => ({
        time: item.time,
        value:
        sendedParams.typeVariable === "integral" || !sendedParams.typeVariable
            ? item.variavel
            : item.difference,
      })),
    }));
  const flatedData = series?.map((s) => s.data).flat();

  const uniqueUnits = [...new Set(series?.map((s) => s.unit))];

  const [showColorPickers, setShowColorPickers] = useState(false);
  const [colors, setColors] = useState(() => {
    return series?.map((s, index) => ({
      color: colorsPlaced[index % colorsPlaced.length],
      name: s.name,
    }));
  });

  return series?.length > 0 ? (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 450px)",
      }}
    >
      <div className="d-flex justify-content-end">
        <Button onClick={() => setShowColorPickers(!showColorPickers)}>
          {t("dataExplorer.customizeColors")}
        </Button>
      </div>
      {showColorPickers && (
        <ModalSelectColor
          colors={colors}
          setColors={setColors}
          open={showColorPickers}
          onClose={() => setShowColorPickers(false)}
        />
      )}
      <ResponsiveContainer width="100%" height={`100%`}>
        <LineChart data={flatedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            type="number"
            domain={["auto", "auto"]}
            tickFormatter={(timeStr) => {
              const time = new Date(timeStr);
              return format(time, "dd/MM HH:mm");
            }}
          />
          <Tooltip labelFormatter={(time) => formatTime(time, language)}
            
          />

          {uniqueUnits.length > 1 ? (
            uniqueUnits.map((unit, index) => (
              <YAxis
                key={unit}
                yAxisId={`yAxis-${index}`}
                orientation={index === 0 ? "left" : "right"}
                label={{
                  value: unit,
                  angle: -90,
                  position: index === 0 ? "insideLeft" : "insideRight",
                }}
                domain={["auto", "auto"]}
              />
            ))
          ) : (
            <YAxis
              yAxisId="singleYAxis"
              label={{
                value: uniqueUnits[0] || "Value",
                angle: -90,
                position: "insideLeft",
              }}
              domain={["auto", "auto"]}

            />
          )}

          <Legend
            wrapperStyle={{
              padding: "0 2rem",
              height: "100%",
              overflow: "auto",
              maxHeight: "50px",
            }}
          />
          {series.map((s, index) => (
            <Line
              dataKey="value"
              type="monotone"
              data={s.data}
              name={s.name}
              key={s.name}
              dot={false}
              connectNulls={false}
              stroke={colors.find((c) => c.name === s.name)?.color}
              isAnimationActive={false}
              yAxisId={
                uniqueUnits.length > 1
                  ? `yAxis-${uniqueUnits.indexOf(s.unit)}`
                  : "singleYAxis"
              }
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <div>
      <CardBody className="text-center"> {t("solar.noData")} </CardBody>
    </div>
  );
});

const ModalSelectColor = ({ colors, setColors, onClose, open }) => {
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    const updatedColors = colors.map((color, index) => ({
      name: color.name,
      color: data.colors[index].color,
    }));
    setColors(updatedColors);
  };

  const onOk = () => {
    handleSubmit(onSubmit)();
    onClose();
  };

  return (
    <Modal open={open} closable onCancel={onClose} onOk={onOk}>
      <div>
        <Form
          layout="vertical"
          style={{
            maxHeight: "500px",
            overflow: "auto",
          }}
        >
          {colors.map((color, index) => (
            <Form.Item key={color.name} label={color.name}>
              <Controller
                name={`colors[${index}].color`}
                control={control}
                defaultValue={color.color}
                render={({ field }) => (
                  <Input
                    type="color"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Form.Item>
          ))}
        </Form>
      </div>
    </Modal>
  );
};

export default DataExplorerChart;
