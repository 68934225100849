import axios from "axios";
import React, { useEffect, useState } from "react";
import { Search } from "react-feather";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Input,
} from "reactstrap";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { ModalNewProject } from "../../igRegisters/projects/ModalNewProject";
import { ProjectsCards } from "./components/ProjectsCards";


/*** Página ***/
const Projects = () => {
  const { t } = useTranslation();
  const [searchbar, setSearchbar] = useState("");
  const [isLoadingSave, setLoadingSave] = useState(true);
  const [openNewProj, setOpenNewProj] = useState(false);
  // ativa/desativa modal
  function toggleNewProj() {
    setOpenNewProj(!openNewProj);
  }

  const [projs, setProjs] = useState([]);
  /* Busca projetos aos quais o usuário está vinculado */
  useEffect(() => {
    if (isLoadingSave) {
      // axios.post('projectUserSearch/', JSON.stringify({
      //   userId: localStorage.getItem('userId')
      // }))
      // axios.get('/projectUserSearch', {params: {
      //   userId: localStorage.getItem('userId')
      // }})
      axios
        .get("/projectUserSearch")
        .then((res) => {
          setProjs(res.data);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);

          if (err.response) {
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          } else {
            toastr.warning("Erro!", "Erro de conexão.");
          }
        })
        .finally(() => setLoadingSave(false));
    }
  }, [isLoadingSave]);

  return (
    <Container fluid>
      <Header style={{ marginBottom: "1rem" }}>
        <HeaderTitle>{t("projects.title")}</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{t("sidebar.home")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("sidebar.projects")}</BreadcrumbItem>
        </Breadcrumb>

        <div
          className="d-flex align-items-center justify-content-start"
          style={{ width: "250px" }}
        >
          <Search width={16} height={16} className="text-muted" />
          <Input
            type="text"
            placeholder={t("projects.searchProjects")}
            aria-label="Search"
            className="form-control-lite"
            value={searchbar}
            onChange={(e) => setSearchbar(e.target.value)}
          />
        </div>
        {localStorage.getItem("userType") === "1" ? (
          <div // ícone de adicionar projeto
            id={"addProject"}
            className="justify-content-end cursor-pointer"
            onClick={() => setOpenNewProj(true)}
          >
            <Button
              color="primary"
              style={{ float: "right", marginTop: "-31px" }}
            >
              {t("projects.addProject")}
            </Button>
            {openNewProj ? ( // modal de adicionar projeto
              <ModalNewProject
                open={openNewProj}
                toggle={toggleNewProj}
                setLoadProjects={(load) => setLoadingSave(load)}
              />
            ) : null}
          </div>
        ) : null}
      </Header>

      <ProjectsCards
        projs={projs}
        searchbar={searchbar
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")}
        isLoading={isLoadingSave}
      />
    </Container>
  );
};

export default Projects;
