import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import { BrasilFlag, USFlag } from "../../assets/flags";

import EngmLogo from "../../assets/img/engm-blue.png";
import { getCompanyLogo } from "../../utils/domain";

const Flag = ({ image, isSelected, ...props }) => (
  <img
    alt="flag"
    src={image}
    style={{ borderRadius: "20%" }}
    className={isSelected ? "flag selected" : "flag"}
    {...props}
  />
);
const TranslateFlags = () => {
  const { i18n } = useTranslation();

  return (
    <div className="d-flex justify-content-center">
      <div className="mx-1 my-0">
        <Flag
          image={BrasilFlag}
          isSelected={i18n.language === "br"}
          onClick={() => i18n.changeLanguage("br")}
        />
      </div>

      <div className="mx-1 my-0">
        <Flag
          image={USFlag}
          isSelected={i18n.language === "en"}
          onClick={() => i18n.changeLanguage("en")}
        />
      </div>
    </div>
  );
};

/****** PÁGINA DE REDEFINIR SENHA -- PADRÃO DO TEMPLATE ******/
const ResetPassword = () => {
  const companyLogo = getCompanyLogo()
  const { t } = useTranslation();

  const [email, setEmail] = useState("");

  let history = useHistory();

  return (
    <React.Fragment>
      <Card className="mb-2">
        <CardBody>
          {/* iGoal Logo */}
          <div className="text-center">
            <img
              alt="logo"
              src={companyLogo}
              width={EngmLogo ? "120" : "333"}
              height="auto"
              className="mt-3"
            />
          </div>

          {/* BLOCO DE TÍTULO */}
          <div className="text-center mt-1">
            <h4>{t("reset.title")}</h4>
            <p>{t("reset.text")}.</p>
          </div>

          {/*** BLOCO DE FORMULÁRIO ***/}
          <div className="m-sm-4">
            <Form
              onSubmit={(e) => {
                // envia dados com Enter
                e.preventDefault();

                /*** ARMAZENAMENTO DE DADOS DO FORMULARIO NO FORMATO JSON ***/
                const jsonData = JSON.stringify({
                  email: email,
                });

                /*** ENVIO DE DADOS PELO AXIOS ***/
                axios
                  .post("auth/forgotPassword/", jsonData)
                  .then((res) => {
                    /*** ARMAZENAMENTO DE ESTADO DE ACESSO ***/
                    localStorage.setItem("isLoggedIn", res.data.auth);
                    localStorage.setItem("token", "");

                    /*** NOTIFICAÇÃO DE SENHA ALTERADA ***/
                    toastr.success("Senha redefinida!", res.data.message);

                    history.push("/");
                  })
                  .catch((err) => {
                    console.log(err);
                    console.log(err.response);

                    /*** NOTIFICAÇÃO E ARMAZENAMENTO DE ESTADO DE ACESSO ***/
                    toastr.warning("Erro!", err.response.data.message);
                    localStorage.setItem("isLoggedIn", err.response.data.auth);
                  });

                /*** LIMPAR FORMULÁRIO ***/
                setEmail("");
              }}
            >
              {/* CAMPO DE E-MAIL */}
              <FormGroup>
                <Label className="ml-1">E-mail</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder={t("reset.insert")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {console.log(email)}
              </FormGroup>

              {/*** BOTÔES ***/}
              <div className="text-center mt-3">
                {/*** BOTÃO DE RETORNAR À PAGINA INICIAL ***/}
                <Link to="/">
                  <Button className="mr-1" color="primary" outline>
                    {t("reset.back")}
                  </Button>
                </Link>

                {/*** BOTÃO DE REDEFINIR SENHA ***/}
                <Button
                  type="submit" // envia dados com Enter
                  className="ml-1"
                  color="primary"
                >
                  {t("reset.reset")}
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>

      {/* Bandeiras */}
      <div>
        <TranslateFlags />
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
