import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';

const CurrentPower = ({ currentPower, loading, loadingModal }) => {
  const { t } = useTranslation();

  var currentPowerAndUnit = (currentPower < 1000)
    ? (currentPower.toFixed(2)).replace(".", ",") + " kW"
      : (currentPower < 1000000)
        ? (parseFloat((currentPower/1000).toFixed(2))).toString().replace(".", ",") + " MW"
          : (currentPower < 1000000000)
            ? (parseFloat((currentPower/1000000).toFixed(2))).toString().replace(".", ",") + " GW"
              : (currentPower < 1000000000000)
                ? (parseFloat((currentPower/1000000000).toFixed(2))).toString().replace(".", ",") + " TW"
                : ""

  return (
    <Row style={{minWidth: "147px"}}>
      <Col sm="12" style={{paddingRight:"0px"}}>
        <Card className="solar-top-card">
          <CardBody style={{padding: "15px"}}>
            <Row>
              <Col className="mt-0">
                <CardTitle tag="h5">{t('solar.current')}<br></br>{t('solar.power')}</CardTitle>
              </Col>
              <Col className="col-auto" style={{paddingLeft: "0px"}}>
                <div style={{width:"26px",height:"26px"}}>
                  <div className="avatar-title rounded-circle bg-primary-dark">
                    <FontAwesomeIcon icon={faTachometerAlt} fixedWidth className="align-middle" style={{marginBottom:"2px"}}/>
                  </div>
                </div>
              </Col>
            </Row>
            {(loadingModal)
              ? <div className="text-center mb-4"><Spinner /></div>
              : (loading)
                ? <div className="text-center mb-4"> <Spinner color="primary"/></div>
                : (currentPower >= 0)
                  ? <h1 className="display-5 mb-4 text-center" style={{fontSize:"17px", fontWeight:"500"}}>{currentPowerAndUnit}</h1>
                  : <p className="text-center">Não há dados</p>
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}   

export default CurrentPower;