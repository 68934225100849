import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CardBody, CardHeader, CardTitle, Spinner } from "reactstrap";

import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useChartHeight from "../hook";
import { formatEnergy } from "../../../../../utils/functions";
import { COLOR_SOLAR } from "../../../../../utils/Constants";
import { DateRangeSelector } from "../../../../../components/DateRangeSelector";
import moment from "moment";

const ENUM_ENTRY_RANGE = {
  today: "minutes",
  last24Hours: "minutes",
  last7Days: "days",
  last30Days: "days",
  last12Months: "months",
  "24Hours": "minutes",
  "7D": "days",
  fullMonth: "days",
  fullYear: "months",
};

const IrradiationChart = ({ hours, energy, irradiation }) => {
  const { t } = useTranslation();

  // Preparar os dados
  const data = hours.map((element, index) => ({
    time: element.toString().padStart(2, "0"),
    generation: energy[index] || 0,
    irradiance: irradiation[index] || 0,
  }));

  const minGeneration = Math.min(...energy);
  const maxGeneration = Math.max(...energy);
  const minIrradiance = Math.ceil(Math.min(...irradiation));
  const maxIrradiance = Math.ceil(Math.max(...irradiation));

  const { generationKwh, irradiationWm2 } = COLOR_SOLAR;
  const height = useChartHeight();

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart data={data}>
        <defs>
          <linearGradient id="colorGeneration" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={irradiationWm2} stopOpacity={0.8} />
            <stop offset="95%" stopColor={irradiationWm2} stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis
          yAxisId="left"
          orientation="left"
          domain={[minGeneration, maxGeneration]}
          tickFormatter={formatEnergy}
          width={50}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          domain={[minIrradiance, maxIrradiance]}
          tickFormatter={(value) => `${value} W/m²`}
          width={50}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip />
        <Legend />
        <Area
          yAxisId="left"
          type="monotone"
          dataKey="generation"
          name={`${t("solar.generation")} (kWh)`}
          stroke="#E98C45"
          fill="url(#colorGeneration)"
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="irradiance"
          name={`${t("solar.irradiance")} (W/m²)`}
          stroke={generationKwh}
          dot={false} // Remover os pontos da linha
          strokeWidth={2}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const GeraçãoXirradiação = ({ projectId }) => {
  const { t } = useTranslation();
  const [start, setStart] = useState(
    moment(new Date()).startOf("day").toDate()
  );
  const [end, setEnd] = useState(moment(new Date()).endOf("day").toDate());
  const [range, setRange] = useState("24Hours");

  const { data, isFetching } = useQuery({
    queryKey: [
      "solarReadDataChartByTimestamp",
      { projId: projectId, start, end, range },
    ],
    queryFn: () =>
      axios
        .get("v2/solar/readDataChartByTimestamp", {
          params: {
            projId: projectId,
            start,
            end,
            range: ENUM_ENTRY_RANGE[range],
          },
        })
        .then((res) => res.data),
    staleTime: 1000 * 60 * 60, // 1 hour
    refetchInterval: 1000 * 60 * 60, // 1 hour
  });

  return (
    <>
      <CardHeader style={{height: "fit-content", paddingBottom: 0}}>
        <CardTitle tag="h5">{t("solar.generationXirradiationDay")}</CardTitle>
      </CardHeader>

      <CardBody className="p-0">
        {isFetching ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <DateRangeSelector
            loading={isFetching}
            initDate={start}
            finishDate={end}
            setEntrieStartDate={setStart}
            setEntrieEndDate={setEnd}
            entrieRange={range}
            setEntrieRange={setRange}
          >
            <IrradiationChart
              hours={data.times}
              energy={data.energy}
              irradiation={data.irradiation}
            />
          </DateRangeSelector>
        )}
      </CardBody>
    </>
  );
};

export default GeraçãoXirradiação;
