export const Line = ({ open, children }) => {
  return (
    <div
      style={{
        width: open ? "202px" : "20px",
        height: "32px",
        padding: open ? "0 12px 0 26px" : "0 6px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};
