import React, { useState, useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';


const Chart = ({ data, setLoading, loading }) => {
  
  useEffect(() => {
    setLoading(true);

    let root = am5.Root.new("chartdiv");

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        wheelY: "zoomX",
        layout: root.verticalLayout,
        maxTooltipDistance: 0
      })
    );

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraTooltipPrecision: 10,
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        // renderer: am5xy.AxisRendererX.new(root, {}),
        // categoryField: "timestamp",


        maxDeviation: 0.5,
        groupData: false,
        baseInterval: {
          timeUnit: "minute",
          count: 1
        },

        renderer: am5xy.AxisRendererX.new(root, {}),
         tooltip: am5.Tooltip.new(root, {}),
        // visible: (index === 0)? true : false
      })
    );
    xAxis.data.setAll(data);

    // Create series

    let series = chart.series.push(
      am5xy.LineSeries.new(root, {
        connect: false,
        name: "Corrente",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField:"InputCurrent1",
        valueXField: "millitimestamp",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "vertical",
          labelText: "[bold]{name}: {InputCurrent1}"
        })
      })
    );
    series.data.setAll(data);

    let series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        connect: false,
        name: "Tensão",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "InputVoltage1",
        valueXField: "millitimestamp",
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]{name}: {InputVoltage1}"
        })
      })
    );

    series2.data.setAll(data);
    
    let series3 = chart.series.push(
      am5xy.LineSeries.new(root, {
        connect: false,
        name: "Tensão2",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "InputVoltage2",
        valueXField: "millitimestamp",
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]{name}: {InputVoltage2}"
        })
      })
    );

    series3.data.setAll(data);

    let series4 = chart.series.push(
      am5xy.LineSeries.new(root, {
        connect: false,
        name: "Tensão3",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "InputVoltage3",
        valueXField: "millitimestamp",
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]{name}: {InputVoltage3}"
        })
      })
    );

    series4.data.setAll(data);

    let series5 = chart.series.push(
      am5xy.LineSeries.new(root, {
        connect: false,
        name: "Frequencia",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "InputFrequency1",
        valueXField: "millitimestamp",
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]{name}: {InputFrequency1}"
        })
      })
    );

    series5.data.setAll(data);

    let series6 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Frequencia2",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "InputFrequency2",
        valueXField: "millitimestamp",
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]{name}: {InputFrequency2}"
        })
      })
    );

    series6.data.setAll(data);

    let series7 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Frequencia3",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "InputFrequency3",
        valueXField: "millitimestamp",
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]{name}: {InputFrequency3}"
        })
      })
    );

    series7.data.setAll(data);

    let series8 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Entradas",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "InputNumLines",
        valueXField: "millitimestamp",
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]{name}: {InputNumLines}"
        })
      })
    );

    series8.data.setAll(data);
  
    // Add legend
    let legend = chart.children.push(am5.Legend.new(root, {}));
    legend.data.setAll(chart.series.values);


    // Add cursor
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomX"
    }));
    cursor.lineY.set("visible", false);
    chart.appear();
    setLoading(false);

    root._logo.dispose();


    // eslint-disable-next-line
  }, [loading])


  return <div id="chartdiv" style={{ width: "95%", height: "44vh", padding: "1vw" }}></div>
}

const NoBreakCharts = ({ graphData, selectedDevice }) => {
  // eslint-disable-next-line 
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // Define data
  let data = graphData.find(item => item[0].deviceId === selectedDevice)[0].inputData;

  return (data.length) ?
    (loading) ?
      <div className="text-center">
        <Spinner color="primary" />
      </div>
      :
       <Chart data={data} setLoading={setLoading} loading={loading}/>
    :  <p style={{ fontSize: "2em", textAlign: "center",marginTop:"2em" }}>{t('dashboards.noDataStats')}</p>

}

export default NoBreakCharts