export const Box = ({ open, selected, children }) => {
  return (
    <div
      style={{
        marginLeft: open ? "0px" : "20px",
        width: open ? "202px" : "32px",
        height: "32px",
        borderRadius: open ? "0 16px 16px 0" : "16px",
        display: "flex",
        alignItems: "center",
        background: selected ? "rgba(0,0,0,0.08)" : "transparent",
        transition: "background ease-in-out 0.2s",
      }}
    >
      {children}
    </div>
  );
};
