import React from "react";
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

const LastComm = ({ allData, state, data }) => {
  const { t } = useTranslation();

  var lastComm = (state === "green")
    ? "On Grid"
    : (state === "red")
      ? "Off Grid"
      : (state === "orange")
        ? "Stand By"
        : (state === "gray")
          ? "OffLine"
          : null


  var pisca = (state !== "green") ? "fa-blink" : ""
  return ((state) ? 
    <div className="w-100">
      <Row>
        <Col sm="12" style={{ paddingRight: "0px" }}>
          <Card className={pisca} style={{ minWidth: "135px", maxHeight: "100px", height: "100px", marginBottom: "10px", borderStyle: "solid", background: state, }}>
            <CardBody style={{ padding: "15px", paddingTop: "10px" }}>
              <Row>
                <Col className="mt-0">
                  <CardTitle style={{ color: "white" }} tag="h5">{t('solar.inverterStatus')}</CardTitle>
                </Col>
                <Col className="col-auto" style={{ paddingLeft: "0px" }}>
                  <div>
                    <FontAwesomeIcon
                      icon={faClock}
                      style={{ fontSize: "16px", color: "white" }}
                    />
                  </div>
                </Col>
              </Row>
              {(state)
                ? <h1 className="display-5 text-center" style={{ fontSize: "17px", fontWeight: "500", color: "white" }}>{(lastComm) ? lastComm : t("devices.modalNoStatus")}</h1>
                : <div className="text-center mb-4"><Spinner /></div>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
    : (!state) ?
      <div className="w-100">
        <Row>
          <Col sm="12" style={{ paddingRight: "0px" }}>
            <Card style={{ minWidth: "135px", maxHeight: "100px", height: "100px", marginBottom: "10px", borderStyle: "solid", background: "#dde0e5", }}>
              <CardBody style={{ padding: "15px", paddingTop: "10px" }}>
                <Row>
                  <Col className="mt-0">
                    <CardTitle tag="h5">{t('solar.inverterStatus')}</CardTitle>
                  </Col>
                  <Col className="col-auto" style={{ paddingLeft: "0px" }}>
                    <div>
                      <FontAwesomeIcon
                        icon={faClock}
                      />
                    </div>
                  </Col>
                </Row>
                {(state)
                  ? <h1 className="display-5 text-center" style={{ fontSize: "17px", fontWeight: "500", color: "white" }}>{(lastComm) ? lastComm : t("devices.modalNoStatus")}</h1>
                  : <CardText className="display-5 text-center" style={{ fontSize: "17px", fontWeight: "500" }}>{t("devices.modalNoStatus")}</CardText>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      : null
  );
}

export default LastComm;