import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSolarPanel } from "react-icons/fa";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import { CardHeaderNoc } from "../Card";
import { fetchGet } from "../../NocService";
import { SlEnergy } from "react-icons/sl";
import { IoWaterOutline } from "react-icons/io5";
import { MdDoNotDisturbAlt, MdOutlineGasMeter } from "react-icons/md";
import { BsSnow } from "react-icons/bs";

import { useQuery } from "@tanstack/react-query";
import { ModalProjectModules } from "./ModalProjectModules";

export const ChartProjectModules = ({
  companyId = localStorage.getItem("companyId"),
}) => {
  const { t } = useTranslation();

  const refetchInterval = 1000 * 60 * 15;
  const [selectedStatus, setSelectedStatus] = useState("solar");
  const currentTheme = useSelector((store) => store.theme.currentTheme);
  const [showModal, setShowModal] = useState(false);

  const {
    status,
    data: reactQuery,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["companyListProjects", companyId],
    queryFn: async () => {
      const res = await axios.get(
        `v2/company/listProjects?companyId=${companyId}`
      );
      return res.data;
    },
    refetchInterval: refetchInterval,
  });

  const statusLabels = {
    solar: t("noc.labels.solar"),
    power: t("noc.labels.power"),
    water: t("noc.labels.water"),
    gas: t("noc.labels.gas"),
    climate: t("noc.labels.climate"),
    noActiveModules: t("noc.labels.noActiveModules"),
  };

  const statusColors = {
    solar: currentTheme.secondary,
    power: currentTheme.warning,
    water: currentTheme.primary,
    gas: currentTheme.tertiary,
    climate: currentTheme.info,
    noActiveModules: currentTheme.disabled,
  };

  const statusIcons = {
    solar: FaSolarPanel,
    power: SlEnergy,
    water: IoWaterOutline,
    gas: MdOutlineGasMeter,
    climate: BsSnow,
    noActiveModules: MdDoNotDisturbAlt,
  };

  const handleNextStatus = () => {
    const keys = Object.keys(statusLabels);
    const currentIndex = keys.indexOf(selectedStatus);
    setSelectedStatus(keys[(currentIndex + 1) % keys.length]);
  };

  const handlePreviousStatus = () => {
    const keys = Object.keys(statusLabels);
    const currentIndex = keys.indexOf(selectedStatus);
    setSelectedStatus(
      keys[currentIndex === 0 ? keys.length - 1 : currentIndex - 1]
    );
  };

  
  const handleModal = () => setShowModal(!showModal);

  return (
    <>
      <CardHeaderNoc
        IconComponent={statusIcons[selectedStatus]}
        color={statusColors[selectedStatus]}
        numberSelected={reactQuery ? reactQuery[selectedStatus] : "0"}
        total={reactQuery ? reactQuery.total : "0"}
        status={statusLabels[selectedStatus]}
        loading={status === "loading" || isFetching}
        error={status === "error"}
        next={handleNextStatus}
        prev={handlePreviousStatus}
        handleReload={refetch}
        title={t("noc.main.headerCards.projects")}
        subtitle={t("noc.main.headerCards.modules")}
        onClick={handleModal}
      />
      {showModal && (
        <ModalProjectModules
          showModal={showModal}
          handleModal={handleModal}
          companyId={companyId}
        />
      )}
    </>
  );
};
