const enUsWaterTranslations = {
  water: {
    period: "Period",
    measurementCost: "Measurement Cost",
    companyRead: "Company consumption",
    meterRead: "Measurement consumption",
    comumArea: "Common area consumption",
    companyBudget: "Water bill",
    condominumBudget: "Condominium bill",
    condominumRead: "Condominium measurement",
    comumAreaCost: "Common area bill",
    consumptionUnits: "Consumption units",
    general: "General",
    generalCondominiumData: "General data of the condominium",
    cubicMeterPrice: "Price m³",
    metersNoData: "There is no data related to the selected Consumer Unit",
    toDefine: "To be defined",
    ucInfo: "Consumption units info ",
    plusInfos: "Additional Information",
    unitConsumption: "Unit Consumption",
    unitValue: "Unit value",
    comumAreaShare: "Apportionment of the Common Area",
    totalToPay: "Total",
    registerNo: "Register",
    previousRead: "Previous reading",
    actualRead: "Actual reading",
    companyConsumption: "Company consumption",
    readConsumption: "Measurement consumption",
    settings: "Config",
    valueToPay: "Value to pay:",
    consumptionByCompany: "Consumption informed by the company:",
    periodEnd: "End of the Measunrement Period:",
    periodInit: "Begining of the Measurement Period:",
    waterModuleConfigure: "Water module configuration",
    ucName: "Unit name",
    ucLinkUser: "Link",
    deleteConfirm:
      'To confirm the removal of the Unit, please type "DELETE" in the field below::',
    managerUC: "Manage Consumer Unit Meters",
    meterName: "Meter name:",
    selectDevice: "Select device",
    selectVariable: "Select variable:",
    selectUser: "Select user:",
    noUser: "No user:",
    initialRead: "Initial read",
    addUc: " Add Consumer Unit to the Project ",
    manageMeters: "Manager meters",
    managerUcs: " Manager consumption units",
    previousValue: "Previous Value",
    currentValue: "Current Value",
    searchByReport: "Search by report",
    price: "Price",
    consumptionUnit: "Consumption Unit",
    meter: "Meter",
    searchByDataConfig: "Search by data config",
    generalConsumption: "General Consumption",
    noData: "No Data",
    report: "Report",
    pdf: {
      graphicAnnualConsumption: "Graphic of annual consumption",
      currentMonthConsumptionTable: "Current month consumption table",
    },
    erro: {
      fetchListUcs: "Error fetching the list of consumption units",
      fetchListUsers: "Error fetching the list of users",
      fetchGeneralData: "Error fetching general data",
      fetchEditUc: "Error editing the consumption unit",
      fetchDeleteUc: "Error deleting the consumption unit",
      fetchEditMeter: "Error editing the meter",
      fetchAddMeters: "Error adding meters",
      fetchSearchMeters: "Error searching meters",
      fetchDeleteMeters: "Error deleting meters",
      fetchAddUc: "Error adding the consumption unit",
      fetchSearhDevices: "Error searching devices",
      fetchSearchVariables: "Error searching variables",
      fetchUpdatePlant: "Error generating the invoice",
      fetchReadPreviousMonthWaterData:
        "Error reading previous month water data",
    },
    plant: {
      consumption: "Consumption",
      ModalGasReport: "Gas Report",
      ModalGasConfigure: "Gas Module Settings",
    },
    modal: {
      consumptionUnits: {
        name: "Name",
        action: "Action",
        clickIn: "Click on",
        toEditUnit: "to edit the unit",
        toAddUnit: "to add a new unit",
        toManageRegisters: "to manage your meters",
      },
    },
  },
};

export default enUsWaterTranslations;
