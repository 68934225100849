import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table
} from "reactstrap";
import { formatAvoidedCO2, formatEnergy, formatIncome, formatPower } from "../../../../../../../../../../utils/functions";
import GeraçãoXirradiacao from "../../../../../../../../projects/solarModule/GeracaoXIrradiacao";
import PerformanceCard from "../../../../../../../../projects/solarModule/PerformanceCard";
import SolarimetricaRealTime from "../../../../../../../../projects/solarModule/SolarimetricaRealTime";
import Availability from "../../../../../../../../projects/solarModule/components/Availability";
import CurrentDayPower from "../../../../../../../../projects/solarModule/components/CurrentDayPower";
import EnergyYield from "../../../../../../../../projects/solarModule/components/EnergyYield";
import Trackers from "../../../../../../../../projects/solarModule/components/Trackers";
import { fetchGetWitchParams } from "../../../../../../../NocService";

export const SolarModule = ({ item }) => {
  const { t } = useTranslation();
  const url = `/readSolarData`;
  const params = {
    projId: item.projectId,
    monthRef: new Date(),
  };

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(() => {
    const source = axios.CancelToken.source();
    setLoading(true);
    setError(null);

    fetchGetWitchParams(source, setData, setError, setLoading, url, params);

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <Skeleton style={{ width: "100%", height: "100px" }} />;
  }

  const handleReload = () => {
    setLoading(true);
    setError(null);
    const source = axios.CancelToken.source();
    fetchGetWitchParams(source, setData, setError, setLoading, url, params);
  };

  const { general = {}, monthlyDataEnergy, dailyDataEnergy } = data;

  if (error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{t("noc.error.errorOccurred")}</CardTitle>
        </CardHeader>
        <CardBody>
          <Button onClick={handleReload}> {t("noc.error.tryAgain")}</Button>
        </CardBody>
      </Card>
    );
  }

  if (!data.general?.processedAt) {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{t("noc.modal.graphic.noData")}</CardTitle>
        </CardHeader>
        <CardBody>
          <Button onClick={handleReload}> {t("noc.error.tryAgain")}</Button>
        </CardBody>
      </Card>
    );
  }


  return (
    <>
      <Table striped responsive>
        <thead>
          <tr>
            <th>{t("noc.modal.processAt")}</th>
            <th>{t("noc.modal.solar.dailyEnergy")}</th>
            <th>{t("noc.modal.solar.monthlyEnergy")}</th>
            <th>{t("noc.modal.solar.lifetimeEnergy")}</th>
            <th>{t("noc.modal.solar.currentPower")}</th>
            <th>{t("noc.modal.solar.installedPower")}</th>
            <th>{t("noc.modal.solar.monthlyEconomy")}</th>
            <th>{t("noc.modal.solar.avoidedEmissions")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{moment(general.processedAt * 1000).format("DD/MM/YYYY HH:mm:ss") || ""}</td>
            <td>{formatEnergy(general.dailyEnergy) || ""}</td>
            <td>{formatEnergy(general.monthlyEnergy) || ""}</td>
            <td>{formatEnergy(general.lifetimeEnergy) || ""}</td>
            <td>{formatPower(general.currentPower) || ""}</td>
            <td>{general.capacity || ""} kWp</td>
            <td>{formatIncome(general.income) || ""}</td>
            <td>{formatAvoidedCO2(general.avoidedCO2) || ""}</td>
          </tr>
        </tbody>
      </Table>
      <Carousel interval={null} controls className="carouselButton">
        {monthlyDataEnergy && (
          <Carousel.Item>
            <Card>
              <EnergyYield
                projectId={item.projectId}
                month={new Date()}
                data={data}
              />
            </Card>
          </Carousel.Item>
        )}
        {dailyDataEnergy && (
          <Carousel.Item>
            <Card>
              <CurrentDayPower data={data} />
            </Card>
          </Carousel.Item>
        )}
        {Boolean(data.general?.hasSolarimeter) && (
          <Carousel.Item>
            <Card>
              <SolarimetricaRealTime solarimeter={general.solarimeter} />
            </Card>
          </Carousel.Item>
        )}
        {Boolean(data.general?.hasSolarimeter) && (
          <Carousel.Item>
            <Card>
              <GeraçãoXirradiacao data={data} />
            </Card>
          </Carousel.Item>
        )}
        {Boolean(data.general?.hasTracker) ? (
          <Carousel.Item>
            <Card>
              <Trackers data={data} />
            </Card>
          </Carousel.Item>
        ) : null}
        {general && (
          <Carousel.Item>
            <Card>
              <PerformanceCard data={data} />
            </Card>
          </Carousel.Item>
        )}
        {monthlyDataEnergy && (
          <Carousel.Item>
            <Card>
              <Availability month={new Date()} data={data} />
            </Card>
          </Carousel.Item>
        )}
      </Carousel>
    </>
  );
};
