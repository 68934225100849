import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Zap } from "react-feather";
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
// import '../solarModule/DailyEnergy.scss';
// import '../../../../assets/scss/6-solar/DailyEnergy.scss'

const DailyEnergy = ({ dailyEnergy, loading, loadingModal }) => {
  const { t } = useTranslation();

  var dailyEnergyEnergyAndUnit = (dailyEnergy < 1000)
    ? (dailyEnergy.toFixed(2)).replace(".", ",") + " kWh"
      : (dailyEnergy < 1000000)
        ? (parseFloat((dailyEnergy/1000).toFixed(2))).toString().replace(".", ",") + " MWh"
          : (dailyEnergy < 1000000000)
            ? (parseFloat((dailyEnergy/1000000).toFixed(2))).toString().replace(".", ",") + " GWh"
              : (dailyEnergy < 1000000000000)
                ? (parseFloat((dailyEnergy/1000000000).toFixed(2))).toString().replace(".", ",") + " TWh"
                : ""

  return (
    <Row style={{minWidth: "147px"}}>
      <Col sm="12" style={{paddingRight:"0px"}}>
        {/* <div className="flip-container">
          <div className="flipper">
            <div className="front"> */}
              <Card className="solar-top-card">
                <CardBody style={{padding: "15px"}}>
                  <Row>
                    <Col className="mt-0">
                      <CardTitle tag="h5">{t('solar.energy')}<br></br>{t('solar.daily')}</CardTitle>
                    </Col>
                    <Col className="col-auto">
                      <div style={{width:"26px",height:"26px"}}>
                        <div className="avatar-title rounded-circle bg-primary-dark">
                          <Zap className="feather align-middle" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {(loadingModal)
                    ? <div className="text-center mb-4"><Spinner /></div>
                    : (loading)
                      ? <div className="text-center mb-4"> <Spinner color="primary"/></div>
                      : (dailyEnergy)
                        ? <h1 className="display-5 text-center" style={{fontSize:"17px", fontWeight:"500"}}>{dailyEnergyEnergyAndUnit}</h1>
                        : <p className="text-center">Não há dados</p>
                  }
                </CardBody>
              </Card>
            {/* </div> */}
            {/* <div className="back">
              <Card style={{ minWidth: "110px", maxHeight:"117px", height: "117px"}}>
                <CardHeader style={{paddingBottom: "0px", paddingTop: "30px" }}>
                  <h5>
                    Highlights
                  </h5>
                </CardHeader>
                <CardBody>
                  <h5>
                    Aumentou 5 %
                  </h5>
                </CardBody>
              </Card>
            </div> */}
          {/* </div>

        </div> */}

      </Col>
    </Row>
  );
}   

export default DailyEnergy;