/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import {
  Button,
  CardHeader,
  CardText,
  CardTitle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import {
  formatTimestamps,
  getStartEndDays,
  getStartEndMonth,
} from "../../../../../../../src/utils/functions.js";
import { readPowerFactorChartUc } from "../ConsumerUnitService";
import { useConsumerUnitContext } from "../ConsumerUnitProvider";
import { Maximize2 } from "react-feather";
import { usePlantContext } from "../../PlantProvider.js";
const moment = require("moment");

export const PowerFactorChart = ({
  windowObervable,
  isModal = false,
  viewChart,
}) => {
  const { t } = useTranslation();

  const { deviceId } = useConsumerUnitContext();
  const { selectedDate } = usePlantContext();

  const [loadingChart, setLoadingChart] = useState(false);
  const [chart, setChart] = useState(null);
  const [start, setStart] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState(moment().endOf("month").format("YYYY-MM-DD"));
  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  useEffect(() => {
    if (deviceId) getChartData();
  }, [deviceId, start, end]);

  useEffect(() => {
    if (selectedDate) getMonthSelected();
  }, [selectedDate]);

  const getChartData = async () => {
    setLoadingChart(true);
    const chart = await readPowerFactorChartUc(deviceId, start, end);
    setChart(chart);
    setLoadingChart(false);
  };

  async function getMonthSelected() {
    setLoadingChart(true);
    const { start, end } = await getStartEndMonth(selectedDate);
    const chart = await readPowerFactorChartUc(deviceId, start, end);
    setChart(chart);
    setLoadingChart(false);
  }

  async function getPeriod(model) {
    const { start, end } = await getStartEndDays(selectedDate, model);
    setStart(start);
    setEnd(end);
  }

  const timestamps = chart ? formatTimestamps(chart.powerFactorTimestamp) : [];
  let data = [];

  timestamps.map((element, index) =>
    data.push({
      name: element,
      [`${t("charts.inductive")}`]:
        chart?.powerFactorIndutive[index] === 0
          ? 0
          : 1 - chart?.powerFactorIndutive[index],
      [`${t("charts.capacitive")}`]:
        chart?.powerFactorCapacitive[index] === 0
          ? 0
          : -1 * (1 - chart?.powerFactorCapacitive[index]),
    })
  );

  const isOneYAxis = data.some((value) => {
    return (
      value[`${t("charts.inductive")}`] >= 0.5 ||
      value[`${t("charts.capacitive")}`] <= -0.5
    );
  });

  const tooltipFormatter = (value, name, props) => {
    if (
      name === `${t("charts.inductive")}` &&
      props.payload[`${t("charts.inductive")}`] !== 0
    ) {
      const inductiveValue = 1 - props.payload[`${t("charts.inductive")}`];
      return [`${t("charts.inductive")}: ${inductiveValue.toFixed(2)}`];
    } else if (
      name === `${t("charts.capacitive")}` &&
      props.payload[`${t("charts.capacitive")}`] !== 0
    ) {
      const capacitiveValue =
        -1 * (1 + props.payload[`${t("charts.capacitive")}`]);
      return [`${t("charts.capacitive")}: ${capacitiveValue.toFixed(2)}`];
    } else if (name === `${t("charts.inductive")}`) {
      return [`${t("charts.inductive")}: ${t("charts.noData")}`];
    } else if (name === `${t("charts.capacitive")}`) {
      return [`${t("charts.capacitive")}: ${t("charts.noData")}`];
    }
  };

  const yAxisTickFormatter = (value) => {
    // NOTE: Mapeia os valores originais para os valores desejados
    const mapping = {
      1: 0,
      0.5: 0.5,
      0.25: 0.75,
      0: 1,
      "-0.25": -0.75,
      "-0.5": -0.5,
      "-1": -0,
    };
    return `${mapping[value]}`;
  };

  return (
    //NOTE: Só exibe se existir a estrutura de energia do driver ou estrutura de energia personalizada
    viewChart || chart?.viewChart ? (
      <div
        className="my-2"
        style={{ width: "100%", minHeight: "470px", paddingBottom: 32 }}
      >
        <CardHeader className="d-flex align-content-center justify-content-between">
          <CardTitle tag="h5" style={{ marginBottom: "0px" }}>
            {t("charts.chartPowerFactor")}
          </CardTitle>
          {!isModal && (
            <Button
              className="bg-transparent border-0"
              id={"button-maximize-dashboard"}
              onClick={handleToggleModal}
            >
              <Maximize2 size={16} color="grey" />
              {isModal && (
                <UncontrolledTooltip target={"button-maximize-dashboard"}>
                  {t("public.expand")}
                </UncontrolledTooltip>
              )}
            </Button>
          )}
        </CardHeader>
        {loadingChart ? (
          <div
            className="d-flex justify-content-center align-items-center h-100"
            style={{
              height: windowObervable < 1400 ? "16rem" : "20rem",
              padding: 32,
            }}
          >
            <Spinner />
          </div>
        ) : (
          <>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  type="number"
                  domain={isOneYAxis ? [-1, 1] : [-0.5, 0.5]}
                  tickFormatter={yAxisTickFormatter}
                />
                <Tooltip formatter={tooltipFormatter} />
                <Legend style={{ fontSize: "16px" }} />
                <ReferenceLine y={1} stroke="#000" />
                <Bar dataKey={`${t("charts.inductive")}`} fill="#8884d8" />
                <Bar dataKey={`${t("charts.capacitive")}`} fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>

            <div
              className="d-flex justify-content-center px-5 pt-4"
              style={{ gap: "4px" }}
            >
              {
                //NOTE: Só exibe estes filtros, caso for o mês atual
                moment().month() === moment(selectedDate).month() && (
                  <>
                    <CardText role="button">
                      <Button
                        size="md"
                        color="primary"
                        onClick={() => getMonthSelected()}
                      >
                        {t("charts.month")}
                      </Button>
                    </CardText>
                    <CardText role="button">
                      <Button
                        size="md"
                        color="primary"
                        onClick={() => getPeriod("week")}
                      >
                        {t("charts.lastDays")}
                      </Button>
                    </CardText>
                    <CardText role="button">
                      <Button
                        size="md"
                        color="primary"
                        onClick={() => getPeriod("day")}
                      >
                        {t("charts.today")}
                      </Button>
                    </CardText>
                  </>
                )
              }
            </div>
            {toggleModal && (
              <Modal isOpen={toggleModal} toggle={handleToggleModal} size="lg">
                <ModalBody>
                  <PowerFactorChart isModal={true} />
                </ModalBody>
              </Modal>
            )}
          </>
        )}
      </div>
    ) : null
  );
};
