const GasModuleENTranslations = {
    gasModule:{
        plant:{
            consumption: "Consumption",
            ModalGasReport: "Gas Report",
            ModalGasConfigure: "Gas Module Settings"
        },
        modal:{
            consumptionUnits:{
                name: "Name",
                action: "Action",
                clickIn: "Click on",
                toEditUnit: "to edit the unit",
                toAddUnit: "to add a new unit",
                toManageRegisters: "to manage your meters",
            }
        }
    }
}

export default GasModuleENTranslations;