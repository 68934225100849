const ptBrDataExplorerTranslations = {
  dataExplorer: {
    noData: "Não há dados.",
    noProject: "Não há projetos registrados.",
    graphics: "Gráficos",
    dataTable: "Tabela de dados",
    parameters: "Parâmetros",
    start: "Início",
    end: "Fim",
    projectsDevices: "Projetos/Dispositivos",
    projects: "Projetos",
    project: "Projeto",
    devices: "Dispositivos",
    device: "Dispositivo",
    toView: "Visualizar",
    selectVariables: "Selecione as Variáveis",
    variablesSelected: "Variáveis Selecionadas",
    select: "Selecionar",
    remove: "Remover",
    selected: "Selecionados",
    available: "Disponíveis",
    variable: "Variável",
    variables: "Variáveis",
    search: "Pesquisar",
    customizeColors: "Personalizar Cores",
    attention: "Atenção",
    error: "Erro",
    errorFetchingData: "Erro ao buscar os dados.",
    success: "Sucesso",
    warningStartEndDate: "O início deve ser anterior ao final.",
    warningResolutionDate: "A resolução não pode ser maior que o tempo de dados selecionado.",
    aggregation: "Agregação",
    resolution: "Resolução",
    selectAggregation: "Selecione uma agregação",
    selectResolution: "Selecione uma resolução",
    typeVariable: "Tipo de variável",
    selectTypeVariable: "Selecione um tipo de valor para variável",
    integral: "Integral",
    differential: "Diferencial",
    first: "Primeiro",
    last: "Último",
    maximum: "Máximo",
    minimum: "Mínimo",
    average: "Média",
    sum: "Soma",
    none: "Nenhum",
    minutes: "Minutos",
    hours: "Horas",
    timestamp: "Data/Hora",
    maxSizeInBytes: "Excesso de dados para o dashboard",
    loading: "Carregando...",
    export: "Exportar",
    nextPage: "Próxima",
    previousPage: "Anterior",
  },
};

export default ptBrDataExplorerTranslations;
