import { t } from "i18next";
import { useState } from "react";
import { Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { resetPlant } from "../../PlantService";
import { usePlantContext } from "../../PlantProvider";
import { resetValidateFields } from "../../../../../../utils/functions";

const ModalDeletePlantData = ({ open, toggle, id, name }) => {
  const { reload, setLoadingData } = usePlantContext();

  const [captcha, setCaptcha] = useState('');
  const [validateFields, setValidateFields] = useState([{
    path: null, 
    msg: null
  }]);

  async function onSubmitForm() {
    setValidateFields([]);
    const reponse = await resetPlant(setValidateFields, {captcha, projId: id});
    if(reponse?.status !== 400) {
      toggle();
      setLoadingData(true);
      reload();
    }
  }; 

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
          onSubmitForm();
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('subMenu.deletePlant')} {name}
        </ModalHeader>

        <ModalBody className="text-center mx-6">
          <div className="m-4">
            <p className="mb-0">
              {t('dashboards.textDeleteDash')}
            </p>
            <Input
              type="text"
              name="captcha"
              id="captcha"
              value={captcha}
              onChange={e => setCaptcha(e.target.value)}
              onFocus={(e) => {
                resetValidateFields("captcha", validateFields, setValidateFields);
              }}
            />
            {validateFields.map((item, index) => {
              return item.path === "captcha" ? (
                <small key={index} className="text-danger d-block">
                  {item.msg}
                </small>  
              ) : null;
            })}
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            color='primary'
            onClick={toggle}
            outline
          >
            {t('dataTable.cancel')}
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            {t('dashboards.delete')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalDeletePlantData