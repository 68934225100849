import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

//NOTE: Exemplo de options
// const optionsExample = [
//   {id: 1, description: "Opção 1"},
// ]

export const MultiSelect = ({ options, setValue, values, disabled = true }) => {

  const { t } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(values || []);
  const [showValues, setShowValues] = useState([]);

  const toggleDropdown = () => {
    if (disabled) return;
    setDropdownOpen((prevState) => !prevState);
  };

  const handleOptionClick = (option) => {
    const isSelected = selectedOptions.includes(option.id);

    if (isSelected) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option.id));
      setValue((prev) => prev.filter((item) => item !== option.id));
      setShowValues(showValues.filter((item) => item !== option.description));
    } else {
      setSelectedOptions([...selectedOptions, option.id]);
      setValue((prev) => (prev ? [...prev, option.id] : [option.id]));
      setShowValues([...showValues, option.description]);
    }
  };

  useEffect(() => {
    const mappedValues =
      options?.map((e) => {
        if (values.includes(e.id)) return String(e.description);
        return null;
      }) || [];

    const filteredValues = mappedValues.filter((value) => value !== null);

    setShowValues(filteredValues);
    setSelectedOptions(values);
  }, [options, values]);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle
        tag="p"
        style={{
          border: "1px solid #ced4da",
          padding: "2px 16px",
          borderRadius: "4px",
          color: "#545a61",
          backgroundColor: disabled ? "#e9ecef" : "transparent",
        }}
      >
        {showValues.length ? showValues.join(", ") : "Selecione"}
      </DropdownToggle>
      <DropdownMenu>
        {options?.map((option) => (
          <DropdownItem
            key={option.id}
            onClick={() => handleOptionClick(option)}
            style={{ display: "flex", alignItems: "center", gap: "4px" }}
          >
            <input
              type="checkbox"
              readOnly
              checked={selectedOptions.includes(option.id)}
            />
            {option.description || "Sem nome informado..."}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
