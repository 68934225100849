import React, { useEffect } from "react";
import { Card, CardBody, Col, Label, Row, Spinner } from "reactstrap";

import { useTranslation } from "react-i18next";
import FilteredDevices from "../../../DeviceVariables";
import { DeviceView } from "./DeviceView";

export const DevicesCards = ({
  open,
  toggle,
  devices,
  loadingDevices,
  solarFilter,
  trackerFilter,
  solarimeterFilter,
  handleFilterDevices,
  filteredDevices,
  setFilteredDevices,
  nobreakFilter,
  climateFilter,
  utilitiesFilter,
  gatewayFilter,
  activeTab,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (solarFilter) {
      handleFilterDevices("S");
    }
    if (trackerFilter) {
      handleFilterDevices("T");
    }
    if (solarimeterFilter) {
      handleFilterDevices("M");
    }
    if (nobreakFilter) {
      handleFilterDevices("NB");
    }
    if (climateFilter) {
      handleFilterDevices("C");
    }
    if (utilitiesFilter) {
      handleFilterDevices("U");
    }
    if (gatewayFilter) {
      handleFilterDevices("G");
    }
    // eslint-disable-next-line
  }, [
    loadingDevices,
    setFilteredDevices,
    solarFilter,
    trackerFilter,
    solarimeterFilter,
    nobreakFilter,
    climateFilter,
    utilitiesFilter,
    gatewayFilter,
  ]);

  // retorna cards dos dispositivos
  return devices.length && activeTab === "default" ? (
    <div>
      <Row className="d-flex flex-row">
        {(!filteredDevices.length && solarFilter) ||
        (!filteredDevices.length && trackerFilter) ||
        (!filteredDevices.length && solarimeterFilter) ||
        (!filteredDevices.length && nobreakFilter) ||
        (!filteredDevices.length && climateFilter) ||
        (!filteredDevices.length && utilitiesFilter) ||
        (!filteredDevices.length && gatewayFilter) ? (
          filteredDevices.length === 0 ? (
            <Card>
              <CardBody
                style={{
                  height: "152px",
                  marginInline: "16px",
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              >
                <Label>{t("devices.noDevicesTypes")}</Label>
                {/* <Button onClick={() => setFilteredDevices(false)}>Remover Filtro</Button> */}
              </CardBody>
            </Card>
          ) : (
            <CardBody className="text-center">
              <Spinner color="primary" />
            </CardBody>
          )
        ) : filteredDevices.length > 0 ? (
          filteredDevices.map((device, index) => (
            <Col key={index} xl="3" lg="6" md="6" sm="6" xs="12">
              <DeviceView
                device={device}
                devices={filteredDevices}
                open={open}
                toggle={toggle}
                filteredDevices={filteredDevices}
                nobreakFilter={nobreakFilter}
                solarFilter={solarFilter}
                climateFilter={climateFilter}
                utilitiesFilter={utilitiesFilter}
                gatewayFilter={gatewayFilter}
              />
            </Col>
          ))
        ) : (
          devices.map((device, index) => (
            <Col key={index} xl="3" lg="4" md="6" sm="6" xs="12">
              <DeviceView
                device={device}
                devices={devices}
                open={open}
                toggle={toggle}
                filteredDevices={filteredDevices}
                nobreakFilter={nobreakFilter}
                solarFilter={solarFilter}
                climateFilter={climateFilter}
                utilitiesFilter={utilitiesFilter}
                gatewayFilter={gatewayFilter}
              />
            </Col>
          ))
        )}
      </Row>
    </div>
  ) : (
    // card explicando que nao foram encontrados dispositivos
    <div>
      <CardBody>{t("devices.noDevices")}</CardBody>
    </div>
  );
};
