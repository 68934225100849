import {
  faBolt,
  faBuilding,
  faChargingStation,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRefMeterContext } from "./RefMeterProvider";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardTitle,
  Col,
  Badge,
  Row,
} from "reactstrap";
import { BarChartConsumption } from "./components/BarChartConsumption";
import { BarChartDemand } from "./components/BarChartDemand";
import { BarChartReactiveConsumed } from "./components/BarChartReactiveConsumed";
import { DonutChartConsumption } from "./components/DonutChartConsumption";
import { ReportBillBarChartDemand } from "./components/ReportBillBarChartDemand";
import { ReportBillBarChartConsumption } from "./components/ReportBillBarChartConsumption";
import { PowerFactorChart } from "./components/PowerFactorChart";
import StatusCard from "./components/StatusCard";
import { getScreenshotImage } from "../../../../../utils/functions";
import nullImage from "../../../../../assets/devices/noDevice.png";

export const RefMeter = () => {
  const { t } = useTranslation();
  const [windowObservable, setWindowObservable] = useState();

  const {
    plantData,
    params,
    deviceInfo,
    reportBillBarChart,
    setBarChartDemandScreenshoot,
    setBarChartConsumptionScreenshoot,
  } = useRefMeterContext();

  // NOTE: Capturar gráficos para relatório da fatura de energia
  useEffect(() => {
    let timer;
    timer = setTimeout(() => {
      ["reportBillBarChartDemand", "reportBillBarChartConsumption"].forEach(
        (id) => {
          getScreenshotImage(id)
            .then((canvas) => {
              id === "reportBillBarChartDemand"
                ? setBarChartDemandScreenshoot(canvas)
                : setBarChartConsumptionScreenshoot(canvas);
            })
            .catch((error) => {
              console.error(
                `Error during screenshot capture for ${id}:`,
                error
              );
            });
        }
      );
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportBillBarChart]);

  useEffect(() => {
    function handleResize() {
      setWindowObservable(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWindowObservable]);

  return (
    <>
      <header>
        <Row className="mb-4 ml-0">
          <Col lg={7}>
            <Row
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridTemplateRows: "1fr 1fr",
                gap: "12px",
              }}
            >
              <StatusCard
                title={t("cards.monthlyConsumption")}
                icon={faBolt}
                keyValue2={t("cards.offPeak")}
                value2={`${Math.floor(
                  plantData?.totalOffPeakConsumption ?? 0
                ).toLocaleString()} kWh`}
                keyValue={t("cards.peak")}
                value={`${Math.floor(
                  plantData?.totalPeakConsumption ?? 0
                ).toLocaleString()} kWh`}
              />
              <StatusCard
                title={t("cards.monthlyConsumptionRe")}
                icon={faBolt}
                keyValue2={t("cards.offPeak")}
                value2={`${Math.floor(
                  plantData?.totalOffPeakConsumptionRe ?? 0
                ).toLocaleString()} kVAr`}
                keyValue={t("cards.peak")}
                value={`${Math.floor(
                  plantData?.totalPeakConsumptionRe ?? 0
                ).toLocaleString()} kVAr`}
              />
              <StatusCard
                title={t("cards.maxDemand")}
                icon={faBolt}
                keyValue2={t("cards.offPeak")}
                value2={`${plantData?.maxOffPeakDemand?.toLocaleString(
                  "pt-br",
                  {
                    currency: "BRL",
                  }
                )} kW`}
                keyValue={t("cards.peak")}
                value={`${plantData?.maxPeakDemand?.toLocaleString("pt-br", {
                  currency: "BRL",
                })} kW`}
              />
              <StatusCard
                title={t("cards.powerFactor")}
                icon={faBolt}
                keyValue={t("cards.avgInductive")}
                value={`${plantData?.avgPowerFactorInductive
                  ?.toFixed(2)
                  ?.toLocaleString("pt-BR", { currency: "BRL" })}`}
                keyValue2={t("cards.avgCapacitive")}
                value2={`${plantData?.avgPowerFactorCapacitive
                  ?.toFixed(2)
                  ?.toLocaleString("pt-BR", { currency: "BRL" })}`}
              />
              <StatusCard
                title={t("cards.chargeFactor")}
                icon={faChargingStation}
                keyValue2={t("cards.offPeak")}
                value2={`${
                  plantData
                    ? (plantData?.chargeFactorOffPeak * 100)?.toFixed(2)
                    : ""
                } %`}
                keyValue={t("cards.peak")}
                value={`${
                  plantData
                    ? (plantData?.chargeFactorPeak * 100)?.toFixed(2)
                    : ""
                } %`}
              />
              <StatusCard
                title={t("cards.monthlyBill")}
                icon={faMoneyBill}
                keyValue={t("cards.totalBill")}
                value={`R$ ${plantData?.invoice?.toLocaleString("pt-BR", {
                  currency: "BRL",
                })}`}
              />
            </Row>
          </Col>
          <Col lg={3}>
            <Card className="m-0">
              <CardHeader className="pb-0">
                <CardTitle tag="h5" className="mb-2 overflow-hidden">
                  {deviceInfo?.description || t("homePlant.none")}
                </CardTitle>
                <div className="card-subtitle mb-2 text-muted d-flex overflow-hidden">
                  {deviceInfo?.model || t("homePlant.none")}
                </div>
              </CardHeader>
              <CardBody className="p-0">
                <CardImg
                  className="p-0"
                  src={deviceInfo?.image ? deviceInfo?.image : nullImage}
                  style={{
                    width: "150px",
                    marginLeft: "50%",
                    transform: "translateX(-50%)",
                  }}
                />
              </CardBody>
              <CardFooter className="pt-0">
                <div>
                  <Badge
                    className="badge-pill mr-1 mb-1"
                    style={{
                      background:
                        deviceInfo?.lastComm >=
                        Math.floor(Date.now() / 1000) - deviceInfo?.commInterval
                          ? "green"
                          : "red",
                    }}
                  >
                    {deviceInfo?.lastComm >=
                    Math.floor(Date.now() / 1000) - deviceInfo?.commInterval
                      ? "Online"
                      : "Offline"}
                  </Badge>
                </div>
                <div style={{ fontSize: "12px" }}>
                    {t("public.lastComm")}: <br />
                  <span
                  className="display-5 mt-2 mb-4 text-center"
                  style={{ fontSize: "18px", color: "#203A45" }}
                  >
                  {new Date(deviceInfo?.lastComm * 1000).toLocaleString() ||
                    t("homePlant.none")}
                  </span>
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg={2}>
            <Card>
              <DonutChartConsumption windowObservable={windowObservable} />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
              <StatusCard
                title={t("cards.contract")}
                icon={faBuilding}
                keyValue={t("cards.distributor")}
                value={`${plantData?.distributor ? plantData?.distributor : ""}`}
                keyValue2={t("cards.flag")}
                value2={`${plantData?.flag ? plantData?.flag : ""}`}
                keyValue3={t("cards.peakContractedDemand")}
                value3={`${params?.peakContractedDemand?.toLocaleString("pt-br", {
                  currency: "BRL",
                })} kW`}
                keyValue4={t("cards.offPeakContractedDemand")}
                value4={`${params?.offPeakContractedDemand?.toLocaleString(
                  "pt-br",
                  {
                    currency: "BRL",
                  }
                )} kW`}
              />
          </Col>
        </Row>
      </header>
      <Row>
      <Col sm="12" lg="5" xl="5.5">
        <Card style={{ minHeight: windowObservable >= 992 ? 'calc(100% - 1.5em)' : 'auto' }}>
          <BarChartConsumption
            windowObservable={windowObservable}
            dvcId={plantData?.refMeter}
            viewChart={plantData?.viewChart.barChartConsumption}
          />
        </Card>
      </Col>
      <Col sm="12" lg="7" xl="6.5">
        <Card>
          <BarChartDemand
            windowObservable={windowObservable}
            dvcId={plantData?.refMeter}
            origin='refMeter'
            viewChart={plantData?.viewChart.barChartDemand}
          />
        </Card>
      </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col sm="12" lg="6">
          <Card>
            <BarChartReactiveConsumed
              windowObservable={windowObservable}
              dvcId={plantData?.refMeter}
              viewChart={plantData?.viewChart.barChartReactiveConsumed}
            />
          </Card>
        </Col>
        <Col sm="12" lg="6">
          <Card>
            <PowerFactorChart
              windowObservable={windowObservable}
              dvcId={plantData?.refMeter}
              viewChart={plantData?.viewChart.powerFactor}
            />
          </Card>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col sm="12" md="7">
          {/* //NOTE: Gráfico de histórico de demanda anual apenas para screenshot */}
          <Card
            style={{ background: "none", border: "none", boxShadow: "none" }}
          >
            <div id="reportBillBarChartDemand" style={{ position: "relative" }}>
              <ReportBillBarChartDemand
                data={reportBillBarChart}
                windowObservable={windowObservable}
              />
            </div>
            {/* //NOTE: Sobreposição ao gráfico */}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#F4F7FC",
                zIndex: 1,
              }}
            ></div>
          </Card>
          {/* //NOTE: Gráfico de histórico de consumo anual apenas para screenshot */}
          <Card
            style={{ background: "none", border: "none", boxShadow: "none" }}
          >
            <div
              id="reportBillBarChartConsumption"
              style={{ position: "relative" }}
            >
              <ReportBillBarChartConsumption
                data={reportBillBarChart}
                windowObservable={windowObservable}
              />
            </div>
            {/* //NOTE: Sobreposição ao gráfico */}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#F4F7FC",
                zIndex: 1,
              }}
            ></div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
