import axios from "axios";
import { useTranslation } from "react-i18next";
import { BrasilFlag, USFlag } from "../../../assets/flags";

export const FlagsDropdown = () => {
  const { i18n } = useTranslation();

  return (
    <Flag image={i18n.language === "br" ? BrasilFlag : USFlag} isSelected />
  );
};

const Flag = ({ image, isSelected, ...props }) => (
  <img
    alt="flag"
    src={image}
    style={{ borderRadius: "20%", marginLeft: "-2px" }}
    className={isSelected ? "flag selected" : "flag"}
    {...props}
  />
);

export const TranslateFlags = () => {
  const { i18n } = useTranslation();

  function updateLanguage(lang) {
    axios
      .put(
        "updateLanguage/",
        JSON.stringify({
          language: lang,
        })
      )
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  }

  return (
    <div className="flags-container">
      <div className="mx-1 my-0">
        <Flag
          image={BrasilFlag}
          isSelected={i18n.language === "br"}
          onClick={() => {
            i18n.changeLanguage("br");
            localStorage.setItem("defaultLanguage", "br");
            updateLanguage("br");
          }}
        />
      </div>

      <div className="mx-1 my-0">
        <Flag
          image={USFlag}
          isSelected={i18n.language === "en"}
          onClick={() => {
            i18n.changeLanguage("en");
            localStorage.setItem("defaultLanguage", "en");
            updateLanguage("en");
          }}
        />
      </div>
    </div>
  );
};
