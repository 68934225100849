import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TreeSelect } from 'antd';
import BootstrapTable from "react-bootstrap-table-next";

import {
  Badge, Button, Row, Col,
  Breadcrumb, BreadcrumbItem,
  Card, CardBody, CardHeader, CardTitle,
  Container, ButtonGroup, ButtonToolbar,
  Spinner, Form, FormGroup, Label, Input,
  UncontrolledTooltip
} from "reactstrap";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faEnvelope,
  faPhone,
  faSearch,
  faSort
} from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useTranslation } from 'react-i18next';

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { SHOW_PARENT } from "rc-tree-select";
import paginationFactory from "react-bootstrap-table2-paginator";




/*** Badge de prioridade ***/
const PriorityBadge = ({ priority }) => {
  const { t } = useTranslation();

  return (priority === 1)
    ? <Badge color="danger">{t('alarms.high')}</Badge>
    : (priority === 2)
      ? <Badge color="warning">{t('alarms.medium')}</Badge>
      : <Badge color="primary">{t('alarms.low')}</Badge>
};

/*** Ícones de contato ***/
const ContactIcons = (alarm) => {
  const { t } = useTranslation();
  
  return <div className="d-flex justify-content-left">
    {(alarm.alarm.email)
      ? <div id={"mail" + alarm.alarm.id}>
        <FontAwesomeIcon fixedWidth icon={faEnvelope} className="mx-1" />
        <UncontrolledTooltip
          placement="top"
          target={"mail" + alarm.alarm.id}
        >
          E-mail
        </UncontrolledTooltip>
      </div>
      : null
    }
    {(alarm.alarm.phone)
    
      ? <div id={"phone" + alarm.alarm.id}>
        <FontAwesomeIcon fixedWidth icon={faPhone} className="mx-1" />
        <UncontrolledTooltip
          placement="top"
          target={"phone" + alarm.alarm.id}
        >
         
          {t('alarms.phone')}
        </UncontrolledTooltip>
      </div>
      : null
    }
    {(alarm.alarm.whatsapp)
      ? <div id={"whatsapp" + alarm.alarm.id}>
        <FontAwesomeIcon fixedWidth icon={faWhatsapp} className="mx-1" color="green" />
        <UncontrolledTooltip
          placement="top"
          target={"whatsapp" + alarm.alarm.id}
        >
          WhatsApp
        </UncontrolledTooltip>
      </div>
      : null
    }
  </div>
};

/*** Tabela ***/
const AlarmConfigTable = () => {
  const { t } = useTranslation();

  const [loadConfig, setLoadConfig] = useState(true);
  const [alarmConfig, setAlarmConfig] = useState([]);
  const [loadAlarms, setLoadAlarms] = useState(true);
  const [alarms, setAlarms] = useState([]);
  const [isMore, setMore] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [analogic , setAnalogic] = useState(true)
  const [digital , setDigital] = useState(true)
  const [multiples , setMultiples] = useState(true)
  const [high, setHigh] = useState(true);
  const [moderate, setModerate] = useState(true);
  const [low, setLow] = useState(true);
  const [searchField, setSearchField] = useState("");
  const [treeOptions, setTreeOptions] = useState();
  const [treeValue, setTreeValue] = useState([]);
  const [projects, setProjects] = useState([]);
  const [devices, setDevices] = useState([]);
  const sizePerPage = 10; // alarmes por página
  const maxAlarms = 30; // máximo de alarmes por chamada
  const [phone, setPhone] = useState(false)
  const [whatsapp, setWhatsapp] = useState(false)
  const [email, setEmail] = useState(false)




  useEffect(() => {
    if (loadConfig) {
      
      axios.get('/alarmConfigUserParamsSearch')
        .then(res => {
          setAlarmConfig(res.data);
          setLoadConfig(false);
          
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);

          setLoadConfig(false);
        })
    }
  }, [loadConfig]);

  useEffect(() => { // busca alarmes
    if (loadAlarms) {
      axios.get('/alarmSearch', {
        params: {
          offset: 0,
          maxAlarms: maxAlarms
        }
      })
        .then(res => {
          
          setAlarms(res.data.alarms);
          setMore(res.data.isMore);
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);

          toastr.warning(t('alarms.error'), t('alarms.errorLoadAlarms'));
        })
        .finally(() => setLoadAlarms(false));
    }
  }, [maxAlarms, t, loadAlarms]);

  useEffect(() => { // busca projetos do usuário
    axios.get('/projectUserSearch')
      .then(res => {
        let optAux = [];
        if (res.data.length > 0) {
          res.data.forEach(proj => {
            const str = 'proj' + proj.id;
            optAux.push({
              title: proj.name,
              value: str,
              key: str,
              id: str,
              isLeaf: false,
              checkable: true,
              children: []
            });
          });
        }
        setTreeOptions(optAux);
      })
      .catch(err => {
        console.log(err);
        console.log(err.response);
      });
  }, []);

// COLUNAS DA TABELA
  const alarmConfigCols = [
    { // ID (oculto)
      dataField: "id",
      text: `${t('alarms.id')}`,
      sort: true,
      hidden: true,
      isKey: true
    },
    { // descricao
      dataField: "description",
      text: t('alarms.name'),
      sort: true
    },
    { // nome do dispositivo
      dataField: "deviceName",
      text: t('alarms.deviceName'),
      sort: true
    },
    { // variável
      dataField: "varName",
      text: t('alarms.varName')
    },
    { // tipo
      dataField: "type",
      text: t('alarms.type'),
      formatter: (cell) => (cell)
        ? 'D'
        : 'A'
    },
    { // limite low
      dataField: "lowLimit",
      text: t('alarms.lowLimit'),
      formatter: (cell, row) => (cell === null)
        ? '-'
        : cell + ((row.varUnit) ? ' ' + row.varUnit : null)
    },
    { // limite high
      dataField: "highLimit",
      text: t('alarms.highLimit'),
      formatter: (cell, row) => (cell === null)
        ? '-'
        : cell + ((row.varUnit) ? ' ' + row.varUnit : null)
    },
    { // prioridade
      dataField: "priority",
      text: t('alarms.priority'),
      formatter: cell => <PriorityBadge priority={cell} />
    },
    { // contato
      dataField: "contact",
      text: t('alarms.contact'),
      headerStyle: {
        minWidth: "90px"
      },
      formatter: (_, row) => {
        if (!row.email && !row.phone && !row.whatsapp) {
          return "-"
        }

        return <ContactIcons alarm={row} />
      }
    },
    { // mensagem ON low
      dataField: "messageLowOn",
      text: t('alarms.messageLowOn'),
      formatter: cell => {
        return (cell === null)
          ? '-'
          : (cell.length > 20)
            ? cell.slice(0, 17) + "..."
            : cell
      }
    },
    { // mensagem ON high
      dataField: "messageHighOn",
      text: t('alarms.messageHighOn'),
      formatter: cell => {
        return (cell === null)
          ? '-'
          : (cell.length > 20)
            ? cell.slice(0, 17) + "..."
            : cell
      }
    },
    { // mensagem OFF low
      dataField: "messageLowOff",
      text: t('alarms.messageLowOff'),
      formatter: cell => {
        return (cell === null)
          ? '-'
          : (cell.length > 20)
            ? cell.slice(0, 17) + "..."
            : cell
      }
    },
    { // mensagem OFF high
      dataField: "messageHighOff",
      text: t('alarms.messageHighOff'),
      formatter: cell => {
        return (cell === null)
          ? '-'
          : (cell.length > 20)
            ? cell.slice(0, 17) + "..."
            : cell
      }
    }
  ]

  /* Legenda (Mostrando xx-xx de xx alarmes) */
  const TableLegend = ({ from, to, size }) => {
    const { t } = useTranslation();

    return (
      <span
        className="react-bootstrap-table-pagination-total"
        style={{
          display: (window.innerWidth < 700) ? "none" : "block",
          marginBottom: (window.innerWidth < 700) ? "15px" : "0"
        }}
      >
        {t('alarms.showing')} {from}-{to} {t('alarms.of')} {size} {t('alarms.results')}
      </span>
    );
  };

  /* Ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) => (!order)
      ? <FontAwesomeIcon
        className={'fa-xs ml-1'}
        icon={faSort}
      />
      : (order === 'asc')
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faCaretDown}
        />
        : <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faCaretUp}
        />
  }

  /** Carrega dispositivos do projeto ao expandir */
  function onLoadData(treeNode) {
    return new Promise(resolve => {
      const index = treeOptions.findIndex(item => {
        return item.key === treeNode.key
      });
      setTimeout(() => {
        if (treeNode.children.length) {
          resolve();
          return;
        } else {
          axios.get('/deviceSearch', {
            params: {
              projId: treeNode.key.split('proj')[1]
              // projId: treeNode.id
            }
          })
            .then(res => {
              let optAux = treeOptions;
              if (res.data.length > 0) {
                res.data.forEach(dev => {
                  const str = 'dev' + dev.id;
                  optAux[index].children.push({
                    title: dev.description,
                    value: str,
                    key: str,
                    id: str,
                    isLeaf: true,
                    checkable: true
                  });
                });
              }
              setTreeOptions();
              setTreeOptions(optAux);
            })
            .catch(err => {
              console.log(err);
              console.log(err.response);
            })
            .finally(() => {
              resolve();
              return
            });
        }
      }, 300);
    });
  };

  /** Atualiza estados de projeto e dispositivo selecionado */
  function onChange(checkedKeys) {
    setTreeValue(checkedKeys);
    if (checkedKeys.length > 0) {
      let projAux = [];
      let devAux = [];
      checkedKeys.forEach(key => {
        if (key.includes('proj')) {
          projAux.push(key.split('proj')[1]);
        } else if (key.includes('dev')) {
          devAux.push(key.split('dev')[1]);
        }
      })
      setProjects(projAux);
      setDevices(devAux);
    } else {
      setProjects([]);
      setDevices([]);
    }
  };

  /* Função de filtro avançado */
  function onFilterSearch() {
    setCurrPage(1);
    setLoadAlarms(true);
   
    
    axios.get('/parametersAlarms', {
      params: {
        analogic:analogic,
        digital:digital,
        multiples:multiples,
        high: high,
        moderate: moderate,
        low: low,
        projects: projects,
        devices: devices,
        searchField: searchField.toLowerCase(),
        whatsapp: whatsapp,
        phone:phone,
        email:email,
        offset: 0,
        maxAlarms: maxAlarms
      }
    })
      .then(response => {
      
        setAlarmConfig(response.data.alarms);  
        setMore(response.data.isMore);
        
      })
      .catch(err => {
        console.log(err);
        console.log(err.response);
      })
      .finally(() => setLoadAlarms(false));
  };

  /* Botões de Paginação */
  const CustomPagination = ({ pages, onPageChange }) => {
    let activePage = pages.find(item => item.active).page;
    
    return <Col
      className={"d-flex justify-content-" + ((window.innerWidth < 700) ? "start" : "end")}
      style={{
        position: (window.innerWidth < 700) ? "absolute" : "relative"
      }}
    >
      <ButtonToolbar className="mr-2">
        <ButtonGroup>
          {pages.map((p, i) => (p.page === ">" || p.page === ">>")
            ? <Button key={i}
              color="primary"
              outline={!p.active}
              disabled={!isMore && p.disabled}
              onClick={() => {
                // verifica se está na última página
                if (activePage === pages[pages.length - 3].page) {
                  setLoadAlarms(true);

                  axios.get('/parametersAlarms', {
                    params: {
                      analogic:analogic,
                      digital:digital,
                      multiples:multiples,
                      high: high,
                      moderate: moderate,
                      low: low,
                      projects: projects,
                      devices: devices,
                      searchField: searchField.toLowerCase(),
                      whatsapp: whatsapp,
                      phone:phone,
                      email:email,
                      offset: 0,
                      maxAlarms: maxAlarms
                    }
                  })
                    .then(res => {
                      setCurrPage((alarms.length % sizePerPage === 0)
                        ? activePage + 1
                        : activePage
                      )
                      let temp = alarms;
                      res.data.alarms.map(item => temp.push(item));
                      setAlarmConfig(temp);
                      setMore(res.data.isMore);
                    })
                    .catch(err => {
                      console.log(err);
                      console.log(err.response);
                    })
                    .finally(() => setLoadAlarms(false));
                }

                else {
                  onPageChange(p.page);
                }
              }}
            >
              {p.page}
            </Button>

            : <Button key={i}
              color="primary"
              outline={!p.active}
              disabled={p.disabled}
              onClick={() => onPageChange(p.page)}
            >
              {p.page}
            </Button>
          )}
        </ButtonGroup>
      </ButtonToolbar>
    </Col>
  };

  /* Opções de Paginação */
  const paginationOptions = {
    page: currPage,
    sizePerPage: sizePerPage,
    pageStartIndex: 1,
    paginationSize: 3,
    alwaysShowAllBtns: true, // Always show next and previous button,
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    hideSizePerPage: true,  //Hide the sizePerPage dropdown always,  
    disablePageTitle: true,
    hidePageListOnlyOnePage: true,//  Hide the pagination list when only one page,
    showTotal: true,
    pageListRenderer: ({ pages, onPageChange }) => <CustomPagination pages={pages} onPageChange={onPageChange} />,
    paginationTotalRenderer: (from, to, size) => <TableLegend from={from} to={to} size={size} />

  };

  
  return (
    <div>
      {/* Ferramentas de busca */}
      <div className="alarm-search">

        <Form

          onSubmit={e => {
            e.preventDefault();
            onFilterSearch();
          }}
        >
          <Row className="mb-2">
            {/* Busca por termo/projeto/prioridade */}
            <Col md={5}>
              {/* Busca por termo */}
              <FormGroup className="mb-0">
                <Label for="search" className="ml-1">
                  {t('alarms.searchField')}
                </Label>
                <Input
                  type="text"
                  name="search"
                  id="search"
                  placeholder={t('alarms.textPlaceholder')}
                  value={searchField}
                  onChange={e => {
                    e.preventDefault();
                    setSearchField(e.target.value)
                  }}
                />
              </FormGroup>

              {/* Busca por projeto */}
              <FormGroup className="mb-0 mt-2">
                <Label for="project" className="ml-1">
                  {t('alarms.searchProject')}
                </Label>
                <TreeSelect
                  id="project"
                  treeDataSimpleMode
                  maxTagCount="responsive"
                  treeData={treeOptions}
                  value={treeValue}
                  onChange={onChange}
                  loadData={onLoadData}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_PARENT}
                  placeholder={t('alarms.treePlaceholder')}
                  size="small"
                  style={{ width: '100%' }}
                  className="cursor-pointer"
                />
              </FormGroup>

              {/* Checkboxes de prioridade  */}
              <div className="d-flex justify-content-between mt-2">
                <Label className="ml-1">
                  {t('alarms.priority')}:
                </Label>
                <FormGroup check inline className="mb-0 ml-2">
                  <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="high"
                      className="cursor-pointer"
                      checked={high}
                      onChange={() => setHigh(!high)}
                      style={{ verticalAlign: "middle" }}
                    />
                    <PriorityBadge priority={1} />
                  </Label>
                </FormGroup>

                <FormGroup check inline className="mb-0 ml-auto">
                  <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="moderate"
                      className="cursor-pointer"
                      checked={moderate}
                      onChange={() => setModerate(!moderate)}
                      style={{ verticalAlign: "middle" }}
                    />
                    <PriorityBadge priority={2} />
                  </Label>
                </FormGroup>

                <FormGroup check inline className="mb-0 ml-auto">
                  <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="low"
                      className="cursor-pointer"
                      checked={low}
                      onChange={() => setLow(!low)}
                      style={{ verticalAlign: "middle" }}
                    />
                    <PriorityBadge priority={3} />
                  </Label>
                </FormGroup>
              </div>
            </Col>

            {/* BUSCA POR CONTATO */}
            <Col md={5}>
              
              <FormGroup>
              <div className="d-flex justify-content-between mt-4 ml-1">
                <Label>{t('alarms.contact')}:</Label>
               
                <FormGroup check inline className="mb-0 ml-2">
                  <Label check className="cursor-pointer">
                   <Input
                      type="checkbox"
                      name="Telefone"
                      className="cursor-pointer"
                      checked={phone}
                      onChange={() => setPhone(!phone)}
                    />
                   <Button size="sm" color="primary" disabled>{'Telefone'}<FontAwesomeIcon className="ml-1" fixedWidth icon={faPhone} /></Button>  
                  </Label>
                </FormGroup>

                <FormGroup check inline className="mb-0 ml-auto">
               <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="Whatsapp"
                      className="cursor-pointer"
                      checked={whatsapp}
                      onChange={() => setWhatsapp(!whatsapp)}
                    />
                    <Button size="sm" color="success" disabled>{'Whatsapp'}<FontAwesomeIcon className="ml-1" fixedWidth icon={faWhatsapp} /></Button>
                  </Label>
                </FormGroup>

                <FormGroup check inline className="mb-0 ml-auto">
               <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="Email"
                      className="cursor-pointer"
                      checked={email}
                      onChange={() => setEmail(!email)}
                    />
                     <Button size="sm" color="secondary" disabled>{'Email'}<FontAwesomeIcon  className="ml-1" fixedWidth icon={faEnvelope} /></Button>
                  </Label>
                </FormGroup>
              </div>

              </FormGroup>
              {/* Checkboxes de estado de ativação */}
              <div className="d-flex justify-content-between mt-4">
                <Label className="ml-1">
                  {'Tipos de alarme: '}
                </Label>
                <FormGroup check inline className="mb-0 ml-2">
                  <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="Analogic"
                      className="cursor-pointer"
                      checked={analogic}
                      onChange={() => setAnalogic(!analogic)}
                    />
                    {'Analogico'}
                  </Label>
                </FormGroup>

                <FormGroup check inline className="mb-0 ml-auto">
                  <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="Digital"
                      className="cursor-pointer"
                      checked={digital}
                      onChange={() => setDigital(!digital)}
                    />
                    {'Digital'}
                  </Label>
                </FormGroup>

                <FormGroup check inline className="mb-0 ml-auto">
                <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="Multiplo"
                      className="cursor-pointer"
                      checked={multiples}
                      onChange={() => setMultiples(!multiples)}
                    />
                    {'Multiplo'}
                  </Label>
                </FormGroup>
              </div>
            </Col>

            {/* Botão de busca */}
            <Col md={2} className="d-flex align-items-end justify-content-end">
              <Button color="primary" type="submit">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {(loadAlarms) // se está carregando...
        // retorna spinner
        ? <div className="text-center">
          <Spinner color="primary" />
        </div>
        // se não está mais carregando, verifica se há alarmes
        : (!alarmConfig.length)
          // se não há alarmes e não está mais carregando, é porque não tem alarmes mesmo
          ? <p className="text-center mt-5">
            {t('alarms.noAlarmHistory')}.
          </p>
          // se não está mais carregando e há alarmes, mostra a tabela
          : <div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={alarmConfig}
              columns={alarmConfigCols}
              condensed
              bordered={false}
              striped={true}
              sort={sortIcon}
              pagination={paginationFactory(paginationOptions)}
            />
          </div>
      }

    </div>
  )
}

/*** Página ***/
const AlarmConfig = () => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t('alarms.titleConfig')}</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{t('sidebar.home')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/">{t('sidebar.alarms')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t('sidebar.alarmConfig')}</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card style={{ overflowY: "scroll", marginTop: "-26px" }}>
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            {t('alarms.headRegisters')}
          </CardTitle>
        </CardHeader>

        <CardBody style={{ minWidth: "1004px" }}>

          <AlarmConfigTable />

        </CardBody>
      </Card>
    </Container>
  );
}

export default AlarmConfig;
