import React from "react";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb, BreadcrumbItem, Container } from "reactstrap";

import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";

import NewChartCard from "./NewChartCard";
import NewStatsCard from "./NewStatsCard";
import NewCustomCard from "./NewCustomCard";
import NewFormulaCard from "./NewFormulaCard";
import NewThermometerCard from "./NewThermometerCard";
import NewBatteryCard from "./NewBatteryCard";
import NewDashboardGauge from "./NewDashboardGauge";
import NewWaterCard from "./NewWaterCard";
import NewMqttControl from "./NewMqttControl";

const NewCard = () => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();
  const location = useLocation();
  const cardProps = location?.state?.cardProps;

  
  const cardType = url.split("/")[2];

  const cardTypeName = t("dashboards." + cardType);
  return (
    <Container>
      <Header>
        <HeaderTitle>{t("dashboards.cardPanel")}</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Página Inicial</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/Dashboards">Dashboards</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/newCard">Novo Card</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {cardProps?.name ? cardProps?.name : cardTypeName}
          </BreadcrumbItem>
        </Breadcrumb>
      </Header>

      {cardType.includes("Chart") ? (
        <NewChartCard cardProps={cardProps} cardType={cardType} />
      ) : cardType.includes("statistics") ? (
        <NewStatsCard cardProps={cardProps} cardType={cardType} />
      ) : cardType.includes("formulaCard") ? (
        <NewFormulaCard cardProps={cardProps} cardType={cardType} />
      ) : cardType.includes("thermometerCard") ? (
        <NewThermometerCard cardProps={cardProps} cardType={cardType} />
      ) : cardType.includes("batteryCard") ? (
        <NewBatteryCard cardProps={cardProps} cardType={cardType} />
      ) : cardType.includes("gaugeCard") ? (
        <NewDashboardGauge cardProps={cardProps} cardType={cardType} />
      ) : cardType.includes("waterCard") ? (
        <NewWaterCard cardProps={cardProps} cardType={cardType} />
      ) : cardType.includes("mqttControl") ? (
        <NewMqttControl cardProps={cardProps} cardType={cardType} />
      ) : (
        <NewCustomCard cardProps={cardProps} cardType={cardType} />
      )}
    </Container>
  );
};

export default NewCard;
