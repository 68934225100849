import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";
import Stepper from "react-stepper-horizontal";
import { FormInput } from "../../../../../../components/FormInput";
import { useServiceAuthContext } from "../../ServiceAuthorizationsProvider";
import { FileInput } from "./components/FileInput";

export const FormModalAS = ({ open, toggle, id }) => {
  const { t } = useTranslation();
  const {
    companies,
    companyUsers,
    register,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    errors,
    onSubmit,
    isSubmitting,
    inputSelectOptions,
    step,
    setStep,
    watch,
    modalEdit,
    searchProject,
    projectExists
  } = useServiceAuthContext();

  if (modalEdit.loading) {
    return <Spinner />;
  }

  const steps = !id ? [
    { title: t("noc.form.company") },
    { title: t("noc.form.modules") },
    { title: t("noc.form.contract") },
    { title: t("noc.form.address") },
  ] : [
    { title: t("noc.form.company") },
    { title: t("noc.form.contract") },
  ];

  const nextStep = async () => {
    let isValid = await trigger();
    if (isValid) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const firstStep = [
    {
      name: "companyId",
      label: t("noc.form.company"),
      placeholder: t("noc.placeholder.company"),
      type: "select",
      required: true,
      options: companies.data,
      feedbackText: t("noc.error.required"),
      disabled: companies.loading || !companyUsers.data.length || id || projectExists.id,
    },
    {
      name: "personResponsibleId",
      label: t("noc.form.personResponsible"),
      placeholder: t("noc.placeholder.personResponsible"),
      type: "select",
      required: true,
      options: companyUsers.data,
      feedbackText: t("noc.error.required"),
      disabled: companyUsers.loading || !companyUsers.data.length,
    },
    {
      name: "commercialResponsibleId",
      label: t("noc.form.commercialResponsible"),
      placeholder: t("noc.placeholder.commercialResponsible"),
      type: "select",
      required: true,
      options: companyUsers.data,
      feedbackText: t("noc.error.required"),
      disabled: companyUsers.loading || !companyUsers.data.length || id,
    },
    {
      name: "projectName",
      label: t("noc.form.projectName"),
      placeholder: t("noc.placeholder.projectName"),
      type: "text",
      required: true,
      feedbackText: t("noc.error.required"),
      maxLength: 255,
    },
    {
      name: "descriptionProject",
      label: t("noc.form.descriptionProject"),
      placeholder: t("noc.placeholder.descriptionProject"),
      type: "textarea",
      required: true,
      feedbackText: t("noc.error.required"),
      maxLength: 255,
      disabled: projectExists.id
    },
  ];

  const secondStep = [
    {
      name: "solarModuleOn",
      label: t("noc.labels.solar"),
      feedbackText: t("noc.error.required"),
      disabled: projectExists.id
    },
    {
      name: "powerModuleOn",
      label: t("noc.labels.power"),
      feedbackText: t("noc.error.required"),
      disabled: projectExists.id
    },
    {
      name: "waterModuleOn",
      label: t("noc.labels.water"),
      feedbackText: t("noc.error.required"),
      disabled: projectExists.id
    },
    {
      name: "gasModuleOn",
      label: t("noc.labels.gas"),
      feedbackText: t("noc.error.required"),
      disabled: projectExists.id
    },
    {
      name: "climateModuleOn",
      label: t("noc.labels.climate"),
      feedbackText: t("noc.error.required"),
      disabled: projectExists.id
    },
  ];

  const thirdStep = [
    {
      name: "typeContract",
      label: t("noc.form.typeContract"),
      placeholder: t("noc.placeholder.typeContract"),
      type: "select",
      required: true,
      options: inputSelectOptions.data.contract,
      disabled: inputSelectOptions.loading,
      feedbackText: t("noc.error.required"),
    },
    {
      name: "deadlineForExecution",
      label: t("noc.form.deadlineForExecution"),
      type: "date",
      required: true,
      feedbackText: t("noc.error.required"),
      xs: 6,
      md: 6,
      disabled: id
    },
    {
      name: "numberOfDevices",
      label: t("noc.form.numberOfDevices"),
      placeholder: t("noc.placeholder.numberOfDevices"),
      type: "number",
      required: true,
      feedbackText: t("noc.error.required"),
      xs: 6,
      md: 6,
      disabled: id
    },
    {
      name: "contractDuration",
      label: t("noc.form.contractDuration"),
      placeholder: t("noc.placeholder.contractDuration"),
      type: "text",
      required: true,
      feedbackText: t("noc.error.required"),
      xs: 6,
      md: 6,
      disabled: id
    },
    {
      name: "monthValue",
      label: t("noc.form.monthValue"),
      placeholder: t("noc.form.monthValue"),
      type: "text",
      required: true,
      feedbackText: t("noc.error.required"),
      xs: 6,
      md: 6,
      disabled: id
    },
  ];

  const fourStep = [
    {
      name: "address",
      label: t("noc.form.address"),
      placeholder: t("noc.placeholder.address"),
      type: "text",
      required: false,
      maxLength: 255,
    },
    {
      name: "neighborhood",
      label: t("noc.form.neighborhood"),
      placeholder: t("noc.placeholder.neighborhood"),
      type: "text",
      required: false,
      maxLength: 255,
    },
    {
      name: "city",
      label: t("noc.form.city"),
      placeholder: t("noc.placeholder.city"),
      type: "text",
      required: false,
      maxLength: 255,
      xs: 12,
      md: 6,
    },
    {
      name: "state",
      label: t("noc.form.state"),
      placeholder: t("noc.placeholder.state"),
      type: "text",
      required: false,
      maxLength: 255,
      xs: 12,
      md: 2,
    },
    {
      name: "zipCode",
      label: t("noc.form.zipCode"),
      placeholder: "00000-000",
      type: "text",
      required: false,
      maxLength: 9,
      xs: 12,
      md: 4,
    },
  ];

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Modal show={open} onHide={toggle}>
      <Modal.Header>
        <Modal.Title>
          {id
            ? t("noc.header.updateModalTitle")
            : t("noc.header.createModalTitle")}
        </Modal.Title>
        <Button
          variant="outline-primary"
          className="p-0 rounded-circle border-0"
          onClick={toggle}
        >
          <X color={"grey"} />
        </Button>
      </Modal.Header>
      <Modal.Body className="p-0">
        {isSubmitting ? (
          <Row
            className="justify-content-center align-items-center h-25"
            style={{ minHeight: "150px" }}
          >
            <Spinner />
          </Row>
        ) : (
          <>
            <Stepper steps={steps} activeStep={step} />
            <Form
              className="p-2"
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={handleKeyDown} // Add this line
            >
              {step === 0 && (
                <>
                  {" "}
                  <Row>
                    {firstStep.map((input, index) => (
                      <Col key={index} xs={input.xs || 12} md={input.md || 12}>
                        <FormInput
                          id={input.name}
                          label={input.label}
                          name={input.name}
                          placeholder={input.placeholder}
                          type={input.type}
                          register={register}
                          validation={{ required: input.required }}
                          isInvalid={!!errors[input.name]}
                          options={input.options || []}
                          feedbackText={input.feedbackText || ""}
                          text={input.text || ""}
                          maxLength={input.maxLength || 32}
                          setValue={setValue}
                          getValues={getValues}
                          disabled={input.disabled}
                          inputBlur={input.name === "projectName" ? searchProject : undefined}
                        />
                      </Col>
                    ))}
                  </Row>
                </>
              )}
              {(step === 1 && !id) && (
                <>
                  <Row
                    style={{
                      margin: 0,
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(150px, 1fr))",
                    }}
                  >
                    {secondStep.map((input, index) => {
                      const value = watch(input.name);
                      const disabled = input.disabled;
                      return (
                        <FormGroup key={index}>
                          {input.label}
                          <Label
                            style={{
                              display: "flex",
                              alignItems: "center",
                              background: "#ccc",
                              width: "fit-content",
                              boxSizing: "border-box",
                              padding: "3px 3px",
                              borderRadius: "15px",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={() => {
                                if (!disabled) setValue(input.name, 1);
                              }}
                              style={{
                                display: "block",
                                textAlign: "center",
                                width: "50px",
                                backgroundColor: value ? "#4EF28F" : "",
                                borderRadius: "10px",
                                transition: "all 0.5s ease",
                                fontWeight: value ? "bold" : "normal",
                              }}
                            >
                              ON
                            </span>
                            <span
                              onClick={() => {
                                if (!disabled) setValue(input.name, 0);
                              }}
                              style={{
                                display: "block",
                                textAlign: "center",
                                width: "50px",
                                backgroundColor: !value ? "#EEEAEB" : "",
                                borderRadius: "10px",
                                transition: "all 0.5s ease",
                                fontWeight: !value ? "bold" : "normal",
                              }}
                            >
                              OFF
                            </span>
                          </Label>
                          {errors?.[input.label] && (
                            <span>{t("projTable.required")}</span>
                          )}
                        </FormGroup>
                      );
                    })}
                  </Row>
                </>
              )}
              {((step === 2  && !id) || (step === 1 && id)) && (
                <>
                  {" "}
                  <Row>
                    {thirdStep.map((input, index) => (
                      <Col key={index} xs={input.xs || 12} md={input.md || 12}>
                        <FormInput
                          id={input.name}
                          label={input.label}
                          name={input.name}
                          placeholder={input.placeholder}
                          type={input.type}
                          register={register}
                          validation={{ required: input.required }}
                          isInvalid={!!errors[input.name]}
                          options={input.options || []}
                          feedbackText={input.feedbackText || ""}
                          text={input.text || ""}
                          maxLength={input.maxLength || 32}
                          setValue={setValue}
                          getValues={getValues}
                          disabled={input.disabled}
                        />
                      </Col>
                    ))}
                  </Row>
                </>
              )}
              {(step === 3 && !id) && (
                <>
                  {" "}
                  <Row>
                    {fourStep.map((input, index) => (
                      <Col key={index} xs={input.xs || 12} md={input.md || 12}>
                        <FormInput
                          id={input.name}
                          label={input.label}
                          name={input.name}
                          placeholder={input.placeholder}
                          type={input.type}
                          register={register}
                          validation={{ required: input.required }}
                          isInvalid={!!errors[input.name]}
                          options={input.options || []}
                          feedbackText={input.feedbackText || ""}
                          text={input.text || ""}
                          maxLength={input.maxLength || 32}
                          setValue={setValue}
                          getValues={getValues}
                        />
                      </Col>
                    ))}
                  </Row>
                </>
              )}
              {step === 4 && (
                <>
                  <FileInput
                    required={false}
                    name="file"
                    label={t("noc.form.file")}
                    register={register}
                    setValue={setValue}
                    isInvalid={!!errors.file}
                    feedbackText={errors?.file?.message || ""}
                  />
                </>
              )}
              <hr />
              <FileInput />
              <hr />
              <Row className="mt-3 justify-content-between m-0">
                <Button
                  variant="outline-primary"
                  onClick={step === 0 ? toggle : prevStep}
                  className="w-auto m-0"
                >
                  {step === 0 ? t("noc.steps.cancel") : t("noc.steps.back")}
                </Button>
                <Button
                  variant="primary"
                  onClick={
                    step === steps.length - 1
                      ? handleSubmit(onSubmit)
                      : nextStep
                  }
                  className="w-auto"
                >
                  {step === steps.length - 1
                    ? t("noc.form.submit")
                    : t("noc.steps.next")}
                </Button>
              </Row>
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
