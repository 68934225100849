import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";

const Processed = ({ state, allData }) => {
  const { t } = useTranslation();

  let ProcessedAt = (allData.general.processedAt)


  // Variável para manipular o timestamp em formato data e hora do último processamento
  const TimestampDate = new Date(ProcessedAt * 1000);
  const DateProcessed = TimestampDate.toLocaleString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });

  return (
    <div className="w-100">
      <Row >
        <Col sm="12" style={{ paddingRight: "0px" }}>
          <Card style={{ minWidth: "135px", maxHeight: "100px", height: "100px", marginBottom: "0px", background: "#dde0e5" }}>
            <CardBody style={{ padding: "15px", paddingTop: "10px" }}>
              <Row>
                <Col className="mt-0">
                  <CardTitle tag="h5" style={{ paddingRight: "0px" }}>{t('solar.processed')}</CardTitle>
                </Col>
                <Col className="col-auto" style={{ paddingLeft: "0px" }}>
                  <div >
                    <FontAwesomeIcon
                      icon={faDatabase}
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                </Col>
              </Row>
              {(state)
                ? <h1 className="display-5 text-center" style={{ fontSize: "17px", fontWeight: "500" }}>{(DateProcessed) ? DateProcessed : "Não processado"}</h1>
                : (!state && DateProcessed)
                  ? <h1 className="display-5 text-center" style={{ fontSize: "17px", fontWeight: "500" }}>{(DateProcessed) ? DateProcessed : "Não processado"}</h1>
                  : <div className="text-center mb-4"><Spinner /></div>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Processed;