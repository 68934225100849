const enUsHomeTranslations = {
    homePage: {
        title: "Home Page"
    },
    menuCard: {
        myAccount: "My Account",
        sharing: "Consumption Sharing"
    },
    public: {
        expand: "Expand",
        lastComm: "Last Communication",
        deviceModel: "Model",
        deviceStatus: "Status",
    },
    energyModule: {
        generalVision: "Solar Module OverView",
        energy: "Energy",
        noEnergyProjects: "There is no Energy type plants",
        market: "Market",
        group: "Group/Subgroup",
        tariffStructure: "Tariff Structure",
        flag: "Flag",
        company: "Utility Company",
        totalDemand: "Total Demand",
        peakDemandStatus: "Peak Demand",
        offPeakStatus: "Peak F Demand",
        powerFactorStatus: "Power Factor",
        billingSimulation: "Billing Simulation",
        energyStatistics: "Energy Statistics",
        maxDemand: "Max Peak Demand",
        maxFDemand: "Max F Peak Demand",
        worstCaseIND: "Worst Case PF IND",
        worstCaseCAP: "Worst Case PF CAP",
        kVar: "kVAR Needed",
        peakDemand: "Peak",
        offPeak: "Off Peak",
        headerTitle: "Consumption",
        headerDescription: "Tariff month",
        dailyHeaderTitle: "Daily Consuption",
        dailyHeaderDescription: "Month",
        maxHeaderTitle: "Max Demand",
        maxHeaderDescription: "Month",
        powerFactorHeaderTitle: "Power Factor",
        powerFactorHeaderDescription: "Month",
        powerFactor: "Power Factor",
        noDataMonth: "No data found for the selected month",
    },
    energy: {
        energyVision: "Energy Module Overview",
        energy: "Energy",
        pvEnergy: "Energy Plant",
        totalConsumption: "Total Consumption",
        peak: "Peak consumption",
        offPeak: "Offpeak consumption",
        tooltipOff: "Communication failure on all devices",
        tooltipOn: "All devices are communicating",
        tooltipWarning: "Communication failure on one or more devices",
        noDataPlants: "There is no Energy type plants",
        actualMonth: "(Actual Month)",
        demand: "Demand",
        reactiveDemand: "Reactive Demand",

    }
}

export default enUsHomeTranslations;
