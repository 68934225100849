import { useState } from "react";

export const Container = ({
  open,
  onClick = () => {},
  moreMarginLeft,
  children,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={{
        width: open ? "240px" : "72px",
        height: "32px",
        whiteSpace: "nowrap",
        display: "block",
        fontSize: "0.875rem",
        transition: "all 0.2s",
        marginTop: "2px",
        cursor: "pointer",
        background: isHovered ? "rgba(0,0,0,0.08)" : "transparent",
        paddingLeft: open && moreMarginLeft ? "12px" : "0px",
        borderRadius: open ? "0 16px 16px 0" : "16px",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
