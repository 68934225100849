import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import * as Icon from "react-feather";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

import setHours from "date-fns/setHours";

import { toastr } from "react-redux-toastr";

import {
  CardHeader, CardBody, CardFooter, CardTitle,
  DropdownItem, DropdownMenu, DropdownToggle,
  UncontrolledDropdown, UncontrolledTooltip,
  CardSubtitle, Row, Col
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import { MoreVertical } from "react-feather";

import ModalDeleteCard from "../ModalDeleteCard";
import { useContainerDimensions } from "../hooks/useRefDimensions";

let cardHeight = (window.innerWidth > 700) ? "70%" : "70%";
let cardWidth = (window.innerWidth > 700) ? "100%" : "100%";

const CardDropdown = (props) => {
  const { t } = useTranslation();
  const [openDeleteCard, setOpenDeleteCard] = useState(false);
  const [editCard, setEditCard] = useState(false)

  function toggleDeleteCard() {
    setOpenDeleteCard(!openDeleteCard);
  }

  return (
    <UncontrolledDropdown className="d-inline-block" disabled={(props.preview)}>
      <DropdownToggle tag="a">
        <MoreVertical className="ml-1" style={{ width: "15px" }} />
      </DropdownToggle>

      <DropdownMenu right>
        <DropdownItem
          onClick={() => toggleDeleteCard()}
        >
          {t('dashboards.delete')}
        </DropdownItem>

        {(openDeleteCard)
          ? <ModalDeleteCard
            open={openDeleteCard}
            toggle={toggleDeleteCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
          />
          : null
        }

        <Link
          onClick={() => setEditCard(props.cardId)}
          to={{
            pathname: `/newCard/LineChart/${props.cardId}`,
            state: {
              cardProps: props.card,
              editCard: editCard,
            }
          }}>
          <DropdownItem>
            {t('dashboards.edit')}
          </DropdownItem>
        </Link>
        <DropdownItem onClick={() => props.setDataLoading(true)}>
          {t('dashboards.refresh')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const LineChartPreview = ({
  cardId,
  name,
  description,
  deviceId,
  variables,
  period,
  tableReload,
  preview,
  reloadTime,
  card,
  cardDataToEdit,
  retrieveDataFromPreviewCard,
  cardProps,
  variableListIfExists,
}) => {

  let history = useHistory();

  const { t } = useTranslation();
  const [dataLoading, setDataLoading] = useState(!preview);
  const [device, setDevice] = useState({});
  const [lastComm, setLastComm] = useState();

  // USANDO MESMA CHAMADA DO DATA EXPLORER
  const [endDate, setEndDate] = useState(new Date()); // data e hora atual
  let startDate = setHours(endDate, endDate.getHours() - 168)
  period = startDate
  // const [startDate, setStartDate] = useState(setHours(endDate, endDate.getHours() - 168)); // 168h antes da hora atual
  const [devicesAndVariables, setDevicesAndVariables] = useState([]);
  const [aggregation, setAggregation] = useState("Nenhum"); // periodo selecionado para Agregação
  const [resolution, setResolution] = useState(""); // periodo selecionado para Resolução
  const [DadosChart, setDadosChart] = useState();
  const [chartDaV, setChartDaV] = useState([]);
  const [isLoadingData, setLoadingData] = useState(false);

  const lastCommDate = new Date(lastComm * 1000);

  const componentRef = useRef()
  const { width } = useContainerDimensions(componentRef);
  // console.log(editDeviceId);
  // Lê variáveis dos dispositivos filtra e enviar para readDataExplorer
  useEffect(() => {
    let selectedValue = [];
    if (deviceId || isLoadingData) {
      axios.get('/readVariables', {
        params: {
          id: (cardProps?.id) ? deviceId : deviceId.split(".")[0]
        }
      })
        .then(response => {
          if (variableListIfExists.length) {
            variableListIfExists.forEach((item, index) => {
              var filtered = selectedValue.filter(a => a.deviceId === item.dvcId);
              if (filtered.length === 0) {
                selectedValue.push({
                  deviceId: item.dvcId,
                  variables: [item.tag],
                  title: [item.varName]
                })
              } else {
                filtered[0].variables.push(item.tag)
                filtered[0].title.push(item.varName)
              }
            })
          } 
          setDevicesAndVariables(selectedValue);
          setDevice(response.data);
          setLoadingData(false);
        })
        .catch(err => {
          console.log(err);
          // toastr.error('Erro!', "Erro ao buscar Unidade.");
        })
    }
  }, [deviceId, variableListIfExists])

  useEffect(() => {
    if (devicesAndVariables || dataLoading) {
      axios.get('/readDeviceInfo', {
        params: {
          idDevice: (cardProps?.id) ? deviceId : deviceId.split(".")[0]
        }
      })
        .then(response => {
          // console.log('useEffect readDeviceInfo', response.data);
          setDevice(response.data);
          setLastComm(response.data.lastComm);
          // setCommInterval(response.data.commInterval);
        })
        .catch(err => {
          console.log(err);
          // toastr.error('Erro!', "Erro ao buscar Modelo.");
        });
      
      axios.get('/v2/data-explorer/readChart', {
        params: {
          startDate: (startDate) ? Math.floor(startDate.getTime() / 1000) : "",
          endDate: (endDate) ? Math.floor(endDate.getTime() / 1000) : "",
          devicesAndVariables: devicesAndVariables,
          aggregation: aggregation,
          resolution: resolution
        }
      })
        .then(response => {
          response.data.dateIni = startDate;
          response.data.dateFin = endDate;
          setDadosChart(response.data);
          setLoadingData(false);
        })
        .catch(err => {
          console.log(err);
          // toastr.error('Erro!', "Erro ao buscar devices.");  // t() da problema com o useEffect
        })
        .finally(() => {

          setChartDaV(chartDaV);
          setLoadingData(false);
        });
    }
  }, [devicesAndVariables, variableListIfExists])

  useEffect(() => {
    if (DadosChart !== undefined) {
      let root = am5.Root.new("chartdiv" + cardId);
      root._logo.dispose();

      root.dateFormatter.setAll({
        dateFormat: `dd.MM.yy\nHH'h'mm`,
        dateFields: "date",
      });

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          layout: root.verticalLayout,
          maxTooltipDistance: 0,
          pinchZoomX: true,
          // wheelX: "panX",

        })
      );

      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.2,
          baseInterval: {
            timeUnit: "minute",
            count: 1
          },
          groupCount: false,
          renderer: am5xy.AxisRendererX.new(root, {}),
          categoryField: "time"
        }));

        let yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
          })
      );
      
      DadosChart?.dataChart.forEach((element, index) => {
        console.log("ELEMENT", element);
        // Create X-Axis
        let seriesLabel = element[0]?.deviceName + '\n' + element[0]?.title;
        let seriesUnit = element[0]?.unit ? element[0]?.unit : ""
        let seriesName = seriesLabel + ((seriesUnit) ? ` (${seriesUnit})` : "");
        let formatDate = "'dd/MM/yyyy\nHH:mm:ss'"
        // let seriesLabelText = "[bold]{label}[/]\n{valueX.formatDate(" + formatDate + ")} - [bold]{valueY} " + seriesUnit + "[/]"
        let seriesLabelText = "[bold]{label}[/]\n{valueX.formatDate(" + formatDate + ")} - [bold]{valueY} " + seriesUnit + "[/]"


        // Create series
        let series = chart.series.push(
          am5xy.LineSeries.new(root, {
            // name: `${varName} (${varUnit})`,
            name: (element.length > 0) ? seriesName : t("solar.noData"),
            xAxis,
            yAxis,
            valueYField: "variavel",
            valueXField: "time",
            tooltip: am5.Tooltip.new(root, {
              labelText: `[bold]{name}[/]\n${seriesLabelText}`
            }),
          })
        );
        series.strokes.template.setAll({ strokeWidth: 2 })
        series.data.setAll(element);
        xAxis.data.setAll(element);
      })

      // Add legend
      if (width > 400) {
        if (devicesAndVariables[0]?.variables.length <= 2) {
          let legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.percent(60),
            x: am5.percent(60),
            // width: am5.percent(100),
          }))
          legend.data.setAll(chart.series.values);
        } else {
          let legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.percent(70),
            x: am5.percent(70),
            height: am5.percent(30),
            width: am5.percent(90),
            verticalScrollbar: am5.Scrollbar.new(root, {
              orientation: "vertical"
            })
          }));

          legend.data.setAll(chart.series.values);
        }
      }
      const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "zoomX"
      }));

      cursor.lineY.set("visible", false);
      // cursor.lineX.set("visible", false);

      return () => {
        root.dispose();
      };
    }
    // eslint-disable-next-line
  }, [device, DadosChart, devicesAndVariables]);


  return ((cardProps?.id) ?
    <div>
      <div id="parent">
        <CardHeader>
          <div className="card-actions float-right">
            <Row className="card-actions float-right">
              <Col className="mp-0">
                <span className="cursor-pointer mp-0">
                  <Icon.RefreshCw className="mp-0" onClick={() => {
                    setDataLoading(true);
                  }} />
                </span>{""}
              </Col>
              <Col className="pl-0">
                <div className="box no-cursor cursor-pointer mp-0">
                  <Icon.Move style={{ width: "15px" }} />
                </div>
              </Col>
              <CardDropdown
                cardId={cardId}
                tableReload={tableReload}
                name={name}
                description={description}
                preview={(preview)}
              />
            </Row>
          </div>
          <CardTitle id={"name" + cardId} style={{ whiteSpace: "nowrap" }} tag="h5" className="mr-3 overflow-hidden text-left my-1 h5">{name}</CardTitle>
          {(name) ?
            <UncontrolledTooltip
              style={{ backgroundColor: "#202020", opacity: 1, fontSize: ".8rem", color: "#F1F1F1", border: "1px solid #000", padding: "1rem" }}
              // className="tooltip-style"
              placement="top"
              target={"name" + cardId}
            >
              {name}
            </UncontrolledTooltip>
            : null}
          <CardSubtitle style={{ whiteSpace: "nowrap" }} className="overflow-hidden text-muted text-left">
            {description}
          </CardSubtitle>
          {(description) ?
            <UncontrolledTooltip
              style={{ backgroundColor: "#202020", opacity: 1, fontSize: ".8rem", color: "#F1F1F1", border: "1px solid #000", padding: "1rem" }}
              // className="tooltip-style"
              placement="bottom"
              target={"name" + cardId}
            >
              {description}
            </UncontrolledTooltip>
            : null}
        </CardHeader>

        {(DadosChart)
          ? <div id={"chartdiv" + cardId} style={{ width: cardWidth, height: cardHeight, display: "block", maxHeight: 200, color: "white" }}>.</div>
          : <div>
            <CardBody className='text-center'> {t('solar.noData')} </CardBody>
          </div>
        }

        <CardFooter className="mt-3 text-left" style={{ bottom: "0", position: `${(!preview) ? "absolute" : null}` }}>
          <h6 className="text-left">{preview}</h6>
          <h6 className="card-subtitle text-muted"></h6>
          <h6 className="card-subtitle text-muted mt-1">
            {(lastComm)
              ?
              lastCommDate.getDate().toString().padStart(2, '0') + '/' +
              (lastCommDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
              lastCommDate.getFullYear().toString().padStart(2, '0') + ' ' +
              lastCommDate.getHours().toString().padStart(2, '0') + ':' +
              lastCommDate.getMinutes().toString().padStart(2, '0') + ':' +
              lastCommDate.getSeconds().toString().padStart(2, '0')
              : t('dashboards.noComm')
            }
          </h6>
        </CardFooter>
      </div>
    </div>
    : <div ref={componentRef} className="flex-fill w-200 mb-3">
      <CardHeader className="pb-0">
        <div className="d-flex justify-content-between">
          <div className="overflow-hidden mr-5" id={"name" + cardId} >
            <CardTitle style={{ whiteSpace: "nowrap" }} tag="h5" className="mr-3 overflow-hidden text-left my-1 h5">{name}</CardTitle>
            {(name) ?
              <UncontrolledTooltip
                placement="top"
                target={"name" + cardId}
              >
                {name}
              </UncontrolledTooltip>
              : null}
            <CardSubtitle style={{ whiteSpace: "nowrap" }} className="overflow-hidden text-muted text-left">
              {description}
            </CardSubtitle>
            {(description) ?
              <UncontrolledTooltip
                placement="bottom"
                target={"name" + cardId}
              >
                {description}
              </UncontrolledTooltip>
              : null}
          </div>
          <div className="d-flex justify-content-end card-actions">
            <div className="cursor-pointer mp-0 ml-2 otavio">
              <Icon.Move style={{ width: "15px", zIndex: 15 }} />
            </div>

            <CardDropdown
              card={card}
              cardId={cardId}
              tableReload={tableReload}
              name={name}
              description={description}
              preview={preview}
              setDataLoading={setDataLoading}
            />
          </div>
        </div>
      </CardHeader>

      {(DadosChart)
        ? <div id={"chartdiv" + cardId} style={{ width: cardWidth, height: cardHeight, display: "block", maxHeight: "200px", color: "white" }}>.</div>
        : <div>
          <CardBody className='text-center'> {t('solar.noData')} </CardBody>
        </div>

      }

      <CardFooter className="text-left mt-1" style={{ backgroundColor: "transparent", bottom: "0", position: `${(!preview) ? "absolute" : null}`, padding: "5px 1.25rem" }}>
        <h6 className="text-left"></h6>
        <h6 className="card-subtitle text-muted"></h6>
        <h6 className="card-subtitle text-muted mt-1">
          {(lastComm)
            ?
            lastCommDate.getDate().toString().padStart(2, '0') + '/' +
            (lastCommDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
            lastCommDate.getFullYear().toString().padStart(2, '0') + ' ' +
            lastCommDate.getHours().toString().padStart(2, '0') + ':' +
            lastCommDate.getMinutes().toString().padStart(2, '0') + ':' +
            lastCommDate.getSeconds().toString().padStart(2, '0')
            : t('dashboards.noComm')
          }
        </h6>
      </CardFooter>
    </div>
  );
};

export default connect(store => ({
  theme: store.theme
}))(LineChartPreview);
