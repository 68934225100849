import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { Card, CardFooter } from "react-bootstrap";
import { Badge, CardBody, CardHeader, CardText, CardTitle } from "reactstrap";

export const ConsumerUnitsCard = ({ units }) => {
    const footerRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
  
    const onlineUnits = units.filter((unit) => unit.status === 0);
    const offlineUnits = units.filter((unit) => unit.status === 1);
    const noCommUnits = units.filter((unit) => unit.status === 2);
  
    useEffect(() => {
      const footer = footerRef.current;
      if (footer) {
        const content = footer.innerHTML;
        footer.innerHTML = content + content;
  
        const scroll = () => {
          if (!isHovered) {
            if (footer.scrollLeft >= footer.scrollWidth / 2) {
              footer.scrollLeft = 0;
            } else {
              footer.scrollLeft += 1;
            }
          }
        };
  
        const intervalId = setInterval(scroll, 20);
        return () => clearInterval(intervalId);
      }
    }, [onlineUnits, offlineUnits, noCommUnits, isHovered]);
  
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  
    return (
      <Card className="m-0 p-0">
        <CardHeader>
          <CardTitle tag="h5">{t("noc.main.headerCards.ucs")}</CardTitle>
        </CardHeader>
        <CardBody>
          <CardText>
            {t("noc.labels.online")}: {onlineUnits.length} | {t("noc.labels.offline")}: {offlineUnits.length} | {t("noc.labels.noCommunication")}: {noCommUnits.length}
          </CardText>
        </CardBody>
        <CardFooter
          ref={footerRef}
          className="scrolling-footer"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
          }}
        >
          {onlineUnits.map((unit) => (
            <Badge key={unit.dvcId} color="success" className="mx-1">
              {unit.description}
            </Badge>
          ))}
          {offlineUnits.map((unit) => (
            <Badge key={unit.dvcId} color="warning" className="mx-1">
              {unit.description}
            </Badge>
          ))}
          {noCommUnits.map((unit) => (
            <Badge key={unit.dvcId} color="secondary" className="mx-1">
              {unit.description}
            </Badge>
          ))}
        </CardFooter>
      </Card>
    );
  };