import { faPen, faPlus, faSort, faSortDown, faSortUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";

const ModalSendMail = ({
  jsonData: sendData,
  open,
  toggle,
  monthRef,
  data,
  projectId,
  data1,
  screenshot,
  ref
}) => {
  const [emailList, setEmailList] = useState([])
  const [inputMail, setInputMail] = useState("")
  const [inputEditMail, setInputEditMail] = useState("")
  const [showEditEmailInput, setShowEditEmailInput] = useState("none")
  const [idMail, setIdMail] = useState(0);
  const [idEditMail, setIdEditMail] = useState(null);
  const [disableSendButton, setDisableSendButton] = useState(false)
  const { t } = useTranslation();

  function handleAddNewEmail(email) {
    let re = /\S+@\S+\.\S+/;
    let validateMail = re.test(email)

    let objMail = {
      id: idMail,
      email: email,
    };

    if (validateMail === false) {
      toastr.warning("Atenção", "Formato de email invalido")
    } else if (emailList.some(emailIsEqual => emailIsEqual.email === inputMail)) {
      toastr.warning("Atenção", "Email já adicionado")
    } else {
      setEmailList([...emailList, objMail])
      setIdMail(idMail + 1);
      setInputMail("")
    }
  }

  function handleEditEmail(row) {
    setShowEditEmailInput("unset");
    const findIndex = emailList.findIndex(item =>
      item.id === row.id
    )
    setIdEditMail(findIndex)
    setInputEditMail(emailList[findIndex].email);
    // console.log(findIndex);
  }

  function handleSubmitEditEmail(email) {
    // console.log("email por param", email);
    let re = /\S+@\S+\.\S+/;
    let validateMail = re.test(email);

    if (validateMail === false) {
      toastr.warning("Atenção", "Formato de email inválido");
    } else if (emailList.some(emailIsEqual => emailIsEqual.email === inputEditMail)) {
      toastr.warning("Atenção", "Email já adicionado");
    } else {
      const newUpdatedEmailList = emailList.map(item => {
        if (item.id === idEditMail) {
          return { ...item, email: email }
        } else {
          // console.log("entrou aqui");
          return item;
        }
      })
      setEmailList(newUpdatedEmailList);
      setShowEditEmailInput("none");
    }
    // console.log("DENTRO DA FUNC", emailList);
  }
  // console.log("FORA DA FUNC", emailList);

  function handleDeleteMail(row) {

    const findIndex = emailList.find(item =>
      item.id === row.id
    )
    setIdEditMail(findIndex);
    setEmailList(emailList.filter(item => item.id !== row.id))
  }
  // // console.log(sendData);
  // function onSubmitDisableSendButton(bool) {
  //   return setDisableSendButton(bool)
  // }

  function handleSendMail() {


    const jsonData = {
      listOfEmails: emailList,
      pdfDocument: sendData,
      locales: window.localStorage.getItem("defaultLanguage")
    }

    if (emailList.length > 0) {
      setDisableSendButton(true);
      axios.post('/sendReportForEmail', JSON.stringify(jsonData))
        .then(response => {
          toastr.success(response.data.messageHead, response.data.messageBody);
          toggle();
          setDisableSendButton(false);
        })
        .catch(err => {
          console.log(err);
        })
    } else {
      toastr.warning("Atenção", "Não há e-mails para ser enviado");
    }
  }

  const emailCols = [
    { // ID (oculto)
      dataField: "id",
      text: t('projTable.id'),
      sort: true,
      hidden: true,
      isKey: true
    },
    { // nome da variável
      dataField: "email",
      text: "E-Mail",
      sort: true
    },
    { // botões de ação
      dataField: "actions",
      text: t('projTable.actions'),
      editable: false,
      headerStyle: { width: "11%" },
      formatter: (_, row) => (
        <div className="d-flex justify-content-left">
          <div // editar
            id={"editGain" + row.id}
            className="align-middle cursor-pointer"
            onClick={() => handleEditEmail(row)}
          // onClick={() => {
          //   setGainToEdit(gainList.find(item => item.id === row.id));
          //   setFormTag(gainList.find(item => item.id === row.id).varTag);
          //   setFormGain(gainList.find(item => item.id === row.id).gain);
          //   setFormUnit(gainList.find(item => item.id === row.id).unit);
          // }}
          >

            <FontAwesomeIcon icon={faPen} fixedWidth />
            {/* Tooltip */}
            <UncontrolledTooltip
              placement="top"
              target={"editGain" + row.id}
            >
              {t('projTable.edit')}
            </UncontrolledTooltip>
          </div>

          <div // remover 
            id={"removeGain" + row.id}
            className="align-middle cursor-pointer"
            onClick={() => handleDeleteMail(row)}
          // onClick={() => console.log(row.id)}
          >
            {/* {console.log(row.id, _, "ROWID")} */}
            <FontAwesomeIcon icon={faTrash} fixedWidth />
            {/* Tooltip */}
            <UncontrolledTooltip
              placement="top"
              target={"removeGain" + row.id}
            >
              {t('projTable.delete')}
            </UncontrolledTooltip>
          </div>
        </div>
      )
    }
  ];

  const sortIcon = {
    sortCaret: (order) => (!order)
      ? <FontAwesomeIcon
        className={'fa-xs ml-1'}
        icon={faSort}
      />
      : (order === 'asc')
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faSortUp}
        />
        : <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faSortDown}
        />
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      scrollable
    >
      <ModalHeader>
        {t("solarReport.sendEmail")}
      </ModalHeader>
      <ModalBody
        style={{
          display: "flex",
          flexDirection: "column",
          paddingInline: "1rem",
          marginInline: 16
        }}>
        <Row>
          <Col className="d-flex align-items-center justify-content-center mb-2">
            <Label>{t("solarReport.bodyText")}</Label>
          </Col>
        </Row>
        <Row style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 8,
        }}>
          <Input
            style={{ width: "12rem", marginRight: "1rem" }}
            placeholder={t("solarReport.inputPlaceholder")}
            value={inputMail}
            onChange={e => setInputMail(e.target.value)}
            type="email"
          />
          <Button color="primary" onClick={() => handleAddNewEmail(inputMail)}>
            <FontAwesomeIcon fontSize={2} fixedWidth icon={faPlus} />
          </Button>
        </Row>
        <div style={{
          display: showEditEmailInput
        }}>
          <Row>
            <Col className="d-flex align-items-center justify-content-center mb-2">
              <Label>{t('solarReport.editText')}</Label>
            </Col>
          </Row>
          <Row style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // marginBottom: 16,
            paddingInline: 12,
            // gap: "1rem",
          }}>
            <Input
              style={{ width: "12rem", marginRight: "1rem" }}
              placeholder={t("solarReport.inputPlaceholder")}
              value={inputEditMail}
              onChange={e => setInputEditMail(e.target.value)}
              type="email"
            />
            <Button color="primary" onClick={() => handleSubmitEditEmail(inputEditMail)}>
              {/* <FontAwesomeIcon fontSize={2} fixedWidth icon={faPlus} /> */}Confirmar
            </Button>
          </Row>
        </div>

        <hr style={{ color: "#000", height: 16 }} />

        <BootstrapTable
          bootstrap4
          keyField="id"
          data={emailList}
          columns={emailCols}
          condensed
          bordered={false}
          striped={true}
          sort={sortIcon}
        // cellEdit={cellEdit}
        />

      </ModalBody>
      <ModalFooter>

        <Button
          color='primary'
          className="mb-1"
          onClick={toggle}
          outline

        >
          {t("solarReport.closeButton")}
        </Button>
        {/* <Button
          color='primary'
          className="mb-1"
          onClick={screenshot}
          outline

        >
          print
        </Button> */}
        <Button
          color='primary'
          className="mb-1"
          onClick={handleSendMail}
          disabled={disableSendButton}
          style={{ minWidth: 70 }}
        >
          {(disableSendButton === true) ? <Spinner size={"sm"} /> : t("solarReport.sendButton")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default ModalSendMail;