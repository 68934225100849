/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Maximize2 } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  Button,
  CardHeader,
  CardText,
  CardTitle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import {
  getStartEndDays,
  getStartEndMonth,
} from "../../../../../../../src/utils/functions.js";
import { useConsumerUnitContext } from "../ConsumerUnitProvider";
import { readBarChartConsumptionUc } from "../ConsumerUnitService";
import { usePlantContext } from "../../PlantProvider.js";
const moment = require("moment");

export const BarChartConsumption = ({
  windowObervable,
  isModal = false,
  viewChart,
}) => {
  const { t } = useTranslation();

  const { deviceId } = useConsumerUnitContext();
  const { selectedDate } = usePlantContext();

  const [loadingChart, setLoadingChart] = useState(false);
  const [chart, setChart] = useState(null);
  const [start, setStart] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState(moment().endOf("month").format("YYYY-MM-DD"));
  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  useEffect(() => {
    if (deviceId) getChartData();
  }, [deviceId, start, end]);

  useEffect(() => {
    if (selectedDate) getMonthSelected();
  }, [selectedDate]);

  const getChartData = async () => {
    setLoadingChart(true);
    const chart = await readBarChartConsumptionUc(deviceId, start, end);
    setChart(chart);
    setLoadingChart(false);
  };

  async function getMonthSelected() {
    setLoadingChart(true);
    const { start, end } = await getStartEndMonth(selectedDate);
    const chart = await readBarChartConsumptionUc(deviceId, start, end);
    setChart(chart);
    setLoadingChart(false);
  }

  async function getPeriod(model) {
    const { start, end } = await getStartEndDays(selectedDate, model);
    setStart(start);
    setEnd(end);
  }

  const data = {
    labels: chart ? chart.totalKwConsumptionTimestamp : [],
    datasets: [
      {
        label: t("charts.peak"),
        data: chart?.totalPeakConsumption,
        borderWidth: 1,
        backgroundColor: "blue",
        fill: true,
      },
      {
        label: t("charts.offPeak"),
        data: chart?.totalOffPeakConsumption,
        borderWidth: 1,
        backgroundColor: "green",
        fill: true,
      },
    ],
  };

  const options = {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        padding: 30,
        fontSize: 14,
      },
    },
  };

  return (
    //NOTE: Só exibe se existir a estrutura de energia do driver ou estrutura de energia personalizada
    viewChart || chart?.viewChart ? (
      <>
        <CardHeader className="d-flex align-content-center justify-content-between">
          <CardTitle tag="h5" style={{ marginBottom: "0px" }}>
            {t("charts.chartConsumption")}
          </CardTitle>
          {!isModal && (
            <Button
              className="bg-transparent border-0"
              id={"button-maximize-dashboard"}
              onClick={handleToggleModal}
            >
              <Maximize2 size={16} color="grey" />
              <UncontrolledTooltip target={"button-maximize-dashboard"}>
                {t("public.expand")}
              </UncontrolledTooltip>
            </Button>
          )}
        </CardHeader>
        {loadingChart ? (
          <div
            className="d-flex justify-content-center align-items-center h-100"
            style={{
              height: windowObervable < 1400 ? "16rem" : "20rem",
              padding: 32,
            }}
          >
            <Spinner />
          </div>
        ) : (
          <>
            <Bar height={120} data={data} options={options} />
            <div
              className="d-flex justify-content-center px-5 pt-4"
              style={{ gap: "4px" }}
            >
              {
                //NOTE: Só exibe estes filtros, caso for o mês atual
                moment().month() === moment(selectedDate).month() && (
                  <>
                    <CardText role="button">
                      <Button
                        size="md"
                        color="primary"
                        onClick={() => getMonthSelected()}
                      >
                        {t("charts.month")}
                      </Button>
                    </CardText>
                    <CardText role="button">
                      <Button
                        size="md"
                        color="primary"
                        onClick={() => getPeriod("week")}
                      >
                        {t("charts.lastDays")}
                      </Button>
                    </CardText>
                    <CardText role="button">
                      <Button
                        size="md"
                        color="primary"
                        onClick={() => getPeriod("day")}
                      >
                        {t("charts.today")}
                      </Button>
                    </CardText>
                  </>
                )
              }
            </div>
            {toggleModal && (
              <Modal isOpen={toggleModal} toggle={handleToggleModal} size="lg">
                <ModalBody>
                  <BarChartConsumption isModal={true} />
                </ModalBody>
              </Modal>
            )}
          </>
        )}
      </>
    ) : null
  );
};
