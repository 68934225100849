import { useQuery } from "@tanstack/react-query";
import { ConfigProvider, Table } from "antd";
import axios from "axios";
import { useMemo, useRef, useState } from "react";
import { Nav } from "react-bootstrap";
import { Modal, ModalBody } from "reactstrap";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";

export const ModalProjectModules = ({ showModal, handleModal, companyId }) => {
  const { t } = useTranslation();
  const {
    status,
    data: reactQuery,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["companyListProjectsModal", companyId],
    queryFn: async () => {
      const res = await axios.get(
        `v2/company/listProjects?companyId=${companyId}&listProjects=true`
      );
      return res.data;
    },
  });

  const types = {
    Climate: ["climateModuleOn"],
    Energy: ["powerModuleOn"],
    Solar: ["solarModuleOn"],
    Water: ["waterModuleOn"],
    Gas: ["gasModuleOn"],
    noActiveModules: [],
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`${t("navbar.search")} ${dataIndex}`}
          value={selectedKeys[0] || ""}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t("navbar.search")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {t("navbar.clear")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            {t("navbar.filter")}
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            {t("projTable.close")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // Adaptação das colunas para os projetos
  const columns = useMemo(
    () => [
      {
        title: t("noc.table.projectName"),
        dataIndex: "projectName",
        key: "projectName",
        width: 200,
        sorter: (a, b) => a.projectName.localeCompare(b.projectName),
        ...getColumnSearchProps("projectName"),
      },
      {
        title: t("noc.table.activeModules"),
        dataIndex: "modulesActive",
        key: "modulesActive",
        width: 150,
        render: (text, record) => {
          const activeModules = [];
          if (record.solarModuleOn) activeModules.push("Solar");
          if (record.powerModuleOn) activeModules.push("Power");
          if (record.waterModuleOn) activeModules.push("Water");
          if (record.gasModuleOn) activeModules.push("Gas");
          if (record.climateModuleOn) activeModules.push("Climate");
          return activeModules.length ? activeModules.join(", ") : "Nenhum";
        },
      },
      {
        title: "Dispositivos Online",
        dataIndex: "device_online",
        key: "device_online",
        width: 120,
        sorter: (a, b) => a.device_online - b.device_online,
      },
      {
        title: "Dispositivos Offline",
        dataIndex: "device_offline",
        key: "device_offline",
        width: 120,
        sorter: (a, b) => a.device_offline - b.device_offline,
      },
      {
        title: "Dispositivos Nunca Comunicaram",
        dataIndex: "device_no_comm",
        key: "device_no_comm",
        width: 150,
        sorter: (a, b) => a.device_no_comm - b.device_no_comm,
      },
      {
        title: "Total de Dispositivos",
        dataIndex: "total_devices",
        key: "total_devices",
        width: 120,
        sorter: (a, b) => a.total_devices - b.total_devices,
      },
    ],
    [searchText, searchedColumn]
  );

  const [activeTab, setActiveTab] = useState("All");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const filteredProjects = useMemo(() => {
    if (activeTab === "All") {
      return reactQuery?.projects || [];
    }

    if (activeTab === "noActiveModules") {
      return (
        reactQuery?.projects?.filter((project) => {
          // Verifica se nenhum dos módulos está ativo
          return (
            !types.Climate.some((module) => project[module]) &&
            !types.Energy.some((module) => project[module]) &&
            !types.Solar.some((module) => project[module]) &&
            !types.Water.some((module) => project[module]) &&
            !types.Gas.some((module) => project[module])
          );
        }) || []
      );
    }

    return (
      reactQuery?.projects?.filter((project) =>
        types[activeTab].some((module) => project[module])
      ) || []
    );
  }, [reactQuery, activeTab]);

  return (
    <Modal isOpen={showModal} toggle={handleModal} size="xl">
      <Nav variant="tabs" activeKey={activeTab} onSelect={toggle}>
        <Nav.Item>
          <Nav.Link eventKey="All">Todos</Nav.Link>
        </Nav.Item>
        {Object.keys(types).map((type) => (
          <Nav.Item key={type}>
            <Nav.Link eventKey={type}>
              {type === "noActiveModules" ? "Sem Módulos Ativos" : type}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <ModalBody>
        {status === "loading" || isFetching ? (
          <p>Loading...</p>
        ) : (
          <div className="w-100" style={{ height: "100%", overflow: "hidden" }}>
            <ConfigProvider>
              <Table
                columns={columns.map((col) => ({
                  ...col,
                  onHeaderCell: (column) => ({
                    width: column.width,
                  }),
                }))}
                scroll={{ y: 600 }}
                dataSource={filteredProjects}
                size="small"
                pagination={{
                  pageSizeOptions: [10, 25, 50],
                  defaultPageSize: 25,
                }}
              />
            </ConfigProvider>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
