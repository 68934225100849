const enUsGlobalTranslations = {
  reports: {
    month1: "January",
    month2: "February",
    month3: "March",
    month4: "April",
    month5: "May",
    month6: "June",
    month7: "July",
    month8: "August",
    month9: "September",
    month10: "October",
    month11: "November",
    month12: "December",
    monthJan: "JAN/",
    monthFev: "FEB/",
    monthMar: "MAR/",
    monthAbr: "APR/",
    monthMai: "MAY/",
    monthJun: "JUN/",
    monthJul: "JUL/",
    monthAgo: "AUG/",
    monthSet: "SEP/",
    monthOut: "OCT/",
    monthNov: "NOV/",
    monthDez: "DEC/",
    deleteEmail: "Delete E-mail",
  },
  page404: {
    notFound: "Page not found",
    removed: "The page you are looking for might have been removed",
    return: "Home Page",
  },
  searchBar: {
    logout: "Sair",
    home: "Home",
    dashboards: "Dashboards",
    dataExplorer: "Data Explorer",
    allCards: "All Cards",
    newCard: "New Card",
    MeusCards: "My Cards",
    projects: "My Plants",
    alarms: "Alarms",
    registers: "Settings",
    settings: "Preferences",
    devices: "Devices",
    alarmView: "Alarm History",
    alarmConfig: "Alarm Parameters",
    usersData: "Companies and Users Registers",
    projectsData: "Plants and Devices Registers",
    alarmsData: "Alarm Parameters Registers",
  },
  dataTable: {
    title: "Companies and Users Overview",
    head: "Companies and Users Data",
    text1: "Please click on",
    text2: "to view the users of each company.",
    newCompany: "Add company",
    insertCompany: "Please insert new company's data",
    insertUser: "Please insert new company's admin data",
    id: "#",
    name: "Name",
    enable: "Enable",
    contactName: "Manager",
    search: "Search",
    company: "Company",
    type: "Type",
    actions: "Actions",
    newUser: "New User",
    password: "Password",
    checkDefaultPassword: "Default Password",
    fullName: "Full name",
    userType: "User Type",
    default: "Default",
    admin: "Admin",
    endUser: "End User",
    module: "Module",
    water: "Water",
    username: "Username",
    cancel: "Cancel",
    saveContinue: "Save and continue",
    saveUsr: "Save user",
    linkCompany: "Link company",
    saveCompany: "Save company",
    editHead: "Edit data of ",
    editSave: "Save Changes",
    editComp: "Edit company",
    editUsers: "Edit user",
    editContactNot: 'Contact user must be "Admin".',
    editUserNot: 'It is not possible to set yourself to "Default".',
    delete: "Delete",
    deleteHead: "Delete",
    deleteUsr: "Delete User",
    deleteUsrMany: "Delete Users",
    deleteComp: "Delete Company",
    deleteCompMany: "Delete Companies",
    deleteContact1: "is the contact user",
    deleteContact2: "Please select a new contact user",
    deleteNotAll: "It is not possible to delete all users of a company.",
    deleteNotComp: "Users cannot delete their company.",
    deleteNotUser: "Users cannot delete theirselves.",
    deleteHeadComp: "Delete selected companies",
    deleteBodyComp: "The selected companies will be deleted.",
    deletedManyComp: "The companies has been deleted.",
    deleteHeadUsrs: "Delete selected users",
    deleteBodyUsrs: "The selected users will be deleted.",
    deletedManyUsrs: "The users has been deleted.",
    success: "Success",
    att: "Warning",
    error: "Error",
    captcha: "Incorrect captcha",
    check1comp: "company is selected",
    check2comp: "companies are selected",
    check1user: "user is selected",
    check2user: "users are selected",
    disableComp: "The selected companies has been disabled.",
    enableComp: "The selected companies has been enabled.",
    disableUsrs: "The selected users has been disabled.",
    enableUsrs: "The selected users has been enabled.",
    noUsers: "No users found",
    noComps: "No companies found",
  },
  none: "No data",
  locales: "en-us",
  dateRangeSelector: {
    today: "Today",
    last24Hours: "Last 24h",
    last7Days: "Last 7d",
    last30Days: "Last 30d",
    last12Months: "Last 12m",
    "24Hours": "24h",
    "7D": "7d",
    fullMonth: "Full Month",
    fullYear: "Full Year",
    custom: "Custom Date",
    selectStartDate: "Select Start Date",
    selectEndDate: "Select End Date",
    apply: "Apply",
  },
};

export default enUsGlobalTranslations;
