import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useTranslation } from "react-i18next";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
} from "reactstrap";

/*** Tabela ***/
const AlarmViewTable = () => {
  const { t } = useTranslation();

  const { data: alarms, status } = useQuery({
    queryKey: "alarmSearch",
    queryFn: async () => {
      const response = await axios.get("/alarmSearch");
      return response.data.alarms;
    },
    refetchInterval: 1000 * 30,
    refetchOnWindowFocus: false,
  });

  const loadAlarms = status === "loading";

  const alarmCols = [
    {
      // ID (oculto)
      dataField: "alarmId",
      text: `ID`,
      sort: true,
      hidden: true,
      isKey: true,
    },
    {
      // nome do alarme
      dataField: "alarmName",
      text: t("alarms.alarm"),
      // sort: true,
    },
    {
      // nome do dispositivo
      dataField: "deviceName",
      text: t("alarms.deviceName"),
      // sort: true,
    },
    {
      // variável
      dataField: "varName",
      text: t("alarms.varName"),
      formatter: (cell) =>
        cell === null
          ? "-"
          : cell.length > 20
          ? cell.slice(0, 17) + "..."
          : cell,
    },
    {
      // valor/unidade
      dataField: "value",
      text: t("alarms.value"),
      headerStyle: { width: "60px" },
      formatter: (cell, row) => cell + " " + row.varUnit,
    },
    {
      // data de ocorrencia
      dataField: "dateOccurred",
      text: t("alarms.dateOcc"),
      sort: true,
      formatter: (cell) => {
        if (cell) {
          var date = new Date(cell * 1000);

          return (
            ("0" + date.getDate()).slice(-2) +
            "/" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "/" +
            date.getFullYear() +
            " " +
            ("0" + date.getHours()).slice(-2) +
            ":" +
            ("0" + date.getMinutes()).slice(-2) +
            ":" +
            ("0" + date.getSeconds()).slice(-2)
          );
        } else return "-";
      },
    },
    {
      // data de finalização
      dataField: "dateFinished",
      text: t("alarms.dateOff"),
      // sort: true,
      formatter: (cell) => {
        if (cell) {
          var date = new Date(cell * 1000);

          return (
            ("0" + date.getDate()).slice(-2) +
            "/" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "/" +
            date.getFullYear() +
            " " +
            ("0" + date.getHours()).slice(-2) +
            ":" +
            ("0" + date.getMinutes()).slice(-2) +
            ":" +
            ("0" + date.getSeconds()).slice(-2)
          );
        } else return "-";
      },
    },
    {
      // mensagem ON
      dataField: "messageOn",
      text: t("alarms.onMsg"),
      formatter: (cell) =>
        cell === null
          ? "-"
          : cell.length > 20
          ? cell.slice(0, 17) + "..."
          : cell,
    },
    {
      // mensagem OFF
      dataField: "messageOff",
      text: t("alarms.offMsg"),
      formatter: (cell) =>
        cell === null
          ? "-"
          : cell.length > 20
          ? cell.slice(0, 17) + "..."
          : cell,
    },

    {
      // prioridade
      dataField: "priority",
      text: t("alarms.priority"),
      formatter: (cell) => {
        return cell === 1 ? (
          <Badge color="danger" style={{ color: "#000000" }}>
            Alta
          </Badge>
        ) : cell === 2 ? (
          <Badge color="warning" style={{ color: "#000000" }}>
            Média
          </Badge>
        ) : (
          <Badge style={{ backgroundColor: "#FFFF00", color: "#000000" }}>
            Baixa
          </Badge>
        );
      },
    },
  ];

  return (
    <div>
      {!alarms?.length ? ( // Se não há alarmes...
        loadAlarms ? ( // ...e se está carregando...
          // retorna Spinner
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          // se não está mais carregando, não há alarmes
          <div style={{ height: "250px" }}>
            <p className="text-center" style={{ height: "100px" }}>
              {t("home.notOccurrences")}
            </p>
          </div>
        )
      ) : (
        // se há alarmes, carrega tabela
        <ToolkitProvider // ToolkitProvider: para barra de busca
          bootstrap4
          keyField="id"
          data={alarms}
          columns={alarmCols}
          // search={ { onColumnMatch: onColumnMatch } }
        >
          {(props) => (
            <div>
              {/* Busca */}
              <div className="mb-2">
                {/* {(onSearch)

              } */}
              </div>

              {/* Tabela */}
              {/* <div style={{overflowX: "scroll"}}> */}
              <div
                className="d-flex"
                style={{
                  overflowY: "scroll",
                  height: "264px",
                  overflow: "auto",
                }}
              >
                <BootstrapTable
                  condensed
                  bordered={false}
                  striped={true}
                  sort={{ dataField: "dateOccurred", order: "desc" }}
                  {...props.baseProps}
                />
              </div>
              {/* </div> */}
            </div>
          )}
        </ToolkitProvider>
      )}
    </div>
  );
};

/*** Página ***/
const AlarmView = () => {
  const { t } = useTranslation();

  return (
    <Card style={{ height: "317px" }}>
      <CardHeader style={{ paddingBottom: "0px" }}>
        <CardTitle tag="h5" className="mb-0">
          {t("home.latestOccurrences")}
        </CardTitle>
      </CardHeader>

      <CardBody style={{ marginBottom: "25px", paddingTop: "0px" }}>
        <AlarmViewTable />
      </CardBody>
    </Card>
  );
};

export default AlarmView;
