import { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

let cardWidth = (window.innerWidth > 700) ? "100%" : "100%";
// let cardHeight = (window.innerWidth > 700) ? "80%" : "80%";

const GaugeChart = ({ card, dataGet, data, deviceVars }) => {

  useEffect(() => {

    let root = am5.Root.new("chartdiv" + card.tag);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360
      })
    );
    // chart.paddingBotton = 15

    let axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -10,
      strokeOpacity: 1,
      strokeWidth: 10,
      minGridDistance: dataGet[card.tag] < 10000 ? 35 : 100,
      strokeGradient: am5.LinearGradient.new(root, {
        rotation: 0,
        stops: [
          dataGet[card.tag] > 0 ? (
            { color: am5.color(0x19d228) },
            { color: am5.color(0xf4fb16) },
            { color: am5.color(0xf6d32b) },
            { color: am5.color(0xfb7116) }
          )
            :
            { color: am5.color(0xfb7116) },
          { color: am5.color(0xf6d32b) },
          { color: am5.color(0xf4fb16) },
          { color: am5.color(0x19d228) },
        ]
      })
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: (dataGet[card.tag] > 0) ? 0 : (dataGet[card.tag] < 0) ? dataGet[card.tag] - 20 : null,
        max: (dataGet[card.tag]) > 1000 ? dataGet[card.tag] * 2 : dataGet[card.tag] + 20,
        strictMinMax: false,
        renderer: axisRenderer
      })
    );

    let axisDataItem = xAxis.makeDataItem({});
    axisDataItem.set("value", dataGet[card.tag]);
    // eslint-disable-next-line
    let bullet = axisDataItem.set("bullet", am5xy.AxisBullet.new(root, {
      sprite: am5radar.ClockHand.new(root, {
        radius: am5.percent(80)
      })
    }));

    // let bullet = axisDataItem.set("bullet", am5xy.AxisBullet.new(root, {
    //   sprite: am5radar.ClockHand.new(root, {
    //     radius: am5.percent(80)
    //   })
    // }));

    xAxis.createAxisRange(axisDataItem);

    axisDataItem.get("grid").set("visible", true);

  }, [dataGet, card.tag]);

  return (
    <div id={"chartdiv" + card.tag}

      style={{
        width: cardWidth,
        height: '130px',
        display: "block",

      }
      }></div >
    // <div id="chartdiv" style={{ width: "100%", height: "100px" }}></div>
  )
}

export default GaugeChart;