import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { getAuthor } from "../../../src/utils/functions";

import { BrasilFlag, USFlag } from "../../assets/flags";



import axiosDefaults from "../../onClicks/axiosDefaults";
import {
  enableClassicTheme,
  enableDarkTheme,
  enableLightTheme,
  enableModernTheme,
} from "../../redux/actions/themeActions";

import { getCompanyLogo } from "../../utils/domain";
import packageJson from "./../../../package.json";
import { TextWithTooltip } from "../../components/TextWithTooltip";

const Flag = ({ image, isSelected, ...props }) => (
  <img
    alt="flag"
    src={image}
    style={{ borderRadius: "20%" }}
    className={isSelected ? "flag selected" : "flag"}
    {...props}
  />
);
const TranslateFlags = () => {
  const { i18n } = useTranslation();

  return (
    <div className="d-flex justify-content-center">
      <div className="mx-1 my-0">
        <Flag
          image={BrasilFlag}
          isSelected={i18n.language === "br"}
          onClick={() => i18n.changeLanguage("br")}
        />
      </div>

      <div className="mx-1 my-0">
        <Flag
          image={USFlag}
          isSelected={i18n.language === "en"}
          onClick={() => i18n.changeLanguage("en")}
        />
      </div>
    </div>
  );
};

/****** PÁGINA DE LOGIN -- PADRÃO DO TEMPLATE ******/
const SignIn = () => {
  const companyLogo = getCompanyLogo();
  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();

  const [entry, setEntry] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  let history = useHistory();
  let { pathname } = useLocation();

  function onSignIn() {
    /*** ARMAZENAMENTO DE DADOS DO FORMULARIO NO FORMATO JSON ***/
    const jsonData = JSON.stringify({
      entry: entry,
      password: password,
    });

    /*** ENVIO DE DADOS PELO AXIOS ***/
    axios
      .post("auth/login/", jsonData)
      .then((res) => {
        /* ARMAZENAMENTO DE ESTADO DE ACESSO, TOKEN E DADOS DE USUÁRIO */
        // armazenamento no localStorage
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        localStorage.setItem("userId", res.data.userId);
        localStorage.setItem("name", res.data.name);
        localStorage.setItem("companyId", res.data.companyId);
        localStorage.setItem("userType", res.data.userType);
        localStorage.setItem("avatar", res.data.userAvatar);
        localStorage.setItem("defaultTheme", res.data.defaultTheme);
        localStorage.setItem("soundOn", res.data.soundOn);
        localStorage.setItem("module", res.data.module);
        localStorage.setItem("projectId", res.data.projectsId[0]);

        if (localStorage.getItem("defaultTheme") === "classic") {
          dispatch(enableClassicTheme());
        } else if (localStorage.getItem("defaultTheme") === "dark") {
          dispatch(enableDarkTheme());
        } else if (localStorage.getItem("defaultTheme") === "light") {
          dispatch(enableLightTheme());
        } else if (localStorage.getItem("defaultTheme") === "modern") {
          dispatch(enableModernTheme());
        }

        // troca idioma
        i18n.changeLanguage(res.data.defaultLanguage);
        localStorage.setItem("defaultLanguage", res.data.defaultLanguage);

        localStorage.setItem("isLoggedIn", res.data.auth);
      })
      .catch((err) => {
        /* NOTIFICAÇÃO DE ERRO DE LOGIN */
        console.log(err);

        if (err.response) {
          console.log(err.response);
          toastr.warning(
            `Erro ${err.response.status}!`,
            err.response.data.message
          );
        }

        /* LIMPAR CAMPOS DO FORMULÁRIO */
        setEntry("");
        setPassword("");
      })
      .finally(() => {
        setLoading(false);
        axiosDefaults();
        history.push(pathname);
      });
  }

  return (
    <React.Fragment>
      <Card className="mb-2">
        <CardBody className="m-0">
          {/* iGoal Logo */}
          <div className="text-center">
            <img
              alt="logo"
              src={companyLogo}
              width={
                window.innerWidth < 992
                  ? "250"
                  : window.location.host === "engm.igoaltecnologia.com"
                  ? "120"
                  : window.location.host === "sollnet.igoaltecnologia.com"
                  ? "120"
                  : "333"
              }
              height="auto"
              className="mt-3"
            />
          </div>

          {/*** BLOCO DE TÍTULO ***/}
          <div className="text-center mt-1">
            <h4>{t("signin.welcome")}</h4>
            <p>{t("signin.text")}</p>
          </div>

          {/*** BLOCO DE FORMULÁRIO ***/}
          <div className="m-sm-4 m-0">
            <Form
              onSubmit={(e) => {
                // para enviar dados com Enter
                /* EVITAR ERRO AO ENVIAR DADOS COM Enter */
                e.preventDefault();
                setLoading(true);
                onSignIn();
              }}
            >
              {/*** CAMPO DE NOME DE USUÁRIO ***/}
              <FormGroup>
                <Label className="ml-1">{t("signin.user")}</Label>
                <Input
                  type="text"
                  name="entry"
                  id="entry"
                  placeholder={t("signin.userPlaceholder")}
                  value={entry}
                  onChange={(e) => setEntry(e.target.value)}
                />
              </FormGroup>

              {/*** CAMPO DE SENHA ***/}
              <FormGroup className="mp-0">
                <Label className="ml-1">{t("signin.password")}</Label>
                <Input
                  // bsSize="lg"
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  placeholder={t("signin.passwordPlaceholder")}
                  onChange={(e) => setPassword(e.target.value)}
                />

                {/*** LINK PARA REDEFINIR SENHA ***/}
                <small className="mp-0">
                  <Link
                    to="/auth/reset-password"
                    style={{ textDecorations: "none", color: "inherit" }}
                  >
                    {t("signin.forgotPassword")}
                  </Link>
                </small>
              </FormGroup>

              {/*** BOTÃO DE ENVIAR DADOS ***/}
              <div className="text-center">
                {loading ? (
                  <Spinner color="primary" />
                ) : (
                  <Button
                    type="submit" // para enviar dados com Enter
                    color="primary"
                  >
                    {t("signin.logIn")}
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </CardBody>
        <CardFooter>
          <div className="text-center">
          <TextWithTooltip text={`${getAuthor()} - v${packageJson.version.split("+")[0]}`}
              id="version"
              tooltip={packageJson.version}
              placement="top"
              style={{
                fontSize: '1rem'
              }}
            />
          </div>
        </CardFooter>
      </Card>

      {/* Bandeiras */}
      <div>
        <TranslateFlags />
      </div>

      {/* Powered by AWS Logo */}
      <div className="text-center">
        <a href="https://aws.amazon.com/what-is-cloud-computing">
          <img
            // src="https://d0.awsstatic.com/logos/powered-by-aws.png" // para fundo claro
            src="https://d0.awsstatic.com/logos/powered-by-aws-white.png" // para fundo escuro
            alt="Powered by AWS Cloud Computing"
            height="24"
            width="auto"
            className="logo mt-4"
          />
        </a>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
