import { useQuery } from "@tanstack/react-query";
import { ConfigProvider, Table } from "antd";
import axios from "axios";
import { useMemo, useRef, useState } from "react";
import { Nav } from "react-bootstrap";
import { Modal, ModalBody } from "reactstrap";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";

export const ModalAlarmsProjects = ({ showModal, handleModal, companyId }) => {
  const { t } = useTranslation();
  const {
    status,
    data: reactQuery,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["companyListAlarmssModal", companyId],
    queryFn: async () => {
      const res = await axios.get(
        `v2/company/listAlarms?companyId=${companyId}&listAlarms=true`
      );
      return res.data;
    },
  });

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`${t("navbar.search")} ${dataIndex}`}
          value={selectedKeys[0] || ""}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t("navbar.search")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {t("navbar.clear")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            {t("navbar.filter")}
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            {t("projTable.close")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // Definindo as prioridades
  const types = {
    highPriority: 1,
    mediumPriority: 2,
    lowPriority: "lowPriority", // Para qualquer valor diferente de 1 ou 2
  };

  // Colunas da tabela com filtro de prioridade
  const columns = useMemo(
    () => [
      {
        title: t("noc.table.messageOn"),
        dataIndex: "messageOn",
        key: "messageOn",
        width: 200,
        ...getColumnSearchProps("messageOn"),
      },
      {
        title: t("noc.table.value"),
        dataIndex: "value",
        key: "value",
        width: 120,
        sorter: (a, b) => a.value - b.value,
      },
      {
        title: t("noc.table.unit"),
        dataIndex: "unit",
        key: "unit",
        width: 100,
      },
      {
        title: t("noc.table.priority"),
        dataIndex: "priority",
        key: "priority",
        width: 120,
        sorter: (a, b) => a.priority - b.priority,
        filters: [
          { text: t("noc.labels.highPriority"), value: 1 },
          { text: t("noc.labels.mediumPriority"), value: 2 },
          { text: t("noc.labels.lowPriority"), value: "low" },
        ],
        onFilter: (value, record) => {
          if (value === "low")
            return record.priority !== 1 && record.priority !== 2;
          return record.priority === value;
        },
        render: (priority) => {
          if (priority === 1) return t("noc.labels.highPriority");
          if (priority === 2) return t("noc.labels.mediumPriority");
          return t("noc.labels.lowPriority");
        },
      },
      {
        title: t("noc.table.dateOccurred"),
        dataIndex: "dateOccurred",
        key: "dateOccurred",
        width: 180,
        render: (timestamp) => new Date(timestamp * 1000).toLocaleString(),
      },
      {
        title: t("noc.table.dateFinished"),
        dataIndex: "dateFinished",
        key: "dateFinished",
        width: 180,
        render: (timestamp) =>
          timestamp ? new Date(timestamp * 1000).toLocaleString() : "N/A",
      },
    ],
    [searchText, searchedColumn]
  );

  const [activeTab, setActiveTab] = useState("All");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Filtragem por prioridade
  const filteredProjects = useMemo(() => {
    if (activeTab === "All") {
      return reactQuery || [];
    }

    if (activeTab === "lowPriority") {
      return reactQuery?.filter(
        (alarm) => alarm.priority !== 1 && alarm.priority !== 2
      );
    }

    return (
      reactQuery?.filter((alarm) => alarm.priority === types[activeTab]) || []
    );
  }, [reactQuery, activeTab]);

  return (
    <Modal isOpen={showModal} toggle={handleModal} size="xl">
      <Nav variant="tabs" activeKey={activeTab} onSelect={toggle}>
        <Nav.Item>
          <Nav.Link eventKey="All">Todos</Nav.Link>
        </Nav.Item>
      </Nav>
      <ModalBody>
        {status === "loading" || isFetching ? (
          <p>Loading...</p>
        ) : (
          <div className="w-100" style={{ height: "100%", overflow: "hidden" }}>
            <ConfigProvider>
              <Table
                columns={columns.map((col) => ({
                  ...col,
                  onHeaderCell: (column) => ({
                    width: column.width,
                  }),
                }))}
                scroll={{ y: 47 * 10 }}
                dataSource={filteredProjects}
                size="small"
                pagination={{
                  pageSizeOptions: [10, 25, 50],
                  defaultPageSize: 25,
                }}
              />
            </ConfigProvider>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
