import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import CarouselCard from "./components/CarouselCard";
import { HeaderCards } from "./components/HeaderCards";
import WaterTools from "./components/Tools";
import UCList from "./components/UCList";
import { WaterModuleProvider, useWaterModuleAPI } from "./WaterProvider";

export const WaterModuleWithProvider = ({ projectId, activeTab }) => {
  return (
    <WaterModuleProvider projectId={projectId} activeTab={activeTab}>
      <WaterModule projectId={projectId} />
    </WaterModuleProvider>
  );
};

const WaterModule = ({ projectId }) => {
  const { t } = useTranslation();
  const adminUser = localStorage.getItem("userType") === "1";
  const endUser = localStorage.getItem("userType") === "3";

  const { generalData, ucList, loadingUcList, loadingGeneralData } =
    useWaterModuleAPI();

  // const [lastCommStr, setLastCommStr] = useState('-');
  const [selectedUC, setSelectedUC] = useState("general");
  const [objectDataUc, setObjectDataUc] = useState(generalData);

  const handleSelectUc = (uc) => {
    if (!generalData.processedAt) return;

    setSelectedUC(uc);
    setObjectDataUc(
      uc === "general"
        ? generalData
        : generalData?.registers?.filter((data) => data.ucId === uc.id)
    );
  };

  useEffect(() => {
    setObjectDataUc(generalData);
  }, [generalData]);

  return (
    <>
      {/*NOTE: Opções */}
      <WaterTools
        data={generalData}
        projectId={projectId}
        adminUser={adminUser}
      />

      {/*NOTE: Cards de cima */}
      <HeaderCards data={generalData} />

      {/*NOTE: UCs e Gráficos */}
      <Row>
        {/*NOTE: UCs */}
        {!endUser && (
          <Col lg="3" xs="12">
            <UCList
              UCs={ucList}
              selectedUC={selectedUC}
              setSelectedUC={handleSelectUc}
              loading={loadingUcList}
            />
          </Col>
        )}

        {/*NOTE: Gráficos */}
        <Col lg={9} xs={12} className="cards-uc">
          <CarouselCard
            generalDataConsumption={objectDataUc}
            selectedUC={selectedUC}
            loading={loadingGeneralData}
          />
        </Col>
      </Row>
    </>
  );
};
