const ptBrDriverTranslations = {
    addNewDriver: {
        myCustomDriversAddBtn: "Adicionar",
        noAddedDrivers: "Não foram adicionados drivers customizados.",
        addSuccessVar: "Variável adicionada com Sucesso.",
        tableColumnModels: "Modelo",
        tableColumnCompany: "Empresa",
        tableColumnType: "Tipo",
        title: "Meus Drivers",
        driverName: "Nome do Driver",
        customDrivers: "Drivers Personalizados",
        btnAdd: "Adicionar",
        modalTitle: "Adicionar driver",
        description: "Descrição",
        tag: "tag",
        unit: "Unidade",
        type: "Tipo",
        measureType: "Medida",
        driverCreator: "Criação de Drivers",
        editDriver: "Editar Driver",
        deleteVariable: "Deletar Variável",
        deleteDriver: "Excluir Driver",
        toastSaveVar: "Por favor, preencha todos os campos antes de salvar seu driver",

        // NOTE:MODAL ADICIONAR DRIVER
        variableSuccessfulyAdded: "Variável adicionada com Sucesso.",
        modelType: "Tipo de Driver",
        varName: "Nome da Variável",
        varDescription: "Descrição da Variável",
        varTag: "Tag",
        varUnit: "Unidade",
        varType: "Tipo",
        varMeasure: "Medida",
        addVarBtn: "Adicionar Variável",
        cancelVarBtn: "Cancelar",
        saveNewDriverBtn: "Salvar Alterações",
        // NOTE:Tipo de driver Options
        chooseOneOption: "Escolha uma opção",
        driverTypeC: "Ar Condicionado",
        driverTypeE: "Medidor de Energia",
        driverTypeM: "Estação Solarimétrica",
        driverTypeP: "Potência",
        driverTypeS: "Inversor",
        driverTypeT: "Rastreador Solar",
        driverTypeU: "Utilidades",
        // NOTE:Tipo de variável
        varTypeBool: "Lógico",
        varTypeNumber: "Número",
        varTypeText: "Texto",
        // NOTE:Tipo das Medidas
        varMeasureTypeI: "Integral",
        varMeasureTypeD: "Diferencial",
        // NOTE:Tipo Opcional da Variável
        varOptionalType: "Tipo de Variável",
        varOptionalTypeSelect: "Opcional",
        varOptionalTypeD: "Acumulador de Energia Diária",
        varOptionalTypeE: "Acumulador de Energia Total",
        varOptionalTypeM: "Irradiação Solar",
        varOptionalTypeP: "Leitura de Potência",
        varOptionalTypeS: "Status",
        // NOTE:MODAL DELETE
        confirmDeleteDriver: "Remover Driver",
        // NOTE:TABELA EXPANDIDA
        driverNameExpandedRow: "Nome",
        driverDescriptionExpandedRow: "Descrição",
        driverTagExpandedRow: "Tag",
        driverUnitExpandedRow: "Unidade",
        driverTypeExpandedRow: "Tipo",
        driverMeasureExpandedRow: "Medida",
        noVariablesAdded: "Não há variáveis adicionadas"
    },
}

export default ptBrDriverTranslations;