import React, { useState } from "react";
import {
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
// /*** PACOTE DE NOTIFICAÇÕES ***/
import { toastr } from "react-redux-toastr";
import axios from "axios";
/*** PACOTE DE TRADUÇÕES ***/
import { useTranslation } from 'react-i18next';

/****** MODAL DE REMOVER DASHBOARD ******/
const ModalDeleteDash = ({open, toggle, dash, tableReload}) => {
  const { t } = useTranslation();

  const [captcha, setCaptcha] = useState("");

  function onSubmitForm() {
    /* Verificação do captcha */
    if (captcha === "DELETE") {
      axios.delete('deleteDashboard/', {data: {
        dashId: dash.dashId
      }})
      .then(response => {
        toastr.success(response.data.messageHead, response.data.messageBody);
        tableReload(); // atualizar visualização da tabela
        toggle(); // fecha o modal
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!',"Erro ao deletar Dashboard."); // t() da problema com o useEffect
      });
    } 
    
    else {
      /* Notificação de captcha incorreto */
      toastr.warning(`${t('dataTable.error')}!`, t('dataTable.captcha'));
      setCaptcha(''); // Limpa campo de entrada
    }
  }

  return (
    <Modal 
      isOpen={open}
      toggle={toggle}
    >
      <Form
        onSubmit={e => {
          e.preventDefault(); // evitar erro ao enviar dados com Enter
          onSubmitForm();
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('dashboards.deleteDash')}
        </ModalHeader>

        <ModalBody className="text-center mx-6">
          <div className="m-4">
            <p className="mb-0">
              {t('dashboards.textDeleteDash')}
            </p>
            <Input 
              type="text" 
              name="captcha"
              id="captcha"
              value={captcha}
              onChange={e=>setCaptcha(e.target.value)}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button 
            color='primary'
            onClick={toggle}
            outline
          >
            {t('dataTable.cancel')}
          </Button>
          {/*** BOTÃO DE DELETAR DAHBOARD ***/}
          <Button
            color="primary"
            type="submit"
          >
            {t('dashboards.delete')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
};

export default ModalDeleteDash;