import React from "react";
import { Card, CardHeader, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";
import { Navigation } from "react-feather";
import { useTranslation } from 'react-i18next';

const WindDirection = ({ windAngle }) => {
  const { t } = useTranslation();

  const windDirection = (windAngle || windAngle === 0)
    ? (windAngle < 22.5)
      ? "north"
      : (windAngle < 67.5)
        ? "northeast"
        : (windAngle < 112.5)
          ? "east"
          : (windAngle < 157.5)
            ? "southeast"
            : (windAngle < 202.5)
              ? "south"
              : (windAngle < 247.5)
                ? "southwest"
                : (windAngle < 292.5)
                  ? "west"
                  : (windAngle < 337.5)
                    ? "northwest"
                    : "north"
    : null;

  return (
    <div className="w-100">
      <Row >
        <Col sm="12" style={{paddingRight:"0px"}}>
          <Card style={{minWidth: "200px", height: "140px", backgroundColor: "#dde0e5", marginBottom: "18px"}}>
            <CardHeader style={{padding: "25px 25px 25px 25px"}}>
              <Row>
                <Col className="mt-0">
                  <CardTitle tag="h5">{t('solar.windDirection')}</CardTitle>
                </Col>
                <Col className="col-auto" style={{paddingLeft:"0px"}}>
                  <div style={{transform: "rotate("+(windAngle-45)+"deg)"}}>
                    <Navigation 
                      className="feather align-middle" 
                      style={{width: "24px", height: "24px"}}
                    />
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody style={{paddingTop: "0px"}}> 
              {(windDirection)
                ? <h2 className="text-center"> {t('solar.'+windDirection)}</h2>
                : <div className="text-center mb-4"><Spinner /></div>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}   

export default WindDirection;