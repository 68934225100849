import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader } from "reactstrap"
import ModalPdfBill from "./ModalPdfBill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
//TODO: Solução temporária, conseguir uma melhor solução quando tiver tempo
const billPdfIcon = window.location.origin + '/reportBillPdf.png';

export const ModalSelectReport = ({
    open,
    toggle,
}) => {

  const { t } = useTranslation();

  const [isModalPdfBillOpen, setIsModalPdfBillOpen] = useState(false);

  function toggleModalPdfBill() {
    setIsModalPdfBillOpen(!isModalPdfBillOpen);
  }

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      size='lg'
    >
      <ModalHeader toggle={toggle}>
        {t("reportsEnergy.title")}
      </ModalHeader>

      <ModalBody className="overflow-auto d-flex justify-content-center align-items-center">
        <Card role="button" onClick={toggleModalPdfBill} className="m-2 card-img-hover-translateY">
          <CardHeader className="d-flex justify-content-between">
            {t("reportsEnergy.bill")}
            <FontAwesomeIcon
              icon={faFilePdf}
              style={{ height: "18px", width: "18px" }}
            />
          </CardHeader>
        <CardBody>
            <img style={{ width: "160px", paddingInline: "2rem" }} src={billPdfIcon} alt="Fatura Mensal" />
            {isModalPdfBillOpen && (
            <ModalPdfBill
                open={isModalPdfBillOpen}
                toggle={toggleModalPdfBill}
            />
            )}
        </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  )
}

export default ModalSelectReport;