import React, { useState, useEffect } from "react";
// import { Table } from "react-bootstrap";
import {
  Button,
  Form, FormGroup, Input, Label,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Row, Col
} from "reactstrap";
import { TreeSelect } from 'antd';
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


/****** MODAL DE EDIÇÃO DE ALARME ANALÓGICO ******/
const ModalEditAnalogicAlarm = ({ open, toggle, alarm, tableReload }) => {
  const { t } = useTranslation();

  let contactAux = [];

  if (alarm.email) {
    contactAux.push("email")
  }
  if (alarm.phone) {
    contactAux.push("phone")
  }
  if (alarm.whatsapp) {
    contactAux.push("whatsapp")
  }

  const [formDesc, setFormDesc] = useState(alarm.description);
  const [formPrior, setFormPrior] = useState(alarm.priority);
  const [formContact, setFormContact] = useState(contactAux);
  const [invalidDesc, setInvalidDesc] = useState(false);

  const [formThrLo, setFormThrLo] = useState(
    (alarm.lowLimit !== null) ? alarm.lowLimit.toString() : ''
  );
  const [formThrHi, setFormThrHi] = useState(
    (alarm.highLimit !== null) ? alarm.highLimit.toString() : ''
  );
  const [invalidThr, setInvalidThr] = useState(false);

  const [formMsgOnLo, setFormMsgOnLo] = useState(
    (alarm.messageLowOn) ? alarm.messageLowOn : ''
  );
  const [formMsgOnHi, setFormMsgOnHi] = useState(
    (alarm.messageHighOn) ? alarm.messageHighOn : ''
  );
  const [formMsgOffLo, setFormMsgOffLo] = useState(
    (alarm.messageLowOff) ? alarm.messageLowOff : ''
  );
  const [formMsgOffHi, setFormMsgOffHi] = useState(
    (alarm.messageHighOff) ? alarm.messageHighOff : ''
  );
  const [invalidMsgOnLo, setInvalidMsgOnLo] = useState(false);
  const [invalidMsgOnHi, setInvalidMsgOnHi] = useState(false);
  const [invalidMsgOffLo, setInvalidMsgOffLo] = useState(false);
  const [invalidMsgOffHi, setInvalidMsgOffHi] = useState(false);

  const inputStyle = {
    width: '20%'
  };

  const contactOptions = [
    {
      title: 'E-mail',
      key: 'email',
      value: 'email',
      isLeaf: true,
      checkable: true,
      id: 'email'
    },
    {
      title: t('alarms.phone'),
      key: 'phone',
      value: 'phone',
      isLeaf: true,
      checkable: true,
      id: 'phone'
    },
    {
      title: 'WhatsApp',
      key: 'whatsapp',
      value: 'whatsapp',
      isLeaf: true,
      checkable: true,
      id: 'whatsapp'
    }
  ];

  function onSubmitForm() {
    /** verifica se a descrição não está em branco */
    if (!formDesc) {
      setInvalidDesc(!formDesc);

      toastr.warning(`${t('alarms.att')}!`, `${'alarms.reference'}.`);
    }

    /** verifica se o usuário configurou algum limite */
    else if (!formThrLo && !formThrHi) {
      setInvalidThr(true);
      toastr.warning(`${t('alarms.error')}!`, `${t('alarms.noLimit')}.`)
    }

    /**  verifica se o limite superior é maior que o inferior, se ambos existem */
    else if (formThrLo && formThrHi && Number(formThrLo) >= Number(formThrHi)) {
      setInvalidThr(true);
      toastr.warning(`${t('alarms.error')}!`, `${t('alarms.lowerHigh')}.`)
    }

    /** verifica se o usuário inseriu mensagens para cada limite definido */
    else if ((formThrLo && (!formMsgOnLo || !formMsgOffLo)) || (formThrHi && (!formMsgOnHi || !formMsgOffHi))) {
      setInvalidMsgOnLo(Boolean(formThrLo && !formMsgOnLo));
      setInvalidMsgOnHi(Boolean(formThrHi && !formMsgOnHi));
      setInvalidMsgOffLo(Boolean(formThrLo && !formMsgOffLo));
      setInvalidMsgOffHi(Boolean(formThrHi && !formMsgOffHi));
      toastr.warning(`${t('alarms.error')}!`, `${t('alarms.noMessages')}.`)
    }

    else {
      const jsonData = {
        alarmId: alarm.id,
        description: formDesc,
        lowLimit: formThrLo,
        highLimit: formThrHi,
        priority: formPrior,
        contact: formContact,
        messageLowOn: (formThrLo) ? formMsgOnLo : '',
        messageHighOn: (formThrHi) ? formMsgOnHi : '',
        messageLowOff: (formThrLo) ? formMsgOffLo : '',
        messageHighOff: (formThrHi) ? formMsgOffHi : ''
      };

      axios.put('editAlarmConfig/', JSON.stringify(jsonData))
        .then(res => {
          toastr.success(res.data.messageHead, res.data.messageBody);
          tableReload();
          toggle();
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
        })
    }
  }

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      size={"lg"}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
          onSubmitForm();
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('alarms.editAlarm')} {alarm.description}
        </ModalHeader>

        <ModalBody className="text-left mx-6">
          {/* Descrição, prioridade e contato */}
          <Row>
            {/* Descrição */}
            <Col>
              <FormGroup>
                <div className="d-flex align-items-center justify-content-start">
                  <Label>{t('alarms.description')}:</Label>
                  <Input
                    type="text"
                    className="ml-1"
                    value={formDesc}
                    onChange={e => {
                      setFormDesc(e.target.value);
                      setInvalidDesc(false);
                    }}
                    invalid={invalidDesc}
                  />
                </div>
              </FormGroup>
            </Col>

            {/* Prioridade */}
            <Col>
              <FormGroup>
                <div className="d-flex align-items-center justify-content-start">
                  <Label>{t('alarms.priority')}:</Label>
                  <Input
                    className="ml-1"
                    type="select"
                    value={formPrior}
                    onChange={e => setFormPrior(e.target.value)}
                    required
                  >
                    <option value="1">{t('alarms.highPriority')}</option>
                    <option value="2">{t('alarms.mediumPriority')}</option>
                    <option value="3">{t('alarms.lowPriority')}</option>
                  </Input>
                </div>
              </FormGroup>
            </Col>

            {/* Contato */}
            <Col>
              <FormGroup>
                <div className="d-flex align-items-center justify-content-start">
                  <Label>{t('alarms.contact')}:</Label>
                  <TreeSelect
                    treeDataSimpleMode
                    maxTagCount="responsive"
                    treeData={contactOptions}
                    value={formContact}
                    onChange={value => setFormContact(value)}
                    treeCheckable={true}
                    placeholder={t('alarms.select')}
                    size="small"
                    style={{ width: '100%' }}
                    className="ml-1 cursor-pointer"
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>

          {/* Limites */}
          <Row>
            {/* Limite Inferior */}
            <Col>
              <FormGroup>
                <div className="d-flex align-items-center justify-content-start">
                  <Label>
                    {t('alarms.lowLim')}:
                  </Label>
                  <Input
                    type="text"
                    className="ml-1"
                    style={{
                      width: inputStyle.width,
                      borderColor: (invalidThr) ? 'orange' : '#ced4da'
                    }}
                    value={formThrLo}
                    onChange={e => {
                      setFormThrLo(e.target.value);
                      setInvalidThr(false);
                      setInvalidMsgOnLo(false);
                      setInvalidMsgOffLo(false);
                    }}
                  />
                  <Label className="ml-1">
                    {alarm.unit}
                  </Label>
                </div>
              </FormGroup>
            </Col>

            {/* Limite Superior */}
            <Col>
              <FormGroup>
                <div className="d-flex align-items-center justify-content-start">
                  <Label>
                    {t('alarms.highLim')}:
                  </Label>
                  <Input
                    className="ml-1"
                    type="text"
                    style={{
                      width: inputStyle.width,
                      borderColor: (invalidThr) ? 'orange' : '#ced4da'
                    }}
                    value={formThrHi}
                    onChange={e => {
                      setFormThrHi(e.target.value);
                      setInvalidThr(false);
                      setInvalidMsgOnHi(false);
                      setInvalidMsgOffHi(false);
                    }}
                  />
                  <Label className="ml-1">
                    {alarm.unit}
                  </Label>
                </div>
              </FormGroup>
            </Col>
          </Row>

          {/* Mensagens ON */}
          <Row>
            {/* Mensagem ON Inferior */}
            <Col>
              <FormGroup>
                <Label>{t('alarms.msgOnLo')}:</Label>
                <Input
                  type="textarea"
                  rows="3"
                  value={formMsgOnLo}
                  onChange={e => {
                    setFormMsgOnLo(e.target.value);
                    setInvalidMsgOnLo(false);
                  }}
                  disabled={(!formThrLo)}
                  placeholder={(!formThrLo) ? t('alarms.noLimitLow') : null}
                  invalid={invalidMsgOnLo}
                />
              </FormGroup>
            </Col>

            {/* Mensagem ON Superior */}
            <Col>
              <FormGroup>
                <Label>{t('alarms.msgOnHi')}:</Label>
                <Input
                  type="textarea"
                  rows="3"
                  value={formMsgOnHi}
                  onChange={e => {
                    setFormMsgOnHi(e.target.value);
                    setInvalidMsgOnHi(false);
                  }}
                  disabled={(!formThrHi)}
                  placeholder={(!formThrHi) ? t('alarms.noLimitHigh') : null}
                  invalid={invalidMsgOnHi}
                />
              </FormGroup>
            </Col>
          </Row>

          {/* Mensagens OFF */}
          <Row>
            {/* Mensagem OFF Inferior */}
            <Col>
              <FormGroup>
                <Label>{t('alarms.msgOffLo')}:</Label>
                <Input
                  type="textarea"
                  rows="3"
                  value={formMsgOffLo}
                  onChange={e => {
                    setFormMsgOffLo(e.target.value);
                    setInvalidMsgOffLo(false);
                  }}
                  disabled={(!formThrLo)}
                  placeholder={(!formThrLo) ? t('alarms.noLimitLow') : null}
                  invalid={invalidMsgOffLo}
                />
              </FormGroup>
            </Col>

            {/* Mensagem OFF Superior */}
            <Col>
              <FormGroup>
                <Label>{t('alarms.msgOffHi')}:</Label>
                <Input
                  type="textarea"
                  rows="3"
                  value={formMsgOffHi}
                  onChange={e => {
                    setFormMsgOffHi(e.target.value);
                    setInvalidMsgOffHi(false);
                  }}
                  disabled={(!formThrHi)}
                  placeholder={(!formThrHi) ? t('alarms.noLimitHigh') : null}
                  invalid={invalidMsgOffHi}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button
            name="btnCancel"
            type="button"
            color='primary'
            className="mr-1 mb-1"
            onClick={toggle}
            outline
          >
            {t('alarms.cancel')}
          </Button>

          {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
          <Button
            name="btnClose"
            type="submit"
            color="primary"
          >
            {t('alarms.saveChanges')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

/****** MODAL DE EDIÇÃO DE ALARME DIGITAL ******/
const ModalEditDigitalAlarm = ({ open, toggle, alarm, tableReload }) => {
  const { t } = useTranslation();

  let contactAux = [];

  if (alarm.email) {
    contactAux.push("email")
  }
  if (alarm.phone) {
    contactAux.push("phone")
  }
  if (alarm.whatsapp) {
    contactAux.push("whatsapp")
  }

  const [formPrior, setFormPrior] = useState(alarm.priority);
  const [formDesc, setFormDesc] = useState(alarm.description);
  const [formContact, setFormContact] = useState(contactAux);
  const [invalidDesc, setInvalidDesc] = useState(false);

  const [formActive, setFormActive] = useState((alarm.highLimit)
    ? alarm.highLimit
    : alarm.lowLimit
  );
  const [formMsgOn, setFormMsgOn] = useState((alarm.messageHighOn)
    ? alarm.messageHighOn
    : alarm.messageLowOn
  );
  const [formMsgOff, setFormMsgOff] = useState((alarm.messageHighOff)
    ? alarm.messageHighOff
    : alarm.messageLowOff
  );
  const [invalidMsgOn, setInvalidMsgOn] = useState(false);
  const [invalidMsgOff, setInvalidMsgOff] = useState(false);

  const contactOptions = [
    {
      title: 'E-mail',
      key: 'email',
      value: 'email',
      isLeaf: true,
      checkable: true,
      id: 'email'
    },
    {
      title: t('alarms.phone'),
      key: 'phone',
      value: 'phone',
      isLeaf: true,
      checkable: true,
      id: 'phone'
    },
    {
      title: 'WhatsApp',
      key: 'whatsapp',
      value: 'whatsapp',
      isLeaf: true,
      checkable: true,
      id: 'whatsapp'
    }
  ];

  function onSubmitForm() {
    /** verifica se o usuário inseriu campos obrigatórios */
    if (!formDesc || !formMsgOn || !formMsgOff) {
      setInvalidDesc(!formDesc);
      setInvalidMsgOn(!formMsgOn);
      setInvalidMsgOff(!formMsgOff);

      toastr.warning(`${t('alarms.att')}!`, `${'alarms.invalid'}.`);
    }

    else {
      const jsonData = {
        alarmId: alarm.id,
        description: formDesc,
        priority: formPrior,
        contact: formContact,
        lowLimit: (formActive === 0)
          ? 0
          : '',
        messageLowOn: (formActive === 0)
          ? formMsgOn
          : '',
        messageLowOff: (formActive === 0)
          ? formMsgOff
          : '',
        highLimit: (formActive === 1)
          ? 1
          : '',
        messageHighOn: (formActive === 1)
          ? formMsgOn
          : '',
        messageHighOff: (formActive === 1)
          ? formMsgOff
          : ''
      };

      axios.put('editAlarmConfig/', JSON.stringify(jsonData))
        .then(res => {
          toastr.success(res.data.messageHead, res.data.messageBody);
          tableReload();
          toggle();
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
        })
    }
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      size={"lg"}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();

          onSubmitForm();
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('alarms.editAlarm')} {alarm.description}
        </ModalHeader>

        <ModalBody className="text-left mx-3">
          {/* Descrição, prioridade e contato */}
          <Row lg="3" xs="1">
            {/* Descrição */}
            <Col>
              <FormGroup>
                <div className="d-flex align-items-center justify-content-start">
                  <Label>{t('alarms.description')}:</Label>
                  <Input
                    type="text"
                    className="ml-1"
                    value={formDesc}
                    onChange={e => {
                      setFormDesc(e.target.value);
                      setInvalidDesc(false);
                    }}
                    invalid={invalidDesc}
                  />
                </div>
              </FormGroup>
            </Col>

            {/* Prioridade */}
            <Col>
              <FormGroup>
                <div className="d-flex align-items-center justify-content-start">
                  <Label>{t('alarms.priority')}:</Label>
                  <Input
                    className="ml-1"
                    type="select"
                    value={formPrior}
                    onChange={e => setFormPrior(e.target.value)}
                    required
                  >
                    <option value="1">{t('alarms.highPriority')}</option>
                    <option value="2">{t('alarms.mediumPriority')}</option>
                    <option value="3">{t('alarms.lowPriority')}</option>
                  </Input>
                </div>
              </FormGroup>
            </Col>

            {/* Contato */}
            <Col>
              <FormGroup>
                <div className="d-flex align-items-center justify-content-start">
                  <Label>{t('alarms.contact')}:</Label>
                  <TreeSelect
                    treeDataSimpleMode
                    maxTagCount="responsive"
                    treeData={contactOptions}
                    value={formContact}
                    onChange={value => setFormContact(value)}
                    treeCheckable={true}
                    placeholder={t('alarms.select')}
                    size="small"
                    style={{ width: '100%' }}
                    className="ml-1 cursor-pointer"
                  // className="ml-1 modal-content form-control cursor-pointer"
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>

          {/* Estado de Ativação */}
          <Row>
            <Col lg="4" sm="7" xs="12">
              <FormGroup>
                <div className="d-flex align-items-center justify-content-between">
                  <Label>{t('alarms.activeState')}:</Label>
                  <Input
                    type="select"
                    className="ml-1"
                    style={{ width: "55%" }}
                    value={formActive}
                    onChange={e => {
                      setFormActive(Number(e.target.value));
                    }}
                  >
                    <option value={0}>{t('alarms.off')}</option>
                    <option value={1}>{t('alarms.on')}</option>
                  </Input>
                </div>
              </FormGroup>
            </Col>
            <Col lg="4" sm="5" xs="0"></Col>
            <Col lg="4" sm="0" xs="0"></Col>
          </Row>

          {/* Mensagem de alarme ligado */}
          <Row sm="2" xs="1">
            <Col>
              <FormGroup>
                <Label>{t('alarms.msgOn')}:</Label>
                <Input
                  type="textarea"
                  rows="3"
                  value={formMsgOn}
                  onChange={e => {
                    setFormMsgOn(e.target.value);
                    setInvalidMsgOn(false);
                  }}
                  invalid={invalidMsgOn}
                />
              </FormGroup>
            </Col>
            <Col></Col>
          </Row>

          {/* Mensagem de alarme desligado */}
          <Row sm="2" xs="1">
            <Col>
              <FormGroup>
                <Label>{t('alarms.msgOff')}:</Label>
                <Input
                  type="textarea"
                  rows="3"
                  value={formMsgOff}
                  onChange={e => {
                    setFormMsgOff(e.target.value);
                    setInvalidMsgOff(false);
                  }}
                  invalid={invalidMsgOff}
                />
              </FormGroup>
            </Col>
            <Col></Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button
            name="btnCancel"
            type="button"
            color='primary'
            className="mr-1 mb-1"
            onClick={toggle}
            outline
          >
            {t('alarms.cancel')}
          </Button>

          {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
          <Button
            name="btnClose"
            type="submit"
            color="primary"
          >
            {t('alarms.saveChanges')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

/******** COMPONENTE DE EDIÇAO DE ALARMES MULTIPLOS */
function VariableSelect({ config, index, formConfig, setFormConfig }) {
  const { t } = useTranslation();
  const [variableList, setVariableList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    axios.get('/projectUserSearch')
      .then(res => {
        setProjectList(res.data)
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar projetos.");  // t() da problema com o useEffect
      });
  }, []);

  useEffect(() => {
    axios.get('/readVariables', {
      params: {
        id: config.formDev.deviceId
      }
    })
      .then(res => {
        setVariableList(res.data)
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar variáveis.");  // t() da problema com o useEffect
      })
  }, [config.formDev]);

  useEffect(() => {
    if (config.formProj) {
      axios.get('/deviceSearch', {
        params: {
          projId: config.formProj.projId
        }
      })
        .then(res => {
          setDeviceList(res.data)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar dispositivos.");  // t() da problema com o useEffect
        }, [config.formProj.projId]);
    }
  }, [config.formProj]);


  // FUNÇÃO DE DELETAR OPÇAO DE COMPARAÇÃO 
  function deleteAlarmOption(index) {
    setFormConfig(formConfig.filter((element, i) => (i !== index)));

  }
  function addNewComparison() {
    let addAlarmVariable = {
      id: config.length,
      comparisonType: config.comparisonType,
      formDev: config.formDev,
      formProj: config.formProj,
      formVar: config.formVar,
      formThrHi: config.formThrHi,
      formThrLo: config.formThrLo,
      formActive: config.formActive,
      formMsgOn: config.formMsgOn,
      formMsgOff: config.formMsgOff,
    };
    setFormConfig(prev => [...prev, addAlarmVariable])
  }
  return (<>
    <Row>
      {/** NOME DE PROJETOS **/}
      <Col>
        <FormGroup>
          <Label>{t('alarms.project')}</Label>
          <Input
            type="select"
            value={JSON.stringify(config.formProj)}
            onChange={(e) => {
              setFormConfig(formConfig.map((data, i) => {

                return (i === index)
                  ? { ...data, formProj: JSON.parse(e.target.value) }
                  : data;
              }))
            }}
          >
            <option key={0} value={''} disabled>
              {t('alarms.projectSelect')}...
            </option>
            {(projectList.length)
              ? projectList.map(item => (
                <option key={item.id} value={JSON.stringify({
                  projId: item.id,
                  projName: item.name
                })}>
                  {item.name}
                </option>
              ))
              : null
            }
          </Input>
        </FormGroup>
      </Col>
      {/* NOME DE DISPOSITIVOS  */}
      <Col>
        <FormGroup>
          <Label>{t('alarms.device')}</Label>
          <Input
            required={true}
            type="select"
            value={JSON.stringify(config.formDev)}
            onChange={(e) =>
              setFormConfig(formConfig.map((data, i) => {

                return (i === index)
                  ? { ...data, formDev: JSON.parse(e.target.value) }
                  : data;
              }))
            }
          >
            <option key={0} value={''} disabled>
              {t('alarms.deviceSelect')}...
            </option>
            {(deviceList.length)
              ? deviceList.map(item => (
                <option key={item.id} value={JSON.stringify({
                  deviceId: item.id,
                  deviceName: item.description

                })}>
                  {item.description}

                </option>
              ))
              : null
            }
          </Input>
        </FormGroup>
      </Col>
      {/** NOME DE VARIAVEIS */}
      <Col>
        <FormGroup>
          <Label>{t('alarms.variable')}</Label>
          <Input
            type="select"
            value={JSON.stringify(config.formVar)}
            onChange={(e) => {

              setFormConfig(formConfig.map((data, i) => {

                return (i === index)
                  ? { ...data, formVar: JSON.parse(e.target.value) }
                  : data;
              }))
            }}
          >
            <option key={index} value={''} disabled>
              {t('alarms.variableSelect')}...
            </option>
            {(config.formDev)
              ? variableList.map((item, i) => (
                <option key={i} value={JSON.stringify({
                  varName: item.varName,
                  varTag: item.tag,
                  varUnit: item.unit
                })}>
                  {item.varName}
                </option>
              ))
              : null
            }
          </Input>
        </FormGroup>
      </Col>
    </Row>
<Row>
    <Col>
      <FormGroup>
        {'Tipo de comparaçao'}
        <Input
          style={{ paddingBottom: "0" }}
          type="select"
          value={config.comparisonType}
          onChange={(e) =>
            setFormConfig(formConfig.map((data, i) => {

              return (i === index)
                ? { ...data, comparisonType: e.target.value }
                : data;
            }))
          }
        >
          <option value={'0'}>
            {t('alarms.analogic')}
          </option>
          <option value={'1'}>
            {t('alarms.digital')}
          </option>

        </Input>
      </FormGroup>
    </Col>
    <Col></Col>
    

    {(config.comparisonType === '0') ?
      <>
        {/* Limite Inferior */}
        <Col >
          <FormGroup>

            <Label>{t('alarms.lowLim')}:</Label>
            <div className="d-flex">
              <Input
                type="text"
                className="ml-1"
                // value={formThrLo}
                // onChange={(e) =>
                //   JSON.stringify(setFormThrLo(e.target.value))
                // }
                value={config.formThrLo}
                onChange={(e) =>
                  setFormConfig(formConfig.map((data, i) => {

                    return (i === index)
                      ? { ...data, formThrLo: e.target.value }
                      : data;
                  }))
                }
              />
              <Label className="ml-1">
                {(config.formVar)
                  ? config.formVar.varUnit
                  : ""
                }
              </Label>
            </div>
          </FormGroup>
        </Col>
        {/* Limite Superior */}
        <Col>
          <FormGroup>

            <Label>{t('alarms.highLim')}:</Label>
            <div className="d-flex">
              <Input
                type="text"
                className="ml-1"
                // value={formThrHi}
                // onChange={(e) =>
                //   JSON.stringify(setFormThrHi(e.target.value))
                // }
                value={config.formThrHi}
                onChange={(e) =>
                  setFormConfig(formConfig.map((data, i) => {

                    return (i === index)
                      ? { ...data, formThrHi: e.target.value }
                      : data;
                  }))
                }

              />
              <Label className="ml-1">
                {(config.formVar)
                  ? config.formVar.varUnit
                  : ""
                }
              </Label>
            </div>
          </FormGroup>
        </Col>
        <Col style={{ textAlign: "end" }}>
          <Button value={formConfig} color="danger" type="button" onClick={() => deleteAlarmOption(index)} size={'sm'}><FontAwesomeIcon icon={faTrash} fixedWidth /></Button>
          <Button style={{ marginLeft: "0.1rem" }} value={JSON.stringify(formConfig)} color="primary" type="button" onClick={addNewComparison} size={'sm'}><FontAwesomeIcon icon={faPlus} fixedWidth /></Button>
        </Col>
        </>: null}

    {(config.comparisonType === '1') ?
      <>
        {/* Estado de Ativação */}
        <Col >
          <FormGroup style={{ margin: "0" }}>
            <div className="d-flex flex-column align-items-center">
              <Label>{t('alarms.activeState')}:</Label>
              <Input
                type="select"
                className="ml-1"
                style={{ width: "100%" }}
                value={config.formActive}
                onChange={(e) =>
                  setFormConfig(formConfig.map((data, i) => {

                    return (i === index)
                      ? { ...data, formActive: e.target.value }
                      : data;
                  }))
                }
              >
                <option value={0}>{t('alarms.off')}</option>
                <option value={1}>{t('alarms.on')}</option>
              </Input>
            </div>
          </FormGroup>
        </Col>
        <Col></Col>
        <Col style={{ textAlign: "end" }}>
          <Button value={formConfig} color="danger" type="button" onClick={() => deleteAlarmOption(index)} size={'sm'}><FontAwesomeIcon icon={faTrash} fixedWidth /></Button>
          <Button style={{ marginLeft: "0.1rem" }} value={JSON.stringify(formConfig)} color="primary" type="button" onClick={addNewComparison} size={'sm'}><FontAwesomeIcon icon={faPlus} fixedWidth /></Button>
        </Col>
      </> : null}
    <Row>

    </Row>
    </Row>
  </>)
}

/**********MODAL DE EDIÇÃO DE ALARMES MULTIPLOS **********/
const ModalEditMultiplelAlarm = ({ open, toggle, alarm, tableReload }) => {
  const { t } = useTranslation();

  let contactAux = [];

  if (alarm.email) {
    contactAux.push("email")
  }
  if (alarm.phone) {
    contactAux.push("phone")
  }
  if (alarm.whatsapp) {
    contactAux.push("whatsapp")
  }


  const [formPrior, setFormPrior] = useState(alarm.priority);
  const [formDesc, setFormDesc] = useState(alarm.description);
  const [formContact, setFormContact] = useState(contactAux);
  const [invalidDesc, setInvalidDesc] = useState(false);
  const [formMsgOn, setFormMsgOn] = useState((alarm.messageHighOn)
    ? alarm.messageHighOn
    : alarm.messageLowOn
  );
  const [formMsgOff, setFormMsgOff] = useState((alarm.messageHighOff)
    ? alarm.messageHighOff
    : alarm.messageLowOff
  );

  const [invalidMsgOn, setInvalidMsgOn] = useState(false);
  const [invalidMsgOff, setInvalidMsgOff] = useState(false);
  const [formConfig, setFormConfig] = useState(JSON.parse(alarm.multipleConfig))

  const contactOptions = [
    {
      title: 'E-mail',
      key: 'email',
      value: 'email',
      isLeaf: true,
      checkable: true,
      id: 'email'
    },
    {
      title: t('alarms.phone'),
      key: 'phone',
      value: 'phone',
      isLeaf: true,
      checkable: true,
      id: 'phone'
    },
    {
      title: 'WhatsApp',
      key: 'whatsapp',
      value: 'whatsapp',
      isLeaf: true,
      checkable: true,
      id: 'whatsapp'
    }
  ];
  
  function onSubmitForm() {
    /** verifica se o usuário inseriu campos obrigatórios */
    if (!formDesc || !formMsgOn || !formMsgOff) {
      setInvalidDesc(!formDesc);
      setInvalidMsgOn(!formMsgOn);
      setInvalidMsgOff(!formMsgOff);

      toastr.warning(`${t('alarms.att')}!`, `${'alarms.invalid'}.`);
    }

    else {
      const multipleAlarmsJson = {
        alarmId: alarm.id,
        deviceId: null,
        description: formDesc,
        varName: null,
        varTag: null,
        varUnit: null,
        priority: formPrior,
        lowLimit: '',
        highLimit: '',
        contact: formContact,
        messageLowOn: formMsgOn,
        messageHighOn: formMsgOn,
        messageLowOff: formMsgOff,
        messageHighOff: formMsgOff,
        multipleConfig: formConfig
      };

      axios.put('editAlarmConfig/', JSON.stringify(multipleAlarmsJson))
      .then(res => {
        toastr.success(res.data.messageHead, res.data.messageBody);
        tableReload();
        toggle();
      })
      .catch(err => {
        console.log(err);
        console.log(err.response);
      })
    }
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      size={"lg"}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();

          onSubmitForm();

        }}
      >
        <ModalHeader toggle={toggle}>
          {t('alarms.editAlarm')} {alarm.description}
        </ModalHeader>

        <ModalBody className="text-left mx-3">
          {/* Descrição, prioridade e contato */}

          <Row lg="3" xs="1">
            {/* Descrição */}
            <Col>
              <FormGroup>
                <div className="d-flex align-items-center justify-content-start">
                  <Label>{t('alarms.description')}:</Label>
                  <Input
                    type="text"
                    className="ml-1"
                    value={formDesc}
                    onChange={e => {
                      setFormDesc(e.target.value);
                      setInvalidDesc(false);
                    }}
                    invalid={invalidDesc}
                  />
                </div>
              </FormGroup>
            </Col>

            {/* Prioridade */}
            <Col>
              <FormGroup>
                <div className="d-flex align-items-center justify-content-start">
                  <Label>{t('alarms.priority')}:</Label>
                  <Input
                    className="ml-1"
                    type="select"
                    value={formPrior}
                    onChange={e => setFormPrior(e.target.value)}
                    required
                  >
                    <option value="1">{t('alarms.highPriority')}</option>
                    <option value="2">{t('alarms.mediumPriority')}</option>
                    <option value="3">{t('alarms.lowPriority')}</option>
                  </Input>
                </div>
              </FormGroup>
            </Col>

            {/* Contato */}
            <Col>
              <FormGroup>
                <div className="d-flex align-items-center justify-content-start">
                  <Label>{t('alarms.contact')}:</Label>
                  <TreeSelect
                    treeDataSimpleMode
                    maxTagCount="responsive"
                    treeData={contactOptions}
                    value={formContact}
                    onChange={value => setFormContact(value)}
                    treeCheckable={true}
                    placeholder={t('alarms.select')}
                    size="small"
                    style={{ width: '100%' }}
                    className="ml-1 cursor-pointer"
                  // className="ml-1 modal-content form-control cursor-pointer"
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>

          {/**EDIÇÂO DE LIMITES ****/}
          {formConfig.map((data, index) => (

            <div key={index} >
              <hr />
              <>
                <VariableSelect
                  key={index}
                  config={data}
                  alarm={alarm}
                  index={index}
                  formConfig={formConfig}
                  setFormConfig={setFormConfig}
                />

              </>
            </div>))}

          {/* Mensagem de alarme ligado */}
          <hr />
          <Row style={{ marginTop: "1rem" }} sm="2" xs="1">

            <Col>
              <FormGroup>
                <Label>{t('alarms.msgOn')}:</Label>
                <Input
                  type="textarea"
                  rows="3"
                  value={formMsgOn}
                  onChange={e => {
                    setFormMsgOn(e.target.value);
                    setInvalidMsgOn(false);
                  }}
                  invalid={invalidMsgOn}
                />
              </FormGroup>
            </Col>
            {/* Mensagem de alarme desligado */}

            <Col>
              <FormGroup>
                <Label>{t('alarms.msgOff')}:</Label>
                <Input
                  type="textarea"
                  rows="3"
                  value={formMsgOff}
                  onChange={e => {
                    setFormMsgOff(e.target.value);
                    setInvalidMsgOff(false);
                  }}
                  invalid={invalidMsgOff}
                />
              </FormGroup>
            </Col>
          </Row>

        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button
            name="btnCancel"
            type="button"
            color='primary'
            className="mr-1 mb-1"
            onClick={toggle}
            outline
          >
            {t('alarms.cancel')}
          </Button>

          {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
          <Button
            name="btnClose"
            type="submit"
            color="primary"
          >
            {t('alarms.saveChanges')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

/****** MODAL DE EDIÇÃO ALARMES MULTIPLOS *********/
export { ModalEditAnalogicAlarm, ModalEditDigitalAlarm, ModalEditMultiplelAlarm };