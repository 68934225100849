import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { Zap } from "react-feather";

const EnergyDaily = ({ data, state, allData, EnergyDaily }) => {
  const { t } = useTranslation();

  var dailyEnergyEnergyAndUnit = (EnergyDaily < 1000)
  ? (EnergyDaily.toFixed(2)).replace(".", ",") + " kWh"
    : (EnergyDaily < 1000000)
      ? (parseFloat((EnergyDaily/1000).toFixed(2))).toString().replace(".", ",") + " MWh"
        : (EnergyDaily < 1000000000)
          ? (parseFloat((EnergyDaily/1000000).toFixed(2))).toString().replace(".", ",") + " GWh"
            : (EnergyDaily < 1000000000000)
              ? (parseFloat((EnergyDaily/1000000000).toFixed(2))).toString().replace(".", ",") + " TWh"
              : ""

  return (
    <div className="w-100">
      <Row >
        <Col sm="12" style={{paddingRight:"0px"}}>
          <Card style={{minWidth: "135px", maxHeight:"100px", height: "100px", marginBottom: "0px", border: state, borderStyle:"solid", background:"#dde0e5"}}>
            <CardBody style={{padding: "15px", paddingTop: "10px"}}>
              <Row>
                <Col className="mt-0">
                  <CardTitle tag="h5">{t('solar.dailyEnergy')}</CardTitle>
                </Col>
                <Col className="col-auto" style={{paddingLeft:"0px"}}>
                  <div>
                    <Zap className="feather align-middle" style={{width: "19px", height: "19px"}} />
                  </div>
                </Col>
              </Row>
              {(allData)
                ? <h1 className="display-5 text-center" style={{fontSize:"17px", fontWeight:"500"}}>{dailyEnergyEnergyAndUnit}</h1>
                : <div className="text-center mb-4"><Spinner /></div>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}   

export default EnergyDaily;