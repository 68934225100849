import React, { useState } from "react";
import "./styles.css";

import {
  Breadcrumb,
  Button,
  Card,
  CardTitle,
  Container,
  Spinner,
} from "react-bootstrap";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Plus } from "react-feather";

import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";
import {
  ServiceAuthorizationsProvider,
  useServiceAuthContext,
} from "./ServiceAuthorizationsProvider";
import { CardItem } from "./components/CardItem";
import { FormModalAS } from "./components/FormModalAS";

export const ServiceAuthorizationsWithProvider = () => {
  return (
    <ServiceAuthorizationsProvider>
      <ServiceAuthorizations />
    </ServiceAuthorizationsProvider>
  );
};

const ServiceAuthorizations = () => {
  const { t } = useTranslation();
  const currentTheme = useSelector((store) => store.theme.currentTheme);

  const {
    modalOpen,
    handleCloseModal,
    listNocData,
    modalEdit,
    handleOnDragEnd,
    setModalOpen
  } = useServiceAuthContext();

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  if (listNocData.loading) {
    return <div>Loading...</div>;
  }

  if (!listNocData.data.list) {
    return <div>No data available</div>;
  }

  const filteredCards = listNocData.data.list.filter((item) =>
    item.projectName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groupedCards = filteredCards.reduce((acc, item) => {
    if (!acc[item.statusName]) {
      acc[item.statusName] = [];
    }
    acc[item.statusName].push(item);
    return acc;
  }, {});

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t("noc.header.title")}</HeaderTitle>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">{t("sidebar.home")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t("noc.header.title")}</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Card className="p-2">
        <Card.Header className="p-0 d-flex justify-content-between align-items-center">
          <Card.Title className="m-0" style={{ width: "fit-content" }}>
            {t("noc.header.cardTitle")}
          </Card.Title>
          <Button
            variant="outline-primary"
            className="p-0 rounded-circle"
            onClick={() => setModalOpen(true)}
          >
            <Plus color={currentTheme.primary} />
          </Button>
        </Card.Header>
        <Card.Body className="p-0 mt-4">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-bar"
          />
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div
              style={{
                display: "flex",
                gap: "4px",
                overflow: "scroll",
                height: "calc(100vh - 300px)",
              }}
            >
              {listNocData.data.header.map(({ name }) => {
                const columnItems = groupedCards[name] || [];
                return (
                  <Droppable droppableId={name} key={name}>
                    {(provided) => (
                      <div
                        style={{
                          flex: 1,
                          minWidth: "288px",
                          maxWidth: "288px",
                          padding: "4px",
                          boxSizing: "border-box",
                          borderRadius: "5px",
                          overflowY: "auto",
                          maxHeight: "calc(100vh - 300px)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          border: "1px solid #e9ecef",
                          backgroundColor: "hsl(0, 5%, 98%)",
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <CardTitle tag="h5" className="w-100 text-left">
                          {t(`noc.card.${name}`)}
                        </CardTitle>
                        {columnItems.length === 0 ? (
                          <p className="empty-column">Nenhum item</p>
                        ) : (
                          columnItems.map((item, index) => (
                            <Draggable
                              key={item.nocId.toString()}
                              draggableId={item.nocId.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <CardItem item={item} index={index} />
                                </div>
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                );
              })}
            </div>
          </DragDropContext>
        </Card.Body>
      </Card>
      {modalEdit.loading && (
        <div
          style={{
            position: "absolute",
            background: "rgba(0,0,0,0.3)",
            inset: 0,
            zIndex: 2000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
      {(modalOpen || modalEdit.id) && (
        <FormModalAS
          open={modalOpen || modalEdit.id}
          toggle={handleCloseModal}
          id={modalEdit.id}
        />
      )}
    </Container>
  );
};
