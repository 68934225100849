/****** IMPORTS  ******/
import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { useDataExplorer } from "../DataExplorerProvider";
import DataExplorerChart from "./NewLineChart";
import DadosTableShow from "./TableData";

const CardWithTabs = () => {
  const { t, i18n } = useTranslation();
  const { activeTab, setActiveTab, fetchLoading, dataExplorerInfo } =
    useDataExplorer();

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }
  if (fetchLoading.includes("dashboard")) {
    return (
      <div className="w-100 p-4">
        <div className="text-center d-flex align-items-center justify-content-center">
          <Spinner size="lg" />
          <span className="ml-2">{t("dataExplorer.loading")}</span>
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <CardHeader>
        <Nav
          tabs
          className="card-header-tabs"
          style={{ marginLeft: "0.5rem", position: "sticky" }}
        >
          <NavItem style={{ zIndex: "1000" }}>
            <NavLink
              className={classnames({ active: activeTab === "dashboard" })}
              onClick={() => {
                toggle("dashboard");
              }}
              href="#"
            >
              {t("dataExplorer.graphics")}{" "}
              {fetchLoading.includes("dashboard") && <Spinner size="sm" />}
            </NavLink>
          </NavItem>

          <NavItem style={{ zIndex: "1000" }}>
            <NavLink
              className={classnames({ active: activeTab === "table" })}
              onClick={() => {
                toggle("table");
              }}
              href="#"
            >
              {t("dataExplorer.dataTable")}{" "}
              {fetchLoading.includes("dashboard") && <Spinner size="sm" />}
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>

      <CardBody>
        <TabContent activeTab={activeTab}>
          <TabPane
            tabId="dashboard"
            className="text-center"
            style={{ padding: "1.1vh" }}
          >
            <DataExplorerChart dataExplorerInfo={dataExplorerInfo} t={t} language={i18n.language} />
          </TabPane>
        </TabContent>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="table" className="text-center">
            <DadosTableShow />
          </TabPane>
        </TabContent>
      </CardBody>
    </div>
  );
};

export default CardWithTabs;
