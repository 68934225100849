import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import {
  Button,
  Form, FormGroup, Input, Label,
  Modal, ModalHeader, ModalBody, ModalFooter, 
} from "reactstrap";

/*** PACOTE DE NOTIFICAÇÕES ***/
import { toastr } from "react-redux-toastr";

/*** PACOTE DE COMUNICAÇÃO COM API ***/
import axios from 'axios';

/****** MODAL DE EDIÇÃO DOS CARDS ******/
const ModalEditCard = (props) => {
  const[decimalNumber, setDecimalNumber] = useState()
  const { t } = useTranslation();
  console.log(props);
  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
    >
      <Form>
        <ModalHeader toggle={props.toggle}>
        {t('dashboards.editCard')}
        </ModalHeader>

        <ModalBody className="m-3">
          <div className="m-sm-4">
            {/*** CAMPO DE NOME ***/}
            <FormGroup>
              <Label> {t('dashboards.name')}</Label>
              <Input
                type="text" 
                name="name"
                id="name" 
                placeholder={props.name}
                defaultValue= {props.name}
              />
            </FormGroup>

            {/*** CAMPO DE DESCRIÇÃO ***/}
            <FormGroup>
              <Label>{t('dashboards.description')}</Label>
              <Input
                type="textarea"
                name="description"
                id="description" 
                placeholder={props.description} 
                defaultValue= {props.description}/>
            </FormGroup>
            {/*** CAMPO DE NUMERO DECIMAL ***/}
            <FormGroup>
              <Label>{t('dashboards.decimalNumber')}</Label>
              <Input type="select" value={decimalNumber} onChange={e => setDecimalNumber(e.target.value)}>
                <option value="Selecione uma opção">Selecione</option>
                <option value="7">Todas</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Input>
            </FormGroup>
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button 
            color='primary' 
            className="mr-1 mb-1" 
            onClick={props.toggle}
            outline
          >
            {t('dataTable.cancel')}
          </Button>
          {/*** BOTÃO DE ENVIAR DADOS ***/}
          <Button 
            type='submit'
            color='primary'
            className="mr-1 mb-1"
            onClick={(e) => {
              e.preventDefault();
              
              /* Salvar dados do formulário no formato JSON */
              const jsonData = JSON.stringify({
                
                id: props.cardId,
                name: document.getElementById("name").value,
                description: document.getElementById("description").value,
                decimalNumber: decimalNumber
              });
              // console.log(jsonData);
              /*** Envio de dados ***/
              axios.put('editCard/', jsonData)
                .then(result => {
                  
                  toastr.success(result.data.messageHead, result.data.messageBody);
                  props.toggle();
                  props.tableReload(true);
                })
                .catch(err => {
                  console.log(err);
                  console.log(err.response);
            
                  if (err.response.data) {
                    /* Notificação de erro de envio */
                    toastr.warning(
                      err.response.data.messageHead, err.response.data.messageBody
                    );
                  }
                });
            }}
          >
            {t('dataTable.editSave')}
          </Button>
        </ModalFooter>
      </Form> 
    </Modal>
  );
}
  
export default ModalEditCard;