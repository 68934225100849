const ptBrLoginTranslations = {
  signin: {
    welcome: "Bem vindo(a)",
    text: "Entre com sua conta para continuar",
    user: "Usuário",
    userPlaceholder: "E-mail ou nome de usuário",
    password: "Senha",
    passwordPlaceholder: "Senha",
    rememberMe: "Lembrar-me",
    forgotPassword: 'Esqueci minha senha',
    logIn: 'Entrar',
  },
  reset: {
    title: "Redefinir Senha",
    text: "Insira seu endereço de e-mail para redefinir sua senha",
    insert: "E-mail",
    reset: "Redefinir Senha",
    back: "Retornar",
  },
}

export default ptBrLoginTranslations;