import React from "react";
import { PDFViewer } from '@react-pdf/renderer';
import ReportManagerDocument from "./ReportManagerDocument";

export const ReportManagerView = ({
  data,
  chartScreenshot,
  projectName
}) => {

  return (
    <PDFViewer width={800} height={600} style={{ width: '100%'}}>
      <ReportManagerDocument 
      data={data}
      chartScreenshot={chartScreenshot} 
      projectName={projectName} 
      />
    </PDFViewer>
  );
}

export default ReportManagerView;
