const ptBrGlobalTranslations = {
    settings: {
        title: "Preferências de Usuário",
  
        // NOTE: barra lateral
        settings: "Preferências",
        account: "Conta",
        password: 'Senha',
  
        // NOTE: perfil
        profile: "Alterar informações de conta",
        avatarText: "Para melhor resolução, envie uma imagem de no mínimo 128x128 pixels no formato .jpg",
        username: "Nome de usuário",
        phone: "Número de telefone",
  
        // NOTE: senha
        changePassword: "Alterar senha",
        currPassword: "Senha atual",
        newPassword: "Nova senha",
        confirmPassword: "Confirmar nova senha",
  
        // NOTE: notificacoes
        success: "Sucesso",
        att: "Atenção",
        err: "Erro",
  
        update: "Informações atualizadas",
        exists: "Informações já existentes",
  
        minCharacters: "A nova senha deve ter no mínimo 8 caracteres",
        notEqual: "As senhas não são iguais",
  
        submit: "Salvar alterações"
    },
    userLogs: {

        login: "Login",
  
        // NOTE: Col logs
        id: "Id usuário",
        username: "Nome do usuário",
        date: "Data de ocorrencia",
        display: "Ação realizada",
  
        // NOTE:Table legend
        showing: "Mostrando",
        of: "de",
        results: "Logs",
  
        // NOTE:Search bar
        searchTitle: "Busca por logs",
        searchHolder: "Digite sua busca",
  
        // NOTE: Checkboxes
        checktitle: "Logs por função",
  
        // NOTE: Submit button
        submit: "Buscar",
  
        // NOTE: Priority Badges
        exclude: "Exclusão",
        add: "Adição",
        edit: "Edição",
  
        // NOTE: Details
        historic: "Sem historico de logs",
        cardtitle: "Tabela de logs",
        subtitle1: "Historico",
        subtitle2: "Logs",
        treePlaceholder: "Selecione a empresa"
    },
}

export default ptBrGlobalTranslations;