import React, { useState } from "react";
import {
    Button, Form, Input, Modal,
    ModalHeader, ModalBody,
    ModalFooter, Row, Col,
} from "reactstrap";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';

const ModalDeleteVar = ({ open, toggle, driver, tableReload }) => {

    const { t } = useTranslation();

    const [captcha, setCaptcha] = useState('')

    return (
        <Modal
            isOpen={open}
            toggle={toggle}
        >
            <Form
                onSubmit={e => {
                    e.preventDefault(); // evitar erro ao enviar dados com Enter

                    /* Verificação do captcha */
                    if (captcha === "DELETE") {
                        const jsonData = JSON.stringify({
                            companyId: driver.id
                        });

                        /*** Envio de dados ***/
                        axios.delete('removeCustomDriver/', { data: jsonData }) // remove driver
                            .then(res => {
                                toastr.success(res.data.messageHead, res.data.messageBody);
                            })
                            .catch(err => {
                                console.log(err);
                                console.log(err.response);
                                
                                if (err.response.data) {
                                    toastr.warning(err.response.data.messageHead, err.response.data.messageBody);
                                }
                            })
                            .finally(() => {
                                tableReload(); // atualizar visualização da tabela
                                toggle(); // fecha o modal
                            });
                    }

                    else {
                        /* Notificação de captcha incorreto */
                        toastr.warning(`${t('dataTable.error')}!`, t('dataTable.captcha'));
                        setCaptcha(''); // Limpa campo de entrada
                    }
                }}
            >
                <ModalHeader toggle={toggle}>
                    {t('dataTable.deleteHead')} {driver.name}
                </ModalHeader>

                <ModalBody className="text-center m-3">
                    <div className="m-sm-4">
                        <Row form>
                            <Col md={1}>
                            </Col>
                            <Col md={10}>
                                <h6>{t('dataTable.att')}!</h6>
                                <p className="mb-0">
                                    {t('dataTable.deleteBody')}
                                </p>
                                {/*** CAMPO DE CAPTCHA ***/}
                                <Input
                                    type="text"
                                    name="captcha"
                                    id="captcha"
                                    value={captcha}
                                    onChange={e => setCaptcha(e.target.value)}
                                />
                            </Col>
                            <Col md={1}>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>

                <ModalFooter>
                    {/*** BOTÃO DE CANCELAR *
              * (apenas fecha o modal sem enviar nada para a API) ***/}
                    <Button
                        color='primary'
                        type="button"
                        onClick={toggle}
                        outline
                    >
                        {t('dataTable.cancel')}
                    </Button>

                    {/*** BOTÃO DE DELETAR DRIVER ***/}
                    <Button
                        color="primary"
                        type="submit"
                    >
                        {t('addNewDriver.confirmDeleteDriver')}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default ModalDeleteVar;