

import { Button, Card, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import {
  SpreadsheetComponent,
  SheetsDirective, SheetDirective,
  ColumnsDirective, RangesDirective, RangeDirective, RowsDirective,
  RowDirective, CellsDirective, CellDirective, ColumnDirective,
} from '@syncfusion/ej2-react-spreadsheet';
import {
  loadCldr,
  setCulture,
} from '@syncfusion/ej2-base';
import * as currencies from 'cldr-data/main/fr-CH/currencies.json';
import * as cagregorian from 'cldr-data/main/fr-CH/ca-gregorian.json';
import * as numbers from 'cldr-data/main/fr-CH/numbers.json';
import * as timeZoneNames from 'cldr-data/main/fr-CH/timeZoneNames.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import '../../../../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../../../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../../../../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../../../../../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../../../../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../../../../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../../../../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import '../../../../../../../node_modules/@syncfusion/ej2-grids/styles/material.css';
import '../../../../../../../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import ptBR from 'date-fns/locale/pt-BR';
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import { DatePicker, Space } from 'antd';
import { igoalLogo, engmLogo } from '../../assets/CompanyLogo';
const { RangePicker } = DatePicker

loadCldr(currencies, cagregorian, numbers, timeZoneNames, numberingSystems);
setCulture('fr-CH');

const SelectDate = ({ setStart, setEnd, setLoading }) => {
  const { i18n, t } = useTranslation();

  return (
    <Space direction='vertical' size={8}>
      <RangePicker

        className="cursor-pointer"
        onChange={(dates) => {
          if (dates != null) {
          const start = moment(dates[0]).startOf('day').unix();
          const end = moment(dates[1]).endOf('day').unix();

            setStart(start);
            setEnd(end);
            setLoading(true);

          }
        }}
        dateFormat="dd/mm/yyyy"
        placeholderText={t('solar.selectMonth')}
        locale={(i18n.language === "br") ? ptBR : enGB}
      />
    </Space>
  );
}


const ModalXls = ({ open, toggle, projectName, projectId }) => {
  const spreadsheetRef = useRef(null);
  let logoImage = (window.location.host === 'engm.igoaltecnologia.com') ?
    [{
      src: engmLogo,
      height: 50,
      width: 100,
      top: 10,
      left: 10
    }]
    : [{
      src: igoalLogo,
      height: 50,
      width: 130,
      top: 10,
      left: 10
    }]

  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState();
  const [totalData, setTotalData] = useState();
  const [alarmData, setAlarmData] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [irradiationData, setIrradiationData] = useState();
  const [devicesLength, setDevicesLength] = useState();
  const [chartDataRange, setChartDataRange] = useState("");
  const [startCell, setStartCell] = useState("")
  const [rowCount, setRowCount] = useState(500);

  let spreadsheet = spreadsheetRef.current;

  const spreadsheetStyles = {
    textCenterBold: {
      backgroundColor: '#fff',
      color: '#000000',
      fontWeight: 'bold',
      textAlign: 'center',
      verticalAlign: 'middle'
    },
    rowTitle: {
      backgroundColor: '#bfbfbf',
      color: '#000000',
      fontWeight: 'bold',
      verticalAlign: 'middle'
    },
    rowValue: {
      backgroundColor: '#fff',
      color: '#000000',
      fontWeight: 'bold',
      textAlign: "left",
      verticalAlign: 'middle'
    },
    status: {
      backgroundColor: '#bfbfbf',
      color: '#000000',
      fontWeight: 'bold',
      verticalAlign: 'middle',
      border: '1px solid #000'
    },
    statusOk: {
      backgroundColor: '#a5e3ab',
      color: '#000000',
      fontWeight: 'bold',
      verticalAlign: 'middle'
    },
    statusRed: {
      backgroundColor: '#ff7d7d',
      color: '#000000',
      fontWeight: 'bold',
      verticalAlign: 'middle'
    },
    statusNoComm: {
      backgroundColor: '#ffff9b',
      color: '#000000',
      fontWeight: 'bold',
      verticalAlign: 'middle'
    },
    statusFailing: {
      backgroundColor: '#f8cbad',
      color: '#000000',
      fontWeight: 'bold',
      verticalAlign: 'middle'
    },
    textCenter: {
      textAlign: 'center',
      verticalAlign: 'middle',
    }


  };

  const chart = [{
    type: 'Line',
    range: chartDataRange,
    startCell: startCell,
    width: 735,
    height: 180,
    paddingTop: 5,
  }];


  let beginDay = new Date(startTime * 1000);
  let endDay = new Date(endTime * 1000);

  let beginDateFormat = ("0" + beginDay.getDate()).slice(-2)
    + '-' + ("0" + (beginDay.getMonth() + 1)).slice(-2)
    + '-' + beginDay.getFullYear()
    + ' ' + ("0" + beginDay.getHours()).slice(-2)
    + ':' + ("0" + beginDay.getMinutes()).slice(-2)
    + ':' + ("0" + beginDay.getSeconds()).slice(-2);

  // let endDateFormat = ("0" + endDay.getDate()).slice(-2)
  //   + '-' + ("0" + (endDay.getMonth() + 1)).slice(-2)
  //   + '-' + endDay.getFullYear()
  //   + ' ' + ("0" + endDay.getHours()).slice(-2)
  //   + ':' + ("0" + endDay.getMinutes()).slice(-2)
  //   + ':' + ("0" + endDay.getSeconds()).slice(-2);

  function saveXls() {
    // const url = `${window.location.protocol}//xlsio.igoaltecnologia.com:5037/api/Spreadsheet/save`;
    const url = `http://xlsio.igoaltecnologia.com:5037/api/Spreadsheet/save`;
      const fileName = `Report_${projectName[0].name}${beginDateFormat}`;

      spreadsheet.save({
        url: url,
        fileName: fileName,
        saveType: 'Xlsx'
      });
  };

  useEffect(() => {
    if (startTime && endTime) {
      setIsLoading(true)
      axios.get('/filesOfReport', {
        params: {
          projId: projectId,
          // monthRef: new Date(),
          startTime: startTime,
          endTime: endTime
        }
      })
        .then((res) => {
          setData(res.data.goToFront);
          setTotalData(res.data.totalData);
          setDevicesLength(res.data.goToFront.length)
   
          setIrradiationData(res.data.averageIrradiation)        
          setChartDataRange("A" + (res.data.goToFront.length + 150) + ":B" + (res.data.goToFront.length + 150 + res.data.averageIrradiation.length))
          setStartCell("A" + (res.data.goToFront.length + 150))

          setRowCount(res.data.goToFront.length + 150 + res.data.averageIrradiation.length + 1)
          
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false)

        })
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    if (isLoading) {
      const jsonData = {
        projId: projectId,
        startTime: startTime,
        endTime: endTime
      }

      axios.get('/readAlarmDataToXls', { params: jsonData })
        .then(response => {
          setAlarmData(response.data)
        })
        .catch(err => {
          console.error(err);
        })
    }
    // eslint-disable-next-line
  }, [isLoading])

  useEffect(() => {
    if (spreadsheet && data) {

      const startCell = "H7";
      const endCell = "K7";

      for (let i = 7; i <= devicesLength + 7; i++) {
        const start = startCell.replace(/(\d+)/g, i);
        const end = endCell.replace(/(\d+)/g, i);
        spreadsheet.merge(start + ":" + end);
      }

      const numberOfAlarms = devicesLength + 36 + alarmData.length;


      for (let i = devicesLength + 33; i <= numberOfAlarms; i++) {
        spreadsheet.merge(`B${i}:C${i}`);
        spreadsheet.merge(`D${i}:K${i}`);
        spreadsheet.setBorder({ border: '1px solid #000' }, `A${i}:K${i}`);
        spreadsheet.wrap(`A${i}:K${i}`);
        spreadsheet.setRowHeight(40, [`${1 + i}`])
      }

      spreadsheet.insertImage(logoImage, 'A1')
      // FORMATAÇÃO CABEÇALHO
      spreadsheet.setBorder({ border: '1px solid #000' }, 'A1:K1 ');
      spreadsheet.setBorder({ border: '1px solid #000' }, 'A2:K2 ');
      spreadsheet.setBorder({ border: '1px solid #000' }, 'A3:K3 ');
      spreadsheet.setBorder({ border: '1px solid #000' }, 'A4:K4 ');
      spreadsheet.setBorder({ border: '1px solid #000' }, 'A5:K5 ');
      // FOMATAÇÃO DADOS
      spreadsheet.setBorder({ border: '1px solid #000' }, `A${devicesLength + 8}: K${devicesLength + 13}`)
      spreadsheet.merge(`H6:K6`)
      spreadsheet.wrap('A6:K6');
      spreadsheet.merge(`A6:A7`)
      spreadsheet.merge(`B6:B7`)
      spreadsheet.merge(`C6:C7`)
      spreadsheet.merge(`D6:D7`)
      spreadsheet.merge(`E6:F6`)
      spreadsheet.merge(`G6:G7`)
      spreadsheet.merge(`H6:K7`)
      spreadsheet.cellFormat({ textAlign: "center", verticalAlign: "middle", fontSize: '10pt' }, `A6:K${devicesLength + 8}`);
      // FORMATAÇÃO TOTALS
      spreadsheet.merge(`A${devicesLength + 8}:A${devicesLength + 9}`)
      spreadsheet.merge(`B${devicesLength + 8}:B${devicesLength + 9}`)
      spreadsheet.merge(`C${devicesLength + 8}:C${devicesLength + 9}`)
      spreadsheet.merge(`D${devicesLength + 8}:D${devicesLength + 9}`)
      spreadsheet.merge(`E${devicesLength + 8}:E${devicesLength + 9}`)
      spreadsheet.merge(`F${devicesLength + 8}:F${devicesLength + 9}`)
      spreadsheet.merge(`G${devicesLength + 8}:G${devicesLength + 9}`)
      spreadsheet.cellFormat({ backgroundColor: "#c6efce" }, `H${devicesLength + 8}:H${devicesLength + 9}`);
      spreadsheet.cellFormat({ backgroundColor: "#ff7d7d" }, `I${devicesLength + 8}:I${devicesLength + 9}`);
      spreadsheet.cellFormat({ backgroundColor: "#ffff9b" }, `J${devicesLength + 8}:J${devicesLength + 9}`);
      spreadsheet.cellFormat({ backgroundColor: "#f8cbad" }, `K${devicesLength + 8}:K${devicesLength + 9}`);

      // FORMATAÇÃO 2. IRRADIAÇÃO
      spreadsheet.setBorder({ border: '1px solid #000' }, `A${devicesLength + 14}`)

      // ALTURA DAS ROWS
      spreadsheet.setRowHeight(80, ['5'])
      spreadsheet.setRowHeight(20, [`${devicesLength + 10}`])
      spreadsheet.setRowHeight(20, [`${devicesLength + 11}`])
      spreadsheet.setRowHeight(40, [`${devicesLength + 12}`])
      spreadsheet.setRowHeight(20, [`${devicesLength + 13}`])

      //FORMATAÇÃO CONDICIONAL
      spreadsheet.conditionalFormat({ type: 'ContainsText', cFColor: 'RedF', value: 'Intermitente', range: 'H6:K35' });
      spreadsheet.conditionalFormat({ type: 'ContainsText', cFColor: 'GreenFT', value: 'Operando', range: 'H6:K35' });
      spreadsheet.conditionalFormat({ type: 'ContainsText', cFColor: 'YellowFT', value: 'Sem comunicação', range: 'H6:K35' });
      spreadsheet.conditionalFormat({ type: 'ContainsText', cFColor: 'RedFT', value: 'Não Operando', range: 'H6:K35' });

      // BORDA EM TODAS CELULAS
      spreadsheet.setBorder({ border: '1px solid #000' }, `A6:K${devicesLength + 32}`)

      const alarmStartCell = `A${devicesLength + 33}`
      const alarmEndCell = `K${devicesLength + 36}`
      spreadsheet.setBorder({ border: '1px solid #000' }, `${alarmStartCell}:${alarmEndCell}`);
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [spreadsheet, data]);

  function getWeekNumber() {
    // Convert timestamp to a Date object
    let dateObject = endDay;

    // Create a new Date object for the first day of the year
    let yearStart = new Date(dateObject.getFullYear(), 0, 1);

    // Calculate the number of days between the date and the first day of the year
    let diff = (dateObject - yearStart) / (1000 * 60 * 60 * 24);

    // Calculate the week number, rounding down to the nearest integer
    let weekNumber = Math.floor(diff / 7) + 1;

    return weekNumber;
  }

  return (
    <Modal
      isOpen={open}
      size='lg'
      className='control-pane'
    >
      <div className="d-flex justify-content-around mt-3 pd-2">
        <div>{projectName[0].name}</div>
        <div> <SelectDate setStart={setStartTime} setEnd={setEndTime} setLoading={setLoading} /></div>

      </div>
      <ModalHeader></ModalHeader>
      {(loading) ?
        <div className='d-flex text-center pd-4 h3 m-1 p-4' style={{ justifyContent: "center" }}>
          <Spinner color="primary" />
        </div>
        : (data && totalData && spreadsheetStyles) ?
          <ModalBody className='control-section spreadsheet-control' style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "none" }} >
            <SpreadsheetComponent allowImage={true} allowWrap={true} ref={spreadsheetRef} scrollSettings={{ isFinite: true, enableVirtualization: false }}
            >
              <SheetsDirective>
                  <SheetDirective rowCount={rowCount} colCount={25} name='Relatorio Solar'>
                  <RowsDirective>
                    <RowDirective height={70}>
                      <CellsDirective>
                        <CellDirective image={logoImage} value='RELATÓRIO DIÁRIO DE OPERAÇÃO' colSpan={11} style={spreadsheetStyles.textCenterBold} ></CellDirective>
                      </CellsDirective> 
                    </RowDirective>
                    <RowDirective>
                      <CellsDirective>
                        <CellDirective value='Planta:' style={spreadsheetStyles.rowTitle}></CellDirective>
                        <CellDirective value={projectName[0].name} colSpan={10} style={spreadsheetStyles.rowValue}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective>
                      <CellsDirective>
                        <CellDirective value='Data Inicial: ' style={spreadsheetStyles.rowTitle} ></CellDirective>
                        <CellDirective value={beginDateFormat} colSpan={10} style={spreadsheetStyles.rowValue} ></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective>
                      <CellsDirective>
                        <CellDirective value='Semana Nº: ' style={{ fontWeight: "bold", display: "flex", justifyContent: "flex-start", textAlign: "left" }}></CellDirective>
                        <CellDirective value={getWeekNumber(endTime)} colSpan={10} style={spreadsheetStyles.rowValue}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective height={40}>
                      <CellsDirective>
                        <CellDirective value='1. Geração' colSpan={11} style={spreadsheetStyles.rowTitle}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={5}>
                      <CellsDirective>
                        <CellDirective index={5} value={'Desempenho efetiva/estimada (%)'}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 7}>
                      <CellsDirective>
                        <CellDirective value=' TOTAL:' style={spreadsheetStyles.textCenter}></CellDirective>
                        <CellDirective value={totalData.estimatedTotalPvSyst} style={spreadsheetStyles.textCenter}></CellDirective>
                        <CellDirective value={totalData.estimatedProd} style={spreadsheetStyles.textCenter}></CellDirective>
                        <CellDirective value={totalData.effectiveProd} style={spreadsheetStyles.textCenter}></CellDirective>
                        <CellDirective value={totalData.effectivePerformance} style={spreadsheetStyles.textCenter}></CellDirective>
                        <CellDirective value={totalData.performance} style={spreadsheetStyles.textCenter}></CellDirective>
                        <CellDirective value={totalData.availability} style={spreadsheetStyles.textCenter}></CellDirective>
                        <CellDirective value={totalData.totalOperatingInverter.toString()} style={spreadsheetStyles.textCenter}></CellDirective>
                        <CellDirective value={totalData.totalNotOperatingInverter.toString()} style={spreadsheetStyles.textCenter}></CellDirective>
                        <CellDirective value={totalData.totalNoCommunicationInverter.toString()} style={spreadsheetStyles.textCenter}></CellDirective>
                        <CellDirective value={totalData.totalIntermittentInverter.toString()} style={spreadsheetStyles.textCenter}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 8}>
                      <CellsDirective>
                        <CellDirective index={7} value={`${totalData.totalOperatingInverterPercent.toString()} %`} style={spreadsheetStyles.textCenter}></CellDirective>
                        <CellDirective index={8} value={`${totalData.totalNotOperatingInverterPercent.toString()} %`} style={spreadsheetStyles.textCenter}></CellDirective>
                        <CellDirective index={9} value={`${totalData.totalNoCommunicationInverterPercent.toString()} %`} style={spreadsheetStyles.textCenter}></CellDirective>
                        <CellDirective index={10} value={`${totalData.totalIntermittentInverterPercent.toString()} %`} style={spreadsheetStyles.textCenter}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 9}>
                      <CellsDirective>
                        <CellDirective value=' Legenda:' style={spreadsheetStyles.rowTitle} colSpan={11}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 10}>
                      <CellsDirective>
                        <CellDirective wrap={true} value=' Operando' style={spreadsheetStyles.statusOk}></CellDirective>
                        <CellDirective wrap={true} value=' Inversor em operação normal' style={spreadsheetStyles.rowValue} colSpan={10}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 11}>
                      <CellsDirective>
                        <CellDirective wrap={true} value=' Não Operando' style={spreadsheetStyles.statusRed}></CellDirective>
                        <CellDirective wrap={true} value=' Inversor fora de operação/desligado' style={spreadsheetStyles.rowValue} colSpan={10}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 12}>
                      <CellsDirective>
                        <CellDirective wrap={true} value=' Sem comunicação (S/C)' style={spreadsheetStyles.statusNoComm}></CellDirective>
                        <CellDirective wrap={true} value=' Inversor em operação normal porém sem comunicação' style={spreadsheetStyles.rowValue} colSpan={10}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective height={40} index={devicesLength + 13}>
                      <CellsDirective >
                        <CellDirective wrap={true} value=' Intermitente' style={spreadsheetStyles.statusFailing}></CellDirective>
                        <CellDirective wrap={true} value=' Inversor em operação com falha intermitente de comunicação (dados incompletos)' style={spreadsheetStyles.rowValue} colSpan={10}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 14} height={40}>
                      <CellsDirective>
                        <CellDirective value='2. Irradiação ' colSpan={11} style={spreadsheetStyles.rowTitle}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective height={50} index={devicesLength + 15}>
                      <CellsDirective>
                        <CellDirective wrap value='Média diária de irradiação: ' style={spreadsheetStyles.textCenterBold}></CellDirective>
                        <CellDirective value={`${totalData?.totalIrradiation} W/m²`} colSpan={10} style={spreadsheetStyles.textCenter} ></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 16}>
                      <CellsDirective>
                        <CellDirective value='Irradiação média: ' style={spreadsheetStyles.textCenterBold}></CellDirective>
                        <CellDirective value={`${totalData?.sunnyHours} kWh/m².dia (horas de sol pleno)`} colSpan={10} style={spreadsheetStyles.textCenter} ></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                      <RowDirective index={devicesLength + 19}>
                      <CellsDirective>
                        <CellDirective index={0} chart={chart} ></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 26} height={40}>
                      <CellsDirective>
                        <CellDirective value='3. Observações' colSpan={11} style={spreadsheetStyles.rowTitle}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 27}>
                      <CellsDirective>
                        <CellDirective value='' colSpan={1} style={spreadsheetStyles.rowValue}></CellDirective>
                        <CellDirective value='' colSpan={10} style={spreadsheetStyles.rowValue}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 28} >
                      <CellsDirective>
                        <CellDirective value='' colSpan={1} style={spreadsheetStyles.rowValue}></CellDirective>
                        <CellDirective value='' colSpan={10} style={spreadsheetStyles.rowValue}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 29}>
                      <CellsDirective>
                        <CellDirective value='' colSpan={1} style={spreadsheetStyles.rowValue}></CellDirective>
                        <CellDirective value='' colSpan={10} style={spreadsheetStyles.rowValue}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 30}>
                      <CellsDirective>
                        <CellDirective value='' colSpan={1} style={spreadsheetStyles.rowValue}></CellDirective>
                        <CellDirective value='' colSpan={10} style={spreadsheetStyles.rowValue}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 31}>
                      <CellsDirective>
                        <CellDirective value='' colSpan={1} style={spreadsheetStyles.rowValue}></CellDirective>
                        <CellDirective value='' colSpan={10} style={spreadsheetStyles.rowValue}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                    <RowDirective index={devicesLength + 32} height={40}>
                      <CellsDirective>
                        <CellDirective value='4. Ocorrências' colSpan={11} style={spreadsheetStyles.rowTitle}></CellDirective>
                      </CellsDirective>
                    </RowDirective>
                  </RowsDirective>
                  <RangesDirective>
                    <RangeDirective dataSource={data} startCell='A7'></RangeDirective>
                    <RangeDirective dataSource={irradiationData} startCell={startCell}></RangeDirective>
                    <RangeDirective dataSource={(alarmData.length) ? alarmData : []} startCell={`A${devicesLength + 34}` ?? 0}></RangeDirective>
                  </RangesDirective>

                  <ColumnsDirective>
                    <ColumnDirective width={120}></ColumnDirective>
                    <ColumnDirective width={65}></ColumnDirective>
                    <ColumnDirective width={80}></ColumnDirective>
                    <ColumnDirective width={80}></ColumnDirective>
                    <ColumnDirective width={70}></ColumnDirective>
                    <ColumnDirective width={65}></ColumnDirective>
                    <ColumnDirective width={95}></ColumnDirective>
                    <ColumnDirective width={40}></ColumnDirective>
                    <ColumnDirective width={40}></ColumnDirective>
                    <ColumnDirective width={40}></ColumnDirective>
                    <ColumnDirective width={40}></ColumnDirective>
                  </ColumnsDirective>
                </SheetDirective>
              </SheetsDirective>
            </SpreadsheetComponent>
            </div>
        
            {spreadsheet?.save ?
              <Button color='primary' onClick={saveXls}>
                Baixar Relatório XLS
              </Button>
              : 
              <Spinner color='primary'></Spinner>
            }

          </ModalBody>
          : <Card className='d-flex text-center pd-4 h3 m-1 p-4'>
            Selecione a data desejada
          </Card>
      }
      <ModalFooter>
        <Button color='primary' onClick={toggle}>Fechar</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalXls

