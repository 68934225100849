import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";
import { usePlantContext } from "../PlantProvider";
import { useTranslation } from 'react-i18next';
import { MultiSelect } from "../../../../../components/MultiSelect";
import {
  listDevices,
  createUcs,
} from "../PlantService";

export const ModalNewMeter = ({ open, toggle, projId, selectedDate }) => {
  const { t } = useTranslation();
  const { params, reload, setLoadingData } = usePlantContext();
  const [devices, setDevices] = useState([]);
  const [meters, setMeters] = useState([]);
  const [metersSelected, setMetersSelected] = useState([]);
  const [refMeter, setRefMeter] = useState(null);

  async function addMeters(projId, meters) {
    const response = await createUcs(projId, meters, selectedDate);
    if(response?.status !== 400) {
      toggle();
      setLoadingData(true);
      reload();
    }
  }; 

  const getMeters = async () => {
    if (params !== 404) {
      setRefMeter(params.refMeter);
      setMeters(JSON.parse(params.meters || []));
    }
  };

  const getDevices = async (meters, refMeter) => {
    const devices = await listDevices(projId);
    setDevices(devices.filter((device) => 
      device.dvcId !== Number(refMeter) && !meters.includes(device.dvcId)
    ));
  };

  useEffect(() => {
    getMeters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projId]);

  useEffect(() => {
    getDevices(meters, refMeter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meters]);

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <Form>
        <ModalHeader toggle={toggle}>
          {t('homePlant.newMeter')}
        </ModalHeader>
        <ModalBody className="text-center mx-6">
          <MultiSelect
            options={devices?.map((device) => ({
              id: device.dvcId,
              description: device.description,
            }))}
            setValue={setMetersSelected}
            values={metersSelected}
            disabled={!devices.length}
          />
        </ModalBody>
        <ModalFooter>
          {/*** BOTÃO DE CANCELAR ***/}
          <Button
            color='primary'
            onClick={toggle}
            outline
          >
            {t('homePlant.cancel')}
          </Button>
          {/*** BOTÃO DE ADICIONAR MEDIDOR ***/}
          <Button
            color="primary"
            onClick={() => {
              addMeters(projId, metersSelected);
            }}
          >
            {t('homePlant.save')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
};