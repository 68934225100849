export const PRESET = {
    MOBILE: "mobile",
    DESKTOP_HD: "desktop-hd",
    DESKTOP_4K: "desktop-4k",
    DESKTOP_HD_PLUS: "desktop-hd-plus",
    DESKTOP_FULL_HD: "desktop-full-hd"

}
export const useWindowDimensions = () => {
    const { useState, useEffect } = require("react");
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    
    useEffect(() => {
        const handleResize = () => {
        setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight
        });
        };
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    
    return {
        width: windowDimensions.width,
        height: windowDimensions.height,
        preset: windowDimensions.width <= 768 ? PRESET.MOBILE
            : windowDimensions.width <= 1366 ? PRESET.DESKTOP_HD
            : windowDimensions.width <= 1440 ? PRESET.DESKTOP_HD_PLUS
            : windowDimensions.width <= 1920 ? PRESET.DESKTOP_FULL_HD
            : PRESET.DESKTOP_4K
                
    };
};

