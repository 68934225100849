/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import Leaflet from "leaflet";
import { Link } from "react-router-dom";
import {
  Button,
  CardHeader,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { Maximize2 } from "react-feather";

const GreenIcon = Leaflet.icon({
  iconUrl: require("../../../../../../assets/iconsHomeMap/icone-verde.png"),
  iconAnchor: new Leaflet.point(15, 50),
  popupAnchor: new Leaflet.point(0, -49),
});

export const MapMeters = ({ windowObervable, isModal = false, filterMeters, projectId }) => {
  const { t } = useTranslation();

  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    (filterMeters) ?
    <>
      <CardHeader className="d-flex align-content-center justify-content-between">
        <CardTitle tag="h5" style={{ marginBottom: "0px" }}>
          {t("homePlant.mapUcs")}
        </CardTitle>
        {!isModal && (
          <Button
            className="bg-transparent border-0"
            id={"button-maximize-dashboard"}
            onClick={handleToggleModal}
          >
            <Maximize2 size={16} color="grey" />
            <UncontrolledTooltip target={"button-maximize-dashboard"}>
              {t("public.expand")}
            </UncontrolledTooltip>
          </Button>
        )}
      </CardHeader>
      {!filterMeters ? (
        <div
          className="d-flex justify-content-center align-items-center h-100"
          style={{
            height: windowObervable < 1400 ? "16rem" : "20rem",
            padding: 32,
          }}
        >
          <Spinner />
        </div>
      ) : (
        <>
        <CardBody>
          <MapContainer
            center={[
              filterMeters[0]?.latitude || -30.0177494,
              filterMeters[0]?.longitude || -51.2063027,
            ]}
            zoom={8}
            style={{ height: "200px", padding: 8, zIndex: 0 }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {filterMeters?.map((meter, index) =>
              meter.latitude && meter.longitude ? (
                <Marker
                  key={index}
                  position={[meter.latitude, meter.longitude]}
                  icon={GreenIcon}
                >
                  <Popup>
                    <strong>{meter.description}</strong>
                    <Button
                      color="primary"
                      className="m-1 py-0"
                      size="sm"
                    >
                      <Link
                        className="text-white"
                        style={{ textDecoration: "none" }}
                        to={`/project-${projectId}-default/${meter.refMeter ? 'meter' : 'uc'}/${meter.device}`}
                      >
                        {t("homePlant.view")}
                      </Link>
                    </Button>
                  </Popup>
                </Marker>
              ) : null
            )}
          </MapContainer>
        </CardBody>
        </>
      )}
      {toggleModal && (
        <Modal
          isOpen={toggleModal}
          toggle={handleToggleModal}
          size={windowObervable < 1400 ? "lg" : "xl"}
        >
          <ModalBody>
          <MapContainer
            center={[
              filterMeters[0]?.latitude || -30.0177494,
              filterMeters[0]?.longitude || -51.2063027,
            ]}
            zoom={8}
            style={{ height: "500px", padding: 8, zIndex: 0 }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {filterMeters?.map((meter, index) =>
              meter.latitude && meter.longitude ? (
                <Marker
                  key={index}
                  position={[meter.latitude, meter.longitude]}
                  icon={GreenIcon}
                >
                  <Popup>
                    <strong>{meter.description}</strong>
                    <Button
                      color="primary"
                      className="m-1 py-0"
                      size="sm"
                    >
                      <Link
                        className="text-white"
                        style={{ textDecoration: "none" }}
                        to={`/project-${projectId}-default/${meter.refMeter ? 'meter' : 'uc'}/${meter.device}`}
                      >
                        {t("homePlant.view")}
                      </Link>
                    </Button>
                  </Popup>
                </Marker>
              ) : null
            )}
          </MapContainer>
          </ModalBody>
        </Modal>
      )}
    </>
    :
    null
  );
};
