import axios from "axios";
import { toastr } from "react-redux-toastr";
import i18n from "../../../../../i18n";

export const fetchListUsers = async ({ projectId }) => {
  try {
    const { data, status } = await axios.get("/v2/user/listUsers", {
      params: { projectId, userType:3 },
    });
    return { data, status };
  } catch (error) {
    toastr.error("Erro", i18n.t('water.erro.fetchListUsers'));
    return false;
  }
};

export const fetchListUcs = async ({ projectId }) => {
  try {
    const { data, status } = await axios.get("/v2/gas/listUCs", {
      params: { projectId },
    });
    return { data, status };
  } catch (error) {
    toastr.error("Erro", i18n.t('water.erro.fetchListUcs'));
    return false;
  }
};

export const fetchGeneralData = async ({ projectId, selectedMonthRef, searchReport }) => {
  const lastDay = new Date(
    selectedMonthRef.getFullYear(),
    selectedMonthRef.getMonth() + 1,
    0
  );
  const firstDay = new Date(
    selectedMonthRef.getFullYear(),
    selectedMonthRef.getMonth(),
    1
  );

  lastDay.setHours(23, 59, 59);
  firstDay.setHours(0, 0, 0);

  try {
    const { data, status } = await axios.get(
      `/v2/gas/readGeneralData`,
      {
        params: {
          projectId,
          timestampInit: firstDay.getTime() / 1000,
          timestampEnd: lastDay.getTime() / 1000,
          selectedMonthRef,
          searchReport,
        }
      }
    );

    if (data.message) {
      toastr.info("",data.message);
    }

    return { data, status };
  } catch (e) {
    toastr.error("Erro", i18n.t('water.erro.fetchGeneralData'));
    return false;
  }
};

export const fetchEditUc = async ({ body }) => {
  try {
    const response = await axios.put("/v2/gas/editUc", body);
    return response;
  } catch (e) {
    toastr.error("Erro", i18n.t('water.erro.fetchEditUc'));
    return false;
  }
};

export const fetchDeleteUc = async ({ ucId, userId }) => {
  try {
    const response = await axios.delete("/v2/gas/deleteUc", {
      params: { id: ucId, userId },
    });
    return response;
  } catch (e) {
    toastr.error("Erro", i18n.t('water.erro.fetchDeleteUc'));
    return false;
  }
};

export const fetchEditMeter = async ({ body }) => {
  try {
    const response = await axios.put("/v2/gas/editMeter", body);
    return response;
  } catch (e) {
    toastr.error("Erro", i18n.t('water.erro.fetchEditMeter'));
    return false;
  }
};

export const fetchAddMeters = async ({ body }) => {
  try {
    const response = await axios.post("/v2/gas/addMeters", body);
    return response;
  } catch (e) {
    toastr.error("Erro", i18n.t('water.erro.fetchAddMeters'));
    return false;
  }
};

export const fetchSearchMeters = async ({ ucId }) => {
  try {
    const response = await axios.get("/v2/gas/readMeters", {
      params: {
        ucId,
      },
    });
    return response;
  } catch (e) {
    toastr.error("Erro", i18n.t('water.erro.fetchSearchMeters'));
    return false;
  }
};

export const fetchDeleteMeters = async ({ id }) => {
  try {
    const response = await axios.delete("/v2/gas/deleteMeter", {
      params: {
        id,
      },
    });
    return response;
  } catch (e) {
    toastr.error("Erro", i18n.t('water.erro.fetchDeleteMeters'));
    return false;
  }
};

export const fetchAddUc = async ({ body }) => {
  try {
    const response = await axios.post("/v2/gas/addUc", body);
    return response;
  } catch (e) {
    toastr.error("Erro", i18n.t('water.erro.fetchAddUc'));
    return false;
  }
};

export const fetchSearhDevice = async ({ projId }) => {
  try {
    const response = await axios.get("/deviceSearch", {
      params: {
        projId,
      },
    });
    return response;
  } catch (e) {
    toastr.error("Erro", i18n.t('water.erro.fetchSearhDevice'));
    return false;
  }
};

export const fetchSearchVariables = async ({ id }) => {
  try {
    const response = await axios.get("/readVariables", {
      params: {
        id,
      },
    });
    return response;
  } catch (e) {
    toastr.error("Erro", i18n.t('water.erro.fetchSearchVariables'));
    return false;
  }
};

export const fetchUpdatePlant = async ({ projectId, body }) => {
  try {
    const { data, status } = await axios.post(
      `/v2/gas/updateOrInsertPlant`,
      body
    );
    return { data, status };
  } catch (err) {
    toastr.error("Erro", i18n.t('water.erro.fetchUpdatePlant'));
    return false;
  }
};

export const fetchReadPreviousMonthWaterData = async ({ params }) => {
  try {
    const { data, status } = await axios.get(
      "/v2/gas/readPreviousMonthWaterData",
      {
        params,
      }
    );
    return { data, status };
  } catch (error) {
    toastr.error("Erro", i18n.t('water.erro.fetchReadPreviousMonthWaterData'));
    return false;
  }
};
