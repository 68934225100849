import { faPercentage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import { Card, CardBody, CardTitle, Col, Row, Spinner } from "reactstrap";

const PvSystRatio = ({ PvSystRatio, loading, loadingModal }) => {
  return (
    <Row style={{minWidth: "147px"}}>
      <Col sm="12" style={{paddingRight:"0px"}}>
        <Card className='solar-top-card'>
          <CardBody style={{padding: "15px"}}>
            <Row>
              <Col className="mt-0">
                <CardTitle tag="h5">PvSyst<br></br>Ratio</CardTitle>
              </Col>
                <Col className="col-auto" style={{paddingLeft:"0px"}}>
                  <div style={{width:"26px",height:"26px"}}>
                    <div className="avatar-title rounded-circle bg-primary-dark">
                      <FontAwesomeIcon icon={faPercentage} fixedWidth className="align-middle" />
                    </div>
                  </div>
                </Col>
              </Row>
              {(loadingModal)
              ? <div className="text-center mb-4"><Spinner /></div>
              : (loading)
                ? <div className="text-center mb-4"> <Spinner color="primary"/></div>
                : (PvSystRatio)
                  ? <h1 className="display-5 text-center" style={{fontSize:"17px", fontWeight:"500"}}>{(PvSystRatio * 100).toLocaleString('pt-br',{
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}%</h1>
                  : <p className="text-center">Não há dados</p>
            }
            </CardBody>
          </Card>
        </Col>
      </Row>
  );
}   

export default PvSystRatio;