import { t } from "i18next";
import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { fetchDeleteMeters } from "../../GasService";

const ModalDeleteMeters = ({ isDeleteOpen, modalDeleteMeter, id, toggle }) => {
  const [captcha, setCaptcha] = useState('');

  async function onSubmitForm() {
    /* Verificação do captcha */
    if (captcha === "DELETE") {
      const response = await fetchDeleteMeters({id: id});
      if (response) {
        toastr.success(response.data.messageHead, response.data.messageBody);
        toggle();
      } else {
        toastr.error("Erro", "Erro ao deletar o medidor");
      }
    }

    else {
      /* Notificação de captcha incorreto */
      toastr.warning(`${t('dataTable.error')}!`, t('dataTable.captcha'));
      setCaptcha(''); // Limpa campo de entrada
    }
  }

  return (
    <Modal
      isOpen={modalDeleteMeter}
      toggle={isDeleteOpen}
    >
      <Form
        onSubmit={e => {
          e.preventDefault(); // evitar erro ao enviar dados com Enter
          onSubmitForm();
        }}
      >
        <ModalHeader toggle={isDeleteOpen}>
          {t('dashboards.deleteDash')}
        </ModalHeader>

        <ModalBody className="text-center mx-6">
          <div className="m-4">
            <p className="mb-0">
              {t('dashboards.textDeleteDash')}
            </p>
            <Input
              type="text"
              name="captcha"
              id="captcha"
              value={captcha}
              onChange={e => setCaptcha(e.target.value)}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button
            color='primary'
            onClick={isDeleteOpen}
            outline
          >
            {t('dataTable.cancel')}
          </Button>
          {/*** BOTÃO DE DELETAR DAHBOARD ***/}
          <Button
            color="primary"
            type="submit"
          >
            {t('dashboards.delete')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalDeleteMeters