import { ConfigProvider, Table } from "antd";
import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";
import { COLOR_CHARTS } from "../../../../utils/Constants";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import Highlighter from "react-highlight-words";
import { Bar, BarChart, ReferenceLine } from "recharts";
import Status from "../solarModule/SolarView/components/Table/Status";

export const TableEnergyPlants = ({ plantList, maxHeight }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
    setSearchText("");
  }, []);

  const getColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Buscar ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Limpar
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filtrar
            </Button>
            <Button type="link" size="small" onClick={() => close()}>
              Fechar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : typeof text === "number" ? (
          text.toLocaleString()
        ) : (
          text
        ),
    }),
    [searchText, searchedColumn, handleSearch, handleReset]
  );

  const columns = useMemo(
    () => [
      {
        title: t("energy.pvEnergy"),
        dataIndex: "name",
        key: "name",
        width: 150,
        fixed: "left",
        sorter: (a, b) => a.name.localeCompare(b.name),
        ...getColumnSearchProps("name"),
        render: (text, record) => (
          <Link to={`/project-${record.PID}-energy`}>{text}</Link>
        ),
      },
      {
        title: t("energy.status"),
        dataIndex: "status",
        key: "status",
        width: 80,
        filters: [
          { text: t("devices.status.offline"), value: 0 },
          { text: t("devices.status.noComm"), value: 1 },
          { text: t("devices.status.online"), value: 2 },
        ],
        onFilter: (value, record) => record.status === value,
        sorter: (a, b) => a.status - b.status,
        render: (_, plant) => (
          <Status
            plant={{
              plantId: plant.id,
              plantStatus: plant.status,
            }}
          />
        ),
      },
      {
        title:`${t("energy.totalConsumption")} (kWh) ${t("energy.actualMonth")}`,
        dataIndex: "monthlyOffPeakConsumption",
        key: "monthlyOffPeakConsumption",
        width: 150,
        sorter: (a, b) => a.totalConsumption - b.totalConsumption,
        render: (_, info) => (
          <MiniAreaChart
            peak={info.monthlyPeakConsumption}
            offpeak={info.monthlyOffPeakConsumption}
          />
        ),
      },
      {
        title: `${t("energy.peak")} (kWh) ${t("energy.actualMonth")}`,
        dataIndex: "totalPeakConsumption",
        key: "totalPeakConsumption",
        width: 150,
        sorter: (a, b) => a.totalPeakConsumption - b.totalPeakConsumption,
        render: (_, info) => (
          <MiniAreaChart peak={info.monthlyPeakConsumption} offpeak={"[]"} />
        ),
      },
      {
        title: `${t("energy.offPeak")} (kWh) ${t("energy.actualMonth")}`,
        dataIndex: "totalOffPeakConsumption",
        key: "totalOffPeakConsumption",
        width: 150,
        sorter: (a, b) => a.totalOffPeakConsumption - b.totalOffPeakConsumption,
        render: (_, info) => (
          <MiniAreaChart peak={"[]"} offpeak={info.monthlyOffPeakConsumption} />
        ),
      },

      {
        title: `${t("energy.demand")} (kWh) ${t("energy.actualMonth")}`,
        dataIndex: "monthlyOffPeakDemand",
        key: "monthlyOffPeakDemand",
        width: 150,
        render: (_, info) => (
          <MiniAreaChart
            peak={info.monthlyPeakDemand}
            offpeak={info.monthlyOffPeakDemand}
          />
        ),
      },
      {
        title: `${t("energy.reactiveDemand")} (kWh) ${t("energy.actualMonth")}`,
        dataIndex: "monthlyOffPeakDemandRe",
        key: "monthlyOffPeakDemandRe",
        width: 150,
        render: (_, info) => (
          <MiniAreaChart
            peak={info.monthlyPeakDemandRe}
            offpeak={info.monthlyOffPeakDemandRe}
          />
        ),
      },
    ],
    [getColumnSearchProps, t]
  );

  return (
    <ConfigProvider>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <h4 className="">{`${t("solar.plants")}`}</h4>
        </CardHeader>
        <CardBody
          className="styleScroll"
          style={{
            padding: 0,
          }}
        >
          <Table
            columns={columns}
            dataSource={plantList}
            rowKey="id"
            scroll={{ y: maxHeight || 47 * 10 }}
            pagination={{ pageSize: 10 }}
            size="small"
          />
        </CardBody>
      </Card>
    </ConfigProvider>
  );
};

export const EnergyModule = ({ activeTab }) => {
  const { t } = useTranslation();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("v2/energy/readEnergy", {
        params: {
          // userId: localStorage.getItem('userId'),
          monthRef: new Date(),
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Container fluid>
        <Header>
          <HeaderTitle>{t("energy.energyVision")}</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">{t("sidebar.home")}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{t("energy.energy")}</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <div>
          {data ? (
            <div>
              <Row sm={12}>
                <Col>
                  <TableEnergyPlants plantList={data} loading={loading} />
                </Col>
              </Row>
            </div>
          ) : loading ? (
            <div className="text-center mb-4" style={{ marginTop: "300px" }}>
              {" "}
              <Spinner color="primary" />
            </div>
          ) : (
            <Card>
              <CardBody
                style={{
                  marginTop: "54px",
                  height: "94px",
                  textAlign: "center",
                }}
              >
                {t("energy.noDataPlants")}
              </CardBody>
            </Card>
          )}
        </div>
      </Container>
    </>
  );
};

function MiniAreaChart({ peak, offpeak }) {
  const arrayPeak = JSON.parse(peak) ?? [];
  const arrayOffPeak = JSON.parse(offpeak) ?? [];

  const total =
    arrayPeak.reduce((acc, curr) => acc + curr, 0) +
    arrayOffPeak.reduce((acc, curr) => acc + curr, 0);
  let chartData = [];
  if (arrayPeak.length > 0) {
    chartData = arrayPeak.map((day, index) => {
      return {
        name: index,
        peak: day,
        offpeak: arrayOffPeak[index],
      };
    });
  } else {
    chartData = arrayOffPeak.map((day, index) => {
      return {
        name: index,
        peak: arrayPeak[index],
        offpeak: day,
      };
    });
  }

  return (
    <div className="d-flex align-items-end justify-content-between">
      <BarChart data={chartData} width={100} height={30}>
        <Bar
          type="monotone"
          dataKey="offpeak"
          stroke={COLOR_CHARTS.offPeak}
          fill={COLOR_CHARTS.offPeak}
          stackId="a"
        />
        <Bar
          type="monotone"
          dataKey="peak"
          stroke={COLOR_CHARTS.peak}
          fill={COLOR_CHARTS.peak}
          stackId="a"
        />
        <ReferenceLine y={0.1} stroke="#ddd" />
      </BarChart>
      <span>{total.toLocaleString()}</span>
    </div>
  );
}
