import React from "react";
import { Card, CardBody, Spinner } from "reactstrap";
import { FaTachometerAlt, FaBolt, FaSun, FaTint, FaFire } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const icons = {
  dashboards: <FaTachometerAlt />,
  powerModuleOn: <FaBolt />,
  solarModuleOn: <FaSun />,
  waterModuleOn: <FaTint />,
  gasModuleOn: <FaFire />,
};

export const HomeFinalUser = () => {
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState({});
  const [hoveredCard, setHoveredCard] = useState(null);

  const { data, isFetching, error } = useQuery({
    queryKey: ["fetchUserReadPlantModulesByUser"],
    queryFn: async () => {
      const res = await axios.get("/v2/user/readPlantModulesByUser");
      return res.data;
    },
  });

  useEffect(() => {
    if (data) {
      setPermissions({ dashboards: 1, ...data });
    }
    if (error) {
      console.error(error);
    }
  }, [data, error]);

  const cards = [
    {
      title: "dashboards",
      content: "",
      link: "/dashboards",
      iconColor: "#007bff",
    },
    { title: "powerModuleOn", content: "", link: "/energy", iconColor: "#ffff1a" },
    { title: "solarModuleOn", content: "", link: "/solar", iconColor: "#ffbb33" },
    { title: "waterModuleOn", content: "", link: "/water", iconColor: "#3366ff" },
    { title: "gasModuleOn", content: "", link: "/gas", iconColor: "#cc9900" },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {isFetching ? (
          <Spinner />
        ) : (
          <>
            {cards
              .filter((card) => permissions[card.title])
              .map((card, index) => (
                <Link
                  to={`${card.link}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                  key={index}
                  onMouseEnter={() => setHoveredCard(index)}
                  onMouseLeave={() => setHoveredCard(null)}
                >
                  <Card
                    style={{
                      margin: "10px",
                      borderRadius: "15px",
                      width: "200px",
                      height: "200px",
                      position: "relative",
                    }}
                  >
                    <CardBody>
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "30px",
                          opacity: 0.1,
                          fontSize: "140px",
                          zIndex: 0,
                          color: hoveredCard === index ? card.iconColor : "black",
                          transition: "color 0.3s ease",
                        }}
                      >
                        {icons[card.title]}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          zIndex: 1,
                          position: "relative",
                        }}
                      >
                        <p variant="h5" component="h2">
                          {t(`sidebar.${card.title}`)}
                        </p>
                      </div>
                      <p variant="body2" component="p">
                        {card.content}
                      </p>
                    </CardBody>
                  </Card>
                </Link>
              ))}
          </>
        )}
      </div>
    </div>
  );
};
