import { BlobProvider } from '@react-pdf/renderer';
import ReportManagerDocument from "./ReportManagerDocument";

const ReportManagerBlob = ({
  data,
  chartScreenshot, 
  projectName, 
  setPdfBlob
}) => (
  <BlobProvider document={
    <ReportManagerDocument 
      data={data}
      chartScreenshot={chartScreenshot}
      projectName={projectName} 
    />
  }>
    {({blob}) => {
      if (blob) {
        setPdfBlob(blob);
      }
      return null;
    }}
  </BlobProvider>
);

export default ReportManagerBlob;
