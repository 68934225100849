import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  createRef,
} from "react";
import GridLayout from // , { Responsive, WidthProvider }
"react-grid-layout";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Card,
  Row,
  Col,
  Spinner,
  CardHeader,
  CardBody,
} from "reactstrap";
// import ResizeObserver from "rc-resize-observer";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";

// import BarAmCharts from "./cards/BarAmCharts";
import BarChart from "./cards/BarChart";
import LineChart from "./cards/LineChart";
import Statistics from "./cards/Statistics";
import StatisticsState from "./cards/StatisticsState";
import CustomState from "./cards/CustomState";
import FormulaMath from "./cards/FormulaMath";
import ThermometerChart from "./cards/Thermometer";
import { useContainerDimensions } from "./hooks/useRefDimensions";
import ResizeObserver from "rc-resize-observer";
import BatteryCard from "./cards/Battery";
import DashboardGauge from "./cards/DashboardGauge";
import WaterChart from "./cards/WaterTank";
import MqttControl from "./cards/MqttControl";

// const ResponsiveReactGridLayout = WidthProvider(Responsive);

const RenderSwitch = ({
  card,
  tableReload,
  fontSize,
  setFontSize,
  dimensions,
  ref,
}) => {
  // console.log("card", card);
  switch (card.type) {
    // case "barAmCharts":
    //   return <BarAmCharts
    //     cardId={card.id}
    //     name={card.name}
    //     description={card.description}
    //     deviceId={card.devices}
    //     variables={card.variables}
    //     period={card.period}
    //     reloadTime={card.reloadTime}
    //     tableReload={tableReload}
    //   />
    case "barChart":
      return (
        <BarChart
          // editCard={editCard}
          // setEditCard={setEditCard}
          ref={ref}
          dimensions={dimensions}
          fontSize={fontSize}
          setFontSize={setFontSize}
          card={card}
          cardId={card.id}
          name={card.name}
          description={card.description}
          deviceId={card.devices}
          variables={card.variables}
          typeVariable={card.typeVariable}
          period={card.period}
          reloadTime={card.reloadTime}
          tableReload={tableReload}
        />
      );
    case "lineChart":
      return (
        <LineChart
          // editCard={editCard}
          // setEditCard={setEditCard}
          ref={ref}
          dimensions={dimensions}
          fontSize={fontSize}
          setFontSize={setFontSize}
          card={card}
          cardId={card.id}
          name={card.name}
          description={card.description}
          deviceId={card.devices}
          variables={card.variables}
          typeVariable={card.typeVariable}
          period={card.period}
          reloadTime={card.reloadTime}
          tableReload={tableReload}
        />
      );
    case "statistics":
      return (
        <Statistics
          // editCard={editCard}
          // setEditCard={setEditCard}
          ref={ref}
          dimensions={dimensions}
          fontSize={fontSize}
          setFontSize={setFontSize}
          card={card}
          decimalNumber={card.decimalNumber}
          cardId={card.id}
          name={card.name}
          description={card.description}
          deviceId={card.devices}
          variables={card.variables}
          reloadTime={card.reloadTime}
          tableReload={tableReload}
        />
      );
    case "statisticsState":
      return (
        <StatisticsState
          // editCard={editCard}
          // setEditCard={setEditCard}
          ref={ref}
          dimensions={dimensions}
          fontSize={fontSize}
          setFontSize={setFontSize}
          card={card}
          cardId={card.id}
          name={card.name}
          description={card.description}
          deviceId={card.devices}
          variables={card.variables}
          reloadTime={card.reloadTime}
          tableReload={tableReload}
        />
      );
    case "customCard":
      return (
        <CustomState
          // editCard={editCard}
          // setEditCard={setEditCard}
          ref={ref}
          dimensions={dimensions}
          fontSize={fontSize}
          setFontSize={setFontSize}
          card={card}
          cardId={card.id}
          name={card.name}
          description={card.description}
          deviceId={card.devices}
          variables={card.variables}
          reloadTime={card.reloadTime}
          rangeStructure={JSON.parse(card.rangeStructure)}
          tableReload={tableReload}
        />
      );
    case "formulaCard":
      return (
        <FormulaMath
          // editCard={editCard}
          // setEditCard={setEditCard}
          ref={ref}
          dimensions={dimensions}
          fontSize={fontSize}
          setFontSize={setFontSize}
          card={card}
          cardId={card.id}
          name={card.name}
          description={card.description}
          deviceId={card.devices}
          variables={card.variables}
          reloadTime={card.reloadTime}
          rangeStructure={JSON.parse(card.rangeStructure)}
          tableReload={tableReload}
        />
      );
    case "thermometerCard":
      return (
        <ThermometerChart
          // editCard={editCard}
          // setEditCard={setEditCard}
          ref={ref}
          dimensions={dimensions}
          fontSize={fontSize}
          setFontSize={setFontSize}
          card={card}
          cardId={card.id}
          name={card.name}
          description={card.description}
          deviceId={card.devices}
          variables={card.variables}
          reloadTime={card.reloadTime}
          rangeStructure={JSON.parse(card.rangeStructure)}
          tableReload={tableReload}
        />
      );
    case "batteryCard":
      return (
        <BatteryCard
          // editCard={editCard}
          // setEditCard={setEditCard}
          ref={ref}
          dimensions={dimensions}
          fontSize={fontSize}
          setFontSize={setFontSize}
          card={card}
          cardId={card.id}
          name={card.name}
          description={card.description}
          deviceId={card.devices}
          variables={card.variables}
          reloadTime={card.reloadTime}
          rangeStructure={JSON.parse(card.rangeStructure)}
          tableReload={tableReload}
        />
      );
    case "gaugeCard":
      return (
        <DashboardGauge
          // editCard={editCard}
          // setEditCard={setEditCard}
          ref={ref}
          dimensions={dimensions}
          fontSize={fontSize}
          setFontSize={setFontSize}
          card={card}
          cardId={card.id}
          name={card.name}
          description={card.description}
          deviceId={card.devices}
          variables={card.variables}
          reloadTime={card.reloadTime}
          rangeStructure={JSON.parse(card.rangeStructure)}
          tableReload={tableReload}
        />
      );
    case "waterCard":
      return (
        <WaterChart
          // editCard={editCard}
          // setEditCard={setEditCard}
          ref={ref}
          dimensions={dimensions}
          fontSize={fontSize}
          setFontSize={setFontSize}
          card={card}
          cardId={card.id}
          name={card.name}
          description={card.description}
          deviceId={card.devices}
          variables={card.variables}
          reloadTime={card.reloadTime}
          rangeStructure={JSON.parse(card.rangeStructure)}
          tableReload={tableReload}
        />
      );
    case "mqttControl":
      return (
        <MqttControl
          ref={ref}
          dimensions={dimensions}
          fontSize={fontSize}
          setFontSize={setFontSize}
          card={card}
          cardId={card.id}
          name={card.name}
          description={card.description}
          deviceId={card.devices}
          variables={card.variables}
          reloadTime={card.reloadTime}
          rangeStructure={JSON.parse(card.rangeStructure)}
          tableReload={tableReload}
        />
      );
    default:
      return null;
  }
};

function saveLayout(currentLayout) {
  currentLayout.forEach((item) => {
    const jsonData = JSON.stringify({
      id: item.i,
      x: item.x,
      y: item.y,
      w: item.w,
      h: item.h,
    });

    axios
      .put("saveLayout/", jsonData)
      .then((result) => {
        // toastr.success(result.data.messageHead, result.data.messageBody);
      })
      .catch((err) => {
        // console.log(err.response);
        if (err.response.data) {
          /* Notificação de erro de envio */
          toastr.warning(
            err.response.data.messageHead,
            err.response.data.messageBody
          );
        }
      });
  });
}

/* Busca cards vinculados a dashboard selecionada */
const CardsLayout = connect((store) => ({
  sidebar: store.sidebar,
  theme: store.theme,
}))(({ sidebar, isLoading, setLoading, cards, layout, width, setWidth }) => {
  const { t } = useTranslation();
  const [fontSize, setFontSize] = useState();
  const [dimensions, setDimensions] = useState();

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(document.getElementById("ricardo").offsetWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
    // eslint-disable-next-line
  }, [width]);

  var sidebarOpen = sidebar.isOpen === true ? 250 : 0; // se está aberto ou não a sidebar
  var sideButton = sidebar.isOpen === true ? 0 : 10; // se está aberto ou não a sidebar
  let bla = Math.floor(window.innerWidth * (91 / 643)) - 108;
  let vw = window.innerWidth - sidebarOpen - bla - sideButton; // tela - side bar

  let widthResponse = 0;
  if (window.innerWidth < 480) {
    widthResponse = width;
  } else {
    widthResponse = vw;
  }

  let autoCol = 0;
  if (window.innerWidth < 1367) {
    autoCol = vw < 720 ? 1 : vw < 900 ? 4 : vw < 900 ? 8 : vw < 1367 ? 18 : 18;
  } else {
    autoCol = vw < 720 ? 1 : vw < 900 ? 4 : vw < 1200 ? 8 : vw < 1400 ? 21 : 21;
  }

  return (
    <div id="ricardo">
      {isLoading || !layout ? (
        <div className="text-center">
          <Spinner color="primary" />
        </div>
      ) : cards.length ? (
        <GridLayout
          className="layout"
          layout={layout}
          cols={autoCol}
          rowHeight={20}
          width={1920}
          draggableHandle=".otavio"
          isResizable={window.innerWidth > 700}
          compactType="vertical"
          verticalCompact={window.innerWidth > 700 ? "true" : "false"}
          // onLayoutChange={(currentLayout) => {
          //   if (window.innerWidth > 700) {
          //     saveLayout(currentLayout);
          //   }
          // }}
          onDragStop={(currentLayout) => {
            if (window.innerWidth > 700) saveLayout(currentLayout);
          }}
          onResizeStop={(currentLayout) => {
            if (window.innerWidth > 700) saveLayout(currentLayout);
          }}
          autosize="true"
          containerPadding={[0, 0]}
          
          // isDraggable={false}
          // isResizable={false}
          // resizeHandle={<></>}
        >
          {!cards.length ? (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          ) : (
            cards.map((card) => (
              <div key={card.id.toString()}>
                <ResizeObserver
                  onResize={(size) => {
                    if (size) {
                      setDimensions({ size: size, id: card.id });
                    }
                    if (size.width < 370 && size.height < 230) {
                      setFontSize(Math.floor(window.innerHeight * 0.05));
                    } else if (size.width < 550 && size.height < 530) {
                      setFontSize(Math.floor(window.innerHeight * 0.05));
                    } else if (size.width < 750 && size.height < 930) {
                      setFontSize(Math.floor(window.innerHeight * 0.06));
                    } else if (size.width < 950 && size.height < 1200) {
                      setFontSize(Math.floor(window.innerHeight * 0.06));
                    } else if (size.width < 1050 && size.height > 930) {
                      setFontSize(Math.floor(window.innerHeight * 0.07));
                    } else {
                      setFontSize(Math.floor(window.innerHeight * 0.08));
                    }
                  }}
                >
                  <Card
                    id={card.id}
                    className="text-center"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <RenderSwitch
                      style={{ height: 5000 }}
                      dimensions={dimensions}
                      // ref={ref}
                      fontSize={fontSize}
                      setFontSize={setFontSize}
                      card={card}
                      tableReload={(load) => setLoading(load)}
                    />
                  </Card>
                </ResizeObserver>
              </div>
            ))
          )}
        </GridLayout>
      ) : (
        <Card className="mt-3">
          <CardHeader></CardHeader>

          <CardBody className="text-center">
            <p> {t("dashboards.notCard")} </p>
          </CardBody>
        </Card>
      )}
    </div>
  );
  // }
});

const DashboardView = () => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const [layout, setLayout] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [dashboardTitle, setDashboardTitle] = useState("");

  function sortByProperty() {
    return function (a, b) {
      if (a["y"] > b["y"]) {
        return 1;
      } else if (a["y"] == b["y"]) {
        if (a["x"] > b["x"]) {
          return 1;
        } else {
          return -1;
        }
      } else if (a["y"] < b["y"]) return -1;
      return 0;
    };
  }

  useEffect(() => {
    if (isLoading) {
      axios
        .get("/v2/dashboard/readCard", {
          params: {
            dashId: localStorage.getItem("dashId"),
          },
        })
        .then((response) => {
          setCards(response.data.cards);
          setDashboardTitle(response.data.dashName);
          let jsonLayout = [];
          response.data.cards.forEach((element) => {
            // var minW = (element.idCard === 4) ? 2 : (element.idCard === 2) ? 6 : (element.idCard === 1) ? 6 : (element.idCard === 14) ? 3 : null;
            // var minH = (element.idCard === 4) ? 4.48 : (element.idCard === 2) ? 8.5 : (element.idCard === 1) ? 9 : (element.idCard === 14) ? 4.48 : null;

            // let element = JSON.parse(item);
            jsonLayout.push({
              i: element.id.toString(),
              x: element.x,
              y: element.y,
              w: element.w,
              h: element.h,
              minW: element.type.includes("Chart") ? 3 : 3,
              minH: element.type.includes("Chart") ? 6 : 6,
            });
          });

          setLayout(jsonLayout);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          // toastr.error('Erro!',"Erro ao buscar projetos.");  // t() da problema com o useEffect
        });
    }
  }, [isLoading]);

  // ORDENA OS CARDS PARA TELAS PEQUENAS
  useEffect(() => {
    // console.log(cards.sort(sortByProperty()));
    cards.sort(sortByProperty());
    cards.forEach((element, index) => {
      element.x = 0;
      element.y = index * 5;

      // console.log(element);
    });
  }, [layout]);

  return (
    <Container fluid>
      <Header className="mb-4">
        <Row>
          <Col>
            <HeaderTitle>
              {dashboardTitle}
              {/* {t('dashboards.myCards')} */}
            </HeaderTitle>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/home">{t("dashboards.homePage")}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to="/dashboards">{t("dashboards.myDashboards")}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {dashboardTitle}
                {/* {t('dashboards.myCards')} */}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="text-right" style={{ marginTop: "24px" }}>
            <Link to="/newCard">
              <Button color="primary" className="">
                {t("dashboards.addCard")}
              </Button>
            </Link>
          </Col>
        </Row>
      </Header>

      <CardsLayout
        isLoading={isLoading}
        setLoading={setLoading}
        cards={cards}
        setCards={setCards}
        layout={layout}
        setLayout={setLayout}
        width={width}
        setWidth={setWidth}
      />
    </Container>
  );
};

export default DashboardView;
