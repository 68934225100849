import axios from "axios";
import setHours from "date-fns/setHours";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Brush,
  CartesianGrid,
  Legend,
  Line as ReLine,
  LineChart as ReLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { toastr } from "react-redux-toastr";

import {
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

import { MoreVertical } from "react-feather";
import { Link, useHistory } from "react-router-dom";

import { format } from "date-fns";
import ModalDeleteCard from "../ModalDeleteCard";
// import { useContainerDimensions } from "../hooks/useRefDimensions";

let cardHeightContainer = window.innerWidth > 700 ? "70%" : "70%";
let cardWidthContainer = window.innerWidth > 700 ? "90%" : "90%";

const CardDropdown = (props) => {
  const { t } = useTranslation();
  const [openDeleteCard, setOpenDeleteCard] = useState(false);
  const [editCard, setEditCard] = useState(false);

  function toggleDeleteCard() {
    setOpenDeleteCard(!openDeleteCard);
  }

  return (
    <UncontrolledDropdown className="d-inline-block" disabled={props.preview}>
      <DropdownToggle tag="a">
        <MoreVertical className="ml-1" style={{ width: "15px" }} />
      </DropdownToggle>

      <DropdownMenu right>
        <DropdownItem onClick={() => toggleDeleteCard()}>
          {t("dashboards.delete")}
        </DropdownItem>

        {openDeleteCard ? (
          <ModalDeleteCard
            open={openDeleteCard}
            toggle={toggleDeleteCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
          />
        ) : null}

        <Link
          onClick={() => setEditCard(props.cardId)}
          to={{
            pathname: `/newCard/LineChart/${props.cardId}`,
            state: {
              cardProps: props.card,
              editCard: editCard,
            },
          }}
        >
          <DropdownItem>{t("dashboards.edit")}</DropdownItem>
        </Link>
        <DropdownItem onClick={() => props.setDataLoading(true)}>
          {t("dashboards.refresh")}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const LineChart = memo(({
  cardId,
  name,
  description,
  deviceId,
  variables,
  typeVariable,
  period,
  tableReload,
  preview,
  reloadTime,
  card,
  dimensions,
}) => {
  let history = useHistory();

  const { t } = useTranslation();
  const [dataLoading, setDataLoading] = useState(!preview);
  const [lastComm, setLastComm] = useState();

  // USANDO MESMA CHAMADA DO DATA EXPLORER
  const [endDate, setEndDate] = useState(new Date()); // data e hora atual
  const [startDate, setStartDate] = useState(
    setHours(endDate, endDate.getHours() - card.period / 60 / 60)
  ); // 1h antes da hora atual
  const [devicesAndVariables, setDevicesAndVariables] = useState([]);
  const [aggregation, setAggregation] = useState("Nenhum"); // periodo selecionado para Agregação
  const [resolution, setResolution] = useState(""); // periodo selecionado para Resolução
  const [DadosChart, setDadosChart] = useState();
  // const [dados, setDados] = useState([]);
  const [isLoadingData, setLoadingData] = useState(false);
  const [isUpdatingChart, setIsUpdatingChart] = useState(false);

  const [cardHeight, setCardHeight] = useState();
  const [cardWidth, setCardWidth] = useState();

  let parsedVariables = variables.length ? JSON?.parse(variables) : "";
  let varName = variables ? JSON.parse(variables)?.varName : "";

  const lastCommDate = new Date(lastComm * 1000);

  const componentRef = useRef();
  // const { width } = useContainerDimensions(componentRef)

  useEffect(() => {
    if (dimensions) {
      if (dimensions?.id === card?.id) {
        let var1 =
          dimensions.size.height < 240 && card.id === dimensions.id
            ? 240
            : dimensions.size.height > 241 &&
              card.id === dimensions.id &&
              dimensions.size.height <= 310
            ? 310
            : dimensions.size.height > 311 &&
              card.id == dimensions.id &&
              dimensions.size.height < 370
            ? 370
            : dimensions.size.height > 371 &&
              card.id == dimensions.id &&
              dimensions.size.height < 420
            ? 420
            : dimensions.size.height > 421 &&
              card.id == dimensions.id &&
              dimensions.size.height < 500
            ? 500
            : dimensions.size.height > 501 && card.id == dimensions.id
            ? 501
            : null;
        let var2 =
          dimensions.size.width < 240 && card.id === dimensions.id
            ? 240
            : dimensions.size.width > 241 &&
              card.id === dimensions.id &&
              dimensions.size.width <= 310
            ? 310
            : dimensions.size.width > 311 &&
              card.id == dimensions.id &&
              dimensions.size.width < 350
            ? 350
            : dimensions.size.width > 371 &&
              card.id == dimensions.id &&
              dimensions.size.width < 420
            ? 420
            : dimensions.size.width > 421 &&
              card.id == dimensions.id &&
              dimensions.size.width < 500
            ? 500
            : dimensions.size.width > 501 && card.id == dimensions.id
            ? 501
            : null;
        setCardHeight(var1);
        setCardWidth(var2);
      }
    }
  }, [dimensions, card?.id]);

  useEffect(() => {
    let selectedValue = [];
    if (deviceId || isLoadingData) {
      axios
        .get("/readVariables", {
          params: {
            id: deviceId,
          },
        })
        .then((response) => {
          if (parsedVariables.length) {
            parsedVariables.forEach((item, index) => {
              var filtered = selectedValue.filter(
                (a) => a.deviceId === item.dvcId
              );
              if (filtered.length === 0) {
                selectedValue.push({
                  deviceId: item.dvcId,
                  variables: [item.tag],
                  title: [item.varName],
                });
              } else {
                filtered[0].variables.push(item.tag);
                filtered[0].title.push(item.varName);
              }
            });
          }
          setDevicesAndVariables(selectedValue);
          setLoadingData(false);
          setDataLoading(false);
        })
        .catch((err) => {
          console.log(err);
          toastr.error("Erro!", "Erro ao buscar Unidade.");
        });
    }
  }, [deviceId, dataLoading]);

  const updateLastComm = useCallback(() => {
    if (!deviceId) return;
    axios
      .get("/readDeviceInfo", {
        params: {
          idDevice: deviceId,
        },
      })
      .then((response) => {
        setLastComm(response.data.lastComm);
      })
      .catch((err) => {
        console.log(err);
        toastr.error("Erro!", "Erro ao buscar Modelo.");
      });
  }, [deviceId]);

  const updateChart = useCallback(() => {
    if (!devicesAndVariables || !devicesAndVariables?.length) return;
    setIsUpdatingChart(true);
    axios
      .get("/v2/dashboard/readChart", {
        params: {
          startDate: startDate ? Math.floor(startDate.getTime() / 1000) : "",
          endDate: endDate ? Math.floor(endDate.getTime() / 1000) : "",
          devicesAndVariables: devicesAndVariables,
          aggregation: aggregation,
          resolution: resolution,
          typeVariable,
        },
      })
      .then((response) => {
        response.data.dateIni = startDate;
        response.data.dateFin = endDate;
        setDadosChart(response?.data);
      })
      .catch((err) => {
        console.log(err);
        toastr.error("Erro!", "Erro ao buscar devices."); // t() da problema com o useEffect
      })
      .finally(() => {
        setLoadingData(false);
        setDataLoading(false);
        setIsUpdatingChart(false);
      });
  }, [
    aggregation,
    devicesAndVariables,
    endDate,
    resolution,
    startDate,
    typeVariable,
  ]);

  useEffect(() => {
    if (
      (devicesAndVariables &&
        devicesAndVariables?.length > 0 &&
        typeVariable) ||
      dataLoading
    ) {
      updateLastComm();

      if (
        devicesAndVariables &&
        devicesAndVariables?.length > 0 &&
        typeVariable
      ) {
        updateChart();
      }
    }
    if (reloadTime) {
      const intervalSearch = setInterval(() => {
        if (deviceId) {
          updateLastComm();

          if (
            devicesAndVariables &&
            devicesAndVariables?.length > 0 &&
            typeVariable
          ) {
            updateChart();
          }

          if (history.location.pathname !== "/dashboardView") {
            clearInterval(intervalSearch);
          }
        }
      }, reloadTime);
    }
  }, [devicesAndVariables, typeVariable]);

  return (
    <div ref={componentRef} className="flex-fill w-200 mb-3">
      <CardHeader className="pb-0">
        <div className="d-flex justify-content-between">
          <div className="overflow-hidden mr-5" id={"name" + cardId}>
            <CardTitle
              style={{ whiteSpace: "nowrap" }}
              tag="h5"
              className="mr-3 overflow-hidden text-left my-1 h5"
            >
              {name}
            </CardTitle>
            {name ? (
              <UncontrolledTooltip placement="top" target={"name" + cardId}>
                {name}
              </UncontrolledTooltip>
            ) : null}
            <CardSubtitle
              style={{ whiteSpace: "nowrap" }}
              className="overflow-hidden text-muted text-left"
            >
              {description}
            </CardSubtitle>
            {description ? (
              <UncontrolledTooltip placement="bottom" target={"name" + cardId}>
                {description}
              </UncontrolledTooltip>
            ) : null}
          </div>
          <div className="d-flex justify-content-end card-actions">
            <div className="cursor-pointer mp-0 ml-2 otavio">
              <Icon.Move style={{ width: "15px", zIndex: 15 }} />
            </div>

            <CardDropdown
              card={card}
              cardId={cardId}
              tableReload={tableReload}
              name={name}
              description={description}
              preview={preview}
              setDataLoading={setDataLoading}
            />
          </div>
        </div>
      </CardHeader>
  

      {DadosChart ? (
        <Line DadosChart={DadosChart} width={cardWidth} height={cardHeight} />
      ) : isUpdatingChart ? (
        <CardBody className="text-center">
          <Icon.Loader
            className="icon-xxl"
            style={{
              animation: "loadingCircle 1s infinite linear",
            }}
          />
        </CardBody>
      ) : (
        <div>
          <CardBody className="text-center"> {t("solar.noData")} </CardBody>
        </div>
      )}

      {cardHeight > 300 ? (
        <CardFooter
          style={{
            whiteSpace: "nowrap",
            backgroundColor: "transparent",
            bottom: "0",
            position: `${!preview ? "absolute" : null}`,
            padding: "5px 1.25rem",
          }}
          className="mr-5 overflow-hidden text-left my-1 h5 text-left mt-1"
        >
          <h6 className="text-left">{preview}</h6>
          <h6 className="card-subtitle text-muted">
            {parsedVariables.length ? parsedVariables[0]?.varName : varName}
          </h6>
          <h6 className="card-subtitle text-muted mt-1">
            {lastComm
              ? lastCommDate.getDate().toString().padStart(2, "0") +
                "/" +
                (lastCommDate.getMonth() + 1).toString().padStart(2, "0") +
                "/" +
                lastCommDate.getFullYear().toString().padStart(2, "0") +
                " " +
                lastCommDate.getHours().toString().padStart(2, "0") +
                ":" +
                lastCommDate.getMinutes().toString().padStart(2, "0") +
                ":" +
                lastCommDate.getSeconds().toString().padStart(2, "0")
              : t("dashboards.noComm")}
          </h6>
        </CardFooter>
      ) : null}
    </div>
  );
});

const formatTime = (time) => {
  const date = new Date(time);
  return format(date, "Pp");
};

const Line = memo(({ width, height, DadosChart }) => {
  // Verificação se os dados estão presentes e formatados corretamente
  if (
    !DadosChart ||
    !DadosChart.formattedData ||
    DadosChart.formattedData.length === 0
  ) {
    return <p>Dados insuficientes para exibir o gráfico.</p>;
  }
  const formattedData = DadosChart.formattedData;
  const colors = DadosChart.colors;
  const units = DadosChart.units;

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ReLineChart
        width={width}
        height={height}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: height > 300 ? 150 : 120,
        }}
        data={formattedData}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tickFormatter={(timeStr) => {
            const time = new Date(timeStr);
            return format(time, "dd/MM HH:mm");
          }}
        />
        <YAxis />
        <Tooltip
          formatter={(value, name, props) => {
            const unit = units[name] || "";
            return [`${value} ${unit}`, name];
          }}
          labelFormatter={(time) => formatTime(time)}
          wrapperStyle={{
            zIndex: 1000,
          }}
        />
        {height > 350 ? (
          <Legend
            wrapperStyle={{
              padding: "0 2rem",
              height: "100%",
              overflow: "auto",
              maxHeight: "50px",
            }}
          />
        ) : null}

        {Object.keys(formattedData[0]).map((key, index) => {
          if (key === "name") {
            return null;
          }
          return (
            <ReLine
              key={key}
              type="monotone"
              dataKey={key}
              stroke={colors[index]}
              dot={false}
              isAnimationActive={false}
            />
          );
        })}

        <Brush
          dataKey="name"
          height={10}
          stroke="#8884d8"
          tickFormatter={(timeStr) => {
            const time = new Date(timeStr);
            return format(time, "dd/MM HH:mm");
          }}
          width={width - 50}
        />
      </ReLineChart>
    </ResponsiveContainer>
  );
});

export default connect((store) => ({
  theme: store.theme,
}))(LineChart);
