import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import moment from "moment";
import { useTranslation } from "react-i18next";

const prepareChartData = (data) => {
  return data.map((item) => ({
    mes: moment(item.mes, "MM/YY").format("MMM/YY"),
    countDemoAs: item["countDemoAs"],
    countContractAs: item["countContractAs"],
  }));
};

export const DemosContractsChart = ({ data }) => {
  const { t } = useTranslation();
  const chartData = prepareChartData(data);
  return (
    <LineChart
      width={800}
      height={400}
      data={chartData}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
    >
      <XAxis dataKey="mes" />
      <YAxis />
      <Tooltip />
      <Legend />
      <CartesianGrid strokeDasharray="3 3" />
      <Line
        type="monotone"
        dataKey="countDemoAs"
        name="Demo"
        stroke="#8884d8"
      />
      <Line
        type="monotone"
        dataKey="countContractAs"
        name={t("noc.form.contract")}
        stroke="#82ca9d"
      />
    </LineChart>
  );
};
