import React, { useEffect, useState } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import {
  CardBody,
  CardTitle,
  Row,
  Col,
  CardHeader,
  CardFooter,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardSubtitle,
  UncontrolledTooltip
} from "reactstrap";
import * as Icon from "react-feather";
import { MoreVertical, RefreshCw } from "react-feather";
import * as math from "mathjs";

import ModalEditCard from "../ModalEditCard";
import ModalDeleteCard from "../ModalDeleteCard";

function CardDropdown(props) {

  const [openEditCard, setOpenEditCard] = useState(false);
  const [openDeleteCard, setOpenDeleteCard] = useState(false);
  function toggleEditCard() {
    setOpenEditCard(!openEditCard);
  }
  function toggleDeleteCard() {
    setOpenDeleteCard(!openDeleteCard);
  }

  return (

    <UncontrolledDropdown className="d-inline-block" disabled={(props.preview)}>
      <DropdownToggle tag="a">
        <MoreVertical />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          onClick={() => {
            toggleDeleteCard();
          }}
        >
          Deletar
        </DropdownItem>
        {(openDeleteCard)
          ? <ModalDeleteCard
            open={openDeleteCard}
            toggle={toggleDeleteCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
          />
          : null
        }

        <DropdownItem
          onClick={() => {
            toggleEditCard();
          }}
        >
          Editar
        </DropdownItem>
        {(openEditCard)
          ? <ModalEditCard
            open={openEditCard}
            toggle={toggleEditCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
            name={props.name}
            description={props.description}
          />
          : null
        }

      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const StatisticsPreview = ({
  decimalNumber,
  deviceId,
  cardId,
  cardType,
  cardProps,
  name,
  description,
  devices,
  variables,
  tableReload,
  preview,
  reloadTime,
  cardDataToEdit,
  retrieveDataFromPreviewCard,
  formula,
  defaultVarUnit
}) => {
  const [deviceData, setdeviceData] = useState([]);
  const [dataLoading, setdataLoading] = useState(true);
  const [model, setModel] = useState(true);
  const [deviceVars, setDeviceVars] = useState(true);
  
  const varName = (variables) ? variables.varName : ""
  const varTag = (variables) ? JSON.parse(variables).tag : ""
  const varUnit = (variables) ? JSON.parse(variables).unit : ""
  console.log(deviceData);
  //Envia o id do device e retorna os valores
  useEffect(() => {
    if (deviceId || cardProps?.id) {
      axios.get('/readDataLast', {
        params: {
          idDevice: (deviceId) ? deviceId : cardProps?.devices
        }
      })
        .then(response => {
          console.log("response", response);
          setdeviceData(response.data)
          retrieveDataFromPreviewCard(Number(deviceData[varTag]));
          setdataLoading(false)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar variável.");
        })
    }

  }, [deviceId, cardProps?.devices, cardProps?.id, dataLoading])

  //Envia o id do device e retorna o modelo 
  useEffect(() => {

    if (deviceId || cardProps?.id) {
      axios.get('/readDeviceInfo', {
        params: {
          idDevice: (deviceId) ? deviceId : cardProps?.devices
        }
      })
        .then(response => {
          setModel(response.data.description)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar Modelo.");
        })
    }

  }, [deviceId, cardProps?.devices, cardProps?.id])

  // console.log("deviceData", deviceVars);
  //Envia o id do device e retorna a unidade da variável 
  useEffect(() => {

    if (deviceId || cardProps?.id) {
      axios.get('/readVariables', {
        params: {
          id: (deviceId) ? deviceId : cardProps?.devices
        }
      })
        .then(response => {
          setDeviceVars(response.data)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar Unidade.");
        })
    }

  }, [deviceId, cardProps?.devices, cardProps?.id])


  const formatValue = (value) => {
    console.log("value", value);
    if (!formula || !formula.includes("x")) {
      return parseFloat(value.toFixed(decimalNumber));
        }

    try {
      const scope = { x: value };
      const evaluatedValue = math.evaluate(formula, scope);
      return parseFloat(evaluatedValue.toFixed(decimalNumber));
    } catch (error) {
      console.error("Formula error:", error);
      return parseFloat(value.toFixed(decimalNumber))
    }
  };

  return (
    (cardProps?.id && cardProps.type === "statistics") ?
      <div>
        <div id="parent">
          <CardHeader>
            <div className="card-actions float-right">
              <Row className="card-actions float-right">
                <Col className="mp-0">
                  <span className="cursor-pointer mp-0">
                    <RefreshCw className="mp-0" onClick={() => {
                      setdataLoading(true);
                      // console.log(devices)
                    }} />
                  </span>{""}
                </Col>
                <Col className="pl-0">
                  {/* alterei o easter egg "VERIFICAR SE VAI TER EFEITO EM OUTRAS PARTES DA APLICAÇÃO" */}
                  <div className="box no-cursor cursor-pointer mp-0">
                    <Icon.Move style={{ width: "15px" }} />
                  </div>
                </Col>
                <CardDropdown
                  cardId={cardId}
                  tableReload={tableReload}
                  name={name}
                  description={description}
                  preview={(preview)}
                />
              </Row>
            </div>
            <CardTitle id={"name" + cardId} style={{ whiteSpace: "nowrap" }} tag="h5" className="mr-3 overflow-hidden text-left my-1 h5">{name}</CardTitle>
            {(cardProps.name) ?
              <UncontrolledTooltip
                style={{ backgroundColor: "#202020", opacity: 1, fontSize: ".8rem", color: "#F1F1F1", border: "1px solid #000", padding: "1rem" }}
                // className="tooltip-style"
                placement="top"
                target={"name" + cardId}
              >
                {name}
              </UncontrolledTooltip>
              : null}
            <CardSubtitle style={{ whiteSpace: "nowrap" }} className="overflow-hidden text-muted text-left">
              {description}
            </CardSubtitle>
            {(description) ?
              <UncontrolledTooltip
                style={{ backgroundColor: "#202020", opacity: 1, fontSize: ".8rem", color: "#F1F1F1", border: "1px solid #000", padding: "1rem" }}
                // className="tooltip-style"
                placement="bottom"
                target={"name" + cardId}
              >
                {description}
              </UncontrolledTooltip>
              : null}
          </CardHeader>
          <CardBody id={"desc" + cardId} className="text-center">
            {(deviceData) ?
              //printa a variável e a unidade selecionada no novo card.
              <h2 className="display-3 text-center">
                {(isNaN(deviceData[varTag])) ?
                  deviceData[varTag]
                  : formatValue(Number(deviceData[varTag]))
                } {defaultVarUnit ?? varUnit}
              </h2>
              : <p className="mt-3">Não há dados</p>}
          </CardBody>
          <CardFooter className="text-left" style={{ bottom: "0", position: `${(!preview) ? "absolute" : null}` }}>
            <h6 className="text-left">{model}</h6>
            <h6 className="card-subtitle text-muted">{varName}</h6>
          </CardFooter>
        </div>
      </div> :

      (cardType === "statistics") ?
        <div>
          <div id="parent">
            <CardHeader>
              <div className="card-actions float-right">
                <Row className="card-actions float-right">
                  <Col className="mp-0">
                    <span className="cursor-pointer mp-0">
                      <RefreshCw className="mp-0" onClick={() => {
                        setdataLoading(true);
                      }} />
                    </span>{""}
                  </Col>
                  <Col className="pl-0">
                    {/* alterei o easter egg "VERIFICAR SE VAI TER EFEITO EM OUTRAS PARTES DA APLICAÇÃO" */}
                    <div className="box no-cursor cursor-pointer mp-0">
                      <Icon.Move style={{ width: "15px" }} />
                    </div>
                  </Col>
                  <CardDropdown
                    cardId={cardId}
                    tableReload={tableReload}
                    name={name}
                    description={description}
                    preview={(preview)}
                  />
                </Row>
              </div>
              <div id={"name" + cardId} >
                <CardTitle
                  style={{ whiteSpace: "nowrap" }}
                  tag="h5"
                  className="mr-3 overflow-hidden text-left my-1 h5"
                >
                  {name}
                </CardTitle>
                {(name) ?
                  <UncontrolledTooltip
                    placement="top"
                    target={"name" + cardId}
                  >
                    {name}
                  </UncontrolledTooltip>
                  : null}
                <CardSubtitle style={{ whiteSpace: "nowrap" }} className="overflow-hidden text-muted text-left">
                  {description}
                </CardSubtitle>
                {(description) ?
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"name" + cardId}
                  >
                    {description}
                  </UncontrolledTooltip>
                  : null}
              </div>
            </CardHeader>
            <CardBody id={"desc" + cardId} className="text-center">
              {(deviceVars.length && deviceData)
                //printa a variável e a unidade selecionada no novo card.
                ?
                <h2 className="display-3 text-center">
                  {/* {console.log("", typeof (deviceData[varTag] === "number"))}
                  {console.log(deviceData[varTag])} */}
                  {(isNaN(deviceData[varTag])) ?
                    deviceData[varTag]
                    : formatValue(Number(deviceData[varTag]))
                  } {defaultVarUnit ?? varUnit}
                  {/* {typeof (deviceData[varTag]) === "number" ?
                    deviceData[varTag]?.toFixed(Number(decimalNumber))
                    : (deviceData[varTag]) === false ? "Desligado"
                      : (deviceData[varTag]) === true ? "Ligado"
                        : deviceData[varTag]} {varUnit} */}
                </h2>
                : <p className="mt-3">Não há dados</p>}
            </CardBody>
            <CardFooter className="text-left" style={{ bottom: "0", position: `${(!preview) ? "absolute" : null}` }}>
              <h6 className="text-left">{model}</h6>
              <h6 className="card-subtitle text-muted">{varName}</h6>
            </CardFooter>
          </div>
        </div>
        : null

  );
}

export default StatisticsPreview;
