import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarBattery } from '@fortawesome/free-solid-svg-icons';

const CurrentFaseB = ({ data, state, allData, CurrentFaseB }) => {

  return (
    <div className="w-100">
      <Row >
        <Col sm="12" style={{paddingRight:"0px"}}>
          <Card style={{minWidth: "113px", maxHeight:"88px", height: "88px", border: state, borderStyle:"solid", background:"#b4d2ff"}}>
            <CardBody style={{padding: "15px"}}>
              <Row>
                <Col className="mt-0">
                  <CardTitle tag="h5">Fase B</CardTitle>
                </Col>
                <Col className="col-auto" style={{paddingLeft:"0px"}}>
                  <div>
                    <FontAwesomeIcon 
                        icon={faCarBattery} 
                        style={{fontSize:"16px"}}
                        />
                  </div>
                </Col>
              </Row>
              {(allData)
                ? <h1 className="display-5 text-center" style={{fontSize:"17px", fontWeight:"500"}}>{CurrentFaseB}</h1>
                : <div className="text-center mb-4"><Spinner /></div>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}   

export default CurrentFaseB;