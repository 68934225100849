import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb, BreadcrumbItem,
  Card, CardBody, CardHeader, CardText, CardTitle,
  Container,
  Spinner,
  // UncontrolledTooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faPen,
  faSort,
  faSortUp,
  faSortDown,
  // faTrash
} from "@fortawesome/free-solid-svg-icons";
import { 
  ChevronDown,
  ChevronRight,
  ChevronsDown,
  ChevronsRight
} from "react-feather";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";

import ModalGatewayStatus from "./ModalGateway";

/** Nome de referência do gateway */
const GWName = ({name, gateway}) => {
  const [openModal, setOpenModal] = useState(false);

  // ativa/desativa modal
  function toggle() {
    setOpenModal(!openModal);
  };

  return <div className="cursor-pointer" onClick={()=>setOpenModal(true)}>
    <p className="m-0 p-0">{name}</p>
    {(openModal)
      ? <ModalGatewayStatus 
          open={openModal}
          toggle={toggle}
          gateway={gateway}
        />
      : null
    }
  </div>
};

/** Tabela de Periféricos */
const PeriphericalsTable = ({gatewayId}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [ppList, setPPList] = useState([]);

  useEffect(()=>{
    axios.get('/gwDevicesSearch', { params: {
      gatewayId: gatewayId
    }})
    .then(res => {
      // console.log(res);
      setPPList(res.data);
      setLoading(false);
    })
    .catch(err => {
      setLoading(false);
      console.log(err);
      console.log(err.response);
    });
  }, [gatewayId]);

  let ppCols = [
    { // ID (oculto)
      dataField: "id",
      text: `${t('dataTable.id')}`,
      sort: true,
      hidden: true
    },
    { // nome de referência
      dataField: "description",
      text: t('gateways.description'),
      sort: true,
    },
    { // fabricante/modelo
      dataField: "model",
      text: t('gateways.model'),
      sort: true,
      formatter: (cell, row) => row.manufacturer + ' ' + cell
    },
    { // última comunicação
      dataField: "lastComm",
      text: t('gateways.lastComm'),
      sort: true,
      formatter: cell => {
        if (cell) {
          var cellDate = new Date(cell*1000);

          return cellDate.getDate().toString().padStart(2, '0') + '/' +
          (cellDate.getMonth()+1).toString().padStart(2, '0') + '/' +
          cellDate.getFullYear().toString().padStart(2, '0') + ' ' +
          cellDate.getHours().toString().padStart(2, '0') + ':' +
          cellDate.getMinutes().toString().padStart(2, '0') + ':' +
          cellDate.getSeconds().toString().padStart(2, '0')
        } else return '-'
      }
    },
    { // tempo de atualização
      dataField: "commInterval",
      text: t('gateways.commInterval'),
      // headerStyle: { width: "13%" },
      sort: true,
      formatter: cell => (cell) ? cell + ' s' : '-'
    },
    // { // ícones de ação
    //   dataField: "actions",
    //   text: t('gateways.actions')`,
    //   formatter: (cell, row, rowIndex, extra) => (
    //     <GWIcons gateway={row} />
    //   )
    // }
  ];

  /* Ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) => (!order)
      ? <FontAwesomeIcon
        className={'fa-xs ml-1'}
        icon={faSort}
      />
      : (order === 'asc')
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faSortUp}
        />
        : <FontAwesomeIcon
            className={'fa-xs ml-1'}
            icon={faSortDown}
          />
  };

  return (ppList.length)
    ? <>
        <BootstrapTable 
          bootstrap4
          keyField="id"
          data={ppList}
          columns={ppCols}
          condensed
          bordered={false}
          sort={sortIcon}
        />
      </>
    : <div className="text-center m-3">
        {(loading)
          ? <Spinner color="primary" />
          : <CardText>{t('gateways.noPeripherical')}.</CardText>
        }
      </div>
};

/** Tabela de Gateways */
const GatewayTable = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [gatewayList, setGatewayList] = useState([]);

  useEffect(()=>{
    if (loading) {
      axios.get('/allGWSearch')
      .then(res => {
        // console.log(res);
        setGatewayList(res.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        console.log(err.response);
      });
    }
  }, [loading]);

  let gwCols = [
    { // ID (oculto)
      dataField: "id",
      text: `${t('dataTable.id')}`,
      sort: true,
      hidden: true
    },
    { // nome de referência
      dataField: "description",
      text: t('gateways.description'),
      sort: true,
      formatter: (cell, row) => (
        <GWName name={cell} gateway={row} />
      )
    },
    { // fabricante/modelo
      dataField: "model",
      text: t('gateways.model'),
      sort: true,
      formatter: (cell, row) => row.manufacturer + ' ' + cell
    },
    { // serial number
      dataField: "serialNumber",
      text: t('gateways.serialNumber'),
      sort: true,
      formatter: cell => cell ? cell : '-'
    },
    { // projeto
      dataField: "projectName",
      text: t('gateways.projectName'),
      sort: true,
    },
    { // empresa
      dataField: "companyName",
      text: t('gateways.companyName'),
      sort: true,
    },
    { // última comunicação
      dataField: "lastComm",
      text: t('gateways.lastComm'),
      sort: true,
      formatter: cell => {
        if (cell) {
          var cellDate = new Date(cell*1000);

          return cellDate.getDate().toString().padStart(2, '0') + '/' +
          (cellDate.getMonth()+1).toString().padStart(2, '0') + '/' +
          cellDate.getFullYear().toString().padStart(2, '0') + ' ' +
          cellDate.getHours().toString().padStart(2, '0') + ':' +
          cellDate.getMinutes().toString().padStart(2, '0') + ':' +
          cellDate.getSeconds().toString().padStart(2, '0')
        } else return '-'
      }
    },
    { // tempo de atualização
      dataField: "commInterval",
      text: t('gateways.commInterval'),
      // headerStyle: { width: "13%" },
      sort: true,
      formatter: cell => (cell) ? cell + ' s' : '-'
    },
    // { // ícones de ação
    //   dataField: "actions",
    //   text: t('gateways.actions'),
    //   formatter: (cell, row, rowIndex, extra) => (
    //     <GWIcons gateway={row} />
    //   )
    // }
  ];

  /* Ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) => (!order)
      ? <FontAwesomeIcon
        className={'fa-xs ml-1'}
        icon={faSort}
      />
      : (order === 'asc')
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faSortUp}
        />
        : <FontAwesomeIcon
            className={'fa-xs ml-1'}
            icon={faSortDown}
          />
  };
  
  /*** Configurações de expansão de linha ***/
  const expandRow = {
    renderer: row => <PeriphericalsTable gatewayId={row.id} />,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => isAnyExpands 
      ? <ChevronsDown className="cursor-pointer" width={16} height={16} />
      : <ChevronsRight className="cursor-pointer" width={16} height={16} />,
    expandColumnRenderer: ({ expanded }) => expanded 
      ? <ChevronDown className="cursor-pointer" width={16} height={16} />
      : <ChevronRight className="cursor-pointer" width={16} height={16} />
  };

  return (gatewayList.length)
    ? <>
        <h6 className="card-subtitle text-muted mb-3">
          {t('gateways.expandText1')} <ChevronRight width={16} height={16} /> {t('gateways.expandText2')}. {t('gateways.clickText')}.
        </h6>

        <BootstrapTable 
          bootstrap4
          keyField="id"
          data={gatewayList}
          columns={gwCols}
          condensed
          bordered={false}
          sort={sortIcon}
          expandRow={expandRow}
        />
      </>
    // ? <p>tabela</p>
    : <div className="text-center m-3">
        {(loading)
          ? <Spinner color="primary" />
          : <CardText>{t('gateways.noGateway')}.</CardText>
        }
      </div>
};

/****** PÁGINA ******/
const Gateways = () => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Header className="mb-0 pb-0">
        <HeaderTitle>{t('gateways.title')}</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{t('sidebar.home')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/">{t('sidebar.registers')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t('sidebar.gateways')}</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card>
        <CardHeader className="mb-0 pb-0">
          <CardTitle tag="h5" className="mb-0 pb-0">
            {t('gateways.title')}
          </CardTitle>
        </CardHeader>

        <CardBody>
          <GatewayTable />
        </CardBody>
      </Card>
    </Container>
  );
};

export default Gateways;
