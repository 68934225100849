import React, { useState } from "react";
import * as Icon from "react-feather";
import { MoreVertical } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

import ModalDeleteCard from "../ModalDeleteCard";
import ModalEditCard from "../ModalEditCard";

function CardDropdown(props) {
  const [openEditCard, setOpenEditCard] = useState(false);
  const [openDeleteCard, setOpenDeleteCard] = useState(false);
  function toggleEditCard() {
    setOpenEditCard(!openEditCard);
  }
  function toggleDeleteCard() {
    setOpenDeleteCard(!openDeleteCard);
  }

  return (
    <UncontrolledDropdown className="d-inline-block" disabled={props.preview}>
      <DropdownToggle tag="a">
        <MoreVertical />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          onClick={() => {
            toggleDeleteCard();
          }}
        >
          Deletar
        </DropdownItem>
        {openDeleteCard ? (
          <ModalDeleteCard
            open={openDeleteCard}
            toggle={toggleDeleteCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
          />
        ) : null}

        <DropdownItem
          onClick={() => {
            toggleEditCard();
          }}
        >
          Editar
        </DropdownItem>
        {openEditCard ? (
          <ModalEditCard
            open={openEditCard}
            toggle={toggleEditCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
            name={props.name}
            description={props.description}
          />
        ) : null}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

const MqttControlPreview = ({
  cardId,
  name,
  description,
  variables,
  preview,
}) => {
  const varName = variables ? variables.varName : "";
  const { t } = useTranslation();

  return (
    <div>
      <div id="parent">
        <CardHeader>
          <div className="card-actions float-right">
            <Row className="card-actions float-right">
              <Col className="pl-0">
                <div className="box no-cursor cursor-pointer mp-0">
                  <Icon.Move style={{ width: "15px" }} />
                </div>
              </Col>
            </Row>
          </div>
          <div id={"name" + cardId}>
            <CardTitle
              style={{ whiteSpace: "nowrap" }}
              tag="h5"
              className="mr-3 overflow-hidden text-left my-1 h5"
            >
              {name}
            </CardTitle>
            {name ? (
              <UncontrolledTooltip placement="top" target={"name" + cardId}>
                {name}
              </UncontrolledTooltip>
            ) : null}
            <CardSubtitle
              style={{ whiteSpace: "nowrap" }}
              className="overflow-hidden text-muted text-left"
            >
              {description}
            </CardSubtitle>
            {description ? (
              <UncontrolledTooltip placement="bottom" target={"name" + cardId}>
                {description}
              </UncontrolledTooltip>
            ) : null}
          </div>
        </CardHeader>
        <CardBody id={"desc" + cardId} className="text-center">
          <Button
            style={{
              background: "transparent",
              border: "2px solid #153d77",
              borderRadius: "8px",
              color: "#153d77",
            }}
          >
            {t("dashboards.exec")}
          </Button>
        </CardBody>
        <CardFooter
          className="text-left"
          style={{ bottom: "0", position: `${!preview ? "absolute" : null}` }}
        >
          <h6 className="card-subtitle text-muted">{varName}</h6>
        </CardFooter>
      </div>
    </div>
  );
};

export default MqttControlPreview;
