import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const SolarContext = createContext();

export const SolarProvider = ({ children, projectId }) => {
  const { t } = useTranslation();

  //NOTE: Solar Data Provider
  const [selectDate, setSelectDate] = useState(new Date());
  const [loadingModal, setLoadingModal] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [lastComm, setLastComm] = useState();
  const [processedAt, setProcessedAt] = useState();
  const [solari, setSolari] = useState(false);
  const [trackerRef, setTrackerRef] = useState(false);

  const [updateParams, setUpdateParams] = useState(false);

  //NOTE: Report manager provider
  const [reportManager, setReportManager] = useState([]);
  // NOTE: Converte captura de gráfico do relatório gerencial para front-end
  const [reportManagerBarChart, setReportManagerBarChart] = useState(null);
  const [
    reportManagerBarChartScreenshoot,
    setReportManagerBarChartScreenshoot,
  ] = useState(null);

  // NOTE: Remove captura de gráfico do relatório gerencial do back-end
  // const [chartReportManagerScreenshoot, setChartReportManagerScreenshoot] = useState(null);

  //NOTE: ReportMonth provider
  const [projectName, setProjectName] = useState("");
  const [monthlyDataUsage, setMonthlyDataUsage] = useState([]);
  const [monthlyReportUsage, setMonthlyReportUsage] = useState([]);

  //NOTE: Solar settings provider
  const [arvore, setArvore] = useState([]);
  const [valores, setValores] = useState([]);
  const [priceState, setPriceState] = useState(0);
  const [energyDevices, setEnergyDevices] = useState([]);
  const [powerState, setPowerState] = useState(0);
  const [area, setArea] = useState(0);
  const [efficiency, setEfficiency] = useState(0);
  const [customer, setCustomer] = useState(null);
  const [location, setLocation] = useState(null);
  const [productCluster, setProductCluster] = useState(null);
  const [positioning, setPositioning] = useState(null);
  const [peakPower, setPeakPower] = useState(null);
  const [contractStart, setContractStart] = useState(null);
  const [contractEnd, setContractEnd] = useState(null);
  const [imagePlant, setImagePlant] = useState(null);
  const [params, setParams] = useState(null);

  const [pvSyst, setPvSyst] = useState([]);
  const [areaConfigArray, setAreaConfigArray] = useState([]);
  const [readPvSyst, setReadPvSyst] = useState([]);
  const [readAreaConfigArray, setReadAreaConfigArray] = useState([]);
  const [pvSystRatio, SetPvSystRatio] = useState([]);

  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = (state) => setToggleModal(state);

  

  const reloadSolarData = async () => {
    axios
      .get("/readSolarData", {
        params: {
          projId: projectId,
          monthRef: !selectDate ? new Date() : selectDate,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readReportMonth = async () => {
    axios
      .get("/reportMonthsSolarData", {
        params: {
          projId: projectId,
          monthRef: !selectDate ? new Date() : selectDate,
        },
      })
      .then((response) => {
        setProjectName(response.data.result);
        setMonthlyDataUsage(response.data.result1);
        setMonthlyReportUsage(response.data.projectedEnergy);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readReportManager = async () => {
    axios
      .get("/v2/solar/readReportManager", {
        params: {
          projId: projectId,
          monthRef: moment(!selectDate ? new Date() : selectDate).format(
            "YYYY-MM-DD"
          ),
          locales: t(`locales`),
        },
      })
      .then((response) => {
        setReportManager(response.data.reportManager);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // NOTE: Converte captura de gráfico do relatório gerencial para front-end
  const readReportManagerBarChart = async () => {
    setReportManagerBarChart(null);
    axios
      .get("/v2/solar/readReportManagerBarChart", {
        params: {
          projId: projectId,
          monthRef: moment(!selectDate ? new Date() : selectDate).format(
            "YYYY-MM-DD"
          ),
          locales: t(`locales`),
        },
      })
      .then((response) => {
        setReportManagerBarChart(response.data.reportManagerBarChart);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // NOTE: Remove captura de gráfico do relatório gerencial do back-end
  // const readReportManagerScreenshot = async () => {
  //   setChartReportManagerScreenshoot(null);
  //   axios
  //     .get("/v2/solar/readReportManagerScreenshot", {
  //       params: {
  //         projId: projectId,
  //         monthRef: moment(!selectDate ? new Date() : selectDate).format(
  //           "YYYY-MM-DD"
  //         ),
  //         locales: t(`locales`),
  //       },
  //     })
  //     .then((response) => {
  //       setChartReportManagerScreenshoot(response.data.reportManager);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const readParams = async () => {
    axios
      .get("/v2/solar/readParamsPlant", {
        params: {
          projId: projectId,
        },
      })
      .then((response) => {
        setPriceState(response.data.params.priceState);
        setEnergyDevices(response.data.params.energyDevices);
        setPowerState(response.data.params.powerState);
        setArea(response.data.params.area);
        setEfficiency(response.data.params.efficiency);
        setCustomer(response.data.params.customer);
        setLocation(response.data.params.location);
        setProductCluster(response.data.params.productCluster);
        setPositioning(response.data.params.positioning);
        setPeakPower(response.data.params.peakPower);
        setContractStart(response.data.params.contractStart);
        setContractEnd(response.data.params.contractEnd);
        setImagePlant(response.data.params.imagePlant);
        setParams(true);
      })
      .catch((err) => {
        setParams(false);
        console.log(err);
        toastr.error("Erro!", "Erro ao buscar configurações!"); // t() da problema com o useEffect
      });
  };

  const readSolarParams = async () => {
    axios
      .get("/readSolarParams", {
        params: {
          projId: projectId,
        },
      })
      .then((response) => {
        setPvSyst(response.data.parsedPvSyst);
        setAreaConfigArray(response.data.parsedInverter);

        setReadPvSyst(response.data.parsedPvSyst);
        setReadAreaConfigArray(response.data.parsedInverter);
        SetPvSystRatio(response.data.parsedPvSystRatio);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const readEnergyDevices = async () => {
    axios
      .get("/readEnergyDevices", {
        params: {
          projId: projectId,
        },
      })
      .then((response) => {
        let arrayAux = [];

        response.data.forEach((element, index) => {
          arrayAux.push({
            title: element.deviceName,
            key: element.id,
            value: element.id,
            isLeaf: true,
            checkable: true,
            id: element.id,
            // children: []
          });
        });
        setArvore(arrayAux);
        let arrayAux2 = energyDevices;
        arrayAux2?.forEach((element, index) => {
          arrayAux2[index] = parseInt(element);
        });
        setValores(arrayAux2);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createSolarData = async (data) => {
    setLoading(true);
    data.pvSyst = pvSyst;

    axios
      .post("/v2/solar/createPlant", data)
      .then((response) => {
        reloadSolarData();
        readReportManager();
        // NOTE: Converte captura de gráfico do relatório gerencial para front-end
        readReportManagerBarChart();
        // NOTE: Remove captura de gráfico do relatório gerencial do back-end
        // readReportManagerScreenshot();
        setUpdateParams(!updateParams);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status === 400) {
          error.response.data.errors.forEach((error) => {
            toastr.warning(
              t(`solar.errors.${error.path}.title`),
              t(`solar.errors.${error.path}.msg`)
            );
          });
        }
      })
      .finally(() => {
        setPriceState(priceState);
        setPowerState(powerState);
        let EnergyString = "";
        valores.forEach((element, index) => {
          EnergyString += element + ",";
        });
        EnergyString = EnergyString.slice(0, -1);
        setEnergyDevices(EnergyString);
        readSolarParams();
        readParams();
        setLoading(false);
      });
  };

  const updateSolarData = async (data) => {
    setLoading(true);
    data.pvSyst = pvSyst;
    data.pvSystRatio = pvSystRatio;

    axios
      .put("/v2/solar/updatePlant", data)
      .then((response) => {
        reloadSolarData();
        readReportManager();
        // NOTE: Converte captura de gráfico do relatório gerencial para front-end
        readReportManagerBarChart();
        // NOTE: Remove captura de gráfico do relatório gerencial do back-end
        // readReportManagerScreenshot();
        setUpdateParams(!updateParams);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status === 400) {
          error.response.data.errors.forEach((error) => {
            toastr.warning(
              t(`solar.errors.${error.path}.title`),
              t(`solar.errors.${error.path}.msg`)
            );
          });
        }
      })
      .finally(() => {
        setPriceState(priceState);
        setPowerState(powerState);
        let EnergyString = "";
        valores.forEach((element, index) => {
          EnergyString += element + ",";
        });
        EnergyString = EnergyString.slice(0, -1);
        setEnergyDevices(EnergyString);
        readSolarParams();
        readParams();
        setLoading(false);
      });
  };

  useEffect(() => {
    const cancelRequest = axios.CancelToken.source();
    const readSolarData = async () => {
      try {
        axios
          .get("/readSolarData", {
            params: {
              projId: projectId,
              monthRef: !selectDate ? new Date() : selectDate,
            },
            cancelToken: cancelRequest.token,
          })
          .then((response) => {
            setData(response.data);
            setProcessedAt(
              response.data.general ? response.data?.general.processedAt : null
            );
            setLastComm(
              response.data.general ? response.data?.general.lastComm : null
            );
            setSolari(
              response.data.general ? response.data.general.hasSolarimeter : false
            );
            setTrackerRef(
              response.data.general ? response.data.general.hasTracker : false
            );
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            if(err?.message === 'Request canceled') return;
            toastr.warning("Erro!", "Erro ao buscar cards solar.");
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };
    readSolarData();
    // readReportManager();
    readReportMonth();
    readParams();
    readEnergyDevices();
    // NOTE: Converte captura de gráfico do relatório gerencial para front-end
    // readReportManagerBarChart();
    // NOTE: Remove captura de gráfico do relatório gerencial para front-end
    // readReportManagerScreenshot();
    readSolarParams();
    
    return () => {
      cancelRequest.cancel('Request canceled');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDate]);

  return (
    <SolarContext.Provider
      value={{
        projectId,
        projectName,
        monthlyDataUsage,
        monthlyReportUsage,
        loading,
        setLoading,
        loadingModal,
        data,
        solari,
        trackerRef,
        reportManager,
        lastComm,
        processedAt,
        // NOTE: Converte captura de gráfico do relatório gerencial para front-end
        reportManagerBarChart,
        reportManagerBarChartScreenshoot,
        setReportManagerBarChartScreenshoot,
        // NOTE: Remove captura de gráfico do relatório gerencial do back-end
        // chartReportManagerScreenshoot,
        arvore,
        valores,
        priceState,
        energyDevices,
        powerState,
        area,
        efficiency,
        customer,
        location,
        productCluster,
        positioning,
        peakPower,
        contractStart,
        contractEnd,
        imagePlant,
        setImagePlant,
        setValores,
        setCustomer,
        setArea,
        setEfficiency,
        setPeakPower,
        setLocation,
        setPositioning,
        setPowerState,
        setPriceState,
        setProductCluster,
        setContractStart,
        setContractEnd,
        selectDate,
        setSelectDate,
        setLoadingModal,
        reloadSolarData,
        createSolarData,
        updateSolarData,
        updateParams,
        setUpdateParams,
        areaConfigArray,
        setAreaConfigArray,
        pvSyst,
        setPvSyst,
        readAreaConfigArray,
        setReadAreaConfigArray,
        readPvSyst,
        setReadPvSyst,
        params,
        toggleModal,
        handleToggleModal,
        monthRef: !selectDate ? new Date() : selectDate,
        pvSystRatio,
        SetPvSystRatio,
      }}
    >
      {children}
    </SolarContext.Provider>
  );
};

export function useAPI() {
  const context = useContext(SolarContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
