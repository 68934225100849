import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { updateModulesProject } from "../ProjectDataService";

export const ModalEditPlantModule = ({
  open,
  toggle,
  proj,
  setLoadProjects,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, errors, watch, setValue, formState } = useForm();
  const { isSubmitting } = formState;

  const onSubmit = async (data) => {
    const userId = localStorage.getItem("userId");
    //NOTE: Convertendo valor true para 1 e false para 0
    Object.keys(data).forEach((key) => {
      data[key] = data[key] ? 1 : 0;
    });

    const request = await updateModulesProject({
      projId: proj.id,
      userId,
      modules: data,
    });

    if (request.status === 200) {
      setLoadProjects(true);
      toggle();
    }
  };

  const modules = [
    "solarModuleOn",
    "powerModuleOn",
    "waterModuleOn",
    "gasModuleOn",
    "climateModuleOn",
    "nobreakModuleOn",
  ];

  useEffect(() => {
    if (proj) {
      modules.forEach((module) => {
        setValue(module, proj[module] === 1 ? true : false);
      });
    }
  }, [proj]);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {t("projTable.editDevice")} {proj?.description}
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row>
            {modules.map((module, index) => {
              const value = watch(module);
              return (
                <Col xs={12} md={4}>
                  <FormGroup key={index}>
                    {t(`projTable.${module}`)}
                    <Label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        background: "#ccc",
                        width: "fit-content",
                        boxSizing: "border-box",
                        padding: "3px 3px",
                        borderRadius: "15px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() => {
                          setValue(module, true);
                        }}
                        style={{
                          display: "block",
                          textAlign: "center",
                          width: "50px",
                          backgroundColor: value ? "white" : "", 
                          borderRadius: "10px",
                          transition: "all 0.5s ease", 
                          fontWeight: value ? "bold" : "normal", 
                        }}
                      >
                        ON
                      </span>
                      <span
                        onClick={() => {
                          setValue(module, false);
                        }}
                        style={{
                          display: "block",
                          textAlign: "center",
                          width: "50px",
                          backgroundColor: !value ? "white" : "",
                          borderRadius: "10px",
                          transition: "all 0.5s ease",
                          fontWeight: !value ? "bold" : "normal",
                        }}
                      >
                        OFF
                      </span>
                    </Label>
                    {errors?.[module] && <span>{t("projTable.required")}</span>}
                  </FormGroup>
                </Col>
              );
            })}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            {t("projTable.cancel")}
          </Button>
          <Button color="primary" type="submit">
            {isSubmitting ? t("projTable.sending") : t("projTable.saveDevice")}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
