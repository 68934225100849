import React, { useState } from "react";
import {
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";
import { usePlantContext } from "../PlantProvider";
import { resetValidateFields } from "../../../../../../src/utils/functions";

import { useTranslation } from 'react-i18next';
import {
  deleteUc
} from "../PlantService";


export const ModalDeleteMeter = ({ open, toggle, projId, meter }) => {

  const { reload, setLoadingData } = usePlantContext();
  const [captchaInput, setCaptchaInput] = useState('');

  const [validateFields, setValidateFields] = useState([{
    path: null, 
    msg: null
  }]);

  async function deleteMeter(meterId, captchaInput) {
    setValidateFields([]);
    const reponse = await deleteUc(setValidateFields, projId, meterId, captchaInput);
    if(reponse?.status !== 400) {
      toggle();
      setLoadingData(true);
      reload();
    }
  }; 

  const { t } = useTranslation();

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <Form>
        <ModalHeader>
          {t('homePlant.deleteMeter')} {meter.description}
        </ModalHeader>
        <ModalBody className="text-center mx-6">
          <div className="m-sm-4">
            <h6>{t('homePlant.attention')}!</h6>
            <p className="mb-0">
              {t('homePlant.captchaLabel')}:
            </p>
            {/*** CAMPO DE CAPTCHA ***/}
            <Input
              type="text"
              id="captcha"
              value={captchaInput}
              onChange={e => setCaptchaInput(e.target.value)}
              onFocus={(e) => {
                resetValidateFields("captchaInput", validateFields, setValidateFields);
              }}
            />
            {validateFields.map((item, index) => {
              return item.path === "captchaInput" ? (
                <small key={index} className="text-danger d-block">
                  {item.msg}
                </small>  
              ) : null;
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          {/*** BOTÃO DE CANCELAR ***/}
          <Button
            color='primary'
            onClick={toggle}
            outline
          >
            {t('homePlant.cancel')}
          </Button>
          {/*** BOTÃO DE DELETAR MEDIDOR ***/}
          <Button
            color="primary"
            onClick={() => {
                deleteMeter(meter.id, captchaInput);
            }}
          >
            {t('homePlant.deleteMeter')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
};