const ptBrAlarmTranslations = {
    alarms: {
        alarms: "Alarmes",
        noAlarms: "Sem alarmes",
        activeAlarms: "alarmes ativos",
        activeAlarm: "alarme ativo",
        titleConfig: "Parâmetros",
        titleView: "Histórico",
        titleRegisters: "Parametrização de Alarmes",
        editarParametros: "Editar Parâmetros",
        headRegisters: "Registro de Parâmetros de Alarmes",
  
        // NOTE: tabela
        alarm: "Alarme",
        id: "Nº",
        description: "Descrição",
        deviceName: "Dispositivo",
        varName: "Variável",
        type: "Tipo",
        priority: "Prioridade",
        contact: "Contato",
        lowLimit: "Lim. Inf.",
        highLimit: "Lim. Sup.",
        messageLowOn: "Msg. ON Inf.",
        messageHighOn: "Msg. ON Sup.",
        messageLowOff: "Msg. OFF Inf.",
        messageHighOff: "Msg. OFF Sup.",
        actions: "Ações",
        value: "Valor",
        dateOcc: "Ocorrido em",
        dateOff: "Desligado em",
        onMsg: "Mensagem de ativação",
        offMsg: "Mensagem de desativação",
        search: "Busca",
        messages: "Mensagens",

        // type
        binary: "Binário",
        regular: "Regular",
        notDefined: "Não definido",
    
        // NOTE: priority
  
        high: "Alta",
        medium: "Média",
        low: "Baixa",
  
        select: "Selecione",
        phone: "Chamada de Voz",
  
        // NOTE: selecionados
        selectedAlarms: "alarmes selecionados",
        selectedAlarm: "alarme selecionado",
  
        // NOTE: tooltips
        addAlarm: "Adicionar alarme",
        editAlarm: "Editar alarme",
        deleteAlarm: "Remover alarme",
        linkUsers: "Vincular alarme a usuários",
        searchAlarms: "Buscar alarme",
        deleteAlarms: "Remover alarmes selecionados",
        turnOffAlarm: "Desligar alarme",
        alarmTurnedOff: "Alarme desligado",
  
        // NOTE: mensagens de erro/nada encontrado
        noAlarmConfig: "Não há alarmes configurados",
        noAlarmHistory: "Não há ocorrências de alarmes",
  
        error: "Erro",
        wrongCaptcha: "Captcha incorreto",
  
        // NOTE: mensagens de sucesso
        success: "Sucesso",
        alarmUpdated: "Alarme atualizado",
        alarmRemoved: "Alarme removido",
  
        // NOTE: formulario
        project: "Projeto",
        projectSelect: "Selecione um projeto",
        device: "Dispositivo",
        deviceSelect: "Selecione um dispositivo",
        variable: "Variável",
        variableSelect: "Selecione um variável",
  
        highPriority: "Alta prioridade",
        mediumPriority: "Média prioridade",
        lowPriority: "Baixa prioridade",
  
        alarmType: "Tipo de alarme",
        analogic: "Analógico",
        digital: "Digital",
  
        activeState: "Estado de ativação",
        on: "Ligado",
        off: "Desligado",
  
        lowLim: "Limite Inferior",
        highLim: "Limite Superior",
  
        msgOn: "Mensagem de alarme ativado",
        msgOnLo: "Mensagem de limite inferior atingido",
        msgOnHi: "Mensagens de limite superior atingido",
        msgOff: "Mensagem de alarme desativado",
        msgOffLo: "Mensagens de limite inferior normalizado",
        msgOffHi: "Mensagens de limite superior normalizado",
  
        // NOTE: notificações
        att: "Atenção",
        invalid: "Existem campos obrigatórios a serem preenchidos",
        reference: "O alarme deve ter um nome de referência",
        noLimitLow: "Não foi configurado limite inferior",
        noLimitHigh: "Não foi configurado limite superior",
        noLimit: "Deve ser definido pelo menos um limite",
        lowerHigh: "O limite inferior deve ser menor que o superior",
        noMessages: "Devem ser definidas mensagens para cada limite definido",
  
        // NOTE: modais de delete
        captchaText: `Essa ação é irreversível. Caso deseje continuar, por favor, digite "DELETE" no campo abaixo`,
        captchaOff: `Caso deseje desligar o alarme, por favor, digite "ALARMOFF" no campo abaixo`,
  
        // NOTE: modal de usuarios
        name: "Nome",
        companyName: "Empresa",
        changeUsers: "Alterar usuários vinculados ao alarme",
  
        // NOTE: botões
        cancel: "Cancelar",
        saveContinue: "Salvar e Continuar",
        saveAlarm: "Salvar Alarme",
        saveChanges: "Salvar Alterações",
        saveDelete: "Remover Alarme",
        saveDeleteSelected: "Remover Alarmes",
        saveTurnOffAlarm: "Desligar Alarme",
  
        // NOTE: paginação
        showing: "Mostrando",
        of: "de",
        results: "alarmes",
  
        // NOTE: busca avançada
        searchField: "Busca por texto",
        searchProject: "Busca por projeto ou dispositivo",
        searchStart: "Ocorrido a partir de",
        searchEnd: "Ocorrido até",
        searchActive: "Estado de ativação",
        active: "Ativos",
        inactive: "Encerrados",
  
        textPlaceholder: "Digite algo",
        treePlaceholder: "Selecione projetos e/ou dispositivos",
        datepickerPlaceholder: "Selecione uma data e hora",
      },
}

export default ptBrAlarmTranslations;