
import ModalReportManagerViewer from "./ModalReportManagerViewer";
import { faPlus} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useAPI } from '../../SolarProvider';

const ModalReportManagerEvent = ({
  open,
  toggle,
}) => {

  const {
    reportManager, 
    projectName,
    // NOTE: Converte captura de gráfico do relatório gerencial para front-end 
    reportManagerBarChartScreenshoot,
    // NOTE: Remove captura de gráfico do relatório gerencial do back-end 
    // chartReportManager 
  } = useAPI();
  const [description, setDescription] = useState("");
  const [action, setAction] = useState("");
  const [event, setEvent] = useState([]);
  const [openReportManagerPDF, setOpenReportManagerPDF] = useState(false);

  const { t } = useTranslation();

  const columns = () => {
    return [
      {
        dataField: 'description',
        text: t("reportManager.eventDescription"),
        classes: 'col-lg-6'
      },
      {
        dataField: 'action',
        text: t("reportManager.eventAction"),
        classes: 'col-lg-6'
      },
    ];
  };

  function addNewEvent() {
    const newEvent = { description, action };

    if(description === "" || action === "") {
      toastr.warning(t("reportManager.notEmptyEvent"));
    } else {
      setEvent([...event, newEvent]);
    }
  }

  function showReportManagerPDF() {
    reportManager.events = event;
    setOpenReportManagerPDF(!openReportManagerPDF);
  }

  return (
    <>
      <Modal
        isOpen={open}
        toggle={toggle}
        scrollable
        size='lg'
      >
        <ModalHeader>
          {t("reportManager.modalTitle")}
        </ModalHeader>
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            paddingInline: "1rem",
            marginInline: 16
          }}>
          <Row>
            <Col className="d-flex align-items-center justify-content-center mb-2">
              <Label>{t("reportManager.labelEvent")}</Label>
            </Col>
          </Row>
          <Row style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: '10px 0',
          }}>
            <Col sm="12" md="5" lg="5">
                <Input
                  style={{ marginRight: "1rem", resize: 'vertical' }}
                  placeholder={t("reportManager.eventDescription")}
                  onChange={e => setDescription(e.target.value)}
                  type="textarea"
                />
            </Col>
            <Col sm="12" md="5" lg="5">
              <Input
                style={{ marginRight: "1rem", resize: 'vertical' }}
                placeholder={t("reportManager.eventAction")}
                onChange={e => setAction(e.target.value)}
                type="textarea"
              />
            </Col>
            <Col sm="12" md="2" lg="1">
              <Button color="primary" onClick={() => addNewEvent()}>
                <FontAwesomeIcon fontSize={2} fixedWidth icon={faPlus} />
              </Button>
            </Col>
          </Row>

          <hr style={{ color: "#000", height: 16 }} />

          <BootstrapTable
            bootstrap4
            keyField="id"
            data={event}
            columns={columns()}
            condensed
            bordered={false}
            striped={true}
          />

        {(openReportManagerPDF)
          ? <ModalReportManagerViewer
            open={openReportManagerPDF}
            toggle={showReportManagerPDF}
            reportManager={reportManager}
            // NOTE: Converte captura de gráfico do relatório gerencial para front-end 
            chartReportManager={reportManagerBarChartScreenshoot}
            // NOTE: Remove captura de gráfico do relatório gerencial do back-end 
            // chartReportManager={chartReportManager}
            projectName={projectName[0].name}
          />
          : null
        }

        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            className="mb-1"
            onClick={showReportManagerPDF}
          >
            {t("reportManager.showPDF")}
          </Button>

          <Button
            color='primary'
            className="mb-1"
            onClick={toggle}
            outline

          >
            {t("solarReport.closeButton")}
          </Button>
        </ModalFooter>
  
      </Modal>
    </>
  )
}
export default ModalReportManagerEvent;