import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Header from "../../../../../../components/Header";
import HeaderTitle from "../../../../../../components/HeaderTitle";
import HeaderSubtitle from "../../../../../../components/HeaderSubtitle";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import SelectMonth from "../../RefMeter/SubMenu/components/SelectMonth";
import moment from 'moment';

export const NoParamsRefMeter = ({projId, projName, monthRef}) => {

  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  async function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <div className="d-flex flex-row justify-content-between">
    <div>
      <Header className="d-flex flex-column">
        <HeaderTitle>{projName}</HeaderTitle>
        <HeaderSubtitle>
          {t("noParamsRefMeter.msg")}
        </HeaderSubtitle>
      </Header>  

      <div role="button" onClick={() => {
        toggleModal();
      }}
      className="d-flex justify-content-start align-items-center gap-3" style={{ color: "white", zIndex: 100 }}
>      <FontAwesomeIcon
        icon={faPlusCircle}
        color='white'
        size="2x"
        />
        <div className="p-2 text-lg">
          {t("noParamsRefMeter.register")}
        </div>
      </div>
     </div>

      <div className="d-flex flex-row">

        <div className="px-2 flex-column inputs form-control">
          REF {'  '}  
          {monthRef
          ? moment(monthRef).format('MM/YYYY')
          : moment().format('MM/YYYY')}
        </div>
        <div className="px-2">
          <SelectMonth />
        </div>

      </div>

    </div>
  )
}