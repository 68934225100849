import { Card } from "react-bootstrap";
import { UncontrolledTooltip } from "reactstrap";

export const TextWithTooltip = ({ id, title, text, tooltip, className, placement="top-end", style }) => {
  return (
    <>
      {title && (
        <Card.Text
          id={id}
          className={className}
          style={{ fontSize: "0.675rem", lineHeight: "1.25rem",  ...style  }}
        >
          {title}
        </Card.Text>
      )}
      {text && (
        <Card.Text
          id={id}
          className={className}
          style={{ fontSize: "0.675rem", lineHeight: "1.25rem", ...style }}
        >
          {text}
        </Card.Text>
      )}
      {tooltip && (
        <UncontrolledTooltip placement={placement} target={id}>
          {tooltip}
        </UncontrolledTooltip>
      )}
    </>
  );
};
