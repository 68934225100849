const enUsClimateTranslations = {
  climate: {
    true: "ON",
    false: "OFF",
    cooling: "Cooling to",
    heating: "Heating to",
    fanOnly: "Fan Only",
    drying: "Drying to",
    autoMode: "Auto Mode to",
    errorSending: "Error sending command",
    successSending: "Command sent successfully",
    error: "Error!",
    success: "Success!",

    cool: "Cool",
    heat: "Heat",
    fan: "Fan",
    dry: "Dry",
    auto: "Auto",

    quiet: "Quiet",
    low: "Low",
    medium: "Medium",
    high: "High",
    strong: "Strong",

    syncState: "Sync A/C State",
    syncOff: "The A/C is OFF",
    syncOn: "The A/C is ON",

    deviceNotRegistered: "Device not registered",
    climate: "Climate",
  },
}

export default enUsClimateTranslations;