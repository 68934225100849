import { useEffect, useRef, useState } from "react";
import { Card, CardFooter } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Badge, CardBody, CardHeader, CardText, CardTitle } from "reactstrap";

export const DevicesCard = ({ devices, title }) => {
  const { t } = useTranslation();
  const footerRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const onlineDevices = devices.filter((device) => device.status === 0);
  const offlineDevices = devices.filter((device) => device.status === 1);
  const noCommDevices = devices.filter((device) => device.status === 2);

  useEffect(() => {
    const footer = footerRef.current;
    if (footer) {
      const contentWidth = footer.scrollWidth;
      const containerWidth = footer.clientWidth;

      if (contentWidth > containerWidth) {
        const content = footer.innerHTML;
        footer.innerHTML = content + content;

        const scroll = () => {
          if (!isHovered) {
            if (footer.scrollLeft >= footer.scrollWidth / 2) {
              footer.scrollLeft = 0;
            } else {
              footer.scrollLeft += 1;
            }
          }
        };

        const intervalId = setInterval(scroll, 20);
        return () => clearInterval(intervalId);
      }
    }
  }, [onlineDevices, offlineDevices, noCommDevices, isHovered]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Card className="m-0 p-0">
      <CardHeader>
        <CardTitle tag="h5">{title}</CardTitle>
      </CardHeader>
      <CardBody>
        <CardText>
          {t("noc.labels.online")}: {onlineDevices.length} | {t("noc.labels.offline")}: {offlineDevices.length} | {t("noc.labels.noCommunication")}: {noCommDevices.length}
        </CardText>
      </CardBody>
      <CardFooter
        ref={footerRef}
        className="scrolling-footer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center",
        }}
      >
        {onlineDevices.map((device) => (
          <Badge key={device.dvcId} color="success" className="mx-1">
            {device.description}
          </Badge>
        ))}
        {offlineDevices.map((device) => (
          <Badge key={device.dvcId} color="warning" className="mx-1">
            {device.description}
          </Badge>
        ))}
        {noCommDevices.map((device) => (
          <Badge key={device.dvcId} color="secondary" className="mx-1">
            {device.description}
          </Badge>
        ))}
      </CardFooter>
    </Card>
  );
};
