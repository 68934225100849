export const CustomToggle = ({ children, eventKey, useAccordionButton }) => {
    const decoratedOnClick = useAccordionButton(eventKey);
  
    return (
      <div
        style={{ cursor: "pointer", padding: "10px", border: "1px solid #ddd" }}
        onClick={decoratedOnClick}
      >
        {children}
      </div>
    );
  };
  