import {
  DiffOutlined,
  SettingOutlined
} from "@ant-design/icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker, Menu, Modal, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import { usePlantContext } from "../PlantProvider";
import ModalSelectReport from "../RefMeter/SubMenu/ModalSelectReport";
import { ModalCalculationBase } from "../RefMeter/components/ModalCalculationBase";
import { PlantConfig } from "../SubMenu/PlantConfig";
import ModalDeletePlantData from "../SubMenu/components/ModalDeletePlantData";
import Preferences from "../SubMenu/components/Preferences";

export const NavBarEnergy = ({ plant }) => {
  const { t } = useTranslation();
  const [isModalPreferencesOpen, setIsModalPreferencesOpen] = useState(false);
  const [isModalCalcReportOpen, setIsModalCalcReportOpen] = useState(false);
  const [isModalReportOpen, setIsModalReportOpen] = useState(false);
  const { selectedDate, setSelectedDate } = usePlantContext();
  const [isModalParamsOpen, setIsModalParamsOpen] = useState(false);
  const [isModalResetPlantOpen, setIsModalResetPlantOpen] = useState(false);

  function toggleModalParams() {
    setIsModalParamsOpen(!isModalParamsOpen);
  }

  function toggleModalCalcReport() {
    setIsModalCalcReportOpen(!isModalCalcReportOpen);
  }

  function toggleModalReport() {
    setIsModalReportOpen(!isModalReportOpen);
  }

  function toggleModalResetPlant() {
    setIsModalResetPlantOpen(!isModalResetPlantOpen);
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf("month");
  };

  const showPreferencesModal = () => setIsModalPreferencesOpen(true);
  const handleModalClose = () => setIsModalPreferencesOpen(false);

  const dateFormat = "YYYY-MM-DD";

  return (
    <>
      <Card className="energy-header-controls">
        <Typography.Text>
          {/* Processado em: {moment().format("llll")}{" "} */}
          {/* Atualizando para a data atual */}
        </Typography.Text>

        <DatePicker
          value={moment(selectedDate, dateFormat)}
          onChange={(date) => {
            if (!date) return;
            setSelectedDate(new Date(date));
          }}
          format="MM/YYYY"
          picker="month"
          placeholder={t("solar.selectMonth")}
          disabledDate={disabledDate}
        />

        <Menu mode="horizontal" className="ml-auto">
          <Menu.Item
            key="rateio"
            icon={<DiffOutlined />}
            style={{ marginRight: "8px" }}
          >
            <Link
              to={`/project-${plant.id}-default/sharing/${plant.companyId}`}
            >
              Rateio
            </Link>
          </Menu.Item>
          <Menu.SubMenu
            key={"report"}
            title={t("solar.reactPdfReport")}
            icon={<FontAwesomeIcon icon={faFilePdf} />}
          >
            <Menu.Item onClick={() => toggleModalReport("report")}>
              {t("dataExplorer.export")}
            </Menu.Item>
            <Menu.Item onClick={() => toggleModalCalcReport("report")}>
              {t("calculationBase.title")}
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="config"
            title="Configurações"
            icon={<SettingOutlined />}
          >
            <Menu.Item
              key="exibirTela"
              icon={<SettingOutlined />}
              onClick={showPreferencesModal}
            >
              Exibir em Tela
            </Menu.Item>
            {localStorage.getItem("userType") !== "3" && (
              <>
                <Menu.Item
                  key="parametros"
                  icon={<SettingOutlined />}
                  onClick={toggleModalParams}
                >
                  Parâmetros
                </Menu.Item>
                <Menu.Item
                  key="apagar"
                  icon={<DiffOutlined />}
                  style={{ marginRight: "20px" }}
                  onClick={toggleModalResetPlant}
                >
                  Apagar Dados
                </Menu.Item>
              </>
            )}
          </Menu.SubMenu>
        </Menu>
        {isModalParamsOpen && (
          <PlantConfig
            open={isModalParamsOpen}
            toggle={toggleModalParams}
            projName={plant.name}
            projId={plant.id}
          />
        )}

        {isModalResetPlantOpen && (
          <ModalDeletePlantData
            open={isModalResetPlantOpen}
            toggle={toggleModalResetPlant}
            name={plant.name}
            id={plant.id}
          />
        )}

        <Modal
          title={t("preferences.title")}
          open={isModalPreferencesOpen}
          onOk={handleModalClose}
          onCancel={handleModalClose}
          footer={null}
        >
          <Preferences
            projId={plant.id}
            open={isModalPreferencesOpen}
            toggle={handleModalClose}
          />
        </Modal>

        {isModalCalcReportOpen && (
          <ModalCalculationBase
            isOpen={isModalCalcReportOpen}
            handleCloseModal={toggleModalCalcReport}
          />
        )}

        {isModalReportOpen && (
          <ModalSelectReport
            open={isModalReportOpen}
            toggle={toggleModalReport}
            projName={plant.name}
            projId={plant.id}
          />
        )}
      </Card>
    </>
  );
};
