import React, { useState, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import axios from 'axios';
import {
  Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader,
  CardTitle, Container, Row, Col, Spinner, CardFooter, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem, ModalBody
} from "reactstrap";

import { MoreVertical } from "react-feather";
import { useTranslation } from 'react-i18next';
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import GaugeChart from "../../igDashboards/view/cards/GaugeChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

const CardDropdown = ({ preview, statisticCard, gaugeCard, stateCard, dataGet, card }) => {
  const { t } = useTranslation();

  return (
    <UncontrolledDropdown className="d-inline-block" disabled={(preview)}>
      <DropdownToggle tag="a">
        <MoreVertical className="ml-1" style={{ width: "15px" }} />
      </DropdownToggle>

      <DropdownMenu right>
        <DropdownItem
          onClick={statisticCard}
        >
          {t('devices.dropDownStatistic')}
        </DropdownItem>

        <DropdownItem
          onClick={gaugeCard}
          disabled={card.varType === "Bool"}
        >
          {t('devices.dropDownGauge')}
        </DropdownItem>

        <DropdownItem
          onClick={stateCard}
          disabled={card.varType !== "Bool"}
        >
          {t('devices.dropDownState')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const VariableCard = ({
  card,
  loadingVars,
  deviceVars,
  dataGet,
  lastCommDataInterval,
  lastCommDate,
  timestampNow,
  loadingData,
  lastCommData,
  setDeviceVars,
  setLoadingVars,
  setDataGet,
  // dailyEnergy,
}) => {
  const { t } = useTranslation();
  const [cardType, setCardType] = useState('statisticCard')

  function statisticCard() {
    setCardType('statisticCard')
  }

  function gaugeCard() {
    setCardType('gaugeCard')
  }

  function stateCard() {
    setCardType('stateCard')
  }

  return (cardType === 'statisticCard') ? (
    <Card>
      <CardHeader>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <CardTitle tag="h5" className="mb-2">
            {card.varName}
          </CardTitle>

          <CardDropdown
            card={card}
            statisticCard={statisticCard}
            gaugeCard={gaugeCard}
            stateCard={stateCard}
            setCardType={setCardType}
          />

        </div>
        <p style={{ overflowX: "scroll" }} className="card-subtitle mb-2 text-muted">
          {card.varDesc}
        </p>

      </CardHeader>
      <CardBody style={{
        height: '162px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}

        className="text-center"
      >
        {(loadingVars || loadingData)
          ? <div className="text-center">
            <Spinner color="primary" />
          </div>
          : (deviceVars && dataGet)
            ? (lastCommDate >= timestampNow - lastCommDataInterval)
              ? <div>
                <div>

                  {(dataGet[card.tag]) ?
                    <h1 style={{
                      fontSize:
                        String(dataGet[card.tag]).length >= 6 ? "2rem" : "3rem"
                    }} className="display-5 mt-1 mb-3 text-center"
                    >

                      {(dataGet[card.tag]) ? dataGet[card.tag] : null}

                      {' '}

                      <span>{(card.unit !== null) ? card.unit : null}</span>
                      {/* <h2>{(dataGet[card.tag]) && card.varName}</h2> */}
                    </h1>
                    : <h1 className="display-5 mt-1 mb-3 text-center">{card.tag}</h1>}
                </div>

              </div>
              : t('devices.offline')
            : t('devices.noData')
        }
      </CardBody>

      <CardFooter>


      </CardFooter>
    </Card>
  ) : (cardType === 'gaugeCard') ? (
    <Card>
      <CardHeader>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <CardTitle tag="h5" className="mb-2">
            {card.varName}
          </CardTitle>

          <CardDropdown
            card={card}
            dataGet={dataGet}
            statisticCard={statisticCard}
            gaugeCard={gaugeCard}
            stateCard={stateCard}
            setCardType={setCardType}
          />

        </div>
        <p style={{ overflowX: "scroll" }} className="card-subtitle mb-2 text-muted">
          {card.varDesc}
        </p>

      </CardHeader>
      <ModalBody>
        <GaugeChart
          card={card}
          dataGet={dataGet}
          deviceVars={deviceVars}
          statisticCard={statisticCard}
          gaugeCard={gaugeCard}
          stateCard={stateCard}
          setDeviceVars={setDeviceVars}
          setLoadingVars={setLoadingVars}
          setDataGet={setDataGet}
          lastCommDate={lastCommDate}
          lastCommData={lastCommData}
        />

      </ModalBody>

      <CardFooter>

      </CardFooter>
    </Card>


  ) : (cardType === 'stateCard') ? (


    <Card>

      <CardHeader>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <CardTitle tag="h5" className="mb-2">
            {card.varName}
          </CardTitle>

          <CardDropdown
            card={card}
            statisticCard={statisticCard}
            gaugeCard={gaugeCard}
            stateCard={stateCard}
            setCardType={setCardType}
          />

        </div>
        <p style={{ overflowX: "scroll" }} className="card-subtitle mb-2 text-muted">
          {card.varDesc}
        </p>

      </CardHeader>
      <CardBody
        style={{
          height: '162px',
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}

        className="text-center">
        {(!isNaN(dataGet[card.tag]) && dataGet[card.tag] > 0)
          ? 
          <div style={{ position: "relative" }}>
            <FontAwesomeIcon
              icon={faPowerOff}
              className="align-middle"
              style={{ color: "green", width: "24px", fontSize: "2.5rem" }}
            />
            <h2 className="display-3 w-responsive text-center" style={{
              fontSize: "2.5rem",
              top: "35%",
              transform: "translate (0, -50%)",
              marginTop: "6px",
              color: "green"
            }}>
              {t('dashboards.on')}
            </h2>
          </div>
          : (!isNaN(dataGet[card.tag]) && dataGet[card.tag] <= 0) ?
          <div style={{ position: "relative" }}>
            <FontAwesomeIcon
              icon={faPowerOff}
              className="align-middle"
              style={{ color: "red", width: "24px", fontSize: "2.5rem" }}
            />
            <h2 className="display-3 w-responsive text-center" style={{
              fontSize: "2.5rem",
              top: "35%",
              transform: "translate (0, -50%)",
              marginTop: "6px",
              color: "red"
            }}>
              {t('dashboards.off')}
            </h2>
          </div> 
          :
          <div style={{ position: "relative" }}>
            <FontAwesomeIcon
              icon={faPowerOff}
              className="align-middle"
              style={{ color: "green", width: "24px", fontSize: "2.5rem" }}
            />
            <h2 className="display-3 w-responsive text-center" style={{
              fontSize: "2.5rem",
              top: "35%",
              transform: "translate (0, -50%)",
              marginTop: "6px",
              color: "red"
            }}>
              {dataGet[card.tag]}
            </h2>
         </div>
          }
      </CardBody>

      <CardFooter>

      </CardFooter>
    </Card>
  ) : null
}

const DeviceVariables = () => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();

  const deviceId = url.split('/')[1].split('-')[1];

  const [loadingVars, setLoadingVars] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [deviceVars, setDeviceVars] = useState([]);
  const [dataGet, setDataGet] = useState();
  const [lastCommData, setLastCommData] = useState();
  const [lastCommDataInterval, setLastCommDataInterval] = useState();
  const [deviceName, setDevicesName] = useState("");
  const lastCommDate = new Date(lastCommData * 1000);
  const timestampNow = Math.floor(Date.now() / 1000);

  useEffect(() => {
    if (loadingVars) { // busca variaveis a serem lidas
      axios.get('/readVariables', {
        params: {
          id: deviceId
        }
      })
        .then(res => {
          setDeviceVars(res.data);
          setLoadingVars(false);
          setLoadingData(true);
        })
        .catch(err => {
          console.log(err);
          setLoadingVars(false);
        })
    }

    if (loadingData) { // busca dados
      axios.get('/readDataLast', {
        params: {
          idDevice: deviceId
        }
      })
        .then(res => {
          setLastCommDataInterval(res.data.commInterval);
          setLastCommData(res.data.lastComm);
          setDevicesName(res.data.deviceName);
          setDataGet(res.data);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => setLoadingData(false));
    }

  }, [deviceId, loadingVars, loadingData]);

  return (
    <Container fluid>
      <Header className="mb-4">
        <Row>
          <Col>
            <HeaderTitle>
              {deviceName}
            </HeaderTitle>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to='/home'>
                  {t('dashboards.homePage')}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to='/projects'>
                  {t('projects.title')}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {deviceName}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="text-right" style={{ marginTop: "24px" }}>
            {/* <Link to="/newCard">
              <Button
                color="primary"
                className=""
              >
                {t('dashboards.addCard')}
              </Button>
            </Link> */}
          </Col>
        </Row>
      </Header>

      <Row>
        {deviceVars.map((card, index) => (
          <Col key={index} xs={12} sm={6} md={6} lg={4} xl={3}>
            <VariableCard
              card={card}
              loadingData={loadingData}
              loadingVars={loadingVars}
              setLoadingVars={setLoadingVars}
              deviceVars={deviceVars}
              dataGet={dataGet}
              lastCommDataInterval={lastCommDataInterval}
              lastCommDate={lastCommDate}
              timestampNow={timestampNow}
              setDeviceVars={setDeviceVars}
              setDataGet={setDataGet}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default DeviceVariables;