import React, { useState } from "react";

import {
  Button, Modal, ModalHeader,
  ModalBody, ModalFooter
} from "reactstrap";
import { useTranslation } from 'react-i18next';
import ReportView from "./components/ReportView";
import ModalSendMail from "./components/ModalSendMail";
import { useAPI } from '../../SolarProvider';

/****** MODAL DE EDIÇÃO DE DASHBOARD ******/
const ModalPDF = ({
  jsonData,
  getDataToSend,
  open,
  toggle,
  data,
  projectId,
  monthlyUsageScreenshot,
  pdfScreenshot,
  projectName,
}) => {
  const { t } = useTranslation();
  const [openSendMail, setOpenSendMail] = useState(false);

  const { selectDate } = useAPI();

  function toggleModalSendMail() {
    setOpenSendMail(!openSendMail);
  }

  monthlyUsageScreenshot();

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      size='lg'
    >
      <ModalHeader toggle={toggle}>
        {t("solar.reactPdfModalTitle")}
      </ModalHeader>

      <ModalBody className="overflow-auto">
        <ReportView
          getDataToSend={getDataToSend}
          projectName={projectName}
          data={data}
          screenshot={pdfScreenshot}
          monthRef={(!selectDate) ? Date.now() : Date.parse(selectDate)}
          projectId={projectId}
        />
        {(openSendMail)
          ? <ModalSendMail
              jsonData={jsonData}
              open={openSendMail}
              toggle={toggleModalSendMail}
              monthRef={(!selectDate) ? Date.now() : Date.parse(selectDate)}
              data={data}
              projectId={projectId}
           />
          : null
        }
      </ModalBody>

      <ModalFooter>
        <Button
          color='primary'
          className="mb-1"
          onClick={toggleModalSendMail}
        >
          {t('solarReport.sendEmail')}
        </Button>
        <Button
          color='primary'
          className="mb-1"
          onClick={toggle}
          outline

        >
          {t('solarReport.closeButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalPDF;