const { default: axios } = require("axios");

export const fetchGet = async (source, setData, url) => {
  try {
    const response = await axios.get(url, { cancelToken: source.token });
    setData((prevData) => ({
      ...prevData,
      list: response.data,
      loading: false,
      error: null,
    }));
  } catch (error) {
    if (axios.isCancel(error)) return;
    setData((prevData) => ({
      ...prevData,
      list: [],
      loading: false,
      error,
    }));
  }
};

export const fetchGetWitchParams = async (
  source,
  setData,
  setError,
  setLoading,
  url,
  params
) => {
  try {
    const response = await axios.get(url, {
      params: params,
      cancelToken: source.token,
    });
    setData(response.data);
  } catch (err) {
    if (!axios.isCancel(err)) {
      setError(err);
    }
  } finally {
    setLoading(false);
  }
};
