import { t } from "i18next";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import {
  readCardValues,
  readCompanies,
  readCompanyUsers,
  readListNocData,
  readProject,
  readSelectOptions,
  registerContract,
  updateContract,
} from "./ServiceAuthorizationsService";
import moment from "moment";

export const NocContext = createContext();

export const ServiceAuthorizationsProvider = ({ children }) => {
  const storageListCompanies = JSON.parse(
    localStorage.getItem("companyList") || "[]"
  );

  const [companies, setCompanies] = useState({
    data: storageListCompanies,
    loading: false,
  });
  const [companyUsers, setCompanyUsers] = useState({
    data: [],
    loading: false,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [inputSelectOptions, setInputSelectOptions] = useState({
    data: {
      contract: [],
      statusNoc: [],
    },
    loading: false,
  });
  const [listNocData, setListNocData] = useState({
    data: [],
    loading: false,
  });
  const [step, setStep] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [modalEdit, setModalEdit] = useState({
    id: null,
    loading: false,
    card: {},
    sourceColumn: null,
    destinationColumn: null,
  });
  const [projectExists, setProjectExists] = useState({
    loading: false,
    id: null,
  });

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      companyId: +localStorage.getItem("companyId") || null,
      personResponsibleId: +localStorage.getItem("userId") || null,
      commercialResponsibleId: "",
      projectName: "",
      descriptionProject: "",
      typeContract: "",
      deadlineForExecution: "",
      numberOfDevices: "",
      contractDuration: "",
      monthValue: "",
      address: "",
      neighborhood: "",
      city: "",
      state: "",
      zipCode: "",
      solarModuleOn: 0,
      powerModuleOn: 0,
      waterModuleOn: 0,
      gasModuleOn: 0,
      climateModuleOn: 0,
    },
  });

  const handleCloseModal = (updated) => {
    if (!updated && modalEdit.destinationColumn) {
      retriveCardToInitLocation();
    }
    setModalOpen(false);
    setModalEdit({
      id: null,
      loading: false,
      card: {},
      sourceColumn: null,
      destinationColumn: null,
    });
    setProjectExists({
      loading: false,
      id: null,
    });
    setFilesUploaded([]);
    setSelectedFiles([]);
    setStep(0);
    reset();
  };

  const retriveCardToInitLocation = () => {
    const newArray = [...listNocData.data.list];
    const card = newArray.find((item) => item.nocId === modalEdit.id);
    const index = newArray.indexOf(card);
    card.statusName = modalEdit.sourceColumn;
    newArray.splice(index, 1);
    newArray.splice(index, 0, card);
    setListNocData({ ...listNocData, list: newArray });
  };

  const handleOnDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) return;

    const restrictedColumns = ["manutencao", "monitoramento"];
    const sourceColumn = source.droppableId;
    const destinationColumn = destination.droppableId;

    if (
      (restrictedColumns.includes(sourceColumn) &&
        !restrictedColumns.includes(destinationColumn)) ||
      sourceColumn === destinationColumn
    ) {
      return;
    }

    const updatedList = [...listNocData.data.list];
    const itemIndex = updatedList.findIndex(
      (item) => item.nocId.toString() === draggableId
    );
    const [removed] = updatedList.splice(itemIndex, 1);
    removed.statusName = destination.droppableId;
    updatedList.splice(destination.index, 0, removed);

    setListNocData({ ...listNocData, list: updatedList });

    handleChangeCardColumn({
      card: removed,
      destinationColumn: destination.droppableId,
      sourceColumn,
    });
  };

  const handleUpdateCard = (card) => {
    getCardValues(card);
  };

  const handleChangeCardColumn = async ({
    card,
    destinationColumn,
    sourceColumn,
  }) => {
    setModalEdit({ ...modalEdit, loading: true });
    await getCardValues(card, true);

    const statusId = inputSelectOptions.data.statusNoc.find(
      (status) => status.name === destinationColumn
    ).id;

    setModalEdit({
      id: card.nocId,
      loading: false,
      card: card,
      sourceColumn,
      destinationColumn: statusId,
    });

    setModalOpen(!modalOpen);
  };

  const getCardValues = async (card, changeColumn = false) => {
    setModalEdit({ ...modalEdit, loading: true });
    const response = await readCardValues(card.nocId);

    setValue("companyId", response.data.companyId);
    setValue(
      "personResponsibleId",
      !changeColumn ? response.data.personResponsibleId : null
    );
    setValue("commercialResponsibleId", response.data.commercialResponsibleId);

    setValue("projectName", response.data.projectName);
    setValue("descriptionProject", response.data.descriptionProject);
    setValue("typeContract", response.data.typeContract);
    setValue(
      "deadlineForExecution",
      moment(response.data.deadlineForExecution.split("T")[0]).format(
        "YYYY-MM-DD"
      )
    );
    setValue("numberOfDevices", response.data.numberOfDevices);
    setValue("contractDuration", "Meses: " + response.data.contractDuration);
    setValue(
      "monthValue",
      "R$ " + response.data.monthValue.replaceAll(",", "").replace(".", ",")
    );
    setValue("address", response.data.address);
    setValue("neighborhood", response.data.neighborhood);
    setValue("city", response.data.city);
    setValue("state", response.data.state);
    setValue("zipCode", response.data.zipCode);
    setValue("solarModuleOn", response.data.solarModuleOn || 0);
    setValue("powerModuleOn", response.data.powerModuleOn || 0);
    setValue("waterModuleOn", response.data.waterModuleOn || 0);
    setValue("gasModuleOn", response.data.gasModuleOn || 0);
    setValue("climateModuleOn", response.data.climateModuleOn || 0);
    setFilesUploaded(JSON.parse(response.data.filesNames));

    setModalEdit({
      ...modalEdit,
      id: card.nocId,
      loading: false,
      card: card,
      sourceColumn: card.statusName,
      destinationColumn: null,
    });
  };

  const onSubmit = async (data) => {
    try {
      if (modalEdit.id) {
        await updateContractCard(data);
      } else {
        await createContractCard(data);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const updateContractCard = async (data) => {
    const formattedData = Object.keys(data).reduce((acc, key) => {
      let value = data[key];

      if (typeof value === "string") {
        value = value.trim();
      }

      if (key === "monthValue") {
        value = parseFloat(
          value
            .replace("R$", "")
            .trim()
            .replaceAll(".", "")
            .replace(",", ".")
            .trim()
        ).toFixed(2);
      } else if (key === "contractDuration") {
        value = parseInt(value.replace("Meses:", "").trim(), 10);
      }

      acc[key] = value;
      return acc;
    }, {});

    const formData = new FormData();

    Object.entries(formattedData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    formData.append("nocId", modalEdit.id);
    if (modalEdit.destinationColumn)
      formData.append("statusId", modalEdit.destinationColumn);
    formData.append("projId", modalEdit.card.projectId);

    const response = await updateContract(formData);

    if (response.status === 200) {
      searchListNocData();
      toastr.success(
        t("noc.axiosMsg.success"),
        t("noc.axiosMsg.contractEdited")
      );
    } else {
      toastr.error(
        t("noc.axiosMsg.error"),
        t("noc.axiosMsg.errorEditingContract")
      );
    }
    handleCloseModal(true);
  };

  const createContractCard = async (data) => {
    const formattedData = Object.keys(data).reduce((acc, key) => {
      let value = data[key];

      if (typeof value === "string") {
        value = value.trim();
      }

      if (key === "monthValue") {
        value = parseFloat(
          value
            .replace("R$", "")
            .trim()
            .replaceAll(".", "")
            .replace(",", ".")
            .trim()
        ).toFixed(2);
      } else if (key === "contractDuration") {
        value = parseInt(value.replace("Meses:", "").trim(), 10);
      }

      acc[key] = value;
      return acc;
    }, {});

    const formData = new FormData();
    Object.entries(formattedData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      await registerContract(formData);
      searchListNocData();
      toastr.success(
        t("noc.axiosMsg.success"),
        t("noc.axiosMsg.contractRegistered")
      );
      handleCloseModal(true);
    } catch (e) {
      toastr.error(
        t("noc.axiosMsg.error"),
        t("noc.axiosMsg.errorRegisteringContract")
      );
    }
  };

  const searchCompanies = async () => {
    try {
      const response = await readCompanies();
      setCompanies({ data: response.data, loading: false });
    } catch (error) {
      console.log(error);
      setCompanies({ data: [], loading: false });
    }
  };

  const searchCompanyUsers = async () => {
    try {
      const response = await readCompanyUsers();
      setCompanyUsers({ data: response.data, loading: false });
    } catch (error) {
      console.log(error);
      setCompanyUsers({ data: [], loading: false });
    }
  };

  const searchSelectOptions = async () => {
    const options = ["contract", "statusNoc"];

    setInputSelectOptions({ ...inputSelectOptions, loading: true });

    try {
      const response = await Promise.all(
        options.map((option) => readSelectOptions(option))
      );
      const data = response.reduce((acc, item, index) => {
        acc[options[index]] = item.data;
        return acc;
      }, {});

      setInputSelectOptions({ data, loading: false });
    } catch (error) {
      console.log(error);
      setInputSelectOptions({
        data: { contract: [], statusNoc: [] },
        loading: false,
      });
    }
  };

  const searchListNocData = async () => {
    const companyId = getValues("companyId");
    try {
      const response = await readListNocData(companyId);
      setListNocData({ data: response.data, loading: false });
    } catch (error) {
      console.log(error);
      setListNocData({ data: [], loading: false });
    }
  };

  const searchProject = async (projectName) => {
    setProjectExists((prev) => ({ ...prev, loading: true }));
    const { data, status } = await readProject(
      projectName,
      getValues("companyId")
    );

    if (status === 200) {
      setValue("projectName", projectName);
      setValue("descriptionProject", data.description);
      setValue("typeContract", data.typeContract || 1);
      setValue("address", data.address);
      setValue("neighborhood", data.neighborhood);
      setValue("city", data.city);
      setValue("state", data.state);
      setValue("zipCode", data.zipCode);
      setValue("solarModuleOn", data.solarModuleOn || 0);
      setValue("powerModuleOn", data.powerModuleOn || 0);
      setValue("waterModuleOn", data.waterModuleOn || 0);
      setValue("gasModuleOn", data.gasModuleOn || 0);
      setValue("climateModuleOn", data.climateModuleOn || 0);

      setProjectExists((prev) => ({ ...prev, id: data.id }));
    }
    setProjectExists((prev) => ({ ...prev, loading: false }));
  };

  useEffect(() => {
    if (!companies.data.length) {
      setCompanies({ ...companies, loading: true });
      searchCompanies();
    }
    searchCompanyUsers();
    searchSelectOptions();
    searchListNocData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NocContext.Provider
      value={{
        modalOpen,
        handleCloseModal,
        companies,
        companyUsers,
        register,
        handleSubmit,
        setValue,
        trigger,
        getValues,
        errors,
        selectedFiles,
        setSelectedFiles,
        onSubmit,
        isSubmitting,
        inputSelectOptions,
        step,
        setStep,
        listNocData,
        modalEdit,
        handleOnDragEnd,
        handleUpdateCard,
        watch,
        setModalOpen,
        filesUploaded,
        setFilesUploaded,
        searchProject,
        projectExists,
      }}
    >
      {children}
    </NocContext.Provider>
  );
};

export function useServiceAuthContext() {
  const context = useContext(NocContext);
  if (context === undefined) {
    throw new Error(
      "useServiceAuthContext must be used within a ServiceAuthorizationsProvider"
    );
  }
  return context;
}
