import React from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import { useTranslation } from 'react-i18next';

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import ChangePassword from './settings/changePassword';
import PublicInfo from "./settings/account";

/****** Página incial de configurações ******/
let tabActive = "account";

/****** BARRA LATERAL ******/
const Navigation = () => {
  const { t } = useTranslation();
  return(
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {t('settings.settings')}
        </CardTitle>
      </CardHeader>
      
      <ListGroup flush>
        <ListGroupItem // conta
          tag="a"
          href="#" 
          action 
          active={tabActive === "account"} 
          onClick={() => (tabActive = "account")}
        >
          {t('settings.account')}
        </ListGroupItem>

        <ListGroupItem // senha
          tag="a"
          href="#" 
          action 
          active={tabActive === "password"} 
          onClick={() => (tabActive = "password")}
        >
        {t('settings.password')}
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
}

/* Card lateral (aba ativa) *
 * Definidos externamente */
const SettingsPage = () => {
  /*** Verifica qual aba está ativa ***/
  if (tabActive === "account") {
    return <PublicInfo />
  } 
  else if (tabActive === "password") {
    return <ChangePassword />
  } 
  else return null
}

/****** PÁGINA DE CONFIGURAÇÕES ******/
const MySettings = () => {
  const { t } = useTranslation();
  return(
    <Container fluid>
      <Header>
        <HeaderTitle>{t('settings.title')}</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{t('sidebar.home')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t('navbar.settings')}</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Row>
        <Col md="3" xl="2">
          <Navigation />
        </Col>

        <Col md="9" xl="10">
          <SettingsPage />
        </Col>
      </Row>
    </Container>
  );
}

export default MySettings;
