import React, { useEffect, useState } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';
import BatteryGauge from 'react-battery-gauge'


import {
  CardBody,
  CardTitle,
  Row,
  Col,
  CardHeader,
  CardFooter,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardSubtitle,
  UncontrolledTooltip,
  Label,
} from "reactstrap";
import * as Icon from "react-feather";
import { MoreVertical } from "react-feather";
import { Link, useHistory } from "react-router-dom";

import ModalDeleteCard from "../ModalDeleteCard";

function CardDropdown(props) {
  const { t } = useTranslation();

  const [openDeleteCard, setOpenDeleteCard] = useState(false);
  const [editCard, setEditCard] = useState(false)

  function toggleDeleteCard() {
    setOpenDeleteCard(!openDeleteCard);
  }
  
  return (
    <UncontrolledDropdown className="d-inline-block" disabled={(props.preview)}>
      <DropdownToggle tag="a">
        <MoreVertical />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          onClick={() => {
            toggleDeleteCard();
          }}
        >
          {t('dashboards.delete')}
        </DropdownItem>
        {(openDeleteCard)
          ? <ModalDeleteCard
            open={openDeleteCard}
            toggle={toggleDeleteCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
          />
          : null
        }
        <Link
          onClick={() => setEditCard(props.cardId)}
          to={{
            pathname: `/newCard/batteryCard/${props.cardId}`,
            state: {
              cardProps: props.card,
              editCard: editCard,
            }
          }}>
          <DropdownItem>
            {t('dashboards.edit')}
          </DropdownItem>
        </Link>
        <DropdownItem
          onClick={() => {
            props.setDataLoading(true);

          }}
        >
          {t('dashboards.refresh')}
        </DropdownItem>

      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const BatteryCard = ({
  cardId,
  card,
  name,
  description,
  decimalNumber,
  deviceId,
  variables,
  tableReload,
  preview,
  reloadTime,
  setMinValue,
  maxTemperature,
  dimensions,
  retrieveDataFromPreviewCard,
  ref
}) => {
  
  const { t } = useTranslation();
  
  const [deviceData, setdeviceData] = useState([]);
  const [dataLoading, setdataLoading] = useState(true);
  const [device, setDevice] = useState("");

  const [cardHeight, setCardHeight] = useState();
  // const [cardWidth, setCardWidth] = useState();
  const [varUnit, setVarUnit] = useState("");
  // const [fontSize, setFontSize] = useState();
  const varName = (variables) ? JSON.parse(variables)?.varName : ""
  const varTag = (variables) ? JSON.parse(variables).tag : "";
  // const [sizeLoading, setSizeLoading] = useState(true);
  const [lastComm, setLastComm] = useState();
  const [commInterval, setCommInterval] = useState();

  // const [toggleCardOrientation, setToggleCardOrientation] = useState("");
  // const [charging, setCharging] = useState(false);
  
  const lastCommDate = new Date(lastComm * 1000);
  
  let history = useHistory();
  
  useEffect(() => {
    if (dimensions) {
      if (dimensions?.id === card?.id) {
        let var1 = ((dimensions?.size.height < 240 && dimensions.size.width  < 240) && (card.id === dimensions?.id)) ? 14
          : ((dimensions?.size.height > 241 && dimensions.size.width > 320) && (card.id === dimensions?.id) && (dimensions?.size.height < 320)) ? 12
            : ((dimensions?.size.height > 321 && dimensions.size.width > 370) && (card.id === dimensions?.id) && (dimensions?.size.height < 370)) ? 8
              : ((dimensions?.size.height > 371 && dimensions.size.width > 370) && (card.id === dimensions?.id)) ? 7
                : 14
        // let var2 = ((dimensions?.size.width < 240) && (card.id === dimensions?.id)) ? 14
        //   : ((dimensions?.size.width > 241) && (card.id === dimensions?.id) && (dimensions?.size.width < 320)) ? 12
        //     : ((dimensions?.size.width > 321) && (card.id === dimensions?.id) && (dimensions?.size.width < 370)) ? 8
        //       : ((dimensions?.size.width > 371) && (card.id === dimensions?.id)) ? 7
        //         : 14
        setCardHeight(var1)
        // setCardWidth(var2)
      }
    }
  }, [dimensions, card?.id])

  useEffect(() => {

    if (deviceId) {
      axios.get('/readDataLast', {
        params: {
          idDevice: deviceId
        }
      })
        .then(response => {
          // console.log(response);
          setdeviceData(response.data)
          setLastComm(response.data.lastComm);
          setCommInterval(response.data.commInterval);
          setdataLoading(false)
        })
        .catch(err => {
          console.log(err);
          toastr.warning('Erro!', "Erro ao buscar dados.");
        })
    }

    if (reloadTime) {
      const intervalSearch = setInterval(() => {
        if (deviceId) {
          axios.get('/readDataLast', {
            params: {
              idDevice: deviceId
            }
          })
            .then(response => {
              setdeviceData(response.data)
              setLastComm(response.data.lastComm);
              setCommInterval(response.data.commInterval);
            })
            .catch(err => {
              console.log(err);
              toastr.warning('Erro!', "Erro ao buscar dados.");
            });
          if (history.location.pathname !== "/dashboardView") {
            clearInterval(intervalSearch);
          };
        }
      }, reloadTime);
    }
  }, [deviceId, dataLoading, history, reloadTime])

  //Envia o id do device e retorna o modelo 
  useEffect(() => {

    if (deviceId) {
      axios.get('/readDeviceInfo', {
        params: {
          idDevice: deviceId
        }
      })
        .then(response => {
          setDevice(response.data);
          setLastComm(response.data.lastComm);
          setCommInterval(response.data.commInterval);
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar Modelo.");
        })
    }

  }, [deviceId])

  //Envia o id do device e retorna a unidade da variável 
  useEffect(() => {
    if (deviceId) {
      axios.get('/readVariables', {
        params: {
          id: deviceId
        }
      })
        .then(res => {
          // setDeviceVars(response.data)
          setVarUnit(res.data.find(item => item.tag === varTag).unit);
        })
        .catch(err => {
          console.log(err);
          // toastr.error('Erro!', "Erro ao buscar Unidade.");
        })
    }
  }, [deviceId, varTag]);
  
  return (
    <div id="parent">
      <CardHeader>
        <div className="card-actions float-right" style={{ marginTop: "2px" }}>
          <Row className="card-actions float-right">
            <Col className="pl-0" style={{ paddingRight: "3px" }}>
              <div className="box no-cursor cursor-pointer mp-0 otavio">
                <Icon.Move style={{ width: "15px" }} />
              </div>
            </Col>
            <CardDropdown
              card={card}
              cardId={cardId}
              tableReload={tableReload}
              name={name}
              description={description}
              preview={(preview)}
              setDataLoading={setdataLoading}
            />
          </Row>
        </div>
        <div id={"name" + cardId} >
          {(name) ?
            <UncontrolledTooltip
              style={{ backgroundColor: "#202020", opacity: 1, fontSize: ".8rem", color: "#F1F1F1", border: "1px solid #000", padding: "1rem" }}
              placement="top"
              target={"name" + cardId}
            >
              {name}
            </UncontrolledTooltip>
            : null}
          <CardTitle style={{ whiteSpace: "nowrap" }} className="mr-5 overflow-hidden text-left my-1 h5">{name}</CardTitle>
          <CardSubtitle style={{ whiteSpace: "nowrap" }} className="mr-5 overflow-hidden text-muted text-left">
            {description}
          </CardSubtitle>
          {(description) ?
            <UncontrolledTooltip
              style={{ backgroundColor: "#202020", opacity: 1, fontSize: ".8rem", color: "#F1F1F1", border: "1px solid #000", padding: "1rem" }}
              placement="bottom"
              target={"name" + cardId}
            >
              {description}
            </UncontrolledTooltip>
            : null}
        </div>
      </CardHeader>
      <CardBody id={"desc" + cardId} className="text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 20, paddingTop: "0px" }}>
        {(deviceData[varTag] || deviceData[varTag] === 0)
          //verifica se tem devices e o conteúdo e se o conteúdo é igual a zero ele printa a variável e a unidade selecionada no novo card, se não não há dados.
          ? (lastComm >= Math.floor(Date.now() / 1000) - (commInterval * 2))
            ? <div className="pb-3" style={{
              paddingLeft: (!name && !description) ? 30 : 0,
              paddingTop: (cardHeight >= 14) ? 10 : 30
            }}>
              <Label tag={(cardHeight >= 14) ? "h5" : "h3"}>{deviceData[varTag]} {varUnit}</Label>
              <BatteryGauge
                padding={cardHeight}
                width={(cardHeight <= 7) ? "100%" : 120}
                height={"100%"}
                aspectRatio={
                  (cardHeight <= 14) ? 0.6
                    : (cardHeight < 14) ? 0.6
                      : 0.5
                }
                customization={{
                  batteryBody: {
                    // fill: 'silver',
                    strokeColor: '#ccc',
                    strokeWidth: 2
                  },
                  batteryCap: {
                    capToBodyRatio: 0.5,
                    // cornerRadius: 3,
                    // fill: 'silver',
                    strokeColor: '#ccc',
                    strokeWidth: 2
                  },
                  batteryMeter: {
                    // fill: 'green',
                    // noOfCells: 1, // more than 1, will create cell battery
                    noOfCells: 10,
                    fill: "#67b7dc",
                    lowBatteryValue: 15,
                    lowBatteryFill: 'red',
                    outerGap: 1,
                    interCellsGap: 1
                  },
                  readingText: {
                    display: "none",
                    darkContrastColor: '#FFF',
                    fontFamily: 'Arial',
                    pointsAtY: 50,
                    x: "50px",
                    x2: "50px",
                    x3: "50px",
                    // fontSize: (cardHeight.size.height < 240) ? 8 : 12,
                    // lightContrastColor: 'purple',
                    lowBatteryColor: 'red',
                    style: { backgroundColor: "#000" }
                  }
                }}
                // charging={charging}
                // orientation={toggleCardOrientation}
                value={deviceData[varTag]}
              />

            </div>
            : <p className="mt-1">{t('dashboards.offlineStats')}</p>
          : <p className="mt-1">{t('dashboards.noDataStats')}</p>
        }
      </CardBody>
      {/* <input type="radio" id="horizontal" name="orientation" onClick={() => setToggleCardOrientation("horizontal")} />
      <label for="horizontal">horizontal</label>
      {" "}
      <input type="radio" id="vertical" name="orientation" onClick={() => setToggleCardOrientation("vertical")} />
      <label for="vertical">vertical</label>

      <label className="d-flex flex-column">
        Carregar
        <input type="checkbox" onClick={isCharging} />
      </label> */}
      {((cardHeight < 14)) ?
        <CardFooter style={{ whiteSpace: "nowrap", backgroundColor: "transparent", bottom: "0", position: `${(!preview) ? "absolute" : null}`, padding: "5px 1.25rem" }} className="mr-5 overflow-hidden text-left my-1 h5 text-left mt-1">
          <h6 className="text-left">{device.description}</h6>
          <h6 className="card-subtitle text-muted">{varName}</h6>
          <h6 className="card-subtitle text-muted mt-1">
            {(lastComm)
              ? lastCommDate.getDate().toString().padStart(2, '0') + '/' +
              (lastCommDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
              lastCommDate.getFullYear().toString().padStart(2, '0') + ' ' +
              lastCommDate.getHours().toString().padStart(2, '0') + ':' +
              lastCommDate.getMinutes().toString().padStart(2, '0') + ':' +
              lastCommDate.getSeconds().toString().padStart(2, '0')
              : t('dashboards.noComm')
            }
          </h6>
        </CardFooter>
        : null}
    </div>
  )
}

export default BatteryCard;