import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

export const ModalAddInBatches = ({
  closeModal,
  closeAllModals,
  isOpen,
  projectId,
  reloadData,
}) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleToggle = () => closeModal((prev) => !prev);

  const handleSubmit = () => {
    if (!selectedFile) {
      alert("Por favor, selecione um arquivo.");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("projectId", projectId);

    axios
      .post("/v2/automatic/automaticRegister", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "seu_arquivo.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setLoading(false);
        reloadData(true);
      })
      .catch((error) => {
        console.error("Ocorreu um erro no envio do arquivo:", error);
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <ModalHeader>{t("projTable.automaticLoraRegisters")}</ModalHeader>
      <Form>
        <ModalBody>
          <FormGroup>
            <Label for="xlsxFile">XLSX</Label>
            <Input
              name="xlsxFile"
              id="xlsxFile"
              type="file"
              onChange={handleFileChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleToggle}>{t("Voltar")}</Button>
          <Button onClick={closeAllModals}>{t("projTable.cancel")}</Button>
          <Button onClick={handleSubmit} disabled={loading}>
            {t("projTable.saveDevice")}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
