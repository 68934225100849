import React from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import packageJson from "../../package.json";
import { getSupportEmail } from "../../src/utils/functions";
import { getCompanyName, getDomain } from "../../src/utils/domain";
import { TextWithTooltip } from "./TextWithTooltip";

const Footer = () => {
  const { t } = useTranslation();
  const href = getDomain();
  const companyName = getCompanyName();

  return (
    <footer
      className="footer"
      style={{
        position: "absolute",
        bottom: "-5px",
        right: "0",
        textAlign: window.innerWidth < 700 ? "center" : "",
      }}
    >
      <Container className="text-muted">
        <ul className="list-inline small d-flex flex-row">
          <li className="list-inline-item d-flex flex-row">
            &copy; {new Date().getFullYear()} -{" "}
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-muted"
            >
              {companyName}
            </a>
            <TextWithTooltip text={` - v${packageJson.version.split("+")[0]}`}
              id="version"
              tooltip={packageJson.version}
              placement="top"
              style={{
                fontSize: "unset"
              }}
            />
  
          </li>
          <li className="list-inline-item ml-3">
            <a href={getSupportEmail().link} className="text-muted">
              {t("footer.support")}
            </a>
          </li>
        </ul>
      </Container>
    </footer>
  );
};

export default Footer;
