const ptBrSharingTranslations = {
  homeSharing: {
    graphics: "Gráfico",
    dataTable: "Tabela",
  },
  formParams: {
    params: "Parâmetros",
    pagaInit: "Página Inicial",
    plant: "Planta",
    periodEnd: "Periodo Final",
    periodInit: "Periodo Inicial",
    enterprise: "Empresa",
    unitConsumption: "Unidades de Consumo",
    invoiceValue: "Valor de Consumo",
    view: "Visualizar",
    noUcs: "Nenhuma unidade de consumo encontrada",
    billWarning: "Valor de consumo não informado"
  },
  sharingTable: {
    uc: "UC",
    totalPeakConsumption: "Consumo Total Ponta",
    totalOffPeakConsumption: "Consumo Total Fora de Ponta",
    totalConsumption: "Consumo Total",
    costPeak: "Custo Ponta",
    costOffPeak: "Custo Fora de Ponta",
    percentKwh: "Percentual Kwh",
    costTotal: "Custo Total",
  }
};

export default ptBrSharingTranslations;
