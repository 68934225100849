import axios from "axios";

export const requestChart = async (params) => {
  const requestChart = await axios.get("/v2/data-explorer/readChart", {
    params,
  });
  return requestChart.data;
};

export const requestTable = async (params) => {
  const requestTable = await axios.get("/v2/data-explorer/readTable", {
    params,
  });
  return requestTable.data;
};

export const requestDataExplorerInCsv = async (params) => {
  const requestTable = await axios
    .get("/v2/data-explorer/writeDataExplorerInCsv", {
      params,
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "seu_arquivo.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      return {
        error: false,
        message: "Arquivo gerado com sucesso!",
      }
      
    })
    .catch((error) => {
      console.error("Ocorreu um erro no envio do arquivo:", error);
      return {
        error: true,
        message: error.response.data.message || "Ocorreu um erro no envio do arquivo",
      }
    })

  return requestTable;
};
