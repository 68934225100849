import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Breadcrumb, BreadcrumbItem,
  Card, CardTitle, CardBody, CardImg,
  Container,
  Row, Col,
  Spinner
} from "reactstrap";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { toastr } from "react-redux-toastr";

import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";
// import '../../igCards';

const SelectCardType = () => {
  const { t } = useTranslation();
  const [cards, setCards] = useState([]);

  const [isLoading, setLoading] = useState(true);

  
  
  useEffect(() => {
    axios.get('cardTypeSearch/')
      .then(response => {
        setCards(response.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toastr.warning('Erro!', "Erro ao buscar cards.");
        setLoading(false);
      });
  }, []);

  return <Container>
    <Header>
      <HeaderTitle>{t('dashboards.cardPanel')}</HeaderTitle>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/">{t('dashboards.homePage')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/Dashboards">Dashboards</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('dashboards.cardSelection')}</BreadcrumbItem>
      </Breadcrumb>
    </Header>

    <Card>
      <CardBody className="text-center mt-4 mb-3">
        {t('dashboards.addCardType')}
      </CardBody>
    </Card>

    {(isLoading || !cards.length)
      ? <div className="text-center m-4">
        <Spinner color="primary" />
      </div>
      : <Row className="d-flex justify-content-start" xs={1} sm={2} md={3} lg={4} xl={5}>
        {cards.map((card, i) => (
          <Col key={i}>
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "90%",
              }}
              className="text-center"
            >
              <CardTitle className="text-center mb-3 pt-3" tag="h5">
                {t(`dashboards.${card.type}`)}
              </CardTitle>
              <CardImg alt="Card image cap" src={card.image} top width="100%" height={"100%"} />
              <CardBody style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
              }}>
                <Link to={`/newCard/${card.type}`}>
                  <Button type="button" className="mr-1" color="primary">
                    {t('dashboards.addCard')}
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    }

  </Container>
}

export default SelectCardType;