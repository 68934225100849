const ptBrClimateTranslations = {
  climate: {
    true: "Ligar",
    false: "Desligar",
    cooling: "Resfriando a",
    heating: "Aquecendo a",
    fanOnly: "Ventilando",
    drying: "Secando",
    autoMode: "Automático a",
    errorSending: "Erro ao enviar comando",
    successSending: "Comando enviado com sucesso",
    error: "Erro!",
    success: "Sucesso!",
    cool: "Resfriamento",
    heat: "Aquecimento",
    fan: "Ventilação",
    dry: "Secagem",
    auto: "Automático",
    quiet: "Silencioso",
    low: "Baixa",
    medium: "Média",
    high: "Alta",
    strong: "Turbo",
    syncState: "Sincronizar Estado do A/C",
    syncOff: "O A/C está desligado",
    syncOn: "O A/C está ligado",
    deviceNotRegistered: "Dispositivo não cadastrado",
    climate: "Climatização"
  },
}

export default ptBrClimateTranslations;