import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTemperatureHigh } from "@fortawesome/free-solid-svg-icons";

const Temperature = ({ data, state, allData }) => {
  const { t } = useTranslation();

  var temperature = (data.temperature)


  return (
    <div className="w-100">
      <Row >
        <Col sm="12" style={{paddingRight:"0px"}}>
          <Card style={{minWidth: "135px", maxHeight:"100px", height: "100px", marginBottom: "0px", border: state, borderStyle:"solid", background:"#dde0e5"}}>
            <CardBody style={{padding: "15px", paddingTop: "10px"}}>
              <Row>
                <Col className="mt-0" style={{paddingRight: "0px"}}>
                  <CardTitle tag="h5">{t('solar.temperature')}</CardTitle>
                </Col>
                <Col className="col-auto" style={{paddingLeft:"0px"}}>
                  <div>
                    <FontAwesomeIcon 
                        icon={faTemperatureHigh} 
                        style={{fontSize:"17px"}}
                        />
                  </div>
                </Col>
              </Row>
              {(allData)
                ? <h1 className="display-5 text-center" style={{fontSize:"17px", fontWeight:"500", paddingTop:"22px"}}>{temperature} °C</h1>
                : <div className="text-center mb-4"><Spinner /></div>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}   

export default Temperature;