import axios from "axios";
import { toastr } from "react-redux-toastr";

export const readUc = async (deviceId) => {
  try {
    const response = await axios.get(`/v2/energy/readUc?deviceId=${deviceId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


export const readUcData = async (deviceId, monthRef) => {

  try {
    const response = await axios.get(`/v2/energy/readUcData?deviceId=${deviceId}&monthRef=${monthRef}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const readBarChartConsumptionUc = async (dvcId, start, end) => {
  try {
    const response = await axios.get(
      `/v2/energy/readBarChartConsumptionUc?dvcId=${dvcId}&start=${start}&end=${end}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const readBarChartDemandUc = async (dvcId, start, end) => {
  try {
    const response = await axios.get(
      `/v2/energy/readBarChartDemandUc?dvcId=${dvcId}&start=${start}&end=${end}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const readPowerFactorChartUc = async (dvcId, start, end) => {
  try {
    const response = await axios.get(
      `/v2/energy/readChartPowerFactorUc?dvcId=${dvcId}&start=${start}&end=${end}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateConsumerUnit = async (jsonData) => {
  try {
    const response = await axios.put(`/v2/energy/updateUcData`, jsonData);
    toastr.success(response.data.message);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const readDeviceInfo = async (deviceId) => {
  try {
    const { data, status } = await axios.get(`/v2/energy/readDeviceInfo`, {
      params: { deviceId },
    });
    return { data, status };
  } catch (error) {
    console.error(error);
  }
};



