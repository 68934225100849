import React from "react";
import { Page, Document, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
//TODO: Solução temporária, conseguir uma melhor solução quando tiver tempo
const igoalLogo = window.location.origin + '/igoal-logo.png';

const PdfBillDocument = ({
  data,
  barChartDemandScreenshot, 
  barChartConsumptionScreenshot
}) => {

  const { t } = useTranslation();

  const dateRef  = {
    refMonth: data.reportBill.refMonthData.monthRef.split('-').join('/'),
    previousMonth: data.reportBill.previousMonthData.monthRef.split('-').join('/')
  }
  const total = data.reportBill.refMonthData.total?.toLocaleString(t('reportBill.localeString'), { currency: "BRL" })

  const distributor = {
    logo: data.reportBill.refMonthData.distributorLogo || igoalLogo,
    legalName: data.reportBill.refMonthData.distributorLegalName ? data.reportBill.refMonthData.distributorLegalName.toUpperCase() : null,
    address: data.reportBill.refMonthData.distributorAddress,
    cnpj: data.reportBill.refMonthData.distributorCnpj,
  };

  const unitConsumer = {
    ucNumber: data.reportBill.refMonthData.device
  };

  const customer = {
    name: data.reportBill.refMonthData.customerName ? data.reportBill.refMonthData.customerName.toUpperCase() : null,
    address: data.reportBill.refMonthData.customerAddress ? `${t('reportBill.customerAddress')}: ${data.reportBill.refMonthData.customerAddress}` : null,
    neighborhood: data.reportBill.refMonthData.customerNeighborhood ? `${t('reportBill.customerNeighborhood')}: ${data.reportBill.refMonthData.customerNeighborhood}` : null, 
    location: data.reportBill.refMonthData.customerCity ? `${data.reportBill.refMonthData.customerCity} - ${data.reportBill.refMonthData.customerState} - ${t('reportBill.customerZipCode')}: ${data.reportBill.refMonthData.customerZipCode}` : null,
    tariffType: data.reportBill.refMonthData.tariffType,
    subgroup: data.reportBill.refMonthData.subgroup,
    peakContractedDemand: data.reportBill.refMonthData.peakContractedDemand,
    offPeakContractedDemand: data.reportBill.refMonthData.offPeakContractedDemand 
  };

  const dataReading = {
    emission: data.reportBill.emission,
    billedDays: data.reportBill.refMonthData.billedDays,
    device: data.reportBill.refMonthData.device,
    maxPeakDemand: data.reportBill.refMonthData.maxPeakDemand,
    maxOffPeakDemand: data.reportBill.refMonthData.maxOffPeakDemand,
    exceededPeakDemand: data.reportBill.refMonthData.exceededPeakDemand || t('reportBill.noData'),
    exceededOffPeakDemand: data.reportBill.refMonthData.exceededOffPeakDemand || t('reportBill.noData'),
  };

  const productsActualMonth = {
    refData: `${data.reportBill.refMonthData.billedDays}/${dateRef.refMonth}`,
    peakDemand: data.reportBill.refMonthData.maxPeakDemand,
    offPeakDemand: data.reportBill.refMonthData.maxOffPeakDemand,
    tusdPeakConsumption: data.reportBill.refMonthData.totalPeakConsumption,
    tusdOffPeakConsumption: data.reportBill.refMonthData.totalOffPeakConsumption,
    systemUsageDistPeak: data.reportBill.refMonthData.maxPeakDemand,
    systemUsageDistOffPeak: data.reportBill.refMonthData.maxOffPeakDemand
  };

  const productsPreviousMonthData = {
    refData: `${data.reportBill.previousMonthData.billedDays}/${dateRef.previousMonth}`,
    peakDemand: data.reportBill.previousMonthData.maxPeakDemand,
    offPeakDemand: data.reportBill.previousMonthData.maxOffPeakDemand,
    tusdPeakConsumption: data.reportBill.previousMonthData.totalPeakConsumption,
    tusdOffPeakConsumption: data.reportBill.previousMonthData.totalOffPeakConsumption,
    systemUsageDistPeak: data.reportBill.previousMonthData.maxPeakDemand,
    systemUsageDistOffPeak: data.reportBill.previousMonthData.maxOffPeakDemand
  };

  const description = {
    tusdPeak: data.reportBill.refMonthData.totalPeakConsumption,
    taxTusdPeak: data.reportBill.refMonthData.taxTusdPeakConsumption,
    valueTusdPeak: ((data.reportBill.refMonthData.totalPeakConsumption * data.reportBill.refMonthData.taxTusdPeakConsumption)).toFixed(2),
    tusdOffPeak: data.reportBill.refMonthData.totalOffPeakConsumption,
    taxTusdOffPeak: data.reportBill.refMonthData.taxTusdOffPeakConsumption,
    valueTusdOffPeak: ((data.reportBill.refMonthData.totalOffPeakConsumption * data.reportBill.refMonthData.taxTusdOffPeakConsumption)).toFixed(2),
    tePeak: data.reportBill.refMonthData.totalPeakConsumption,
    taxTePeak: data.reportBill.refMonthData.taxTePeakConsumption,
    valueTePeak: ((data.reportBill.refMonthData.totalPeakConsumption * data.reportBill.refMonthData.taxTePeakConsumption)).toFixed(2),
    teOffPeak: data.reportBill.refMonthData.totalOffPeakConsumption,
    taxTeOffPeak: data.reportBill.refMonthData.taxTeOffPeakConsumption,
    valueTeOffPeak: ((data.reportBill.refMonthData.totalOffPeakConsumption * data.reportBill.refMonthData.taxTeOffPeakConsumption)).toFixed(2),
    systemUsageDistPeak: data.reportBill.refMonthData.peakContractedDemand,
    systemUsageDistOffPeak: data.reportBill.refMonthData.offPeakContractedDemand,
    taxSystemUsageDistPeak: data.reportBill.refMonthData.taxTusdPeakDemand,
    taxSystemUsageDistOffPeak: data.reportBill.refMonthData.taxTusdOffPeakDemand,
    valueSystemUsageDistPeak: (data.reportBill.refMonthData.systemUsageDistPeak),
    valueSystemUsageDistOffPeak: (data.reportBill.refMonthData.systemUsageDistOffPeak),
    subtotal: data.reportBill.refMonthData.subtotal
  };

  const taxes = {
    base: data.reportBill.refMonthData.total,
    taxRateIcms: data.reportBill.refMonthData.taxRateIcms || t('reportBill.noData'),
    taxRatePisPasep: data.reportBill.refMonthData.taxRatePisPasep || t('reportBill.noData'),
    taxRateCofins: data.reportBill.refMonthData.taxRateCofins || t('reportBill.noData'),
    valueIcms: data.reportBill.refMonthData.taxRateIcms
        ? ((data.reportBill.refMonthData.total * data.reportBill.refMonthData.taxRateIcms) / 100).toFixed(2)
        : t('reportBill.noData'),
    valuePisPasep: data.reportBill.refMonthData.taxRatePisPasep
        ? ((data.reportBill.refMonthData.total * data.reportBill.refMonthData.taxRatePisPasep) / 100).toFixed(2)
        : t('reportBill.noData'),
    valueCofins: data.reportBill.refMonthData.taxRateCofins
        ? ((data.reportBill.refMonthData.total * data.reportBill.refMonthData.taxRateCofins) / 100).toFixed(2)
        : t('reportBill.noData')
  };

  const footer = {
    logo: igoalLogo,
    date: data.reportBill.ref,
    totalBill: total,
  };

  
  const styles = StyleSheet.create({

    // Layout
    page: {
      paddingTop: 20,
      paddingHorizontal: 20,
      backgroundColor: "white",
    },
    
    row: {
      flexDirection: 'row',
      marginBottom: 10,
    },
    
    distributor: {
      flex: '0 0 80%',
      padding: '0 5px',
      margin: '0 5px 0 0',
      flexDirection: 'row'
    },

    distributorData: {
      justifyContent: 'center',
      padding: '0 80px 0 30px'
    },
    
    unitConsumer: {
      flex: '0 0 20%',
      border: '2px solid #c7ccea',
      padding: '5px',
      textAlign: 'center',
      backgroundColor: 'yellow',
      justifyContent: 'center',
      fontSize: 12
    },

    customer: {
      flex: 1,
      border: '2px solid #c7ccea',
      padding: '5px',
      margin: '0 5px'
    },

    dataReading: {
      flex: 1,
      border: '2px solid #c7ccea',
      padding: '5px',
      margin: '0 5px'
    },

    products: {
      flex: 1,
      border: '2px solid #c7ccea',
      padding: '5px',
      margin: '0 5px'
    },

    description: {
      flex: 1,
      border: '2px solid #c7ccea',
      padding: '5px',
      margin: '0 5px'
    },

    barChartDemand: {
      flex: 1,
      border: '2px solid #c7ccea',
      padding: '5px',
      margin: '0 5px'
    },

    barChartConsumption: {
      flex: 1,
      border: '2px solid #c7ccea',
      padding: '5px',
      margin: '0 5px'
    },

    taxes: {
      flex: '0 0 60%',
      border: '2px solid #c7ccea',
      padding: '5px',
      margin: '0 5px'
    },

    refMonthData: {
      flex: '0 0 20%',
      border: '2px solid #c7ccea',
      padding: '5px',
      margin: '0 5px',
      fontSize: 12,
      justifyContent: 'center',
      alignItems: 'center',
    },

    total: {
      flex: '0 0 20%',
      border: '2px solid #c7ccea',
      padding: '5px',
      margin: '0 5px',
      fontSize: 12,
      justifyContent: 'center',
      alignItems: 'center',
    },

    footer: {
      flex: '0 0 70%',
      padding: '0 5px',
      margin: '15px 5px 0 0',
      flexDirection: 'row'
    },

    footerData: {
      justifyContent: 'center',
      padding: '0 15px'
    },

    footerText: {
      fontSize: '7px',
      margin: '2px 0',
    },

    footerTotalBill: {
      flex: '0 0 30%',
      textAlign: 'center',
      backgroundColor: 'whitesmoke',
      justifyContent: 'center',
      fontSize: 12,
      margin: '15px 5px 0 0'
    },

    // Elementos Gerais

    text: {
      fontSize: '9px',
      margin: '2px 0',
    },

    detachText: {
      fontSize: '13px',
      fontWeight: 'bold',
      color: '#320352',
      alignSelf: 'center',
      marginTop: '5px'
    },

    title: {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#320352',
      margin: '4px 0'
    },

    logo: {
      height: 'auto',
      width: '120px',
      background: 'no-repeat',
      backgroundSize: 'contain'
    },

    // Tabela
    
    headerTable: {
      fontSize: '8px',
      padding: 2,
    },

    tr: {
      flexDirection: 'row'
    },

    td: {
      flex: 1,
      padding: 2,
    },

    cell: {
      fontSize: '7px'
    },
    
  });
    return (
      <Document>
        <Page style={styles.page} size="A4" orientation="portrait">
          <View style={styles.row}>
              
              {/* Distribuidora */}
              <View style={styles.distributor}>
                <View>
                  <Image style={styles.logo} src={distributor.logo} />
                </View>
                <View style={styles.distributorData}>
                  <Text style={styles.text}>{distributor.legalName}</Text>
                  <Text style={styles.text}>{distributor.address}</Text>
                  <Text style={styles.text}>{distributor.cnpj}</Text>
                </View>
              </View>

              {/* Número da UC */}
              <View style={styles.unitConsumer}>
                  <Text>{t('reportBill.ucNumber')}</Text>
                  <Text style={styles.detachText}>{unitConsumer.ucNumber}</Text>
              </View>

          </View>

          <View style={styles.row}>
              
              {/* Cliente */}
              <View style={styles.customer}>
                <View style={styles.title}>
                    <Text>{t('reportBill.customerTitle')}</Text>
                </View>
                <View style={styles.customerData}>
                    <Text style={styles.text}>{customer.name}</Text>
                    <Text style={styles.text}>{customer.address}</Text>
                    <Text style={styles.text}>{customer.neighborhood}</Text>
                    <Text style={styles.text}>{customer.location}</Text>
                    <Text style={styles.text}>{t('reportBill.tariffType')}: {customer.tariffType}</Text>
                    <Text style={styles.text}>{t('reportBill.subgroup')}: {customer.subgroup}</Text>
                    <Text style={styles.text}>{t('reportBill.peakContractedDemand')}: {customer.peakContractedDemand}</Text>
                    <Text style={styles.text}>{t('reportBill.offPeakContractedDemand')}: {customer.offPeakContractedDemand}</Text>
                </View>
              </View>

              {/* Dados Leitura */}
              <View style={styles.dataReading}>
                <View style={styles.title}>
                    <Text>{t('reportBill.dataReading')}</Text>
                </View>
                <View style={styles.customerData}>
                    <Text style={styles.text}>{t('reportBill.emission')}: {dataReading.emission}</Text>
                    <Text style={styles.text}>{t('reportBill.billedDays')}: {dataReading.billedDays}</Text>
                    <Text style={styles.text}>{t('reportBill.device')}: {dataReading.device}</Text>
                    <Text style={styles.text}>{t('reportBill.maxPeakDemand')}: {dataReading.maxPeakDemand}</Text>
                    <Text style={styles.text}>{t('reportBill.maxOffPeakDemand')}: {dataReading.maxOffPeakDemand}</Text>
                    <Text style={styles.text}>{t('reportBill.exceededPeakDemand')}: {dataReading.exceededPeakDemand}</Text>
                    <Text style={styles.text}>{t('reportBill.exceededOffPeakDemand')}: {dataReading.exceededOffPeakDemand}</Text>
                </View>
              </View>

          </View>

          <View style={styles.row}>
              
              {/* Tabela Produtos */}
              <View style={styles.products}>
                <View style={styles.tr}>
                    <View style={styles.td}>
                      <View style={styles.headerTable}>
                        <Text>{t('reportBill.product')}</Text>
                      </View>
                    </View>
                    <View style={styles.td}>
                      <View style={styles.headerTable}>
                        <Text>{t('reportBill.const')}</Text>
                      </View>
                    </View>
                    <View style={styles.td}>
                      <View style={styles.headerTable}>
                        <Text>{t('reportBill.previousReading')}</Text>
                        <Text style={styles.cell}>{productsPreviousMonthData.refData}</Text>
                      </View>
                    </View>
                    <View style={styles.td}>
                      <View style={styles.headerTable}>
                        <Text>{t('reportBill.actualReading')}</Text>
                        <Text style={styles.cell}>{productsActualMonth.refData}</Text>
                      </View>
                    </View>
                    <View style={styles.td}>
                      <View style={styles.headerTable}>
                        <Text>{t('reportBill.quantity')}</Text>
                      </View>
                    </View>
                  </View>

                  {/* Demanda Ponta */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.peakDemand')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>1.0000</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsPreviousMonthData.peakDemand}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsActualMonth.peakDemand}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsActualMonth.peakDemand}</Text>
                        </View>
                      </View>
                  </View>

                  {/* Demanda Fora Ponta */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.offPeakDemand')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>1.0000</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsPreviousMonthData.offPeakDemand}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsActualMonth.offPeakDemand}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsActualMonth.offPeakDemand}</Text>
                        </View>
                      </View>
                  </View>

                  {/* Consumo Ponta */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.peakConsumption')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>1.0000</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsPreviousMonthData.tusdPeakConsumption}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsActualMonth.tusdPeakConsumption}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsActualMonth.tusdPeakConsumption}</Text>
                        </View>
                      </View>
                  </View>

                  {/* Consumo Fora Ponta */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.offPeakConsumption')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>1.0000</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsPreviousMonthData.tusdOffPeakConsumption}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsActualMonth.tusdOffPeakConsumption}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsActualMonth.tusdOffPeakConsumption}</Text>
                        </View>
                      </View>
                  </View>

                  {/* Uso do Sistema Dist.Pta */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.systemUsageDistOffPeak')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>1.0000</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsPreviousMonthData.systemUsageDistPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsActualMonth.systemUsageDistPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsActualMonth.systemUsageDistPeak}</Text>
                        </View>
                      </View>
                  </View>

                  {/* Uso do Sistema Dist.F.Pta */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.systemUsageDistPeak')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>1.0000</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsPreviousMonthData.systemUsageDistOffPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsActualMonth.systemUsageDistOffPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{productsActualMonth.systemUsageDistOffPeak}</Text>
                        </View>
                      </View>
                  </View>

              </View>

              {/* Tabela Descrição */}
              <View style={styles.description}>
                <View style={styles.tr}>
                    <View style={styles.td}>
                      <View style={styles.headerTable}>
                        <Text>{t('reportBill.description')}</Text>
                      </View>
                    </View>
                    <View style={styles.td}>
                      <View style={styles.headerTable}>
                        <Text>{t('reportBill.quantity')}</Text>
                      </View>
                    </View>
                    <View style={styles.td}>
                      <View style={styles.headerTable}>
                        <Text>{t('reportBill.price')}</Text>
                      </View>
                    </View>
                    <View style={styles.td}>
                      <View style={styles.headerTable}>
                        <Text>{t('reportBill.value')}</Text>
                      </View>
                    </View>
                  </View>

                  {/* TUSD ENERGIA PONTA */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.tusdPeakConsumption')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.tusdPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.taxTusdPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.valueTusdPeak}</Text>
                        </View>
                      </View>
                  </View>

                  
                  {/* TUSD ENERGIA FORA PONTA */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.tusdOffPeakConsumption')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.tusdOffPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.taxTusdOffPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.valueTusdOffPeak}</Text>
                        </View>
                      </View>
                  </View>

                  {/* TE ENERGIA PONTA */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.tePeakConsumption')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.tePeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.taxTePeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.valueTePeak}</Text>
                        </View>
                      </View>
                  </View>

                  
                  {/* TE ENERGIA FORA PONTA */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.teOffPeakConsumption')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.teOffPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.taxTeOffPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.valueTeOffPeak}</Text>
                        </View>
                      </View>
                  </View>

                  {/* USO SISTEMA DISTR.PTA */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.systemUsageDistPeakKw')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.systemUsageDistPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.taxSystemUsageDistPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.valueSystemUsageDistPeak}</Text>
                        </View>
                      </View>
                  </View>

                  {/* USO SISTEMA DISTR.F.PTA */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.systemUsageDistOffPeakkw')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.systemUsageDistOffPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.taxSystemUsageDistOffPeak}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.valueSystemUsageDistOffPeak}</Text>
                        </View>
                      </View>
                  </View>

                  {/* SUBTOTAL */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.subtotal')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text></Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text></Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{description.subtotal}</Text>
                        </View>
                      </View>
                  </View>

              </View>

          </View>

          {/* <View style={styles.row}>
          </View> */}

          <View style={styles.row}>
              {/* Gráfico de Demanda */}
              <View style={styles.barChartDemand}>
                <View style={styles.title}>
                    <Text>{t('reportBill.barChartDemand')}</Text>
                </View>
                <Image src={barChartDemandScreenshot} />
              </View>
              {/* Gráfico de Consumo */}
              <View style={styles.barChartConsumption}>
                <View style={styles.title}>
                    <Text>{t('reportBill.barChartConsumption')}</Text>
                </View>
                <Image src={barChartConsumptionScreenshot} />
              </View>
          </View>

          <View style={styles.row}>
              
              {/* Tabela Impostos */}
              <View style={styles.taxes}>
                <View style={styles.tr}>
                    <View style={styles.td}>
                      <View style={styles.headerTable}>
                        <Text></Text>
                      </View>
                    </View>
                    <View style={styles.td}>
                      <View style={styles.headerTable}>
                        <Text>{t('reportBill.base')}</Text>
                      </View>
                    </View>
                    <View style={styles.td}>
                      <View style={styles.headerTable}>
                        <Text>{t('reportBill.taxRate')}</Text>
                      </View>
                    </View>
                    <View style={styles.td}>
                      <View style={styles.headerTable}>
                        <Text>{t('reportBill.value')}</Text>
                      </View>
                    </View>
                  </View>

                  {/* ICMS */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.taxRateIcms')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{taxes.base}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{taxes.taxRateIcms}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{taxes.valueIcms}</Text>
                        </View>
                      </View>
                  </View>


                  {/* PIS/PASEP */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.taxRatePisPasep')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{taxes.base}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{taxes.taxRatePisPasep}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{taxes.valuePisPasep}</Text>
                        </View>
                      </View>
                  </View>

                  {/* COFINS */}
                  <View style={styles.tr}>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                            <Text>{t('reportBill.taxRateCofins')}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{taxes.base}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                          <Text>{taxes.taxRateCofins}</Text>
                        </View>
                      </View>
                      <View style={styles.td}>
                        <View style={styles.cell}>
                        <Text>{taxes.valueCofins}</Text>
                        </View>
                      </View>
                  </View>
              </View>

              {/* Mês de Referência */}
              <View style={styles.refMonthData}>
                  <Text>{t('reportBill.refMonthData')}</Text>
                  <Text style={styles.detachText}>{dateRef.refMonth}</Text>
              </View>

              {/* Valor Total */}
              <View style={styles.total}>
                  <Text>{t('reportBill.total')}</Text>
                  <Text style={styles.detachText}>{total}</Text>
              </View>

          </View>

          {/* Rodapé */}
          <View style={styles.row}>

              <View style={styles.footer}>
                <View>
                  <Image style={styles.logo} src={footer.logo} />
                </View>
                <View style={styles.footerData}>
                  <Text style={styles.text}>{t('reportBill.date')} {footer.date}</Text>
                  <Text style={styles.footerText}>{t('reportBill.generate')}</Text>
                  <Text style={styles.footerText}>suporte@igoaltecnologia.com</Text>
                </View>
              </View>

              <View style={styles.footerTotalBill}>
                  <Text>{t('reportBill.totalBill')} {t('reportBill.currency')} {footer.totalBill}</Text>
              </View>

          </View>

    
        </Page>
      </Document> 
    )

}

export default PdfBillDocument;
