import { Carousel } from "antd";
import React, { useRef } from "react";
import { WaterTable } from "../carouselComponents/components/WaterTable";
import ConsumChart from "../carouselComponents/ConsumChart";

export default function CarouselGeneral({ generalDataConsumption }) {
  const ref = useRef(null);

  const handleNext = () => {
    ref.current.next();
  };

  const handlePrev = () => {
    ref.current.prev();
  };

  return (
    <div className="carousel-general-water">
      <button
        onClick={handlePrev}
        className="button-carousel button-carousel-left"
      >
        <span aria-hidden="true" class="carousel-control-prev-icon"></span>
      </button>
      <button
        onClick={handleNext}
        className="button-carousel button-carousel-right"
      >
        <span aria-hidden="true" class="carousel-control-next-icon"></span>
      </button>
      <Carousel ref={ref} dots={true} infinite={true} draggable={false}>
        <div>
          <div className="info-container">
            <ConsumChart
              registers={generalDataConsumption.generalConsumptionPerDay}
              dashName={"general"}
            />
          </div>
        </div>
        <div>
          <div className="info-container">
            <WaterTable data={generalDataConsumption.registers} />
          </div>
        </div>
      </Carousel>
    </div>
  );
}
