export const COLOR_CHARTS = {
  peak: "#FFA352",
  offPeak: "#65AD65",
  contractPeak: "#FF7700",
  contractOffPeak: "#468646",
};

export const COLOR_SOLAR = {
  // Comparação Mensal de Energia
  energyGenerated: "#ffa352", // Verde oliva suave
  energyForecasted: "#65ad65", // Laranja opaco
  energyAccumulated: "#ffa352", // Azul acinzentado

  // Geração x Irradiação do Dia Atual
  generationKwh: "#BF5D5A", // Marrom avermelhado opaco
  irradiationWm2: "#E98C45", // Laranja suave

  // Disponibilidade Geral da Planta
  availabilityVeryPoor: "#B23A48", // Vermelho opaco
  availabilityPoor: "#D9895E", // Laranja queimado
  availabilityAverage: "#D0A555", // Amarelo suave
  availabilityGood: "#8AA62F", // Verde oliva mais claro
  availabilityExcellent: "#6B8E23", // Verde musgo

  // Energia do Dia Atual
  dailyEnergyGenerated: "#DAA520", // Amarelo dourado

  // Indicadores de Desempenho
  performanceIrradiation: "#C9A449", // Amarelo dourado suave
  performancePvSyst: "#546E7A", // Azul acinzentado
  performanceDelta: "#8B8B8B", // Cinza médio suave

  veryLowProduction: '#B23A48', // Vermelho opaco - Indica produção muito baixa, alerta
  lowProduction: '#D9895E', // Laranja queimado - Indica produção baixa, necessidade de atenção
  moderateProduction: '#DAA520', // Dourado-claro - Produção moderada, equilibrada
  highProduction: '#8AA62F', // Verde oliva claro - Produção alta, bom desempenho
  veryHighProduction: '#228B22', // Verde-escuro profundo - Produção excelente, ótimo desempenho

};
