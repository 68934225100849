import React, { useMemo } from "react";
import { Row, Col } from "reactstrap";

import DataCard from "../DataCard";
import { useTranslation } from "react-i18next";
export const MeterInfo = ({ meterInfo }) => {
  const { t } = useTranslation();
  const leituraAnterior = useMemo(() => {
    if (
      meterInfo &&
      meterInfo.leituraAnterior &&
      meterInfo.leituraAnterior.length > 0
    ) {
      return {
        value:
          meterInfo?.leituraAnterior[0]?.consumption * (meterInfo.gain ?? 1) +
          meterInfo?.leituraInicial,
        timestamp: meterInfo?.leituraAnterior[0].timestamp ?? null,
      };
    }
    return {
      value: t("none"),
      timestamp: null,
    };
  }, [meterInfo, t]);
  const leituraAtual = useMemo(() => {
    if (meterInfo && meterInfo.leituraAtual) {
      return {
        value: meterInfo.leituraAtual.consumption * (meterInfo.gain ?? 1) + meterInfo?.leituraInicial,
        timestamp: meterInfo.leituraAtual.timestamp,
      };
    }
    if (
      meterInfo &&
      meterInfo.leituraAnterior &&
      meterInfo.leituraAnterior.length > 0
    ) {
      return {
        value:
          meterInfo?.leituraAnterior[0]?.consumption * (meterInfo.gain ?? 1) +
          meterInfo?.leituraInicial,
        timestamp: meterInfo?.leituraAnterior[0].timestamp ?? null,
      };
    }
    return {
      value: t("none"),
      timestamp: null,
    };
  }, [meterInfo, t]);

  return (
    <div className="mx-0">
      <Row>
        <Col>
          <DataCard
            title={t("water.initialRead")}
            value={meterInfo?.leituraInicial ?? t("none")}
            unit="m³"
            dark
          />
        </Col>
        <Col>
          <DataCard
            title={t("water.previousValue")}
            value={leituraAnterior.value}
            unit="m³"
            dark
            timestamp={leituraAnterior.timestamp}
          />
        </Col>
        <Col>
          <DataCard
            title={t("water.currentValue")}
            value={leituraAtual.value}
            unit="m³"
            dark
            timestamp={leituraAtual.timestamp}
          />
        </Col>
      </Row>
    </div>
  );
};
