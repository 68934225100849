import BootstrapTable from "react-bootstrap-table-next";
import {
    Modal, ModalHeader, ModalBody,
} from "reactstrap";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faCaretUp,
    faSort,
} from "@fortawesome/free-solid-svg-icons";
// import { ModalTitle } from "react-bootstrap";




const ModalAlarmData = ({ open, toggle, tableReload, alarmData}) => {
    const { t } = useTranslation();

    const comparisonCols = [
        { // ID (oculto)
            dataField: "id",
            text: `${t('alarms.id')}`,
            sort: true,
            hidden: true,
            isKey: true
          },
        {//nome do dispositivo
            dataField: "formDev",
            text: t('alarms.deviceName'),
            sort: true,
            headerStyle: {
                minWidth: "100px"
            },
            formatter: cell => cell.deviceName
        },
        { // variavel
            dataField: "formVar",
            text: t('alarms.varName'),
            headerStyle: {
                minWidth: "100px"
            },
            formatter: cell => cell.varName
        },
        {// Tipo de comparaçao
            dataField: "comparisonType",
            text: "Tipo",
            headerStyle:{
                minWidth:"50px"
            },
            formatter:cell =>(cell === '0')
            ? 'A'
            : 'D'
        },
        { // Limite Superior
            dataField: "formThrHi",
            text: t('alarms.highLimit'),
            headerStyle: {
                minWidth: "50px"
            },
            // formatter: (cell, row) => (cell === null)
            //     ? '-'
            //     : cell + ((row.varUnit) ? ' ' + row.varUnit : null)
        },
        { // limite low
            dataField: "formThrLo",
            text: t('alarms.lowLimit'),
            headerStyle: {
                minWidth: "50px"
            },
           
        },
    ]
    const sortIcon = {
        sortCaret: (order) => (!order)
            ? <FontAwesomeIcon
                className={'fa-xs ml-1'}
                icon={faSort}
            />
            : (order === 'asc')
                ? <FontAwesomeIcon
                    className={'fa-xs ml-1'}
                    icon={faCaretDown}
                />
                : <FontAwesomeIcon
                    className={'fa-xs ml-1'}
                    icon={faCaretUp}
                />
    }

    return (
        <Modal
            isOpen={open}
            toggle={toggle}
            size={"lg"}
        >
            <ModalHeader>            
            </ModalHeader>

             {/* TABELA DE COMPARAÇOES ATIVAS */}
            <ModalBody>
                <BootstrapTable
                    condensed
                    keyField={'id'}
                    bordered={false}
                    striped={true}
                    columns={comparisonCols}
                    data={JSON.parse(alarmData.multipleConfig)}
                    sort={sortIcon}
                />
            </ModalBody>
        </Modal>
    )
}
export default ModalAlarmData