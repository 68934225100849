//NOTE: importações não utilizadas vão ser utilizadas na próxima refatoração

import {
  FilePdfOutlined,
  FilterOutlined,
  HddOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { DatePicker, Menu, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Spinner } from "reactstrap";
import { useWaterModuleAPI } from "../WaterProvider";
import ModalWaterReport from "./modals/ModalWaterReport";
import ModalWaterSettings from "./modals/ModalWaterSettings";
import ModalWaterUCs from "./modals/ModalWaterUCs";

const WaterTools = ({ data, projectId, loading, setLoading, adminUser }) => {
  const { t } = useTranslation();
  const {
    selectedMonthRef,
    handleChangeDate,
    searchReport,
    setSearchReport,
    generalData,
  } = useWaterModuleAPI();

  const [openModalReport, setOpenModalReport] = useState(false);
  const [openModalUCs, setOpenModalUCs] = useState(false);
  const [openModalSettings, setOpenModalSettings] = useState(false);

  function toggleModalReport() {
    setOpenModalReport(!openModalReport);
  }

  function toggleModalUCs() {
    setOpenModalUCs(!openModalUCs);
  }

  function toggleModalSettings() {
    setOpenModalSettings(!openModalSettings);
  }

  const processedAt = generalData?.processedAt
    ? new Date(generalData?.processedAt * 1000)
    : new Date();

  const disabledDate = (current) => {
    // Impede a seleção de meses após o mês atual
    return current && current > moment().endOf("month");
  };
  return (
    <Card className="header-water-gas-menus">
      <Typography.Text>
        Processado em: {moment(processedAt).format("llll")}
      </Typography.Text>

      <DatePicker
        value={moment(selectedMonthRef, "YYYY-MM-DD")}
        onChange={(date) => {
          if (!date) return;
          handleChangeDate(new Date(date));
        }}
        format={"MM/YYYY"}
        picker="month"
        clearIcon={null}
        disabledDate={disabledDate}
      />
      <div>
        <Menu mode="horizontal">
          {adminUser && (
            <Menu.SubMenu title={t("devices.filter")} icon={<FilterOutlined />}>
              <Menu.Item
                key="filter"
                onClick={() => setSearchReport(!searchReport)}
              >
                {t("water.searchByDataConfig")}
              </Menu.Item>
            </Menu.SubMenu>
          )}

          <Menu.Item
            key="relatorio"
            icon={<FilePdfOutlined />}
            onClick={toggleModalReport}
          >
            {t("water.report")}
          </Menu.Item>
          {adminUser && (
            <Menu.Item
              key="unidade"
              icon={<HddOutlined />}
              onClick={toggleModalUCs}
            >
              {t("water.consumptionUnits")}
            </Menu.Item>
          )}
          {adminUser && (
            <Menu.Item
              key="config"
              icon={<SettingOutlined />}
              onClick={toggleModalSettings}
            >
              {t("water.settings")}
            </Menu.Item>
          )}
        </Menu>
        {openModalSettings ? (
          <ModalWaterSettings
            open={openModalSettings}
            toggle={toggleModalSettings}
            waterData={data}
            projectId={projectId}
            setLoading={setLoading}
          />
        ) : loading ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : null}

        {openModalUCs ? (
          <ModalWaterUCs open={openModalUCs} toggle={toggleModalUCs} />
        ) : null}
        {openModalReport ? (
          <ModalWaterReport
            data={data}
            open={openModalReport}
            toggle={toggleModalReport}
          />
        ) : null}
      </div>
    </Card>
  );
};

export default WaterTools;
