import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card, CardBody, CardHeader, CardTitle,
  Form, FormGroup, Input, Label,
  Row, Col, Spinner
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { toastr } from "react-redux-toastr";

import CustomState from "../cards/CustomState";
import blankImage from "../../../../assets/img/blank";
import { t } from "i18next";

/* Função redução da imagem e conversão para base64 */
function resizeFile(file) {
  return new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      250,
      190,
      "JPEG",
      50,
      0,
      uri => resolve(uri),
      "base64"
    );
  });
};

const StateForm = ({ index, states, setStates }) => {
  /* GAMBIARRA *
   * COMPONENTE <button> FAZ A FUNÇÃO DE UM COMPONENTE <input> OCULTO */
  const hiddenFileInput = useRef(null);

  /* Função upload de imagem */
  async function onChangeImage(img) {
    try {
      let image = await resizeFile(img);
      setStates(states.map((item, i) => {
        return (i === index)
          ? { ...item, image: image }
          : item;
      }));
    } catch (err) {
      console.log(err);
    }
  };

  function onChangeMsg(msg) {
    setStates(states.map((item, i) => {
      return (i === index)
        ? { ...item, message: msg }
        : item;
    }));
  };

  function onChangeColor(color) {
    setStates(states.map((item, i) => {
      return (i === index)
        ? { ...item, color: color }
        : item;
    }));
  };

  return (
    <div>
      {/* Estado [index] */}
      <h5 className="text-center">
        {t('dashboards.state')} ${index}
      </h5>

      <Row>
        <Col className="d-flex flex-column">
          {/* Mensagem */}
          <FormGroup>
            <Label>
              {t('dashboards.message')}
            </Label>
            <Input
              type="text"
              name={"message" + index}
              id={"message" + index}
              placeholder={t('dashboards.msgPlaceholder')}
              value={states[index].message}
              onChange={e => onChangeMsg(e.target.value)}
            />
          </FormGroup>

          {/* Seleção de cor */}
          <FormGroup className="mb-0">
            <Label>
              {t('dashboards.color')}
            </Label>
            <Input
              type="color"
              name={"color" + index}
              id={"color" + index}
              value={states[index].color}
              onChange={e => onChangeColor(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* Imagem */}
        <Col className="d-flex flex-column">
          {/* Legenda */}
          <Label>
            {t('dashboards.image')}
          </Label>

          {/* Preview da imagem e Texto */}
          <div
            className="d-flex justify-content-start"
          >
            <img
              alt={`State ${index}`}
              src={(states[index].image) ? states[index].image : blankImage}
              className="img-responsive"
              height={"50px"}
              style={{
                "borderRadius": 6
              }}
            />
            <small className="ml-2">
              {t('dashboards.imageText')}.
            </small>
          </div>

          {/* Botão */}
          <div className="mt-auto">
            <Button
              color="primary"
              outline
              onClick={e => {
                e.preventDefault();
                hiddenFileInput.current.click();
              }}
            >
              <FontAwesomeIcon icon={faUpload} /> Upload
            </Button>
            <input
              type="file"
              name={"image" + index}
              id={"image" + index}
              ref={hiddenFileInput}
              onChange={e => onChangeImage(e.target.files[0])}
              style={{ display: 'none' }}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
};

const LimitForm = ({ index, thresholds, setThresholds }) => {
  function onChangeLimit(value) {
    setThresholds(thresholds.map((item, i) => {
      return (i === index)
        ? { ...item, value: value }
        : item;
    }));
  };

  function onChangeIncludes(value) {
    setThresholds(thresholds.map((item, i) => {
      return (i === index)
        ? { ...item, includes: value }
        : item;
    }));
  };

  return (
    <div>
      <h5 className="text-center">
        {t('dashboards.limitHead')} ${index} {t('dashboards.limitAnd')} ${index + 1}
      </h5>

      {/* Limite i */}
      <Row>
        {/* Valor */}
        <Col>
          <FormGroup row>
            <Label lg={12} xl={4}>
              {t('dashboards.value')}
            </Label>
            <Col lg={12} xl={8}>
              <Input
                type="text"
                name={"value" + index}
                id={"value" + index}
                value={thresholds[index].value}
                onChange={e => onChangeLimit(Number(e.target.value))}
              />
            </Col>
          </FormGroup>
        </Col>

        {/* Includes */}
        <Col>
          <FormGroup row>
            <Label lg={12} xl={5}>
              {t('dashboards.includes')}:
            </Label>
            <Col lg={12} xl={7}>
              <Input
                type="select"
                name={"includes" + index}
                id={"includes" + index}
                value={thresholds[index].includes}
                onChange={e => onChangeIncludes(Number(e.target.value))}
              >
                <option value="0">{t('dashboards.state')} {index}</option>
                <option value="1">{t('dashboards.state')} {index + 1}</option>
              </Input>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

const NewCustomCard = ({ cardType, cardProps }) => {
  const { t } = useTranslation();
  
  const [isLoading, setLoading] = useState(true); // carregando projetos
  const [isEditLoading, setEditLoading] = useState(false); // salvando edição
  const [projList, setProjList] = useState([]); // lista de projetos
  const [dvcList, setDvcList] = useState([]); // lista de devices do projeto selecionado
  const [varList, setVarList] = useState([]); // lista de variaveis do device selecionado

  const [name, setName] = useState((cardProps?.name) ? cardProps?.name : ""); // campo de nome
  const [description, setDescription] = useState((cardProps?.description) ? cardProps?.description : "") // campo de descricao
  const [deviceId, setDeviceId] = useState((cardProps?.devices) ? cardProps?.devices : ""); // device selecionado
  const [variable, setVariable] = useState((cardProps?.variables) ? cardProps?.variables : ""); // variavel selecionada
  const [reloadTime, setReloadTime] = useState((cardProps?.reloadTime) ? cardProps?.reloadTime : ""); // periodo selecionado para reload
  const [cardDataToEdit, setCardDataToEdit] = useState()

  let varName = (cardProps?.variables) ? JSON.parse(cardProps?.variables).varName : ""
  // let rangeStructure = (cardProps?.rangeStructure) ? JSON.parse(cardProps?.rangeStructure) : ""
  

  const [thresholds, setThresholds] = useState([{
    value: "",
    includes: 0
  }]);
  
  const [states, setStates] = useState([
    {
      message: "",
      color: "#203a45",
      image: ""
    },
    {
      message: "",
      color: "#203a45",
      image: ""
    }
  ]);
  
  const [loadingStates, setLoadingStates] = useState(false);
  // const [formImage, setFormImage] = useState();

  let history = useHistory();

  useEffect(() => {
    if (cardProps?.id) {
      axios.get('/readDataOnEditCard', {
        params: {
          dvcId: cardProps.devices
        }
      })
        .then(res => {
          // console.log(res);
          setCardDataToEdit(res.data)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar projetos.");  // t() da problema com o useEffect
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios.get('/projectUserSearch')
      .then(response => {
        setProjList(response.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar projetos.");  // t() da problema com o useEffect
      });
  }, []);

  function onProjectChange(projId) {
    setDvcList([]); // limpa lista de dispositivos
    setVarList([]); // limpa lista de variaveis
    setDeviceId(""); // limpa dispositivo selecionado
    setVariable(""); // limpa variavel selecionada

    setLoading(true);
    axios.get('/deviceSearch', {
      params: {
        projId: projId
      }
    })
      .then(response => {
        setDvcList(response.data) // atualiza lista de dispositivos
        setLoading(false);
      })
      .catch(err => {
        // console.log(err);
        toastr.error('Erro!', "Erro ao buscar dispositivos.");  // t() da problema com o useEffect
        setLoading(false);
      })
  };

  function onDeviceChange(dvcId) {
    setVarList([]); // limpa lista de variaveis
    setVariable(""); // limpa variavel selecionada

    setDeviceId(dvcId); // atualiza dispositivo selecionado
    setLoading(true);
    axios.get('/readVariables', {
      params: {
        id: dvcId
      }
    })
      .then(response => {
        setVarList(response.data);
        setLoading(false);
      })
      .catch(err => {
        // console.log(err);
        toastr.error('Erro!', "Erro ao buscar variáveis.");  // t() da problema com o useEffect
        setLoading(false);
      });
  };

  async function onNewStateClick() {
    await setLoadingStates(true);
    let thrAux = thresholds;
    let stAux = states;

    thrAux.push({ value: "", includes: 0 });
    stAux.push({ message: "", color: "" });

    setThresholds(thrAux);
    setStates(stAux);

    await setLoadingStates(false);

    return
  };

  function onSubmitForm() {
    setEditLoading(true)
    setTimeout(() => {
      setEditLoading(false)
    }, 3000)

    if (name.length >= 50) {
      return toastr.warning("Cuidado!", "Título muito extenso.")
    }

    if (!deviceId || !variable) {
      toastr.warning('Erro!', 'Não foi selecionada variável.');
    }

    else {
      const whArr = [3, 6];
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        dashId: localStorage.getItem('dashId'),
        // idCard: cardId,
        cardType: cardType,
        name: name,
        description: description,
        devices: deviceId,
        variables: variable,
        period: null,
        reloadTime: reloadTime,
        w: whArr[0],
        h: whArr[1],
        // decimalNumber: null,
        // decimalNumber: (decimalNumber) ? decimalNumber : null,
        rangeStructure: {
          thresholds: thresholds,
          states: states
        },
        formula: null,
        decimalNumber: null,
        minValueProp: null,
        maxValueProp: null
      };
      // return console.log(jsonData); 
      /*** Envio de dados ***/
      axios.post('newCard/', JSON.stringify(jsonData))
        .then(result => {
          toastr.success(result.data.messageHead, result.data.messageBody);
          history.push("../dashboardView")
        })
        .catch(err => {
          // console.log(err);
          // console.log(err.response);
          if (err.response.data) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  };

  // FUNÇÃO PARA SALVAR DADOS EDITADOS DO CARD
  async function handleUpdateCard(cardId) {
    setEditLoading(true)
    setTimeout(() => {
      setEditLoading(false)
    }, 3000)
    if (name.length >= 50) {
      return toastr.warning("Cuidado!", "Título muito extenso.")
    }
    if (!deviceId || !variable) {
      toastr.warning('Erro!', 'Não foi selecionada variável.');
    }

    else {
      const whArr = [3, 6];
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        dashId: localStorage.getItem('dashId'),
        idCard: cardId,
        // cardType: cardType,
        name: name,
        description: description,
        devices: deviceId,
        variables: variable,
        period: null,
        reloadTime: reloadTime,
        w: whArr[0],
        h: whArr[1],
        // decimalNumber: null,
        // decimalNumber: (editDecimalNumber) ? editDecimalNumber : "null",
        rangeStructure: {
          thresholds: thresholds,
          states: states
        },
        formula: null,
        decimalNumber: null,
        minValueProp: null,
        maxValueProp: null
      };
      // return console.log(jsonData);
      /*** Envio de dados ***/
      await axios.put('editCardV2/', JSON.stringify(jsonData))
        .then(result => {
          toastr.success(result.data.messageHead, result.data.messageBody);
          setEditLoading(false)
          history.push("../../dashboardView")
        })
        .catch(err => {
          // console.log(err);
          // console.log(err.response);
          setEditLoading(false)
          if (err.response.data) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  };  

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    let result = minutes;
    if (millis === 30000) {
      return `${seconds} segundos`
    } else {
      return `${result} minutos`
    }
  }

  useEffect(() => {
    if (cardProps?.id && cardDataToEdit) {
      onProjectChange(cardDataToEdit.projId);
      onDeviceChange(cardProps?.devices);
      setVariable(cardProps?.variables);
      let reloadRangeStructure = JSON.parse(cardProps.rangeStructure);

      setStates(reloadRangeStructure.states);
      setThresholds(reloadRangeStructure.thresholds);
    }
    // eslint-disable-next-line
  }, [cardDataToEdit]);
  
  return (
    <Row xs={1} xl={2}>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">{t('dashboards.cardOptions')}</CardTitle>
          </CardHeader>

          <CardBody>
            <Form
              onSubmit={e => {
                e.preventDefault();
                onSubmitForm();
              }}
            >
              {(cardProps?.id) ?
                <>
                  {/* Editar Nome */}
                  <FormGroup>
                    <Label>
                      {t('dashboards.name')}
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder={t('dashboards.name')}
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </FormGroup>

                  {/* Editar Descrição */}
                  <FormGroup>
                    <Label>
                      {t('dashboards.description')}
                    </Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder={t('dashboards.description')}
                      rows="3"
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                    />
                  </FormGroup>

                  {/* Editar Seleção de projeto */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t('dashboards.projects')}
                    </Label>
                    <Col lg={12} xl={9}>
                      {(cardDataToEdit) ?
                        <Input
                          type="select"
                          id="project"
                          required
                          defaultValue={(cardDataToEdit?.name) ? cardDataToEdit.name : ""}
                          onChange={e => onProjectChange(e.target.value)}
                          disabled={isLoading}
                        >
                          <option hidden value={(cardDataToEdit?.name) ? cardDataToEdit.name : ""}>{(cardDataToEdit) ? cardDataToEdit?.name : ""}</option>
                          {(projList.length)
                            ? projList.map(item => (
                              <option value={item.id} key={item.id}>{item.name}</option>
                            ))
                            : <option disabled>{t('dashboards.noProject')}</option>
                          }
                        </Input>
                        : null}
                    </Col>
                  </FormGroup>

                  {/* Editar Seleção de dispositivo */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t('dashboards.devices')}
                    </Label>
                    <Col lg={12} xl={9}>
                      {(cardDataToEdit) ?
                        <Input
                          type="select"
                          id="device"
                          required
                          defaultValue={(cardDataToEdit?.description) ? cardDataToEdit.description : ""}
                          onChange={e => onDeviceChange(e.target.value)}
                        // disabled={!dvcList.length || isLoading}
                        >
                          <option hidden defaultValue={(cardDataToEdit?.id) ? cardDataToEdit.id : ""}>{(cardProps.id) ? cardDataToEdit.description : t('dashboards.pleaseDevice')}</option>
                          {dvcList.map(item => (
                            <option value={item.id} key={item.id}>
                              {item.description}
                            </option>
                          ))}
                        </Input>
                        : null}
                    </Col>
                  </FormGroup>

                  {/* Editar Seleção de variável */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t('dashboards.variables')}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="select"
                        id="variablesSelect"
                        required
                        defaultValue={variable}
                        onChange={e => setVariable(e.target.value)}
                      // disabled={!varList.length || isLoading}
                      >
                        <option hidden value="">
                          {(varName) ? varName : t('dashboards.pleaseVariable')}
                        </option>
                        {varList.map(item => (
                          <option value={JSON.stringify(item)} key={item.tag}>
                            {item.varName}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>

                  {/* Editar Seleção tempo Reload */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t('dashboards.refreshTime')}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="select"
                        id="reloadTimeSelect"
                        required
                        defaultValue={reloadTime}
                        onChange={e => setReloadTime(e.target.value)}
                      >
                        <option hidden value={(reloadTime) ? reloadTime : ""}>{millisToMinutesAndSeconds(cardProps.reloadTime)}</option>
                        <option value="30000">30 segundos</option>
                        <option value="60000">1 minuto</option>
                        <option value="120000">2 minutos</option>
                        <option value="180000">3 minutos</option>
                        <option value="240000">4 minutos</option>
                        <option value="300000">5 minutos</option>
                        <option value="900000">15 minutos</option>
                        <option value="1800000">30 minutos</option>
                      </Input>
                    </Col>
                  </FormGroup>
                </>
                :
                <>
                  {/* Nome */}
                  <FormGroup>
                    <Label>
                      {t('dashboards.name')}
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder={t('dashboards.name')}
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </FormGroup>

                  {/* Descrição */}
                  <FormGroup>
                    <Label>
                      {t('dashboards.description')}
                    </Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder={t('dashboards.description')}
                      rows="3"
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                    />
                  </FormGroup>

                  {/* Seleção de projeto */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t('dashboards.projects')}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="select"
                        id="project"
                        required
                        onChange={e => onProjectChange(e.target.value)}
                        disabled={isLoading}
                      >
                        <option hidden value="">{t('dashboards.pleaseProject')}</option>
                        {(projList.length)
                          ? projList.map(item => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))
                          : <option disabled>{t('dashboards.noProject')}</option>
                        }
                      </Input>
                    </Col>
                  </FormGroup>

                  {/* Seleção de dispositivo */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t('dashboards.devices')}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="select"
                        id="device"
                        required
                        value={deviceId}
                        onChange={e => onDeviceChange(e.target.value)}
                        disabled={!dvcList.length || isLoading}
                      >
                        <option hidden value="">{t('dashboards.pleaseDevice')}</option>
                        {dvcList.map(item => (
                          <option value={item.id} key={item.id}>
                            {item.description}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>

                  {/* Seleção de variável */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t('dashboards.variables')}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="select"
                        id="variablesSelect"
                        required
                        value={variable}
                        onChange={e => setVariable(e.target.value)}
                        disabled={!varList.length || isLoading}
                      >
                        <option hidden value="">{t('dashboards.pleaseVariable')}</option>
                        {varList.map(item => (
                          <option value={JSON.stringify(item)} key={item.tag}>
                            {item.varName}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                  
                  {/* Seleção tempo Reload */}
                  <FormGroup row>
                    <Label lg={12} xl={3}>
                      {t('dashboards.refreshTime')}
                    </Label>
                    <Col lg={12} xl={9}>
                      <Input
                        type="select"
                        id="reloadTimeSelect"
                        required
                        value={reloadTime}
                        onChange={e => setReloadTime(e.target.value)}
                      >
                        <option hidden value="">{t('dashboards.pleaseRefresh')}</option>
                        <option value="30000">30 segundos</option>
                        <option value="60000">1 minuto</option>
                        <option value="120000">2 minutos</option>
                        <option value="180000">3 minutos</option>
                        <option value="240000">4 minutos</option>
                        <option value="300000">5 minutos</option>
                        <option value="900000">15 minutos</option>
                        <option value="1800000">30 minutos</option>

                      </Input>
                    </Col>
                  </FormGroup>

                </>
              }

              <hr />

              {/* Criação de Estados */}
              {(loadingStates)
                ? <Spinner />
                : <div>
                  <StateForm index={0} states={states} setStates={setStates} />

                  {thresholds.map((thr, i) => (
                    <div key={i}>
                      <hr />
                      <LimitForm index={i} thresholds={thresholds} setThresholds={setThresholds} />
                      <hr />
                      <StateForm index={i + 1} states={states} setStates={setStates} />
                    </div>
                  ))}
                </div>
              }
              
              {/* Botões */}
              <div className="d-flex justify-content-between mt-5">
                {/* Novo estado */}
                <Button color="primary" type="button" onClick={onNewStateClick}>
                  {t('dashboards.newState')}
                </Button>

                <div>
                  {/* Cancelar */}
                  <Link to="/dashboardView">
                    <Button type="button" color="primary" outline>
                      {t('dashboards.cancel')}
                    </Button>
                  </Link>

                  {/* Salvar */}
                  {(cardProps?.id) ?
                    <Button
                      color="primary"
                      type="button"
                      disabled={isEditLoading}
                      style={{ minWidth: 80 }}
                      onClick={() => handleUpdateCard(cardProps.id)}
                    >
                      {(isEditLoading === true) ? <Spinner size={"sm"} /> : t("Atualizar")}
                    </Button>
                    : <Button
                      disabled={isEditLoading}
                      style={{ minWidth: 80 }}
                      color="primary"
                      type="submit"
                    >
                      {(isEditLoading === true) ? <Spinner size={"sm"} /> : t('dashboards.save')}
                    </Button>
                  }
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>

      <Col>
        <Card>
          <CustomState
            cardDataToEdit={cardDataToEdit}
            name={name}
            description={description}
            variables={variable}
            deviceId={deviceId}
            preview={true}
            rangeStructure={{ thresholds: thresholds, states: states }}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default NewCustomCard;