import React, { useState } from "react";
import {
  Card, CardBody, CardHeader, CardTitle,
  Button,
  Form, FormGroup, Input, Label,
} from "reactstrap";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';

const ChangePassword = () => {

  const [currentPass, setCurrentPass] = useState('');
  const [newPass1, setNewPass1] = useState('');
  const [newPass2, setNewPass2] = useState('');
  
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {t('settings.changePassword')}
        </CardTitle>
      </CardHeader>

      <CardBody>
        <Form
          onSubmit={e=>{
            e.preventDefault();

            /* Salvar dados do formulário no formato JSON */
            const jsonData = JSON.stringify({
              // userId: localStorage.getItem('userId'),
              password: currentPass,
              password1: newPass1
            });
            // verificação de campos de senha iguais
            if (newPass1 === newPass2) {
              // verificação de tamanho de senha
              if (newPass1.length < 8) {
                toastr.warning(`${t('settings.err')}!`, `${t('settings.minCharacters')}.`);
              } else {
                // axios.post('changePassword/',jsonData)
                axios.put('changePassword/',jsonData)
                .then(res => {
                  console.log(res)
                  toastr.success(`${t('settings.success')}!`, res.data.message);
                })
                .catch(err =>{
                  console.log(err.response)

                  if (err.response.data.message) {
                    toastr.warning(`${t('settings.err')}!`, `${err.response.data.message}.`);
                  }
                })
              }
            } else {
              toastr.warning(`${t('settings.err')}!`, `${t('settings.notEqual')}!`);
            }
            
            setCurrentPass("");
            setNewPass1("");
            setNewPass2("");
          }}
        >
          {/* Senha atual */}
          <FormGroup>
          <Label>{t('settings.currPassword')}</Label>
          <Input 
            type="password" 
            name="password1"
            id="currentPass"
            placeholder={t('settings.currPassword')}
            value={currentPass}
            onChange={e=>setCurrentPass(e.target.value)}
          />
          </FormGroup>

          {/* Nova senha */}
          <FormGroup>
          <Label>{t('settings.newPassword')}</Label>
          <Input 
            type="password"
            name="newPass1"
            id="newPass1"
            placeholder={t('settings.newPassword')}
            value={newPass1}
            onChange={e=>setNewPass1(e.target.value)} 
          />
          </FormGroup>

          {/* Confirmação de senha */}
          <FormGroup>
          <Label>{t('settings.confirmPassword')}</Label>
          <Input 
            type="password"
            name="newPass2"
            id="newPass2"
            placeholder={t('settings.confirmPassword')}
            value={newPass2}
            onChange={e=>setNewPass2(e.target.value)}
          />
          </FormGroup>

          {/* Botão salvar */}
          <Button 
            type="submit"
            color="primary"
          >
            {t('settings.submit')}
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
}

export default ChangePassword;