import React from "react";
import { Card, CardBody, Col, Row, CardHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from '@fortawesome/free-solid-svg-icons';

const PVCards = ( { data }) => {

  let tension = data

  const rows = Object.keys(tension).filter(f => f.startsWith('PV')
    && f.endsWith('_V')
  ).map((row) => {
    return {
      chave: row,
      valor: tension[row]
    }
  });

  let cards = [];
  let pilha = [];

  for (const row of rows) {
    pilha.push(row);
    if (pilha.length === Number(tension["stringsPerMPPT"])) {
      cards = [
        ...cards,
        pilha,
      ];
      pilha = [];
    }
  }

  if (pilha.length > 0) {
    cards = [
      ...cards,
      pilha,
    ];
  }

  let renderiza1 = (card, index) => {

    return (
      <div className="div por fora do card renderiza 01" style={{marginRight:"15px", height:"98px", marginLeft:"2px"}}>
        <Card 
          // key={m.chave}
          style={{minWidth: "130px", width: "130px", maxHeight:"100px", borderStyle:"solid", background:"#97c3c3", display:"flex", flexDirection:"row", borderBottom: "solid #3e4676"}}
        >
          <Col style={{padding:"0px"}}>
            <CardHeader style={{paddingBottom:"0px", textAlign:"center"}}>
              <Row>
                <Col>
                  <h5>MPPT {index}</h5>
                </Col>
                <Col className="col-auto" style={{paddingLeft:"0px"}}>
                  <div>
                    <FontAwesomeIcon 
                        icon={faBolt} 
                        style={{fontSize:"16px"}}
                        />
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody style={{textAlign:"center", paddingTop: "5px"}}>
              <h5>{card[0].chave.substring(0, card[0].chave.length - 2)}</h5>
              <h5>{card[0].valor}</h5>
            </CardBody>  
          </Col>
        </Card>
      </div>
    )
  }

  let renderiza2 = (card, index) => {

    return(
      <div key={index} className="div por fora do card renderiza 01" style={{marginRight:"16px", height:"98px", marginLeft:"2px"}}>
        <Card 
          // key={m.chave}
          style={{ minWidth: "117px", width: "117px", maxHeight:"100px", height: "100px", background:"#97c3c3", borderBottom: "solid #3e4676"}}
        >
            <Col style={{padding:"0px"}}>
              <CardHeader style={{paddingBottom:"0px", textAlign:"center"}}>
                <Row>
                  <Col style={{paddingRight: "0px"}}>
                    <h5>MPPT {index}</h5>
                  </Col>
                  <Col className="col-auto" style={{paddingLeft:"0px"}}>
                    <div>
                      <FontAwesomeIcon 
                          icon={faBolt} 
                          style={{fontSize:"16px"}}
                          />
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{textAlign:"center", display: "flex", padding: "5px", justifyContent: "center", paddingLeft: "10px"}}>
                {card.map((m, i) => ( 
                  <div key={i} style={{marginRight:"10px", width: "50%"}}>
                  <h5>{m.chave.substring(0, m.chave.length - 2)}</h5>
                  <h5>{m.valor}</h5>
                  </div>    
                ))}
              </CardBody>  
            </Col>
        </Card>
      </div>
    )
  }

  let renderiza3 = (card, index) => {

    return(
      <div key={index} className="div por fora do card renderiza 01" style={{marginRight:"15px", height:"98px", marginLeft:"2px"}}>
        <Card 
          // key={m.chave}
          style={{ minWidth: "165px", width: "165px", maxHeight:"100px", height: "100px", background:"#97c3c3"}}
        >
            <Col style={{padding:"0px"}}>
              <CardHeader style={{paddingBottom:"0px", textAlign:"center"}}>
                <Row>
                  <Col>
                    <h5>MPPT {index}</h5>
                  </Col>
                  <Col className="col-auto" style={{paddingLeft:"0px"}}>
                    <div>
                      <FontAwesomeIcon 
                          icon={faBolt} 
                          style={{fontSize:"16px"}}
                          />
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{textAlign:"center", display: "flex", padding: "5px", justifyContent: "center", paddingLeft: "10px"}}>
                {card.map((m) => ( 
                  <div style={{marginRight:"10px", width: "50%"}}>
                  <h5>{m.chave.substring(0, m.chave.length - 2)}</h5>
                  <h5>{m.valor}</h5>
                  </div>    
                ))}
              </CardBody>  
            </Col>
        </Card>
      </div>
    )
  }

  let renderiza4 = (card, index) => {

    return(
      <div key={index} className="div por fora do card renderiza 01" style={{marginRight:"15px", height:"98px", marginLeft:"2px"}}>
        <Card 
          // key={m.chave}
          style={{ minWidth: "215px", width: "215px", maxHeight:"100px", height: "100px", background:"#97c3c3"}}
        >
            <Col style={{padding:"0px"}}>
              <CardHeader style={{paddingBottom:"0px", textAlign:"center"}}>
                <Row>
                    <Col>
                      <h5>MPPT {index}</h5>
                    </Col>
                    <Col className="col-auto" style={{paddingLeft:"0px"}}>
                      <div>
                        <FontAwesomeIcon 
                            icon={faBolt} 
                            style={{fontSize:"16px"}}
                            />
                      </div>
                    </Col>
                  </Row>
              </CardHeader>
              <CardBody style={{textAlign:"center", display: "flex", padding: "5px", justifyContent: "center", paddingLeft: "10px"}}>
                {card.map((m) => ( 
                  <div style={{marginRight:"10px", width: "50%"}}>
                  <h5>{m.chave.substring(0, m.chave.length - 2)}</h5>
                  <h5>{m.valor}</h5>
                  </div>    
                ))}
              </CardBody>  
            </Col>
        </Card>
      </div>
    )
  }

  return(
    <div>
      <Row style={{marginLeft: "0px", justifyContent: "space-between"}}>
        {(tension.stringsPerMPPT === 1)
          ? 
          <Col lassName="div do card 01" style={{display:"flex", paddingLeft:"0px"}}>
            {cards.map((card, index) => (
              renderiza1(card, index+1)
            ))}
          </Col> 
          : (tension.stringsPerMPPT === 2)
            ? 
              cards.map((card, index) => (
                renderiza2(card, index+1)
              ))
            : (tension.stringsPerMPPT === 3)
              ? 
                cards.map((card, index) => (
                  renderiza3(card, index+1)
                ))
              : (tension.stringsPerMPPT === 4)
                ? 
                  cards.map((card, index) => (
                    renderiza4(card, index+1)
                  ))
                : null             
        }
      </Row>
    </div>
  );
}

export default PVCards;