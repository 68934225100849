import { Card, CardBody, CardTitle, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";

const sanitizeId = (id) => id.replaceAll(' ', "_");

export const InfoCard = ({
  title,
  IconComponent,
  demoValue,
  contractValue,
  loading = false,
  tooltipContractValue,
  tooltipDemoValue,
}) => {
  const { t } = useTranslation();
  const demoValueId = tooltipDemoValue ? sanitizeId(tooltipDemoValue) : `${title}_demo`;
  const contractValueId = tooltipContractValue ?  sanitizeId(tooltipContractValue) : `${title}_contract`;

  return (
    <Col>
      <Card
        style={{
          minWidth: "135px",
          marginBottom: "0px",
        }}
      >
        <CardBody style={{ padding: "15px", paddingTop: "10px" }}>
          <Row className="m-0">
            <Col className="mt-0">
              <CardTitle tag="h5">{title}</CardTitle>
            </Col>
            <Col className="col-auto" style={{ paddingLeft: "0px" }}>
              {IconComponent && <IconComponent />}
            </Col>
          </Row>
          <Row className="m-0 justify-content-around">
            <Col className="col-auto" id={demoValueId}>
              <p>Demo:</p>
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <strong>{demoValue || "n/a"}</strong>
              )}
            </Col>
            {tooltipDemoValue && (
              <UncontrolledTooltip placement="top-end" target={demoValueId}>
                {tooltipDemoValue}
              </UncontrolledTooltip>
            )}
            <Col className="col-auto" id={contractValueId}>
              <p>{t("noc.form.contract")}:</p>
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <strong>{contractValue || "n/a"}</strong>
              )}
            </Col>
            {tooltipContractValue && (
              <UncontrolledTooltip placement="top-end" target={contractValueId}>
                {tooltipContractValue}
              </UncontrolledTooltip>
            )}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};
