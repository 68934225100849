export const SpanText = ({ text }) => {
  return (
    <span
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        cursor: "pointer",
        display: "inline-block",
        marginRight: "auto",
        verticalAlign: "middle",
        lineHeight: "20px",
      }}
    >
      {text}
    </span>
  );
};
