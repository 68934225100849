import React from "react";
import { Card, CardBody, Container, Row, Table } from "reactstrap";
import { GeneralConsumptionChart } from "./GeneralConsumptionChart";
import { useTranslation } from "react-i18next";

export const ProjectDetails = ({ project }) => {
  const { t } = useTranslation();
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  return (
    <Container>
      <Card className="my-4">
        <CardBody>
          <Row>
            <Table striped responsive>
              <thead>
                <tr>
                  <th>{t("noc.modal.monthRef")}</th>
                  <th>{t("noc.modal.processAt")}</th>
                  <th>{t("noc.modal.water.meteringConsumption")}</th>
                  <th>{t("noc.modal.water.commonAreaConsumption")}</th>
                  <th>{t("noc.modal.water.dealerConsumption")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{project.monthRef}</td>
                  <td>{formatTimestamp(project.processedAt)}</td>
                  <td>{Number(project.consumoMedicao).toFixed(2) || 0} m³</td>
                  <td>
                    {Number(project.generalConsumption).toFixed(2) || 0} m³
                  </td>
                  <td>
                    {Number(project.dealerConsumption).toFixed(2) || 0} m³
                  </td>
                </tr>
              </tbody>
            </Table>
            {project.generalConsumptionPerDay.length > 0 ? (
              <GeneralConsumptionChart
                item={project.generalConsumptionPerDay}
              />
            ) : (
              <div>{t("noc.modal.graphic.noData")}</div>
            )}
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};
