import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import store from "./redux/store/index";
import Routes from "./routes/Routes";
import axiosDefaults from "./onClicks/axiosDefaults";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "antd/es/modal/style";
import "antd/es/slider/style";

export const queryClient = new QueryClient();

class App extends React.Component {
  componentDidMount() {
    // Remove `active` className from `.splash` element in `public/index.html`
    !document.querySelector(".splash") ||
      document.querySelector(".splash").classList.remove("active");
  }

  render() {
    axiosDefaults();
    return (
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Routes />
          <ReduxToastr
            timeOut={5000}
            newestOnTop={true}
            position="top-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            // progressBar
            closeOnToastrClick
          />
        </Provider>
      </QueryClientProvider>
    );
  }
}

export default App;
