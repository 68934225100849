import enGB from "date-fns/locale/en-GB";
import ptBR from "date-fns/locale/pt-BR";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  // CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useWaterModuleAPI } from "../../WaterProvider";
import { fetchUpdatePlant } from "../../WaterService";

const ModalWaterSettings = ({ open, toggle, waterData, projectId }) => {
  const { setGeneralData } = useWaterModuleAPI();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [timestampInit, setTimestampInit] = useState(new Date());
  const [timestampEnd, setTimestampEnd] = useState(new Date());
  const [dealerConsumption, setDealerConsumption] = useState(
    waterData.consumoConcessionaria
  );
  const [utilityBill, setUtilityBill] = useState(waterData.contaConcessionaria);
  const [loading, setLoading] = useState(false);
  const inputStyle = {
    width: "50%",
  };

  const handleGenerateInvoice = async () => {
    timestampInit.setHours(0, 0, 0);
    timestampEnd.setHours(23, 59, 59);
    const body = {
      projectId,
      timestampInit: timestampInit.getTime() / 1000,
      timestampEnd: timestampEnd.getTime() / 1000,
      dealerConsumption: parseFloat(dealerConsumption.replace(",", ".")),
      utilityBill: parseFloat(utilityBill.replace(",", ".")),
    };

    setLoading(true);
    const { data, status } = await fetchUpdatePlant({
      body,
    });
    if (status === 200) {
      setGeneralData(data);
      toggle();
    }

    setLoading(false);
  };

  return (
    <Modal isOpen={open} toggle={toggle}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleGenerateInvoice();
        }}
      >
        <ModalHeader toggle={toggle}>
          {t("water.waterModuleConfigure")}
        </ModalHeader>

        <ModalBody className="mx-3">
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("water.periodInit")}</Label>
                <div style={{ width: "70%" }}>
                  <DatePicker
                    selected={timestampInit}
                    onChange={(date) => {
                      date.setHours(0, 0, 0);
                      setTimestampInit(date);
                    }}
                    locale={i18n.language === "br" ? ptBR : enGB}
                    dateFormat={
                      i18n.language === "en" ? "MMMM d, yyyy" : "dd/MM/yyyy"
                    }
                    className="form-control width-75"
                  />
                </div>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>{t("water.periodEnd")}</Label>
                <div style={{ width: "70%" }}>
                  <DatePicker
                    selected={timestampEnd}
                    onChange={(date) => {
                      date.setHours(23, 59, 59);
                      setTimestampEnd(date);
                    }}
                    locale={i18n.language === "br" ? ptBR : enGB}
                    dateFormat={
                      i18n.language === "en" ? "MMMM d, yyyy" : "dd/MM/yyyy"
                    }
                    className="form-control width-75"
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label>{t("water.consumptionByCompany")}</Label>
                <div className="d-flex flex-row align-items-center">
                  <Input
                    type="text"
                    style={inputStyle}
                    value={dealerConsumption}
                    required
                    onChange={({ target }) => {
                      let { value } = target;
                      value = value.replace(".", ",").replace(/[^\d,]/g, "");
                      setDealerConsumption(
                        value.replace(/,/g, function (match, offset, original) {
                          return offset === original.indexOf(",") ? match : "";
                        })
                      );
                    }}
                  />
                  <Label className="ml-2">m³</Label>
                </div>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>{t("water.valueToPay")}</Label>
                <div className="d-flex flex-row align-items-center">
                  <Label className="mr-2">R$</Label>
                  <Input
                    type="text"
                    style={inputStyle}
                    value={utilityBill}
                    required
                    onChange={({ target }) => {
                      let { value } = target;
                      value = value.replace(".", ",").replace(/[^\d,]/g, "");
                      setUtilityBill(
                        value.replace(/,/g, function (match, offset, original) {
                          return offset === original.indexOf(",") ? match : "";
                        })
                      );
                    }}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button
            type="button"
            color="primary"
            className="mr-1 mb-1"
            onClick={toggle}
            outline
          >
            {t("dashboards.cancel")}
          </Button>

          {loading ? (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          ) : (
            <Button type="submit" color="primary" className="mr-1 mb-1">
              {t("dashboards.save")}
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ModalWaterSettings;
