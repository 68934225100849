import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import { faSignal } from "@fortawesome/free-solid-svg-icons";

export default function Status({ plant }) {
  const { plantId, plantStatus } = plant;

  const { t } = useTranslation();

  const color = {
    0: "red",
    1: "#FFA500",
    2: "green",
  };

  const classNamesIcon = {
    0: "fa-blink",
    1: "",
    2: "",
  };

  const toolTip = {
    0: t("solar.tooltipOff"),
    1: t("solar.tooltipWarning"),
    2: t("solar.tooltipOn"),
  };

  return (
    <div id={"tumb" + plantId} className="d-flex justify-content-center">
      <FontAwesomeIcon
        icon={faSignal}
        fixedWidth
        className={"align-middle " + classNamesIcon[plantStatus]}
        style={{ marginBottom: "2px", color: color[plantStatus] }}
      />
      <UncontrolledTooltip placement="top" target={"tumb" + plantId}>
        {toolTip[plantStatus]}
      </UncontrolledTooltip>
    </div>
  );
}
