const enUsSharingTranslations = {
  homeSharing: {
    graphics: "Graphics",
    dataTable: "Data Table",
  },
  formParams: {
    params: "Parameters",
    pageInit: "Page init",
    plant: "Plant",
    periodEnd: "Period End",
    periodInit: "Period Init",
    enterprise: "Enterprise",
    unitConsumption: "Unit consumption",
    invoiceValue: "Invoice Value",
    view: "View",
    noUcs: "No consumption unit found",
    billWarning: "Consumption value not informed"
  },
  sharingTable: {
    uc: "UC",
    totalPeakConsumption: "Total Peak Consumption",
    totalOffPeakConsumption: "Total Off Peak Consumption",
    totalConsumption: "Total Consumption",
    costPeak: "Cost Peak",
    costOffPeak: "Cost Off Peak",
    percentKwh: "Percent Kwh",
    costTotal: "Cost Total",
  }
};

export default enUsSharingTranslations;
