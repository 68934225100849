const GasModulePTTranslations = {
    gasModule:{
        plant:{
            consumption: "Consumo",
            ModalGasReport: "Relatório de Gas",
            ModalGasConfigure: "Configurações de Módulo Gas"
        },
        modal:{
            consumptionUnits:{
                name: "Nome",
                action: "Ação",
                clickIn: "Clique em",
                toEditUnit: "para edita a unidade.",
                toAddUnit: "para adicionar uma nova unidade.",
                toManageRegisters: "para gerenciar seus medidores.",
            }
        }
    }
}

export default GasModulePTTranslations;