import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAPI } from "../../SolarProvider";
import { toastr } from "react-redux-toastr";

const ModalPvSyst = ({ open, toggle }) => {
  const { t } = useTranslation();
  const { setPvSyst, readPvSyst, pvSystRatio, SetPvSystRatio } = useAPI();
  const [valores, setValores] = useState(
    readPvSyst.map((item, index) => Object.values(item)[0]) || []
  );
  const [ratios, setRatios] = useState(
    pvSystRatio?.map((item, index) => Object.values(item)[0]) || []
  )

  const handleChange = (event, mes) => {
    const novoValor = parseFloat(event.target.value);
    setValores((prevValores) => ({ ...prevValores, [mes]: novoValor || 0 }));
  };

  const handleChangeRatios = (event, mes) => {  
    const novoValor = parseFloat(event.target.value);
    setRatios((prevRatios) => ({ ...prevRatios, [mes]: novoValor || 0 }));
  };

  const mesesAbreviados = [
    t("solar.pvSystJan"),
    t("solar.pvSystFev"),
    t("solar.pvSystMar"),
    t("solar.pvSystAbr"),
    t("solar.pvSystMai"),
    t("solar.pvSystJun"),
    t("solar.pvSystJul"),
    t("solar.pvSystAgo"),
    t("solar.pvSystSet"),
    t("solar.pvSystOut"),
    t("solar.pvSystNov"),
    t("solar.pvSystDez"),
  ];

  const handleSubmit = (event) => {
    event.preventDefault();

    const valoresFormatados = Object.values(valores).map((valor, index) => ({
      [mesesAbreviados[index]]: valor || 0,
    }));

    const ratiosFormatados = Object.values(ratios).map((ratio, index) => ({
      [mesesAbreviados[index]]: ratio || 0,
    }));

    if(valoresFormatados.length !== 12 || ratiosFormatados.length !== 12) {
      toastr.error("Erro", "Preencha todos os campos");
      return;
    }

    setPvSyst(valoresFormatados);
    SetPvSystRatio(ratiosFormatados);
    toggle();
  };
  return (
    <Modal isOpen={open} toggle={toggle} size="md" scrollable>
      <ModalHeader toggle={toggle}>{t("solar.modalPvSystHeader")}</ModalHeader>

      <ModalBody className="d-flex flex-column align-items-center">
        <Row>
          <Col style={{ maxWidth: 400 }}>
            <Label tag={"h4"} className="mb-4 text-center">
              O valor da projeção mensal deve conter apenas números em
              megawatt-hora (MWh)
            </Label>
          </Col>
        </Row>
        <Row
          className="d-flex flex-row align-items-center w-100"
          style={{ display: "flex" }}
        >
          <Col sm={4}>
            <b>Mes</b>
          </Col>
          <Col sm={4}>
            <b>Valor</b>
          </Col>
          <Col sm={4}>
            <b>Ratio</b>
          </Col>
        </Row>
        {readPvSyst?.length
          ? readPvSyst.map((item, index) => (
              <Row
                className="d-flex flex-row mt-2 mb-2 align-items-center w-100"
                style={{ display: "flex" }}
              >
                <Col sm={3} className="d-flex" key={index + 1}>
                  <Label htmlFor={`mes-${Object.keys(item)[0]}`}>
                    {Object.keys(item)[0]}
                  </Label>
                </Col>
                <Col>
                  <Input
                    type="number"
                    id={`mes-${index}`}
                    value={valores[index]}
                    onChange={(event) => handleChange(event, index)}
                    step={0.01}
                  />
                </Col>
                <Col>
                  <Input
                    type="number"
                    id={`mes-${index}-ratio`}
                    value={ratios[index]}
                    onChange={(event) => handleChangeRatios(event, index)}
                    step={0.01}
                  />
                </Col>
              </Row>
            ))
          : Array.from({ length: 12 }, (_, index) => {
              const mes = index + 1;
              const mesAbreviado = mesesAbreviados[index];
              return (
                <Row
                  className="d-flex flex-column align-items-center"
                  style={{ display: "flex" }}
                >
                  <Col sm={6} className="d-flex" key={mes}>
                    <Label htmlFor={`mes-${mes}`}>{mesAbreviado}</Label>
                  </Col>
                  <Col sm={8}>
                    <Input
                      type="number"
                      id={`mes-${mes}`}
                      value={valores[mes] || ""}
                      onChange={(event) => handleChange(event, mes)}
                      step={0.01}
                    />
                  </Col>
                </Row>
              );
            })}
      </ModalBody>

      <ModalFooter>
        <Button
          type="button"
          color="primary"
          className="mb-1"
          onClick={handleSubmit}
        >
          {t("solar.save")}
        </Button>
        <Button color="primary" className="mb-1" onClick={toggle} outline>
          {t("solarReport.closeButton")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalPvSyst;
