import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../../components/Header";
import { usePlantContext } from "./PlantProvider";
import { RefMeterProvider } from "./RefMeter/RefMeterProvider";
import { NoParamsRefMeter } from "./RefMeter/components/NoParamsRefMeter";
import { NoParamsPlant } from "./components/NoParamsPlant";

export default function NotFoundPage() {
  const location = useLocation();
  const currentPath = location.pathname;
  const { plant } = usePlantContext();
  const { objectEnergy, params, selectedDate } = usePlantContext();
  return (
    <Header className="d-flex flex-column">
      {/* NOTE: Mensagem de sem parâmetros cadastrados para planta */}
      {currentPath.split("/")[2] !== `meter` && (
        <NoParamsPlant
          projId={plant?.id}
          projName={plant?.name}
          monthRef={selectedDate}
          params={params}
          plantData={objectEnergy?.meters?.length}
        />
      )}
      {/* NOTE: Mensagem de sem parâmetros cadastrados para medidor de referência */}
      {currentPath.split("/")[2] === `meter` && (
        <RefMeterProvider projId={plant?.id}>
          <NoParamsRefMeter
            projId={plant?.id}
            projName={plant?.name}
            monthRef={selectedDate}
          />
        </RefMeterProvider>
      )}
    </Header>
  );
}
