const enUsDashboardTranslations = {
  dashboards: {
    anyDashboards: "You don't have any dashboards.",
    myDashboards: "My Dashboards",
    homePage: "Home page",
    moreDash: "You can add new dashboards, according to your need.",
    name: "Name",
    companyName: "Company",
    description: "Description",
    decimalNumber: "Decimal Places",
    actions: "Actions",
    editDashboards: "Edit Dashboards",
    deleteDash: "Delete Dashboard",
    textDeleteDash:
      'Attention! This action is irreversible. If you wish to continue, please type "DELETE" in the field below:',
    delete: "Delete",
    newDash: "New Dashboard",
    save: "Save",
    myCards: "My Cards",
    addCard: "Add Cards",
    notCard: "You don't have any cards.",
    editCard: "Edit Card",
    deleteCard: "Delete Card",
    deleteMessageCard:
      'Attention! This action is irreversible. If you wish to continue, please type "DELETE" in the field below:',
    copyDashboard: "Copy",
    copyDashboardModal: "Select users to copy ",
    edit: "Edit",
    cardPanel: "Card Panel",
    cardSelection: "Card Selection",
    addCardType: "You can add new cards, according to your need.",
    exec: "Execute",
    topic: "Topic",
    commandReceived: "Command Received",
    commandSuccess: "Write command was successful!",
    commandFailed: "Command Failed! Error #2",
    commandPending: "Command sent, but we did not receive the command return",
    barChart: "Bar Graph",
    lineChart: "Line Graph",
    calendar: "Calendar",
    statistics: "Statistics",
    piechart2: "Piechart",
    streetview: "StreetView",
    statisticsState: "State",
    customCard: "Custom",
    formulaCard: "Formula card",
    thermometerCard: "Temperature",
    batteryCard: "Battery",
    gaugeCard: "Gauge",
    waterCard: "Water",
    mqttControl: "MQTT Control",

    cardOptions: "Card Options",
    projects: "Project",
    devices: "Device",
    variables: "Variable",
    refreshTime: "Refresh",
    typeVariable: "Variable Type",
    integral: "Integral",
    differential: "Differential",
    period: "Period",
    cancel: "Cancel",
    pleaseTypeVariable: "Please, select a variable type value",
    pleaseProject: "Please, select a project",
    noProject: "There's no registered projects to this user.",
    pleaseDevice: "Please, select a device",
    pleaseVariable: "Please, select a variable",
    pleasePeriod: "Please select a time range",
    pleaseRefresh: "Please, select an update period",
    seconds: "seconds",
    minutes: "minutes",
    formula: "Formula",
    unit: "Unit",

    state: "State",
    message: "Message",
    msgPlaceholder: "Type here what will be shown in the card",
    color: "Color",
    image: "Image",
    imageText:
      "For better resolution, please upload a square image in .jpg format",
    limitHead: "Limit between States",
    limitAnd: "and",
    value: "Value",
    includes: "Includes in",
    newState: "New State",

    noDataCharts: "No data found for the selected period",
    offlineCharts: "The device may be offline",
    noDataStats: "No data found",
    offlineStats: "Device offline",
    noComm: "Never had communicated",
    on: "ON",
    off: "OFF",
    refresh: "Refresh",

    // NOTE:DASHBOARDS TOOLTIP
    exportDashboardTooltip: "Exportar para URL",
    copyDashobardTooltip: "Copy dashboard",
    editDashobardTooltip: "Edit dashboard",
    deleteDashobardTooltip: "Delete dashboard",

    // NOTE:DASHBOARD CONFIRM TOASTR
    dashboardConfirmToastr:
      "Some users cannot access data of dashboard's device card. Would you like to add users to project now?",

    // NOTE:DASHBOARD EXPORT MODAL
    modalHeaderUrl: "Export to URL",
    urlName: "URL's name:",
    explanation:
      "The url cannot contain symbols, uppercase letters and empty spaces.",
    alert: "Attention!",
    deleteUrl: "Delete URL",
    editUrl: "Edit URL",
    successToastTitle: "Sucess",
    successToastMessage: "URL edit successfuly",
    warningToastTitle: "Error",
    warningToastMessage: "Failed to edit URL.",
    warningDeleteToastMessage: "Failed to delete URL.",
    successDeleteToastTitle: "Success",
    successDeleteToastMessage: "URL deleted successfully.",
    symbolsWarningToastTitle: "Caution",
    symbolsWarningToastMessage: " You are using uppercase letter or symbols.",
    smallWarningToastMessage:
      "URL too short. The URL must have at least four digits.",
    warningDeleteToastUrl:
      "CAUTION! This action cannot be undone. Are you sure you want to proceed?",
  },
}

export default enUsDashboardTranslations;