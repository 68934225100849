import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import enGB from "date-fns/locale/en-GB";
import { TreeSelect } from "antd";
import moment from "moment";
import ImgCrop from "antd-img-crop";
import { Upload, Image } from "antd";
import Resizer from "react-image-file-resizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useAPI } from "../SolarProvider";

import "../../../../../assets/scss/3-components/_antd.scss";
import { ModalAreaSettings } from "./components/ModalAreaSettings";
import ModalPvSyst from "./components/ModalPvSyst";

/****** MODAL DE EDIÇÃO DE DASHBOARD ******/
const ModalSolarSettings = ({ open, toggle }) => {
  const { i18n, t } = useTranslation();

  // Estados dos inputs
  // const [pricekWh, setpricekWh] = useState(priceState);
  const [isModalAreaSettingsOpen, setIsModalAreaSettingsOpen] = useState(false);
  const [isModalModalPvSystOpen, setIsModalModalPvSystOpen] = useState(false);

  function toggleModalSettings() {
    setIsModalAreaSettingsOpen(!isModalAreaSettingsOpen);
  }

  function toggleModalPvSyst() {
    setIsModalModalPvSystOpen(!isModalModalPvSystOpen);
  }

  const {
    projectId,
    selectDate,
    arvore,
    valores,
    priceState,
    powerState,
    area,
    efficiency,
    customer,
    location,
    productCluster,
    positioning,
    peakPower,
    contractStart,
    contractEnd,
    imagePlant,
    setImagePlant,
    setValores,
    setCustomer,
    setArea,
    setEfficiency,
    setPeakPower,
    setLocation,
    setPositioning,
    setPowerState,
    setPriceState,
    setProductCluster,
    setContractStart,
    setContractEnd,
    updateSolarData,
    createSolarData,
    loading,
    expectedEfficiency,
    setExpectedEfficiency,
    data,
    setData,
    readAreaConfigArray,
    areaConfigArray,
    params,
  } = useAPI();

  const [fileList, setFileList] = useState([]);

  function resizeFile(file) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        128,
        128,
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  }

  async function handleChange(e) {
    try {
      const fileList = e.file.originFileObj;
      const image = await resizeFile(fileList);
      setImagePlant(image);
      setFileList([e.file]);
    } catch (err) {
      console.log(err);
    }
  }

  /* salva o valor selecionado no clique */
  const onChange = (value) => {
    setValores(value);
  };

  return loading ? null : (
    <Modal isOpen={open} toggle={toggle}>
      <Form>
        <ModalHeader toggle={toggle}>{t("solar.settings")}</ModalHeader>
        <ModalBody style={{ padding: "10px 0px 0px 0px", margin: 0 }}>
          <div
            style={{
              maxHeight: "80vh",
              overflowY: "auto",
              width: "100%",
              padding: "0px 10px",
            }}
          >
            <Card>
              <CardHeader
                style={{ fontSize: "20px" }}
                className="font-weight-bold"
              >
                {t("solar.params")}
              </CardHeader>
              <CardBody>
                <Row style={{ justifyContent: "center" }}>
                  {/*** INPUT DEMANDA CONTRATADA ***/}
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                    xs={12}
                    md={6}
                  >
                    <FormGroup className="mx-2">
                      <Label className="my-2">{t("solar.price")}</Label>
                      <Input
                        type="number"
                        name="priceState"
                        id="priceState"
                        style={{
                          height: "30px",
                          fontSize: "14px",
                          color: "red !important",
                          textAlign: "center",
                        }}
                        value={priceState}
                        onChange={(e) => {
                          setPriceState(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {/*** INPUT POTÊNCIA INSTALADA ***/}
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                    xs={12}
                    md={6}
                  >
                    <FormGroup className="mx-2">
                      <Label className="my-2">
                        {t("solar.powerCard")} {t("solar.capacity")} (kWp)
                      </Label>
                      <Input
                        // className="form-control"
                        type="number"
                        name="powerState"
                        id="powerState"
                        style={{
                          height: "30px",
                          fontSize: "14px",
                          color: "red !important",
                          textAlign: "center",
                        }}
                        value={powerState}
                        onChange={(e) => {
                          setPowerState(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                  {/*** INPUT AREA ***/}
                  {/* <Col style={{ padding: "0px", marginTop: "4px", fontSize: "15px" }}>
                <FormGroup className="mx-2">
                  <Label className="my-2">{t('solar.area')} (m²)</Label>
                  <Input
                    // className="form-control"
                    type="number"
                    name="area"
                    id="area"
                    style={{ height: "30px", fontSize: "14px", color: "red !important", textAlign: "center" }}
                    value={area}
                    onChange={(e) => {
                      setArea(e.target.value)
                    }}
                  />
                </FormGroup>
              </Col> */}
                  {/*** INPUT DE REFERÊNCIA DE CONSUMO ***/}
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                    xs={12}
                    md={6}
                  >
                    <FormGroup className="mx-2">
                      <Label className="my-2">{t("solar.refConsuption")}</Label>
                      <TreeSelect
                        treeDataSimpleMode
                        maxTagCount="responsive"
                        treeData={arvore}
                        value={valores}
                        onChange={onChange}
                        treeCheckable={true}
                        style={{
                          width: "100%",
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {/*** INPUT RENDIMENTO DO MÓDULO ***/}
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                    xs={12}
                    md={6}
                  >
                    <FormGroup className="mx-2">
                      <Label className="my-2">
                        {t("solar.rendimento")} (%)
                      </Label>
                      <Input
                        // className="form-control"
                        type="number"
                        max="100"
                        name="efficiency"
                        id="efficiency"
                        style={{
                          height: "30px",
                          fontSize: "14px",
                          color: "red !important",
                          textAlign: "center",
                        }}
                        value={efficiency}
                        onChange={(e) => {
                          setEfficiency(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <br></br>

                {/*** INPUT PVSYST ***/}
                <Row style={{ justifyContent: "center" }}>
                  <Col
                    xs={6}
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                  >
                    <Label>{t("PVSyst")}</Label>
                  </Col>
                  <Col xs={5} style={{ paddingLeft: "0px" }}>
                    <FormGroup>
                      <Button
                        // className="form-control"
                        color="primary"
                        type="button"
                        onClick={toggleModalPvSyst}
                        name="expectedEfficiency"
                        id="expectedEfficiency"
                        style={{
                          height: "30px",
                          fontSize: "14px",
                          color: "red !important",
                          textAlign: "center",
                        }}
                      >
                        Configurar
                      </Button>
                    </FormGroup>
                  </Col>
                  <ModalPvSyst
                    open={isModalModalPvSystOpen}
                    toggle={toggleModalPvSyst}
                  />
                </Row>

                {/*** CONFIGURAR POR INVERSOR PREVISÃO ESPERADA ***/}
                <Row style={{ justifyContent: "center" }}>
                  <Col
                    xs={6}
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                  >
                    <Label>{t("solar.expectedEfficiency")}</Label>
                  </Col>
                  <Col xs={5} style={{ paddingLeft: "0px" }}>
                    <FormGroup>
                      <Button
                        color="primary"
                        type="button"
                        onClick={toggleModalSettings}
                        name="expectedEfficiency"
                        id="expectedEfficiency"
                        style={{
                          height: "30px",
                          fontSize: "14px",
                          color: "red !important",
                          textAlign: "center",
                        }}
                      >
                        Configurar
                      </Button>
                    </FormGroup>
                  </Col>

                  <ModalAreaSettings
                    open={isModalAreaSettingsOpen}
                    toggle={toggleModalSettings}
                    expectedEfficiency={expectedEfficiency}
                    setExpectedEfficiency={setExpectedEfficiency}
                    area={area}
                    data={data}
                    setData={setData}
                    setArea={setArea}
                    readAreaConfigArray={readAreaConfigArray}
                  />
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader
                style={{ fontSize: "20px" }}
                className="font-weight-bold"
              >
                {t("solar.details")}
              </CardHeader>
              <CardBody>
                <Row style={{ justifyContent: "center" }}>
                  {/*** INPUT CLIENTE ***/}
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                    xs={12}
                    md={6}
                  >
                    <FormGroup className="mx-2">
                      <Label className="my-2">{t("solar.cliente")}</Label>
                      <Input
                        type="text"
                        max="100"
                        name="customer"
                        id="customer"
                        style={{
                          height: "30px",
                          fontSize: "14px",
                          color: "red !important",
                        }}
                        value={customer}
                        onChange={(e) => {
                          setCustomer(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/*** INPUT LOCALIZAÇÃO ***/}
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                    xs={12}
                    md={6}
                  >
                    <FormGroup className="mx-2">
                      <Label className="my-2">{t("solar.localizacao")}</Label>
                      <Input
                        type="text"
                        max="100"
                        name="location"
                        id="location"
                        style={{
                          height: "30px",
                          fontSize: "14px",
                          color: "red !important",
                        }}
                        value={location}
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                  {/*** INPUT CONJUNTO DE PRODUTO ***/}
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                    xs={12}
                    md={4}
                  >
                    <FormGroup className="mx-2">
                      <Label className="my-2">
                        {t("solar.conjuntoProduto")}
                      </Label>
                      <Input
                        type="text"
                        max="100"
                        name="productCluster"
                        id="productCluster"
                        style={{
                          height: "30px",
                          fontSize: "14px",
                          color: "red !important",
                          textAlign: "center",
                        }}
                        value={productCluster}
                        onChange={(e) => {
                          setProductCluster(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/*** INPUT POSICIONAMENTO  ***/}
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                  >
                    <FormGroup className="mx-2">
                      <Label className="my-2">
                        {t("solar.posicionamento")}
                      </Label>
                      <Input
                        type="text"
                        max="100"
                        name="positioning"
                        id="positioning"
                        style={{
                          height: "30px",
                          fontSize: "14px",
                          color: "red !important",
                          textAlign: "center",
                        }}
                        value={positioning}
                        onChange={(e) => {
                          setPositioning(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {/*** INPUT PICO DE ENERGIA  ***/}
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                  >
                    <FormGroup className="mx-2">
                      <Label className="my-2">{t("solar.picoEnergia")}</Label>
                      <Input
                        type="text"
                        max="100"
                        name="peakPower"
                        id="peakPower"
                        style={{
                          height: "30px",
                          fontSize: "14px",
                          color: "red !important",
                          textAlign: "center",
                        }}
                        value={peakPower}
                        onChange={(e) => {
                          setPeakPower(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                  {/*** INPUT INÍCIO DE CONTRATO  ***/}
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                    xs={6}
                  >
                    <FormGroup className="mx-2">
                      <Label className="my-2">
                        {t("solar.contratoInicio")}
                      </Label>
                      <DatePicker
                        wrapperClassName="w-100 h-100 p-1 inputs form-control cursor-pointer"
                        className="w-100 h-100 p-1 inputs form-control cursor-pointer"
                        selected={Date.parse(contractStart)}
                        onChange={(date) => {
                          setContractStart(date);
                        }}
                        dateFormat="dd/MM/yyyy"
                        locale={i18n.language === "br" ? ptBR : enGB}
                      />
                    </FormGroup>
                  </Col>

                  {/*** INPUT FIM DE CONTRATO  ***/}
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                  >
                    <FormGroup className="mx-2">
                      <Label className="my-2">{t("solar.contratoFim")}</Label>
                      <DatePicker
                        wrapperClassName="w-100 h-100 p-1 inputs form-control cursor-pointer"
                        className="w-100 h-100 p-1 inputs form-control cursor-pointer"
                        selected={Date.parse(contractEnd)}
                        onChange={(date) => {
                          setContractEnd(date);
                        }}
                        dateFormat="dd/MM/yyyy"
                        locale={i18n.language === "br" ? ptBR : enGB}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                  {/*** INPUT IMAGEM DA PLANTA  ***/}
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                  >
                    <FormGroup className="mx-2">
                      <Label className="my-2">{t("solar.imagemPlanta")}</Label>
                      <ImgCrop aspect={9 / 7}>
                        <Upload
                          listType="picture-card"
                          fileList={fileList}
                          showUploadList={false}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          {imagePlant ? (
                            <div>
                              <Image src={imagePlant} width={100} />
                              <FontAwesomeIcon
                                className="mx-2"
                                fixedWidth
                                icon={faEdit}
                              />
                            </div>
                          ) : (
                            fileList.length < 2 && (
                              <FontAwesomeIcon fixedWidth icon={faPlus} />
                            )
                          )}
                        </Upload>
                      </ImgCrop>
                    </FormGroup>
                  </Col>
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "4px",
                      fontSize: "15px",
                    }}
                  ></Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="mr-1 mb-1"
            onClick={toggle}
            outline
          >
            {t("dataTable.cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            className="mr-1 mb-1"
            onClick={(e) => {
              e.preventDefault();
              toggle();
              const jsonData = {
                projId: projectId,
                monthRef: !selectDate
                  ? moment().format("YYYY-MM-DD")
                  : moment(selectDate).format("YYYY-MM-DD"),
                pricekWh: priceState,
                energyDevices: valores.length !== 0 ? valores : null,
                area,
                efficiency,
                capacity: powerState,
                customer,
                location,
                productCluster,
                positioning,
                peakPower,
                contractStart: contractStart
                  ? moment(contractStart).format("YYYY-MM-DD")
                  : null,
                contractEnd: contractEnd
                  ? moment(contractEnd).format("YYYY-MM-DD")
                  : null,
                imagePlant,
                areaConfigArray,
              };

              params ? updateSolarData(jsonData) : createSolarData(jsonData);
            }}
          >
            {t("solar.save")}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export { ModalSolarSettings };
