import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';

const MonthlyEnergy = ({ monthlyEnergy, loading, loadingModal }) => {
  const { t } = useTranslation();

  if (monthlyEnergy) {
    var monthlyEnergyEnergyAndUnit = (monthlyEnergy < 1000)
      ? (monthlyEnergy.toFixed(2)).replace(".", ",") + " kWh"
        : (monthlyEnergy < 1000000)
          ? (parseFloat((monthlyEnergy/1000).toFixed(2))).toString().replace(".", ",") + " MWh"
            : (monthlyEnergy < 1000000000)
              ? (parseFloat((monthlyEnergy/1000000).toFixed(2))).toString().replace(".", ",") + " GWh"
                : (monthlyEnergy < 1000000000000)
                  ? (parseFloat((monthlyEnergy/1000000000).toFixed(2))).toString().replace(".", ",") + " TWh"
                  : ""
  }               

  return (
    <Row style={{minWidth: "147px"}}>
      <Col sm="12" style={{paddingRight:"0px"}}>
        <Card className="solar-top-card">
          <CardBody style={{padding: "15px"}}>
            <Row>
              <Col className="mt-0">
                <CardTitle tag="h5">{t('solar.monthly')}<br></br>{t('solar.monthlyEnergy')}</CardTitle>
              </Col>
              <Col className="col-auto">
                <div style={{width:"26px",height:"26px"}}>
                  <div className="avatar-title rounded-circle bg-primary-dark">
                    <FontAwesomeIcon icon={faCalendarCheck} fixedWidth className="align-middle" />
                  </div>
                </div>
              </Col>
            </Row>
            {(loadingModal)
              ? <div className="text-center mb-4"><Spinner /></div>
              : (loading)
                ? <div className="text-center mb-4"> <Spinner color="primary"/></div>
                : (monthlyEnergy)
                  ? <h1 className="display-5 text-center" style={{fontSize:"17px", fontWeight:"500"}}>{monthlyEnergyEnergyAndUnit}</h1>
                  : <p className="text-center">Não há dados</p>
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}   

export default MonthlyEnergy;