import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";
// import { Droplet } from "react-feather";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView } from '@fortawesome/free-solid-svg-icons';

const ExpectedGeneration = ({projectedEnergy}) => {
  const { t } = useTranslation();

  const projectedEnergyAndUnit = (projectedEnergy < 1000)
  ? projectedEnergy.toFixed(1).replace(".", ",") + " kWh"
    : (projectedEnergy < 1000000)
      ? parseFloat(projectedEnergy/1000).toFixed(1).replace(".", ",") + " MWh"
        : (projectedEnergy < 1000000000)
          ? parseFloat(projectedEnergy/1000000).toFixed(1).replace(".", ",") + " GWh"
            : (projectedEnergy < 1000000000000)
              ? parseFloat(projectedEnergy/1000000000).toFixed(1).replace(".", ",") + " TWh"
              : "";

  return (
    <div className="w-100">
      <Row >
        <Col sm="12" style={{paddingRight:"0px"}}>
          <Card style={{minWidth: "200px", height: "140px", backgroundColor: "#dde0e5", marginBottom: "18px"}}>
            <CardHeader style={{padding: "25px 25px 21px 25px"}}>
              <Row>
                <Col className="mt-0">
                  <CardTitle tag="h5">{t('solar.expectedGeneration')}</CardTitle>
                </Col>
                <Col className="col-auto" style={{paddingLeft:"0px"}}>
                <div>
                  <FontAwesomeIcon 
                    icon={faStreetView} 
                    style={{fontSize:"22px"}}
                  />
                  </div>
                </Col>
              </Row>
            </CardHeader>  
            <CardBody style={{paddingTop:"0px"}}>  
              {(projectedEnergyAndUnit)
                ? <h2 className="text-center"> {projectedEnergyAndUnit}</h2>
                : <div className="text-center mb-4"><Spinner /></div>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}   

export default ExpectedGeneration;