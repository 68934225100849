import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";

import * as Icon from "react-feather";
import { MoreVertical } from "react-feather";
import { Link } from "react-router-dom";
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

import axios from "axios";
import ModalDeleteCard from "../ModalDeleteCard";

function CardDropdown(props) {
  const { t } = useTranslation();

  const [openDeleteCard, setOpenDeleteCard] = useState(false);
  const [editCard, setEditCard] = useState(false);

  function toggleDeleteCard() {
    setOpenDeleteCard(!openDeleteCard);
  }

  return (
    <UncontrolledDropdown className="d-inline-block" disabled={props.preview}>
      <DropdownToggle tag="a">
        <MoreVertical />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          onClick={() => {
            toggleDeleteCard();
          }}
        >
          {t("dashboards.delete")}
        </DropdownItem>
        {openDeleteCard ? (
          <ModalDeleteCard
            open={openDeleteCard}
            toggle={toggleDeleteCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
          />
        ) : null}
        <Link
          onClick={() => setEditCard(props.cardId)}
          to={{
            pathname: `/newCard/mqttControl/${props.cardId}`,
            state: {
              cardProps: props.card,
              editCard: editCard,
            },
          }}
        >
          <DropdownItem>{t("dashboards.edit")}</DropdownItem>
        </Link>

        <DropdownItem
          onClick={() => {
            props.setDataLoading(true);
          }}
        >
          {t("dashboards.refresh")}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

const MqttControl = ({
  cardId,
  card,
  name,
  description,
  deviceId,
  variables,
  tableReload,
  preview,
  dimensions,
  value = 50,
}) => {
  const { t } = useTranslation();
  const [dataLoading, setdataLoading] = useState(true);
  const [device, setDevice] = useState("");
  const [cardHeight, setCardHeight] = useState();
  const [varUnit, setVarUnit] = useState("");
  const [lastComm, setLastComm] = useState();
  const [loading, setLoading] = useState(false);

  const varName = variables ? JSON.parse(variables)?.varName : "";

  const lastCommDate = new Date(lastComm * 1000);

  const handleExecuteConnection = async () => {
    const data = {
      userId: localStorage.getItem("userId"),
      cardId,
    };
    setLoading(true);
    axios
      .post("v2/mqtt/execute", data)
      .then((result) => {
        if (String(result.data.messageBody) === "Command Failed! Error #2") {
          toastr.warning(
            result.data.messageHead,
            t("dashboards.commandFailed")
          );
        } else if (result.status === 202) {
          toastr.warning(
            result.data.messageHead,
            t("dashboards.commandPending")
          );
        } else {
          toastr.success(
            result.data.messageHead,
            t("dashboards.commandSuccess")
          );
        }
      })
      .catch((err) => {
        if (err.response.data) {
          /* Notificação de erro de envio */
          toastr.warning(
            err.response.data.messageHead,
            err.response.data.messageBody
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dimensions) {
      if (dimensions?.id === card?.id) {
        let var1 =
          dimensions.size.height < 240 &&
          dimensions.size.width < 240 &&
          card.id === dimensions.id
            ? 69
            : dimensions.size.height > 241 &&
              dimensions.size.width > 320 &&
              card.id === dimensions.id &&
              dimensions.size.height < 320
            ? 110
            : dimensions.size.height > 321 &&
              dimensions.size.width > 370 &&
              card.id === dimensions.id &&
              dimensions.size.height < 370
            ? 200
            : dimensions.size.height > 371 &&
              dimensions.size.width > 420 &&
              card.id === dimensions.id &&
              dimensions.size.height < 420
            ? 250
            : dimensions.size.height > 421 &&
              dimensions.size.width > 500 &&
              card.id === dimensions.id &&
              dimensions.size.height < 500
            ? 290
            : dimensions.size.height > 501 &&
              dimensions.size.width > 501 &&
              card.id === dimensions.id
            ? 350
            : 70;
        setCardHeight(var1);
      }
    }
  }, [dimensions, card?.id]);

  return (
    <div id="parent">
      <CardHeader>
        <div className="card-actions float-right" style={{ marginTop: "2px" }}>
          <Row className="card-actions float-right">
            <Col className="pl-0" style={{ paddingRight: "3px" }}>
              <div className="box no-cursor cursor-pointer mp-0 otavio">
                <Icon.Move style={{ width: "15px" }} />
              </div>
            </Col>
            <CardDropdown
              card={card}
              cardId={cardId}
              tableReload={tableReload}
              name={name}
              description={description}
              preview={preview}
              setDataLoading={setdataLoading}
            />
          </Row>
        </div>
        <div id={"name" + cardId}>
          {name ? (
            <UncontrolledTooltip
              style={{
                backgroundColor: "#202020",
                opacity: 1,
                fontSize: ".8rem",
                color: "#F1F1F1",
                border: "1px solid #000",
                padding: "1rem",
              }}
              placement="top"
              target={"name" + cardId}
            >
              {name}
            </UncontrolledTooltip>
          ) : null}
          <CardTitle
            style={{ whiteSpace: "nowrap" }}
            className="mr-5 overflow-hidden text-left my-1 h5"
          >
            {name}
          </CardTitle>
          <CardSubtitle
            style={{ whiteSpace: "nowrap" }}
            className="mr-5 overflow-hidden text-muted text-left"
          >
            {description}
          </CardSubtitle>
          {description ? (
            <UncontrolledTooltip
              style={{
                backgroundColor: "#202020",
                opacity: 1,
                fontSize: ".8rem",
                color: "#F1F1F1",
                border: "1px solid #000",
                padding: "1rem",
              }}
              placement="bottom"
              target={"name" + cardId}
            >
              {description}
            </UncontrolledTooltip>
          ) : null}
        </div>
      </CardHeader>
      <CardBody
        id={"desc" + cardId}
        className="text-center overflow-hidden"
        style={{
          whiteSpace: "nowrap",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          style={{
            background: "transparent",
            border: "2px solid #153d77",
            borderRadius: "8px",
            color: "#153d77",
          }}
          onClick={handleExecuteConnection}
        >
          {t("dashboards.exec")}
        </Button>
      </CardBody>
      {cardHeight > 80 ? (
        <CardFooter
          className="text-left mt-1"
          style={{
            backgroundColor: "transparent",
            bottom: "0",
            position: `${!preview ? "absolute" : null}`,
            padding: "5px 1.25rem",
          }}
        >
          <h6 className="text-left">{device.description}</h6>
          <h6 className="card-subtitle text-muted">{varName}</h6>
          <h6 className="card-subtitle text-muted mt-1">
            {lastComm
              ? lastCommDate.getDate().toString().padStart(2, "0") +
                "/" +
                (lastCommDate.getMonth() + 1).toString().padStart(2, "0") +
                "/" +
                lastCommDate.getFullYear().toString().padStart(2, "0") +
                " " +
                lastCommDate.getHours().toString().padStart(2, "0") +
                ":" +
                lastCommDate.getMinutes().toString().padStart(2, "0") +
                ":" +
                lastCommDate.getSeconds().toString().padStart(2, "0")
              : t("dashboards.noComm")}
          </h6>
        </CardFooter>
      ) : null}
    </div>
  );
};

export default MqttControl;
