import React from "react";
import classNames from "classnames";

const defaultProps = {
  tag: "div"
};

var FontTitle = (window.innerWidth < 400) ? "11px" : "0.9rem";

const Header = ({ children, className, tag: Tag, ...rest }) => (
  <Tag className={classNames("header", className)} {...rest} style={{fontSize: FontTitle}}>
    {children}
  </Tag>
);

Header.defaultProps = defaultProps;

export default Header;
