import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import "chartjs-plugin-doughnutlabel";
import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
  Table,
} from "reactstrap";
import DoughnutModal from "./DoughnutModal";

const LegendTable = ({ label, index, color, devices }) => {
  const [openModal, setOpenModal] = useState(false);

  function toggleModal() {
    setOpenModal(!openModal);
  }

  return (
    <tr
      className="p-0 cursor-pointer"
      style={{ padding: "2px" }}
      onClick={toggleModal}
    >
      <td style={{ padding: "2px" }}>
        <FontAwesomeIcon icon={faCircle} color={color} /> {label}
      </td>
      <td className="text-right" style={{ padding: "2px" }}>
        {devices.length}
      </td>
      <DoughnutModal
        open={openModal}
        toggle={toggleModal}
        index={index}
        devices={devices}
      />
    </tr>
  );
};

const DoughnutChart = ({ theme }) => {
  const { t } = useTranslation();

  const { data: deviceList, status } = useQuery({
    queryKey: "deviceUserSearch",
    queryFn: async () => {
      const response = await axios.get("/deviceUserSearch");
      return response.data;
    },
    refetchInterval: 1000 * 30,
  });

  const { onlines, offlines, noComms } = !deviceList ?
  { onlines: [], offlines: [], noComms: [] }
  : deviceList.reduce(
    (acc, device) => {
      if (!device.lastComm) {
        acc.noComms.push(device);
      } else if (
        device.lastComm <
        Date.now() / 1000 - device.commInterval * 2
      ) {
        acc.offlines.push(device);
      } else {
        acc.onlines.push(device);
      }
      return acc;
    },
    { onlines: [], offlines: [], noComms: [] }
  );

  const isLoading = status === "loading";

  const data = {
    /* label do gráfico */
    labels: [t("home.online"), t("home.offline"), t("home.noComm")],
    datasets: [
      /* data popula com as infos e background a sua cor referente, de acordo com o theme*/
      {
        data: [onlines.length, offlines.length, noComms.length],
        backgroundColor: [theme.success, theme.warning, theme.secondary],
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 65,
    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: data.datasets[0].data.reduce((a, b) => a + b, 0),
            font: {
              size: "25",
            },
            color: "black",
          },
          {
            text: "Total",
            font: {
              size: "20",
            },
          },
        ],
      },
    },
    legend: {
      position: "bottom", //posição da legenda
      display: false, // desativa a legenda nativa do componente
    },
  };

  return (
    <Card style={{ minWidth: "247px" }}>
      <CardHeader>
        <CardTitle
          tag="h5"
          style={{ paddingBottom: "0px", marginBottom: "0px" }}
        >
          {t("home.connectivity")}
        </CardTitle>
        <h6 className="card-subtitle text-muted"></h6>
      </CardHeader>
      <CardBody style={{ height: "280px", paddingTop: "0px" }}>
        {isLoading ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <div
            className="chart chart-xs"
            style={{
              height: "166px",
              minHeight: "100px",
              marginBottom: "18px",
            }}
          >
            <Doughnut data={data} options={options} />
            <Table className="mt-3" style={{ padding: "0" }}>
              <tbody>
                {data.labels.map((label, index) => (
                  <LegendTable
                    key={index}
                    label={label}
                    index={index}
                    color={data.datasets[0].backgroundColor[index]}
                    devices={
                      index === 0 ? onlines : index === 1 ? offlines : noComms
                    }
                  />
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(DoughnutChart);
