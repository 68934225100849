import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import {
  PRESET,
  useWindowDimensions,
} from "../../../../../igDashboards/view/hooks/useWindowDimensions";
import ConsumChart from "./carouselComponents/ConsumChart";
import General from "./carouselComponents/General";
import { MeterInfo } from "./carouselComponents/MeterInfo";
import CarouselGeneral from "./modals/CarouselGeneral";
import CarouselSimple from "./modals/CarouselSimple";
function groupConsumptionByDayForAllRegisters(registers) {
  console.log("registers", registers);

  const totalConsumptionPerDay = {};
  const reg = registers ?? [];

  reg?.forEach((register) => {
    register.consumptionPerDay?.forEach((entry) => {
      const date = entry.timestamp.split(" ")[0];
      const consumption = entry.consumption;

      if (!totalConsumptionPerDay[date]) {
        totalConsumptionPerDay[date] = 0;
      }

      totalConsumptionPerDay[date] += consumption;
    });
  });

  const newArray = [];
  Object.keys(totalConsumptionPerDay).forEach((key) => {
    newArray.push({
      timestamp: key,
      consumption: parseFloat(totalConsumptionPerDay[key].toFixed(2)),
    });
  });

  return newArray;
}

const CarouselCard = ({ generalDataConsumption, selectedUC, loading }) => {
  const { t } = useTranslation();
  const { preset, height } = useWindowDimensions();

  const [ucValues, setUcValues] = useState({});

  useEffect(() => {
    if (selectedUC !== "general" && generalDataConsumption) {
      const generalUcConsumptionPerDay = groupConsumptionByDayForAllRegisters(
        Array.isArray(generalDataConsumption)
          ? generalDataConsumption
          : [generalDataConsumption]
      );
      const generalUcConsumption = generalUcConsumptionPerDay?.reduce(
        (sum, value) => sum + value.consumption,
        0
      );

      setUcValues({
        generalDataConsumption: Array.isArray(generalDataConsumption)
          ? generalDataConsumption
          : [generalDataConsumption],
        generalUcConsumptionPerDay: Array.isArray(generalUcConsumptionPerDay)
          ? generalUcConsumptionPerDay
          : [generalUcConsumptionPerDay],
        generalUcConsumption,
      });
    }
  }, [generalDataConsumption, selectedUC]);

  const isGeneral = selectedUC === "general";

  return (
    <Row>
      <Col
        xs={12}
        lg={!isGeneral ? 9 : 12}
        className={`${!isGeneral ? `pr-0` : ""}`}
      >
        <Card>
          <CardHeader className="d-flex justify-content-between">
            <CardTitle tag="h5" className="mb-0">
              {selectedUC === "general"
                ? t("water.plusInfos")
                : t("water.ucInfo") + selectedUC.description}
            </CardTitle>
          </CardHeader>

          {loading ? (
            <div className="text-center mt-2">
              <Spinner color="primary" />
            </div>
          ) : (
            <CardBody className="text-center">
              {selectedUC === "general" ? (
                <>
                  {generalDataConsumption?.generalConsumptionPerDay?.length >
                  0 ? (
                    <CarouselGeneral
                      generalDataConsumption={generalDataConsumption}
                    />
                  ) : (
                    <p>{t("water.noData")}</p>
                  )}
                </>
              ) : (
                <CarouselSimple
                  components={[
                    ucValues?.generalUcConsumptionPerDay?.length > 0 ? (
                      <>
                        <CardTitle tag="h5" className="mb-0">
                          {t("water.generalConsumption")}{" "}
                          {selectedUC?.description}
                        </CardTitle>
                        <CardBody
                          style={{
                            height:
                              preset === PRESET.MOBILE ? 300 : height - 480,
                          }}
                        >
                          <ConsumChart
                            registers={ucValues?.generalUcConsumptionPerDay}
                            dashName={selectedUC?.description}
                          />
                        </CardBody>
                      </>
                    ) : null,
                    ucValues?.generalDataConsumption?.map((meter, index) => (
                      <div key={index}>
                        <CardTitle tag="h5" className="m-0">
                          {t("water.registerNo")}: {meter.registerNo}
                        </CardTitle>
                        <CardBody>
                          <MeterInfo meterInfo={meter} />
                          <div
                            style={{
                              height:
                                preset === PRESET.MOBILE ? 300 : height - 660,
                              padding: 0,
                            }}
                          >
                            <ConsumChart
                              registers={meter?.consumptionPerDay}
                              dashName={meter.registerNo + index}
                            />
                          </div>
                        </CardBody>
                      </div>
                    )),
                  ]}
                />
              )}
            </CardBody>
          )}
        </Card>
      </Col>
      {selectedUC !== "general" && (
        <Col xs={12} lg={3} className="mx-0 px-0">
          <General ucData={ucValues} />
        </Col>
      )}
    </Row>
  );
};

export default CarouselCard;
