const ptBrHomeTranslations = {
    homePage: {
        title: "Página Inicial"
    },
    menuCard: {
        myAccount: "Minha conta",
        sharing: "Rateio de Consumo"
    },
    public: {
        expand: "Expandir",
        lastComm: "Última comunicação",
        deviceModel: "Modelo",
        deviceStatus: "Status",
    },
    energyModule: {
        generalVision: "Solar Module OverView",
        energy: "Energy",
        noEnergyProjects: "There is no Energy type plants",
        market: "Market",
        group: "Group/Subgroup",
        tariffStructure: "Tariff Structure",
        flag: "Flag",
        company: "Utility Company",
        totalDemand: "Total Demand",
        peakDemandStatus: "Peak Demand",
        offPeakStatus: "Peak F Demand",
        powerFactorStatus: "Power Factor",
        billingSimulation: "Billing Simulation",
        energyStatistics: "Energy Statistics",
        maxDemand: "Max Peak Demand",
        maxFDemand: "Max F Peak Demand",
        worstCaseIND: "Worst Case PF IND",
        worstCaseCAP: "Worst Case PF CAP",
        kVar: "kVAR Needed",
        peakDemand: "Peak",
        offPeak: "Off Peak",
        headerTitle: "Consumption",
        headerDescription: "Tariff month",
        dailyHeaderTitle: "Daily Consuption",
        dailyHeaderDescription: "Month",
        maxHeaderTitle: "Max Demand",
        maxHeaderDescription: "Month",
        powerFactorHeaderTitle: "Power Factor",
        powerFactorHeaderDescription: "Month",
        powerFactor: "Power Factor",
        noDataMonth: "Não há dados para o mês selecionado.",
    },
    energy: {
        energyVision: "Visão Geral do Módulo Energia",
        energy: "Energia",
        pvEnergy: "Planta de Energia",
        totalConsumption: "Consumo Total",
        peak: "Consumo Ponta",
        offPeak: "Consumo Fora Ponta",
        status: "Status",
        tooltipOff: "Falha de comunicação em todos os dispositivos",
        tooltipOn: "Todos os dispositivos estão comunicando",
        tooltipWarning: "Falha de comunicação em um ou mais dispositivos",
        noDataPlants: "Não há plantas do tipo Energia",
        actualMonth: "(Mês Atual)",
        demand: "Demanda",
        reactiveDemand: "Demanda Reativa",

    }
}

export default ptBrHomeTranslations;