import moment from "moment";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

export const SelectMonth = ({ selectedDate = moment().toDate(), setSelectedDate }) => {
    const { i18n, t } = useTranslation();
  
    return (
      <DatePicker
        className="inputs form-control cursor-pointer text-center"
        selected={selectedDate}
        onChange={(date) => {
          setSelectedDate(date);
        }}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        placeholderText={t("solar.selectMonth")}
        locale={i18n.language === "br" ? "ptBR" : "enGB"}
        maxDate={selectedDate}
      />
    );
  };
  