import { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "react-bootstrap";
import { BsSnow } from "react-icons/bs";
import { GoZap } from "react-icons/go";
import { MdOutlineGasMeter, MdOutlineWaterDrop } from "react-icons/md";
import { PiSolarPanelLight } from "react-icons/pi";
import { useHistory } from "react-router-dom";
import { IconModule } from "../../../../../../../components/IconModule";
import { ModalProjects } from "./components/ModalProjects";

export const CardProject = ({ item, accordion, color }) => {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const handleCardClick = async () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleNavigate = (value, module, projectId) => {
    if (value) history.push(`/project-${projectId}-${module}`);
  };

  return (
    <>
      <Card
        style={{
          marginBottom: "4px",
          borderRadius: "5px",
          borderTop: `4px solid ${color}`,
          transition: "background-color 0.3s ease-in-out",
          padding: "10px",
        }}
        onClick={handleCardClick}
      >
        <CardHeader className="p-0">
          <CardTitle>{item.projectName}</CardTitle>
        </CardHeader>
        <CardBody className="p-0">
          <Row className="m-0 mb-2 mt-3 align-items-center">
            <Col className="d-flex m-0 p-0" style={{ gap: "0.375rem" }}>
              <IconModule
                IconComponent={PiSolarPanelLight}
                moduleValue={item.solarModuleOn}
                onClick={() =>
                  handleNavigate(item.solarModuleOn, "solar", item.projectId)
                }
                className="no-card-click"
              />
              <IconModule
                IconComponent={GoZap}
                moduleValue={item.powerModuleOn}
                onClick={() =>
                  handleNavigate(item.powerModuleOn, "energy", item.projectId)
                }
                className="no-card-click"
              />
              <IconModule
                IconComponent={BsSnow}
                moduleValue={item.climateModuleOn}
                onClick={() =>
                  handleNavigate(
                    item.climateModuleOn,
                    "climate",
                    item.projectId
                  )
                }
                className="no-card-click"
              />
              <IconModule
                IconComponent={MdOutlineWaterDrop}
                moduleValue={item.waterModuleOn}
                onClick={() =>
                  handleNavigate(item.waterModuleOn, "water", item.projectId)
                }
                className="no-card-click"
              />
              <IconModule
                IconComponent={MdOutlineGasMeter}
                moduleValue={item.gasModuleOn}
                onClick={() =>
                  handleNavigate(item.gasModuleOn, "gas", item.projectId)
                }
                className="no-card-click"
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      {showModal && (
        <ModalProjects
          onClose={handleCloseModal}
          open={showModal}
          item={item}
        />
      )}
    </>
  );
};
