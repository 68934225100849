import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import DataCard from "./DataCard";
import { IoWater } from "react-icons/io5";
import { AiOutlineFieldTime, AiOutlineDollarCircle } from "react-icons/ai";
import { MdOutlineApartment, MdOutlineAttachMoney } from "react-icons/md";
import { BiWater } from "react-icons/bi";

export const HeaderCards = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Row className="mt-2 water-cards">
      <Col>
        <DataCard
          title={t("water.companyRead")}
          value={data?.dealerConsumption ?? t("none")}
          unit="m³"
          icon={<IoWater className="feather align-middle" />}
        />
      </Col>

      <Col>
        <DataCard
          title={t("water.meterRead")}
          value={data?.generalConsumption ?? t("none")}
          unit="m³"
          icon={<AiOutlineFieldTime className="feather align-middle" />}
        />
      </Col>

      <Col>
        <DataCard
          title={t("water.comumArea")}
          value={data?.commonAreaConsumption ?? t("none")}
          unit="m³"
          icon={<MdOutlineApartment className="feather align-middle" />}
        />
      </Col>

      <Col>
        <DataCard
          title={t("water.companyBudget")}
          value={data?.utilityBill ?? t("none")}
          unit="R$"
          reverse
          icon={<AiOutlineDollarCircle className="feather align-middle" />}
        />
      </Col>

      <Col>
        <DataCard
          title={t("water.comumAreaCost")}
          value={data?.countMetering ?? t("none")}
          unit="R$"
          reverse
          icon={<MdOutlineAttachMoney className="feather align-middle" />}
        />
      </Col>
      <Col>
        <DataCard
          title={t("water.cubicMeterPrice")}
          value={data?.pricem3 ?? t("none")}
          unit="R$"
          reverse
          icon={<BiWater className="feather align-middle" />}
        />
      </Col>
    </Row>
  );
};
