import axios from "axios";

export const registerContract = async (formData) => {
  return await axios.post("v2/noc/registerContract", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const readCompanies = async () => {
  return await axios.get("v2/noc/readCompanies");
};

export const readCompanyUsers = async () => {
  return await axios.get(`v2/noc/readCompanyUsers`);
};

export const readListNocData = async (companyId) => {
  return await axios.get(`v2/noc/readListNocData?companyId=${companyId}`);
};

export const readSelectOptions = async (option) => {
  try {
    const response = await axios.get(
      `v2/noc/readSelectOptions?typeSelect=${option}`
    );
    return response;
  } catch (error) {
    console.log(error);
    return { data: [] };
  }
};

export const readCardValues = async (nocId) => {
  return await axios.get(`v2/noc/readCardValues?nocId=${nocId}`);
};

export const updateContract = async (formData) => {
  return await axios.put("v2/noc/updateContract", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteFile = async (fileKey, nocId) => {
  return await axios.delete(`v2/noc/deleteFile?fileKey=${fileKey}&nocId=${nocId}`);
}

export const readProject = async (projectName, companyId) => {
  return await axios.get(`v2/noc/readProject?projectName=${projectName}&companyId=${companyId}`)
};