import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { useAPI } from '../../SolarProvider';


export const InverterConfigArea = ({
  inverter,
  updateArrayWithEfficiency,
}) => {
  const { t } = useTranslation();

  const { readAreaConfigArray } = useAPI();

  let findId = readAreaConfigArray?.find(item => item.id === inverter.id)

  const [areaEfficiency, setAreaEfficiency] = useState((readAreaConfigArray?.length) ? findId?.inverterEfficiency : "");
  // const [areaEditEfficiency, setAreaEditEfficiency] = useState((readAreaConfigArray.length) ? findId.inverterEfficiency : "");
  const [isMounted, setIsMounted] = useState(false);

  // function formatarNumeroPtBR(numero) {
  //   // Remove todos os caracteres não numéricos
  //   const numeroLimpo = numero.replace(/\D/g, '');

  //   // Formata o número com separador de milhares e separador decimal
  //   const numeroFormatado = numeroLimpo.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  //   return numeroFormatado;
  // }

  useEffect(() => {
    setIsMounted(true)
    // if (readAreaConfigArray.length > 0) {
    //   updateArrayWithEfficiency({ id: findId.id, inverterEfficiency: areaEfficiency })
    // } else {
    updateArrayWithEfficiency({ id: inverter.id, inverterEfficiency: areaEfficiency })
    // }

    // eslint-disable-next-line
  }, [areaEfficiency])

  // function updateArray(efficiency) {
  //   let inverterData = inverter
  //   console.log(inverterData)
  //   setArrayInverterArea(prevData => [...prevData, inverter])
  // }
  // console.log("readAreaConfigArray", readAreaConfigArray)


  return (

    (readAreaConfigArray?.length > 0 && isMounted) ? (
      <Row className="d-flex align-items-center">
        <Col className="d-flex justify-content-center" sm={8} style={{ padding: "0px", marginTop: "4px", fontSize: "15px" }}>
          <Label>{t("solar.area") + " " + inverter.deviceName}</Label>
        </Col>
        <Col sm={4} style={{ paddingLeft: "0px" }}>
          <Input
            type="number"
            name="area"
            id="area"
            // placeholder={`O valor atual é: R$  ${area}`}
            style={{ height: "30px", fontSize: "14px", color: "red !important", textAlign: "center" }}
            value={areaEfficiency}
            onChange={(e) => setAreaEfficiency(e.target.value)}
          />
        </Col>
      </Row>
    ) :
      <Row className="d-flex align-items-center">
        <Col className="d-flex justify-content-center" sm={8} style={{ padding: "0px", marginTop: "4px", fontSize: "15px" }}>
          <Label>{t("solar.area") + " " + inverter.deviceName}</Label>
        </Col>
        <Col sm={4} style={{ paddingLeft: "0px" }}>
          <Input
            type="number"
            name="area"
            id="area"
            // placeholder={`O valor atual é: R$  ${area}`}
            style={{ height: "30px", fontSize: "14px", color: "red !important", textAlign: "center" }}
            value={areaEfficiency}
            onChange={(e) => setAreaEfficiency(e.target.value)}
          />
        </Col>
      </Row>

  )
}


export const ModalAreaSettings = ({
  open,
  toggle,
  data,
  areaConfigArray,
}) => {
  const { t } = useTranslation();
  const [newArrayWithEfficiency, setNewArrayWithEfficiency] = useState([]);
  const { setAreaConfigArray } = useAPI();

  function handleSaveAreaEfficiency() {
    setAreaConfigArray([])
    setAreaConfigArray(prevData => [...prevData, ...newArrayWithEfficiency]);
    toggle();
  }

  function updateArrayWithEfficiency(inverterObj) {
    const updatedInverter = {
      ...inverterObj,
    };

    setNewArrayWithEfficiency(prevArray => {
      const updatedArray = prevArray.filter(
        item => item.id !== updatedInverter.id
      );
      return [...updatedArray, updatedInverter];
    });
  }

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      size='md'
      scrollable
    >
      <ModalHeader toggle={toggle}>
        {t("Configurar área por Inversor")}
      </ModalHeader>

      <ModalBody className="d-flex flex-column align-items-center">
        <Row>
          <Col style={{ maxWidth: 400 }}>
            <Label tag={"h4"} className="mb-4 text-center">
              O fator de multiplicação do inversor deve ser preenchido de acordo com
              a área do módulo x número de módulos x eficiência do inversor e deve
              ser utilizado no seguinte formato: 1250,15
              {/* Exmplo (Amod*nMod*nInv) */}
              {/* A área coberta deve ser adicionada em m² utilizando somente números */}
            </Label>
          </Col>
        </Row>


        {(data.devices) ?
          data.devices.map(inverter => (
            <FormGroup key={inverter.id}>
              <InverterConfigArea
                data={data}
                // readAreaConfigArray={readAreaConfigArray}
                inverter={inverter}
                arrayInverterArea={areaConfigArray}
                setArrayInverterArea={setAreaConfigArray}
                updateArrayWithEfficiency={updateArrayWithEfficiency}
              />
            </FormGroup>
          ))
          : null}

      </ModalBody>

      <ModalFooter>
        <Button
          type="button"
          color='primary'
          className="mb-1"
          onClick={handleSaveAreaEfficiency}
        >
          {t('solar.save')}
        </Button>
        <Button
          color='primary'
          className="mb-1"
          onClick={toggle}
          outline

        >
          {t('solarReport.closeButton')}
        </Button>
      </ModalFooter>
    </Modal >
  )
}