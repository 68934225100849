import React, { useEffect, useState } from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import {
  Breadcrumb, BreadcrumbItem,
  Card, CardBody, CardHeader, CardTitle,
  Container,
  Row, Spinner, UncontrolledTooltip
} from "reactstrap";
import {
  ChevronDown,
  ChevronRight,
  ChevronsDown,
  ChevronsRight
} from "react-feather";
import {
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from 'react-i18next';
import { toastr } from "react-redux-toastr";

import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";
import ModalAddNewDriver from "./ModalAddNewDriver";
import ExpandedRowData from "./ExpandedRowData";


import ModalDeleteDriver from "./ModalDeleteDriver"
import ModalEditVar from "./ModalEditDriver";


const ActionButtonsComp = ({ driver, tableReload }) => {

  const { t } = useTranslation();

  const [openEditDriver, setOpenEditDriver] = useState(false);
  const [openDeleteDriver, setOpenDeleteDriver] = useState(false);

  // ativa/desativa modais
  function toggleEditDriver() {
    setOpenEditDriver(!openEditDriver);
  }
  function toggleDeleteDriver() {
    setOpenDeleteDriver(!openDeleteDriver);
  }
  return (
    <div className="d-flex justify-content-left">
      <div // botão de editar
        id={"editDriver" + driver.id}
        className="align-middle mr-1 cursor-pointer"
        onClick={() => setOpenEditDriver(true)}
      >
        <FontAwesomeIcon icon={faPen} fixedWidth />
        {/* ** MODAL DE EDITAR VARIÁVEL  ** */}
        {(openEditDriver)
          ? <ModalEditVar
            bootstrap4
            keyField="id"
            striped={true}
            condensed
            open={openEditDriver}
            toggle={toggleEditDriver}
            driver={driver}
            tableReload={tableReload}
          />
          : null
        }
        {/* Tooltip */}
        <UncontrolledTooltip
          placement="top"
          target={"editDriver" + driver.id}
        >
          {t('addNewDriver.editDriver')}
        </UncontrolledTooltip>
      </div>

      <div // botão de remover
        id={"deleteDriver" + driver.id}
        className="align-middle cursor-pointer"
        onClick={() => { setOpenDeleteDriver(true) }}
      >
        <FontAwesomeIcon icon={faTrash} fixedWidth />
        {/*** MODAL DE REMOVER DRIVER  ***/}
        {(openDeleteDriver)
          ? <ModalDeleteDriver
            open={openDeleteDriver}
            toggle={toggleDeleteDriver}
            driver={driver}
            tableReload={tableReload}
          />
          : null
        }
        {/* Tooltip */}
        <UncontrolledTooltip
          placement="top"
          target={"deleteDriver" + driver.id}
        >
          {t('addNewDriver.deleteDriver')}
        </UncontrolledTooltip>
      </div>
    </div>
  )
};

const AddNewDriver = () => {
  const { t } = useTranslation();
  const [modelsList, setModelsList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      axios.get('/getCustomDriverData/')
        .then(res => {
          setModelsList(res.data)
          setLoading(false)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar projetos.");  // t() da problema com o useEffect
        });
    }
  }, [isLoading]);


  const expandRow = {
    renderer: row => {
      return (
        <div>
          <ExpandedRowData varList={JSON.parse(row.varList)} />
        </div>
      );
    },
    /* Coluna de expansão - sinais > e V */
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? (
        <ChevronsDown className="cursor-pointer" width={16} height={16} />
      ) : (
        <ChevronsRight className="cursor-pointer" width={16} height={16} />
      ),
    expandColumnRenderer: ({ expanded }) =>
      expanded ? (
        <ChevronDown className="cursor-pointer" width={16} height={16} />
      ) : (
        <ChevronRight className="cursor-pointer" width={16} height={16} />
      )
  };

  const driverCols = [
    { // ID (oculto)
      dataField: "driverId",
      text: `driverID`,
      hidden: true
    },
    { // nome do driver
      dataField: "name",
      text: `${t('addNewDriver.tableColumnModels')}`,
    },
    { // nome da variável
      dataField: "companyName",
      text: `${t('addNewDriver.tableColumnCompany')}`,
    },
    { // nome da variável
      dataField: "modelType",
      text: `${t('addNewDriver.tableColumnType')}`,
    },
    { // botões de ação (editar/remover)
      dataField: "actions",
      text: `${t('dataTable.actions')}`,
      headerStyle: { width: "10%" },
      formatter: (_, row) => {
        return (
          <ActionButtonsComp
            className="text-center"
            driver={row}
            tableReload={() => setLoading(true)}
          />
        )
      }
    }

  ];

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          {t('addNewDriver.title')}
        </HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            {/**** CORRIGIR LINKS ****/}
            <Link to="/">{t('sidebar.home')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {t('addNewDriver.driverCreator')}
          </BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card style={{ marginTop: "-26px" }}>
        <CardHeader>
          <CardTitle tag="h5">
            {t("addNewDriver.customDrivers")}
          </CardTitle>
          {/* <h6 className="card-subtitle text-muted mt-1">
            {t('projTable.text1')}
            <ChevronRight width={16} height={16} />
            {t('projTable.text2')}.
          </h6> */}
        </CardHeader>

        <CardBody>
          {isLoading
            ? <Spinner />
            : (
              <div>
                <Row className="ant-row-end mr-3 mb-2" >
                  <ModalAddNewDriver tableReload={() => setLoading(true)} />
                </Row>
                {(modelsList.length)
                  ?
                  <BootstrapTable // Tabela
                    bootstrap4
                    keyField="id"
                    data={modelsList}
                    columns={driverCols}
                    bordered={false}
                    striped={true}
                    expandRow={expandRow}
                    condensed
                  // sort={sortIcon}
                  // pagination={paginationFactory(paginationOptions)}
                  />
                  :
                  <p className="text-center">
                    {t('addNewDriver.noAddedDrivers')}
                  </p>
                }
              </div>
            )}
        </CardBody>
      </Card>
    </Container >
  )
}

export default AddNewDriver;