import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBatteryFull } from '@fortawesome/free-solid-svg-icons';


const LifetimeEnergy = ({ lifetimeEnergy, loading, loadingModal }) => {
  const { t } = useTranslation();
  
  var lifetimeEnergyAndUnit = (lifetimeEnergy < 1000)
    ? (lifetimeEnergy.toFixed(2)).replace(".", ",") + " kWh"
      : (lifetimeEnergy < 1000000)
        ? (parseFloat((lifetimeEnergy/1000).toFixed(2))).toString().replace(".", ",") + " MWh"
          : (lifetimeEnergy < 1000000000)
            ? (parseFloat((lifetimeEnergy/1000000).toFixed(2))).toString().replace(".", ",") + " GWh"
              : (lifetimeEnergy < 1000000000000)
                ? (parseFloat((lifetimeEnergy/1000000000).toFixed(2))).toString().replace(".", ",") + " TWh"
                : ""

  return (
    <Row style={{minWidth: "147px"}}>
      <Col sm="12" style={{paddingRight:"0px"}}>
        <Card className="solar-top-card">
          <CardBody style={{padding: "15px"}}>
            <Row>
              <Col className="mt-0" style={{paddingRight:"0px"}}>
                <CardTitle tag="h5">{t('solar.daily2')}<br></br>{t('solar.lifetime')}</CardTitle>
              </Col>
              <Col className="col-auto"style={{paddingLeft:"0px"}}>
                <div style={{width:"26px",height:"26px"}}>
                  <div className="avatar-title rounded-circle bg-primary-dark">
                    <FontAwesomeIcon icon={faBatteryFull} fixedWidth className="align-middle" />
                  </div>
                </div>
              </Col>
            </Row>
            {(loadingModal)
              ? <div className="text-center mb-4"><Spinner /></div>
              : (loading)
                ? <div className="text-center mb-4"> <Spinner color="primary"/></div>
                : (lifetimeEnergy >= 0)
                  ? <h1 className="display-5 mb-4 text-center" style={{fontSize:"17px", fontWeight:"500"}}>{lifetimeEnergyAndUnit}</h1>
                  : <p className="text-center">Não há dados</p>
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}   

export default LifetimeEnergy;