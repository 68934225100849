import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Table, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

export const RefMeter = ({ devices, refMeterId, setRefMeter, refMeter }) => {

  const { t } = useTranslation();

  const structure = [
    { id: 1, description: t("params.peakOffPeak"), variable: 'peakOffPeak' },
    { id: 2, description: t("params.kWhActiveConsumed"), variable: 'kWhActiveConsumed' },
    { id: 3, description: t("params.kWhActiveGenerate"), variable: 'kWhActiveGenerate' },
    { id: 4, description: t("params.demandAt"), variable: 'demandAt' },
    { id: 5, description: t("params.demandRe"), variable: 'demandRe' },
    { id: 6, description: t("params.kWhReativeConsumedExtended"), variable: 'kWhReativeConsumedExtended' },
    { id: 7, description: t("params.postoReat"), variable: 'postoReat' },
    { id: 8, description: t("params.kWhReativeConsumed"), variable: 'kWhReativeConsumed' },
    { id: 9, description: t("params.powerFactor"), variable: 'powerFactor' },
    { id: 10, description: t("params.pAtC"), variable: 'pAtC' },
    { id: 11, description: t("params.fpCap"), variable: 'fpCap' },
    { id: 12, description: t("params.fpInd"), variable: 'fpInd' }
  ];

  const [form, setForm] = useState({
    dvcId: null,
    model: null,
    description: null,
    energyStructure: structure.reduce((acc, { variable }) => {
      acc[variable] = null;
      return acc;
    }, {}),
    varlist: []
  });

  function handleSelectedDevice(e) {
    const selectedDeviceId = parseInt(e.target.value);
    const selectedDevice = devices.find(device => device.dvcId === selectedDeviceId);
    if (selectedDevice) {
      setRefMeter({
        dvcId: selectedDevice.dvcId,
        model: selectedDevice.model,
        description: selectedDevice.description,
        energyStructure: selectedDevice?.energyStructure || structure.reduce((acc, { variable }) => {
          acc[variable] = null;
          return acc;
        }, {}),
        varlist: selectedDevice?.varlist || []
      });
    }
  };

  function handleSelectedVariable(variable, value) {
    const updatedEnergyStructure = form.energyStructure;
    updatedEnergyStructure[variable] = value;

    setForm(prevForm => ({
      ...prevForm,
      energyStructure: updatedEnergyStructure
    }));

    setRefMeter(prevRefMeter => ({
      ...prevRefMeter,
      energyStructure: updatedEnergyStructure
    }));
    
  };

  useEffect(() => {
    const selectedDevice = devices.find(device => device.dvcId === refMeterId);
    if (selectedDevice) {
      setRefMeter({
        dvcId: selectedDevice.dvcId,
        model: selectedDevice.model,
        description: selectedDevice.description,
        energyStructure: selectedDevice?.energyStructure || structure.reduce((acc, { variable }) => {
          acc[variable] = null;
          return acc;
        }, {}),
        varlist: selectedDevice?.varlist || []
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, refMeterId]);

  useEffect(() => {
    if (refMeter) {
      setForm({
        dvcId: refMeter.dvcId,
        model: refMeter.model,
        description: refMeter.description,
        energyStructure: refMeter?.energyStructure || structure.reduce((acc, { variable }) => {
          acc[variable] = null;
          return acc;
        }, {}),
        varlist: refMeter?.varlist || []
      });
    } else {
      setForm({
        dvcId: null,
        model: null,
        description: '',
        energyStructure: structure.reduce((acc, { variable }) => {
          acc[variable] = null;
          return acc;
        }, {}),
        varlist: []
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refMeter]);

  return (
    <Row>
      <Col md={12}>
        <Form>
          <FormGroup>
            <Label for="device">{t("params.selectRefMeter")}</Label>
            <Input
              type="select"
              id="device"
              value={form.dvcId || ''}
              onChange={handleSelectedDevice}
            >
              <option value="" hidden>
                {form.description || t("params.selectDevice")}
              </option>
              {devices.filter(device => form.dvcId !== device.dvcId)
                .map(device => (
                  <option key={device.dvcId} value={device.dvcId}>
                    {device.description}
                  </option>
                ))}
            </Input>
          </FormGroup>
          {form.description &&
          <Table>
            <thead>
              <tr>
                <th>{t("params.model")}</th>
                <th>{t("params.structure")}</th>
                <th>{t("params.variables")}</th>
              </tr>
            </thead>
            <tbody>
              {structure.map((item) => (
                <tr key={item.id}>
                  <td>{form.model}</td>
                  <td>
                    {item.description} 
                    {["Ponta e Fora ponta", "Energia Ativa Consumida"].includes(item.description) && 
                    <span style={{ color: 'red' }}> é requerido </span>}
                  </td>
                  <td>
                    <Input
                      type="select"
                      value={form.energyStructure[item.variable] || ''}
                      onChange={(e) => handleSelectedVariable(item.variable, e.target.value)}
                      disabled={form.model === "IG9K" || form.model === "IG9K - Estendido"}
                      >
                      <option value={null}>{form.energyStructure[item.variable] || t("params.selectVariable")}</option>
                      {form.varlist.map(variable => (
                        <option key={variable.tag} value={variable.tag}>{variable.varName}</option>
                      ))}
                    </Input>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          } 
        </Form>
      </Col>
    </Row>
  );
};
