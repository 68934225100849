import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import moment from "moment";

const generateNext12Months = () => {
  const months = [];
  const now = moment();
  for (let i = 0; i < 12; i++) {
    months.push(now.clone().add(i, "months").format("MMM/YYYY"));
  }
  return months;
};

const prepareChartData = (contracts) => {
  const months = generateNext12Months();
  const chartData = months.map((month) => ({
    month,
    Total: 0,
  }));

  contracts.forEach((contract) => {
    const activationDate = moment(contract["firstProcessedAt"]);
    const now = moment().startOf("month");
    const startIndex = activationDate.isBefore(now)
      ? 0
      : months.findIndex((m) => m === activationDate.format("MMM/YYYY"));

    if (startIndex !== -1) {
      for (
        let i = startIndex;
        i < months.length && i < startIndex + contract["contractDuration"];
        i++
      ) {
        if (!chartData[i][contract.projectName]) {
          chartData[i][contract.projectName] = 0;
        }
        chartData[i][contract.projectName] += contract["monthValue"];
        chartData[i].Total += contract["monthValue"];
      }
    }
  });

  return chartData;
};

export const ContractsValueChart = ({ data }) => {
  const chartData = prepareChartData(data);
  const [hiddenLines, setHiddenLines] = useState({});

  const handleLegendClick = (data) => {
    setHiddenLines((prev) => ({
      ...prev,
      [data.dataKey]: !prev[data.dataKey],
    }));
  };

  return (
    <LineChart
      width={800}
      height={400}
      data={chartData}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
    >
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      <Legend onClick={handleLegendClick} />
      <CartesianGrid strokeDasharray="3 3" />
      {data.map((contract) => (
        <Line
          key={contract.id}
          type="monotone"
          dataKey={contract.projectName}
          stroke="#8884d8"
          hide={hiddenLines[contract.projectName]}
        />
      ))}
      <Line
        type="monotone"
        dataKey="Total"
        stroke="#82ca9d"
        hide={hiddenLines.total}
      />
    </LineChart>
  );
};
