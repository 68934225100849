const ptBrProjectTranslations = {
    projects: {
        title: "Minhas Plantas",
        devices: "Dispositivos",
        solar: "Solar",
        energy: "Energia",
        energyBreadcrumb: "Energia (Visão Geral)",
        water: "Água",
        gas: "Gás",
        noBreak: "No Break",
        noProjects: "O usuário não está cadastrado em nenhum projeto",
        searchProjects: "Buscar projetos",
        addProject: "Adicionar Projeto"
    },
    projTable: {
        title: "Visão Geral de Projetos e Dispositivos",
        head: "Registro de Projetos e Dispositivos",
  
        // NOTE: tabelas
        text1: "Clique em",
        text2: "para visualizar os dispositivos de cada projeto",
        id: "ID",
        name: "Nome",
        description: "Descrição",
        company: "Empresa",
        address: "Endereço",
        neighborhood: "Bairro",
        city: "Cidade",
        state: "Estado",
        zipCode: "CEP",
        activeModules: "Módulos Ativos",
        actions: "Ações",
  
        devices: "Dispositivos",
        manufacturer: "Fabricante",
        model: "Modelo",
        reference: "Referência",
        hash: "Assinatura",
        hashIR: "Assinatura de IR",
        createdAt: "Data de Criação",
        alarm: "Alarmes",
        lastComm: "Última com.",
        commInterval: "Tempo de atualização",
        latitude: "Latitude",
        tooltipLatitude: "Consulte a latitude, clicando com botão direito do mouse sobre um ponto de localização dentro do Google Maps.",
        longitude: "Longitude",
        tooltipLongitude: "Consulte a longitude, clicando com botão direito do mouse sobre um ponto de localização dentro do Google Maps.",
        noProject: "Não há projetos cadastrados",
        noDevices: "Não há dispositivos cadastrados",
  
        // NOTE: ações
        search: "Busca",
        addProject: "Adicionar Projeto",
        editProject: "Editar Projeto",
        deleteProject: "Remover Projeto",
        manageUsers: "Gerenciar Usuários",
  
        addDevice: "Adicionar Dispositivo",
        editDevice: "Editar Dispositivo",
        deleteDevice: "Remover Dispositivo",
        manageGains: "Fatores de Multiplicação",
        defaultAlarms: "Alarmes Padrão",   
        
        detailsProject: "Informações",
        addressProject: "Endereço",
  
        // NOTE: formulários  
        addDeviceTitle: "Adicionar Dispositivo ao Projeto",
        changeUsers: "Alterar Usuários do Projeto",
        manageGainsHead: "Gerenciar Fatores de Multiplicação de",
        defaultAlarmsHead: "Alarmes Padrão de",

        select: "Selecione uma opção",
  
        gw: "Gateway",
        sd: "Standalone",
        pp: "Periférico",
        lw: "Lora",
  
        addInBatches: "Cadastrar em lotes",
        automaticLoraRegisters: "Cadastrar automaticamente dispositivos Lora",
  
        disabledPPText: "É necessário um Gateway para cadastrar um Periférico",
  
        new: "Novo",
        changeText: "Selecione os usuários vinculados ao projeto",
  
        serialNumber: "Número de Série",
        gatewayId: "Vincular a Gateway",
  
        seconds: "Segundos",
        minutes: "Minutos",
        hours: "Horas",
  
        variable: "Variável",
        value: "Fator",
        noGains: "Não há fatores de multiplicação cadastrados para este dispositivo",
        edit: "Editar",
        delete: "Remover",
  
        image: "Imagem",
        imageText: "Para melhor resolução, envie uma imagem de no mínimo 100x100 pixels no formato .jpg",
  
        newGatewayText: "Selecione um novo Gateway para vincular os dispositivos periféricos atualmente vinculados a",
  
        // NOTE: toastrs
        att: "Atenção",
        addGainWarning: 'É necessário selecionar uma variável e inserir um fator de multiplicação',
        editGainWarning: 'É necessário inserir um fator de multiplicação',
        captchaLabel: 'Essa ação é irreversível. Caso deseje continuar, por favor, digite "DELETE" no campo abaixo',
        captcha: "Captcha incorreto",
        invalidText: "Existem campos não preenchidos",
        insertIRHash: "Insira a assinatura de IR",
        newGateway: "É necessário selecionar um novo Gateway para vincular os periféricos",
  
        error: "Erro",
        addGainError: "Erro ao adicionar fator de multiplicação. Por favor, tente novamente",
        editGainError: "Erro ao atualizar fator de multiplicação. Por favor, tente novamente",
        removeGainError: "Erro ao remover fator de multiplicação. Por favor, tente novamente",
  
        success: "Successo",
        projectUpdated: "Projeto atualizado",
  
        // NOTE: botões
        cancel: "Cancelar",
        saveContinue: "Salvar e Continuar Adicionando",
        saveProj: "Salvar Projeto",
        saveChanges: "Salvar Alterações",
        saveDelete: 'Remover Projeto',
        saveDevice: 'Salvar Dispositivo',
        close: "Fechar",
        addUnit: "Adicionar Unidade",
  
        // NOTE: módulos
        modules: "Módulos",
        solarModule: "Módulo Solar",
        powerModule: "Módulo Energia",
        waterModule: "Módulo Água",
        gasModule: "Módulo Gás",

        solarModuleOn: "Módulo Solar",
        powerModuleOn: "Módulo Energia",
        waterModuleOn: "Módulo Água",
        gasModuleOn: "Módulo Gás",
        climateModuleOn: "Módulo Climatização",
        nobreakModuleOn: "Módulo No-break",

        sending: "Salvando",
        editModules: "Editar Módulos",
    },
    devices: {
        title: "Dispositivos de",
        selectData: "Selecione um dado",
        lastComm: "Última comunicação",
        noData: "Não há dados",
        noDevices: "Não há dispositivos cadastrados",
        noDevicesTypes: "Não há dispositivos deste tipo",
        noVarData: "Não há dados da variável selecionada",
        noProject: "Projeto não encontrado",
        offline: "O dispositivo está offline",
        noComm: "Nunca comunicou",
        searchDevices: "Buscar dispositivos",
        back: "Retornar",
        filter: "Filtrar",
        inverter: "Inversor",
        tracker: "Rastreador",
        solarimeter: "Solarimétrica",
        nobreak: "No-break",
        climate: "Climatização",
        utils: "Utilidades",
        gateway: "Gateway",
        variablesOf: "Variáveis de",
        modalLastComm: "Última Publicação",
        modalNoStatus: "Sem Status",
        modalNoPowerFactory: "Sem Fator",
        inputCurrent: "Correntes Entrada",
        inputVoltage: "Tensões Entrada",
        outputCurrent: "Correntes Saída",
        outputVoltage: "Tensões Saída",
        batery: "Bateria",
        others: "Outros",
        dropDownStatistic: "Estatística",
        dropDownGauge: "Medidor",
        dropDownState: "Estado",
        status: {
            offline: "Offline",
            online: "Online",
            noComm: "Nunca comunicou",
            partial: "Parcialmente online",
          },
    },
}

export default ptBrProjectTranslations;
