import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Card, CardBody, CardHeader, CardTitle,
  Form, FormGroup, Input, Label,
  Row, Col, Spinner, CardText,
} from "reactstrap";
import InputMask from "react-input-mask";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';
import Resizer from "react-image-file-resizer";
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { changeAvatar } from "../../../redux/actions/sidebarActions";


const Demo = ({ avatarPreview, setAvatarPreview }) => {
  const { t } = useTranslation();



  const [fileList, setFileList] = useState((avatarPreview !== "null")
    ? [{
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: avatarPreview
    }]
    : []
  );

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  /*** Função redução da imagem e conversão para base64 ***/
  const resizeFile = file => {
    return new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        125,
        125,
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  }

  /*** Função upload de imagem ***/
  const handleChange = async (event) => {
   
    try {
      const file = event.file.originFileObj; //busca imagem
      let image = await resizeFile(file)
      setAvatarPreview(image);// atualiza preview da imagem
      setFileList([event.file])
      console.log([event.file]);
    }
    catch (err) {
      console.log('imagem');
    }
   

  };



  return (

    <div style={{}}>
      <ImgCrop rotate>
        <Upload
          listType="picture-card"
          fileList={fileList}
          onChange={e => {
            handleChange(e);
          }}
          onPreview={onPreview}
          onRemove={(event) => {
            try {
              const file = event.url //busca imagem
              setAvatarPreview(file);// atualiza preview da imagem
              setFileList([])
              setAvatarPreview("null")
            }
            catch (err) {
              console.log(err);
            }
           }}
        >
          {fileList.length < 2 && '+ Upload'}
        </Upload>
      </ImgCrop>
      <div>
        <small>
          {t('settings.avatarText')}
        </small>
      </div>
    </div>
  );
};

/****** DADOS DE PERFIL *
      * Podem ser alterados por usuários padrão ******/
const PublicInfo = connect(store => ({
  app: store.app
}))(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true);
  const [avatarPreview, setAvatarPreview] = useState(localStorage.getItem('avatar'));
  const [formUsername, setFormUsername] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formPhone, setFormPhone] = useState('');

  const [invalidUser, setInvalidUser] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);

  useEffect(() => {
    if (isLoading) {
      axios.get('/userContactSearch')
        .then(res => {
          setFormUsername(res.data.loginUser);
          setFormEmail(res.data.email);
          setFormPhone(res.data.phone);
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);

          toastr.warning('Erro', 'Erro ao buscar informações do usuário');
        })
        .finally(() => setLoading(false));
    }
  }, [isLoading]);

  return <Card>
    <CardHeader>
      <CardTitle tag="h5" className="mb-0">
        {t('settings.profile')}
      </CardTitle>
    </CardHeader>

    <CardBody style={{ paddingBottom: "40px", paddingLeft: "20px" }}>
      {(isLoading)
        ? <div><Spinner className="text-center" color="primary" /></div>
        : (!formUsername || !formEmail)
          ? <CardText>
            Falha em carregar dados do usuário.
          </CardText>
          : <Form
            onSubmit={e => {
              e.preventDefault();

              if (!formUsername) {
                toastr.warning("Atenção!", "O campo de nome de usuário é obrigatório.");
                setInvalidUser(true);
              }

              else if (!formEmail) {
                toastr.warning("Atenção!", "O campo de e-mail é obrigatório.");
                setInvalidEmail(true);
              }

              else {
                const jsonData = JSON.stringify({
                  // userId: localStorage.getItem('userId'),
                  avatar: avatarPreview,
                  username: formUsername,
                  email: formEmail,
                  phone: formPhone
                });
  
                /*** ENVIO DE DADOS PELO AXIOS ***/
                axios.put('profileUpload/', jsonData)
                .then(res => {
                  /* NOTIFICAÇÃO DE IAMGEM ALTERADA */
                  toastr.success(t('settings.success'), t('settings.update'));
                  localStorage.setItem('avatar', avatarPreview);
                  dispatch(changeAvatar(avatarPreview));
                  setInvalidUser(false);
                  setInvalidEmail(false);
                  setInvalidPhone(false);
                })
                .catch(err => {
                  console.log(err);
                  console.log(err.response);

                  /* NOTIFICAÇÃO DE ERRO */
                  if (err.response.data.message) {
                    toastr.warning(t('settings.att'), t('settings.exists'));
                  }
                  if (err.response.data.redBox) {
                    setInvalidUser(err.response.data.redBox[0]);
                    setInvalidEmail(err.response.data.redBox[1]);
                    setInvalidPhone(err.response.data.redBox[2]);
                  }
                })
                // .finally(() => {
                //   window.location.reload(true)
                // })
              }



            }}
          >
            <Row className="d-flex justify-content-start">
              {/* Trocar avatar */}
              <Col md={4}>
                <Demo
                  avatarPreview={avatarPreview}
                  setAvatarPreview={setAvatarPreview}
                />
              </Col>

              {/* Nome de usuário e botao de salvar */}
              <Col md={5} className="d-flex flex-column ml-3">
                {/* Nome de usuário */}
                <FormGroup className="flex-fill">
                  <Label>{t('settings.username')}</Label>
                  <Input
                    type="text"
                    value={formUsername}
                    onChange={e => setFormUsername(e.target.value)}
                    invalid={invalidUser}
                  />
                </FormGroup>

                {/* E-mail */}
                <FormGroup className="flex-fill">
                  <Label>E-mail</Label>
                  <Input
                    type="text"
                    value={formEmail}
                    onChange={e => setFormEmail(e.target.value)}
                    placeholder="user@mail.com"
                    invalid={invalidEmail}
                  />
                </FormGroup>

                {/* Núero de telefone */}
                <FormGroup className="flex-fill">
                  <Label>{t('settings.phone')}</Label>
                  <InputMask
                    mask="+99 (99) 99999-9999"
                    value={formPhone}
                    onChange={e => setFormPhone(e.target.value)}
                  >
                    {inputProps => (
                      <Input
                        {...inputProps}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="+xx (xx) xxxxx-xxxx"
                        invalid={invalidPhone}
                      />
                    )}
                  </InputMask>
                </FormGroup>

                {/* Botão salvar alterações */}
                <div className="text-right mt-4">
                  <Button color="primary" type="submit">
                    {t('settings.submit')}
                  </Button>
                </div>
              </Col>

              <Col md={1}></Col>
            </Row>
          </Form>
      }
    </CardBody>
  </Card>
})

export default PublicInfo;