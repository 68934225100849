import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Col,
    Input,
    Label,
    Modal,
    ModalBody,
    Row
} from "reactstrap";


export const PdfViewer = ({
    file,
    open,
    toggle,
    changeFile,
    data,
    setData,
    generateBillingReport,
  }) => {
    const { t } = useTranslation();
    const [pdfUrl, setPdfUrl] = useState(null);
  
    useEffect(() => {
      if (file) {
        if (typeof file === "string") {
          setPdfUrl(file);
        } else {
          const url = URL.createObjectURL(file);
          setPdfUrl(url);
          return () => URL.revokeObjectURL(url);
        }
      }
    }, [file]);
  
    const handleInputChange = useCallback(
      (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      },
      [setData]
    );
  
    const inputFields = [
      [
        { label: t("solar.billingReport.previousRead"), name: "previousReading" },
        { label: t("solar.billingReport.currentRead"), name: "currentReading" },
      ],
      [
        { label: t("solar.billingReport.generationBalancekWh"), name: "accumulatedCredit" },
        { label: t("solar.billingReport.kwhBalanceToExpire"), name: "creditExpiration" },
      ],
      [
        { label: t("solar.billingReport.kwhBalanceToExpire"), name: "TUSDConsumption" },
        { label: `${t("solar.billingReport.value")} (R$)`, name: "TUSDConsumptionValue" },
      ],
      [
        { label: t("solar.billingReport.TUSDConsumption"), name: "TEConsumption" },
        { label: `${t("solar.billingReport.value")} (R$)`, name: "TEConsumptionValue" },
      ],
      [
        { label: t("solar.billingReport.consumption"), name: "consumption" },
        { label: `${t("solar.billingReport.value")} (R$)`, name: "consumptionValue" },
      ],
      [
        { label: t("solar.billingReport.TUSDInjectedEnergy"), name: "TUSDInjectedEnergy" },
        { label: `${t("solar.billingReport.value")} (R$)`, name: "TUSDInjectedEnergyValue" },
      ],
      [
        { label: t("solar.billingReport.TEInjectedEnergy"), name: "TEInjectedEnergy" },
        { label: `${t("solar.billingReport.value")} (R$)`, name: "TEInjectedEnergyValue" },
      ],
      [
        { label: t("solar.billingReport.publicLightingAgreement"), name: "publicLightingAgreement" },
        { label: `${t("solar.billingReport.value")} (R$)`, name: "publicLightingAgreementValue" },
      ],
      [
        { label: t("solar.billingReport.injectedActiveEnergy"), name: "generatedEnergy" },
        { label: t("solar.billingReport.totalToPay"), name: "totalToPay" },
      ],
    ];
  
    const renderInputFields = () =>
      inputFields.map((fieldPair, index) => (
        <Row key={index}>
          {fieldPair.map(({ label, name }) => (
            <Col key={name} md={6}>
              <Label>{label}:</Label>
              <Input
                name={name}
                value={data[name]}
                onChange={handleInputChange}
              />
            </Col>
          ))}
        </Row>
      ));
  
    return (
      <Modal isOpen={open} toggle={toggle} scrollable size="xl">
        <ModalBody>
          <Row>
            <Col md={typeof file !== "string" ? 8 : 12}>
              {pdfUrl ? (
                <iframe
                  src={pdfUrl}
                  width="100%"
                  height="600px"
                  style={{ border: "none" }}
                  title="PDF Viewer"
                />
              ) : (
                <p>{t("solar.billingReport.noPdfFileSelected")}</p>
              )}
            </Col>
            {typeof file !== "string" && (
              <Col md={4} style={{ display: "grid" }}>
                <h5>{t("solar.billingReport.confirmTheReadData")}:</h5>
                <div>{renderInputFields()}</div>
  
                <Row
                  className="m-0 align-content-center justify-content-center"
                  style={{ gap: "4px" }}
                >
                  <Button
                    className="bg-warning"
                    style={{ border: "none" }}
                    onClick={() => {
                      changeFile();
                      toggle();
                    }}
                  >
                    {t("solar.billingReport.changeFile")}
                  </Button>
                  <Button
                    className="bg-primary"
                    style={{ border: "none" }}
                    onClick={generateBillingReport}
                  >
                    {t("solar.billingReport.continue")}
                  </Button>
                </Row>
              </Col>
            )}
          </Row>
        </ModalBody>
      </Modal>
    );
  };