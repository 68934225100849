import { SearchOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import {
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import {
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { COLOR_SOLAR } from "../../../../../utils/Constants";
import useChartHeight from "../hook";
import { useAPI } from "../SolarProvider";
import { Trash } from "react-feather";

const AvailabilityChart = ({ month, days, availability }) => {
  const { t } = useTranslation();

  // Definindo os dados para o gráfico
  const data = days.map((day, index) => ({
    date: day.toString().padStart(2, "0"),
    value1: availability[index] ? availability[index] * 100 : 0,
  }));

  const {
    availabilityAverage,
    availabilityExcellent,
    availabilityGood,
    availabilityPoor,
    availabilityVeryPoor,
  } = COLOR_SOLAR;
  const height = useChartHeight();

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart layout="horizontal" data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="category" dataKey="date" />
        <YAxis
          type="number"
          domain={[0, 100]}
          tickFormatter={(value) => `${value} %`}
          width={50}
        />
        <Tooltip formatter={(value) => `${value.toFixed(2)} %`} />
        <Legend formatter={() => `${t("solar.availability")} (%)`} />
        <Bar dataKey="value1" name={`${t("solar.availability")} (%)`}>
          {data.map((entry, index) => {
            let color;
            if (entry.value1 < 20) {
              color = availabilityVeryPoor;
            } else if (entry.value1 < 40) {
              color = availabilityPoor;
            } else if (entry.value1 < 60) {
              color = availabilityAverage;
            } else if (entry.value1 < 80) {
              color = availabilityGood;
            } else {
              color = availabilityExcellent;
            }

            return <Cell key={`cell-${index}`} fill={color} />;
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const Availability = ({ month, projectId }) => {
  const [showConfig, setShowConfig] = useState(false);
  const { t } = useTranslation();

  const { data, status, refetch } = useQuery({
    queryKey: [
      "readSolarMonthlyEnergy",
      { projId: projectId, monthRef: month },
    ],
    queryFn: () =>
      axios
        .get("/readSolarMonthlyEnergy", {
          params: {
            projId: projectId,
            monthRef: month,
          },
        })
        .then((res) => res.data),
    staleTime: 1000 * 60 * 60, // 1 hour
    refetchInterval: 1000 * 60 * 60, // 1 hour
  });
  return (
    <>
      <CardHeader className="d-flex flex-row justify-content-between align-items-center">
        <CardTitle tag="h5">{t("solar.plantAvailability")}</CardTitle>
        <Button color="primary" onClick={() => setShowConfig(true)}>
          {t("solar.config")}
        </Button>
      </CardHeader>
      {showConfig && (
        <ModalConfigAviability
          onClose={() => setShowConfig(false)}
          refetchChart={refetch}
        />
      )}

      <CardBody>
        {status === "loading" ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <AvailabilityChart
            month={month}
            days={data.monthlyEnergy.days}
            availability={data.monthlyEnergy.availability}
          />
        )}
      </CardBody>
    </>
  );
};

const ModalConfigAviability = ({ onClose, refetchChart }) => {
  const { t, i18n } = useTranslation();
  const { data, projectId, monthRef } = useAPI();
  const { data: info, refetch } = useQuery({
    queryKey: [
      "devices_offline",
      {
        projId: projectId,
        monthRef: monthRef,
      },
    ],
    queryFn: () =>
      axios
        .get("/devicesOffline", {
          params: {
            projId: projectId,
            monthRef: monthRef,
          },
        })
        .then((res) => res.data),
    staleTime: 1000 * 60 * 60, // 1 hour
    refetchInterval: 1000 * 60 * 60, //
  });
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [intervalOffline, setIntervalOffline] = useState([]);
  const [motivation, setMotivation] = useState("");
  const [showFormAdd, setShowFormAdd] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loadingForm, setLoadingForm] = useState(false);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            type="button"
          >
            Buscar
          </Button>
          <Button onClick={() => handleReset(clearFilters)} type="button">
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100); // Se você tiver uma ref para o input de busca
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: t("availability.deviceName"),
      dataIndex: "deviceName",
      key: "deviceName",
      fixed: "left",
      width: 180,
      ...getColumnSearchProps("deviceName"), // Adiciona filtro para deviceName
      sorter: (a, b) => a.deviceName.localeCompare(b.deviceName), // Ordenação por deviceName
    },
    {
      title: t("availability.model"),
      dataIndex: "model",
      key: "model",
      width: 120,
      ...getColumnSearchProps("model"), // Adiciona filtro para model
      sorter: (a, b) => a.model.localeCompare(b.model), // Ordenação por model
    },
    {
      title: t("availability.startOffline"),
      dataIndex: "start_offline",
      key: "start_offline",
      width: 150,
      sorter: (a, b) =>
        moment(a.start_offline).unix() - moment(b.start_offline).unix(),
      render: (text, record) => (
        <>{moment(record.start_offline).format("llll")}</>
      ),
    },
    {
      title: t("availability.endOffline"),
      dataIndex: "end_offline",
      key: "end_offline",
      width: 150,
      sorter: (a, b) =>
        moment(a.end_offline).unix() - moment(b.end_offline).unix(),
      render: (text, record) => (
        <>{moment(record.end_offline).format("llll")}</>
      ),
    },
    {
      title: t("availability.motivation"),
      dataIndex: "motivation",
      key: "motivation",
      width: 200,
      ...getColumnSearchProps("motivation"), // Adiciona filtro para motivation
      sorter: (a, b) => a.motivation.localeCompare(b.motivation), // Ordenação por motivation
    },
    {
      title: t("availability.actions"),
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title={t("availability.deleteConfirm")}
            onConfirm={() => handleDelete(record.key)}
            okText={t("availability.deleteYes")}
            cancelText={t("availability.deleteNo")}
            disabled={loadingForm}
          >
            <Button
              color="danger"
              size="small"
              title={t("availability.delete")}
              disabled={loadingForm}
            >
              {loadingForm ? <Spinner size="sm" /> : <Trash size={16} />}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleDelete = async (recordKey) => {
    try {
      setLoadingForm(true);
      await axios.delete(`/devicesOffline/${recordKey}`);
      toastr.success(t("availability.deleteSuccess"));
      refetch();
      refetchChart();
    } catch (error) {
      console.error("Erro ao deletar:", error);
      toastr.error(t("availability.deleteError"));
    } finally {
      setLoadingForm(false);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const dataSource = info?.map((device) => {
    const dvc = data.devices.find((d) => d.id === device.deviceId);
    return {
      key: device.id,
      deviceName: dvc.deviceName,
      model: dvc.model,
      start_offline: device.start_offline,
      end_offline: device.end_offline,
    };
  });

  const toggle = () => {
    setShowFormAdd(!showFormAdd);
    if (showFormAdd) {
      setSelectedDevices([]);
      setIntervalOffline([]);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoadingForm(true);
    axios
      .post("/devicesOffline", {
        projId: projectId,
        devices: selectedDevices,
        start_offline: intervalOffline[0],
        end_offline: intervalOffline[1],
        monthRef: monthRef,
        motivation: motivation.trim(),
      })
      .then(() => {
        toggle();
        refetch();
        refetchChart();
        toastr.success(t("availability.saveSuccess"));
        setSelectedDevices([]);
        setIntervalOffline([]);
        setMotivation("");
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          toastr.error(t("availability.saveConflict"), err.response.data.error);
        } else {
          toastr.error(t("availability.saveError"));
        }
        console.error(err);
      })
      .finally(() => setLoadingForm(false));
  };

  return (
    <Modal isOpen toggle={onClose} size="lg">
      <ModalHeader toggle={onClose}>
        {t("availability.configTitle")} -{" "}
        {moment(monthRef).locale(i18n.language).format("MMMM/YYYY")}
        <Button color="primary" onClick={toggle} className="ml-4">
          {showFormAdd
            ? t("availability.backButton")
            : t("availability.addButton")}
        </Button>
      </ModalHeader>
      <ModalBody
        style={{
          maxHeight: "calc(100vh - 200px)",
          overflowY: "auto",
        }}
      >
        {!showFormAdd ? (
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{ y: 240, x: "max-content" }}
            size="small"
          />
        ) : (
          <Form layout="vertical">
            <Form.Item label={t("availability.deviceName")} className="mb-2">
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder={t("availability.addFiles")}
                onChange={(value) => setSelectedDevices(value)}
                value={selectedDevices}
              >
                {data.devices.map((device) => (
                  <Select.Option key={device.id} value={device.id}>
                    {device.deviceName} - {device.model}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={t("availability.startOffline")} className="mb-2">
              <DatePicker.RangePicker
                format="DD/MM/YYYY HH:mm"
                showTime={{ format: "HH:mm" }}
                style={{ width: "100%" }}
                onChange={(value) => setIntervalOffline(value)}
                value={intervalOffline}
                disabledDate={(current) => {
                  return (
                    current &&
                    (current < moment(monthRef).startOf("month") ||
                      current > moment(monthRef).endOf("month"))
                  );
                }}
              />
            </Form.Item>
            <Form.Item label={t("availability.motivation")} className="mb-2">
              <Input
                placeholder={t("availability.motivation")}
                value={motivation}
                onChange={(e) => {
                  if (e.target.value.length > 255) return;
                  setMotivation(e.target.value);
                }}
                max={255}
                id="motivation"
              />
              <small>{motivation.length}/255</small>
            </Form.Item>

            <div className="d-flex justify-content-end mt-4">
              <Button color="secondary" onClick={toggle}>
                {t("availability.cancelButton")}
              </Button>
              <Button
                color="primary"
                className="ml-2"
                type="submit"
                disabled={
                  selectedDevices.length === 0 ||
                  intervalOffline.length === 0 ||
                  loadingForm
                }
                onClick={handleSave}
              >
                {loadingForm ? (
                  <Spinner size="sm" />
                ) : (
                  t("availability.saveButton")
                )}
              </Button>
            </div>
          </Form>
        )}
      </ModalBody>
    </Modal>
  );
};

export default Availability;
