import { Breadcrumb, Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TbDeviceTabletPlus } from "react-icons/tb";
import { AiOutlineProject } from "react-icons/ai";
import { FaMoneyBill } from "react-icons/fa";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";
import { InfoCard } from "./components/InfoCard";
import { ContractsValueChart } from "./components/ContractsValueChart";
import { DemosContractsChart } from "./components/DemosContractsChart";
import { useEffect, useState } from "react";
import { fetchGet } from "../NocService";
import axios from "axios";

export const CommercialManagement = () => {
  const { t } = useTranslation();

  const readNocDataURL = `v2/noc/readGeneralInformationCommercial`;

  const [data, setData] = useState({
    list: {
      demo: {
        projectCount: 0,
        totalMonthValue: 0,
        averageMonthValue: 0,
        totalDevices: 0,
      },
      contrato: {
        projectCount: 0,
        totalMonthValueCurrentMonth: 0,
        averageMonthValue: 0,
        totalDevicesCurrentMonth: 0,
      },
      chartMonthValues: [],
      chartContractMonth: [],
    },
    loading: true,
    error: null,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchGet(source, setData, readNocDataURL);

    return () => {
      source.cancel();
    };
  }, [readNocDataURL]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t("noc.header.title")}</HeaderTitle>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">{t("sidebar.home")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/serviceAuthorizations-">{t("noc.header.titleSA")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {t("noc.header.commercialManagement")}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Card className="p-2">
        <Card.Header className="p-0 d-flex justify-content-between align-items-center">
          <Card.Title className="m-0" style={{ width: "fit-content" }}>
            {t("noc.commercialScreen.generalContractData")}
          </Card.Title>
        </Card.Header>
        <Row className="m-0 flex-row mb-4">
          <InfoCard
            IconComponent={AiOutlineProject}
            title={t("noc.commercialScreen.projectQuantities")}
            loading={data.loading}
            contractValue={data.list.contrato.projectCount}
            demoValue={data.list.demo.projectCount}
          />
          <InfoCard
            IconComponent={FaMoneyBill}
            title={t("noc.commercialScreen.financialValues")}
            loading={data.loading}
            contractValue={data.list.contrato.totalMonthValueCurrentMonth}
            demoValue={data.list.demo.totalMonthValue}
            tooltipDemoValue={t("noc.tooltip.totalValueDemocontracts")}
            tooltipContractValue={t(
              "noc.tooltip.totalContractValueCurrentMonth"
            )}
          />
          <InfoCard
            IconComponent={FaMoneyBillTrendUp}
            title={t("noc.commercialScreen.monthlyAvarageContracts")}
            loading={data.loading}
            contractValue={data.list.contrato.averageMonthValue}
            demoValue={data.list.demo.averageMonthValue}
            tooltipDemoValue={"Valor médio de contratos Demo"}
            tooltipContractValue={"Valor médio de contratos"}
          />
          <InfoCard
            IconComponent={TbDeviceTabletPlus}
            title={t("noc.commercialScreen.monthlyDevices")}
            loading={data.loading}
            contractValue={data.list.contrato.totalDevicesCurrentMonth}
            demoValue={data.list.demo.totalDevicesCurrentMonth}
          />
        </Row>
        <Row className="m-0 flex-row">
          <Col>
            <Card>
              <Card.Header>{t("noc.commercialScreen.futureMonthlyPayments")}</Card.Header>
              <ContractsValueChart data={data.list.chartMonthValues} />
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>{t("noc.commercialScreen.averageContractsPerMonth")}</Card.Header>
              <DemosContractsChart data={data.list.chartContractMonth} />
            </Card>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};
