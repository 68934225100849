import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoWaterOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { CardHeaderNoc } from "../Card";
import { ModalInfoWater } from "./ModalInfoWater";

export const InfoWater = ({
  companyId = localStorage.getItem("companyId"),
}) => {
  const refetchInterval = 1000 * 60 * 15;
  const [selectedStatus, setSelectedStatus] = useState("online");
  const currentTheme = useSelector((store) => store.theme.currentTheme);
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const {
    status,
    data: reactQuery,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["companyReadWaterData", companyId],
    queryFn: async () => {
      const res = await axios.get(
        `v2/company/readWaterData?companyId=${companyId}`
      );
      return res.data;
    },
    refetchInterval: refetchInterval,
  });

  const statusLabels = {
    online: t("noc.labels.online"),
    offline: t("noc.labels.offline"),
    noCommunication: t("noc.labels.noCommunication"),
  };

  const statusColors = {
    online: currentTheme.success,
    offline: currentTheme.warning,
    noCommunication: currentTheme.disabled,
  };

  const statusKeys = Object.keys(statusLabels);
  const totalDevices = reactQuery
    ? Object.values(reactQuery).reduce((sum, count) => sum + count, 0)
    : 0;

  const handleNextStatus = () => {
    setSelectedStatus((prevStatus) => {
      const currentIndex = statusKeys.indexOf(prevStatus);
      return statusKeys[(currentIndex + 1) % statusKeys.length];
    });
  };

  const handlePreviousStatus = () => {
    setSelectedStatus((prevStatus) => {
      const currentIndex = statusKeys.indexOf(prevStatus);
      return statusKeys[
        currentIndex === 0 ? statusKeys.length - 1 : currentIndex - 1
      ];
    });
  };

  const handleModal = () => setShowModal(!showModal);

  return (
    <>
      <CardHeaderNoc
        IconComponent={IoWaterOutline}
        color={statusColors[selectedStatus]}
        numberSelected={reactQuery ? reactQuery[selectedStatus] : "0"}
        total={totalDevices}
        status={statusLabels[selectedStatus]}
        next={handleNextStatus}
        prev={handlePreviousStatus}
        loading={status === "loading" || isFetching}
        error={status === "error"}
        handleReload={refetch}
        title={`${t("noc.labels.water")}/${t("noc.labels.gas")}`}
        subtitle={t("noc.main.headerCards.ucs")}
        onClick={handleModal}
      />
      {showModal && (
        <ModalInfoWater
          showModal={showModal}
          handleModal={handleModal}
          companyId={companyId}
        />
      )}
    </>
  );
};
