import React, { useState } from "react";
import {
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';

/****** MODAL DE REMOVER ALARME ******/
const ModalDeleteAlarm = ({ open, toggle, alarm, tableReload }) => {
  const { t } = useTranslation();
  
  const [captchaInput, setCaptchaInput] = useState('');

  return (
    <Modal 
      isOpen={open}
      toggle={toggle}
    >
      <Form 
        onSubmit={e => {
          e.preventDefault();

          /* Verificação do captcha */
          if (captchaInput === 'DELETE') {
            /*** Envio de solicitação para a API ***/
            // axios.post('deleteAlarmConfig/', JSON.stringify({
            //   alarmId: alarm.id
            // }))
            axios.delete('deleteAlarmConfig/', {data: {alarmId: alarm.id}})
            .then(res => {
              toastr.success(res.data.messageHead, res.data.messageBody);
              tableReload();
              toggle();
            })
            .catch(err => {
              console.log(err);
              console.log(err.response);        
            })
          } else {
            /* Notificação de captcha incorreto */
            toastr.warning(
              `${t('alarms.error')}!`, 
              `${t('alarms.wrongCaptcha')}.`
            );
            // Limpa campo de entrada
            setCaptchaInput('');
          }
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('alarms.deleteAlarm')} {alarm.description}
        </ModalHeader>

        <ModalBody className="text-center mx-6">
          <div className="m-sm-4">
            <h6>{t('alarms.att')}!</h6>
            <p className="mb-0">
              {t('alarms.captchaText')}:
            </p>

            {/*** CAMPO DE CAPTCHA ***/}
            <Input 
              type="text" 
              value={captchaInput}
              onChange={e => setCaptchaInput(e.target.value)}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR ***/}
          <Button 
            name="btnCancel"
            type="button"
            color='primary' 
            className="mr-1 mb-1" 
            onClick={toggle}
            outline
          >
            {t('alarms.cancel')}
          </Button>

          {/*** BOTÃO DE REMOVER ALARME ***/}
          <Button
            name="btnClose"
            type="submit"
            color="primary"
          >
            {t('alarms.saveDelete')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

/****** MODAL DE REGISTRO DE DISPOSITIVO ******/
const ModalDeleteManyAlarms = ({ open, toggle, alarmsSelected, tableReload }) => {
  const { t } = useTranslation();
  
  const [captchaInput, setCaptchaInput] = useState('');

  return (
    <Modal 
      isOpen={open}
      toggle={toggle}
    >
      <Form 
        onSubmit={e => {
          e.preventDefault();

          /* Verificação do captcha */
          if (captchaInput === 'DELETE') {
            alarmsSelected.forEach((id, i, arr) => {
              /*** Envio de solicitação para a API ***/
              // axios.post('deleteAlarmConfig/', JSON.stringify({
              //   alarmId: id
              // }))
              axios.delete('deleteAlarmConfig/', {data: {alarmId: id}})
              .then(res => {
                // console.log(res.data);
              })
              .catch(err => {
                console.log(err);
                console.log(err.response);
              })
              .finally(()=>{
                if (Object.is(arr.length - 1, i)) {
                  toastr.success(
                    `${t('alarms.success')}!`,
                    `${t('alarms.alarmRemoved')}.`
                  );
                  tableReload();
                  toggle();
                }
              });
            });

          } else {
            /* Notificação de captcha incorreto */
            toastr.warning(
              `${t('alarms.error')}!`,
              `${t('alarms.wrongCaptcha')}.`
            );
            // Limpa campo de entrada
            setCaptchaInput('');
          }
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('alarms.deleteAlarms')}
        </ModalHeader>

        <ModalBody className="text-center mx-6">
          <div className="m-sm-4">
            <h6>{t('alarms.att')}!</h6>
            <p className="mb-0">
              {t('alarms.captchaText')}:
            </p>

            {/*** CAMPO DE CAPTCHA ***/}
            <Input 
              type="text" 
              value={captchaInput}
              onChange={e => setCaptchaInput(e.target.value)}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR ***/}
          <Button 
            name="btnCancel"
            type="button"
            color='primary' 
            className="mr-1 mb-1" 
            onClick={toggle}
            outline
          >
            {t('alarms.cancel')}
          </Button>

          {/*** BOTÃO DE REMOVER ALARME ***/}
          <Button
            name="btnClose"
            type="submit"
            color="primary"
          >
            {t('alarms.saveDeleteSelected')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export { ModalDeleteAlarm, ModalDeleteManyAlarms };