import axios from "axios";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { fetchGetWitchParams } from "../../../../../../../NocService";
import { ConsumerUnitsCard } from "./components/ConsumerUnitsCard";
import { DevicesCard } from "./components/DevicesCard";

export const Overview = ({ item }) => {
  const {t} = useTranslation();
  const url = "v2/project/listInfoModules";
  const params = {
    projId: item.projectId,
    solarModuleOn: item.solarModuleOn,
    powerModuleOn: item.powerModuleOn,
    climateModuleOn: item.climateModuleOn,
    waterModuleOn: item.waterModuleOn,
    gasModuleOn: item.gasModuleOn,
  };

  const [data, setData] = useState({
    listWaterAndGas: [],
    listPower: [],
    listClimate: [],
    listSolar: [],
    devices: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();

    fetchGetWitchParams(source, setData, setError, setLoading, url, params);

    return () => {
      source.cancel();
    };
  }, []);

  const handleReload = () => {
    setLoading(true);
    setError(null);
    const source = axios.CancelToken.source();
    fetchGetWitchParams(source, setData, setError, setLoading, url, params);
  };

  if (error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{t("noc.error.errorOccurred")}</CardTitle>
        </CardHeader>
        <CardBody>
          <Button onClick={handleReload}> {t("noc.error.tryAgain")}</Button>
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      {loading ? (
        <Skeleton style={{ width: "100%" }} height={150} />
      ) : (
        <Row
          className="m-0"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(48%, 1fr))",
            gap: "1rem",
          }}
        >
          <DevicesCard devices={data.devices} title={t("noc.main.headerCards.devices")} />
          {Boolean(data.listWaterAndGas.length) && (
            <ConsumerUnitsCard units={data.listWaterAndGas} />
          )}
          {Boolean(data.listSolar.length) && (
            <DevicesCard devices={data.listSolar} title={t("noc.labels.solar")} />
          )}
          {Boolean(data.listClimate.length) && (
            <DevicesCard devices={data.listClimate} title={t("noc.labels.climate")} />
          )}
          {Boolean(data.listPower.length) && (
            <DevicesCard devices={data.listPower} title={t("noc.labels.power")} />
          )}
        </Row>
      )}
    </>
  );
};
