import React, { useState } from "react";
import {
  Button, Modal, ModalHeader,
  ModalBody, ModalFooter, Spinner
} from "reactstrap";
import { useTranslation } from 'react-i18next';
import ModalReportManagerEmail from "./components/ModalReportManagerEmail";
import ReportManagerView from "./components/ReportManagerView";
import ReportManagerBlob from "./components/ReportManagerBlob";


const ModalReportManagerViewer =  ({
  open,
  toggle,
  reportManager,
  chartReportManager,
  projectName
}) => {
  const { t } = useTranslation();
  const [openSendMail, setOpenSendMail] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);

  function toggleModalSendMail() {
    setOpenSendMail(!openSendMail);
  }
  
  return ( !reportManager ? 
    <div className="text-center p-3"><Spinner color="primary"/></div>
    :
    <Modal
      isOpen={open}
      toggle={toggle}
      size='xl'
    >
      <ModalHeader toggle={toggle}>
        {t("reportManager.modalTitle")}
      </ModalHeader>

      <ModalBody className="overflow-auto">
          <ReportManagerView
            data={reportManager}
            chartScreenshot={chartReportManager}
            projectName={projectName}
          />
          <ReportManagerBlob
            data={reportManager}
            chartScreenshot={chartReportManager}
            projectName={projectName}
            setPdfBlob={setPdfBlob}
          />
        {(openSendMail)
          ? <ModalReportManagerEmail
              open={openSendMail}
              toggle={toggleModalSendMail}
              pdfBlob={pdfBlob}
              projectName={projectName}
           />
          : null
        }
      </ModalBody>

      <ModalFooter>
        <Button
          color='primary'
          className="mb-1"
          onClick={toggleModalSendMail}
        >
          {t('solarReport.sendEmail')}
        </Button>
        <Button
          color='primary'
          className="mb-1"
          onClick={toggle}
          outline
        >
          {t('solarReport.closeButton')}
        </Button>
      </ModalFooter>      
    </Modal>
    )
}

export default ModalReportManagerViewer;