/****** IMPORTS  ******/
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Card, Container, Row } from "reactstrap";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";

import { DataExplorerProvider } from "./DataExplorerProvider";
import CardWithTabs from "./components/CardWithTabs";
import DataSelect from "./components/DataSelect";

const DataExplorerIndex = () => {
  const { t } = useTranslation();


  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t("sidebar.dataExplorer")}</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{t("sidebar.home")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("sidebar.dataExplorer")}</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Card style={{ marginTop: "-26px" }}>
        <Row className="" style={{ marginLeft: "0", width: "100%" }}>
          <DataSelect />
        </Row>
        <Row>
          <CardWithTabs />
        </Row>
      </Card>
    </Container>
  );
};

const DataExplorer = () => {
  return (
    <DataExplorerProvider>
      <DataExplorerIndex />
    </DataExplorerProvider>
  );
};

export default DataExplorer;
