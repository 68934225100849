import React from "react";
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent } from "@fortawesome/free-solid-svg-icons";

const PowerFactory = ({ data, state, allData }) => {
  const { t } = useTranslation();

  var powerFactory = (data.FP)

  return (
    <div className="w-100">
      <Row >
        <Col sm="12" style={{paddingRight:"0px"}}>
          <Card style={{minWidth: "135px", maxHeight:"100px", height: "100px", marginBottom: "0px", border: state, borderStyle:"solid", background:"#dde0e5"}}>
            <CardBody style={{padding: "15px", paddingTop:"10px"}}>
              <Row>
                <Col className="mt-0">
                  <CardTitle tag="h5">{t('solar.powerFactory')}</CardTitle>
                </Col>
                <Col className="col-auto" style={{paddingLeft:"0px"}}>
                  <div>
                    <FontAwesomeIcon 
                        icon={faPercent} 
                        style={{fontSize:"14px"}}
                        />
                  </div>
                </Col>
              </Row>
              {(allData)
                ? <CardText className="display-5 text-center" style={{ fontSize: "17px", fontWeight: "500" }}>{(powerFactory) ? powerFactory : t("devices.modalNoPowerFactory")}</CardText>
                : <div className="text-center mb-4"><Spinner /></div>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}   

export default PowerFactory;