import { faSignal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import classnames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav, NavItem, NavLink,
  Row,
  TabContent, TabPane
} from "reactstrap";
import { TableEnergyPlants } from "../../projects/energyModule/generalVisionEnergy";
import PlantsTableSort from "../../projects/solarModule/SolarView/components/Table";
import { TableWaterPlants } from "../../projects/waterModule";
import { OfflinePlantsTable } from "./components/OfflinePlantsTable";
import { GatewayLogs } from "./components/GatewayLogs";

export const NewWindow = ({
  companyId = localStorage.getItem("companyId"),
}) => {
  const { t } = useTranslation();

  const [statusConnection, setStatusConnection] = useState(false);

  const { data: solarData } = useQuery({
    queryKey: ["readUserSolarData"],
    queryFn: async () => {
      const res = await axios.get("/readUserSolarData", {
        params: {
          monthRef: new Date(),
        },
      });
      return res.data;
    },
    refetchInterval: 1000 * 30 * 15, // 15 minutos
  });

  const { data: energyData } = useQuery({
    queryKey: ["readEnergyData"],
    queryFn: async () => {
      const res = await axios.get(`v2/energy/readEnergy`, {
        params: {
          monthRef: new Date(),
        },
      });
      return res.data;
    },
    refetchInterval: 1000 * 30 * 15, // 15 minutos
  });

  const { data: waterData } = useQuery({
    queryKey: ["listWaterPlants"],
    queryFn: async () => {
      const res = await axios.get(`v2/water/listPlants`, {
        params: {
          monthRef: new Date(),
        },
      });
      return res.data;
    },
    refetchInterval: 1000 * 30 * 15, // 15 minutos
  });

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Row
      className="m-0 w-100"
      style={{
        display: "flex",
        gap: "16px",
        height: "100%",
        flexWrap: "nowrap",
      }}
    >
      <Col
        style={{
          display: "grid",
          gridAutoRows: "minmax(0, 1fr)",
          gap: "8px",
          height: "100%",
          maxWidth: "fit-content",
          padding: 0,
          minWidth: "450px",
          maxHeight: "554px",
        }}
      >
        <Card
          className="w-100"
          style={{ maxWidth: "450px", marginBottom: 0, height: "100%" }}
        >
          <CardHeader className="d-flex align-content-center justify-content-between">
            <CardTitle tag="h5" className="mb-0">
            {t('noc.header.titleLog')}
            </CardTitle>
            <FontAwesomeIcon
              icon={faSignal}
              fixedWidth
              className={"align-middle fa-blink"}
              style={{
                color: statusConnection
                  ? "rgb(26, 202, 26)"
                  : "rgb(247, 51, 51)",
              }}
            />{" "}
          </CardHeader>
          <CardBody className="p-0">
            <GatewayLogs companyId={companyId} setStatusConnection={setStatusConnection}/>
          </CardBody>
        </Card>
        <Card
          className="w-100"
          style={{ maxWidth: "450px", marginBottom: 0, height: "100%" }}
        >
          <CardHeader>Plantas em atenção</CardHeader>
          <CardBody className="p-0">
            <OfflinePlantsTable companyId={companyId} />
          </CardBody>
        </Card>
      </Col>

      <Col style={{ padding: 0, overflowX: "auto", overflowY: "hidden" }}>
        <Card style={{ marginBottom: 0, height: "100%" }}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                Solar
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
              >
                {t('projects.water')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                }}
              >
                 {t('sidebar.energyView')}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {solarData && (
                <PlantsTableSort
                  plantList={solarData.plantList}
                  maxHeight={47 * 8}
                />
              )}
            </TabPane>
            <TabPane tabId="2">
              {waterData && (
                <TableWaterPlants plantList={waterData} maxHeight={47 * 8} />
              )}
            </TabPane>
            <TabPane tabId="3">
              {energyData && (
                <TableEnergyPlants plantList={energyData} maxHeight={47 * 8} />
              )}
            </TabPane>
          </TabContent>
        </Card>
      </Col>
    </Row>
  );
};
