import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import ptBR from "antd/es/locale/pt_BR";
import enUS from "antd/es/locale/en_US";

import { ConfigProvider, DatePicker, Modal, Select } from "antd";
import { useDataExplorer } from "./../DataExplorerProvider";
import TableTransfer from "./TableTransfer";
import moment from "moment";

const DataSelect = () => {
  const { t, i18n } = useTranslation();
  const {
    activeTab,
    searchDataValues,
    errorInitDatepicker,
    errorEndDatepicker,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    aggregation,
    setAggregation,
    resolution,
    setResolution,
    typeVariable,
    setTypeVariable,
    fetchLoading,
    projects,
    projectSelects,
    setProjectSelects,
    devicesSelecteds,
    setDevicesSelecteds,
    lisDevices,
    variablesSelect,
    setVariablesSelect,
    openModalSelectVariables,
    showModalSelectVariables,
    closeModalSelectVariables,
    listVariables,
    handleChange,
    listDevicesLoading,
    variablesLoading,
  } = useDataExplorer();

  return (
    <Form
      style={{ width: "100%" }}
      onSubmit={(e) => {
        e.preventDefault();
        searchDataValues(activeTab);
      }}
    >
      {/*Titulo de parametro */}
      <CardHeader>
        <CardTitle tag="h5" className="mb-0 b-0">
          {t("dataExplorer.parameters")}
        </CardTitle>
      </CardHeader>
      {/* Corpo de formulario superior */}
      <CardBody
        style={{
          padding: "0.10rem 1rem",
          gap: "0.5rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row className="alarm-search align-items-end" xs={12}>
          <Col>
            <FormGroup className="mb-0">
              <Label>{t("dataExplorer.projects")}</Label>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                maxTagCount={"responsive"}
                allowClear
                options={projects
                  .map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                value={projectSelects}
                optionFilterProp="label"
                onChange={(value, option) => {
                  setProjectSelects(value);
                  setDevicesSelecteds((old) => {
                    return old.filter((item) => {
                      return value.find((item2) => item2 === item.info.projId);
                    });
                  });
                  setVariablesSelect((old) => {
                    return old.filter((item) => {
                      const objectItem = JSON.parse(item);
                      return option.find((item) => {
                        return item.value === objectItem.device.projId;
                      });
                    });
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="mb-0">
              <Label>
                {t("dataExplorer.devices")}{" "}
                {listDevicesLoading && (
                  <span className="text-danger">
                    {t("dataExplorer.loading")}
                  </span>
                )}
              </Label>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                options={lisDevices
                  .map((item) => ({
                    label: `${item.projectName} - ${item.description}`,
                    value: item.id,
                    info: item,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                optionFilterProp="label"
                maxTagCount={"responsive"}
                value={devicesSelecteds}
                onChange={(value, option) => {
                  if (Array.isArray(option)) {
                    setDevicesSelecteds(option);
                    setVariablesSelect((old) => {
                      return old.filter((item) => {
                        const objectItem = JSON.parse(item);
                        return option.find((item) => {
                          return (
                            item.value === objectItem.device.id &&
                            item.info.id === objectItem.device.id
                          );
                        });
                      });
                    });
                    return;
                  }
                  if (option) {
                    setDevicesSelecteds([option]);
                  }
                }}
                disabled={projectSelects.length === 0}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="mb-0">
              <Label>{t("dataExplorer.typeVariable")}</Label>
              <Select
                style={{ width: "100%" }}
                value={typeVariable}
                onChange={(value) => {
                  setTypeVariable(value);
                }}
                options={[
                  { label: t("dataExplorer.integral"), value: "integral" },
                  {
                    label: t("dataExplorer.differential"),
                    value: "differential",
                  },
                ]}
                placeholder={t("dataExplorer.selectTypeVariable")}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup
              className="mb-0 d-flex align-items-center"
              style={{
                gap: "10px",
              }}
            >
              <div className="d-flex flex-column">
                <span>
                  {variablesSelect.length} {t("dataExplorer.variablesSelected")}
                </span>
                {variablesLoading && (
                  <span className="text-danger">
                    {t("dataExplorer.loading")}
                  </span>
                )}
              </div>
              <Button
                onClick={openModalSelectVariables}
                disabled={devicesSelecteds.length === 0}
              >
                {t("dataExplorer.selectVariables")}
              </Button>
            </FormGroup>
            {showModalSelectVariables && <ModalSelectParameters
              showModalSelectVariables={showModalSelectVariables}
              closeModalSelectVariables={closeModalSelectVariables}
              variablesSelect={variablesSelect}
              listVariables={listVariables}
              updateVariables={handleChange}
            />}
          </Col>
        </Row>
        <ConfigProvider locale={i18n.language === "en" ? enUS : ptBR}>
          <Row className="align-items-end" xs={12}>
            <Col>
              <FormGroup className="mb-0 d-flex flex-column">
                <div
                  className="
                d-flex
                justify-content-between
                "
                >
                  <Label>{t("dataExplorer.start")}</Label>
                  <Label>{t("dataExplorer.end")}</Label>
                </div>
                <DatePicker.RangePicker
                  value={[moment(startDate), moment(endDate)]}
                  onChange={(date) => {
                    if (date) {
                      setStartDate(date[0].toDate());
                      setEndDate(date[1].toDate());
                    }
                  }}
                  format={
                    i18n.language === "en"
                      ? "MMMM D, YYYY, hh:mm a"
                      : "DD/MM/YYYY, hh:mm a"
                  }
                  showTime
                  disabledDate={(current) => {
                    return current > moment();
                  }}
                  status={
                    errorEndDatepicker || errorInitDatepicker ? "error" : ""
                  }
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup className="mb-0">
                <Label>{t("dataExplorer.aggregation")}</Label>
                <Input
                  type="select"
                  id="SelectAggregation"
                  disabled={
                    typeVariable === "differential" || typeVariable === null
                  }
                  value={aggregation}
                  onChange={(e) => {
                    setAggregation(e.target.value);
                  }}
                >
                  <option hidden value="">
                    {t("dataExplorer.selectAggregation")}
                  </option>
                  <option value="Primeiro">{t("dataExplorer.first")}</option>
                  <option value="Ultimo">{t("dataExplorer.last")}</option>
                  <option value="Maximo">{t("dataExplorer.maximum")}</option>
                  <option value="Minimo">{t("dataExplorer.minimum")}</option>
                  <option value="Média">{t("dataExplorer.average")}</option>
                  <option value="Soma">{t("dataExplorer.sum")}</option>
                  <option value="Nenhum">{t("dataExplorer.none")}</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="mb-0">
                <Label>{t("dataExplorer.resolution")}</Label>
                <Input
                  type="select"
                  id="SelectResolution"
                  disabled={
                    aggregation === "Nenhum" ||
                    aggregation === "" ||
                    typeVariable === "differential" ||
                    typeVariable === null
                  }
                  value={resolution}
                  onChange={(e) => {
                    setResolution(e.target.value);
                  }}
                >
                  <option hidden value="">
                    {t("dataExplorer.selectResolution")}
                  </option>
                  <option value={300000}>5 {t("dataExplorer.minutes")}</option>
                  <option value={900000}>15 {t("dataExplorer.minutes")}</option>
                  <option value={3600000}>
                    60 {t("dataExplorer.minutes")}
                  </option>
                </Input>
              </FormGroup>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <Button
                color="primary"
                type="submit"
                disabled={fetchLoading.includes("dashboard")}
                className="w-100"
              >
                {t("dataExplorer.toView")}
              </Button>

              <Button
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  searchDataValues("export");
                }}
                disabled={fetchLoading.includes("export")}
                className="w-100"
              >
                {fetchLoading.includes("export")
                  ? t("dataExplorer.loading")
                  : t("dataExplorer.export")}
              </Button>
            </Col>
          </Row>
        </ConfigProvider>
      </CardBody>
    </Form>
  );
};

const ModalSelectParameters = ({
  showModalSelectVariables,
  closeModalSelectVariables,
  variablesSelect,
  listVariables,
  updateVariables,
}) => {
  const [variablesSelectLocal, setVariablesSelectLocal] =
    useState(variablesSelect);

  const { t } = useTranslation();

  const handleChange = (nextTargetKeys) => {
    setVariablesSelectLocal(nextTargetKeys);
  };

  const onOk = () => {
    updateVariables(variablesSelectLocal);
    closeModalSelectVariables();
  };

  return (
    <Modal
      title={`${t("dataExplorer.selectVariables")}`}
      open={showModalSelectVariables}
      onClose={closeModalSelectVariables}
      closable
      onCancel={closeModalSelectVariables}
      onOk={onOk}
      width={`75vw`}
    >
      <div
        style={{
          maxHeight: "70vh",
          overflowY: "auto",
          maxWidth: "100%",
          overflowX: "auto",
        }}
      >
        <TableTransfer
          dataSource={listVariables.map((item) => ({
            key: JSON.stringify(item),
            project: item.device.projectName,
            device: item.device.description,
            name: item.varName,
          }))}
          targetKeys={variablesSelectLocal}
          showSearch
          onChange={handleChange}
          operations={[
            `${t("dataExplorer.select")}`,
            `${t("dataExplorer.remove")}`,
          ]}
          filterOption={(inputValue, item) => {
            return (
              item.name.toLowerCase().indexOf(inputValue.toLowerCase()) !==
                -1 ||
              item.device.toLowerCase().indexOf(inputValue.toLowerCase()) !==
                -1 ||
              item.project.toLowerCase().indexOf(inputValue.toLowerCase()) !==
                -1
            );
          }}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
          }}
          titles={[
            `${t("dataExplorer.available")}`,
            `${t("dataExplorer.selected")}`,
          ]}
          locale={{
            itemUnit: `${t("dataExplorer.variable")}`,
            itemsUnit: `${t("dataExplorer.variables")}`,
            searchPlaceholder: `${t("dataExplorer.search")}`,
          }}
          showSelectAll={true}
          leftColumns={[
            {
              dataIndex: "name",
              title: `${t("dataExplorer.variable")}`,
              sorter: (a, b) => a.name.localeCompare(b.name),
              sortDirections: ["ascend", "descend"],
            },
            {
              dataIndex: "device",
              title: `${t("dataExplorer.device")}`,
              sorter: (a, b) => a.name.localeCompare(b.name),
              sortDirections: ["ascend", "descend"],
            },
            {
              dataIndex: "project",
              title: `${t("dataExplorer.project")}`,
              sorter: (a, b) => a.name.localeCompare(b.name),
              sortDirections: ["ascend", "descend"],
            },
          ]}
          rightColumns={[
            {
              dataIndex: "name",
              title: `${t("dataExplorer.variable")}`,
              sorter: (a, b) => a.name.localeCompare(b.name),
              sortDirections: ["ascend", "descend"],
            },
            {
              dataIndex: "device",
              title: `${t("dataExplorer.device")}`,
              sorter: (a, b) => a.name.localeCompare(b.name),
              sortDirections: ["ascend", "descend"],
            },
            {
              dataIndex: "project",
              title: `${t("dataExplorer.project")}`,
              sorter: (a, b) => a.name.localeCompare(b.name),
              sortDirections: ["ascend", "descend"],
            },
          ]}
        />
      </div>
    </Modal>
  );
};

export default DataSelect;
