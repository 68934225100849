import * as types from "../constants";

import { 
  enableModernTheme,
  enableClassicTheme,
  enableDarkTheme,
  enableLightTheme
} from "../actions/themeActions";

const themes = {
  modern: {
    primary: "#3B7DDD",
    secondary: "#6f42c1",
    tertiary: "#669ae5",
    success: "#28a745",
    info: "#20c997",
    warning: "#fd7e14",
    danger: "#dc3545",
    dark: "#153d77",
    disabled: "#B8B7B5"
  },
  classic: {
    primary: "#38C485",
    secondary: "#5C59ED",
    tertiary: "#41D492",
    success: "#a877b5",
    info: "#00bcd4",
    warning: "#F2B51D",
    danger: "#F26666",
    dark: "#203A45",
    disabled: "#B8B7B5"
  },
  dark: {
    primary: "#687ae8",
    secondary: "#95aac9",
    tertiary: "#95aac9",
    success: "#0c9",
    info: "#19b5fe",
    warning: "#f7bc06",
    danger: "#f2545b",
    dark: "#28304e",
    disabled: "#B8B7B5"
  },
  light: {
    primary: "#3B7DDD",
    secondary: "#6f42c1",
    tertiary: "#669ae5",
    success: "#28a745",
    info: "#20c997",
    warning: "#fd7e14",
    danger: "#dc3545",
    dark: "#f4f7fc",
    disabled: "#B8B7B5"
  }
};

const initialState = {
  currentTheme: (localStorage.getItem('defaultTheme') === 'classic')
    ? themes.classic
    : (localStorage.getItem('defaultTheme') === 'dark')
      ? themes.dark
      : (localStorage.getItem('defaultTheme') === 'light')
        ? themes.light
        : themes.modern,
  themes: themes
};

if (localStorage.getItem('defaultTheme') === 'classic') {
  enableClassicTheme();
}
else if (localStorage.getItem('defaultTheme') === 'dark') {
  enableDarkTheme();
}
else if (localStorage.getItem('defaultTheme') === 'light') {
  enableLightTheme();
}
else if (localStorage.getItem('defaultTheme') === 'modern') {
  enableModernTheme();
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.THEME_TOGGLE:
      return {
        ...state,
        currentTheme: state.themes[actions.payload]
      };

    default:
      return state;
  }
}
