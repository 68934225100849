const enUsLoginTranslations = {
  signin: {
    welcome: "Welcome",
    text: "Please sign in to your account to continue",
    user: "User",
    userPlaceholder: "E-mail or username",
    password: "Password",
    passwordPlaceholder: "Password",
    rememberMe: "Remember me",
    forgotPassword: "Forgot password?",
    logIn: "Sign In",
  },
  reset: {
    title: "Reset Password",
    text: "Please enter your e-mail to reset your password",
    insert: "E-mail",
    reset: "Reset Password",
    back: "Go Back",
  },
}

export default enUsLoginTranslations;