import React, { useState, useEffect } from "react";
import {
  Button,
  Form, FormGroup, Input, Label,
  Modal, ModalHeader, ModalBody, ModalFooter, 
  Row, Col,
  Spinner
} from "reactstrap";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';

/****** MODAL DE EDIÇÃO DE EMPRESA ******/
const ModalEditCompany = ({ open, toggle, comp, tableReload }) => {
  const { t } = useTranslation();

  /*** Busca lista de usuários da API ***/
  const [isLoading, setLoading] = useState(true);
  const [people, setPeople] = useState();
  // dados do formulario
  const [contactUser, setContactUser] = useState(JSON.stringify({
    id: comp.contactId,
    name: comp.contactName,
    email: comp.contactEmail,
    userType: '1'
  }));
  const [name, setName] = useState(comp.name);
  const [parentId, setParentId] = useState(comp.parentId);

  useEffect(() => {
    // axios.post('userSearchRestricted/', JSON.stringify({
    //   companyId: comp.id
    // }))
    axios.get('/userSearchRestricted', {params: {
      companyId: comp.id
    }})
    .then(res => {
      setPeople(res.data);
      setLoading(false);
    })
    .catch(err => {
      toastr.error('Erro!',"Erro ao buscar usuários."); // t() da problema com o useEffect
    });
  }, [comp.id]);

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <Form 
        onSubmit={e => {
          e.preventDefault(); // evitar erro ao enviar dados com Enter

          var contactUserParse = JSON.parse(contactUser);

          const jsonData = JSON.stringify({
            companyId: comp.id,
            name: name,
            parentId: parentId,
            contactId: contactUserParse.id,
            contactName: contactUserParse.name,
            contactEmail: contactUserParse.email
          });


          /*** Envio de dados ***/
          axios.put('updateCompany/', jsonData)
          .then(res => {
            toastr.success(res.data.messageHead, res.data.messageBody);

            /* Verifica se novo usuário de contato não é admin */
            if (contactUser.userType !== 1) {
              /* Torna usuário admin */

              var contactUserParse = JSON.parse(contactUser);
              const jsonDataUsr = JSON.stringify({
                userId: contactUserParse.id,
                name: contactUserParse.name,
                email: contactUserParse.email,
                companyId: comp.id,
                userType: 1,
              });
              axios.put('updateUser/', jsonDataUsr)
              .then(res => {
                toastr.success(res.data.messageHead, res.data.messageBody);
              })
              .catch(err => {
                /* Notificação de erro de envio */
                toastr.warning(err.response.data.messageHead, err.response.data.messageBody);
              });
            }
          })
          .catch(err => {
            /* Notificação de erro de envio */
            if (err.response.data) {
              toastr.warning('Erro!', err.response.data.message);
            }
          })
          .finally(()=>{
            tableReload(); // atualizar visualização da tabela
            toggle(); // fecha o modal
          });        
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('dataTable.editHead')} {comp.name}
        </ModalHeader>

        <ModalBody className="m-3">
          <div className="m-sm-4">
            <Row form>
              <Col md={2}>
              </Col>

              <Col md={8}>
                {/*** CAMPO DE NOME ***/}
                <FormGroup>
                  <Label>{t('dataTable.name')}</Label>
                  <Input
                    type="text" 
                    name="name"
                    id="name" 
                    value={name}
                    onChange={e=>setName(e.target.value)}
                  />
                </FormGroup>

                {/*** CAMPO DE VINCULAR EMPRESA ***/}
                <FormGroup> 
                  <Label>{t('dataTable.linkCompany')}</Label>
                  <Input
                    type="select" 
                    name="linkCompanyId"
                    id="linkCompanyId"
                    value={(parentId) ? parentId : ""}
                    onChange={e=>setParentId(e.target.value)}
                    disabled={(parentId === null) ? true : false}
                  >
                    {(comp.parentId === null) 
                      ? <option value={0} key={0}>{'-'}</option> 
                      : JSON.parse(localStorage.getItem('companyList'))
                        .map((item, i) => {
                          if (item.id !== comp.id) {
                            return(
                              <option 
                                value={item.id}
                                key={i}
                              >{item.name}</option>
                            )
                          } else return null
                        })
                    }
                  </Input>
                </FormGroup>

                {/*** CAMPO DE USUÁRIO DE CONTATO ***/}
                <FormGroup> 
                  <Label>{t('dataTable.contactName')}</Label>
                  {(!isLoading)
                    ? <Input
                        type="select" 
                        name="contact"
                        id="contact"
                        // envia nome e email do usuário selecionado no formato JSON
                        value={contactUser}
                        onChange={e=>setContactUser(e.target.value)}
                      >
                      {people.map((item, i) => {
                        return(
                          <option 
                            value={JSON.stringify({
                              "id": item.id,
                              "name": item.name, 
                              "email": item.email,
                              "userType": item.userType
                            })}
                            key={i}
                          >
                            {item.name}
                          </option>
                        )
                      })}
                      </Input>
                    : (<div><Spinner color="primary" /></div>)
                  }
                </FormGroup>
              </Col>

              <Col md={2}>
              </Col>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
            <Button 
              type="button"
              color='primary' 
              className="mr-1 mb-1" 
              onClick={toggle}
              outline
            >
              {t('dataTable.cancel')}
            </Button>
            {/*** BOTÃO DE ENVIAR DADOS ***/}
            <Button 
              type="submit"
              color='primary' 
              className="mr-1 mb-1"
            >
              {t('dataTable.editSave')}
            </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

/****** MODAL DE EDIÇÃO DE USUÁRIO ******/
const ModalEditUser = ({ open, toggle, user, onlyAdmin, contactId, tableReload, tableCompReload }) => {
  const { t } = useTranslation();

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [companyId, setCompanyId] = useState(user.companyId);  
  const [userType, setUserType] = useState(user.userType);
  const [linked, setLinked] = useState(user.isLinked);

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <Form 
        onSubmit={e => {
          e.preventDefault(); // evitar erro ao enviar dados com Enter

          /* Verifica se trocou usuário de contato para o tipo "padrão" */
          if (contactId === user.id && userType === '2') {
            toastr.warning(`${t('dataTable.error')}!`, t('dataTable.editContactNot'));
          } 

          /* Verifica se trocou a si prórpio para o tipo 'padrão */
          else if (localStorage.getItem('userId') === user.id.toString() && userType === '2') {
            toastr.warning(`${t('dataTable.error')}!`, t('dataTable.editUserNot'));
          } 

          else {
            /** Verifica se foi alterada a empresa do usuário */
            if (companyId !== user.companyId) {
              /* Salvar dados do formulário no formato JSON */
              const jsonData = JSON.stringify({
                name: name,
                email: email,
                companyId: companyId,
                userType: userType,
                userId: user.id,
                isLinked: linked,
                module: 'default'
              });

              /*** Envio de dados ***/
              axios.put('editUserCompany/', jsonData)
              .then(res => {
                toastr.success(res.data.messageHead, res.data.messageBody);
              })
              .catch(err => {
                /* Notificação de erro de envio */
                toastr.warning(err.response.data.messageHead, err.response.data.messageBody);
              })
              .finally(()=>{
                tableReload(); // atualizar visualização da tabela
                toggle(); // fecha o modal (sempre)
              });
            } else {
              /* Salvar dados do formulário no formato JSON */
              const jsonData = JSON.stringify({
                name: name,
                email: email,
                userType: userType,
                userId: user.id,
                isLinked: linked,
                module: module
              });

              /*** Envio de dados ***/
              axios.put('updateUser/', jsonData)
              .then(res => {
                toastr.success(res.data.messageHead, res.data.messageBody);

                /*** Atualização do contato da empresa
                 * Se nome ou email do usuário de contato for alterado ***/
                if (user.id === contactId  && (name !== user.name  || email !== user.email)) {
                  const jsonDataComp = JSON.stringify({
                    companyId: user.companyId,
                    contactId: user.id,
                    contactName: name,
                    contactEmail: email
                  });

                  axios.put('updateContact/', jsonDataComp)
                  .then(res => {
                    toastr.success(res.data.messageHead, res.data.messageBody);
                  })
                  .catch(err => {
                    /* Notificação de erro de envio */
                    toastr.warning(err.response.data.messageHead, err.response.data.messageBody);
                  })
                  .finally(()=>{
                    tableReload(); // atualizar visualização da tabela
                    tableCompReload(); // atualizar tabela de empresas
                    toggle(); // fecha o modal (sempre)
                  });
                }

                else {
                  tableReload(); // atualizar visualização da tabela
                  toggle(); // fecha o modal
                }
              })
              .catch(err => {
                /* Notificação de erro de envio */
                toastr.warning(err.response.data.messageHead, err.response.data.messageBody);
              });
            }
            
          }
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('dataTable.editHead')} {user.name}
        </ModalHeader>

        <ModalBody className="m-3">
          <div className="m-sm-4">
            <Row form>
              {/*** CAMPO DE NOME ***/}
              <Col md={6}>
                <FormGroup>
                  <Label>{t('dataTable.fullName')}</Label>
                  <Input
                    type="text" 
                    name="name"
                    id="name" 
                    placeholder={name}
                    value={name}
                    onChange={e=>setName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              {/*** CAMPO DE EMAIL ***/}
              <Col md={6}>
                <FormGroup>
                  <Label>E-mail</Label>
                  <Input
                    type="email" 
                    name="email"
                    id="email" 
                    placeholder={email}
                    value={email}
                    onChange={e=>setEmail(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              {/*** CAMPO DE EMPRESA ***/}
              <Col md={6}>
                <FormGroup> 
                  <Label>{t('dataTable.company')}</Label>
                  <Input
                    type="select" 
                    name="company"
                    id="company"
                    value={companyId}
                    onChange={e=>setCompanyId(e.target.value)}
                    disabled={(localStorage.getItem('userId') === user.id.toString())}
                  >
                    {JSON.parse(localStorage.getItem('companyList'))
                      .map((item, i)=>{
                        return(
                          <option 
                            value={item.id}
                            key={i}
                          >{item.name}</option>
                        )
                      })
                    }
                  </Input>
                </FormGroup>
              </Col>
              {/*** TIPO DE USUÁRIO ***/}
              <Col md={6}>
                <FormGroup>
                  <Label>{t('dataTable.userType')}</Label>
                  <Input
                    type="select" 
                    value={userType}
                    onChange={(e) => {
                      let userType = e.target.value;
                      setUserType(userType);
                      userType === '3' ? setLinked(false) : setLinked(true);
                    }}
                    disabled={(localStorage.getItem('userId') === user.id.toString()) || onlyAdmin}
                  >
                    <option value="2">{t('dataTable.default')}</option>
                    <option value="1">{t('dataTable.admin')}</option>
                    <option value="3">{t('dataTable.endUser')}</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button 
            color='primary' 
            className="mr-1 mb-1" 
            onClick={toggle}
            outline
          >
            {t('dataTable.cancel')}
          </Button>
          {/*** BOTÃO DE ENVIAR DADOS ***/}
          <Button 
            type='submit'
            color='primary'
            className="mr-1 mb-1"
          >
            {t('dataTable.editSave')}
          </Button>
        </ModalFooter>
      </Form> 
    </Modal>
  );
}

export { ModalEditUser, ModalEditCompany };
