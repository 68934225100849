import React, { useState, useEffect } from "react";
import {
  Button,
  Form, FormGroup, Input, Spinner,
  Modal, ModalHeader, ModalBody, ModalFooter, 
  Row, Col,
} from "reactstrap";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';

/****** MODAL DE REMOVER EMPRESA ******/
const ModalDeleteCompany = ({ open, toggle, comp, tableReload }) => {
  const { t } = useTranslation();

  const [captcha, setCaptcha] = useState('')

  return (
    <Modal 
      isOpen={open}
      toggle={toggle}
    >
      <Form
        onSubmit={e => {
          e.preventDefault(); // evitar erro ao enviar dados com Enter
          
          /* Verificação do captcha */
          if (captcha === "DELETE") {            
            const jsonData = JSON.stringify({
              companyId: comp.id
            });

            /*** Envio de dados ***/
            axios.delete('deleteCompany/', {data: jsonData}) // remove empresa
            .then(res => {
              toastr.success(res.data.messageHead, res.data.messageBody);
            })
            .catch(err => {
              console.log(err);
              console.log(err.response);

              if (err.response.data) {
                toastr.warning(err.response.data.messageHead, err.response.data.messageBody);
              }
            })
            .finally(()=>{
              tableReload(); // atualizar visualização da tabela
              toggle(); // fecha o modal
            });
          } 

          else {
            /* Notificação de captcha incorreto */
            toastr.warning(`${t('dataTable.error')}!`, t('dataTable.captcha'));
            setCaptcha(''); // Limpa campo de entrada
          }
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('dataTable.deleteHead')} {comp.name}
        </ModalHeader>

        <ModalBody className="text-center m-3">
          <div className="m-sm-4">
            <Row form>
              <Col md={1}>
              </Col>
              <Col md={10}>
                <h6>{t('dataTable.att')}!</h6>
                <p className="mb-0">
                  {t('dataTable.deleteBody')}
                </p>
                {/*** CAMPO DE CAPTCHA ***/}
                <Input
                  type="text" 
                  name="captcha"
                  id="captcha"
                  value={captcha}
                  onChange={e=>setCaptcha(e.target.value)}
                />
              </Col>
              <Col md={1}>
              </Col>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button 
            color='primary'
            type="button"
            onClick={toggle}
            outline
          >
            {t('dataTable.cancel')}
          </Button>
          
          {/*** BOTÃO DE DELETAR EMPRESA ***/}
          <Button
            color="primary"
            type="submit"
          >
            {t('dataTable.deleteComp')} 
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

/****** MODAL DE REMOVER USUÁRIO ******/
const ModalDeleteUser = ({ open, toggle, user, tableReload }) => {
  const { t } = useTranslation();

  const [captcha, setCaptcha] = useState('');

  return (
    <Modal 
      isOpen={open}
      toggle={toggle}
    >
      <Form
        onSubmit={e => {
          e.preventDefault(); // evitar erro ao enviar dados com Enter

          /* Verificação do captcha */
          if (captcha === "DELETE") {
            const jsonData = JSON.stringify({
              userId: user.id
            });

            /*** Envio de dados ***/
            // axios.post('deleteUser/', jsonData)
            axios.delete('deleteUser/', {data: jsonData})
            .then(res => {
              toastr.success(res.data.messageHead, res.data.messageBody);
              tableReload();
              toggle(); // fecha o modal
            })
            .catch(err => {
              console.log(err);
              console.log(err.response);
              /* Notificação de erro de envio */
              toastr.warning(err.response.data.messageHead, err.response.data.messageBody);
            });
          }
          
          else {
            /* Notificação de captcha incorreto */
            toastr.warning(`${t('dataTable.error')}!`, t('dataTable.captcha'));
            setCaptcha(''); // Limpa campo de entrada
          }
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('dataTable.deleteHead')} {user.name}
        </ModalHeader>

        <ModalBody className="text-center m-3">
          <div className="m-sm-4">
            <Row>
              <Col md={1}>
              </Col>
              <Col md={10}>
                <h6>{t('dataTable.att')}!</h6>
                <p className="mb-0">
                  {t('dataTable.deleteBody')}
                </p>
                {/*** CAMPO DE CAPTCHA ***/}
                <Input 
                  type="text" 
                  name="captcha"
                  id="captcha"
                  value={captcha}
                  onChange={e=>setCaptcha(e.target.value)}
                />
              </Col>
              <Col md={1}>
              </Col>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button 
            color='primary'
            type="button"
            onClick={toggle}
            outline
          >
            {t('dataTable.cancel')}
          </Button>
          {/*** BOTÃO DE DELETAR USUÁRO ***/}
          <Button
            color="primary"
            type="submit"
          >
              {t('dataTable.deleteUsr')} 
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

/****** MODAL DE REMOVER USUÁRIO DE CONTATO ******/
const ModalDeleteContact = ({ open, toggle, user, tableReload, tableCompReload }) => {
  const { t } = useTranslation();

  /*** Busca lista de usuários da API ***/
  const [isLoading, setLoading] = useState(true);
  const [people, setPeople] = useState();
  // dados de edição
  const [contactUser, setContactUser] = useState();
  const [captcha, setCaptcha] = useState('');

  useEffect(() => {
    // axios.post('userSearchRestricted/', JSON.stringify({
    //   companyId: user.companyId
    // }))
    axios.get('/userSearchRestricted', {params: {
      companyId: user.companyId
    }})
    .then(res => {
      setPeople(res.data);
      setContactUser({
        contactId: res.data[0].id, 
        contactName: res.data[0].name, 
        contactEmail: res.data[0].email
      });
      setLoading(false);
    })
    .catch(err => {
      console.log(err);
      toastr.error('Erro!',"Erro ao buscar usuários."); // t() da problema com o useEffect
    });
  }, [user]);

  return (
    <Modal 
      isOpen={open}
      toggle={toggle}
    >
      <Form
        onSubmit={e => {
          e.preventDefault(); // evitar erro ao enviar dados com Enter

          if (captcha === "DELETE") {
            const jsonData =  JSON.stringify({
              companyId: user.companyId,
              contactId: contactUser.contactId,
              contactName: contactUser.contactName,
              contactEmail: contactUser.contactEmail
            });

            /*** Envio de dados ***/
            /* Atualiza contato da empresa */
            axios.put('updateContact/', jsonData)
            .then(() => {
              /* Remove usuário do banco de dados */
              // axios.post('deleteUser/', JSON.stringify({userId: user.id}))
              axios.delete('deleteUser/', {data: {userId: user.id}})
              .then( res => {
                toastr.success(res.data.messageHead, res.data.messageBody);

                toggle(); // fecha o modal
              })
              .catch(err => {
                console.log(err);
                console.log(err.response);
                /* Notificação de erro de envio */
                toastr.warning(
                  err.response.data.messageHead, 
                  err.response.data.messageBody
                );
              });

            })
            .catch(err => {
              console.log(err);
              console.log(err.response);
              /* Notificação de erro de envio */
              toastr.warning(err.response.data.messageHead, err.response.data.messageBody);
            })
            .finally(()=>{
              tableReload(); // atualizar visualização da tabela
              tableCompReload(); // atualizar visualização da tabela de empresas
            });
          }
          
          else {
            /* Notificação de captcha incorreto */
            toastr.warning(`${t('dataTable.error')}!`, t('dataTable.captcha'));
            setCaptcha(''); // Limpa campo de entrada
          }
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('dataTable.deleteHead')} {user.name}
        </ModalHeader>

        <ModalBody className="text-center m-3">
          <div className="m-sm-4">
            <Row form>
              <Col md={1}>
              </Col>

              <Col md={10}>
                <h6>{t('dataTable.att')}!</h6>
                <p className="mb-0">
                  {user.name} {t('dataTable.deleteContact1')}. {t('dataTable.deleteContact2')}.
                </p>

                <FormGroup> 
                  {/*** CAMPO DE SELEÇÃO DE NOVO USUÁRIO DE CONTATO ***/}
                  {(!isLoading)
                    ? <Input 
                        type="select" 
                        name="contact"
                        id="contact"
                        value={contactUser}
                        onChange={e=>{
                          // console.log(e.target.value);
                          setContactUser(JSON.parse(e.target.value));
                        }}
                      >
                      {people.map((item, i) => {
                        if (item.id !== user.id) {
                          return(
                            <option 
                              // envia dados do usuário selecionado no formato JSON
                              value={JSON.stringify({
                                "contactId": item.id, 
                                "contactName": item.name, 
                                "contactEmail": item.email
                              })}
                              key={i}
                            >{item.name}</option>
                          )
                        } else return null
                      })}
                      </Input>
                    : (<div><Spinner color="primary" /></div>)
                  }
                </FormGroup>
              </Col>

              <Col md={1}>
              </Col>
            </Row>

            <Row form>
              <Col md={1}>
              </Col>
              <Col md={10}>
                <p className="mb-0">
                  {t('dataTable.deleteBody')}
                </p>
                {/*** CAMPO DE CAPTCHA ***/}
                <Input 
                  type="text" 
                  name="captcha"
                  id="captcha"
                  value={captcha}
                  onChange={e=>setCaptcha(e.target.value)}
                />
              </Col>
              <Col md={1}>
              </Col>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button 
            color='primary'
            type="button"
            onClick={toggle}
            outline
          >
            {t('dataTable.cancel')}
          </Button>
          {/*** BOTÃO DE DELETAR USUÁRIO ***/}
          <Button
            color="primary"
            type="submit"
          >
              {t('dataTable.deleteUsr')} 
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

/****** MODAL DE REMOVER VARIAS EMPRESAS ******/
const ModalDelManyComps = ({ open, toggle, comps, tableReload, setSelectedRows }) => {
  const { t } = useTranslation();

  const [captcha, setCaptcha] = useState('');

  return (
    <Modal 
      isOpen={open}
      toggle={toggle}
    >
      <Form
        onSubmit={e => {
          e.preventDefault(); // evitar erro ao enviar dados com Enter

          /* Verificação do captcha */
          if (captcha === "DELETE") {
            comps.forEach((item, i, arr) => {
              const jsonData = JSON.stringify({
                companyId: item.id
              });

              /*** Envio de dados ***/
              axios.delete('deleteCompany/', jsonData) // remove empresa
              .then(res => {
                console.log(res.data);
              })
              .catch(err => {
                console.log(err);
                console.log(err.response);
              })
              .finally(()=>{
                if (Object.is(arr.length - 1, i)) {
                  toastr.success(`${t('dataTable.success')}!`, t('dataTable.deletedManyComp'));
                  tableReload(); // atualizar visualização da tabela
                  setSelectedRows([]); // limpa seleção
                  toggle(); // fecha o modal
                }
              });
            })
          }
          
          else {
            /* Notificação de captcha incorreto */
            toastr.warning(`${t('dataTable.error')}!`, t('dataTable.captcha'));
            setCaptcha(''); // Limpa campo de entrada
          }
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('dataTable.deleteHeadComp')}
        </ModalHeader>

        <ModalBody className="text-center m-3">
          <div className="m-sm-4">
            <Row>
              <Col md={1}>
              </Col>
              <Col md={10}>
                <p>{t('dataTable.deleteBodyComp')}</p>
              </Col>
              <Col md={1}>
              </Col>
            </Row>
            <Row form>
              <Col md={1}>
              </Col>
              <Col md={10}>
                <h6>{t('dataTable.att')}!</h6>
                <p className="mb-0">
                  {t('dataTable.deleteBody')}
                </p>
                {/*** CAMPO DE CAPTCHA ***/}
                <Input
                  type="text" 
                  name="captcha"
                  id="captcha"
                  value={captcha}
                  onChange={e=>setCaptcha(e.target.value)}
                />
              </Col>
              <Col md={1}>
              </Col>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button 
            color='primary' 
            type="button"
            onClick={toggle}
            outline
          >
            {t('dataTable.cancel')}
          </Button>

          {/*** BOTÃO DE DELETAR EMPRESAS ***/}
          <Button
            color="primary"
            type="submit"
          >
              {t('dataTable.deleteCompMany')} 
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

/****** MODAL DE REMOVER VARIOS USUARIOS ******/
const ModalDelManyUsers = ({ open, toggle, users, tableReload }) => {
  const { t } = useTranslation();

  const [captcha, setCaptcha] = useState('');

  return (
    <Modal 
      isOpen={open}
      toggle={toggle}
    >
      <Form
        onSubmit={e => {
          e.preventDefault(); // evitar erro ao enviar dados com Enter

          /* Verificação do captcha */
          if (captcha === "DELETE") {
            users.forEach((item, i, arr) => {              
              const jsonData = JSON.stringify({
                userId: item.id
              });

              /*** Envio de dados ***/
              // axios.post('deleteUser/', jsonData)
              axios.delete('deleteUser/', {data: jsonData})
              .then( res => {
                // console.log(res.data);
              })
              .catch(err => {
                console.log(err);
                console.log(err.response);
                /* Notificação de erro de envio */
                toastr.warning(err.response.data.messageHead, err.response.data.messageBody);
              })
              .finally(()=>{
                if (Object.is(arr.length - 1, i)) {
                  toastr.success(`${t('dataTable.success')}!`, t('dataTable.deletedManyUsrs'));
                  tableReload(); // atualizar visualização da tabela
                  toggle(); // fecha o modal
                }
              })
            });
          }
          
          else {
            /* Notificação de captcha incorreto */
            toastr.warning(`${t('dataTable.error')}!`, t('dataTable.captcha'));
            setCaptcha(''); // Limpa campo de entrada
          }
        }}
      >

        <ModalHeader toggle={toggle}>
          {t('dataTable.deleteHeadUsrs')}
        </ModalHeader>

        <ModalBody className="text-center m-3">
          <div className="m-sm-4">
            <Row>
              <Col md={1}>
              </Col>
              <Col md={10}>
                <p>{t('dataTable.deleteBodyUsrs')}</p>
              </Col>
              <Col md={1}>
              </Col>
            </Row>
            
            <Row form>
              <Col md={1}>
              </Col>
              <Col md={10}>
                <h6>{t('dataTable.att')}!</h6>
                <p className="mb-0">
                  {t('dataTable.deleteBody')}
                </p>
                {/*** CAMPO DE CAPTCHA ***/}
                <Input
                  type="text" 
                  name="captcha"
                  id="captcha"
                  value={captcha}
                  onChange={e=>setCaptcha(e.target.value)}
                />
              </Col>
              <Col md={1}>
              </Col>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button 
            color='primary' 
            type="button"
            onClick={toggle}
            outline
          >
            {t('dataTable.cancel')}
          </Button>

          {/*** BOTÃO DE DELETAR USUÁRIOS ***/}
          <Button
            color="primary"
            type="submit"
          >
              {t('dataTable.deleteUsrMany')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

/****** MODAL DE REMOVER VARIOS USUÁRIOS E USUARIO DE CONTATO ******/
const ModalDelContactMany = ({ open, toggle, selectedRows, people, contactUser, tableReload, tableCompReload }) => {
  const { t } = useTranslation();

  let unselectedRows = [];

  people.forEach(item => {
    if (!selectedRows.map(a=>a.id).includes(item.id)) {
      unselectedRows.push(item)
    }
  });

  const [contactUserForm, setContactUserForm] = useState({
    contactId: unselectedRows[0].id, 
    contactName: unselectedRows[0].name, 
    contactEmail: unselectedRows[0].email
  });
  const [captcha, setCaptcha] = useState('');

  return (
    <Modal 
      isOpen={open}
      toggle={toggle}
    >
      <Form
        onSubmit={e => {
          e.preventDefault(); // evitar erro ao enviar dados com Enter

          if (captcha === "DELETE") {

            axios.put('updateContact/', JSON.stringify({
              companyId: contactUser.companyId,
              contactId: contactUserForm.contactId,
              contactName: contactUserForm.contactName,
              contactEmail: contactUserForm.contactEmail
            }))
            .then(() => {
              selectedRows.forEach((item, i, arr) => {
                const jsonData = JSON.stringify({
                  userId: item.id
                });

                /*** Envio de dados ***/
                // axios.post('deleteUser/', jsonData)
                axios.delete('deleteUser/', {data: jsonData})
                .then(res => {
                  console.log(res.data);
                })
                .catch(err => {
                  console.log(err);
                  console.log(err.response);
                  /* Notificação de erro de envio */
                  toastr.warning(err.response.data.messageHead, err.response.data.messageBody);
                })
                .finally(()=>{
                  if (Object.is(arr.length - 1, i)) {
                    toastr.success(`${t('dataTable.success')}!`, t('dataTable.deletedManyUsrs'));
                    tableReload(); // atualizar visualização da tabela
                    tableCompReload(); // atualizar visualização da tabela de empresas
                    toggle(); // fecha o modal    
                  }            
                })

              })
            })
            .catch(err => {
              console.log(err);
              console.log(err.response);
              /* Notificação de erro de envio */
              toastr.warning(err.response.data.messageHead, err.response.data.messageBody);
            });
          }
          
          else {
            /* Notificação de captcha incorreto */
            toastr.warning('Erro!','Captcha incorreto');
            setCaptcha(''); // Limpa campo de entrada
          }
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('dataTable.deleteHeadUsrs')}
        </ModalHeader>

        <ModalBody className="text-center m-3">
          <div className="m-sm-4">
            <Row>
              <Col md={1}>
              </Col>
              <Col md={10}>
                <p>{t('dataTable.deleteBodyUsrs')}</p>
              </Col>
              <Col md={1}>
              </Col>
            </Row>

            <Row form>
              <Col md={1}>
              </Col>
              <Col md={10}>
                <h6>{t('dataTable.att')}!</h6>
                <p className="mb-0">
                  {contactUser.name} {t('dataTable.deleteContact1')}. {t('dataTable.deleteContact2')}.
                </p>
                <FormGroup> 
                {/*** CAMPO DE SELEÇÃO DE NOVO USUÁRIO DE CONTATO ***/}
                  <Input 
                    type="select" 
                    name="contact"
                    id="contact"
                    value={contactUserForm}
                    onChange={e=>{
                      // console.log(e.target.value);
                      setContactUserForm(JSON.parse(e.target.value));
                    }}
                  >
                  {unselectedRows.map((item, i) => (
                    <option 
                      // envia dados do usuário selecionado no formato JSON
                      value={JSON.stringify({
                        "contactId": item.id, 
                        "contactName": item.name, 
                        "contactEmail": item.email
                      })}
                      key={i}
                    >
                      {item.name}
                    </option>
                  ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={1}>
              </Col>
            </Row>
            <Row form>
              <Col md={1}>
              </Col>
              <Col md={10}>
                <p className="mb-0">
                  {t('dataTable.deleteBody')}
                </p>
                {/*** CAMPO DE CAPTCHA ***/}
                <Input 
                  type="text" 
                  name="captcha"
                  id="captcha"
                  value={captcha}
                  onChange={e=>setCaptcha(e.target.value)}
                />
              </Col>
              <Col md={1}>
              </Col>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button 
            color='primary'
            type="button"
            onClick={toggle}
            outline
          >
            {t('dataTable.cancel')}
          </Button>

          {/*** BOTÃO DE DELETAR USUÁRIO ***/}
          <Button
            color="primary"
            type="submit"
          >
              {t('dataTable.deleteUsrMany')} 
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export { 
  ModalDeleteCompany,
  ModalDeleteUser, 
  ModalDeleteContact, 
  ModalDelManyComps,
  ModalDelManyUsers,
  ModalDelContactMany
};
