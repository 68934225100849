import React, { useState } from "react";
import {
    Button,
    Form, FormGroup, Input,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Row, Col, Label, UncontrolledTooltip
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';
// import AddNewVariable from "./AddNewVariable";

const ModalEditDriver = ({ open, toggle, driver, tableReload }) => {

    const { t } = useTranslation();
    const [models, setModels] = useState(driver.name);
    const [modelType, setModelType] = useState(driver.modelType);
    const [varList, setVarList] = useState(JSON.parse(driver.varList))

    function handleAddNewVar() {
        setVarList(prevData => [...prevData, varList])
    }

    function handleDeleteVariable(index) {
        setVarList(varList.filter((item, i) => (i !== index)));
    }

    return (
        <div>
            <Modal scrollable={true} isOpen={open} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    {t('dataTable.editHead')} {driver.name}
                </ModalHeader>

                <ModalBody
                    className="m-3"

                >
                    <Form name="form"

                        onSubmit={e => {
                            e.preventDefault(); // evitar erro ao enviar dados com Enter

                            if (!models || !modelType) {
                                setModels((!models));
                                setModelType((!modelType));
                                setVarList((!varList));
                                // e.preventDefault(); // evitar erro ao enviar dados com Enter
                                toastr.warning('Atenção!', 'Existem campos não preenchidos.')
                            } else {
                                /* Salvar dados do formulário no formato JSON */
                                const jsonData = JSON.stringify({
                                    driverId: driver.id,
                                    models: models,
                                    modelType: modelType,
                                    varList: varList
                                });

                                /*** Envio de dados ***/
                                axios.put('editCustomDriver/', jsonData)
                                    .then(res => {
                                        toastr.success(res.data.messageHead, res.data.messageBody);
                                    })
                                    .catch(err => {
                                        /* Notificação de erro de envio */
                                        if (err.response.data) {
                                            toastr.warning('Erro!', err.response.data.message);
                                        }
                                    })
                                    .finally(() => {
                                        tableReload(); // atualizar visualização da tabela
                                        toggle(); // fecha o modal
                                    });
                            }
                        }

                        }
                    >
                        <Row scrollable={true}>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="models">
                                        {t('addNewDriver.driverName')}
                                    </Label>
                                    <Input
                                        required
                                        id="models"
                                        name="models"
                                        type="text"
                                        value={models}
                                        onChange={(e) => setModels(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="modelType">
                                        {t('addNewDriver.modelType')}
                                    </Label>
                                    <Input
                                        required
                                        id="modelType"
                                        name="modelType"
                                        type="select"
                                        value={modelType}
                                        onChange={(e) => setModelType(e.target.value)}
                                    >
                                        <option value="P">{t('addNewDriver.driverTypeP')}</option>
                                        <option value="S">{t('addNewDriver.driverTypeS')}</option>
                                        <option value="M">{t('addNewDriver.driverTypeM')}</option>
                                        <option value="C">{t('addNewDriver.driverTypeC')}</option>
                                        <option value="T">{t('addNewDriver.driverTypeT')}</option>
                                        <option value="U">{t('addNewDriver.driverTypeU')}</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        {varList.map((driverDetails, index) => {
                            return (
                                <div key={index}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="varName">
                                                    {t('addNewDriver.varName')}
                                                </Label>
                                                <Input
                                                    required
                                                    id="varName"
                                                    name="varName"
                                                    // placeholder="Descreva sua variável"
                                                    type="text"
                                                    value={driverDetails.varName}
                                                    onChange={(e) => setVarList(varList.map((driverDetails, i) => {

                                                        return (i === index)
                                                            ? { ...driverDetails, varName: e.target.value }
                                                            : driverDetails;
                                                    }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="varDescription">
                                                    {t('addNewDriver.varDescription')}
                                                </Label>
                                                <Input
                                                    required
                                                    id="varDescription"
                                                    name="varDescription"
                                                    type="text"
                                                    value={driverDetails.varDescription}
                                                    onChange={(e) => setVarList(varList.map((driverDetails, i) => {
                                                        return (i === index)
                                                            ? { ...driverDetails, varDescription: e.target.value }
                                                            : driverDetails;
                                                    }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="tag">
                                                    {t('addNewDriver.varTag')}
                                                </Label>
                                                <Input
                                                    required
                                                    id="tag"
                                                    name="tag"
                                                    type="text"
                                                    value={driverDetails.tag}
                                                    onChange={(e) => setVarList(varList.map((driverDetails, i) => {

                                                        return (i === index)
                                                            ? { ...driverDetails, tag: e.target.value }
                                                            : driverDetails;
                                                    }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="unit">
                                                    {t('addNewDriver.varUnit')}
                                                </Label>
                                                <Input
                                                    required
                                                    id="unit"
                                                    name="unit"
                                                    type="text"
                                                    value={driverDetails.unit}
                                                    onChange={(e) => setVarList(varList.map((driverDetails, i) => {

                                                        return (i === index)
                                                            ? { ...driverDetails, unit: e.target.value }
                                                            : driverDetails;
                                                    }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="varType">
                                                    {t('addNewDriver.varType')}
                                                </Label>
                                                <Input
                                                    required
                                                    id="varType"
                                                    name="varType"
                                                    // placeholder="Descreva sua variável"
                                                    type="select"
                                                    value={driverDetails.varType ? driverDetails.varType : "Number"}
                                                    onChange={(e) => setVarList(varList.map((driverDetails, i) => {

                                                        return (i === index)
                                                            ? { ...driverDetails, varType: e.target.value }
                                                            : driverDetails;
                                                    }))}
                                                >
                                                    {/* <option>{t('addNewDriver.chooseOneOption')}</option> */}
                                                    <option value="Number">{t('addNewDriver.varTypeNumber')}</option>
                                                    <option value="Text">{t('addNewDriver.varTypeText')}</option>
                                                    <option value="Bool">{t('addNewDriver.varTypeBool')}</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="measureType">
                                                    {t('addNewDriver.measureType')}
                                                </Label>
                                                <Input
                                                    required
                                                    id="measureType"
                                                    name="measureType"
                                                    // placeholder="Descreva sua variável"
                                                    type="select"
                                                    value={(driverDetails.measureType) ? driverDetails.measureType : "Integral"}
                                                    onChange={(e) => setVarList(varList.map((driverDetails, i) => {

                                                        return (i === index)
                                                            ? { ...driverDetails, measureType: e.target.value }
                                                            : driverDetails;
                                                    }))}
                                                // {...register("varDescription")}
                                                >
                                                    {/* <option>{t('addNewDriver.chooseOneOption')}</option> */}
                                                    <option value="I">{t("addNewDriver.varMeasureTypeI")}</option>
                                                    <option value="D">{t("addNewDriver.varMeasureTypeD")}</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="varOptionalType">
                                                    {t('addNewDriver.varOptionalType')}
                                                </Label>
                                                <Input
                                                    id="varOptionalType"
                                                    name="varOptionalType"
                                                    type="select"
                                                    value={driverDetails.varOptionalType}
                                                    onChange={(e) => setVarList(varList.map((driverDetails, i) => {

                                                        return (i === index)
                                                            ? { ...driverDetails, varOptionalType: e.target.value }
                                                            : driverDetails;
                                                    }))}
                                                >
                                                    <option>{t("addNewDriver.varOptionalTypeSelect")}</option>
                                                    <option value="D">{t("addNewDriver.varOptionalTypeD")}</option>
                                                    <option value="E">{t("addNewDriver.varOptionalTypeE")}</option>
                                                    <option value="M">{t("addNewDriver.varOptionalTypeM")}</option>
                                                    <option value="P">{t("addNewDriver.varOptionalTypeP")}</option>
                                                    <option value="S">{t("addNewDriver.varOptionalTypeS")}</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                        <div id={"deleteVariable" + index}>
                                            <FontAwesomeIcon id={"deleteVariable" + index} onClick={() => handleDeleteVariable(index)} icon={faTrash} fixedWidth />
                                            <UncontrolledTooltip
                                                placement="top"
                                                target={"deleteVariable" + index}
                                            >
                                                {t('addNewDriver.deleteVariable')}
                                            </UncontrolledTooltip>
                                        </div>
                                    </Row>
                                    <hr />
                                </div>
                            )
                        })}
                        <Row className="ant-row-end mr-3 mb-3">
                            <Button
                                color="primary"
                                onClick={handleAddNewVar}
                            >
                                {t("addNewDriver.addVarBtn")}
                            </Button>
                        </Row>
                        <ModalFooter>
                            {/*** BOTÃO DE CANCELAR *
                  * (apenas fecha o modal sem enviar nada para a API) ***/}
                            <Button
                                type="button"
                                color='primary'
                                className="mr-1 mb-1"
                                onClick={toggle}
                                outline
                            >
                                {t('dataTable.cancel')}
                            </Button>
                            {/*** BOTÃO DE ENVIAR DADOS ***/}
                            <Button
                                type="submit"
                                color='primary'
                                className="mr-1 mb-1"
                            >
                                {t('dataTable.editSave')}
                            </Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalEditDriver;