const enUsAlarmsTranslations = {
  alarms: {
    titleConfig: "Alarm Parameters",
    titleView: "Alarm History",
    titleRegisters: "Alarm Parameters",
    editarParametros: "Edit Parameters",
    headRegisters: "Alarm parameters registers",

    // NOTE: table
    alarm: "Alarm",
    id: "#",
    deviceName: "Device",
    description: "Description",
    varName: "Variable",
    type: "Type",
    priority: "Priority",
    contact: "Contact",
    lowLimit: "Low Limit",
    highLimit: "High Limit",
    messageLowOn: "Msg. ON Low",
    messageHighOn: "Msg. ON High",
    messageLowOff: "Msg. OFF Low",
    messageHighOff: "Msg. OFF High",
    actions: "Actions",
    value: "Value",
    dateOcc: "Occurred at",
    dateOff: "Turned off at",
    onMsg: "Activation Message",
    offMsg: "Deactivation Message",
    search: "Search",

    high: "High",
    medium: "Moderate",
    low: "Low",

    select: "Select",
    phone: "Voice Call",

    // NOTE: selected
    selectedAlarms: "alarms selected",
    selectedAlarm: "alarm selected",

    // NOTE: tooltips
    addAlarm: "Add alarm",
    editAlarm: "Edit alarm",
    deleteAlarm: "Delete alarm",
    linkUsers: "Link alarm to users",
    searchAlarms: "Search alarm",
    deleteAlarms: "Delete selected alarms",
    turnOffAlarm: "Turn off alarm",
    alarmTurnedOff: "Alarm turned off",

    // NOTE: eror messages/nothing found
    noAlarmConfig: "No alarms set",
    noAlarmHistory: "No alarm occurred",

    error: "Error",
    wrongCaptcha: "Wrong captcha",

    // NOTE: mensagens de sucesso
    success: "Success",
    alarmUpdated: "Alarm updated",
    alarmRemoved: "The alarm was removed successfully",

    // NOTE: form
    project: "Project",
    projectSelect: "Please choose a project",
    device: "Device",
    deviceSelect: "Please choose a device",
    variable: "Variable",
    variableSelect: "Please choose a variable",

    highPriority: "High priority",
    mediumPriority: "Moderate priority",
    lowPriority: "Low priority",

    alarmType: "Alarm type",
    analogic: "Analogic",
    digital: "Digital",

    activeState: "Trigger state",
    on: "On",
    off: "Off",

    lowLim: "Low Limit",
    highLim: "High Limit",

    msgOn: "Message for alarm triggered",
    msgOnLo: "Message for alarm triggered on low limit",
    msgOnHi: "Message for alarm triggered on high limit",
    msgOff: "Message for alarm  untriggered",
    msgOffLo: "Message for alarm  untriggered on low limit",
    msgOffHi: "Message for alarm  untriggered on high limit",

    // NOTE: notifications
    att: "Attention",
    invalid: "Some required fields are missing",
    reference: "The alarm must have a reference name",
    noLimitLow: "No lower limit set",
    noLimitHigh: "No upper limit set",
    noLimit: "At least one limit must be set",
    lowerHigh: "The low limit must be smaller than the high limit",
    noMessages: "Messages must be set",

    // NOTE: modal delete
    captchaText: `This action is irreversible. If you wish to continue, please type "DELETE" in the field below`,
    captchaOff: `If you wish to continue, please type "ALARMOFF" in the field below`,

    // NOTE: modal de usuarios
    name: "Name",
    companyName: "Company",
    changeUsers: "Link users to alarm",

    // NOTE: buttons
    cancel: "Cancel",
    saveContinue: "Save and Continue",
    saveAlarm: "Save Alarm",
    saveChanges: "Save Changes",
    saveDelete: "Delete Alarm",
    saveDeleteSelected: "Delete Alarms",
    saveTurnOffAlarm: "Turn Off Alarm",

    // NOTE: paginação
    showing: "Showing",
    of: "of",
    results: "alarms",

    // NOTE: busca avançada
    searchField: "Search by text",
    searchProject: "Search by projects or devices",
    searchStart: "Occurred since",
    searchEnd: "Occurred until",
    searchActive: "Activation state",
    active: "Active",
    inactive: "Turned off",

    textPlaceholder: "Type some text",
    treePlaceholder: "Select some project or devices",
    datepickerPlaceholder: "Select a date and time",
  },
}

export default enUsAlarmsTranslations;