import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import * as Icon from "react-feather";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

import setHours from "date-fns/setHours";

import { toastr } from "react-redux-toastr";

import {
  CardHeader, CardBody, CardFooter, CardTitle,
  DropdownItem, DropdownMenu, DropdownToggle,
  UncontrolledDropdown, CardSubtitle,
  UncontrolledTooltip
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import { MoreVertical } from "react-feather";

import ModalDeleteCard from "../ModalDeleteCard";
// import { useContainerDimensions } from "../hooks/useRefDimensions";

let cardHeightContainer = (window.innerWidth > 700) ? "70%" : "70%";
let cardWidthContainer = (window.innerWidth > 700) ? "90%" : "90%";

const CardDropdown = (props) => {
  const { t } = useTranslation();
  const [openDeleteCard, setOpenDeleteCard] = useState(false);
  const [editCard, setEditCard] = useState(false)

  function toggleDeleteCard() {
    setOpenDeleteCard(!openDeleteCard);
  }
  return (
    <UncontrolledDropdown className="d-inline-block" disabled={(props.preview)}>
      <DropdownToggle tag="a">
        <MoreVertical className="ml-1" style={{ width: "15px" }} />
      </DropdownToggle>

      <DropdownMenu right>
        <DropdownItem
          onClick={() => toggleDeleteCard()}
        >
          {t('dashboards.delete')}
        </DropdownItem>

        {(openDeleteCard)
          ? <ModalDeleteCard
            open={openDeleteCard}
            toggle={toggleDeleteCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
          />
          : null
        }

        <Link
          onClick={() => setEditCard(true)}
          to={{
            pathname: `/newCard/BarChart/${props.cardId}`,
            state: {
              cardProps: props.card,
              editCard: editCard,
            }
          }}>
          <DropdownItem>
            {t('dashboards.edit')}
          </DropdownItem>
        </Link>
        <DropdownItem onClick={() => props.setDataLoading(true)}>
          {t('dashboards.refresh')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const BarAmCharts = ({
  cardId,
  name,
  description,
  deviceId,
  variables,
  typeVariable,
  period,
  tableReload,
  preview,
  reloadTime,
  card,
  dimensions
}) => {

  let history = useHistory();
  const { t } = useTranslation();
  const [dataLoading, setDataLoading] = useState(!preview);
  const [device, setDevice] = useState({});
  const [lastComm, setLastComm] = useState();
  let varName = (variables) ? JSON.parse(variables)?.varName : "";

  // USANDO MESMA CHAMADA DO DATA EXPLORER
  const [endDate, setEndDate] = useState(new Date()); // data e hora atual
  const [startDate, setStartDate] = useState(setHours(endDate, endDate.getHours() - card.period / 60 / 60)); // card.period antes da hora atual
  const [devicesAndVariables, setDevicesAndVariables] = useState([]);
  // const [aggregation, setAggregation] = useState(""); // periodo selecionado para Agregação
  // const [resolution, setResolution] = useState(""); // periodo selecionado para Resolução
  const [DadosChart, setDadosChart] = useState()
  const [chartDaV, setChartDaV] = useState([]);
  const [commInterval, setCommInterval] = useState();
  const [isLoadingData, setLoadingData] = useState(false);
  const [cardHeight, setCardHeight] = useState();
  const [cardWidth, setCardWidth] = useState();
  
  let parsedVariables = (variables.length) ? JSON?.parse(variables) : "";

  const lastCommDate = new Date(lastComm * 1000);

  const componentRef = useRef();
  // const { width, height } = useContainerDimensions(componentRef);
  
  useEffect(() => {
    if (dimensions) {
      if (dimensions?.id === card?.id) {

        let var1 =
          ((dimensions.size.height < 240) && (card.id === dimensions.id)) ? 240
            : ((dimensions.size.height > 241) && (card.id === dimensions.id) && (dimensions.size.height <= 310)) ? 310
              : ((dimensions.size.height > 311) && (card.id === dimensions.id) && (dimensions.size.height < 370)) ? 370
                : ((dimensions.size.height > 371) && (card.id === dimensions.id) && (dimensions.size.height < 420)) ? 420
                  : ((dimensions.size.height > 421) && (card.id === dimensions.id) && (dimensions.size.height < 500)) ? 500
                    : ((dimensions.size.height > 501) && (card.id === dimensions.id)) ? 501
                      : null
        let var2 =
          ((dimensions.size.width < 240) && (card.id === dimensions.id)) ? 240
            : ((dimensions.size.width > 241) && (card.id === dimensions.id) && (dimensions.size.width <= 310)) ? 310
              : ((dimensions.size.width > 311) && (card.id === dimensions.id) && (dimensions.size.width < 350)) ? 350
                : ((dimensions.size.width > 371) && (card.id === dimensions.id) && (dimensions.size.width < 420)) ? 420
                  : ((dimensions.size.width > 421) && (card.id === dimensions.id) && (dimensions.size.width < 500)) ? 500
                    : ((dimensions.size.width > 501) && (card.id === dimensions.id)) ? 501
                      : null
        setCardHeight(var1)
        setCardWidth(var2)
      }
    }
  }, [dimensions, card.id])

  // Lê variáveis dos dispositivos filtra e enviar para readDataExplorer
  useEffect(() => {
    let selectedValue = [];
    if (deviceId || dataLoading) {
      axios.get('/readVariables', {
        params: {
          id: deviceId
        }
      })
        .then(response => {
          if (parsedVariables.length) {
            parsedVariables.forEach((item, index) => {
              var filtered = selectedValue.filter(a => a.deviceId === item.dvcId);
              if (filtered.length === 0) {
                selectedValue.push({
                  deviceId: item.dvcId,
                  variables: [item.tag],
                  title: [item.varName]
                })
              } else {
                filtered[0].variables.push(item.tag)
                filtered[0].title.push(item.varName)
              }
            })
          } else {
            setDevicesAndVariables([{
              deviceId: deviceId,
              variables: [parsedVariables.tag],
              title: [parsedVariables.varName]
            }])
          }
          setDevicesAndVariables(selectedValue);
          setDevice(response.data);
          setDataLoading(false)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar Unidade.");
        })
    }
  }, [deviceId, dataLoading])

  useEffect(() => {
    if (devicesAndVariables || isLoadingData) {
      axios.get('/readDeviceInfo', {
        params: {
          idDevice: deviceId
        }
      })
        .then(response => {

          setDevice(response.data);
          setLastComm(response.data.lastComm);
          setCommInterval(response.data.commInterval);
        })
        .catch(err => {
          console.log(err);
          // toastr.error('Erro!', "Erro ao buscar Modelo.");
        });

      axios.get('/v2/data-explorer/readChart', {
        params: {
          startDate: (startDate) ? Math.floor(startDate.getTime() / 1000) : "",
          endDate: (endDate) ? Math.floor(endDate.getTime() / 1000) : "",
          devicesAndVariables: devicesAndVariables,
          aggregation: "",
          resolution: ""
        }
      })
        .then(response => {
          response.data.dateIni = startDate;
          response.data.dateFin = endDate;
          setDadosChart(response.data)
          setLoadingData(false);

          // response.data = response.data.data_to_send;
          // let responseDataAux = response.data;
          // Object.keys(response.data).forEach((dvcItem, d) => {
          //   // // console.log(dvcItem);
          //   response.data[dvcItem].forEach((item, i) => {
          //     // // console.log(item);
          //     let a = new Date(item.timestamp * 1000);
          //     var timeString = (a.getDate())
          //       ? `${('0' + a.getDate()).slice(-2)}/${('0' + (a.getMonth() + 1)).slice(-2)}/${a.getFullYear()} ${('0' + a.getHours()).slice(-2)}:${('0' + a.getMinutes()).slice(-2)}`
          //       : null;
          //     responseDataAux[dvcItem][i].timestamp = timeString;
          //   })
          // })
          // setDados(responseDataAux);
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar devices.");  // t() da problema com o useEffect
        })
        .finally(() => {
          setChartDaV(chartDaV);
          setLoadingData(false);
          setDataLoading(false)
        });
    }
    if (reloadTime) {
      const intervalSearch = setInterval(() => {
        if (deviceId) {
          axios.get('/readDeviceInfo', {
            params: {
              idDevice: deviceId
            }
          })
            .then(response => {
              // console.log('useEffect readDeviceInfo', response.data);
              setDevice(response.data);
              setLastComm(response.data.lastComm);
              setCommInterval(response.data.commInterval);
            })
            .catch(err => {
              console.log(err);
            });

          axios.get('/v2/data-explorer/readChart', {
            params: {
              startDate: (startDate) ? Math.floor(startDate.getTime() / 1000) : "",
              endDate: (endDate) ? Math.floor(endDate.getTime() / 1000) : "",
              devicesAndVariables: devicesAndVariables,
              aggregation: "",
              resolution: ""
            }
          })
            .then(response => {
              response.data.dateIni = startDate;
              response.data.dateFin = endDate;
              setDadosChart(response.data)
              setLoadingData(false);
            })
            .catch(err => {
              console.log(err);
              toastr.error('Erro!', "Erro ao buscar devices.");  // t() da problema com o useEffect
            })
            .finally(() => {

              setChartDaV(chartDaV);
              setLoadingData(false);
              setDataLoading(false)
            });
          if (history.location.pathname !== "/dashboardView") {
            clearInterval(intervalSearch);
          };
        }
      }, reloadTime)
    }

  }, [devicesAndVariables])

  useEffect(() => {

    if (DadosChart !== undefined) {
      let root = am5.Root.new("chartdiv" + cardId);
      root._logo.dispose();

      root.dateFormatter.setAll({
        dateFormat: `dd.MM.yy\nHH'h'mm`,
        dateFields: "date",
      });

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          layout: root.verticalLayout,
          maxTooltipDistance: 0,
          pinchZoomX: true,
          // wheelX: "panX",
          // wheelY: "zoomX"
        })
      );

      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.2,
          baseInterval: {
            timeUnit: "minute",
            count: 1
          },
          groupCount: false,
          renderer: am5xy.AxisRendererX.new(root, {}),
          categoryField: "time"
        }));

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {})
        })
      );
      DadosChart?.dataChart.forEach((element) => {
        // console.log("ELEMENT", element);
        // console.log("card", card);
        
        // Create Y-axis
        let seriesLabel = element[0]?.deviceName + '\n' + element[0]?.title;
        let seriesUnit = element[0]?.unit ? element[0].unit : ""
        let seriesName = seriesLabel + ((seriesUnit) ? ` (${seriesUnit})` : "");
        let seriesLabelText = `[bold]{label}[/] \n[bold]{datetime} \n[bold]{valueY} ${seriesUnit} [/]`;
        // Create series
        let series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            // name: `${varName} (${varUnit})`,
            name: (element.length > 0) ? seriesName : t("solar.noData"),
            xAxis,
            yAxis,
            valueYField: typeVariable === "integral" || typeVariable === null ? "variavel" : "difference",
            valueXField: "time",
            tooltip: am5.Tooltip.new(root, {
              labelText: `[bold]{name}[/]\n${seriesLabelText}`
            }),
          })
        );

        series.data.setAll(element);
      })
      
      // Add legend
      if (cardHeight > 300 && cardWidth > 300) {

        if (devicesAndVariables[0]?.variables.length < 2) {

          let legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.percent(60),
            x: am5.percent(60),
            // width: am5.percent(100),
          }));

          legend.data.setAll(chart.series.values);
        } else {

          let legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.percent(70),
            x: am5.percent(70),
            height: am5.percent(30),
            width: am5.percent(90),
            verticalScrollbar: am5.Scrollbar.new(root, {
              orientation: "vertical"
            })
          }));

          legend.data.setAll(chart.series.values);
        }
      }

      const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "zoomX"
      }));

      cursor.lineY.set("visible", false);

      // cursor.lineX.set("visible", false);
      return () => {
        root.dispose();
      };

    }

  }, [DadosChart, cardHeight, cardWidth]);

  return (
    <div ref={componentRef} className="flex-fill w-200 mb-3">
      <CardHeader className="pb-0">
        <div className="d-flex justify-content-between">
          <div className="overflow-hidden mr-5" id={"name" + cardId} >
            <CardTitle style={{ whiteSpace: "nowrap" }} tag="h5" className="mr-3 overflow-hidden text-left my-1 h5">{name}</CardTitle>
            {(name) ?
              <UncontrolledTooltip
                placement="top"
                target={"name" + cardId}
              >
                {name}
              </UncontrolledTooltip>
              : null}
            <CardSubtitle style={{ whiteSpace: "nowrap" }} className="overflow-hidden text-muted text-left">
              {description}
            </CardSubtitle>
            {(description) ?
              <UncontrolledTooltip
                placement="bottom"
                target={"name" + cardId}
              >
                {description}
              </UncontrolledTooltip>
              : null}
          </div>
          <div className="d-flex justify-content-end card-actions">
            <div className="cursor-pointer mp-0 ml-2 otavio">
              <Icon.Move style={{ width: "15px", zIndex: 15 }} />
            </div>

            <CardDropdown
              card={card}
              cardId={cardId}
              tableReload={tableReload}
              name={name}
              description={description}
              preview={preview}
              setDataLoading={setDataLoading}
            />
          </div>
        </div>
      </CardHeader>

      {/* <div id={"chartdiv" + cardId} style={{ width: cardWidth, height: cardHeight, display: "block" }}></div> */}
      {(DadosChart)
        ? <div id={"chartdiv" + cardId} style={{ width: cardWidthContainer, height: cardHeightContainer, display: "block" }}></div>
        : <div>
          <CardBody className='text-center'> {t('solar.noData')} </CardBody>
        </div>
      }
      {(cardHeight > 300) ? 
        <CardFooter style={{ whiteSpace: "nowrap", backgroundColor: "transparent", bottom: "0", position: `${(!preview) ? "absolute" : null}`, padding: "5px 1.25rem" }} className="mr-5 overflow-hidden text-left my-1 h5 text-left mt-1">
        <h6 className="text-left">{preview}</h6>
          <h6 className="card-subtitle text-muted">{(parsedVariables.length) ? parsedVariables[0]?.varName : varName}</h6>
        <h6 className="card-subtitle text-muted mt-1">
          {(lastComm)
            ?
            lastCommDate.getDate().toString().padStart(2, '0') + '/' +
            (lastCommDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
            lastCommDate.getFullYear().toString().padStart(2, '0') + ' ' +
            lastCommDate.getHours().toString().padStart(2, '0') + ':' +
            lastCommDate.getMinutes().toString().padStart(2, '0') + ':' +
            lastCommDate.getSeconds().toString().padStart(2, '0')
            : t('dashboards.noComm')
          }
        </h6>
        </CardFooter>
        : null}
    </div>
  );
};

export default connect(store => ({
  theme: store.theme
}))(BarAmCharts);
