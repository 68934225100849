import React from "react";
import { Container, Row, Col } from "reactstrap";
import Doughnut from "./Doughnut";
import MapCard from "./Map";
import AlarmViewTable  from "./ViewConect";
import DoughnutTypesAlarm  from "./DoughnutTypesAlarm";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Header >
        <HeaderTitle>{t('home.welcome')}!</HeaderTitle>
      </Header>
      
      {/* Cada Row possui 2 col com os gráficos da página */}
      <Row style={{marginBottom: "-11px", marginTop: "-25px"}}> 
        <Col md="3" style={{minWidth:"247px", paddingRight: "0px"}}>        
          <Doughnut /> 
        </Col>
        
        <Col md="9" style={{paddingRight: "0px"}} >
          <MapCard />
        </Col>
      </Row>

      <Row >
        <Col md="9" style={{paddingRight: "0px"}}>
          <AlarmViewTable style={{paddingRight: "0px"}} />
        </Col>
        <Col md="3" style={{paddingRight: "0px"}}>
          <DoughnutTypesAlarm />  
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
