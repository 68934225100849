import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

import { useTranslation } from "react-i18next";
import { AiOutlineFieldTime } from "react-icons/ai";
import { MdOutlineAttachMoney } from "react-icons/md";
import {
  PRESET,
  useWindowDimensions,
} from "../../../../../../igDashboards/view/hooks/useWindowDimensions";

const LocalCard = ({ title, value, isLast, icon }) => {
  const { preset } = useWindowDimensions();

  return (
    <Card
      style={{
        maxWidth: preset === PRESET.DESKTOP_HD ? "200px" : "unset",
        width: "100%",
        height: "100%",
        display: "flex",
        marginBottom: !isLast ? 10 : 0,
      }}
    >
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag="h1">{title}</CardTitle>
          </Col>
          {icon && (
            <Col className="col-auto">
              <div style={{ width: "33px", height: "33px" }}>
                <div className="avatar-title rounded-circle bg-primary-dark">
                  {icon}
                </div>
              </div>
            </Col>
          )}
        </Row>
      </CardHeader>
      <CardBody
        style={{
          paddingTop: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "0px",
          paddingRight: "0px",
          flex: 1,
        }}
      >
        <h3>{value}</h3>
      </CardBody>
    </Card>
  );
};

const General = ({ ucData }) => {
  const { t } = useTranslation();
  const { preset, height } = useWindowDimensions();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: preset !== PRESET.MOBILE ? height - 375 : "600px",
        maxHeight: preset === PRESET.DESKTOP_HD ? height - 300 : "unset",
        overflowY: "auto",
        padding: "0  10px",
      }}
    >
      <LocalCard
        title={t("water.unitConsumption")}
        value={`${
          ucData?.generalUcConsumption
            ? `${ucData?.generalUcConsumption.toLocaleString()} m³`
            : t("water.noData")
        }`}
        icon={<AiOutlineFieldTime className="feather align-middle" />}
      />
      <LocalCard
        title={t("water.unitValue")}
        value={`${
          ucData?.totalConsumption?.price
            ? `R$ ${ucData?.totalConsumption?.price.toLocaleString()}`
            : t("water.noData")
        }`}
        icon={<MdOutlineAttachMoney className="feather align-middle" />}
      />
      <LocalCard
        title={t("water.comumAreaShare")}
        value={`${
          ucData?.commonAreaApportionment
            ? `R$ ${ucData?.commonAreaApportionment.toLocaleString()}`
            : t("water.noData")
        }`}
        icon={<MdOutlineAttachMoney className="feather align-middle" />}
      />
      <LocalCard
        title={t("water.totalToPay")}
        value={`${
          ucData?.totalCost
            ? `R$ ${ucData?.totalCost.toLocaleString()}`
            : t("water.noData")
        }`}
        isLast
        icon={<MdOutlineAttachMoney className="feather align-middle" />}
      />
    </div>
  );
};

export default General;
