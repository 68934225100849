import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  CustomInput,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faCaretDown,
  faCaretUp,
  faSearch,
  faSort,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  ChevronDown,
  ChevronRight,
  ChevronsDown,
  ChevronsRight,
} from "react-feather";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import nullAvatar from "../../../assets/img/avatar_null";

import { ModalNewUser, ModalNewCompany } from "./modalNew";
import { ModalEditUser, ModalEditCompany } from "./modalEdit";
import {
  ModalDeleteCompany,
  ModalDeleteUser,
  ModalDeleteContact,
  ModalDelManyComps,
  ModalDelManyUsers,
  ModalDelContactMany,
} from "./modalDelete";

// const companyId = localStorage.getItem('companyId');

/*** Função habilita/desabilita empresa ***/
function enableCompany(comp, check) {
  comp.isEnabled = !comp.isEnabled;

  /* Comunicação com API pelo axios */
  axios
    .put(
      "companyEnable/",
      JSON.stringify({
        companyId: comp.id,
        isEnabled: comp.isEnabled ? "1" : "0",
      })
    )
    .then((res) => {
      /* check: verifica se função foi executada pelos checkboxes ou pelos switches *
       * se foi pelos switches, exibe notificação  */
      if (!check) {
        toastr.success(
          res.data.messageHead,
          `${comp.name} foi ${res.data.messageBody}.`
        );
      }
    })
    .catch((err) => {
      console.log(err);
      console.log(err.response);
    });
}

/*** Função habilita/desabilita usuário ***/
function enableUser(user, check) {
  user.isEnabled = !user.isEnabled;

  /* Comunicação com API pelo axios */
  axios
    .put(
      "userEnable/",
      JSON.stringify({
        userId: user.id,
        isEnabled: user.isEnabled ? "1" : "0",
      })
    )
    .then((res) => {
      /* check: verifica se função foi executada pelos checkboxes ou pelos switches *
       * se foi pelos switches, exibe notificação  */
      if (!check) {
        toastr.success(
          res.data.messageHead,
          `${user.name} ${res.data.messageBody}.`
        );
      }
    })
    .catch((err) => {
      console.log(err);
      console.log(err.response);
    });
}

/*** Botões do topo empresas ***/
const CompHeadIcons = ({
  comps,
  tableReload,
  selectedRows,
  setSelectedRows,
}) => {
  const { t } = useTranslation();

  const [openNewComp, setOpenNewComp] = useState(false);
  const [openDelManyComps, setOpenDelManyComps] = useState(false);
  // ativa/desativa modais
  function toggleNewComp() {
    setOpenNewComp(!openNewComp);
  }
  function toggleDelManyComps() {
    setOpenDelManyComps(!openDelManyComps);
  }

  /* Array de empresas desabilitadas */
  const disabledArr = selectedRows.filter((x) => x.isEnabled === 0);

  return (
    <div className="d-flex justify-content-between">
      {/* selecionados */}
      <div>
        {selectedRows.length !== 0 ? (
          <div className="d-flex justify-content-left">
            <p // ...empresas selecionadas
              className="mr-2 mb-0 pb-0"
            >
              {selectedRows.length}
              {selectedRows.length === 1
                ? ` ${t("dataTable.check1comp")}.`
                : ` ${t("dataTable.check2comp")}.`}
            </p>

            <CustomInput // switch de habilitar/desabilitar
              bsSize="sm"
              type="switch"
              id={`enableSelected`}
              defaultChecked={!disabledArr.length}
              onChange={() => {
                if (disabledArr.length) {
                  disabledArr.forEach((item) => enableCompany(item, true));
                  toastr.success(
                    `${t("dataTable.sucess")}!`,
                    t("dataTable.enableComp")
                  );
                } else {
                  selectedRows.forEach((item) => enableCompany(item, true));
                  toastr.success(
                    `${t("dataTable.sucess")}!`,
                    t("dataTable.disableComp")
                  );
                }
                tableReload(); // atualizar visualização da tabela
              }}
            />

            <div // botão de remover
              id={"deleteSelected"}
              className="align-middle cursor-pointer"
              onClick={() => {
                setOpenDelManyComps(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} fixedWidth />
              {/*** modal de remover várias empresas ***/}
              {openDelManyComps ? (
                <ModalDelManyComps
                  open={openDelManyComps}
                  toggle={toggleDelManyComps}
                  comps={selectedRows}
                  tableReload={tableReload}
                  setSelectedRows={setSelectedRows}
                />
              ) : null}
              {/* Tooltip */}
              <UncontrolledTooltip placement="top" target={"deleteSelected"}>
                {t("dataTable.delete")}
              </UncontrolledTooltip>
            </div>
          </div>
        ) : null}
      </div>

      <div // adicionar empresa
        id={"addCompany"}
        className="mr-1 cursor-pointer"
        onClick={() => setOpenNewComp(true)}
      >
        <Button color="primary">{t("dataTable.newCompany")}</Button>
        {openNewComp ? ( // modal de criar empresa
          <ModalNewCompany
            open={openNewComp}
            toggle={toggleNewComp}
            companyList={comps}
            tableReload={tableReload}
          />
        ) : null}
      </div>
    </div>
  );
};

/*** Botões do topo usuários ***/
const UserHeadIcons = ({
  comp,
  people,
  admins,
  selectedRows,
  tableReload,
  tableCompReload,
}) => {
  const { t } = useTranslation();

  const [openNewUsr, setOpenNewUsr] = useState(false);
  const [openDelManyUsers, setOpenDelManyUsers] = useState(false);
  // ativa/desativa modal
  function toggleNewUsr() {
    setOpenNewUsr(!openNewUsr);
  }
  function toggleDelManyUsers() {
    setOpenDelManyUsers(!openDelManyUsers);
  }

  /* Array de usuários desabilitados */
  const disabledArr = selectedRows.filter((x) => !x.isEnabled);
  /* Verifica se usuário de contato foi selecionado */
  const contactUser = selectedRows.find((item) => item.id === comp.contactId);

  /* Verifica se todos admins foram selecionados */
  const adminsSelected = admins.filter((item) => {
    return selectedRows.filter((element) => element.id === item.id).length;
  });

  return (
    <div className="d-flex justify-content-between">
      {/* selecionados */}
      <div className="text-left">
        {selectedRows.length !== 0 ? (
          <div className="d-flex justify-content-left">
            <p // ...usuários selecionados
              className="mr-2 mb-0 pb-0"
            >
              {selectedRows.length}
              {selectedRows.length === 1
                ? ` ${t("dataTable.check1user")}.`
                : ` ${t("dataTable.check2user")}.`}
            </p>

            <CustomInput // switch de habilitar/desabilitar
              bsSize="sm"
              type="switch"
              id={`enableSelectedUser`}
              defaultChecked={!disabledArr.length}
              onChange={() => {
                if (disabledArr.length) {
                  disabledArr.forEach((item) => enableUser(item, true));
                  toastr.success(
                    `${t("dataTable.sucess")}!`,
                    t("dataTable.enableUsrs")
                  );
                } else {
                  selectedRows.forEach((item) => enableUser(item, true));
                  toastr.success(
                    `${t("dataTable.sucess")}!`,
                    t("dataTable.disableUsrs")
                  );
                }
                tableReload(); // atualizar visualização da tabela
              }}
            />

            <div // botão de remover
              id={"deleteSelectedUsers"}
              className="align-middle cursor-pointer"
              onClick={() =>
                selectedRows.length < people.length
                  ? adminsSelected.length !== admins.length
                    ? setOpenDelManyUsers(true)
                    : toastr.warning(
                        "Erro!",
                        "Não é possível remover todos os administradores da empresa."
                      )
                  : toastr.warning(
                      `${t("dataTable.error")}!`,
                      `${t("dataTable.deleteNotAll")}.`
                    )
              }
            >
              <FontAwesomeIcon icon={faTrash} fixedWidth />
              {/*** modal de remover vários usuários ***/}
              {openDelManyUsers ? (
                contactUser ? (
                  <ModalDelContactMany
                    open={openDelManyUsers}
                    toggle={toggleDelManyUsers}
                    selectedRows={selectedRows}
                    people={people}
                    contactUser={contactUser}
                    tableReload={tableReload}
                    tableCompReload={tableCompReload}
                  />
                ) : (
                  <ModalDelManyUsers
                    open={openDelManyUsers}
                    toggle={toggleDelManyUsers}
                    users={selectedRows}
                    tableReload={tableReload}
                  />
                )
              ) : null}
              {/* Tooltip */}
              <UncontrolledTooltip
                placement="top"
                target={"deleteSelectedUsers"}
              >
                {t("dataTable.delete")}
              </UncontrolledTooltip>
            </div>
          </div>
        ) : null}
      </div>

      {/* adicionar usuário */}
      <div className="text-left">
        <div
          id={"addUser"}
          className="mr-1 cursor-pointer"
          onClick={() => setOpenNewUsr(true)}
        >
          <FontAwesomeIcon icon={faUserPlus} fixedWidth />
          {/*** modal de criar empresa  ***/}
          {openNewUsr ? (
            <ModalNewUser
              open={openNewUsr}
              toggle={toggleNewUsr}
              companyId={comp.id}
              tableReload={tableReload}
            />
          ) : null}
          {/* Tooltip */}
          <UncontrolledTooltip placement="top" target={"addUser"}>
            {t("dataTable.newUser")}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

/** Campo tipo de usuário (admin/padrão/usuarioFinal) */
const UserTypeField = ({ userType }) => {
  const { t } = useTranslation();
  let userTypeName = "";

  if(userType === 1) {
    userTypeName = "Admin";
  } else if (userType === 2) {
    userTypeName = t("dataTable.default");
  } else if (userType === 3) {
    userTypeName = t("dataTable.endUser");
  }
  return userTypeName;
};

/*** Ícones de ação empresas ***/
const ActionButtonsComp = ({ comp, tableReload }) => {
  const { t } = useTranslation();

  const [openEditComp, setOpenEditComp] = useState(false);
  const [openDeleteComp, setOpenDeleteComp] = useState(false);
  // ativa/desativa modais
  function toggleEditComp() {
    setOpenEditComp(!openEditComp);
  }
  function toggleDeleteComp() {
    setOpenDeleteComp(!openDeleteComp);
  }

  return (
    <div className="d-flex justify-content-left">
      <div // botão de editar
        id={"editComp" + comp.id}
        className="align-middle mr-1 cursor-pointer"
        onClick={() => setOpenEditComp(true)}
      >
        <FontAwesomeIcon icon={faPen} fixedWidth />
        {/*** MODAL DE EDITAR EMPRESA  ***/}
        {openEditComp ? (
          <ModalEditCompany
            open={openEditComp}
            toggle={toggleEditComp}
            comp={comp}
            tableReload={tableReload}
          />
        ) : null}
        {/* Tooltip */}
        <UncontrolledTooltip placement="top" target={"editComp" + comp.id}>
          {t("dataTable.editComp")}
        </UncontrolledTooltip>
      </div>

      <div // botão de remover
        id={"deleteComp" + comp.id}
        className="align-middle cursor-pointer"
        onClick={() => {
          comp.id.toString() === localStorage.getItem("companyId")
            ? toastr.warning(t("dataTable.att"), t("dataTable.deleteNotComp"))
            : setOpenDeleteComp(true);
        }}
      >
        <FontAwesomeIcon icon={faTrash} fixedWidth />
        {/*** MODAL DE REMOVER EMPRESA  ***/}
        {openDeleteComp ? (
          <ModalDeleteCompany
            open={openDeleteComp}
            toggle={toggleDeleteComp}
            comp={comp}
            tableReload={tableReload}
          />
        ) : null}
        {/* Tooltip */}
        <UncontrolledTooltip placement="top" target={"deleteComp" + comp.id}>
          {t("dataTable.deleteComp")}
        </UncontrolledTooltip>
      </div>
    </div>
  );
};

/*** Ícones de ação usuários ***/
const ActionButtonsUsr = ({
  user,
  comp,
  people,
  admins,
  tableReload,
  tableCompReload,
}) => {
  const { t } = useTranslation();

  const [openEditUsr, setOpenEditUsr] = useState(false);
  const [openDeleteUsr, setOpenDeleteUsr] = useState(false);
  // ativa/desativa modais
  function toggleEditUsr() {
    setOpenEditUsr(!openEditUsr);
  }
  function toggleDeleteUsr() {
    setOpenDeleteUsr(!openDeleteUsr);
  }

  return (
    <div className="d-flex justify-content-left">
      <div // botão de editar
        id={"editUser" + user.id}
        className="align-middle mr-1 cursor-pointer"
        onClick={() => setOpenEditUsr(true)}
      >
        <FontAwesomeIcon icon={faPen} fixedWidth />
        {/*** MODAL DE EDITAR USUÁRIO  ***/}
        {openEditUsr ? (
          <ModalEditUser
            open={openEditUsr}
            toggle={toggleEditUsr}
            user={user}
            onlyAdmin={admins.length < 2}
            contactId={comp.contactId}
            tableReload={tableReload}
            tableCompReload={tableCompReload}
          />
        ) : null}
        {/* Tooltip */}
        <UncontrolledTooltip placement="top" target={"editUser" + user.id}>
          {t("dataTable.editUsers")}
        </UncontrolledTooltip>
      </div>

      <div // botão de remover
        id={"deleteUser" + user.id}
        className="align-middle cursor-pointer"
        onClick={() => {
          user.id.toString() === localStorage.getItem("userId")
            ? toastr.warning(t("dataTable.att"), t("dataTable.deleteNotUser"))
            : people.length === 1
            ? toastr.warning(
                "Atenção!",
                "Não é possível excluir o único usuário da empresa."
              )
            : admins.includes(user) && admins.length === 1
            ? toastr.warning(
                "Atenção!",
                "Não é possível excluir o único administrador da empresa."
              )
            : setOpenDeleteUsr(true);
        }}
      >
        <FontAwesomeIcon icon={faTrash} fixedWidth />
        {/*** MODAL DE REMOVER USUÁRIO  ***/}
        {openDeleteUsr ? (
          user.id === comp.contactId ? (
            <ModalDeleteContact
              open={openDeleteUsr}
              toggle={toggleDeleteUsr}
              user={user}
              comp={comp}
              tableReload={tableReload}
              tableCompReload={tableCompReload}
            />
          ) : (
            <ModalDeleteUser
              open={openDeleteUsr}
              toggle={toggleDeleteUsr}
              user={user}
              comp={comp}
              tableReload={tableReload}
            />
          )
        ) : null}
        {/* Tooltip */}
        <UncontrolledTooltip placement="top" target={"deleteUser" + user.id}>
          {t("dataTable.deleteUsr")}
        </UncontrolledTooltip>
      </div>
    </div>
  );
};

/****** TABELA DE USUÁRIOS (SUB-TABELA) ******/
const UserTableShow = ({ comp, tableCompReload }) => {
  const { t } = useTranslation();

  /*** busca usuários da API ***/
  const [isLoading, setLoading] = useState(true);
  const [people, setPeople] = useState();
  const [admins, setAdmins] = useState([]);
  /* array de usuários selecionados */
  const [selPeeps, setSelPeeps] = useState([]);

  /*** configurações das colunas da tabela de usuários ***/
  const colsUsr = [
    {
      // ID (oculto)
      dataField: "userId",
      text: `${t("dataTable.id")}`,
      sort: true,
      hidden: true,
    },
    {
      // foto de perfil
      dataField: "avatar",
      text: "",
      headerStyle: { width: "7%" },
      formatter: (rowContent) => (
        <img
          alt=""
          src={rowContent ? rowContent : nullAvatar}
          className="rounded-circle img-responsive mp-0"
          width="30"
          height="30"
        />
      ),
    },
    {
      // nome do usuário
      dataField: "name",
      text: `${t("dataTable.name")}`,
      sort: true,
    },
    {
      // email do usuário
      dataField: "email",
      text: "E-mail",
      headerStyle: { width: "25%" },
    },
    {
      // telefone do usuário
      dataField: "phone",
      text: "Telefone",
      // headerStyle: { width: "25%" }
      formatter: (cell) => (cell ? cell : "-"),
    },
    {
      // nome da empresa
      dataField: "companyName",
      text: `${t("dataTable.company")}`,
    },
    {
      // tipo de usuário
      dataField: "userType",
      text: `${t("dataTable.type")}`,
      sort: true,
      formatter: (rowContent) => <UserTypeField userType={rowContent} />,
    },
    {
      // switch habilita/desabilita
      dataField: "isEnabled",
      text: `${t("dataTable.enable")}`,
      sort: true,
      headerStyle: { width: "15%" },
      formatter: (_, row) => {
        return (
          <CustomInput
            type="switch"
            id={`user${row.id}Switch`}
            defaultChecked={row.isEnabled}
            disabled={row.id.toString() === localStorage.getItem("userId")}
            onChange={() => {
              enableUser(row);
            }}
          />
        );
      },
    },
    {
      // botões de ação (editar/remover)
      dataField: "actions",
      text: `${t("dataTable.actions")}`,
      headerStyle: { width: "10%" },
      formatExtraData: { comp: comp, people: people, admins: admins },
      formatter: (cell, row, rowIndex, extra) => {
        return (
          <ActionButtonsUsr
            className="text-center"
            user={row}
            comp={extra.comp}
            people={extra.people}
            admins={extra.admins}
            tableReload={() => setLoading(true)}
            tableCompReload={tableCompReload}
          />
        );
      },
    },
  ];

  useEffect(() => {
    // axios.post('userSearchRestricted/', JSON.stringify({
    //   companyId: comp.id
    // }))
    axios
      .get("/userSearchRestricted", {
        params: {
          companyId: comp.id,
        },
      })
      .then((res) => {
        setPeople(res.data);
        setAdmins(res.data.filter((item) => item.userType === 1 && item.id));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toastr.error("Erro!", "Erro ao buscar usuários."); // t() da problema com o useEffect
      });
  }, [isLoading, comp.id]);

  /* exibe enquanto promise do axios não resolve */
  if (isLoading && !people) {
    return (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    );
  }

  /* ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) =>
      !order ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faSort} />
      ) : order === "asc" ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faCaretUp} />
      ) : (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faCaretDown} />
      ),
  };

  /* Caixas de seleção de linha */
  const selectRow = {
    mode: "checkbox",
    bgColor: "#f8f9fa",
    nonSelectable: [parseInt(localStorage.getItem("userId"))],
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelPeeps([
          ...selPeeps,
          {
            id: row.id,
            name: row.name,
            isEnabled: row.isEnabled,
            switch: document.getElementById(`user${row.id}Switch`).checked,
          },
        ]);
      } else {
        setSelPeeps(selPeeps.filter((x) => x.id !== row.id));
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        let ids = [];
        rows.map((row) =>
          ids.push({
            id: row.id,
            name: row.name,
            isEnabled: row.isEnabled,
            switch: document.getElementById(`user${row.id}Switch`).checked,
          })
        );
        setSelPeeps(ids);
      } else {
        setSelPeeps([]);
      }
    },
  };

  const { SearchBar } = Search;

  function onColumnMatch({ searchText, value, column, row }) {
    if (row.name && row.email) {
      if (
        row.name
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(
            searchText
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          ) ||
        row.email
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(
            searchText
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
      ) {
        return true;
      }
    }
    return false;
  }

  /*** RENDERIZA SUB-TABELA ***/
  return people.length ? (
    <div className="my-2">
      <ToolkitProvider
        bootstrap4
        keyField="id"
        data={people}
        columns={colsUsr}
        search={{
          onColumnMatch,
        }}
      >
        {(props) => (
          <>
            <Row className="mb-2 align-items-center">
              <Col md="4" xs="4" className="text-left">
                <SearchBar
                  style={{ width: "100%" }}
                  placeholder="Buscar usuário"
                  {...props.searchProps}
                />
              </Col>
              <Col md="8" xs="8" className="text-right">
                <UserHeadIcons
                  comp={comp}
                  people={people}
                  admins={admins}
                  selectedRows={selPeeps}
                  tableReload={() => setLoading(true)}
                  tableCompReload={tableCompReload}
                />
              </Col>
            </Row>
            <BootstrapTable
              condensed
              bordered={false}
              // striped={true}
              sort={sortIcon}
              selectRow={selectRow}
              {...props.baseProps}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  ) : (
    <p>{t("dataTable.noUsers")}.</p>
  );
};

/****** TABELA DE EMPRESAS ******/
const CompTableShow = () => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true);
  const [comps, setComps] = useState([]);
  /* Array de empresas selecionadas */
  const [selComps, setSelComps] = useState([]);
  const [onSearch, setOnSearch] = useState(false);

  /*** Busca empresas da API ***/
  localStorage.removeItem("companyList");

  useEffect(() => {
    if (isLoading) {
      // axios.post('companySearch/', JSON.stringify({
      //   companyId: companyId
      // }))
      // axios.get('/companySearch', {params: {
      //   companyId: localStorage.getItem('companyId')
      // }})
      axios
        .get("/companySearch")
        .then((res) => {
          setComps(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          toastr.error("Erro!", "Erro ao buscar empresas."); // t() da problema com o useEffect
        });
    }
  }, [isLoading, selComps]);

  /* Exibe spinner enquanto a promise do axios não resolve */
  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    );
  }

  /* Salva lista de empresas na localStorage *
   * para agilizar carregamento posterior */
  localStorage.setItem("companyList", JSON.stringify(comps));

  /*** Configurações das colunas da tabela de empresas ***/
  const colsComp = [
    {
      // ID (oculto)
      dataField: "id",
      text: `${t("dataTable.id")}`,
      sort: true,
      hidden: true,
      isKey: true,
    },
    {
      // nome da empresa
      dataField: "name",
      text: `${t("dataTable.name")}`,
      sort: true,
      headerStyle: { width: "20%" },
    },
    {
      // nome do usuário de contato
      dataField: "contactName",
      text: `${t("dataTable.contactName")}`,
    },
    {
      // email do usuário de contato
      dataField: "contactEmail",
      text: "E-mail",
    },
    {
      // switch habilita/desabilita
      dataField: "isEnabled",
      text: `${t("dataTable.enable")}`,
      sort: true,
      headerStyle: { width: "13%" },
      formatter: (_, row) => {
        return (
          <CustomInput
            bsSize="sm"
            type="switch"
            id={`comp${row.id}Switch`}
            defaultChecked={row.isEnabled}
            disabled={row.id.toString() === localStorage.getItem("companyId")}
            onChange={() => {
              enableCompany(row);
            }}
          />
        );
      },
    },
    {
      // botões de ação (editar/remover)
      dataField: "actions",
      text: `${t("dataTable.actions")}`,
      headerStyle: { width: "10%" },
      formatter: (_, row) => {
        return (
          <ActionButtonsComp
            className="text-center"
            comp={row}
            tableReload={() => setLoading(true)}
          />
        );
      },
    },
  ];

  /* Ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) =>
      !order ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faSort} />
      ) : order === "asc" ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faCaretUp} />
      ) : (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faCaretDown} />
      ),
  };

  /* Caixas de seleção de linha */
  const selectRow = {
    mode: "checkbox",
    bgColor: "#f8f9fa",
    nonSelectable: [parseInt(localStorage.getItem("companyId"))],
    onSelect: (row, isSelect) => {
      setOnSearch(false);
      if (isSelect) {
        setSelComps([
          ...selComps,
          {
            id: row.id,
            name: row.name,
            isEnabled: row.isEnabled,
          },
        ]);
      } else {
        setSelComps(selComps.filter((x) => x.id !== row.id));
      }
    },
    onSelectAll: (isSelect, rows) => {
      setOnSearch(false);
      if (isSelect) {
        let ids = [];
        rows.map((row) =>
          ids.push({
            id: row.id,
            name: row.name,
            isEnabled: row.isEnabled,
          })
        );
        setSelComps(ids);
      } else {
        setSelComps([]);
      }
    },
  };

  /*** Configurações de expansão de linha ***/
  const expandRow = {
    renderer: (row) => {
      return (
        <div>
          <UserTableShow comp={row} tableCompReload={() => setLoading(true)} />
        </div>
      );
    },
    /* Coluna de expansão - sinais > e V */
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? (
        <ChevronsDown className="cursor-pointer" width={16} height={16} />
      ) : (
        <ChevronsRight className="cursor-pointer" width={16} height={16} />
      ),
    expandColumnRenderer: ({ expanded }) =>
      expanded ? (
        <ChevronDown className="cursor-pointer" width={16} height={16} />
      ) : (
        <ChevronRight className="cursor-pointer" width={16} height={16} />
      ),
  };

  /* Barra de busca */
  const { SearchBar } = Search;

  // ignorar acentos e letras maiúsculas
  function onColumnMatch({ searchText, value, column, row }) {
    if (
      row.name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          searchText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        ) ||
      row.contactName
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          searchText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        ) ||
      row.contactEmail
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          searchText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    ) {
      return true;
    }
    return false;
  }

  /* Retorna Tabela de Empresas e demais componentes */
  return (
    <div>
      {comps.length ? (
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={comps}
          columns={colsComp}
          search={{
            onColumnMatch,
          }}
        >
          {(props) => (
            <div>
              <Row className="mb-2 d-flex align-items-center">
                <Col md="4" xs="4" className="text-left">
                  {onSearch ? (
                    <SearchBar
                      style={{ width: "100%" }}
                      placeholder="Buscar empresa"
                      {...props.searchProps}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      icon={faSearch}
                      fixedWidth
                      onClick={() => {
                        setOnSearch(true);
                        setSelComps([]);
                      }}
                    />
                  )}
                </Col>

                <Col md="8" xs="8" className="text-right">
                  <CompHeadIcons
                    comps={comps}
                    tableReload={() => setLoading(true)}
                    selectedRows={selComps}
                    setSelectedRows={setSelComps}
                  />
                </Col>
              </Row>

              <BootstrapTable
                condensed
                bordered={false}
                // striped={true}
                sort={sortIcon}
                selectRow={selectRow}
                expandRow={expandRow}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      ) : (
        <div>
          <Row className="mb-2">
            <Col md="4" xs="4" className="text-left"></Col>

            <Col md="8" xs="8" className="text-right">
              <CompHeadIcons
                comps={comps}
                tableReload={() => setLoading(true)}
                selectedRows={selComps}
                setSelectedRows={setSelComps}
              />
            </Col>
          </Row>
          <p>{t("dataTable.noComps")}.</p>
        </div>
      )}
    </div>
  );
};

/****** PÁGINA ******/
const DataTable = () => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t("dataTable.title")}</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{t("sidebar.home")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/">{t("sidebar.registers")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("sidebar.usersData")}</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Card style={{ overflowY: "scroll", marginTop: "-26px" }}>
        <CardHeader>
          <CardTitle tag="h5">{t("dataTable.head")}</CardTitle>
          <h6 className="card-subtitle text-muted mt-1">
            {t("dataTable.text1")} <ChevronRight width={16} height={16} />{" "}
            {t("dataTable.text2")}
          </h6>
        </CardHeader>
        <CardBody>
          <CompTableShow />
        </CardBody>
      </Card>
    </Container>
  );
};

export default DataTable;
