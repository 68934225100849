import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";
import { getSupportEmail } from "./../../../../../../../utils/functions";

import { getCompanyLogo } from "../../../../../../../utils/domain";

function displayBigNumbers(num, unit) {
  const tera = Math.pow(10, 12);
  const giga = Math.pow(10, 9);
  const mega = Math.pow(10, 6);
  const kilo = Math.pow(10, 3);

  if (unit.startsWith("k")) {
    num = num * kilo;
  } else if (unit.startsWith("M")) {
    num = num * mega;
  } else if (unit.startsWith("G")) {
    num = num * giga;
  } else if (unit.startsWith("T")) {
    num = num * tera;
  }

  let disp;
  let scale;

  if (num > tera) {
    disp = Math.floor((num / tera) * 1000) / 1000;
    scale = "T";
  } else if (num > giga) {
    disp = Math.floor((num / giga) * 1000) / 1000;
    scale = "G";
  } else if (num > mega) {
    disp = Math.floor((num / mega) * 1000) / 1000;
    scale = "M";
  } else if (num > kilo) {
    disp = Math.floor((num / kilo) * 1000) / 1000;
    scale = "k";
  } else {
    disp = Math.floor(num * 1000) / 1000;
    scale = "";
  }

  return disp + " " + scale + unit.slice(1);
}

export const ReportView = ({
  getDataToSend,
  monthRef,
  data,
  projectId,
  sendMail,
  screenshot,
  projectName,
  monthlyDataUsage,
  monthlyReportUsage,
}) => {
  const styles = StyleSheet.create({
    page: {
      paddingTop: 22,
      paddingHorizontal: 48,
      backgroundColor: "#eef9f9",
    },
    logo: {
      height: 50,
      marginLeft: "auto",
      marginRight: "auto",
    },
    viewer: {
      width: "100%",
      height: Math.floor(window.innerHeight * 0.67),
    },
    body: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
    },
    h2: {
      display: "block",
      fontSize: 16,
      marginTop: 1,
      marginBottom: 1,
      marginLeft: 0,
      marginRight: 0,
      padding: 10,
    },
    row: {
      alignItems: "center",
      flexDirection: "row",
    },
    card: {
      flexDirection: "row",
    },
    left: {
      width: "50%", //<- working alternative
      color: "#3E4676",
    },
    right: {
      padding: 5,
      color: "#2469CE",
      width: "50%", //<- working alternative
      textAlign: "right",
    },
    footer: {
      fontSize: 12,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "#2469CE",
      address: {
        fontSize: 12,
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "#3E4676",
        lineHeight: 1.5,
      },
      obtainedData: {
        position: "absolute",
        fontSize: 8,
        bottom: -16,
        right: 50,
        color: "#3E4676",
        lineHeight: 1.5,
      },
    },
  });

  return data ? (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <ReportDocument
        getDataToSend={getDataToSend}
        monthRef={monthRef}
        data={data}
        projectId={projectId}
        sendMail={sendMail}
        screenshot={screenshot}
        projectName={projectName}
        monthlyDataUsage={monthlyDataUsage}
        monthlyReportUsage={monthlyReportUsage}
      />
    </PDFViewer>
  ) : (
    <div className="text-center">
      <Spinner color="primary" />
    </div>
  );
};

export const ReportDocument = ({
  getDataToSend,
  monthRef,
  data,
  screenshot,
  projectName,
}) => {
  const { t } = useTranslation();
  // Create styles
  const styles = StyleSheet.create({
    page: {
      paddingTop: 22,
      paddingHorizontal: 48,
      backgroundColor: "#eef9f9",
    },
    logo: {
      height: 50,
      marginLeft: "auto",
      marginRight: "auto",
    },
    viewer: {
      width: "100%",
      height: Math.floor(window.innerHeight * 0.67),
    },
    body: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
    },
    h2: {
      display: "block",
      fontSize: 16,
      marginTop: 1,
      marginBottom: 1,
      marginLeft: 0,
      marginRight: 0,
      padding: 10,
    },
    row: {
      alignItems: "center",
      flexDirection: "row",
    },
    card: {
      flexDirection: "row",
    },
    left: {
      width: "50%", //<- working alternative
      color: "#3E4676",
    },
    right: {
      padding: 5,
      color: "#2469CE",
      width: "50%", //<- working alternative
      textAlign: "right",
    },
    footer: {
      fontSize: 12,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "#2469CE",
      address: {
        fontSize: 12,
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "#3E4676",
        lineHeight: 1.5,
      },
      obtainedData: {
        position: "absolute",
        fontSize: 8,
        bottom: -16,
        right: 50,
        color: "#3E4676",
        lineHeight: 1.5,
      },
    },
  });
  const logo = getCompanyLogo();

  const monthDate = new Date(Number(monthRef));
  const monthStr = `${t(
    "reports.month" + (monthDate.getMonth() + 1)
  )}/${monthDate.getFullYear()}`;
  const monthlyEnergy = displayBigNumbers(data.general.monthlyEnergy, "kWh");
  const cultivatedTrees = displayBigNumbers(data.general.cultivatedTrees, "");
  const projectedEnergy = displayBigNumbers(
    data.general.projectedEnergy,
    "kWh"
  );
  const avoidedDisp =
    (Math.floor(data.general.avoidedCO2) / 1000).toString().replace(".", ",") +
    " t CO2";

  const currencyBRL = (value) => {
    const formattedValue = value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    return formattedValue;
  };

  const jsonToAttachment = {
    projectName: projectName[0].name,
    logo: logo,
    monthStr: monthStr,
    currencyBRL: currencyBRL(data.general.income),
    performance: data.general.performanceRatio,
    monthlyEnergy: monthlyEnergy,
    projectedEnergy: projectedEnergy,
    avoidedDisp: avoidedDisp,
    cultivatedTrees: cultivatedTrees,
    screenshot: screenshot,
  };

  getDataToSend(jsonToAttachment);

  return (
    <Document>
      {/*render a single page*/}
      <Page style={styles.page} size="A4">
        {/* NOME DA PLANTA E LOGO IGOAL */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 8,
            alignItems: "center",
            color: "#00649f",
            // color: "#2469CE"
          }}
        >
          <View>
            <Text>{t("solarReport.reportTitle")}</Text>
            {projectName ? <Text>{projectName[0].name}</Text> : null}
          </View>
          <View>
            <Image style={styles.logo} src={logo} />
          </View>
        </View>
        <Text
          style={{
            //MÊS DE REFERÊNCIA
            color: "#00649f",
            letterSpacing: 4,
            fontSize: 25,
            textAlign: "left",
            textTransform: "uppercase",
            marginTop: 16,
          }}
        >
          {monthStr}
        </Text>
        {/* VOCÊ ECONOMIZOU / TAXA DE DESEMPENHO */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 16,
            marginBottom: 20,
            paddingLeft: 20,
          }}
        >
          <View
            style={{
              //ECONOMIA
              position: "absolute",
              left: 0,
              justifyContent: "space-between",
              backgroundColor: "#009c77",
              color: "#FFF",
              border: "2px solid #CCC",
              borderRadius: 16,
              height: 90,
              width: "50%", //<- working alternative
              textAlign: "center",
            }}
          >
            <View>
              <Text style={{ fontSize: 16, marginTop: 16 }}>
                {t("solarReport.youSaved")}
              </Text>
              <Text style={{ fontSize: 28, marginTop: 8, color: "#a6fdb3" }}>
                {currencyBRL(data.general.income)}
              </Text>
            </View>
          </View>
          <View
            style={{
              //ENERGIA GERADA
              position: "absolute",
              right: -16,
              backgroundColor: "#0078BF",
              marginHorizontal: 18,
              color: "#FFF",
              border: "2px solid #CCC",
              borderRadius: 16,
              height: 90,
              width: "50%", //<- working alternative
              textAlign: "center",
            }}
          >
            <View>
              <Text style={{ fontSize: 14, marginTop: 16 }}>
                {t("solarReport.performanceRatio")}
              </Text>
              <Text style={{ fontSize: 28, marginTop: 8, color: "#CCC" }}>
                {Number(data.general.performanceRatio).toFixed(2)}%
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            //VISÃO GERAL EM Wh DA GERAÇÃO E PRESERVAÇÃO
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            position: "absolute",
            top: 250,
            left: 50,
            right: 50,
            alignItems: "center",
            color: "#00649f",
            // color: "#2469CE"
          }}
        >
          <View
            style={{
              backgroundColor: "#009c77",
              // marginHorizontal: 18,
              color: "#FFF",
              border: "2px solid #CCC",
              borderRadius: 16,
              height: 80,
              width: "33%", //<- working alternative
              // color: "#3E4676",
              textAlign: "center",
            }}
          >
            <Text style={{ fontSize: 16, marginTop: 16 }}>
              {t("solarReport.generatedEnergy")}
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16, marginTop: 8, color: "#a6fdb3" }}>
                {monthlyEnergy}
              </Text>
            </View>
          </View>
          <View
            style={{
              backgroundColor: "#009c77",
              // marginHorizontal: 18,
              color: "#FFF",
              border: "2px solid #CCC",
              borderRadius: 16,
              height: 80,
              width: "33%", //<- working alternative
              textAlign: "center",
            }}
          >
            <Text style={{ fontSize: 16, marginTop: 16 }}>
              {t("solarReport.projectedEnergy")}
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16, marginTop: 8, color: "#a6fdb3" }}>
                {projectedEnergy}
              </Text>
            </View>
          </View>
          <View
            style={{
              backgroundColor: "#009c77",
              color: "#FFF",
              border: "2px solid #CCC",
              borderRadius: 16,
              height: 80,
              width: "33%", //<- working alternative
              textAlign: "center",
            }}
          >
            <Text style={{ fontSize: 16, marginTop: 16, color: "#FFF" }}>
              {t("solarReport.avoidedDisp")}
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <Image style={{ marginTop: 8, width: 24, }} src={co2Icon} /> */}
              <Text style={{ fontSize: 16, marginTop: 8, color: "#a6fdb3" }}>
                {avoidedDisp}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            //VISÃO GERAL EM Wh DA GERAÇÃO E PRESERVAÇÃO
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            position: "absolute",
            top: 350,
            left: 50,
            right: 50,
            // marginBottom: 12,
            alignItems: "center",
            color: "#00649f",
            // color: "#2469CE"
          }}
        >
          <View
            style={{
              backgroundColor: "#009c77",
              // marginHorizontal: 18,
              color: "#FFF",
              border: "2px solid #CCC",
              borderRadius: 16,
              height: 80,
              width: "100%", //<- working alternative
              // color: "#3E4676",
              textAlign: "center",
            }}
          >
            <Text style={{ fontSize: 16, marginTop: 16 }}>
              {t("solarReport.cultivatedTrees")}
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <Image style={{ marginTop: 8, width: 24, }} src={treeIcon} /> */}
              <Text style={{ fontSize: 16, marginTop: 8, color: "#a6fdb3" }}>
                {Number(cultivatedTrees).toFixed(1)}
              </Text>
            </View>
          </View>
        </View>

        {/* GRAFICO MESES ANTERIORES */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            justifyContent: "space-between",
            alignItems: "center",
            bottom: 140,
            left: 50,
            right: 50,
            // marginBottom: 20,
            // height: 300,
          }}
        >
          <View>
            <Text
              style={{
                // margin: "auto",
                textAlign: "center",
                backgroundColor: "#009c77",
                width: "100%",
                color: "#FFF",
                paddingVertical: 4,
                fontSize: 16,
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
              }}
            >
              {t("solarReport.previousMonths")}
            </Text>
            <View
              style={{
                color: "#FFF",
                height: 220,
                textAlign: "center",
                paddingBottom: 16,
                paddingRight: 16,
                backgroundColor: "#CCC",
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
              }}
            >
              {screenshot ? <Image src={screenshot} /> : null}
            </View>
          </View>
        </View>

        <View></View>

        {/* === RODAPÉ FIXO === */}
        <View
          style={{
            position: "absolute",
            bottom: 50,
            left: 0,
            right: 0,
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center"
          }}
        >
          <Text style={styles.footer}>{t("solarReport.solutionMade")}</Text>
          <Image
            style={{
              height: 33,
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={logo}
          />
          <Text style={styles.footer.address}>
            E-mail: {getSupportEmail().email}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default ReportView;
