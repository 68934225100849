const enUsDriverTranslations = {
  addNewDriver: {
    myCustomDriversAddBtn: "Add",
    noAddedDrivers: "No custom drivers added.",
    addSuccessVar: "Variable Added Successfuly.",
    tableColumnModels: "Model",
    tableColumnCompany: "Company",
    tableColumnType: "Type",
    title: "My Drivers",
    driverName: "Driver Name",
    customDrivers: "Custom Drivers",
    btnAdd: "Add",
    modalTitle: "Add Driver",
    description: "Description",
    tag: "Tag",
    unit: "Unit",
    type: "Type",
    measureType: "Measure",
    driverCreator: "Driver Creator",
    editDriver: "Edit Driver",
    deleteVariable: "Delete Variable",
    deleteDriver: "Delete Driver",
    toastSaveVar: "Please, fill all the fields before saving.",

    // NOTE:MODAL ADICIONAR DRIVER
    variableSuccessfulyAdded: "Variable Successfuly Added.",
    modelType: "Driver Type",
    varName: "Variable Name",
    varDescription: "Variable Description",
    varTag: "Tag",
    varUnit: "Unit",
    varType: "Type",
    varMeasure: "Measure",
    addVarBtn: "Add Variable",
    cancelVarBtn: "Cancel",
    saveNewDriverBtn: "Save Driver",
    // NOTE:Tipo de driver Options
    chooseOneOption: "Choose one option",
    driverTypeC: "A/C",
    driverTypeE: "Power Weather",
    driverTypeM: "Solarimetric Station",
    driverTypeP: "Power",
    driverTypeS: "Inverter",
    driverTypeT: "Solar Tracker",
    driverTypeU: "Utils",
    // NOTE:Tipo de variável
    varTypeBool: "Logical",
    varTypeNumber: "Number",
    varTypeText: "Text",
    // NOTE:Tipo das Medidas
    varMeasureTypeI: "Integral",
    varMeasureTypeD: "Differential",
    // NOTE:Tipo Opcional da Variável
    varOptionalType: "Variable Type",
    varOptionalTypeSelect: "Optional",
    varOptionalTypeD: "Daily Energy Accumulator",
    varOptionalTypeE: "Total Energy Accumulator",
    varOptionalTypeM: "Solar Irradiation",
    varOptionalTypeP: "Power Reading",
    varOptionalTypeS: "Status",
    // NOTE:MODAL DELETE
    confirmDeleteDriver: "Remove Driver",
    // NOTE:TABELA EXPANDIDA
    driverNameExpandedRow: "Name",
    driverDescriptionExpandedRow: "Description",
    driverTagExpandedRow: "Tag",
    driverUnitExpandedRow: "Unit",
    driverTypeExpandedRow: "Type",
    driverMeasureExpandedRow: "Measure",
    noVariablesAdded: "No variables added",
  },
}

export default enUsDriverTranslations;