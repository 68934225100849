import React from "react";
import { PDFViewer } from '@react-pdf/renderer';
import PdfBillDocument from "./PdfBillDocument";

export const PdfBillView = ({
  data, 
  barChartDemandScreenshot, 
  barChartConsumptionScreenshot
}) => {

  return (
    <PDFViewer width={800} height={600} style={{ width: '100%'}}>
        <PdfBillDocument
        data={data}
        barChartDemandScreenshot={barChartDemandScreenshot}
        barChartConsumptionScreenshot={barChartConsumptionScreenshot}
        />
    </PDFViewer>
  );
}

export default PdfBillView;
