import React, { useEffect, useState } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';
import {
  CardBody,
  CardTitle,
  Row,
  Col,
  CardHeader,
  CardFooter,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardSubtitle,
  UncontrolledTooltip
} from "reactstrap";
import * as Icon from "react-feather";
import {
  MoreVertical,
} from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ModalDeleteCard from "../ModalDeleteCard";

function CardDropdown(props) {
  const { t } = useTranslation()
  // const [openEditCard, setOpenEditCard] = useState(false);
  const [openDeleteCard, setOpenDeleteCard] = useState(false);
  const [editCard, setEditCard] = useState(false)

  function toggleDeleteCard() {
    setOpenDeleteCard(!openDeleteCard);
  }

  return (

    <UncontrolledDropdown className="d-inline-block" disabled={(props.preview)}>
      <DropdownToggle tag="a">
        <MoreVertical />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          onClick={() => {
            toggleDeleteCard();
          }}
        >
          {t('dashboards.delete')}
        </DropdownItem>
        {(openDeleteCard)
          ? <ModalDeleteCard
            open={openDeleteCard}
            toggle={toggleDeleteCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
          />
          : null
        }

        <Link
          onClick={() => setEditCard(props.cardId)}
          to={{
            pathname: `/newCard/statisticsState/${props.cardId}`,
            state: {
              cardProps: props.card,
              editCard: editCard,
            }
          }}>
          <DropdownItem>
            {t('dashboards.edit')}
          </DropdownItem>
        </Link>

        {/* <DropdownItem
          onClick={() => {
            toggleEditCard();
          }}
        >
          {t('dashboards.edit')}
        </DropdownItem>
        {(openEditCard)
          ? <ModalEditCard
            open={openEditCard}
            toggle={toggleEditCard}
            cardId={props.cardId}
            tableReload={props.tableReload}
            name={props.name}
            description={props.description}
          />
          : null
        } */}
        <DropdownItem
          onClick={() => {
            props.setDataLoading(true);

          }}
        >
          {t('dashboards.refresh')}
        </DropdownItem>

      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const StatisticsState = ({
  cardId,
  card,
  name,
  description,
  deviceId,
  variables,
  tableReload,
  preview,
  reloadTime,
  decimalNumber,
  cardType,
  cardProps,
  devices,
  cardDataToEdit,
  retrieveDataFromPreviewCard,
  dimensions
}) => {
  const { t } = useTranslation();

  const [deviceData, setdeviceData] = useState([]);
  const [dataLoading, setdataLoading] = useState(true);
  const [device, setDevice] = useState("");
  const [deviceVars, setDeviceVars] = useState(true);
  const [lastComm, setLastComm] = useState();
  const [commInterval, setCommInterval] = useState();
  const [cardHeight, setCardHeight] = useState();

  const varName = (variables) ? JSON.parse(variables)?.varName : ""
  const varTag = (variables) ? JSON.parse(variables).tag : ""

  let history = useHistory();

  useEffect(() => {
    if (dimensions) {
      if (dimensions?.id === card?.id) {
        let var1 =
          ((dimensions.size.height < 240 && dimensions.size.width < 240) && (card.id === dimensions.id)) ? 24
            : ((dimensions.size.height > 241 && dimensions.size.width > 310) && (card.id === dimensions.id) && (dimensions.size.height <= 310)) ? 48
              : ((dimensions.size.height > 311 && dimensions.size.width > 370) && (card.id === dimensions.id) && (dimensions.size.height < 370)) ? 72
                : ((dimensions.size.height > 371 && dimensions.size.width > 420) && (card.id === dimensions.id) && (dimensions.size.height < 420)) ? 96
                  : ((dimensions.size.height > 421 && dimensions.size.width > 500) && (card.id === dimensions.id) && (dimensions.size.height < 500)) ? 78
                    : ((dimensions.size.height > 501 && dimensions.size.width > 501) && (card.id === dimensions.id)) ? 84
                      : 30
        setCardHeight(var1)
      }
    }
  }, [dimensions, card?.id])

  //Envia o id do device e retorna os valores
  useEffect(() => {

    if (deviceId) {
      axios.get('/readDataLast', {
        params: {
          idDevice: deviceId
        }
      })
        .then(response => {
          setdeviceData(response.data)
          setLastComm(response.data.lastComm);
          setCommInterval(response.data.commInterval);
          setdataLoading(false)
        })
        .catch(err => {
          console.log(err);
          toastr.warning('Erro!', "Erro ao buscar dados.");
        })
    }

    //Seta o intervalo de atualização
    if (reloadTime) {
      const intervalSearch = setInterval(() => {
        if (deviceId) {
          axios.get('/readDataLast', {
            params: {
              idDevice: deviceId
            }
          })
            .then(response => {
              setdeviceData(response.data)
              setLastComm(response.data.lastComm);
              setCommInterval(response.data.commInterval);
            })
            .catch(err => {
              console.log(err);
              toastr.warning('Erro!', "Erro ao buscar dados.");
            });
          if (history.location.pathname !== "/dashboardView") {
            clearInterval(intervalSearch);
          };
        }
      }, reloadTime);
    }
  }, [deviceId, dataLoading, history, reloadTime])

  //Envia o id do device e retorna o modelo 
  useEffect(() => {

    if (deviceId) {
      axios.get('/readDeviceInfo', {
        params: {
          idDevice: deviceId
        }
      })
        .then(response => {
          setDevice(response.data)
          setLastComm(response.data.lastComm);
          setCommInterval(response.data.commInterval);
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar Modelo.");
        })
    }

  }, [deviceId])

  //Envia o id do device e retorna a unidade da variável 
  useEffect(() => {

    if (deviceId) {
      axios.get('/readVariables', {
        params: {
          id: deviceId
        }
      })
        .then(response => {
          setDeviceVars(response.data)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar Unidade.");
        })
    }

  }, [deviceId]);

  const lastCommDate = new Date(lastComm * 1000);

  return <div id="parent">
    <CardHeader>
      <div className="card-actions float-right" style={{ paddingRight: "3px" }}>
        <Row className="card-actions float-right">
          <Col className="pl-0" style={{ paddingRight: "3px" }}>
            <div className="box no-cursor cursor-pointer mp-0 otavio">
              <Icon.Move style={{ width: "15px" }} />
            </div>
          </Col>
          <CardDropdown
            card={card}
            cardId={cardId}
            tableReload={tableReload}
            name={name}
            description={description}
            preview={(preview)}
            setDataLoading={setdataLoading}
          />
        </Row>
      </div>
      <div id={"name" + cardId} >
        <CardTitle style={{ whiteSpace: "nowrap" }} tag="h5" className="mr-3 overflow-hidden text-left my-1 h5">{name ? name : name}</CardTitle>
        {((name) ? name : name) ?
          <UncontrolledTooltip
            placement="top"
            target={"name" + cardId}
          >
            {(name) ? name : name}
          </UncontrolledTooltip>
          : null}
        <CardSubtitle style={{ whiteSpace: "nowrap" }} className="overflow-hidden text-muted text-left">
          {(description) ? description : description}
        </CardSubtitle>
        {((description) ? description : description) ?
          <UncontrolledTooltip
            placement="bottom"
            target={"name" + cardId}
          >
            {(description) ? description : description}
          </UncontrolledTooltip>
          : null}
      </div>
    </CardHeader>

    <CardBody id={"desc" + cardId} className="text-center" style={{ paddingTop: "0px" }}>
      {(deviceVars.length && deviceData)
        //printa a variável e a unidade selecionada no novo card.
        ? (lastComm >= ((Date.now() / 1000) - (commInterval * 2)))
          ? <div style={{
            whiteSpace: "nowrap",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: 8
          }}>
            {(!isNaN(deviceData[varTag]) && deviceData[varTag] > 0) ?
                <div style={{
                    whiteSpace: "nowrap",
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: 8
                  }}
                  className="overflow-hidden text-muted text-left"
                >
                  <FontAwesomeIcon
                    icon={faPowerOff}
                    className="align-middle"
                    size="xs"
                    style={{ color: "green", fontSize: cardHeight }}
                  />
                  <h2 className="display-3 w-responsive text-center" style={{
                    fontSize: cardHeight,
                    top: "35%",
                    transform: "translate (0, -50%)",
                    marginTop: "-6px",
                    color: "green"
                  }}>
                    {t('dashboards.on')}
                  </h2>
              </div>
              : (!isNaN(deviceData[varTag]) && deviceData[varTag] <= 0) ?
              <div style={{
                whiteSpace: "nowrap",
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: 8
                }} 
                className="overflow-hidden text-muted text-left"
              >
              <FontAwesomeIcon
                icon={faPowerOff}
                className="align-middle"
                size="xs"
                style={{ color: "red", fontSize: cardHeight }}
              />
              <h2 className="display-3 w-responsive text-center" style={{
                fontSize: cardHeight,
                top: "35%",
                transform: "translate (0, -50%)",
                marginTop: "-6px",
                color: "red"
              }}>
                {t('dashboards.off')}
              </h2>
            </div>:
             <div style={{
              whiteSpace: "nowrap",
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: 8
              }} 
              className="overflow-hidden text-muted text-left"
            >

            <FontAwesomeIcon
              icon={faPowerOff}
              className="align-middle"
              size="xs"
              style={{ color: "green", fontSize: cardHeight }}
            />
            
            <h2 className="display-3 w-responsive text-center" style={{
              fontSize: cardHeight,
              top: "35%",
              transform: "translate (0, -50%)",
              marginTop: "-6px",
             
            }}>
              {deviceData[varTag]}
            </h2>
          </div>
            }
          </div>
          : <p className="mt-1">{t('dashboards.offlineStats')}</p>
        : <p className="mt-1">{t('dashboards.noDataStats')}</p>
      }
    </CardBody>
    {(cardHeight > 30) ?
      <CardFooter className="text-left mt-1" style={{ bottom: "0", position: `${(!preview) ? "absolute" : null}`, padding: "5px 1.25rem" }}>
        <h6 className="text-left">{device.description}</h6>
        <h6 className="card-subtitle text-muted">{(varName) ? varName : varName}</h6>
        <h6 className="card-subtitle text-muted mt-1">
          {(lastComm)
            ? lastCommDate.getDate().toString().padStart(2, '0') + '/' +
            (lastCommDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
            lastCommDate.getFullYear().toString().padStart(2, '0') + ' ' +
            lastCommDate.getHours().toString().padStart(2, '0') + ':' +
            lastCommDate.getMinutes().toString().padStart(2, '0') + ':' +
            lastCommDate.getSeconds().toString().padStart(2, '0')
            : t('dashboards.noComm')
          }
        </h6>
      </CardFooter>
      : null}
  </div>
}

export default StatisticsState;
