import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, CardBody, CardTitle, Col, Row, Spinner } from "reactstrap"

const GenericStateCard = ({
  cardTitle,
  icon,
  data,
  varData,
  varUnit,
  backgroundColor,
  key,
  state,
  zap,
  width,
  height,
  color,
  borderBottomColor,
  ProcessedAt,
}) => {
  var TimestampDate = new Date(ProcessedAt * 1000);
  var DateProcessed = (((TimestampDate.getDate()).toString()).padStart(2, '0') +
    "/" + ((TimestampDate.getMonth() + 1).toString()).padStart(2, '0') +
    "/" + TimestampDate.getFullYear() +
    " " + TimestampDate.getHours().toString().padStart(2, '0') +
    ":" + TimestampDate.getMinutes().toString().padStart(2, '0') +
    ":" + TimestampDate.getSeconds().toString().padStart(2, '0'));

  var lastComm = (state === "green")
    ? "On Grid"
    : (state === "red")
      ? "Off Grid"
      : (state === "orange")
        ? "Stand By"
        : (state === "gray")
          ? "OffLine"
          : null
  
  return (
    <div className="w-100">
      <Row>
        <Col key={key} sm="12" style={{ paddingRight: "0px" }}>
          <Card style={{
            width: (width) ? width : 143,
            height: (height) ? height : 100,
            minWidth: "135px", 
            maxHeight: "100px",
            borderStyle: "solid",
            alignItems: "center",
            marginBottom: "0px",
            border: state,
            backgroundColor: (backgroundColor) ? backgroundColor : "#dde0e5",
            // textAlign: "center",
            // justifyContent: "center",
            // margin: "auto",
            // overflowY: "scroll"
            // verde 008000 // cinza dde0e5 // 
            //azul tensao FASE e corrente FASE b4d2ff //
            //azul TENSAO ENTRADA b4d2ff BORDA EMBAIXO 3e4676
            //azul corrente ENTRADA
          }}>

            <CardBody style={{ padding: "15px", paddingTop: "10px", borderBottomColor: borderBottomColor, }}>
              <Row>
                <Col className="mt-0">
                  <CardTitle style={{ color: color }} tag="h5">{cardTitle}</CardTitle>
                </Col>
                <Col className="col-auto" style={{ paddingLeft: "0px" }}>
                  <div>
                    {(FontAwesomeIcon) ?
                      <FontAwesomeIcon
                        color={(backgroundColor === "green") ? "#F1F1F1" : null}
                        icon={icon}
                        style={{ fontSize: "16px" }}
                      />
                      : null
                    }
                  </div>
                </Col>
              </Row>
              {(state)
                ? <h1 className="display-5 text-center" style={{ fontSize: "14px", fontWeight: "500", color: "white" }}>{(lastComm) ? lastComm : "Nunca comunicou"}</h1>
                : (ProcessedAt)
                  ? <h1 className="display-5 text-center" style={{ fontSize: "14px", fontWeight: "500" }}>{(DateProcessed) ? DateProcessed : "Não processado"}</h1>
                  : <div className="text-center mb-4"><Spinner /></div>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
export default GenericStateCard