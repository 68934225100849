import React from "react";
import { 
  CardBody, CardHeader, CardTitle, 
  Row, Col
} from "reactstrap";
import { useTranslation } from 'react-i18next';
import PerformanceRatio from "./components/PerformanceRatio";
import ExpectedGeneration from "./components/ExpectedGeneration";
import CapacityFactor from "./components/CapacityFactor";
import Disponibility from "./components/Disponibility";

const PerformanceCard = ({ data }) => {
  const { t } = useTranslation();

  let cardHeight = (window.innerWidth > 700 ) ? "369px" : "277px";

  //variáveis para responsividade
  // var heightGraph = (window.innerWidth < 700 ) ? "418px" : "410px";
  // var over = (window.innerWidth < 700) ? "scroll" : null

  return (
    <>
      {(data.general)
        ? <>
            <CardHeader>
              <CardTitle tag="h5">
              {t('solar.indicators')}
              </CardTitle>
            </CardHeader>

            <CardBody className="ml-2 pr-5" style={{ 
              width: "100%", 
              height: cardHeight 
            }}>
              <Row style={{marginBottom: "18px"}}>
                <Col>
                  <PerformanceRatio projPR={data.general.performanceRatio} />
                </Col>
                <Col>
                  <ExpectedGeneration projectedEnergy={data.general.projectedEnergy} />
                </Col>
              </Row>
              <Row> 
                <Col>
                  <CapacityFactor capacityFactor={data.general.capacityFactor} />
                </Col>
                <Col>
                  <Disponibility avgAvailability={data.general.avgAvailability} />
                </Col>
              </Row>
            </CardBody>
          </>
        : <CardBody className="text-center py-6">
            {t('solar.noSolarDevices')}.
          </CardBody>
      }
    </>
  );
}

export default PerformanceCard;