import React from "react";
import { useTranslation } from "react-i18next";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export const GeneralConsumptionChart = ({ item }) => {
  const { t } = useTranslation();
  item.sort((a, b) => {
    const dateA = new Date(a.timestamp.split("/").reverse().join("-"));
    const dateB = new Date(b.timestamp.split("/").reverse().join("-"));
    return dateA - dateB;
  });

  return (
    <div className="w-100">
      <small>{t("noc.modal.generalConsumptionsUcs")}</small>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={item}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="consumption" name={t("noc.modal.water.consumption")} stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
